import ModalV2, { BaseModalProps, useModalV2 } from "../ModalV2";
import { SurveyUser } from "../../types/survey";
import Button from "../core/Button";
import { UserAvatarAndName } from "../UserAvatar";
import IconButton from "../IconButton";
import ConfirmationModalV2 from "../modals/ConfirmationModalV2";
import surveyAPI from "../../../vendorrisk/reducers/surveyAPI";
import { useAppDispatch } from "../../types/reduxHooks";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import "../../style/components/surveydetails/SurveyCollaboratorListModal.scss";

interface SurveyCollaboratorListModalProps extends BaseModalProps {
  surveyId: number;
  sharedWith: SurveyUser[];
  onOpenInviteModal: () => void;
}

const SurveyCollaboratorModal = (props: SurveyCollaboratorListModalProps) => {
  const dispatch = useAppDispatch();
  const [openConfirmModal, confirmModal] = useModalV2(ConfirmationModalV2);

  const [deleteCollaborator] = surveyAPI.useDeleteCollaboratorMutation();

  const onDeleteUser = (email: string) => {
    openConfirmModal({
      dangerousAction: true,
      title: "Are you sure you would like to remove this user?",
      buttonAction: () =>
        deleteCollaborator({ surveyId: props.surveyId, userEmail: email })
          .unwrap()
          .then(() =>
            dispatch(
              addDefaultSuccessAlert(
                `Removed ${email} as collaborator for questionnaire`
              )
            )
          )
          .catch(() =>
            dispatch(
              addDefaultUnknownErrorAlert(
                `Failed to remove ${email} as collaborator`
              )
            )
          )
          .finally(props.onClose),
    });
  };

  return (
    <ModalV2
      className={"collaborator-list-modal"}
      active={props.active}
      onClose={props.onClose}
      headerContent={"Collaborators"}
      footerContent={
        <div className={"footer-right"}>
          <Button tertiary onClick={props.onClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              props.onOpenInviteModal();
              props.onClose();
            }}
          >
            Add collaborator
          </Button>
        </div>
      }
    >
      <div className={"users"}>
        {props.sharedWith.map((u) => (
          <div key={u.id} className={"shared-with-user"}>
            <UserAvatarAndName
              avatar={u.avatar}
              name={u.name}
              email={u.email}
            />
            <IconButton
              icon={<i className={"cr-icon-trash"} />}
              onClick={() => onDeleteUser(u.email)}
            />
          </div>
        ))}
      </div>

      {confirmModal}
    </ModalV2>
  );
};

export default SurveyCollaboratorModal;
