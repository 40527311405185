import { FC, useState } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import ReportCard from "../../_common/components/ReportCard";
import { usePagination, useSorting } from "../../_common/hooks";
import { UserScopeApp } from "../api/types";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
// import AppPermissionUser from "./AppPermissionsExpandedTable";
import _ from "lodash";

import { useLocation, useRouteMatch } from "react-router-dom";
import { UserRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";
import "./UserPermissions.scss";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import UserPermissionsTable from "./UserPermissionsTable";

const UserPermissions: FC = ({}) => {
  const { pathname } = useLocation();
  const match = useRouteMatch<UserRouteParams>();
  const userUUID = match.params.userUUID;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserScopesV1Query({
    userUUID: userUUID,
  });

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "exposure_level",
      text: "Exposure level",
      sortable: true,
    },
    {
      id: "permission_name",
      text: "Description",
      sortable: true,
    },
    {
      id: "num_apps_for_permission",
      text: "Apps",
      sortable: true,
    },
  ];

  const [sortedPermissions, sortedBy, onSortChange] = useSorting<
    UserScopeApp,
    "permission_name" | "exposure_level" | "num_apps_for_permission"
  >(
    data?.scopes ?? [],
    "exposure_level",
    SortDirection.DESC,
    {
      permission_name: {
        orderFuncs: [(p) => p.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      exposure_level: {
        orderFuncs: [(p) => p.riskLevel, (p) => p.appNames.length],
        sortDirsAsc: [SortDirection.ASC, SortDirection.DESC],
        sortDirsDesc: [SortDirection.DESC],
      },
      num_apps_for_permission: {
        orderFuncs: [(p) => p.appNames.length],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    pathname
  );

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedPermissions,
    10,
    undefined,
    pathname
  );

  // Keep track of which users' rows are expanded
  const [expanded, setExpanded] = useState<Set<string>>(new Set());

  const toggleRowExpansion = (s: UserScopeApp) => {
    const newExpanded = _.clone(expanded);
    if (newExpanded.has(s.name)) {
      newExpanded.delete(s.name);
    } else {
      newExpanded.add(s.name);
    }
    setExpanded(newExpanded);
  };

  if (!isLoading && data?.scopes === undefined) {
    return <></>;
  }

  const rows = pageItems.map((p: UserScopeApp): IXTableRow<string> => {
    return {
      id: p.name,
      onClick: () => toggleRowExpansion(p),
      expanded: expanded.has(p.name),
      expandContent: <UserPermissionsTable appNames={p.appNames} />,
      cells: [
        <XTableCell key={"riskLevel"}>
          <ScopeRiskLevelDisplay riskLevel={p.riskLevel} />
        </XTableCell>,
        <XTableCell
          className="user-permissions-cell-with-subtext"
          key="permission"
        >
          <div className="user-permission-maintext">{p.description}</div>
          <div className="user-permission-subtext">{p.name}</div>
        </XTableCell>,
        <XTableCell key={"numApps"}>{p.appNames.length}</XTableCell>,
      ],
    };
  });

  const isEmptyResult = !isLoading && pageItems.length === 0;

  return (
    <div className="user-permissions">
      <ReportCard newStyles>
        {isEmptyResult && (
          <EmptyCard
            text={"There are no permissions for this user at this time."}
          />
        )}

        {!isEmptyResult && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            expandableRows
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default UserPermissions;
