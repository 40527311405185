import BaseAPI from "../../_common/rtkQueryApi";
import { VendorSummaryRisk } from "../../_common/types/vendorSummary";
import {
  CloudGitConnection,
  CloudGitRepoManifest,
  CloudGitRepository,
  CloudGitRepositoryDetail,
  CloudConnectionScan,
  CloudConnection,
  CloudConnectionAsset,
  CloudConnectionAssetWithChildren,
  CloudProvider,
  CloudProviderAccessScope,
  CloudProviderConfig,
  ManifestDependency,
  OrgVulnDependencyWithBreachsightVuln,
  CloudProviderType,
} from "./types";
import { RiskBreakdown } from "./riskBreakdown";
import { IScore } from "../../_common/types/score";
import {
  OrganisationAcceptedRisk,
  RiskAssetType,
} from "../../vendorrisk/reducers/customerAcceptedRisks.actions";
import { IUserMini } from "../../_common/types/user";

export enum AppGuardTagTypes {
  currentInProgressCloudGitSetup = "currentInProgressCloudGitSetup",
  cloudGitConnections = "cloudGitConnections",
  cloudGitRepos = "cloudGitRepos",
  cloudGitRepoDetails = "cloudGitRepoDetails",
  cloudGitRepoManifestDetails = "cloudGitRepoManifestDetails",
  riskProfile = "riskProfile",
  orgVulns = "orgVulns",
  summary = "summary",
  cloudProviders = "cloudProviders",
  cloudConnections = "cloudConnections",
  cloudConnScan = "cloudConnScan",
  cloudConnLatestScan = "cloudConnLatestScan",
  riskWaivers = "riskWaivers",
}

interface VerifyGithubInstallationV1Req {
  code: string;
  installationId: number;
}

interface GetInProgressCloudGitConnectionV1Resp {
  connection?: CloudGitConnection;
  repositories?: CloudGitRepository[];
}

interface GetCloudGitConnectionsV1Resp {
  connections: CloudGitConnection[];
}

interface CompleteConnectionV1Req {
  connectionUUID: string;
  enabledRepoUUIDs: string[];
  nickname: string;
}

export interface GetCloudGitReposV1Resp {
  repositories: CloudGitRepositoryDetail[];
}

interface GetCloudGitRepoV1Resp {
  repo: CloudGitRepositoryDetail;
  manifests: CloudGitRepoManifest[];
  dependencies: ManifestDependency[];
  risks: VendorSummaryRisk[];
}

type OptionalValue<T> = { value: T | null };

interface ConfigureCloudGitRepoV1Req {
  repoUUID: string;
  enabled?: boolean;
  nickname?: OptionalValue<string>;
  labels?: LabelOps;
}

export interface GetRisksV1Resp {
  risks: VendorSummaryRisk[];
}

interface GetVulnsV1Resp {
  vulns: OrgVulnDependencyWithBreachsightVuln[];
}

interface LabelOps {
  toAdd: number[];
  toRemove: number[];
}
interface ConfigureRepoManifestV1Req {
  manifestUUID: string;
  labels?: LabelOps;
  nickname?: OptionalValue<string>;
}

interface CheckManualRepoScanV1Resp {
  finished: boolean;
}
interface CheckManualCloudConnectionScanV1Resp {
  finished: boolean;
  startedAt: string;
}

interface GetSummaryV1Resp {
  scores: IScore[];
  adjustedScores: IScore[];
  currentAdjustedScore: number;
  riskBreakdown: RiskBreakdown;
}

interface GetCloudProvidersV1Resp {
  providers: CloudProvider[];
}

export interface VerifyCloudProviderAuthV1Req {
  providerConfig: CloudProviderConfig;
}

interface GetCloudProviderAccessScopesV1Req {
  providerConfig: CloudProviderConfig;
}

interface GetCloudProviderAccessScopesV1Resp {
  accessScopes: CloudProviderAccessScope[];
}

export interface CreateCloudConnectionV1Req {
  connection: {
    nickname?: string;
    config: CloudProviderConfig;
    provider: CloudProviderType;
  };
}

export interface ConfigureCloudConnectionV1Req {
  uuid: string;
  nickname?: string;
  config?: Partial<CloudProviderConfig>;
  labels?: LabelOps;
}

interface CreateCloudConnectionV1Resp {
  uuid: string;
}

interface GetCloudConnectionsV1Resp {
  cloudConnections: CloudConnection[];
}

interface GetCloudConnectionAssetsV1Resp {
  assets: CloudConnectionAsset[];
  assetsByIP: CloudConnectionAssetWithChildren[];
}

interface TriggerManualCloudConnectionScanV1Resp {
  scanUUID: string;
}

interface CloudConnectionScanV1Resp {
  cloudConnectionScan: CloudConnectionScan;
}

interface GetRiskWaiversV1Resp {
  waivers: OrganisationAcceptedRisk[];
  users: IUserMini[];
}

export interface CreateAppguardRiskWaiverV1ReqBody {
  riskId: string;
  riskAssetType: RiskAssetType;
  approverEmail?: string;
  justification: string;
  expiresAt?: string;
  appguardRepoUuids?: string[];
  appguardManifestUuids?: string[];
}

const AppguardAPI = BaseAPI
  // Add all our AppGuard cache tags here
  .enhanceEndpoints({
    addTagTypes: [...Object.values(AppGuardTagTypes)],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      /*
        getCloudGitConnectionsV1
        - gets the list of active cloud git connections
       */
      getCloudGitConnectionsV1: builder.query<
        GetCloudGitConnectionsV1Resp,
        void
      >({
        query: () => ({
          url: "/appguard/cloudgit/connections/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.cloudGitConnections],
      }),

      /*
        getInProgressCloudGitConnectionV1
        - gets the users in-progress cloud git setup
       */
      getInProgressCloudGitConnectionV1: builder.query<
        GetInProgressCloudGitConnectionV1Resp,
        void
      >({
        query: () => ({
          url: "/appguard/cloudgit/setup/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.currentInProgressCloudGitSetup],
      }),

      /*
        verifyGithubInstallationV1
        - verifies a github installation
        - forces a re-GET of the in-progress cloud git connection
       */
      verifyGithubInstallationV1: builder.mutation<
        void,
        VerifyGithubInstallationV1Req
      >({
        query: (req) => ({
          url: "/appguard/github/verify/v1",
          method: "POST",
          body: JSON.stringify(req),
        }),
        invalidatesTags: [
          AppGuardTagTypes.currentInProgressCloudGitSetup,
          AppGuardTagTypes.cloudGitConnections,
        ],
      }),

      /*
        completeCloudGitConnectionV1
        - finalises a github installation
        - forces a re-GET of the in-progress cloud git connection + connections for user
       */
      completeCloudGitConnectionV1: builder.mutation<
        void,
        CompleteConnectionV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloudgit/complete/v1",
          method: "PUT",
          body: JSON.stringify(req),
        }),
        invalidatesTags: [
          AppGuardTagTypes.currentInProgressCloudGitSetup,
          AppGuardTagTypes.cloudGitConnections,
          AppGuardTagTypes.cloudGitRepos,
          AppGuardTagTypes.riskProfile,
          AppGuardTagTypes.summary,
          AppGuardTagTypes.orgVulns,
        ],
      }),

      /*
        getCloudGitReposV1
        - gets the list of cloud git repos
       */
      getCloudGitReposV1: builder.query<GetCloudGitReposV1Resp, void>({
        query: () => ({
          url: "/appguard/cloudgit/repos/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.cloudGitRepos],
      }),

      /*
      deleteCloudGitConnectionV1
       - deletes a gloud git connection
       */
      deleteCloudGitConnectionV1: builder.mutation({
        query: (uuid: string) => ({
          url: "/appguard/cloudgit/connections/v1",
          method: "DELETE",
          params: {
            uuid,
          },
        }),
        invalidatesTags: [
          AppGuardTagTypes.currentInProgressCloudGitSetup,
          AppGuardTagTypes.cloudGitConnections,
          AppGuardTagTypes.cloudGitRepos,
          AppGuardTagTypes.riskProfile,
          AppGuardTagTypes.summary,
          AppGuardTagTypes.orgVulns,
        ],
      }),

      /*
        getCloudGitRepoV1
        - gets the details of a cloud git repo
       */
      getCloudGitRepoV1: builder.query<GetCloudGitRepoV1Resp, { uuid: string }>(
        {
          query: ({ uuid }) => ({
            url: "/appguard/cloudgit/repos/details/v1",
            method: "GET",
            params: {
              uuid,
            },
          }),
          providesTags: (result) =>
            result
              ? [
                  {
                    type: AppGuardTagTypes.cloudGitRepoDetails,
                    id: result.repo.uuid,
                  },
                  ...result.manifests.map((m) => ({
                    type: AppGuardTagTypes.cloudGitRepoManifestDetails,
                    id: m.uuid,
                  })),
                ]
              : [],
        }
      ),

      /*
      triggerManualRepoScanV1
       - triggers a manual repository scan
       */
      triggerManualRepoScanV1: builder.mutation({
        query: (uuid: string) => ({
          url: "/appguard/cloudgit/repos/scan/v1",
          method: "POST",
          params: {
            uuid,
          },
        }),
        invalidatesTags: [],
      }),

      /*
        configureCloudGitRepoV1
      - updates a cloud git repo
      */
      configureCloudGitRepoV1: builder.mutation<
        void,
        ConfigureCloudGitRepoV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloudgit/repos/v1",
          method: "PUT",
          body: JSON.stringify(req),
        }),
        invalidatesTags: (_, err, { repoUUID }) =>
          !err
            ? [
                AppGuardTagTypes.cloudGitRepos,
                { type: AppGuardTagTypes.cloudGitRepoDetails, id: repoUUID },
                AppGuardTagTypes.riskProfile,
                AppGuardTagTypes.summary,
                AppGuardTagTypes.orgVulns,
              ]
            : [],
      }),

      /*
        configureCloudGitRepoManifestV1
      - updates a cloud git repo manifest
      */
      configureCloudGitRepoManifestV1: builder.mutation<
        void,
        ConfigureRepoManifestV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloudgit/repo/manifest/v1",
          method: "PUT",
          body: JSON.stringify(req),
        }),
        invalidatesTags: (result, _, { manifestUUID }) =>
          result
            ? [
                {
                  type: AppGuardTagTypes.cloudGitRepoManifestDetails,
                  id: manifestUUID,
                },
              ]
            : [],
      }),

      /*
        getRisksV1
        - gets the list of active appguard risks
       */
      getAppguardRisksV1: builder.query<GetRisksV1Resp, void>({
        query: () => ({
          url: "/appguard/risks/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.riskProfile],
      }),

      /*
       * getVulnsV1
       * - gets the list of active vulnerabilities
       */
      getVulnsV1: builder.query<GetVulnsV1Resp, void>({
        query: () => ({
          url: "/appguard/vulns/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.orgVulns],
      }),

      /*
      checkManualRepoScanV1
       - checks if a manual repository scan has completed
       */
      checkManualRepoScanV1: builder.query<
        CheckManualRepoScanV1Resp,
        { uuid: string; lastScanAt?: string; scoreAt?: string }
      >({
        query: ({ uuid, lastScanAt, scoreAt }) => ({
          url: "/appguard/cloudgit/repos/scan/v1",
          method: "GET",
          params: {
            uuid,
            previous_scan_timestamp: lastScanAt,
            previous_score_at: scoreAt,
          },
        }),
      }),

      /*
      downloadSBOMV1
       - downloads an sbom
       */
      downloadSBOMV1: builder.query<
        void,
        { type: "software-inventories" | "repo"; uuids: string[] }
      >({
        query: ({ type, uuids }) => ({
          url: "/appguard/cloudgit/sbom/v1",
          method: "GET",
          params: {
            uuids,
            type,
          },
        }),
      }),

      /*
    getAppguardSummaryV1
     - gets the appguard summary for the current org
     */
      getAppguardSummaryV1: builder.query<GetSummaryV1Resp, void>({
        query: () => ({
          url: "/appguard/summary/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.summary],
      }),

      getCloudProvidersV1: builder.query<GetCloudProvidersV1Resp, void>({
        query: () => ({
          url: "/appguard/cloud/providers/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.cloudProviders],
      }),
      verifyCloudProviderAuthV1: builder.query<
        void,
        VerifyCloudProviderAuthV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloud/providers/verify/v1",
          method: "POST",
          body: JSON.stringify(req),
        }),
      }),
      getCloudProviderAccessScopesV1: builder.query<
        GetCloudProviderAccessScopesV1Resp,
        GetCloudProviderAccessScopesV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloud/providers/accessscopes/v1",
          method: "POST",
          body: JSON.stringify(req),
        }),
      }),
      createCloudConnectionV1: builder.mutation<
        CreateCloudConnectionV1Resp,
        CreateCloudConnectionV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloud/connections/v1",
          method: "POST",
          body: JSON.stringify(req),
        }),
        invalidatesTags: [AppGuardTagTypes.cloudConnections],
      }),
      configureCloudConnectionV1: builder.mutation<
        void,
        ConfigureCloudConnectionV1Req
      >({
        query: (req) => ({
          url: "/appguard/cloud/connections/v1",
          method: "PUT",
          body: JSON.stringify(req),
        }),
        invalidatesTags: [AppGuardTagTypes.cloudConnections],
      }),
      getCloudConnectionsV1: builder.query<GetCloudConnectionsV1Resp, void>({
        query: () => ({
          url: "/appguard/cloud/connections/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.cloudConnections],
      }),
      getCloudConnectionAssetsV1: builder.query<
        GetCloudConnectionAssetsV1Resp,
        void
      >({
        query: () => ({
          url: "/appguard/cloud/assets/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.cloudConnections],
      }),
      triggerManualCloudConnectionScanV1: builder.mutation<
        TriggerManualCloudConnectionScanV1Resp,
        { cloud_connection_uuid: string }
      >({
        query: (params) => ({
          url: "/appguard/cloud/scans/v1",
          method: "POST",
          params,
        }),
        invalidatesTags: [],
      }),
      getLatestCloudConnectionScanV1: builder.query<
        CloudConnectionScanV1Resp,
        { cloud_connection_uuid: string }
      >({
        query: (params) => ({
          url: "/appguard/cloud/latestscan/v1",
          method: "GET",
          params,
        }),
        providesTags: (result, _, { cloud_connection_uuid }) =>
          result
            ? [
                {
                  type: AppGuardTagTypes.cloudConnLatestScan,
                  id: cloud_connection_uuid,
                },
                {
                  type: AppGuardTagTypes.cloudConnScan,
                  id: result.cloudConnectionScan.uuid,
                },
              ]
            : [],
      }),
      checkLatestCloudConnectionScanV1: builder.query<
        CheckManualCloudConnectionScanV1Resp,
        { cloud_connection_uuid: string }
      >({
        query: (params) => ({
          url: "/appguard/cloud/scans/v1",
          method: "GET",
          params,
        }),
        providesTags: (result, _, { cloud_connection_uuid }) =>
          result
            ? [
                {
                  type: AppGuardTagTypes.cloudConnLatestScan,
                  id: cloud_connection_uuid,
                },
              ]
            : [],
      }),
      /*
      checkManualCloudScanV1
       - checks if a manual cloud scan has completed
       */
      checkManualCloudConnectionScanV1: builder.query<
        CheckManualCloudConnectionScanV1Resp,
        { uuid: string }
      >({
        query: (params) => ({
          url: "/appguard/cloud/scans/v1",
          method: "GET",
          params,
        }),
        providesTags: (result, _, { uuid }) =>
          result ? [{ type: AppGuardTagTypes.cloudConnScan, id: uuid }] : [],
      }),
      /*
    GetRiskWaiversV1
     - gets the appguard risk waivers for the current org
     */
      getAppguardRiskWaiversV1: builder.query<GetRiskWaiversV1Resp, void>({
        query: () => ({
          url: "/appguard/risk_waivers/v1",
          method: "GET",
        }),
        providesTags: [AppGuardTagTypes.riskWaivers],
      }),

      /*
      RiskAcceptanceCreateV1
       - creates a new appguard risk waiver for the org
       */
      createAppguardRiskWaiverV1: builder.mutation<
        void,
        CreateAppguardRiskWaiverV1ReqBody
      >({
        query: (req) => ({
          url: "/appguard/risk_waivers/v1",
          method: "POST",
          body: JSON.stringify(req),
        }),
        invalidatesTags: [
          AppGuardTagTypes.riskProfile,
          AppGuardTagTypes.summary,
          AppGuardTagTypes.riskWaivers,
        ],
      }),

      /*
      RiskAcceptanceDeleteV1
       - delete an appguard waiver for the org
      */
      deleteAppguardRiskWaiverV1: builder.mutation<void, number>({
        query: (id) => ({
          url: "/appguard/risk_waivers/v1",
          method: "DELETE",
          params: {
            id,
          },
        }),
        invalidatesTags: [
          AppGuardTagTypes.riskProfile,
          AppGuardTagTypes.summary,
          AppGuardTagTypes.riskWaivers,
        ],
      }),
      /*
      DeleteCloudScanAccountV1
       - delete an appguard cloudscan account
      */
      deleteCloudConnectionV1: builder.mutation<void, string>({
        query: (uuid) => ({
          url: "/appguard/cloud/connections/v1",
          method: "DELETE",
          params: {
            uuid,
          },
        }),
        invalidatesTags: [AppGuardTagTypes.cloudConnections],
      }),
    }),
  });

export default AppguardAPI;
