import { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Admin from "./views/AdminView";
import ApplicationsView from "./views/ApplicationsView";
import ApplicationUsersView from "./views/ApplicationUsersView";
import ApplicationSummaryView from "./views/ApplicationSummaryView";
import ApplicationPermissionsView from "./views/ApplicationPermissionsView";
import RiskProfileView from "./views/RiskProfileView";
import UsersView from "./views/UsersView";
import CreateRiskModificationView from "./views/CreateRiskModificationView";
import RiskModificationsView from "./views/RiskModificationsView";
import UserRiskProfileView from "./views/UserRiskProfileView";
import UserApplicationsView from "./views/UserApplicationsView";
import UserSummaryView from "./views/UserSummaryView";
import IdentityBreachesView from "./views/IdentityBreachesView";
import IdentityBreachSummaryView from "./views/IdentityBreachSummaryView";
import UserPermissionsView from "./views/UserPermissionsView";
import DashboardView from "./views/DashboardView";
import PermissionsView from "./views/PermissionsView";

export const urlPrefix = `/userbase`;
export const appUrlPrefix = `${urlPrefix}/application`;
export const userUrlPrefix = `${urlPrefix}/user`;
export const identityBreachesUrlPrefix = `${urlPrefix}/identity_breach`;

export const appUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}`;
};
export const appSummaryUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}/summary`;
};

export const appUsersUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}/users`;
};

export const appPermissionsUrl = (appName: string) => {
  return `${appUrlPrefix}/${appName}/permissions`;
};

export const userUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}`;
};

export const userSummaryUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/summary`;
};

export const userRiskProfileUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/risk_profile`;
};

export const userApplicationsUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/applications`;
};

export const userPermissionsUrl = (userUUID: string) => {
  return `${userUrlPrefix}/${userUUID}/permissions`;
};

export const identityBreachUrl = (identityBreachID: number) => {
  return `${identityBreachesUrlPrefix}/${identityBreachID}`;
};

export const identityBreachSummaryUrl = (identityBreachID: number) => {
  return `${identityBreachesUrlPrefix}/${identityBreachID}/summary`;
};

export const adminUrl = `${urlPrefix}/admin`;
export const riskProfileUrl = `${urlPrefix}/risk_profile`;
export const usersUrl = `${urlPrefix}/users`;
export const applicationsUrl = `${urlPrefix}/applications`;
export const permissionsUrl = `${urlPrefix}/permissions`;
export const identityBreachesUrl = `${urlPrefix}/identity_breaches`;
export const createRiskModificationUrl = `${urlPrefix}/risk_modifications/create`;
export const riskModificationUrl = `${urlPrefix}/risk_modifications`;
export const dashboardUrl = `${urlPrefix}/dashboard`;

const UserBaseAppRouter: FC = () => {
  return (
    <div>
      <Switch>
        <Route path={dashboardUrl} component={DashboardView} />
        <Route path={adminUrl} component={Admin} />
        <Route path={riskProfileUrl} component={RiskProfileView} />
        <Route path={usersUrl} component={UsersView} />
        <Route path={applicationsUrl} component={ApplicationsView} />
        <Route path={permissionsUrl} component={PermissionsView} />
        <Route path={identityBreachesUrl} component={IdentityBreachesView} />
        <Route
          path={createRiskModificationUrl}
          component={CreateRiskModificationView}
        />
        <Route path={riskModificationUrl} component={RiskModificationsView} />
        <Route
          path={`${appUrlPrefix}/:appName/users`}
          component={ApplicationUsersView}
        />
        <Route
          path={`${appUrlPrefix}/:appName/summary`}
          component={ApplicationSummaryView}
        />
        <Route
          path={`${appUrlPrefix}/:appName/permissions`}
          component={ApplicationPermissionsView}
        />
        <Route
          path={`${appUrlPrefix}/:appName`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${appUrlPrefix}/${props?.match?.params?.appName}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/summary`}
          component={UserSummaryView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/risk_profile`}
          component={UserRiskProfileView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/applications`}
          component={UserApplicationsView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID/permissions`}
          component={UserPermissionsView}
        />
        <Route
          path={`${userUrlPrefix}/:userUUID`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${userUrlPrefix}/${props?.match?.params?.userUUID}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
        <Route
          path={`${identityBreachesUrlPrefix}/:breachID/summary`}
          component={IdentityBreachSummaryView}
        />
        <Route
          path={`${identityBreachesUrlPrefix}/:breachID`}
          render={(props) => {
            // Manually construct the redirect passing through the current state
            return (
              <Redirect
                to={{
                  pathname: `${identityBreachesUrlPrefix}/${props?.match?.params?.breachID}/summary`,
                  state: props?.location?.state,
                }}
              />
            );
          }}
        />
      </Switch>
    </div>
  );
};

export default UserBaseAppRouter;
