import { FC } from "react";
import userbaseApi from "../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSorting } from "../../_common/hooks";
import { formatDateAsLocal } from "../../_common/helpers";
import { SaaSUser } from "../api/types";
import TeamsPills from "./TeamsPills";
import { WaivedAssets } from "../../vendorrisk/reducers/risks.actions";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";

interface RiskSaaSUsersProps {
  riskId: string;
  showWaived?: boolean;
  waivedAssets?: WaivedAssets;
}

const RiskSaaSUsers: FC<RiskSaaSUsersProps> = ({
  riskId,
  showWaived,
  waivedAssets,
}) => {
  const { data, isLoading } = userbaseApi.useGetUserBaseRiskUsersV1Query({
    riskId,
  });

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "name",
      text: "Name",
      sortable: true,
    },
    {
      id: "team",
      text: "Team",
      sortable: true,
    },
    {
      id: "first_detected",
      text: "First Detected",
      sortable: true,
    },
    {
      id: "status",
      text: "Status",
      sortable: false,
    },
  ];

  const filteredUsers =
    data?.users.filter((u) => {
      if (showWaived) {
        return true;
      }

      return waivedAssets?.assetsWaived[u.uuid] === undefined;
    }) ?? [];

  const [sortedUsers, sortedBy, onSortChange] = useSorting<
    SaaSUser,
    "name" | "team" | "first_detected"
  >(filteredUsers, "name", SortDirection.ASC, {
    name: {
      orderFuncs: [
        (u) =>
          u.name ? u.name.toLocaleLowerCase() : u.email.toLocaleLowerCase(),
      ],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    team: {
      orderFuncs: [
        (u) =>
          u.teams
            .map((t) => t.toLocaleLowerCase())
            .sort((a, b) => a.localeCompare(b))
            .join(","),
      ],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    first_detected: {
      orderFuncs: [(u) => u.firstDetected],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const rows = sortedUsers.map<IXTableRow>((u) => {
    const isWaived = waivedAssets?.assetsWaived[u.uuid] !== undefined;
    const isPendingWaived =
      waivedAssets?.assetsPendingWaiver[u.uuid] !== undefined;
    return {
      id: u.email,
      cells: [
        <XTableCell key={"name"}>{u.name || u.email}</XTableCell>,
        <XTableCell key={"team"}>
          {u.teams ? <TeamsPills teams={u.teams} /> : "-"}
        </XTableCell>,
        <XTableCell key={"first_detected"}>
          {u.firstDetected ? formatDateAsLocal(u.firstDetected) : "-"}
        </XTableCell>,
        <XTableCell key="status">
          {isWaived && <PillLabel>Waived</PillLabel>}
          {isPendingWaived && (
            <PillLabel color={LabelColor.Orange}>Waiver pending</PillLabel>
          )}
        </XTableCell>,
      ],
    };
  });

  return (
    <>
      <XTable
        stickyColumnHeaders={false}
        numLoadingRows={3}
        className={"saas-users-container"}
        columnHeaders={columnHeaders}
        rows={rows}
        loading={isLoading}
        iconOptions
        sortedBy={sortedBy}
        onSortChange={onSortChange}
      />
    </>
  );
};

export default RiskSaaSUsers;
