import React, { useEffect, useMemo, useState } from "react";
import { clearedVendorFilters, Filters, FilterTypes } from "./filter/types";
import {
  IVendorSearchVendorAndWatched,
  IWatchlistPaging,
} from "../../_common/types/vendor";
import { DefaultRootState } from "react-redux";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../_common/types/redux";
import {
  runFetchVendorSearchResults,
  setCustomerDataFiltersAndRefreshData,
  setVendorSearch,
} from "../reducers/cyberRiskActions";
import ReportCard from "../../_common/components/ReportCard";
import {
  FilterBarContainer,
  FilterLabelContainer,
  FilterPanelContainer,
  isFilterActive,
} from "./filter";
import SlidePanel from "../../_common/components/SlidePanel";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import LabelList from "./LabelList";
import { LabelClassification, LabelColor } from "../../_common/types/label";
import ColorGrade, { ColorGradeSize } from "./executive_summary/ColorGrade";
import { debounce as _debounce } from "lodash";
import SearchBox from "../../_common/components/SearchBox";

import "../style/components/SelectVendorCard.scss";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import classNames from "classnames";
import { getFiltersFromState } from "../reducers/filters.actions";
import {
  fetchAllFilteredVendors,
  fetchVendorsNoCache,
} from "../reducers/vendors.actions";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import Button, { IButtonProps } from "../../_common/components/core/Button";
import VendorTierBadge from "./vendor_tiers/VendorTierBadge";
import {
  OrgAccessVendorPortfolios,
  OrgAccessVendorTechnologies,
  UserVendorRiskWrite,
} from "../../_common/permissions";
import Score from "./Score";
import PillLabel from "./PillLabel";
import { getVendorWords, IVendorWords } from "../../_common/constants";
import { AssuranceType } from "../../_common/types/organisations";
import { validateUrl } from "../../_common/helpers";
import SearchEmptyCardWithDomainScan from "../../_common/components/SearchEmptyCardWithDomainScan";
import { shouldBypassSearch } from "../helpers/util";
import { getDefaultFilters } from "../reducers/defaults";
import { appConnect } from "../../_common/types/reduxHooks";
import moment from "moment";

interface ISelectAllVendorsButtonProps extends IButtonProps {
  onVendorsSelected: (vendorIds: number[]) => void;
  setLoading: (loading: boolean) => void;
  filterText: string;
  dispatch: DefaultThunkDispatch;
}

// the default page size when displaying search results over all vendors (not just watched vendors). See the optional
// flag supportUnwatchedVendors.
const VENDORS_PAGESIZE = 15;

const supportedFilters = [
  FilterTypes.VENDOR_ASSESSMENT_STATUS,
  FilterTypes.VENDOR_ASSESSMENT_AUTHOR,
  FilterTypes.VENDOR_REASSESSMENT_DATE,
  FilterTypes.VENDOR_TIER,
  FilterTypes.VENDOR_LABEL,
  FilterTypes.SCORE,
  FilterTypes.VENDOR_SURVEY_TYPES,
  FilterTypes.VENDOR_EVIDENCE_TYPES,
];

// SelectAllVendorsButton can be used in with the SelectVendorsCard to provide a button that will
// select all vendors that match the current filter
// The filter text should be passed in props to ensure it matches fully
export const SelectAllVendorsButton = (props: ISelectAllVendorsButtonProps) => {
  const onSelectAll = async () => {
    props.setLoading(true);
    props
      .dispatch(fetchAllFilteredVendors(props.filterText))
      .then((vendorMinis) => {
        if (vendorMinis) {
          props.onVendorsSelected(vendorMinis.map((v) => v.id));
        }
      })
      .catch(() =>
        props.dispatch(
          addDefaultUnknownErrorAlert(
            "Error selecting all vendors that match filters"
          )
        )
      )
      .finally(() => {
        props.setLoading(false);
      });
  };

  return (
    <Button {...props} onClick={onSelectAll}>
      {props.children}
    </Button>
  );
};

interface ISelectVendorCardOwnProps {
  selectedVendorIDs: number[];
  onSelectVendor: (
    vendorId: number,
    selected: boolean,
    vendorName: string,
    vendorHostname: string,
    isWatched: boolean,
    hasAssessmentPublished: boolean
  ) => void;
  isVisible: boolean;
  hideHeader?: boolean;
  onClearSelection?: () => void;
  allowMultiSelect?: boolean;
  className?: string;
  setFilterText: (filterText: string) => void;
  filterText: string;
  loading?: boolean;
  setFiltersActive?: (active: boolean) => void;
  setTotalVendors?: (total: number) => void;
  writablePortfoliosOnly?: boolean; // If true, filter the list to portfolio IDs the user has write access to only
  assessedVendorsOnly?: boolean; // If true, filter to vendors with completed assessments only
  pageSize?: number;
  sortBy?: string;
  sortDir?: SortDirection;
  defaultFilterText?: string;
  supportUnwatchedVendors?: boolean;
  allVendorsSearchResult?: IVendorSearchVendorAndWatched[];
  setAllVendorsSearchResult?: (
    results: IVendorSearchVendorAndWatched[]
  ) => void;
  buildHeaders?: () => IXTableColumnHeader[]; // Optional header builder function
  buildRows?: (vendor: IVendorSearchVendorAndWatched[]) => IXTableRow[]; // Optional row builder function
}

interface ISelectVendorCardConnectedProps {
  filters?: Filters;
  vendorPortfoliosSupported: boolean;
  fourthPartySupported: boolean;
  enabledPortfolioIDs: number[];
  disabledPortfolioIDs: number[];
  vendorWords: IVendorWords;
  assuranceType: AssuranceType;
}

type ISelectVendorCardProps = ISelectVendorCardOwnProps &
  ISelectVendorCardConnectedProps &
  DefaultThunkDispatchProp;

export const SelectVendorTable = (props: ISelectVendorCardProps) => {
  const [pageOpts, setPageOpts] = useState<Partial<IWatchlistPaging>>({
    pageNum: 1,
    pageSize: props.pageSize ? props.pageSize : 30,
    sortBy: props.sortBy,
    sortDir: props.sortDir,
  });
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorResult, setVendorResult] = useState(
    [] as IVendorSearchVendorAndWatched[]
  );
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [domainScanning, setDomainScanning] = useState(false);

  // fetchAllVendorsNoCache
  // use existing function to run a vendor search over all vendors (watched and unwatched). the
  // results of this search aren't paged, and are capped at approx 30 elements. For a nice UX we'll page the
  // 30-odd results once we've received them here
  const fetchAllVendorsNoCache = (searchTerm: string, filters: any) => {
    return async (
      dispatch: DefaultThunkDispatch,
      getState: () => DefaultRootState
    ) => {
      dispatch(
        setVendorSearch({
          loading: true,
          query: searchTerm,
          results: {},
        })
      );
      return runFetchVendorSearchResults(
        searchTerm,
        getState,
        dispatch,
        filters,
        30
      );
    };
  };

  const fetchVendors = async (opts?: {
    namePrefix?: string;
    pageOpts?: Partial<IWatchlistPaging>;
  }) => {
    const searchText = opts?.namePrefix ?? props.filterText;
    const fetchVendorsFilter = {
      ...props.filters,
      assessedVendorsOnly:
        !props.supportUnwatchedVendors && props.assessedVendorsOnly,
      namePrefix: searchText,
    };

    if (
      props.vendorPortfoliosSupported &&
      props.writablePortfoliosOnly &&
      props.enabledPortfolioIDs.length > 0
    ) {
      // Filter to only the portfolios the user has write access to.
      if (
        fetchVendorsFilter.portfolioIds &&
        fetchVendorsFilter.portfolioIds.length > 0
      ) {
        // A filter has been specified, make sure to only include writable portfolios
        fetchVendorsFilter.portfolioIds =
          fetchVendorsFilter.portfolioIds.filter((pId) =>
            props.enabledPortfolioIDs.includes(pId)
          );
      }

      if (
        !fetchVendorsFilter.portfolioIds ||
        fetchVendorsFilter.portfolioIds.length === 0
      ) {
        fetchVendorsFilter.portfolioIds = props.enabledPortfolioIDs;
      }
    }

    props.setTotalVendors && props.setTotalVendors(0);
    const finalVendors: IVendorSearchVendorAndWatched[] = [];

    if (props.supportUnwatchedVendors) {
      // search over ALL vendors, not just watched vendors.
      if (!searchText || shouldBypassSearch(searchText)) {
        props.setAllVendorsSearchResult &&
          props.setAllVendorsSearchResult(
            [] as IVendorSearchVendorAndWatched[]
          );
        setVendorResult([] as IVendorSearchVendorAndWatched[]);
      } else {
        setVendorsLoading(true);

        props
          .dispatch(fetchAllVendorsNoCache(searchText, getDefaultFilters()))
          .then((result: any) => {
            const numVendors = result?.vendors?.length;
            const pageSize = props.pageSize ?? VENDORS_PAGESIZE;
            const numPages = Math.ceil(numVendors / pageSize);
            setPageOpts({
              ...pageOpts,
              pageNum: 1,
              pageSize: pageSize,
              totalPages: numPages,
              totalItems: result?.vendorsTotal,
            } as IWatchlistPaging);
            const watchedVendorIndex: { [hostname: string]: any } = {};
            result?.watchedVendors.forEach((v: any) => {
              watchedVendorIndex[v.primary_hostname] = v;
              finalVendors.push({
                isWatched: true,
                id: v.id,
                name: v.name,
                display_name: v.display_name,
                primary_hostname: v.primary_hostname,
                cstar_score: v.cstar_score,
                labels: v.labels,
                vendorTier: v.vendorTier,
                vendorTierName: v.vendorTierName,
              } as IVendorSearchVendorAndWatched);
            });
            const snapshotVendorIndex: { [hostname: string]: any } = {};
            result?.lookups.forEach((l: any) => {
              snapshotVendorIndex[l.primaryDomain] = l;
            });
            const now = moment();
            result?.vendors.forEach((v: any) => {
              if (!watchedVendorIndex[v.primaryHostname]) {
                const l = snapshotVendorIndex[v.primaryHostname];
                const daysLeftInSnapshot = l
                  ? moment(l.lookupDate).add(30, "days").diff(now, "days")
                  : undefined;
                finalVendors.push({
                  isWatched: false,
                  isSnapshot: l ? !l.expired : false,
                  snapshotDaysRemaining: daysLeftInSnapshot,
                  id: v.id,
                  name: v.name,
                  display_name:
                    v.legalName.length > 0 ? v.legalName : undefined,
                  cstar_score: v.cstarScore,
                  primary_hostname: v.primaryHostname,
                } as IVendorSearchVendorAndWatched);
              }
            });
            if (pageOpts.sortBy && pageOpts.sortDir) {
              sortAllVendorsInPlace(
                finalVendors,
                pageOpts.sortBy,
                pageOpts.sortDir
              );
            } else {
              finalVendors.sort((a, b) => {
                const aw = a.isWatched ?? "M";
                const bw = b.isWatched ?? "U";
                return aw > bw ? -1 : aw < bw ? 1 : 0;
              });
            }
            props.setAllVendorsSearchResult &&
              props.setAllVendorsSearchResult(finalVendors);
            setVendorResult(
              finalVendors.slice(0, Math.min(pageSize, finalVendors.length))
            );
            setVendorsLoading(false);
          })
          .catch((e) => {
            setVendorsLoading(false);
            console.error(`failed to fetch all vendors: ${e}`);
            props.dispatch(
              addDefaultUnknownErrorAlert(
                `error fetching ${props.vendorWords?.plural}: ${e}`
              )
            );
          });
      }
    } else {
      setVendorsLoading(true);
      fetchVendorsFilter.namePrefix = searchText;
      props
        .dispatch(
          fetchVendorsNoCache(opts?.pageOpts ?? pageOpts, fetchVendorsFilter)
        )
        .then((vendorResult) => {
          if (vendorResult) {
            setPageOpts(vendorResult?.paging);
            const finalVendors = [] as IVendorSearchVendorAndWatched[];
            vendorResult.vendors?.forEach((v) => {
              finalVendors.push({
                isWatched: true,
                id: v.id,
                name: v.name,
                display_name: v.display_name,
                primary_hostname: v.primary_hostname,
                cstar_score: v.cstar_score,
                labels: v.labels,
                vendorTier: v.vendorTier,
                vendorTierName: v.vendorTierName,
              } as IVendorSearchVendorAndWatched);
            });
            setVendorResult(finalVendors);
          }
          props.setTotalVendors &&
            props.setTotalVendors(vendorResult?.paging.totalFilteredItems ?? 0);
          setVendorsLoading(false);
        })
        .catch(() => {
          setVendorsLoading(false);
          props.dispatch(
            addDefaultUnknownErrorAlert(
              `error fetching ${props.vendorWords?.plural}`
            )
          );
        });
    }
  };

  // load vendors on both load and filter change
  useEffect(() => {
    fetchVendors();
  }, [props.filters, props.assessedVendorsOnly]);

  const headers: IXTableColumnHeader[] = useMemo(() => {
    if (props.buildHeaders) {
      return props.buildHeaders();
    }

    const defaultHeaders = [
      {
        id: "name",
        text: "Vendor",
        sortable: true,
      },
      {
        id: "hostname",
        text: "Main website",
        sortable: true,
      },
      {
        id: "score",
        text: "Score",
        sortable: true,
      },
      {
        id: "labels",
        text: "Labels",
      },
      {
        id: "tier",
        text: "Tier",
        sortable: true,
      },
    ];
    if (props.supportUnwatchedVendors) {
      defaultHeaders.push({
        id: "type",
        text: "Type",
        sortable: true,
      });
    }
    return defaultHeaders;
  }, [props.supportUnwatchedVendors, props.buildHeaders]);

  const rows: IXTableRow[] = props.buildRows
    ? props.buildRows(vendorResult) // Use custom cell builder if provided
    : vendorResult.map((v) => {
        const cells: React.ReactNode[] = [
          <XTableCell key={"name"} className={"vendor-cell"}>
            {v.display_name ?? v.name}
          </XTableCell>,
          <XTableCell key={"hostname"} className={"domain-cell"}>
            {v.primary_hostname}
          </XTableCell>,
          <XTableCell key={"score"} className={"score-cell"}>
            <div className={"score-div"}>
              <ColorGrade score={v.cstar_score} size={ColorGradeSize.Small} />
              <Score colored small score={v.cstar_score} />
            </div>
          </XTableCell>,
          <XTableCell key={"labels"} className={"labels-cell"}>
            <LabelList
              classification={LabelClassification.VendorLabel}
              labels={v.labels}
              maxWidth={400}
            />
          </XTableCell>,
          <XTableCell key={"tier"} className={"tier-cell"}>
            {v.vendorTier && v.vendorTierName && (
              <VendorTierBadge
                tier={v.vendorTier}
                name={v.vendorTierName}
                showName
              />
            )}
          </XTableCell>,
        ];

        // only show the vendor type (Monitored/Unmonitored) if this is a free-trial account, since
        // only under this circumstance do we do a full vendor search (rather than just a monitored search)
        if (props.supportUnwatchedVendors) {
          cells.push(
            <XTableCell key={"type"} className={"type-cell"}>
              {v.isWatched ? (
                <PillLabel color={LabelColor.Blue}>Monitored</PillLabel>
              ) : (
                <PillLabel color={LabelColor.Red}>Unmonitored</PillLabel>
              )}
            </XTableCell>
          );
        }

        return {
          id: v.id,
          cells: cells,
          onClick: () => {
            props.onSelectVendor(
              v.id,
              !props.selectedVendorIDs.includes(v.id) ||
                !props.allowMultiSelect,
              v.name,
              v.primary_hostname,
              v.isWatched,
              !!v.assessments?.some((a) => !!a.publishedAt)
            );
          },
          selected: props.selectedVendorIDs.includes(v.id),
          selectionDisabled: true,
        };
      });

  const currentPage = pageOpts.pageNum;
  const totalPages = pageOpts.totalPages;

  const onPageChange = (pageNum: number) => {
    setPageOpts((opts) => ({ ...opts, pageNum }));
    if (!props.supportUnwatchedVendors) {
      fetchVendors({ pageOpts: { ...pageOpts, pageNum } });
    } else {
      const pageSize = pageOpts?.pageSize ?? VENDORS_PAGESIZE;
      setVendorResult(
        (props.allVendorsSearchResult ?? []).slice(
          (pageNum - 1) * pageSize,
          Math.min(
            pageNum * pageSize,
            (props.allVendorsSearchResult ?? []).length
          )
        )
      );
    }
  };

  const sortAllVendorsInPlace = (
    allVendors: IVendorSearchVendorAndWatched[],
    columnId: string,
    sortDir: SortDirection
  ) => {
    switch (columnId) {
      case "name":
        allVendors.sort((a, b) => {
          const aname = a.display_name ?? a.name;
          const bname = b.display_name ?? b.name;
          return aname < bname ? -1 : aname > bname ? 1 : 0;
        });
        break;
      case "hostname":
        allVendors.sort((a, b) => {
          return a.primary_hostname < b.primary_hostname
            ? -1
            : a.primary_hostname > b.primary_hostname
              ? 1
              : 0;
        });
        break;
      case "score":
        allVendors.sort((a, b) => {
          return a.cstar_score < b.cstar_score
            ? -1
            : a.cstar_score > b.cstar_score
              ? 1
              : 0;
        });
        break;
      case "tier":
        allVendors.sort((a, b) => {
          return (a.vendorTier ?? Number.MAX_VALUE) <
            (b.vendorTier ?? Number.MAX_VALUE)
            ? -1
            : (a.vendorTier ?? Number.MAX_VALUE) >
                (b.vendorTier ?? Number.MAX_VALUE)
              ? 1
              : 0;
        });
        break;
      case "type":
        allVendors.sort((a, b) => {
          const aw = a.isWatched ?? "M";
          const bw = b.isWatched ?? "U";
          return aw < bw ? -1 : aw > bw ? 1 : 0;
        });
        break;
    }
    if (sortDir == SortDirection.DESC) {
      allVendors.reverse();
    }
  };

  const { sortDir, sortBy } = pageOpts;

  const onSortChange = (columnId: string, newSortDir: SortDirection) => {
    setPageOpts((opts) => ({ ...opts, sortBy: columnId, sortDir: newSortDir }));
    if (!props.supportUnwatchedVendors) {
      fetchVendors({
        pageOpts: { ...pageOpts, sortBy: columnId, sortDir: newSortDir },
      });
    } else {
      // sort the allVendorsSearchResult list and re-gen the current page
      const sortedAllVendors = [...(props.allVendorsSearchResult ?? [])];
      sortAllVendorsInPlace(sortedAllVendors, columnId, newSortDir);

      const pageNum = pageOpts.pageNum ? pageOpts.pageNum - 1 : 0;
      const pageSize = pageOpts.pageSize ?? VENDORS_PAGESIZE;
      const vendorResult = sortedAllVendors.slice(
        pageNum * pageSize,
        Math.min(
          (pageNum + 1) * pageSize,
          (props.allVendorsSearchResult ?? []).length
        )
      );
      setVendorResult(vendorResult);
      props.setAllVendorsSearchResult &&
        props.setAllVendorsSearchResult(sortedAllVendors);
    }
  };

  const _onSearchChange = _debounce((searchString: string) =>
    fetchVendors({ namePrefix: searchString, pageOpts: pageOpts })
  );

  const onSearchChange = (searchString: string) => {
    props.setFilterText(searchString);
    _onSearchChange(searchString);
  };

  if (props.vendorPortfoliosSupported) {
    supportedFilters.push(FilterTypes.VENDOR_PORTFOLIO);
  }
  if (props.fourthPartySupported) {
    supportedFilters.push(FilterTypes.FOURTH_PARTY_PRODUCT);
  }

  useEffect(() => {
    props.setFiltersActive &&
      props.filters &&
      props.setFiltersActive(isFilterActive(props.filters, supportedFilters));
    // whenever filters are changed we need to reset the current selection, but only if we can see this component
    props.onClearSelection && props.isVisible && props.onClearSelection();
  }, [props.filters]);

  const looksLikeDomain = validateUrl(props.filterText);
  return (
    <>
      <div className={"search"}>
        <SearchBox
          autoFocus
          onChanged={onSearchChange}
          disabled={domainScanning}
          returnFocusOnClear
          inputRef={searchInputRef}
          value={props.filterText}
          placeholder={"Search for a vendor by name or URL"}
        />
      </div>
      {(vendorsLoading || rows.length > 0) && (
        <XTable
          rows={rows}
          columnHeaders={headers}
          selectable
          onSelectClick={(id, newSelectedState) => {
            const v = vendorResult.find((v) => v.id === (id as number));
            if (v) {
              props.onSelectVendor(
                v.id,
                newSelectedState || !props.allowMultiSelect,
                v.name,
                v.primary_hostname,
                v.isWatched,
                !!v.assessments?.some((a) => !!a.publishedAt)
              );
            }
          }}
          radioSelector={!props.allowMultiSelect}
          loading={vendorsLoading || props.loading}
          pagination={{
            totalPages: totalPages ?? 0,
            currentPage: currentPage ?? 1,
            onPageChange: onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          sortedBy={
            sortBy && sortDir
              ? { columnId: sortBy, direction: sortDir }
              : undefined
          }
          onSortChange={onSortChange}
        />
      )}
      {!vendorsLoading && rows.length === 0 && props.filterText && (
        <>
          {props.supportUnwatchedVendors && looksLikeDomain ? (
            <SearchEmptyCardWithDomainScan
              dispatch={props.dispatch}
              fetchVendors={fetchVendors}
              pageOpts={pageOpts}
              searchString={props.filterText}
              vendorWords={props.vendorWords}
              searchItemText={props.vendorWords.plural}
              onScanStartStop={(state: boolean) => {
                setDomainScanning(state);
              }}
              onClear={() => {
                onSearchChange("");
                searchInputRef.current?.focus();
              }}
            />
          ) : (
            <SearchEmptyCard
              searchString={props.filterText}
              onClear={() => {
                onSearchChange("");
                searchInputRef.current?.focus();
              }}
              searchItemText={"vendors"}
            />
          )}
        </>
      )}
      {!vendorsLoading &&
        rows.length === 0 &&
        props.filters &&
        isFilterActive(props.filters, supportedFilters) &&
        !props.filterText && (
          <SearchEmptyCard
            onClear={() => {
              props.dispatch(
                setCustomerDataFiltersAndRefreshData({
                  ...clearedVendorFilters,
                })
              );
              searchInputRef.current?.focus();
            }}
            hideSubText
            searchItemText={"vendors"}
            clearText={"Clear filters"}
          />
        )}
      {!vendorsLoading &&
        rows.length === 0 &&
        (!props.filters || !isFilterActive(props.filters, supportedFilters)) &&
        !props.filterText &&
        (props.supportUnwatchedVendors ? (
          <SearchEmptyCard
            searchTextOverride={`Search for a ${props.vendorWords.singular}`}
            searchItemText={props.vendorWords.plural}
            subTextOverride={`Enter a company's name or URL to start your search.`}
          />
        ) : (
          <SearchEmptyCard
            searchTextOverride={"Find your vendor"}
            onClear={() => {
              props.dispatch(
                setCustomerDataFiltersAndRefreshData({
                  ...clearedVendorFilters,
                })
              );
              searchInputRef.current?.focus();
            }}
            hideSubText
            searchItemText={"vendors"}
            clearText={"Clear filters"}
          />
        ))}
    </>
  );
};

const SelectVendorCard = (props: ISelectVendorCardProps) => {
  const [filterPanelOpen, setFilterPanelOpen] = useState(false);

  if (!props.isVisible) {
    return <></>;
  }

  return (
    <>
      {!props.supportUnwatchedVendors && (
        <>
          {/* don't support filters if we're using the search and will be displaying unmonitored results */}
          <FilterBarContainer
            isCustomer
            supportedFilters={supportedFilters}
            // TODO - is sub
          />
        </>
      )}
      <ReportCard
        newStyles
        className={classNames("select-vendor-card", props.className)}
      >
        {!props.hideHeader && (
          <div className={"header"}>
            <div className={"header-title"}>
              Select vendor{props.allowMultiSelect ? "s" : ""}
            </div>
            {!props.supportUnwatchedVendors && (
              <>
                {/* don't support filters if we're using the search and will be displaying unmonitored results */}
                <div className={"header-right"}>
                  <FilterLabelContainer
                    supportedFilters={supportedFilters}
                    onClick={() => setFilterPanelOpen(true)}
                  />
                </div>
              </>
            )}
          </div>
        )}
        <SelectVendorTable
          dispatch={props.dispatch}
          filters={props.filters}
          allVendorsSearchResult={props.allVendorsSearchResult}
          buildHeaders={props.buildHeaders}
          buildRows={props.buildRows}
          setAllVendorsSearchResult={props.setAllVendorsSearchResult}
          vendorPortfoliosSupported={props.vendorPortfoliosSupported}
          fourthPartySupported={props.fourthPartySupported}
          enabledPortfolioIDs={props.enabledPortfolioIDs}
          disabledPortfolioIDs={props.disabledPortfolioIDs}
          selectedVendorIDs={props.selectedVendorIDs}
          onSelectVendor={props.onSelectVendor}
          isVisible={props.isVisible}
          onClearSelection={props.onClearSelection}
          allowMultiSelect={props.allowMultiSelect}
          setFilterText={props.setFilterText}
          filterText={props.filterText}
          loading={props.loading}
          setFiltersActive={props.setFiltersActive}
          setTotalVendors={props.setTotalVendors}
          writablePortfoliosOnly={props.writablePortfoliosOnly}
          pageSize={props.pageSize}
          sortBy={props.sortBy}
          sortDir={props.sortDir}
          defaultFilterText={props.defaultFilterText}
          assessedVendorsOnly={props.assessedVendorsOnly}
          supportUnwatchedVendors={props.supportUnwatchedVendors}
          vendorWords={props.vendorWords}
          assuranceType={props.assuranceType}
        />
      </ReportCard>
      <SlidePanel
        active={filterPanelOpen}
        title={"Filter by"}
        newStyles
        dimContent
        onClose={() => setFilterPanelOpen(false)}
      >
        <FilterPanelContainer
          closePanel={() => setFilterPanelOpen(false)}
          supportedFilters={supportedFilters}
          disabledVendorPortfolioIds={
            props.vendorPortfoliosSupported && props.writablePortfoliosOnly
              ? props.disabledPortfolioIDs
              : undefined
          }
        />
      </SlidePanel>
    </>
  );
};

const emptyArray: number[] = [];

export default appConnect<
  ISelectVendorCardConnectedProps,
  never,
  ISelectVendorCardOwnProps
>((state: DefaultRootState) => {
  const vendorPortfoliosSupported =
    state.common.userData.orgPermissions.includes(OrgAccessVendorPortfolios);
  const fourthPartySupported = state.common.userData.orgPermissions.includes(
    OrgAccessVendorTechnologies
  );

  const enabledPortfolioIDs: number[] = [];
  const disabledPortfolioIDs: number[] = [];

  if (
    vendorPortfoliosSupported &&
    !state.common.userData.userPermissions.includes(UserVendorRiskWrite)
  ) {
    Object.entries(
      state.common.userData.vendorPortfolioSpecificPermissions
    ).forEach(([portfolioId, perms]) => {
      if (perms?.includes(UserVendorRiskWrite)) {
        enabledPortfolioIDs.push(parseInt(portfolioId));
      } else {
        disabledPortfolioIDs.push(parseInt(portfolioId));
      }
    });
  }
  const assuranceType = state.common.userData.assuranceType;

  return {
    filters: getFiltersFromState(state),
    vendorPortfoliosSupported,
    fourthPartySupported,
    enabledPortfolioIDs:
      enabledPortfolioIDs.length > 0 ? enabledPortfolioIDs : emptyArray,
    disabledPortfolioIDs:
      disabledPortfolioIDs.length > 0 ? disabledPortfolioIDs : emptyArray,
    vendorWords: getVendorWords(assuranceType),
    assuranceType: assuranceType,
  };
})(SelectVendorCard);
