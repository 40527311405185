import PageHeader from "../../_common/components/PageHeader";
import { FC, useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { DefaultRouteProps } from "../../_common/types/router";
import userbaseApi from "../api/userbase.api";
import RiskSummaryTable from "../../vendorrisk/components/RiskSummaryTable";
import OrgScoresChart from "../components/OrgScoresChart";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import { IRisk } from "../../vendorrisk/components/RiskSummaryDetails";
import { createRiskModificationUrl } from "../UserBaseAppRouter";
import { CreateRiskModificationLocationState } from "./CreateRiskModificationView";
import { trackEvent } from "../../_common/tracking";
import "./RiskProfileView.scss";

type IRiskProfileViewProps = DefaultRouteProps;

const RiskProfileView: FC<IRiskProfileViewProps> = ({ location }) => {
  const history = useHistory();

  const [includePassedRisks, setIncludePassedRisks] = useState(false);
  const [showWaivedRisks, setShowWaivedRisks] = useState(false);

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const { data, isLoading } = userbaseApi.useGetUserBaseRisksV1Query();
  const { data: riskWaiverData, isLoading: isRiskWaiversLoading } =
    userbaseApi.useGetUserBaseRiskWaiversV1Query();

  const pageHeaderBackAction = useMemo(
    () =>
      location.state?.backContext?.goBack
        ? history.goBack
        : location.state?.backContext
          ? () =>
              history.push(
                location.state.backContext?.backTo ?? "",
                location.state.backContext?.backToContext
              )
          : undefined,
    [history, location.state?.backContext]
  );

  const getUrlPrefix = () => "/userbase";

  const onCreateWaiverForRisk = useCallback(
    (risk: IRisk) => {
      const context: CreateRiskModificationLocationState = { riskId: risk.id };

      history.push(createRiskModificationUrl, {
        backContext: {
          goBack: true,
          backToText: `Back to Risk Profile`,
        },
        ...context,
      });
    },
    [history]
  );

  const onIncludePassedRisk = (val: boolean) => {
    setIncludePassedRisks(val);
    trackEvent("UserBase_IncludePassedRisks_Toggled", { value: val });
  };

  const onShowWaivedRisks = (val: boolean) => {
    setShowWaivedRisks(val);
    trackEvent("UserBase_ShowWaivedRisks_Toggled", { value: val });
  };

  return (
    <div id="risk_profile">
      <PageHeader
        history={history}
        title="Risk Profile"
        backAction={pageHeaderBackAction}
        backText={location.state?.backContext?.backToText ?? undefined}
        infoSectionPageKey={"userbaseRiskProfile"}
        infoSection={
          <p>
            Risk Profile summarizes the user-related risks in your organization.
            You can monitor your security rating over time, drill into
            individual risks and manage them to improve your security posture.
          </p>
        }
      />
      <OrgScoresChart />
      <RiskSummaryTable
        className={"user-base-risk-profile"}
        history={history}
        loading={isLoading || isRiskWaiversLoading}
        title="Risk details"
        risks={data?.risks ?? []}
        filterActive={false}
        urlPrefix={getUrlPrefix}
        canManageVendorRiskWaivers={userHasUserBaseWrite}
        userHasWriteRiskWaiversPermission={userHasUserBaseWrite}
        hideShowWaivedRisksOption={false}
        hideShowPassedChecksOption={false}
        includePassedRisks={includePassedRisks}
        setIncludePassedRisks={onIncludePassedRisk}
        onCreateRiskWaiver={onCreateWaiverForRisk}
        showWaivedRisks={showWaivedRisks}
        onShowWaivedRisks={onShowWaivedRisks}
        customerRiskWaivers={riskWaiverData?.waivers}
      />
    </div>
  );
};

export default RiskProfileView;
