import PageHeader from "../../_common/components/PageHeader";
import Button from "../../_common/components/core/Button";
import {
  DefaultRouteProps,
  useDefaultHistory,
} from "../../_common/types/router";
import { getAuthFromLocalStorage } from "../../_common/reducers/commonActions";

import { FC, memo, useEffect, useState } from "react";
import {
  fetchExistingOAuthConnections,
  fetchNewOAuth2RequestURL,
  GOOGLE_SERVICE,
  MICROSOFT_SERVICE,
} from "../../vendorrisk/reducers/oauth.actions";
import { popupCenter } from "../../_common/helpers";
import {
  appConnect,
  useAppDispatch,
  useAppSelector,
} from "../../_common/types/reduxHooks";
import { getOAuthConnectionData } from "../../vendorrisk/reducers/oauth.selectors";
import { OAuthState } from "../../_common/types/oauth";
import "./AdminView.scss";
import userbaseApi from "../api/userbase.api";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import { canAccessSystemAdmin } from "../../_common/selectors/permissions";
import { isAdmin } from "../../vendorrisk/helpers/roles";

interface IAdminViewConnectedProps {
  googleConnections?: OAuthState;
  microsoftConnections?: OAuthState;
}

type IAdminViewOwnProps = DefaultRouteProps;

type IAdminViewProps = IAdminViewConnectedProps & IAdminViewOwnProps;

const AdminView: FC<IAdminViewProps> = ({
  googleConnections,
  microsoftConnections,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const userData = useAppSelector((state) => state.common.userData);
  const isUserAdmin = isAdmin(userData.currentOrgRoles);
  const isUserSystemAdmin = useAppSelector((state) =>
    canAccessSystemAdmin(state)
  );
  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const [recalculateRisksMutation] =
    userbaseApi.useRecalculateUserBaseRisksV1Mutation();

  useEffect(() => {
    dispatch(fetchExistingOAuthConnections(GOOGLE_SERVICE));
    dispatch(fetchExistingOAuthConnections(MICROSOFT_SERVICE));
  }, [dispatch]);

  const oauthLoading = googleConnections?.loading ?? true;
  const showSetupButton =
    isUserAdmin &&
    !oauthLoading &&
    (googleConnections?.connections?.length ?? 0) === 0 &&
    (microsoftConnections?.connections?.length ?? 0) === 0;
  const hasGoogle = (googleConnections?.connections?.length ?? 0) > 0;
  const hasMicrosoft365 = (microsoftConnections?.connections?.length ?? 0) > 0;
  const showFetchButtons =
    isUserSystemAdmin && !oauthLoading && (hasGoogle || hasMicrosoft365);

  const showRecalculateRisksButton = userHasUserBaseWrite;

  const fetchData = (service: string): void => {
    setLoading(true);
    const auth = getAuthFromLocalStorage();
    window.open(
      `/api/userbase/fetch_data/v1?&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}&service=${encodeURIComponent(
        service
      )}`
    );
    setLoading(false);
  };

  const fetchDriveActivities = (): void => {
    setLoading(true);
    const auth = getAuthFromLocalStorage();
    window.open(
      `/api/userbase/fetch_activities/v1?&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}`
    );
    setLoading(false);
  };

  const fetchDriveFiles = (): void => {
    setLoading(true);
    const auth = getAuthFromLocalStorage();
    window.open(
      `/api/userbase/fetch_files/v1?&apikey=${encodeURIComponent(
        auth.apiKey
      )}&token=${encodeURIComponent(auth.token)}`
    );
    setLoading(false);
  };

  const startAuthFlow = async (service: string): Promise<void> => {
    setLoading(true);

    const { url }: { url: string } = await dispatch(
      fetchNewOAuth2RequestURL(service)
    );

    popupCenter(url, service, 800, 800);

    // While the user follows the prompts in the popup window, we'll sit here polling for OAuth2 data
    // until it's done.
    const timer = window.setInterval(async () => {
      try {
        const resp = await dispatch(
          fetchExistingOAuthConnections(service, "", true, true, true)
        );

        if (resp && resp.connections.length > 0) {
          window.clearInterval(timer);
          setLoading(false);
          history.push("/userbase/admin");
        }

        // TODO cap the time for how long we wait, in case there's any kind of problem with the oauth flow
      } catch (e) {
        console.log(`Caught an exception: ${JSON.stringify(e)}`);
      }
    }, 1000);
  };

  const recalculate = () => {
    setLoading(true);

    recalculateRisksMutation()
      .unwrap()
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert("An error occurred recalculating risks")
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="userbase-admin">
      <PageHeader
        history={history}
        title="Admin"
        infoSection={<>UserRisk Admin.</>}
      />

      {showSetupButton && (
        <>
          <Button
            key="start-google-oauth"
            loading={loading}
            onClick={() => startAuthFlow("Google")}
          >
            Create Google OAuth Connection
          </Button>
          <Button
            key="start-microsoft-oauth"
            loading={loading}
            onClick={() => startAuthFlow("Microsoft365")}
          >
            Create Microsoft 365 OAuth Connection
          </Button>
        </>
      )}
      {showFetchButtons && (
        <Button
          key="start-fetch"
          loading={loading}
          onClick={() =>
            fetchData(hasGoogle ? "Google" : hasMicrosoft365 ? "Microsoft" : "")
          }
        >
          Fetch User and Token Data
        </Button>
      )}
      {showFetchButtons && hasGoogle && (
        <>
          <Button
            key="start-fetch-activities"
            loading={loading}
            onClick={fetchDriveActivities}
          >
            Fetch Google Drive Activities
          </Button>
          <Button
            key="start-fetch-files"
            loading={loading}
            onClick={fetchDriveFiles}
          >
            Fetch Google Drive Files
          </Button>
        </>
      )}
      {showRecalculateRisksButton && (
        <Button key="recalculate" loading={loading} onClick={recalculate}>
          Recalculate Risks
        </Button>
      )}
    </div>
  );
};

export default appConnect<IAdminViewConnectedProps, never, IAdminViewOwnProps>(
  (state): IAdminViewConnectedProps => {
    return {
      googleConnections: getOAuthConnectionData(state, GOOGLE_SERVICE),
      microsoftConnections: getOAuthConnectionData(state, MICROSOFT_SERVICE),
    };
  }
)(memo(AdminView));
