import { Component, createRef, FormEvent, ReactNode, RefObject } from "react";
import { History } from "history";
import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import { addCustomerCloudscan } from "../../reducers/cyberRiskActions";
import { trackEvent } from "../../../_common/tracking";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../../_common/types/redux";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import {
  addCustomIPRange,
  editCustomIPRange,
  fetchCustomerIPAddresses,
  fetchVendorIPAddresses,
  ipAddressesState,
} from "../../reducers/ipAddresses.actions";
import { isIPAddress } from "../../../_common/helpers";
import classnames from "classnames";
import "../../style/components/AddIPAddressesModal.scss";
import InfoBanner, { BannerType } from "../InfoBanner";
import {
  IPAddress,
  IPRange,
  ipToInt,
} from "../../../_common/types/ipAddresses";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import { CloseSidePanel } from "../DomainsAndIPsPanelGroup";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { appConnect } from "../../../_common/types/reduxHooks";

export const AddIPAddressesModalName = "AddIPAddressesModalName";

interface IAddIPAddressesModalData {
  mode: Mode;
  ipRangeToEdit?: IPRange;
  history: History;
  vendorId?: number;
}

interface IAddIPAddressesModalOwnProps {
  dispatch: DefaultThunkDispatch;
  modalData: IAddIPAddressesModalData;
}

interface IAddIPAddressesConnectedProps {
  ipAddresses?: IPAddress[];
  ipRanges?: IPRange[];
}

type IAddIPAddressesModalProps = IAddIPAddressesModalOwnProps &
  IAddIPAddressesConnectedProps &
  DefaultThunkDispatchProp;

export enum Mode {
  SingleIP,
  AddIPRange,
  EditIPRange,
}

interface IAddIPAddressesModalState {
  loading: boolean;
  mode: Mode;
  singleIP: string;
  startIP: string;
  endIP: string;
  validating: boolean;
}

class AddIPAddressesModal extends Component<
  IAddIPAddressesModalProps,
  IAddIPAddressesModalState
> {
  constructor(props: IAddIPAddressesModalProps) {
    super(props);
    this.state = {
      loading: false,
      mode: props.modalData.mode,
      singleIP: "",
      startIP: props.modalData.ipRangeToEdit?.start || "",
      endIP: props.modalData.ipRangeToEdit?.end || "",
      validating: false,
    };
  }

  componentDidMount() {
    if (this.props.modalData.vendorId) {
      this.props.dispatch(
        fetchVendorIPAddresses(this.props.modalData.vendorId)
      );
    } else {
      this.props.dispatch(fetchCustomerIPAddresses());
    }
  }

  onSubmit = async (evt: FormEvent): Promise<void> => {
    evt.preventDefault();

    if (this.validationError()) {
      this.setState({ validating: true });
      return;
    }

    trackEvent("AddIPAddresses");

    const { dispatch, modalData } = this.props;
    const { ipRangeToEdit, vendorId } = modalData;
    const { mode, singleIP, startIP, endIP } = this.state;

    this.setState({ loading: true });

    try {
      if (mode === Mode.SingleIP) {
        await dispatch(addCustomerCloudscan(singleIP, vendorId));
      } else if (mode === Mode.AddIPRange) {
        await dispatch(addCustomIPRange(startIP, endIP, vendorId));
        dispatch(
          addDefaultSuccessAlert("IP range has been added", [
            "IP addresses within the range will be added as soon as data is detected.",
          ])
        );
      } else if (mode === Mode.EditIPRange && ipRangeToEdit?.customID) {
        await dispatch(
          editCustomIPRange(ipRangeToEdit.customID, startIP, endIP, vendorId)
        );

        // close the side panel in case that's where we came from
        !!this.props.modalData.history &&
          CloseSidePanel(this.props.modalData.history);
      }
      dispatch(closeModal());
    } catch (e: any) {
      console.log("error", e);
      this.setState({ loading: false });
      if (typeof e === "string") {
        dispatch(addDefaultWarningAlert(e));
      } else {
        dispatch(
          addDefaultUnknownErrorAlert(`Error adding IP addresses: ${e.message}`)
        );
      }
    }
  };

  validationError = (): ReactNode => {
    const { mode, singleIP, startIP, endIP } = this.state;
    const { modalData, ipAddresses, ipRanges } = this.props;

    if (mode === Mode.SingleIP) {
      if (!isIPAddress(singleIP)) {
        return "IP address is invalid";
      }

      if (ipAddresses?.some((ip) => ip.ip === singleIP)) {
        return `${singleIP} is already listed`;
      }

      // make sure this IP is not included in a range already being monitored
      const ipInt = ipToInt(singleIP);
      const parentRange = ipRanges?.find((r) => {
        if (!r.customID) {
          return false;
        }
        return ipToInt(r.start) <= ipInt && ipInt <= ipToInt(r.end);
      });

      if (parentRange) {
        return (
          <>
            IP is already included in custom range: {parentRange.start} -{" "}
            {parentRange.end}
          </>
        );
      }
    } else {
      if (!isIPAddress(startIP) && !isIPAddress(endIP)) {
        return "IP addresses are invalid";
      }
      if (!isIPAddress(startIP)) {
        return "Start IP address is invalid";
      }
      if (!isIPAddress(endIP)) {
        return "End IP address is invalid";
      }

      const startInt = ipToInt(startIP),
        endInt = ipToInt(endIP);

      if (startInt >= endInt) {
        return "End IP must be greater than start IP";
      }

      // make sure this range does not overlap with any existing custom ranges
      const overlappingRange = ipRanges?.find((r) => {
        if (!r.customID) {
          return false;
        }

        // make sure we don't identify the range we're editing as "overlapping"
        if (
          modalData.mode === Mode.EditIPRange &&
          modalData.ipRangeToEdit?.customID === r.customID
        ) {
          return false;
        }

        return ipToInt(r.start) <= endInt && ipToInt(r.end) >= startInt;
      });

      if (overlappingRange) {
        return (
          <>
            Range overlaps with existing range {overlappingRange.owner}:{" "}
            {overlappingRange.start} - {overlappingRange.end}
          </>
        );
      }

      if (
        modalData.mode === Mode.EditIPRange &&
        startIP === modalData.ipRangeToEdit?.start &&
        endIP === modalData.ipRangeToEdit?.end
      ) {
        return "IP range is unchanged";
      }
    }

    return null;
  };

  render() {
    const { mode, singleIP, startIP, endIP, loading, validating } = this.state;

    const validationError = this.validationError();

    if (!this.props.ipAddresses) {
      return <LoadingBanner />;
    }

    return (
      <div className="add-ip-addresses-modal">
        <form onSubmit={this.onSubmit} className={classnames({ validating })}>
          <div className="modal-header">
            <h2>
              {mode === Mode.EditIPRange ? "Edit IP range" : "Add IP addresses"}
            </h2>
          </div>
          <div className="modal-content">
            {mode !== Mode.EditIPRange && (
              <>
                <div className="mode-option">
                  <ColorCheckbox
                    small
                    radio
                    onClick={() =>
                      this.setState({ mode: Mode.SingleIP, validating: false })
                    }
                    checked={mode === Mode.SingleIP}
                    label="Single IP address"
                  />
                </div>
                <div className="mode-option">
                  <ColorCheckbox
                    small
                    radio
                    onClick={() =>
                      this.setState({
                        mode: Mode.AddIPRange,
                        validating: false,
                      })
                    }
                    checked={mode === Mode.AddIPRange}
                    label="Range of IP addresses"
                  />
                </div>
              </>
            )}
            {mode === Mode.SingleIP && (
              <>
                <h4 className="ip-mode-title">Enter an IP address</h4>
                <input
                  name="single-ip"
                  type="text"
                  className={classnames({ invalid: !isIPAddress(singleIP) })}
                  required
                  placeholder="0.0.0.0"
                  onChange={(evt) =>
                    this.setState({ singleIP: evt.target.value })
                  }
                  value={singleIP}
                />
              </>
            )}
            {mode === Mode.AddIPRange && (
              <>
                <h4 className="ip-mode-title">Enter an IP range</h4>
                <p>
                  The range can include up to 256 IPs from a single block (/24).
                </p>
                <IPRangeInput
                  startIP={startIP}
                  endIP={endIP}
                  onChange={(startIP, endIP) =>
                    this.setState({ startIP, endIP })
                  }
                />
              </>
            )}
            {mode === Mode.EditIPRange && (
              <IPRangeInput
                startIP={startIP}
                endIP={endIP}
                onChange={(startIP, endIP) => this.setState({ startIP, endIP })}
              />
            )}
          </div>
          <div className="modal-footer">
            {validating && !!validationError && (
              <div className="validation-container">
                <InfoBanner type={BannerType.ERROR} message={validationError} />
              </div>
            )}
            <Button
              id="add-ips-cancel"
              onClick={() => this.props.dispatch(closeModal())}
              tertiary
            >
              Cancel
            </Button>
            <Button
              id="add-ips"
              type="submit"
              primary
              loading={loading}
              disabled={loading || (validating && !!validationError)}
            >
              {mode === Mode.EditIPRange ? "Save" : "Add"}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

interface IIPRangeInputProps {
  startIP: string;
  endIP: string;
  onChange: (startIP: string, endIP: string) => void;
}

export const IPRangeInput = ({
  startIP,
  endIP,
  onChange,
}: IIPRangeInputProps) => {
  const startOctets = startIP ? startIP.split(".") : ["", "", "", ""];
  const endOctets = endIP ? endIP.split(".") : ["", "", "", ""];

  const startOctetRefs: RefObject<HTMLInputElement>[] = [
    createRef(),
    createRef(),
    createRef(),
    createRef(),
  ];

  return (
    <div className="ip-range-input">
      <div className="ip-range-input-start">
        <div className="ip-range-input-label">Start</div>
        {startOctets.map((o, i) => (
          <div key={i} className="ip-range-octet">
            <input
              type="text"
              className={classnames({ invalid: !octetValid(o) })}
              required
              ref={startOctetRefs[i]}
              placeholder={startIP ? "" : "0"}
              maxLength={3}
              onChange={(evt) => {
                startOctets[i] = evt.target.value;
                if (i < 3) {
                  endOctets[i] = evt.target.value;
                }
                onChange(startOctets.join("."), endOctets.join("."));
              }}
              onPaste={(evt) => {
                const pastedText = evt.clipboardData.getData("Text");
                if (i === 0 && isIPAddress(pastedText)) {
                  evt.preventDefault();
                  onChange(pastedText, pastedText);
                  startOctetRefs[3]?.current?.focus();
                } else if (pastedText.includes(".")) {
                  evt.preventDefault();
                }
              }}
              onKeyDown={(evt) => {
                // when user presses ".", move to next octet
                if (evt.key === ".") {
                  evt.preventDefault();
                  startOctetRefs[i + 1]?.current?.focus();
                }
              }}
              value={o}
            />
          </div>
        ))}
      </div>
      <div className="ip-range-input-end">
        <div className="ip-range-input-label">End</div>
        {endOctets.map((o, i) => (
          <div key={i} className="ip-range-octet">
            <input
              type="text"
              className={classnames({ invalid: i === 3 && !octetValid(o) })}
              required
              disabled={i < 3}
              placeholder={endIP ? "" : "0"}
              maxLength={3}
              onChange={(evt) => {
                endOctets[i] = evt.target.value;
                onChange(startIP, endOctets.join("."));
              }}
              onKeyDown={(evt) => evt.key === "." && evt.preventDefault()}
              value={o}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const octetValid = (o: string): boolean => {
  if (!o) {
    return false;
  }
  const num = parseInt(o, 10);
  return !isNaN(num) && num >= 0 && num <= 255;
};

export default appConnect<
  IAddIPAddressesConnectedProps,
  never,
  IAddIPAddressesModalOwnProps
>((state, props) => {
  const data: ipAddressesState | undefined = props.modalData.vendorId
    ? state.cyberRisk.vendors[props.modalData.vendorId]?.ipAddresses
    : state.cyberRisk.customerData?.ipAddresses;

  return {
    ipAddresses: data?.ipAddresses,
    ipRanges: data?.ipRanges,
  };
})(AddIPAddressesModal);
