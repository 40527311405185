import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import {
  fetchUserInitiatedExports,
  SET_SCHEDULED_EXPORT_ITEMS,
} from "../../_common/reducers/commonActions";
import { getFiltersHash } from "./cyberRiskSelectors";
import {
  DisplayableExportSchedule,
  ExportFiletype,
  ExportSectionData,
  ExportType,
  Section,
} from "../../_common/types/exportReport";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { Filters, WebsiteStatusFilter } from "../components/filter/types";
import { getFiltersFromState } from "./filters.actions";
import { GetTimeUnitFromDateRange } from "../../_common/types/dataLeaks";
import { VendorRiskAssessmentSummary } from "../constants/reportTypes";
import { ConfiguredExportConfig } from "../../_common/types/exportConfig";
import { getDefaultFilters } from "./defaults";

// ====================
// Redux Methods
// ====================

export const CLEAR_EXPORT_SECTIONS = "CLEAR_EXPORT_SECTIONS";

export const clearExportSections = () => ({
  type: CLEAR_EXPORT_SECTIONS,
});

export const SET_EXPORT_SECTIONS = "SET_EXPORT_SECTIONS";

const setExportSections = (
  exportType: ExportType,
  filtersHash: string | null,
  vendorId: number | undefined,
  isSubsidiary: boolean,
  loading: boolean,
  error: never | null,
  data: ExportSectionData | null
) => ({
  type: SET_EXPORT_SECTIONS,
  loading,
  error,
  data,
  exportType,
  filtersHash,
  vendorId,
  isSubsidiary,
});

const setScheduledExports = (
  loading: boolean,
  scheduledExports?: DisplayableExportSchedule[]
) => ({
  type: SET_SCHEDULED_EXPORT_ITEMS,
  scheduledExports,
  loading,
});

// ====================
// HTTP Methods
// ====================

const getFilterParamsForExport = (
  filters: Filters,
  overrideFilters?: Partial<Filters>
) => ({
  vendor_label_ids: filters.vendorLabelIds,
  vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
  vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
  website_label_ids: filters.websiteLabelIds,
  website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
  website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
  website_include_unlabeled: filters.websiteIncludeUnlabeled,
  website_portfolio_ids: filters.domainPortfolioIds,
  include_unlabeled: filters.includeUnlabeled,
  min_score: filters.minScore,
  max_score: filters.maxScore,
  vendor_ids: filters.vendorIds,
  risk_ids: filters.riskIds,
  risk_categories: filters.riskCategories,
  vulns_cve_names: filters.vulnsCveNames,
  vulns_cpe_names: filters.vulnsSoftware.map((cpe) => cpe.name),
  vulns_exclude_verified: filters.vulnsExcludeVerified,
  vulns_exclude_unverified: filters.vulnsExcludeUnverified,
  vulns_only_kev: filters.vulnsOnlyKEV,
  vulns_excluded_cvss_severities: filters.vulnsExcludedSeverities,
  vulns_epss_score_range_lower: filters.vulnsEPSSScores?.lowerVal,
  vulns_epss_score_range_upper: filters.vulnsEPSSScores?.upperVal,
  ignore_reasons: filters.typosquatReasonIds,
  permutation_types: filters.typosquatTypeIds,
  ignore_reasons_do_not_match: filters.typosquatReasonIdsDoNotMatch,
  permutation_types_do_not_match: filters.typosquatTypeIdsDoNotMatch,
  malicious_activities: filters.typosquatMaliciousActivities,
  malicious_activities_not_in: filters.typosquatMaliciousActivitiesDoNotMatch,
  subsidiary_ids: filters.subsidiaryIds,
  subsidiary_label_ids: filters.subsidiaryLabelIds,
  subsidiary_min_score: filters.subsidiaryMinScore,
  subsidiary_max_score: filters.subsidiaryMaxScore,
  subsidiary_countries: filters.subsidiaryCountries,
  vendor_tiers: filters.vendorTiers,
  vendor_assessment_classifications: filters.vendorAssessmentClassifications,
  vendor_assessment_authors: filters.vendorAssessmentAuthors,
  vendor_assessment_authors_not: filters.vendorAssessmentAuthorDoNotMatch,
  vendor_reassessment_start: filters.vendorReassessmentStartDate,
  vendor_reassessment_end: filters.vendorReassessmentEndDate,
  vendor_reassessment_before: filters.vendorReassessmentDateBefore,
  vendor_reassessment_between: filters.vendorReassessmentDateBetween,
  portfolio_ids: filters.portfolioIds,
  data_leaks_filter_start_date:
    filters.dataLeaksReportedDate?.startDate?.format("YYYY-MM-DD"),
  data_leaks_filter_end_date:
    filters.dataLeaksReportedDate?.endDate?.format("YYYY-MM-DD"),
  data_leaks_filter_group_type:
    filters.dataLeaksReportedDate?.startDate &&
    filters.dataLeaksReportedDate?.endDate
      ? GetTimeUnitFromDateRange(
          filters.dataLeaksReportedDate?.startDate,
          filters.dataLeaksReportedDate?.endDate
        )
      : undefined,
  // since the attribute filters are complex we serialise them to json
  attributes: JSON.stringify(filters.selectedAttributes),
  selected_survey_statuses: filters.selectedSurveyStatuses.join(","),
  selected_survey_attributes: filters.selectedSurveyAttributes.join(","),
  survey_type_ids: filters.vendorSurveyTypes,
  evidence_type_ids: filters.vendorEvidenceTypes,
  fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
  tprms_customer_names: filters.orgNames
    ? filters.orgNames.map((org) => org.value)
    : [],
  tprms_excluded_service_levels: filters.excludedServiceLevels,
  tprms_included_service_statuses: filters.includedServiceStatuses,
  appguard_cloud_providers: filters.cloudConnectionProviderTypes,
  appguard_cloud_connection_uuids: filters.cloudConnectionUUIDs,
  ...overrideFilters,
});

export interface IExportReportParams {
  exportType: ExportType;
  fileType: ExportFiletype;
  cannedReportName?: string;
  exportOptions: Record<string, unknown>;
  applyFilters: boolean;
  sections?: Section[];
  config?: ConfiguredExportConfig;
  overrideFilters?: Partial<Filters>; // Optional object to override filters in state
  emailRecipients?: string[];
  emailRecipientIDs?: number[];
  vendorId?: number;
  isSubsidiary?: boolean;
  portfolioIdsNotInState?: number[];
  isCustom?: boolean;
  ignoreFiltersFromState?: boolean;
  orgIsFreeTrial?: boolean;
  selectedFields?: string[];
}

export const exportReport =
  ({
    exportType,
    fileType,
    cannedReportName,
    exportOptions,
    applyFilters,
    sections,
    config,
    overrideFilters,
    emailRecipients,
    vendorId,
    isSubsidiary,
    isCustom,
    ignoreFiltersFromState,
    selectedFields,
  }: IExportReportParams) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    const state = getState();
    const filters = !ignoreFiltersFromState
      ? getFiltersFromState(state, vendorId, isSubsidiary)
      : getDefaultFilters();
    const params = {
      ...(applyFilters
        ? getFilterParamsForExport(filters, overrideFilters)
        : {}),
      ...exportOptions,
      export_type: exportType,
      file_type: fileType,
      canned_report_name: cannedReportName,
      email_recipients: emailRecipients,
      status_filter: filters.websiteStatus,
      is_custom: isCustom,
      selected_fields: selectedFields,
      is_subsidiary: isSubsidiary,
    };

    let body = JSON.stringify({});
    if (sections) {
      body = JSON.stringify({ sections });
    } else if (config) {
      body = JSON.stringify({ config });
    }

    try {
      await FetchCyberRiskUrl(
        "export/v2",
        params,
        { method: "POST", body },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error requesting export");

      throw e;
    }

    // Now refresh the pending exports
    await dispatch(fetchUserInitiatedExports(true));
  };

export interface IFetchExportSectionsParams {
  exportType: ExportType;
  fileType?: ExportFiletype;
  exportOptions: Record<string, unknown>;
  applyFilters: boolean;
  overrideFilters?: Partial<Filters>; // Optional object to override filters in state
  vendorId?: number;
  isSubsidiary?: boolean;
  isIncludingSubsidiaries?: boolean;
}

export const fetchExportSections =
  ({
    exportType,
    fileType,
    exportOptions,
    applyFilters,
    overrideFilters,
    vendorId,
    isSubsidiary,
    isIncludingSubsidiaries,
  }: IFetchExportSectionsParams) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    const state = getState();
    const filters: Filters = getFiltersFromState(state, vendorId, isSubsidiary);
    const filtersHash = getFiltersHash(
      state,
      applyFilters,
      exportOptions,
      vendorId,
      isSubsidiary,
      overrideFilters
    );

    dispatch(
      setExportSections(
        exportType,
        filtersHash,
        vendorId,
        isSubsidiary ?? false,
        true,
        null,
        null
      )
    );

    const params = {
      ...(applyFilters
        ? {
            website_label_ids: filters.websiteLabelIds,
            website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
            website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
            website_include_unlabeled: filters.websiteIncludeUnlabeled,
            website_portfolio_ids: filters.domainPortfolioIds,
            vendor_label_ids: filters.vendorLabelIds,
            vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
            vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
            include_unlabeled: filters.includeUnlabeled,
            min_score: filters.minScore,
            max_score: filters.maxScore,
            vendor_ids: filters.vendorIds,
            risk_ids: filters.riskIds,
            risk_categories: filters.riskCategories,
            vendor_id: vendorId,
            is_subsidiary: isSubsidiary,
            is_including_subsidiaries: isIncludingSubsidiaries,
            // since the attribute filters are complex we serialise them to json
            attributes: JSON.stringify(filters.selectedAttributes),
            survey_type_ids: filters.vendorSurveyTypes,
            evidence_type_ids: filters.vendorEvidenceTypes,
            fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
            ...overrideFilters,
          }
        : {
            is_subsidiary: isSubsidiary,
          }),
      ...exportOptions,
      export_type: exportType,
      file_type: fileType,
      status_filter: filters.websiteStatus,
    };

    let json: ExportSectionData;
    try {
      json = await FetchCyberRiskUrl(
        "export/sections/v2",
        params,
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error requesting export sections");

      dispatch(
        setExportSections(
          exportType,
          filtersHash,
          vendorId,
          isSubsidiary ?? false,
          false,
          e as any,
          null
        )
      );
      throw e;
    }

    dispatch(
      setExportSections(
        exportType,
        filtersHash,
        vendorId,
        isSubsidiary ?? false,
        false,
        null,
        json
      )
    );
  };

export interface IScheduleExportReportParams extends IExportReportParams {
  first_run: string;
  interval: string;
  period: number;
  expires_at?: string; // currently unused
  ignoreFiltersFromState?: boolean;
  orgIsFreeTrial?: boolean;
}

export const scheduleExport =
  ({
    exportType,
    fileType,
    cannedReportName,
    exportOptions,
    applyFilters,
    sections,
    config,
    overrideFilters,
    first_run,
    interval,
    period,
    expires_at,
    emailRecipients,
    vendorId,
    isSubsidiary,
    ignoreFiltersFromState,
    selectedFields,
  }: IScheduleExportReportParams) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    const filters = !ignoreFiltersFromState
      ? getFiltersFromState(getState(), vendorId, isSubsidiary)
      : getDefaultFilters();
    const params = {
      ...(applyFilters
        ? getFilterParamsForExport(filters, overrideFilters)
        : {}),
      ...exportOptions,
      export_type: exportType,
      file_type: fileType,
      canned_report_name: cannedReportName,
      first_run: first_run,
      interval: interval,
      period: period,
      expires_at: expires_at,
      email_recipients: emailRecipients,
      status_filter: filters.websiteStatus,
      selected_fields: selectedFields,
    };

    let body = JSON.stringify({});
    if (sections) {
      body = JSON.stringify({ sections });
    } else if (config) {
      body = JSON.stringify({ config });
    }

    try {
      await FetchCyberRiskUrl(
        "schedule_export/v1",
        params,
        { method: "POST", body },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error scheduling export");

      throw e;
    }

    // re-load the scheduled exports
    dispatch(getScheduledExports());
  };

export const scheduleExecSummaryReport =
  (
    selectedReportConfig: Record<string, unknown>,
    useFilters: boolean,
    first_run: string,
    interval: string,
    period: number,
    emailRecipients?: string[],
    expires_at?: string,
    isIncludingSubsidiaries?: boolean,
    isOnlySubsidiaries?: boolean
  ) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    const { filters } = getState().cyberRisk.customerData;
    const opts = {
      ...selectedReportConfig,
      ...(useFilters ? getFilterParamsForExport(filters) : {}),
      is_including_subsidiaries: isIncludingSubsidiaries,
      subsidiaries_only: isOnlySubsidiaries,
      first_run: first_run,
      interval: interval,
      period: period,
      expires_at: expires_at,
      email_recipients: emailRecipients,
    };
    try {
      await FetchCyberRiskUrl(
        "reports/export/schedule/pdf/v1",
        opts,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error scheduling exec summary pdf export");

      throw e;
    }
  };

interface GetScheduledExportsResponse {
  scheduledExports: DisplayableExportSchedule[];
  status: string;
  more: boolean;
}

export const getScheduledExports =
  () =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    dispatch(setScheduledExports(true));
    let json: GetScheduledExportsResponse;
    try {
      json = await FetchCyberRiskUrl(
        "schedule_export/v1",
        {},
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting scheduled exports");

      throw e;
    }

    dispatch(setScheduledExports(false, json.scheduledExports));
  };

export const editScheduledExport =
  (
    schedule_id: number,
    next_run: string,
    interval: string,
    period: number,
    email_recipients: string[]
  ) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      await FetchCyberRiskUrl(
        "scheduled_export/v1",
        {
          schedule_id,
          next_run,
          interval,
          period,
          email_recipients,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error modifying scheduled export.");

      throw e;
    }
  };

export const deleteScheduledExport =
  (schedule_id: number) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    try {
      await FetchCyberRiskUrl(
        "scheduled_export/v1",
        {
          schedule_id,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting scheduled export.");

      throw e;
    }
  };

//
// Support for the new executive report for breachsight and vendors, including asynchronous schedule and email recipients
//
export const submitConfigurablePDFSummaryReportRequest =
  (
    selectedReport: string,
    selectedOptions: Record<string, any>,
    scheduleExport: boolean,
    scheduleInterval: string,
    firstRun: string,
    emailReport: boolean,
    emailRecipients: string[],
    isSummaryReport: boolean,
    isAssessmentReport: boolean,
    vendorId: number | undefined = undefined
  ) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    try {
      const reportOptions: Record<string, any> = {
        ...selectedOptions,
        export_type: selectedReport,
        file_type: ExportFiletype.PDF,
        first_run: firstRun,
        interval: scheduleExport ? scheduleInterval : undefined,
        period: scheduleInterval ? 1 : undefined,
        email_recipients: emailReport ? emailRecipients : undefined,
        vendor_id: vendorId,
        status_filter: WebsiteStatusFilter.All,
        configurable_report_type: isSummaryReport
          ? "Summary"
          : isAssessmentReport
            ? "Assessment"
            : "Detailed",
      };
      const reportSections = { sections: [] };
      if (scheduleExport) {
        await FetchCyberRiskUrl(
          "schedule_export/v1",
          reportOptions,
          { method: "POST", body: JSON.stringify(reportSections) },
          dispatch,
          getState
        );
      } else {
        await FetchCyberRiskUrl(
          "export/v2",
          reportOptions,
          { method: "POST", body: JSON.stringify(reportSections) },
          dispatch,
          getState
        );
      }
    } catch (e) {
      LogError("error scheduling executive summary pdf export");

      throw e;
    }

    // Now refresh the pending exports
    await dispatch(fetchUserInitiatedExports(true));
  };

//
// Support for the new risk assessment status report
//
export const submitConfigurablePDFRiskAssessmentReportRequest =
  (
    selectedPortfolioIds: number[],
    selectedAssessmentClassificationIds: number[],
    scheduleExport: boolean,
    scheduleInterval: string,
    firstRun: string,
    emailReport: boolean,
    emailRecipients: string[],
    useFilters: boolean
  ) =>
  async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<void> => {
    let filters: Filters = {} as Filters;
    if (useFilters) {
      filters = getFiltersFromState(getState());
    }
    try {
      const reportOptions = {
        portfolio_ids: selectedPortfolioIds,
        vendor_assessment_classifications: selectedAssessmentClassificationIds,
        vendor_assessment_authors: useFilters
          ? filters.vendorAssessmentAuthors
          : null,
        vendor_assessment_authors_not: useFilters
          ? filters.vendorAssessmentAuthorDoNotMatch
          : null,
        vendor_reassessment_start: useFilters
          ? filters.vendorReassessmentStartDate
          : null,
        vendor_reassessment_end: useFilters
          ? filters.vendorReassessmentEndDate
          : null,
        vendor_reassessment_before: useFilters
          ? filters.vendorReassessmentDateBefore
          : null,
        vendor_reassessment_between: useFilters
          ? filters.vendorReassessmentDateBetween
          : null,
        vendor_tiers: useFilters ? filters.vendorTiers : null,
        vendor_label_ids: useFilters ? filters.vendorLabelIds : null,
        vendor_label_ids_match_all: useFilters
          ? filters.vendorLabelIdsMatchAll
          : null,
        vendor_label_ids_do_not_match: filters
          ? filters.vendorLabelIdsDoNotMatch
          : null,
        include_unlabeled: useFilters ? filters.includeUnlabeled : null,
        min_score: useFilters ? filters.minScore : null,
        max_score: useFilters ? filters.maxScore : null,
        // since the attribute filters are complex we serialise them to json
        attributes: useFilters
          ? JSON.stringify(filters.selectedAttributes)
          : null,
        survey_type_ids: filters.vendorSurveyTypes,
        evidence_type_ids: filters.vendorEvidenceTypes,
        fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
        export_type: VendorRiskAssessmentSummary,
        file_type: ExportFiletype.PDF,
        first_run: firstRun,
        interval: scheduleExport ? scheduleInterval : undefined,
        period: scheduleInterval ? 1 : undefined,
        email_recipients: emailReport ? emailRecipients : undefined,
        status_filter: WebsiteStatusFilter.All,
      } as Record<string, any>;
      const reportSections = { sections: [] };

      if (scheduleExport) {
        await FetchCyberRiskUrl(
          "schedule_export/v1",
          reportOptions,
          { method: "POST", body: JSON.stringify(reportSections) },
          dispatch,
          getState
        );
      } else {
        await FetchCyberRiskUrl(
          "export/v2",
          reportOptions,
          { method: "POST", body: JSON.stringify(reportSections) },
          dispatch,
          getState
        );
      }
    } catch (e) {
      LogError("error scheduling assessment summary pdf export");

      throw e;
    }

    // Now refresh the pending exports
    await dispatch(fetchUserInitiatedExports(true));
  };
