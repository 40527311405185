import { FC } from "react";
import classnames from "classnames";
import "../../style/components/navigation/ExpandableNavigationContainer.scss";
import IconButton from "../../components/IconButton";
import HeightAdjustableContainer from "./HeightAdjustableContainer";

interface ExpandableNavigationContainer {
  className?: string;
  isOpen: boolean;
  onToggleNavigationOpen?: (isOpen: boolean) => void;
  children: React.ReactNode;
  disableFader?: boolean;
  fullContainerClickableWhenClosed?: boolean;
}

const ExpandableNavigationContainer: FC<ExpandableNavigationContainer> = (
  props
) => {
  return (
    <div
      className={classnames(
        "expandable-navigation-container",
        props.className,
        {
          open: props.isOpen,
        },
        { clickable: !props.isOpen && props.fullContainerClickableWhenClosed }
      )}
      onClick={
        props.fullContainerClickableWhenClosed
          ? () => {
              if (props.onToggleNavigationOpen && !props.isOpen) {
                props.onToggleNavigationOpen(!props.isOpen);
              }
            }
          : undefined
      }
    >
      <HeightAdjustableContainer disableFader={props.disableFader}>
        {props.children}
      </HeightAdjustableContainer>
      <div
        className={classnames("navigation-bottom", {
          open: props.isOpen,
        })}
      >
        <IconButton
          className={"navigation-expander"}
          icon={
            <div
              className={classnames("cr-icon-expander", "nav-expander-button", {
                "rotate-180": props.isOpen,
              })}
            />
          }
          onClick={() => {
            if (props.onToggleNavigationOpen) {
              props.onToggleNavigationOpen(!props.isOpen);
            }
          }}
        />
      </div>
    </div>
  );
};

export default ExpandableNavigationContainer;
