import classnames from "classnames";
import { useState } from "react";

import "../../style/components/VendorTierBadge.scss";
import IconButton from "../../../_common/components/IconButton";
import UpdateVendorTierModal from "./UpdateVendorTierModal";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

interface VendorTierBadgeProps {
  tier?: number;
  name?: string;
  large?: boolean;
  showName?: boolean;
  canUpdate?: boolean;
  className?: string;
  truncate?: boolean; // truncate the label at a certain width and display a popup
  noPopup?: boolean; // do not wrap in a SidePopupV2

  // If updating, which vendor ids should we apply the update to
  vendorIds?: number[];
}

const VendorTierBadge = (props: VendorTierBadgeProps) => {
  const [isShowingModal, setIsShowingModal] = useState(false);

  const classes = classnames("vendor-tier-badge-display", props.className, {
    interact: props.canUpdate,
  });
  const badgeClasses = classnames("vendor-tier-badge", { large: props.large });
  const nameClasses = classnames("name", {
    truncate: props.truncate,
  });

  let popupContent = "";

  if (!props.showName && props.name) {
    popupContent = props.name;
  } else if (!props.tier && props.canUpdate) {
    popupContent = "Assign a tier";
  }

  const isTierRecord = props.tier !== null && props.tier !== undefined;

  const innerContent = (
    <>
      {isTierRecord && (
        <div
          className={classes}
          onClick={
            props.canUpdate
              ? (ev) => {
                  setIsShowingModal(true);
                  ev.stopPropagation();
                }
              : undefined
          }
        >
          <>
            {!props.tier && (
              <div className={`${badgeClasses} untiered`}>
                <i className={"cr-icon-minus-circle"} />
              </div>
            )}
            {(props.tier || 0) > 0 && (
              <div className={badgeClasses}>
                <div className={"badge"}>{props.tier}</div>
              </div>
            )}
            {props.name && props.showName && (
              <div
                title={props.truncate ? props.name : undefined}
                className={nameClasses}
              >
                {props.name}
              </div>
            )}
          </>
        </div>
      )}
      {!isTierRecord && props.canUpdate && (
        <IconButton
          icon={<div className={"cr-icon-plus"} />}
          onClick={() => {
            setIsShowingModal(true);
          }}
        />
      )}
      {props.canUpdate && props.vendorIds && props.vendorIds.length > 0 && (
        <span onClick={(e) => e.stopPropagation()}>
          <UpdateVendorTierModal
            active={isShowingModal}
            onClose={() => {
              setIsShowingModal(false);
            }}
            selectedTier={props.tier}
            vendorIds={props.vendorIds}
          />
        </span>
      )}
    </>
  );

  return props.noPopup ? (
    <div className="vendor-tier-badge-container">{innerContent}</div>
  ) : (
    <SidePopupV2
      text={popupContent}
      position="top"
      noWrap
      className={"vendor-tier-badge-container"}
    >
      {innerContent}
    </SidePopupV2>
  );
};

export default VendorTierBadge;
