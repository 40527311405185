/*
 * This package is generated by gen.go, DO NOT MANUALLY EDIT.
 */

export enum RiskClassification {
  SecurityPoliciesAndProcesses = "security_policies_and_processes",
  SecurityPoliciesAndProcessesInformationSecurity = "security_policies_and_processes_information_security",
  SecurityPoliciesAndProcessesITOperationsManagement = "security_policies_and_processes_it_operations_management",
  SecurityPoliciesAndProcessesComplianceManagement = "security_policies_and_processes_compliance_management",
  SecurityPoliciesAndProcessesHumanResourcesSecurity = "security_policies_and_processes_human_resources_security",
  InfrastructureAndAssetManagement = "infrastructure_and_asset_management",
  InfrastructureAndAssetManagementAssetManagement = "infrastructure_and_asset_management_asset_management",
  InfrastructureAndAssetManagementNetworkSecurity = "infrastructure_and_asset_management_network_security",
  InfrastructureAndAssetManagementEmailSecurity = "infrastructure_and_asset_management_email_security",
  InfrastructureAndAssetManagementWebsiteSecurity = "infrastructure_and_asset_management_website_security",
  InfrastructureAndAssetManagementEndpointSecurity = "infrastructure_and_asset_management_endpoint_security",
  InfrastructureAndAssetManagementPhysicalSecurity = "infrastructure_and_asset_management_physical_security",
  DataClassificationAndHandling = "data_classification_and_handling",
  DataClassificationAndHandlingPrivacyManagement = "data_classification_and_handling_privacy_management",
  DataClassificationAndHandlingAccessManagement = "data_classification_and_handling_access_management",
  ApplicationSecurity = "application_security",
  ApplicationSecuritySoftwareDevelopment = "application_security_software_development",
  ApplicationSecurityVulnerabilityManagement = "application_security_vulnerability_management",
  RiskManagement = "risk_management",
  RiskManagementEnterprise = "risk_management_enterprise",
  RiskManagement3rdNthPartyManagement = "risk_management_third_to_nth_party_management",
  RiskManagementThreatManagement = "risk_management_threat_management",
  RecoveryAndResponse = "recovery_and_response",
  RecoveryAndResponseBusinessContinuity = "recovery_and_response_business_continuity",
  RecoveryAndResponseCybersecurityIncidentManagement = "recovery_and_response_cybersecurity_incident_management",
}

export function RiskClassificationString(
  c: RiskClassification | undefined,
  defaultString: string,
  enabled: boolean
): string {
  if (!enabled) {
    return defaultString;
  }

  switch (c) {
    case RiskClassification.SecurityPoliciesAndProcesses:
      return "Security policies and processes";
    case RiskClassification.SecurityPoliciesAndProcessesInformationSecurity:
      return "Information security";
    case RiskClassification.SecurityPoliciesAndProcessesITOperationsManagement:
      return "IT operations management";
    case RiskClassification.SecurityPoliciesAndProcessesComplianceManagement:
      return "Compliance management";
    case RiskClassification.SecurityPoliciesAndProcessesHumanResourcesSecurity:
      return "Human resources security";
    case RiskClassification.InfrastructureAndAssetManagement:
      return "Infrastructure and asset management";
    case RiskClassification.InfrastructureAndAssetManagementAssetManagement:
      return "Asset management";
    case RiskClassification.InfrastructureAndAssetManagementNetworkSecurity:
      return "Network security";
    case RiskClassification.InfrastructureAndAssetManagementEmailSecurity:
      return "Email security";
    case RiskClassification.InfrastructureAndAssetManagementWebsiteSecurity:
      return "Website security";
    case RiskClassification.InfrastructureAndAssetManagementEndpointSecurity:
      return "Endpoint security";
    case RiskClassification.InfrastructureAndAssetManagementPhysicalSecurity:
      return "Physical security";
    case RiskClassification.DataClassificationAndHandling:
      return "Data classification and handling";
    case RiskClassification.DataClassificationAndHandlingPrivacyManagement:
      return "Privacy management";
    case RiskClassification.DataClassificationAndHandlingAccessManagement:
      return "Access management";
    case RiskClassification.ApplicationSecurity:
      return "Application security";
    case RiskClassification.ApplicationSecuritySoftwareDevelopment:
      return "Software development";
    case RiskClassification.ApplicationSecurityVulnerabilityManagement:
      return "Vulnerability management";
    case RiskClassification.RiskManagement:
      return "Risk management";
    case RiskClassification.RiskManagementEnterprise:
      return "Enterprise risk management";
    case RiskClassification.RiskManagement3rdNthPartyManagement:
      return "Third to Nth party management";
    case RiskClassification.RiskManagementThreatManagement:
      return "Threat management";
    case RiskClassification.RecoveryAndResponse:
      return "Recovery and response";
    case RiskClassification.RecoveryAndResponseBusinessContinuity:
      return "Business continuity";
    case RiskClassification.RecoveryAndResponseCybersecurityIncidentManagement:
      return "Cybersecurity incident management";

    default:
      return defaultString;
  }
}

// Group returns the parent category to which this Classification belongs.
// For categories with no parent, Group returns the receiver unchanged.
export function RiskClassificationGroup(
  c: RiskClassification | undefined
): RiskClassification | undefined {
  switch (c) {
    case RiskClassification.SecurityPoliciesAndProcesses:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesInformationSecurity:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesITOperationsManagement:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesComplianceManagement:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.SecurityPoliciesAndProcessesHumanResourcesSecurity:
      return RiskClassification.SecurityPoliciesAndProcesses;
    case RiskClassification.InfrastructureAndAssetManagement:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.InfrastructureAndAssetManagementAssetManagement:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.InfrastructureAndAssetManagementNetworkSecurity:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.InfrastructureAndAssetManagementEmailSecurity:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.InfrastructureAndAssetManagementWebsiteSecurity:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.InfrastructureAndAssetManagementEndpointSecurity:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.InfrastructureAndAssetManagementPhysicalSecurity:
      return RiskClassification.InfrastructureAndAssetManagement;
    case RiskClassification.DataClassificationAndHandling:
      return RiskClassification.DataClassificationAndHandling;
    case RiskClassification.DataClassificationAndHandlingPrivacyManagement:
      return RiskClassification.DataClassificationAndHandling;
    case RiskClassification.DataClassificationAndHandlingAccessManagement:
      return RiskClassification.DataClassificationAndHandling;
    case RiskClassification.ApplicationSecurity:
      return RiskClassification.ApplicationSecurity;
    case RiskClassification.ApplicationSecuritySoftwareDevelopment:
      return RiskClassification.ApplicationSecurity;
    case RiskClassification.ApplicationSecurityVulnerabilityManagement:
      return RiskClassification.ApplicationSecurity;
    case RiskClassification.RiskManagement:
      return RiskClassification.RiskManagement;
    case RiskClassification.RiskManagementEnterprise:
      return RiskClassification.RiskManagement;
    case RiskClassification.RiskManagement3rdNthPartyManagement:
      return RiskClassification.RiskManagement;
    case RiskClassification.RiskManagementThreatManagement:
      return RiskClassification.RiskManagement;
    case RiskClassification.RecoveryAndResponse:
      return RiskClassification.RecoveryAndResponse;
    case RiskClassification.RecoveryAndResponseBusinessContinuity:
      return RiskClassification.RecoveryAndResponse;
    case RiskClassification.RecoveryAndResponseCybersecurityIncidentManagement:
      return RiskClassification.RecoveryAndResponse;
    default:
      return undefined;
  }
}
