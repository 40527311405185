import { FC } from "react";
import UserBaseAPI from "../api/userbase.api";
import { useDefaultHistory } from "../../_common/types/router";
import { applicationsUrl, dashboardUrl } from "../UserBaseAppRouter";
import SingleStat from "./SingleStat";
import { pluralise } from "../../_common/helpers";

const OrgAppCount: FC = ({}) => {
  const history = useDefaultHistory();

  const onViewApps = () => {
    history.push(applicationsUrl, {
      backContext: {
        backTo: dashboardUrl,
        backToText: `Back to Dashboard`,
      },
    });
  };

  const { data, isLoading } = UserBaseAPI.useGetUserBaseDashboardStatsV1Query();

  const numApps = data?.stats?.numApps ?? 0;

  return (
    <SingleStat
      isLoading={isLoading}
      statNum={numApps}
      statDescription={pluralise(numApps, "App in use", "Apps in use")}
      onClick={onViewApps}
      statLinkText="View all apps"
    />
  );
};

export default OrgAppCount;
