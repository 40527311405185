import "./../../style/components/questionnaires/CompensatingControlInfo.scss";
import CommentDisplay from "../CommentDisplay";
import InfoBanner, { BannerType, SubItemType } from "../InfoBanner";
import { SurveyRiskVisibility } from "../../../_common/types/survey";
import UserAvatar from "../../../_common/components/UserAvatar";
import { formatDateAsLocal } from "../../../_common/helpers";
import { getSurveyEditorPath } from "../../../_common/views/SurveyDetails";
import { Link } from "react-router-dom";
import { useDefaultHistory } from "../../../_common/types/router";

export interface ICompensatingControlInfoProps {
  risk: {
    why?: string;
    explanation: string;
    author?: string;
    avatar?: string;
    sharedAt?: string;
    inRemediation?: boolean;
    riskVisibility?: SurveyRiskVisibility;
    surveyId?: number;
    answerId?: number;
    questionId?: string;
    vendorId?: number;
  };
  orgAccessNewRiskDesigns?: boolean;
}

export const CompensatingControlInfo = (
  props: ICompensatingControlInfoProps
) => {
  const r = props.risk;

  const explanationProvided = r.explanation && r.explanation.length > 0;
  const explanationExpected = r.why && r.why.length > 0;

  if (explanationExpected && explanationProvided) {
    return (
      <CommentDisplay
        name={r.author}
        avatar={r.avatar}
        date={r.sharedAt}
        comment={r.explanation}
      />
    );
  } else if (
    explanationExpected &&
    !explanationProvided &&
    r.riskVisibility === SurveyRiskVisibility.Hidden &&
    !r.inRemediation
  ) {
    return (
      <InfoBanner
        type={BannerType.INFO}
        message={
          "This risk is hidden from recipients due to the Risk Visibility setting on this questionnaire."
        }
        subItemType={SubItemType.BULLET}
        subItems={[
          "Requesting remediation for this risk will make it visible to recipients",
        ]}
      />
    );
  } else if (explanationExpected && !explanationProvided) {
    const content = r.author
      ? `${r.author} did not provide any compensating control information`
      : "No compensating control information was provided.";
    return <InfoBanner type={BannerType.WARNING} message={content} />;
  } else {
    return (
      <InfoBanner
        type={BannerType.INFO}
        message={"No compensating control information was requested."}
      />
    );
  }
};

export const CompensatingControlInfoV2 = (
  props: ICompensatingControlInfoProps
) => {
  const history = useDefaultHistory();

  const explanationProvided =
    props.risk.explanation && props.risk.explanation.length > 0;
  const explanationExpected = props.risk.why && props.risk.why.length > 0;
  const showCompensatingControlExplanation =
    explanationExpected && explanationProvided;

  let headerContent = <p>No compensating control information was requested.</p>;

  if (explanationExpected && !explanationProvided) {
    headerContent = <p>No compensating controls were provided.</p>;
  } else if (showCompensatingControlExplanation) {
    headerContent = (
      <>
        <div className="avatar-container">
          <UserAvatar name={props.risk.author} avatar={props.risk.avatar} />
        </div>
        <div className="title-container">
          {props.risk.author && <div className="name">{props.risk.author}</div>}
          {props.risk.sharedAt && (
            <div className="date">{formatDateAsLocal(props.risk.sharedAt)}</div>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="compensating-controls-info">
      <div className="header-container">
        <div className="detail-container">{headerContent}</div>
        <div className="link-container">
          {props.risk.surveyId && history ? (
            <>
              <Link
                to={getSurveyEditorPath({
                  surveyId: props.risk.surveyId,
                  questionId: props.risk.questionId,
                  answerId: props.risk.answerId,
                  editMode: false,
                  location: history.location,
                  vendorId: props.risk.vendorId,
                })}
                target="_blank"
                rel="noopener noreferrer"
              >
                {"View in questionnaire"}
              </Link>
              <span className="cr-icon-external-link" />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {showCompensatingControlExplanation ? (
        <div className="content">{props.risk.explanation}</div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CompensatingControlInfo;
