import { FC } from "react";

import { AppScopeUser } from "../api/types";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";

import { useHistory, useRouteMatch } from "react-router-dom";
import UserBaseAPI from "../api/userbase.api";
import { AppRouteParams } from "../UserBaseNavItems";
import TeamsPills from "./TeamsPills";
import { usePagination, useSorting } from "../../_common/hooks";
import UserApprovalPill from "./UserApprovalPill";
import "./AppPermissionsExpandedTable.scss";
import SecurityRatingDisplay, { RatingSize } from "./SecurityRatingDisplay";

interface AppPermissionsExpandedTableProps {
  scopeId: string;
}

const AppPermissionsExpandedTable: FC<AppPermissionsExpandedTableProps> = ({
  scopeId,
}) => {
  const history = useHistory();
  const match = useRouteMatch<AppRouteParams>();
  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppScopeUsersV1Query({
    appName,
    scopeId: scopeId,
  });

  const dataToUse = data?.users ?? [];

  const [sortedPermissions, sortedBy, onSortChange] = useSorting<
    AppScopeUser,
    "name" | "team" | "security_rating" | "status"
  >(dataToUse, "name", SortDirection.ASC, {
    name: {
      orderFuncs: [(p) => p.name.toLocaleLowerCase() || p.email],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    team: {
      orderFuncs: [(p) => p.teams.join(",")],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    security_rating: {
      orderFuncs: [(p) => p.adjustedRating],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    status: {
      orderFuncs: [(p) => p.approved],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedPermissions,
    10
  );

  const rows = pageItems.map((u: AppScopeUser): IXTableRow<string> => {
    return {
      id: u.email,
      className: "app-permissions-user-row",
      onClick: () => {
        history.push(`/userbase/user/${u.uuid}/summary`, {
          from: "permissions",
          backContext: {
            backTo: `/userbase/application/${appName}/permissions`,
            backToText: "Back to Permissions",
          },
        });
      },
      cells: [
        <XTableCell key="name">{u.name || u.email}</XTableCell>,
        <XTableCell key="team">
          <TeamsPills teams={u.teams} />
        </XTableCell>,
        <XTableCell key="security_rating">
          <SecurityRatingDisplay
            rating={u.adjustedRating}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key="status">
          <UserApprovalPill approved={u.approved} waived={u.waived} />
        </XTableCell>,
      ],
    };
  });

  if (!isLoading && data?.users === undefined) {
    return <></>;
  }

  return (
    <div className="application-permissions-expanded-table">
      <XTable
        className="app-permissions-user-list"
        loading={isLoading}
        columnHeaders={[
          { id: "name", text: "Name", sortable: true },
          { id: "team", text: "Team", sortable: true },
          { id: "security_rating", text: "User rating", sortable: true },
          { id: "status", text: "Status", sortable: true },
        ]}
        rows={rows}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        pagination={{
          currentPage: currentPage,
          totalPages: totalPages,
          onPageChange: onPageChange,
          hidePaginationIfSinglePage: true,
        }}
      />
    </div>
  );
};

export default AppPermissionsExpandedTable;
