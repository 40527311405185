import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";
import { cancelSubscription } from "../../reducers/cyberRiskActions";
import {
  addDefaultUnknownErrorAlert,
  addMessageAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import { BannerType } from "../InfoBanner";

export const CancelSubscriptionModalName = "CancelSubscriptionModalName";

class CancelSubscriptionModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { loading: false };

  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });

    this.props
      .dispatch(cancelSubscription())
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addMessageAlert({
            message:
              "Your subscription has been cancelled and you will no longer be charged",
            type: BannerType.SUCCESS,
            subMessages: [
              "You will be able to continue to use the UpGuard CyberRisk Platform until your current billing cycle concludes.",
            ],
          })
        );
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            "An error occured while cancelling your subscription"
          )
        );
      });
  };

  render() {
    return (
      <div className="modal-content">
        <h2>
          {
            "Are you sure you want to cancel your subscription to the UpGuard CyberRisk Platform?"
          }
        </h2>
        <p />
        <form onSubmit={this.onSubmit}>
          <Button
            type="submit"
            className="btn btn-danger"
            style={{ boxShadow: "none" }}
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Cancel Subscription
          </Button>
        </form>
      </div>
    );
  }
}

export default CancelSubscriptionModal;
