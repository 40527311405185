import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";
import { updateBillingEmail } from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const UpdateBillingEmailModalName = "UpdateBillingEmailModalName";

class UpdateBillingEmailModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = { loading: false };

  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });

    this.props
      .dispatch(updateBillingEmail(this.emailField.value.trim().toLowerCase()))
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultSuccessAlert(
            "Your billing email has been updated successfully"
          )
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  render() {
    return (
      <div className="modal-content">
        <h2>Change your billing email</h2>
        <p />
        <form onSubmit={this.onSubmit}>
          <label htmlFor="existingEmail">Existing billing email</label>
          <input
            type="email"
            name="existingEmail"
            disabled
            value={this.props.modalData.existingEmail}
          />
          <label htmlFor="email">New billing email</label>
          <input
            type="email"
            name="email"
            required
            ref={(ref) => (this.emailField = ref)}
          />
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Submit
          </Button>
        </form>
      </div>
    );
  }
}

export default UpdateBillingEmailModal;
