import * as React from "react";
import classnames from "classnames";
import "../style/components/ColorCheckbox.scss";
import { SidePopupV2 } from "../../_common/components/DismissablePopup";

export interface IColorCheckboxProps {
  color?: string;
  checked?: boolean;
  indeterminate?: boolean;
  label?: React.ReactNode;
  className?: string;
  small?: boolean;
  radio?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  disabled?: boolean;
  helpPopup?: string;
  helpPopupPosition?: "top" | "right" | "left" | "bottom";
  tabIndex?: number;
  helpPopupMicroFormat?: boolean;
  helpPopupWidth?: number;
  testId?: string;
}

/**
 * <ColorCheckbox />
 * is a controlled faux-checkbox that can be in unchecked, indeterminate or checked states.
 */
const ColorCheckbox = ({
  color,
  checked,
  indeterminate,
  label,
  small,
  className,
  onClick,
  radio,
  disabled,
  helpPopup,
  helpPopupPosition,
  tabIndex,
  helpPopupMicroFormat = false,
  helpPopupWidth,
  testId,
}: IColorCheckboxProps) => {
  const checkboxCls = classnames("color-checkbox-inner", color, className, {
    indeterminate,
    radio,
    checked,
    small,
    clickable: !!onClick && !disabled,
    disabled,
    ellipsis: !helpPopup,
  });

  const content = (
    <>
      <div className="box" />
      {label && <span className="label">{label} </span>}
    </>
  );

  return (
    <div className={"color-checkbox"} data-testid={testId ?? label}>
      <div
        className={checkboxCls}
        onClick={!disabled ? onClick : undefined}
        tabIndex={tabIndex ?? 0}
        onKeyPress={
          !disabled
            ? (ev) => {
                const isClickLike = isClickLikeKeypress(ev, true);
                if (isClickLike && onClick) {
                  onClick(ev as any);
                }
              }
            : undefined
        }
        data-testid={"button"}
        role={"checkbox"}
        aria-checked={checked}
      >
        {helpPopup && (
          <SidePopupV2
            popupClassName={
              !helpPopupWidth ? "checkbox-popup-content-auto-width" : ""
            }
            micro={helpPopupMicroFormat}
            position={helpPopupPosition ?? "top"}
            text={helpPopup}
            width={helpPopupWidth ?? 200}
          >
            {content}
          </SidePopupV2>
        )}
        {!helpPopup && content}
      </div>
    </div>
  );
};

ColorCheckbox.defaultProps = {
  color: "",
  checked: false,
  indeterminate: false,
  label: null,
  className: "",
  small: false,
  radio: false,
  onClick: undefined,
  disabled: false,
  helpPopup: "",
};

export default ColorCheckbox;

export const isClickLikeKeypress = (
  event: React.KeyboardEvent<HTMLDivElement>,
  preventDefault = false
) => {
  if (preventDefault) {
    event.preventDefault();
    event.stopPropagation();
  }

  if (event.type === "click") {
    return true;
  } else if (event.type === "keypress") {
    const code = event.key || event.keyCode || event.charCode;

    return code === "Enter" || code === " " || code === 32 || code === 13;
  } else {
    return false;
  }
};
