import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";

import {
  MIN_NUM_VENDORS,
  MAX_NUM_VENDORS,
  BASE_SUBSCRIPTION_COST_CENTS,
  PER_VENDOR_COST_CENTS,
  vendorSlotCosts,
  monthlyTotal,
} from "../../../_common/pricing";

import { updatePlan } from "../../reducers/cyberRiskActions";
import "../../style/components/UpdatePlanModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import TextField from "../../../_common/components/TextField";

export const UpdatePlanModalName = "UpdatePlanModalName";

const RangeInput = (props) => {
  const { value, max } = props;

  let overlayWidth = Math.max(100 - (value / max) * 100, 3);
  overlayWidth = overlayWidth < 25 ? overlayWidth + 1 : overlayWidth;

  return (
    <div className="rangeWrapper">
      <div
        className="rangeTrackOverlay"
        style={{ width: `${overlayWidth}%` }}
      />
      <input {...props} />
    </div>
  );
};

RangeInput.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

class UpdatePlanModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      submitDisabled: true,
      numVendorSlots: this.props.modalData.currentVendorSlots,
    };
  }

  onVendorSlotsUpdated = (val) => {
    let slots = Math.round(val);

    if (slots < MIN_NUM_VENDORS) {
      slots = MIN_NUM_VENDORS;
    }

    if (slots > MAX_NUM_VENDORS) {
      slots = MAX_NUM_VENDORS;
    }

    this.setState({
      numVendorSlots: slots,
      submitDisabled:
        parseInt(slots) === this.props.modalData.currentVendorSlots,
    });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });

    this.props
      .dispatch(updatePlan(this.state.numVendorSlots))
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultSuccessAlert("Your plan has been updated")
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(addDefaultUnknownErrorAlert(e.message));
      });
  };

  render() {
    return (
      <div className="update-subscription-modal modal-content">
        <h2>Update your plan</h2>
        <div className="vendor-slots field">
          <h3>
            What&apos;s the total number of vendors you&apos;d like to monitor?
          </h3>
          <TextField
            type="number"
            name="vendorSlots"
            min={MIN_NUM_VENDORS}
            max={MAX_NUM_VENDORS}
            step="1"
            onChanged={this.onVendorSlotsUpdated}
            value={this.state.numVendorSlots}
          />
          <RangeInput
            type="range"
            name="vendorSlotsRange"
            min={MIN_NUM_VENDORS}
            max={MAX_NUM_VENDORS}
            step="1"
            value={this.state.numVendorSlots}
            onChange={(e) => this.onVendorSlotsUpdated(e.target.value)}
          />
        </div>

        <div className="field">
          <span className="title">Base subscription</span>
          <span className="value">
            ${BASE_SUBSCRIPTION_COST_CENTS / 100}
            <span className="currency"> USD</span>
          </span>
          <span className="desc">
            The base cost to access the Cyber Risk Platform and track your
            digital assets.
          </span>
        </div>
        <div className="field">
          <span className="title">Monitored vendors</span>
          <span className="value">
            ${vendorSlotCosts(this.state.numVendorSlots)}
            <span className="currency"> USD</span>
          </span>
          <span className="desc">
            {this.state.numVendorSlots} @ ${PER_VENDOR_COST_CENTS / 100} per
            vendor.
          </span>
        </div>
        <div className="field">
          <span className="title">Vendor ad-hoc reports</span>
          <span className="value">
            {this.state.numVendorSlots}
            <span className="currency"> reports</span>
          </span>
          <span className="desc">
            In addition to your monitored vendors, you will be able to run
            ad-hoc reports each month.
          </span>
        </div>
        <div className="field monthly-total">
          <span className="title">MONTHLY TOTAL</span>
          <span className="value bigger">
            ${monthlyTotal(this.state.numVendorSlots)}
            <span className="currency"> USD</span>
          </span>
          <span className="desc">
            The amount you pay monthly, a combination of the base cost and the
            number of vendors you have selected.
          </span>
        </div>

        <Button
          primary
          type="button"
          loading={this.state.loading}
          disabled={this.state.loading || this.state.submitDisabled}
          onClick={this.onSubmit}
        >
          Update
        </Button>
      </div>
    );
  }
}

export default UpdatePlanModal;
