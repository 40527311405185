import {
  ConnectedComponent,
  DefaultRootState,
  shallowEqual,
} from "react-redux";
import React, { ComponentType } from "react";
import { PortfolioSpecificPermissions } from "../vendorrisk/types/roles";
import { Diff } from "utility-types";
import { createSelector } from "@reduxjs/toolkit";
import { ISingleVendorData, IUserData } from "./types/redux";
import { appConnect, useAppSelector } from "./types/reduxHooks";
import { RootState } from "./types/reduxStore";
import { getCurrentOrgFromUserData } from "./helpers/userOrg.helpers";

// System Permissions
export const UserReadAllOrganisations = "ReadAllOrganisations";
export const UserWriteAllOrganisations = "WriteAllOrganisations";
export const ImpersonateAllOrganisations = "ImpersonateAllOrganisations";
export const UserManageTrialOrganisations = "ManageTrialOrganisations";
export const UserManageHostnameOverridesPermission = "ManageHostnameOverrides";
export const UserRunWhoisReverseLookupPermission = "RunWhoisReverseLookup";
export const UserRunBulkAccountDataUpdates = "RunBulkAccountDataUpdates";
export const UserManageGlobalCustomVendors = "ManageGlobalCustomVendors";
export const UserWriteHostnameOverridesPermission = "WriteHostnameOverrides";

// Application Permissions
export const UserWriteOrganisations = "WriteOrganisations";
export const UserAccessDataLeaks = "ManageDataLeaks";
export const UserWriteDataLeaks = "WriteDataLeaks";
export const UserAccessVendorAssessments = "ManageVendorAssessments";
export const UserWriteVendorAssessments = "WriteVendorAssessments";
export const UserAccessSurveys = "ManageSurveys";
export const UserWriteSurveys = "WriteSurveys";
export const UserAccessPrefilledSurveys = "ManagePrefilledSurveys";
export const UserWritePrefilledSurveys = "WritePrefilledSurveys";
export const UserWriteSharedProfileUsers = "WriteSharedProfileUsers";
export const UserAccessRemediation = "ManageRemediation";
export const UserWriteRemediation = "WriteRemediation";
export const UserWriteTyposquatting = "WriteTyposquatting";
export const UserWriteEmailExposures = "WriteEmailExposures";
export const UserAccessApiKey = "ManageAPIKey";
export const UserManageIntegrations = "ManageIntegrations";
export const UserAccessAdditionalEvidence = "ManageAdditionalEvidence";
export const UserWriteAdditionalEvidence = "WriteAdditionalEvidence";
export const UserManageAuditLog = "ManageAuditLog";
export const UserWriteVulns = "WriteVulns";
export const UserManageVendorsUnderManagement = "ManageVendorsUnderManagement";
export const UserWriteVendorsUnderManagement = "WriteVendorsUnderManagement";
export const UserManageVendorRiskWaivers = "ManageVendorRiskWaivers";
export const UserWriteVendorInfo = "WriteVendorInfoPermission";
export const UserWriteQuestionnaireTypes = "WriteQuestionnaireTypes";
export const UserWriteScoreBadge = "WriteScoreBadge";

export const UserReadContentLibrary = "ReadContentLibrary";
export const UserWriteContentLibrary = "WriteContentLibrary";

export const UserReadSurveyImportExport = "ReadSurveyImportExport";
export const UserWriteSurveyImportExport = "WriteSurveyImportExport";

export const UserSurveyShareAnswersWithOrg = "SurveyShareAnswersWithOrg";

export const OrgAccessBreachSight = "AccessBreachSight";
export const OrgAccessVendorAssessments = "AccessVendorAssessments";
export const OrgAccessSurveys = "AccessSurveys";
export const OrgAccessWebsites = "AccessWebsites";
export const OrgAccessVendors = "AccessVendors";
export const OrgAccessCustomerInfo = "AccessCustomerInfo";
export const OrgAccessLabels = "AccessLabels";
export const OrgAccessBusinessInfo = "AccessBusinessInfo";
export const OrgAccessEmailExposures = "AccessEmailExposures";
export const OrgAccessVendorTechnologies = "AccessVendorTechnologies";
export const OrgAccessScoreHistory = "AccessScoreHistory";
export const OrgAccessTyposquatting = "AccessTyposquatting";
export const OrgAccessAdditionalEvidence = "AccessAdditionalEvidence";

export const OrgAccessVulns = "AccessVulns";
export const OrgQuestionnaireScores = "QuestionnaireScores";
export const OrgQuestionnaireScoresInclude = "QuestionnaireScoresInclude";

export const OrgAccessDefaultVendor = "DefaultVendor";
export const OrgNewVendorWatchLookup = "NewVendorInfo";

export const OrgHideVendorRisks = "HideVendorRisks";
export const OrgReadOnlyQuestionnaireLibrary = "ReadOnlyQuestionnaireLibrary";
export const OrgAccessVendorDataLeaks = "AccessVendorDataLeaks";
export const OrgAccessScoringChangeNotification = "ScoringChangeNotification";

export const OrgCustomLogo = "CustomLogo";
export const OrgAccessAuditLog = "AccessAuditLog";
export const OrgAccessDefaultTexts = "AccessDefaultTexts";

export const OrgAccessSubsidiaries = "AccessSubsidiaries";
export const OrgAccessVendorsUnderManagement = "AccessVendorsUnderManagement";

export const OrgAccessQuestionnaireBuilder = "AccessQuestionnaireBuilder";
export const OrgVisibleVendorDataLeakUrl = "VisibleVendorDataLeakUrl";

export const UserSystemRoleVendorManagementAnalyst = "VendorManagementAnalyst";
export const GroupAccessVendorAssetSharing = "VendorAssetSharing";

// role based access permissions
export const OrgAccessNewRoleCreation = "AccessNewRoleCreation";

export const OrgAccessDomainPortfolios = "DomainPortfolios";
export const OrgAccessVendorPortfolios = "VendorPortfolios";

export const UserBreachsightEnabled = "BreachSight";
export const UserVendorRiskEnabled = "VendorRisk";
export const UserUserBaseEnabled = "UserBase";

export const UserBreachsightWrite = "BreachsightWrite";
export const UserVendorRiskWrite = "VendorRiskWrite";
export const UserUserBaseWrite = "UserBaseWrite";

export const UserTyposquattingRead = "ReadTypoSquatting";
export const UserEmailExposureRead = "ReadEmailExposure";
export const OrgAccessCustomDomainsIPsForVendors = "CustomDomainsIPsForVendors";
export const UserWriteOwnOrganisation = "WriteOwnOrganisation";
export const UserWriteUsers = "WriteUsers";
export const DisableChatGPT = "DisableChatGPT";
export const OrgChatGPTAutofill = "ChatGPTAutofill";

export const OrgAccessRelationshipQuestionnaireAutomation =
  "RelationshipQuestionnaireAutomation";

export const WriteQuestionnaireAutomation = "WriteQuestionnaireAutomation";

export const OrgAccessAdditionalEvidenceRiskRemediation =
  "AdditionalEvidenceRiskRemediation";

export const OrgAccessCustomReportingTemplates = "CustomReportingTemplates";
export const UserWriteCustomReportingTemplates =
  "WriteCustomReportingTemplates";

export const OrgRequestAdditionalEvidence = "RequestAdditionalEvidence";

export const OrgVendorAssessmentPickDomains = "VendorAssessmentPickDomains";
export const OrgExtraQuestionnaireStatusChanges = "QuestionnaireStatusChanges";
export const OrgSurveyImportExport = "SurveyImportExport";
export const OrgNewRiskClassifications = "NewRiskClassifications";
export const OrgAccessManagedVendorsV3 = "ManagedVendorsV3";
export const OrgAccessAppGuard = "AppGuard";
export const OrgAccessUserBase = "UserBase";
export const OrgAccessSurveyInternalCollaborators =
  "SurveyInternalCollaborators";
export const OrgAccessNewRiskDesigns = "NewRiskDesigns";
export const OrgAccessImpersonateUser = "ImpersonateUser";

// full set of permissions available for vendor portfolios
// Note: Please keep in sync with Vendor Risk permissions part of portfolioSpecificPermissions in roles.go
export const vendorPortfolioPermissionSet = new Set([
  UserAccessSurveys,
  UserAccessRemediation,
  UserAccessVendorAssessments,
  UserAccessAdditionalEvidence,
  UserWriteSurveys,
  UserWriteRemediation,
  UserWriteAdditionalEvidence,
  UserManageVendorRiskWaivers,
  UserVendorRiskEnabled,
  UserVendorRiskWrite,
  UserWriteVendorInfo,
  UserWriteVulns,
  UserWriteVendorAssessments,
]);

export type IPermissionSet = { [permission: string]: boolean };

export interface IUserOrgPermissions {
  org: IPermissionSet;
  user: IPermissionSet;
}

export const hasOrgPermission = (
  permissions: IUserOrgPermissions,
  permission: string
): boolean => !!permissions.org[permission];

export const hasUserPermission = (
  permissions: IUserOrgPermissions,
  permission: string
): boolean => !!permissions.user[permission];

export const hasPortfolioSpecificPermission = (
  permissions: PortfolioSpecificPermissions,
  permission: string,
  portfolioId?: number
): boolean => {
  if (portfolioId)
    return permissions[portfolioId]?.includes(permission) ?? false;

  return Object.values(permissions).flat().includes(permission);
};

export interface HasPermissionOptions {
  vendorPortfolioID?: number;
  anyVendorPortfolioID?: boolean;
  domainPortfolioIDs?: number[];
  anyDomainPortfolioID?: boolean;
}

/**
 * Checks whether a single permission is enabled for the current user
 * @param permission The user-level permission to check for
 * @param userPermissions The current user's set of permissions
 * @param vendorPortfolioSpecificPermissions The current user's vendor-portfolio specific permissions
 * @param domainPortfolioSpecificPermissions The current user's domain-portfolio specific permissions
 * @param options Options to configure how vendor/domain portfolio permissions are checks
 * @returns true if the user has the permission, false otherwise
 */
export const hasUserOrPortfolioPermission = (
  permission: string,
  userPermissions: IPermissionSet,
  vendorPortfolioSpecificPermissions: PortfolioSpecificPermissions,
  domainPortfolioSpecificPermissions: PortfolioSpecificPermissions,
  options?: HasPermissionOptions
): boolean => {
  // Destructuring these out with defaults helps TypeScript infer better
  const {
    anyDomainPortfolioID,
    anyVendorPortfolioID,
    vendorPortfolioID,
    domainPortfolioIDs,
  } = options || {};

  // Permission applies to user, simple
  if (userPermissions[permission]) return true;

  // Permission applies to user, *any* vendor portfolio (must opt-in supplying `AnyVendorPortfolioID` option)
  if (
    anyVendorPortfolioID &&
    Object.values(vendorPortfolioSpecificPermissions)
      .flat()
      .includes(permission)
  ) {
    return true;
  }

  // Permission applies to user, *any* domain portfolio (must opt-in supplying `AnyDomainPortfolioID` option)
  if (
    anyDomainPortfolioID &&
    Object.values(domainPortfolioSpecificPermissions)
      .flat()
      .includes(permission)
  ) {
    return true;
  }

  // Permission applies to user, specific vendor portfolio
  if (
    vendorPortfolioID &&
    vendorPortfolioSpecificPermissions[vendorPortfolioID]?.includes(permission)
  ) {
    return true;
  }

  // Permission applies to user, specific domain portfolios
  if (
    Array.isArray(domainPortfolioIDs) &&
    domainPortfolioIDs.length &&
    Object.entries(domainPortfolioSpecificPermissions)
      .flatMap(([key, value]) =>
        domainPortfolioIDs.includes(Number(key)) ? value : []
      )
      .includes(permission)
  ) {
    return true;
  }

  return false;
};

/**
 * Checks whether all given permissions are enabled for the current user
 * Options are included with every check, so there are some complex usages
 * that are not supported
 * Example: **NOT SUPPORTED**
 *    Checking multiple permissions where one or more must fit very specific criteria.
 *
 *    Permission A on any vendor AND permission B on specific vendor
 *    Permission A on any domain AND permission B on specific domain
 *    Permission A on specific vendor AND permission B on specific domain

 *
 * Example: **SUPPORTED**
 *    Checking multiple permissions loosely between vendor, domain or both
 *    Permission A on any vendor AND permission B on any vendor
 *    Permission A on specific vendor or domain AND permission B on any vendor or domain
 * @param permissions The user-level permissions to check for
 * @param userPermissions The current user's set of permissions
 * @param vendorPortfolioSpecificPermissions The current user's vendor-portfolio specific permissions
 * @param domainPortfolioSpecificPermissions The current user's domain-portfolio specific permissions
 * @param options Options to configure how vendor/domain portfolio permissions are checks
 * @returns true if the user has all specified permissions, false otherwise
 */
export const hasUserOrPortfolioPermissions = (
  permissions: string[],
  userPermissions: IPermissionSet,
  vendorPortfolioSpecificPermissions: PortfolioSpecificPermissions,
  domainPortfolioSpecificPermissions: PortfolioSpecificPermissions,
  options?: HasPermissionOptions
): boolean =>
  permissions.every((permission) =>
    hasUserOrPortfolioPermission(
      permission,
      userPermissions,
      vendorPortfolioSpecificPermissions,
      domainPortfolioSpecificPermissions,
      options
    )
  );

interface IPermissionSelectorResult {
  userPermissions: IPermissionSet;
  vendorPortfolioSpecificPermissions: PortfolioSpecificPermissions;
  domainPortfolioSpecificPermissions: PortfolioSpecificPermissions;
}

const userPermissionSelector = createSelector(
  [
    (state: DefaultRootState) => state.common.permissions.user,
    (state: DefaultRootState) =>
      state.common.userData.vendorPortfolioSpecificPermissions,
    (state: DefaultRootState) =>
      state.common.userData.domainPortfolioSpecificPermissions,
  ],
  (
    permissions,
    vendorPortfolioSpecificPermissions,
    domainPortfolioSpecificPermissions
  ): IPermissionSelectorResult => ({
    userPermissions: permissions,
    vendorPortfolioSpecificPermissions,
    domainPortfolioSpecificPermissions,
  })
);

/**
 * Checks whether all given permissions are enabled for the current user
 * Options are included with every check, so there are some complex usages
 * that are not supported
 * Example: **NOT SUPPORTED**
 *    Checking multiple permissions where one or more must fit very specific criteria.
 *
 *    Permission A on any vendor AND permission B on specific vendor
 *    Permission A on any domain AND permission B on specific domain
 *    Permission A on specific vendor AND permission B on specific domain
 *
 * Example: **SUPPORTED**
 *    Checking multiple permissions loosely between vendor, domain or both
 *    Permission A on any vendor AND permission B on any vendor
 *    Permission A on specific vendor or domain AND permission B on any vendor or domain
 * @param permissions One or many user-level permissions to check
 * @param options Options to configure how vendor/domain portfolio permissions are checks
 * @returns true if the user has all specified permissions, false otherwise
 */
export const useHasUserOrPortfolioPermissions = (
  permissions: string | string[],
  options?: HasPermissionOptions
): boolean => {
  const {
    userPermissions,
    domainPortfolioSpecificPermissions,
    vendorPortfolioSpecificPermissions,
  } = useAppSelector(userPermissionSelector);

  if (Array.isArray(permissions))
    return hasUserOrPortfolioPermissions(
      permissions,
      userPermissions,
      vendorPortfolioSpecificPermissions,
      domainPortfolioSpecificPermissions,
      options
    );

  return hasUserOrPortfolioPermission(
    permissions,
    userPermissions,
    vendorPortfolioSpecificPermissions,
    domainPortfolioSpecificPermissions,
    options
  );
};

/**
 * Checks whether all given entitlements are enabled for the current organisation
 * @param entitlements (a.k.a "org permissions") One or many entitlements to check for
 * @returns true if the organisation has all specified entitlements
 */
export const useHasOrgEntitlement = (entitlements: string | string[]) => {
  const permissions = useAppSelector((state) => state.common.permissions.org);

  if (Array.isArray(entitlements)) {
    return entitlements.every(
      (orgEntitlement) => !!permissions[orgEntitlement]
    );
  }

  return !!permissions[entitlements];
};

/**
 * Checks whether the current org, if any, is a verified vendor.
 * Returns false if the current org is not verified or if current org is not defined
 */
export const useOrgIsVerifiedVendor = () => {
  const userData = useAppSelector((state) => state.common?.userData);
  const currentOrg = getCurrentOrgFromUserData(userData);
  return currentOrg?.isVerifiedVendor ?? false;
};

export const canAccessPrefilledSurveys = (
  permissions: IUserOrgPermissions
): boolean =>
  !!(
    permissions.user[UserAccessPrefilledSurveys] ||
    permissions.user[UserWritePrefilledSurveys]
  );

export const canAccessWebsites = (permissions: IUserOrgPermissions): boolean =>
  !!(
    permissions.org[OrgAccessCustomerInfo] && permissions.org[OrgAccessWebsites]
  );

export const canAccessVendors = (permissions: IUserOrgPermissions): boolean =>
  !!(permissions.org[OrgAccessVendors] && permissions.org[OrgAccessWebsites]);

export const canAccessVendorTechnologies = (
  permissions: IUserOrgPermissions
): boolean =>
  !!(
    canAccessVendors(permissions) &&
    permissions.org[OrgAccessVendorTechnologies]
  );

export const canAccessBreachSight = (
  permissions: IUserOrgPermissions
): boolean =>
  !!(
    (permissions.user[UserAccessDataLeaks] ||
      permissions.user[UserWriteDataLeaks]) &&
    permissions.org[OrgAccessBreachSight]
  );

export const userHasWriteRemediationPermission = (
  userPermissions: string[]
): boolean => userPermissions.includes(UserWriteRemediation);

export const userHasWriteVendorsUnderManagementPermission = (
  userPermissions: string[]
): boolean => userPermissions.includes(UserWriteVendorsUnderManagement);

export const userHasReadVendorsUnderManagementPermission = (
  userPermissions: string[]
): boolean => userPermissions.includes(UserManageVendorsUnderManagement);

export const accountIncludesSurveyScoresForVendors = (
  permissions: IUserOrgPermissions
): boolean =>
  !!(
    permissions.org[OrgQuestionnaireScores] &&
    permissions.org[OrgQuestionnaireScoresInclude]
  );

export const userHasBreachsightEnabled = (userPermissions: string[]): boolean =>
  userPermissions.includes(UserBreachsightEnabled);

export const userHasVendorRiskEnabled = (userPermissions: string[]): boolean =>
  userPermissions.includes(UserVendorRiskEnabled);

export const hideBreachsight = (userPermissions: string[]): boolean =>
  !userHasBreachsightEnabled(userPermissions);

export const hideVendorRisk = (
  userPermissions: string[],
  portfolioSpecificPermissions: PortfolioSpecificPermissions
): boolean =>
  !userHasVendorRiskEnabled(userPermissions) &&
  (!portfolioSpecificPermissions ||
    Object.keys(portfolioSpecificPermissions).length === 0);

export const userHasWriteBreachSight = (userPermissions: string[]): boolean =>
  userPermissions.includes(UserBreachsightWrite);

export const userHasWriteVendorRisk = (userPermissions: string[]): boolean =>
  userPermissions.includes(UserVendorRiskWrite);

// userHasPermissionForVendorFromState checks if the current user has a required permission, either globally,
// or for any portfolio the specified vendor appears in.
export const userHasPermissionForVendorFromState = (
  state: DefaultRootState,
  requiredPermission: string,
  vendorId: number
): boolean => {
  const userPerms = getUserPermissionsForVendor(
    vendorId,
    state.common.userData,
    state.cyberRisk.vendors
  );
  return !!userPerms[requiredPermission];
};

// getUserPermissionsForVendorFromState gets the current user permissions both global and for any portfolio
// the specified vendor appears in.
// This may be more efficient when checking multiple permissions, rather than than calling
// userHasPermissionForVendorFromState for each permission.
// Generally we could consider caching / me
export const getUserPermissionsForVendorFromState = (
  state: DefaultRootState,
  vendorId: number
): IPermissionSet =>
  getUserPermissionsForVendor(
    vendorId,
    state.common.userData,
    state.cyberRisk.vendors
  );

export const arrayToPermissionSet = (
  permissions: string[] = []
): IPermissionSet =>
  // Using default arg, but it would be slightly safer to check permissions array exists
  // in function body rather than using the default arg here, due to possible `null` value
  // and even still, other possible garbage returned from backend
  // since:
  // - commonReducer calls this with vanilla JS (no type safety there)
  // - commonRouter.initialState calls this with `any` type (again, no type safety there)
  permissions.reduce<IPermissionSet>(
    (result, perm) => ({
      ...result,
      [perm]: true,
    }),
    {}
  );

// getUserPermissionsForVendor
// gets users permissions for a vendor checking portfolios if needed
// note that for the portfolio permission to work the vendor must be loaded into redux so the component that
// calls this function at minimum should call fetchVendorWatchStatus to ensure its loaded
const getUserPermissionsForVendor = (
  vendorId: number,
  userData: IUserData,
  vendors: { [vendorId: number]: ISingleVendorData | undefined }
): IPermissionSet => {
  // If we've got a valid vendor ID and the user has any portfolio specific permissions
  if (
    vendorId &&
    userData.orgPermissions.includes(OrgAccessVendorPortfolios) &&
    Object.keys(userData.vendorPortfolioSpecificPermissions).length
  ) {
    // Get any vendor-specific portfolio permissions
    const portfolios = vendors[vendorId]?.watching?.result?.vendorPortfolios;
    const portfolioPermissions = portfolios?.flatMap(
      (p) => userData.vendorPortfolioSpecificPermissions[p.id] || []
    );

    const commonUserPermissions = userData.userPermissions.filter(
      (p) => !vendorPortfolioPermissionSet.has(p)
    );

    return {
      ...arrayToPermissionSet(commonUserPermissions),
      ...arrayToPermissionSet(portfolioPermissions),
    };
  }

  return arrayToPermissionSet(userData.userPermissions);
};

export const userCanMangeAuditLog = (
  permissions: IUserOrgPermissions
): boolean =>
  !!(
    permissions.org[OrgAccessAuditLog] && permissions.user[UserManageAuditLog]
  );

export interface IWithPermissionsProps {
  permissions: IUserOrgPermissions;
  canAccessSurveys: boolean;
  canAccessPrefilledSurveys: boolean;
  canAccessWebsites: boolean;
  canAccessVendors: boolean;
  canAccessVendorTechnologies: boolean;
  canAccessBreachSight: boolean;
  canAccessVulns: boolean;
  canAccessScoreHistory: boolean;
  orgHasBreachSightEnabled: boolean;
  canAccessHIBPExposures: boolean;
  userHasWriteVendorInfoPermission: boolean;
  userHasWriteSurveysPermission: boolean;
  userHasWriteDataLeaksPermission: boolean;
  userHasWriteRemediationPermission: boolean;
  userHasWriteTyposquattingPermission: boolean;
  userHasWriteEmailExposuresPermission: boolean;
  userHasWriteVendorsUnderManagementPermission: boolean;
  userHasWriteVulnsPermission: boolean;
  userHasWritePrefilledSurveysPermission: boolean;
  userHasWriteSharedProfileUsersPermission: boolean;
  userHasManageIntegrationsPermissions: boolean;
  orgHasDefaultVendorEnabled: boolean;
  orgHasPortfoliosEnabled: boolean;
  orgHasDomainPortfoliosEnabled: boolean;
  canCreateNewWatchItemsAndLookups: boolean;
  mustHideVendorRisks: boolean;
  hasReadOnlyQuestionnaireLibrary: boolean;
  canManageVendorRiskWaivers: boolean;
  canManageAuditLog: boolean;
  userHasWriteBreachSight: boolean;
  userHasVendorRiskWrite: boolean;
  canAccessVendorRisk: boolean;
  editablePortfolioIds: number[];
  editableDomainPortfolioIds: number[];
  canWriteVendorAssessment: boolean;
}

/*
  @deprecated prefer usePermissions() hook or getPermissionsFromState() in connect func (or retrieving perms directly from state)
*/
export const withPermissions = <BaseProps extends Record<never, never>>(
  BaseComponent:
    | ComponentType<BaseProps>
    | ConnectedComponent<
        ComponentType<Diff<BaseProps, IWithPermissionsProps>>,
        any
      >
) =>
  appConnect<
    IWithPermissionsProps,
    never,
    Diff<BaseProps, IWithPermissionsProps>
  >((state, ownProps) => {
    return getPermissionsFromState(state, ownProps);
  })(BaseComponent);

const emptyArray: number[] = [];

const getPermissions = (
  permissions: IUserOrgPermissions,
  userData: IUserData,
  vendors: { [vendorId: number]: ISingleVendorData | undefined },
  componentProps?: React.ComponentProps<any>
): IWithPermissionsProps => {
  const {
    orgPermissions,
    vendorPortfolioSpecificPermissions,
    domainPortfolioSpecificPermissions,
  } = userData;

  const commonVendorProps = (componentProps ?? {}) as {
    // Look for some common vendor-related props to help us determine portfolio-specific permissions.
    vendorId?: number | string;
    isSubsidiary?: boolean;
    isVendorPortal?: boolean;
  };

  const userPerms = getUserPermissionsForVendor(
    commonVendorProps.isSubsidiary || commonVendorProps.isVendorPortal
      ? 0
      : typeof commonVendorProps.vendorId === "string"
        ? parseInt(commonVendorProps.vendorId)
        : commonVendorProps.vendorId || 0,
    userData,
    vendors
  );

  const userHasVendorRiskWrite = !!userPerms[UserVendorRiskWrite];
  const userHasWriteSurveysPermission = !!userPerms[UserWriteSurveys];
  const userHasWriteRemediationPermission = !!userPerms[UserWriteRemediation];
  const canManageVendorRiskWaivers = !!userPerms[UserManageVendorRiskWaivers];
  const canAccessSurveys =
    hasOrgPermission(permissions, OrgAccessSurveys) &&
    !!userPerms[UserAccessSurveys];
  const canWriteVendorAssessment = userPerms[UserWriteVendorAssessments];

  // If the user can only edit specific portfolios, keep a list of the IDs here
  let editablePortfolioIds: number[] = emptyArray;
  const orgHasPortfoliosEnabled =
    orgPermissions && orgPermissions.includes(OrgAccessVendorPortfolios);
  if (
    orgHasPortfoliosEnabled &&
    vendorPortfolioSpecificPermissions &&
    Object.keys(vendorPortfolioSpecificPermissions).length > 0
  ) {
    editablePortfolioIds = [];
    Object.entries(vendorPortfolioSpecificPermissions).forEach(
      ([portfolioId, perms]) => {
        if (perms?.includes(UserVendorRiskWrite)) {
          editablePortfolioIds.push(parseInt(portfolioId));
        }
      }
    );
  }

  // If the user can only edit specific domain portfolios, keep a list of the IDs here
  let editableDomainPortfolioIds: number[] = emptyArray;
  const orgHasDomainPortfoliosEnabled =
    orgPermissions && orgPermissions.includes(OrgAccessDomainPortfolios);
  if (
    orgHasDomainPortfoliosEnabled &&
    domainPortfolioSpecificPermissions &&
    Object.keys(domainPortfolioSpecificPermissions).length > 0
  ) {
    editableDomainPortfolioIds = [];
    Object.entries(domainPortfolioSpecificPermissions).forEach(
      ([portfolioId, perms]) => {
        if (perms?.includes(UserBreachsightWrite)) {
          editableDomainPortfolioIds.push(parseInt(portfolioId));
        }
      }
    );
  }

  const canCreateWatchItems =
    hasOrgPermission(permissions, OrgNewVendorWatchLookup) &&
    (userData.userPermissions.includes(UserVendorRiskWrite) ||
      Object.values(userData.vendorPortfolioSpecificPermissions).findIndex(
        (perms) => {
          // If the user has any portfolio sepcific roles including write access, they can monitor new vendors
          return perms?.includes(UserVendorRiskWrite);
        }
      ) > -1);

  const permProps: IWithPermissionsProps = {
    permissions,
    canAccessSurveys,
    canAccessPrefilledSurveys: canAccessPrefilledSurveys(permissions),
    canAccessWebsites: canAccessWebsites(permissions),
    canAccessVendors: canAccessVendors(permissions),
    canAccessVendorTechnologies: canAccessVendorTechnologies(permissions),
    canAccessBreachSight: canAccessBreachSight(permissions),
    canAccessVulns: hasOrgPermission(permissions, OrgAccessVulns),
    canAccessScoreHistory: hasOrgPermission(permissions, OrgAccessScoreHistory),
    orgHasBreachSightEnabled: hasOrgPermission(
      permissions,
      OrgAccessBreachSight
    ),
    canAccessHIBPExposures: hasOrgPermission(
      permissions,
      OrgAccessEmailExposures
    ),
    userHasManageIntegrationsPermissions: hasUserPermission(
      permissions,
      UserManageIntegrations
    ),
    userHasWriteVendorInfoPermission: userHasVendorRiskWrite,
    userHasWriteSurveysPermission,
    userHasWriteDataLeaksPermission: !!userPerms[UserWriteDataLeaks],
    userHasWriteRemediationPermission,
    userHasWriteTyposquattingPermission: !!userPerms[UserWriteTyposquatting],
    userHasWriteEmailExposuresPermission: !!userPerms[UserWriteEmailExposures],
    userHasWriteVendorsUnderManagementPermission:
      !!userPerms[UserWriteVendorsUnderManagement],
    userHasWriteVulnsPermission: !!userPerms[UserWriteVulns],
    orgHasDefaultVendorEnabled: hasOrgPermission(
      permissions,
      OrgAccessDefaultVendor
    ),
    orgHasPortfoliosEnabled,
    orgHasDomainPortfoliosEnabled,
    canCreateNewWatchItemsAndLookups: canCreateWatchItems,
    mustHideVendorRisks: hasOrgPermission(permissions, OrgHideVendorRisks),
    hasReadOnlyQuestionnaireLibrary: hasOrgPermission(
      permissions,
      OrgReadOnlyQuestionnaireLibrary
    ),
    canManageVendorRiskWaivers,
    canManageAuditLog: userCanMangeAuditLog(permissions),
    userHasWriteBreachSight: !!userPerms[UserBreachsightWrite],
    userHasVendorRiskWrite: userHasVendorRiskWrite,
    canAccessVendorRisk:
      (!!userPerms[UserVendorRiskEnabled] ||
        (vendorPortfolioSpecificPermissions &&
          Object.keys(vendorPortfolioSpecificPermissions).length > 0)) &&
      hasOrgPermission(permissions, OrgAccessVendors),
    userHasWritePrefilledSurveysPermission:
      !!userPerms[UserWritePrefilledSurveys],
    userHasWriteSharedProfileUsersPermission:
      !!userPerms[UserWriteSharedProfileUsers],
    editablePortfolioIds,
    editableDomainPortfolioIds,
    canWriteVendorAssessment,
  };

  return permProps;
};

export const getPermissionsFromState = (
  state: DefaultRootState,
  componentProps?: React.ComponentProps<any>
) =>
  getPermissions(
    state.common.permissions,
    state.common.userData,
    state.cyberRisk.vendors,
    componentProps
  );

const permissionsSelector = createSelector(
  [
    (state: DefaultRootState) => state.common.permissions,
    (state: DefaultRootState) => state.common.userData,
    (state: DefaultRootState) => state.cyberRisk.vendors,
    (_state: DefaultRootState, componentProps?: React.ComponentProps<any>) =>
      componentProps,
  ],
  (permissions, userData, vendors, componentProps) => {
    return getPermissions(permissions, userData, vendors, componentProps);
  }
);

// usePermissions
// hook to get permissions for user/org
// pass in appropriate props to get the perms for a single vendor
export const usePermissions = (componentProps?: {
  vendorId?: number | string;
  isSubsidiary?: boolean;
  isVendorPortal?: boolean;
}) =>
  useAppSelector(
    (state) => permissionsSelector(state, componentProps),
    shallowEqual
  );

// basicPermissionSelector selects permissions from state without any transformations based on
// vendor access. This can be used when portfolio-specific permissions are not needed.
const basicPermissionSelector = createSelector(
  [
    (state: RootState) => state.common.userData.userPermissions,
    (state: RootState) => state.common.userData.orgPermissions,
  ],
  (userPermissions, orgPermissions) => {
    return {
      userPermissions: userPermissions.reduce(
        (prev: Record<string, true | undefined>, next) => {
          prev[next] = true;
          return prev;
        },
        {}
      ),
      orgPermissions: orgPermissions.reduce(
        (prev: Record<string, true | undefined>, next) => {
          prev[next] = true;
          return prev;
        },
        {}
      ),
    };
  }
);

export const useBasicPermissions = () =>
  useAppSelector(basicPermissionSelector);
