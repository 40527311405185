import { Component } from "react";
import PropTypes from "prop-types";
import Button from "../../../_common/components/core/Button";

import "../../styles/ImpersonateOrgModal.scss";
import {
  closeModal,
  impersonateUserOrg,
} from "../../../_common/reducers/commonActions";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";

export const ImpersonateOrgModalName = "ImpersonateOrgModal";

class ImpersonateOrgModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    modalData: PropTypes.shape({
      orgId: PropTypes.number.isRequired,
      orgName: PropTypes.string.isRequired,
    }).isRequired,
  };

  static reasons = [
    "Account Configuration",
    "Audit",
    "Customer Meeting",
    "Feature",
    "Issue Investigation",
    "Other",
  ];

  state = {
    reason: "Account Configuration",
    customReason: "",
  };

  onSubmit = (e) => {
    e.preventDefault();
    let { reason } = this.state;
    if (reason === "Other") {
      reason = this.state.customReason;
    }

    this.props
      .dispatch(impersonateUserOrg(this.props.modalData.orgId, reason))
      .then((currentOrgRoles) => {
        this.props.dispatch(closeModal());
        if (currentOrgRoles.includes("Distributor")) {
          this.props.history.push("/distributor/accounts");
        } else {
          this.props.history.push("/");
        }
      });
  };

  render() {
    return (
      <div className="modal-content" id="impersonate-org-modal">
        <h2>Log in to {this.props.modalData.orgName}</h2>
        <p>
          You are about to log into a customer&apos;s account. Are you sure you
          want to proceed?
        </p>
        <InfoBanner
          type={BannerType.WARNING}
          message="REMEMBER: this action AND your justification will be visible in the customer's audit log."
        />
        <br />
        <form onSubmit={this.onSubmit}>
          <label htmlFor="reason">Please provide a justification:</label>
          <select
            name="reason"
            value={this.state.reason}
            onChange={(e) => this.setState({ reason: e.target.value })}
          >
            {ImpersonateOrgModal.reasons.map((r) => (
              <option value={r} key={r}>
                {r}
              </option>
            ))}
          </select>
          {this.state.reason === "Other" && (
            <input
              type="text"
              value={this.state.customReason}
              onChange={(e) => this.setState({ customReason: e.target.value })}
              placeholder="Detail your justification here"
            />
          )}
          <div className="btn-group">
            <Button
              tertiary
              loading={this.state.loading}
              onClick={() => this.props.dispatch(closeModal())}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              primary
              loading={this.state.loading}
              disabled={
                this.state.loading ||
                (this.state.reason === "Other" && !this.state.customReason)
              }
            >
              Log in
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default ImpersonateOrgModal;
