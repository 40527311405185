import {
  VendorAssessment,
  VendorAssessmentEvidence,
  VendorAssessmentKeyRisk,
  VendorAssessmentNarrativeSection,
  VendorAssessmentScores,
  VendorAssessmentStatus,
} from "../../types/vendorAssessments";
import { MergeTag } from "../../../_common/types/orgDefaultTexts";
import { FC } from "react";
import VendorAssessmentV3TextCard from "./VendorAssessmentV3TextCard";
import VendorAssessmentV3EvidenceUsedCard from "./VendorAssessmentV3EvidenceUsedCard";
import VendorAssessmentV3RisksCard from "./VendorAssessmentV3RisksCard";
import { VendorAssessmentAnalystMode } from "../../views/VendorAssessmentV3";
import "../../style/components/vendor_assessment/VendorAssessmentAutofillAssessmentCard.scss";
import { RemediationRequestWithMeta } from "../../../_common/types/remediation";
import InfoBanner, { BannerType } from "../InfoBanner";
import VendorAssessmentAutofillClassificationsSummaryCard from "./VendorAssessmentAutofillClassificationsSummaryCard";
import { IVendorRiskWaiver } from "../../../_common/types/vendorRiskWaivers";
import { VendorSummaryRisk } from "../../../_common/types/vendorSummary";

interface VendorAssessmentAutofillAssessmentCardProps {
  assessment: VendorAssessment;
  evidence: VendorAssessmentEvidence;
  vendorName: string;
  managedOrgId?: number;
  readonly: boolean;
  numCurrentActiveRiskWaivers: number;
  numUnapprovedRiskWaivers: number;
  numPendingPublicRiskWaivers: number;
  mergeTags: MergeTag[];
  onSaveCopy?: (type: string, text: string) => Promise<void>;
  urlPrefix: string;
  goToWaivers: () => void;
  goToSurvey: (id: number, isPublic: boolean) => void;
  goToAdditionalEvidence: (id: number, isPublic: boolean) => void;
  goToEvidencePage: (url: string, category: string) => void;
  goToTemplates?: () => void;
  includeRiskWaivers: boolean;
  onOpenCloudscanPanel: (scan: string, risk: string) => void;
  orgHasCustomTemplatesAccess: boolean;
  orgHasSurveyScoresEnabled: boolean;
  remediationRequest?: RemediationRequestWithMeta;
  goToRemediationRequest?: () => void;
  analystMode?: VendorAssessmentAnalystMode;
  loading?: boolean;
  onToggleKeyRisk?: (risk: VendorAssessmentKeyRisk, selected: boolean) => void;
  keyRisks?: VendorAssessmentKeyRisk[];
  onToggleHideSection?: (type: string, hidden: boolean) => void;
  scoringAndRisksCalcComplete: boolean;
  onOpenDomainsModal: () => void;
  narrativeSections?: VendorAssessmentNarrativeSection[];
  risks?: VendorSummaryRisk[];
  waivers?: IVendorRiskWaiver[];
  autofillRunning?: boolean;
  assessmentScores?: VendorAssessmentScores;
}

// VendorAssessmentAutofillAssessmentCard
// This component displays the assessment summary for an autofilled assessment .
// Replaces the VendorAssessmentV3AssessmentCard component for regular assessments.
const VendorAssessmentAutofillAssessmentCard: FC<
  VendorAssessmentAutofillAssessmentCardProps
> = (props) => {
  const published =
    props.assessment.status === VendorAssessmentStatus.Published;
  const waiverCount = published
    ? props.assessment.numWaivers ?? 0
    : props.numCurrentActiveRiskWaivers;

  const executiveSummarySection = props.narrativeSections?.find(
    (s) => s.section === "executive_summary"
  );

  const vendorBackgroundSection = props.narrativeSections?.find(
    (s) => s.section === "background"
  );

  const conclusionSection = props.narrativeSections?.find(
    (s) => s.section === "conclusion"
  );

  return (
    <div className={"vendor-assessment-autofill-assessment-card"}>
      {!props.scoringAndRisksCalcComplete && !props.readonly && (
        <InfoBanner
          type={BannerType.INFO}
          message={
            "We are calculating the score for this assessment. You'll be able to publish it once this is complete."
          }
        />
      )}
      {!!executiveSummarySection &&
        (!props.readonly || !executiveSummarySection.hidden) && (
          <VendorAssessmentV3TextCard
            copyType={"executive_summary"}
            hideSectionToggle={props.readonly}
            disableSectionToggle={props.autofillRunning}
            hideEditButton={props.readonly}
            disableEditButton={props.autofillRunning}
            title="Executive Summary"
            text={executiveSummarySection.content ?? ""}
            mergeTags={props.mergeTags}
            onSave={props.onSaveCopy}
            orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
            loading={props.loading || props.autofillRunning}
            sectionHidden={executiveSummarySection.hidden}
            onToggleHideSection={props.onToggleHideSection}
          />
        )}
      {!!vendorBackgroundSection &&
        (!props.readonly || !vendorBackgroundSection.hidden) && (
          <VendorAssessmentV3TextCard
            copyType={"background"}
            hideSectionToggle={props.readonly}
            disableSectionToggle={props.autofillRunning}
            hideEditButton={props.readonly}
            disableEditButton={props.autofillRunning}
            title="Vendor Background"
            text={vendorBackgroundSection.content ?? ""}
            mergeTags={props.mergeTags}
            onSave={props.onSaveCopy}
            orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
            loading={props.loading || props.autofillRunning}
            sectionHidden={vendorBackgroundSection.hidden}
            onToggleHideSection={props.onToggleHideSection}
          />
        )}
      {(!props.readonly || !props.assessment.hideAutofillSummary) && (
        <VendorAssessmentAutofillClassificationsSummaryCard
          vendorName={props.vendorName}
          readonly={props.readonly}
          autofillRunning={props.autofillRunning}
          title="Assessment summary"
          mergeTags={props.mergeTags}
          onSave={props.onSaveCopy}
          loading={props.loading || props.autofillRunning}
          sectionHidden={props.assessment.hideAutofillSummary}
          onToggleHideSection={props.onToggleHideSection}
          narrativeSections={props.narrativeSections}
          author={props.assessment.authorName}
          publishedDate={props.assessment.publishedAt}
          overallRating={props.assessmentScores?.overallScore}
          overallRatingDescription={
            props.assessmentScores?.overallScoreDescription
          }
          automatedScanRating={props.assessmentScores?.scanningScore}
          evidenceRating={props.assessmentScores?.surveyScore}
          risks={props.risks}
          waivers={props.waivers}
        />
      )}
      <VendorAssessmentV3RisksCard
        readonly={true}
        urlPrefix={props.urlPrefix}
        assessmentId={props.assessment.id}
        isPublishedAssessment={published}
        vendorId={props.assessment.datastoreVendorID}
        managedOrgId={props.managedOrgId}
        includeRiskWaivers={props.includeRiskWaivers}
        onOpenCloudscanPanel={props.onOpenCloudscanPanel}
        remediationRequest={props.remediationRequest}
        goToRemediationRequest={props.goToRemediationRequest}
        onToggleKeyRisk={props.onToggleKeyRisk}
        keyRisks={props.keyRisks}
        displayKeyRisks={
          !(props.readonly && (props.keyRisks?.length ?? 0) == 0)
        }
        enableKeyRisks={!props.readonly}
        calculatingRisks={
          !props.assessment.includeAllHostnames &&
          !props.scoringAndRisksCalcComplete
        }
        isManagedAssessment={
          !!props.assessment.managedAssessmentId || !!props.managedOrgId
        }
        showRiskClassification={true}
        showRiskAdjustmentJustification={true}
      />
      {!!conclusionSection && (
        <VendorAssessmentV3TextCard
          copyType={"conclusion"}
          hideSectionToggle={props.assessment.publishedAt != undefined}
          hideEditButton={props.assessment.publishedAt != undefined}
          disableEditButton={
            !!props.managedOrgId || !!props.assessment.publishedAt
          }
          editButtonToolTip={
            !!props.managedOrgId || !!props.assessment.publishedAt
              ? "Only the customer can edit this section"
              : undefined
          }
          goToTemplates={props.goToTemplates}
          title="Conclusion"
          headerSubText={
            props.assessment.publishedAt == undefined
              ? props.managedOrgId
                ? "This is where the customer will include their commentary for this risk assessment"
                : "This is where you can include any custom commentary for this risk assessment"
              : undefined
          }
          text={conclusionSection.content ?? ""}
          mergeTags={props.mergeTags}
          onSave={props.onSaveCopy}
          orgHasCustomTemplatesAccess={props.orgHasCustomTemplatesAccess}
          loading={props.loading}
          sectionHidden={conclusionSection.hidden}
          onToggleHideSection={props.onToggleHideSection}
        />
      )}
      <VendorAssessmentV3EvidenceUsedCard
        evidence={props.evidence}
        assessment={props.assessment}
        goToAdditionalEvidence={props.goToAdditionalEvidence}
        goToEvidencePage={props.goToEvidencePage}
        goToSurvey={props.goToSurvey}
        goToWaivers={props.goToWaivers}
        numRiskWaivers={waiverCount}
        numPendingPublicRiskWaivers={props.numPendingPublicRiskWaivers}
        numUnapprovedRiskWaivers={props.numUnapprovedRiskWaivers}
        orgHasSurveyScoresEnabled={props.orgHasSurveyScoresEnabled}
        onOpenDomains={props.onOpenDomainsModal}
      />
    </div>
  );
};

export default VendorAssessmentAutofillAssessmentCard;
