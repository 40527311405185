import { trackEventBackend } from "./reducers/analytics";
import { DefaultThunkDispatch } from "./types/redux";
import { isAdmin } from "../vendorrisk/helpers/roles";

// Keep a global reference to our redux store so we can access the
// dispatch function without having to pass it through everywhere.
let globalDispatch: DefaultThunkDispatch;

export const setGlobalDispatch = (dispatch: DefaultThunkDispatch) => {
  globalDispatch = dispatch;
};

export const trackEvent = (
  eventName: string,
  properties?: Record<string, any>
) => {
  if (!globalDispatch) {
    console.warn("trackEvent was called but no global dispatch is present", {
      eventName,
      properties,
    });
    return;
  }

  globalDispatch(trackEventBackend(eventName, properties));
};

export const initialiseIntercom = ({
  env,
  applianceName,
  uniqueUserId,
  uniqueUserIdHash,
  orgId,
  userId,
  orgName,
  orgCreatedAt,
  accountType,
  firstName,
  lastName,
  name,
  jobTitle,
  email,
  segmentModules,
  currentOrgRoles,
  userCreatedAt,
  orgIsFreeTrial = false,
  isImpersonating = false,
}: {
  env: string;
  applianceName: string;
  uniqueUserId?: number;
  uniqueUserIdHash?: string;
  orgId?: number;
  userId?: number;
  orgName?: string;
  orgCreatedAt?: string;
  accountType?: string;
  firstName?: string;
  lastName?: string;
  name?: string;
  jobTitle?: string;
  email?: string;
  segmentModules?: any;
  currentOrgRoles?: any;
  userCreatedAt?: string;
  orgIsFreeTrial?: boolean;
  isImpersonating?: boolean;
}) => {
  if (isImpersonating) {
    return;
  }

  if (!window.Intercom) {
    console.warn("attempted to initialise intercom but it was undefined");
    return;
  }

  let orgRoles = null;
  if (orgId && currentOrgRoles) {
    orgRoles = {
      orgRole_admin: isAdmin(currentOrgRoles),
      orgRole_breachSight_readonly:
        currentOrgRoles.includes("BreachSightReadOnly") &&
        !currentOrgRoles.includes("BreachSightFull"),
      orgRole_breachSight_write: currentOrgRoles.includes("BreachSightFull"),
      orgRole_breachSight_identityBreaches: currentOrgRoles.includes(
        "BreachSightIdentityBreaches"
      ),
      orgRole_breachSight_typosquatting: currentOrgRoles.includes(
        "BreachSightTypoSquatting"
      ),
      orgRole_vendorRisk_readonly:
        currentOrgRoles.includes("VendorRiskReadOnly") &&
        !currentOrgRoles.includes("VendorRiskFull"),
      orgRole_vendorRisk_write: currentOrgRoles.includes("VendorRiskFull"),
      orgRole_riskAssessor:
        currentOrgRoles.includes("VendorRiskCreateRiskAssessments") ||
        currentOrgRoles.includes("VendorAssessor"),
      orgRole_questionnaireCreator:
        currentOrgRoles.includes("VendorRiskCreateQuestionnaireBuilder") ||
        currentOrgRoles.includes("QuestionnaireCreator"),
      orgRole_dataLeaks:
        currentOrgRoles.includes("CyberResearchDataLeaks") ||
        currentOrgRoles.includes("BreachSight"),
      orgRole_managedVendor:
        currentOrgRoles.includes("CyberResearchManagedVendors") ||
        currentOrgRoles.includes("VendorsUnderManagementAdmin"),
    };
  }

  // This lets us see what organisation a user is a part of in Intercom. It's
  // only turned on for production to keep the data clean.
  let company =
    (env === "production" || env === "development") && orgId && orgCreatedAt
      ? {
          id: orgId,
          name: orgName,
          created_at: Math.round(new Date(orgCreatedAt).valueOf() / 1000), // Unix timestamp in seconds is required by intercom
        }
      : null;

  if (company && segmentModules) {
    company = {
      ...company,
      ...segmentModules,
    };
  }

  if (!name) {
    name = (firstName + " " + lastName).trim();
  }

  const settings = {
    app_id: window.INTERCOM_APP_ID,
    name: name,
    email,
    user_id: uniqueUserId,
    user_hash: uniqueUserIdHash,
    applianceName,
    orgName,
    accountType,
    isFreeTrial: orgIsFreeTrial,
    orgId: orgId || null,
    userId,
    firstName,
    lastName,
    jobTitle,
    userCreatedAt,
    ...orgRoles, // Intercom does not support nested objects for properties, so fill at the top level
    company, // Company is required in the top level and...
    traits: {
      company, // ...within `traits` otherwise Intercom doesn't pick it up.
    },
  };

  // @ts-ignore
  window.intercomSettings = settings;
  window.Intercom("boot", settings);
};
