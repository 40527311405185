import { ILabel, LabelColor } from "./label";
import { IItemWithLabelsAndPortfolios } from "./labelsAndPortfolios";
import {
  CloudConnectionDetail,
  CloudProviderType,
} from "../../appguard/api/types";

export interface IPAddress extends IItemWithLabelsAndPortfolios {
  ip: string;
  ipRangeID: number;
  rangeStart: string;
  rangeEnd: string;
  owner: string;
  country: string | null;
  asn: number | null;
  asName: string | null;
  services?: string[];
  score: number | null;
  hostnameID?: number;

  customRangeID?: number;
  sources: IPSource[];
  labelsForParentRange?: ILabel[];
  cloudConnections?: CloudConnectionDetail[];
}

export enum IPSource {
  DNS = "DNS",
  Owned = "Owned Range",
  Cert = "Certificate",
  CustomIP = "Custom IP",
  CustomRange = "Custom Range",
  Cloud = "Cloud",
}

export interface IPAddressDetails extends IPAddress {
  domains?: DomainScore[];
}

export interface DomainScore {
  domain: string;
  score: number;
}

export interface IPRange extends IItemWithLabelsAndPortfolios {
  // either id or customID will be set,
  // depending on whether it's a public or custom range
  id?: number;
  customID?: number;

  start: string;
  end: string;
  owner: string;
  country: string | null;
  asn: number | null;
  asName: string | null;
  ipCount: number;

  sources: Partial<Record<IPSource, true>>;
}

export const addSourcesToRange = (range: IPRange, sources: IPSource[]) => {
  sources?.forEach((s) => {
    range.sources[s] = true;
  });
};

export interface CustomIPRange extends IItemWithLabelsAndPortfolios {
  id: number;
  start: string;
  end: string;
  parentStart: string;
  parentEnd: string;
  owner: string;
  country: string | null;
  asn: number | null;
  asName: string | null;
}

export interface IPAddressFilters {
  sources: Partial<Record<IPSource, true>>;
  owners: Record<string, true>;
  asns: Record<string, true>;
  countries: Record<string, true>;
  services: Record<string, true>;
  labelIds: number[];
  portfolioIds: number[];
  labelIdsMatchAll: boolean;
  labelIdsDoNotMatch: boolean;
  includeUnlabeled: boolean;
  cloudConnectionProviderTypes: CloudProviderType[];
  cloudConnectionUUIDs: string[];
}

export const defaultIPAddressFilters = {
  sources: {},
  owners: {},
  asns: {},
  countries: {},
  services: {},
  labelIds: [],
  labelIdsDoNotMatch: false,
  labelIdsMatchAll: false,
  includeUnlabeled: false,
  portfolioIds: [],
  cloudConnectionProviderTypes: [],
  cloudConnectionUUIDs: [],
};

export const ipToInt = (ip: string): number => {
  const octets = ip.split(".").map((s) => parseInt(s, 10));
  return (
    octets[0] * Math.pow(2, 24) +
    octets[1] * Math.pow(2, 16) +
    octets[2] * Math.pow(2, 8) +
    octets[3]
  );
};

export const ipRangeKey = (range: IPRange): string =>
  `${range.start}-${range.end}`;

export const IPSourceDescriptionsCustomer = {
  [IPSource.DNS]:
    "This IP address was found in the DNS records of your domains",
  [IPSource.Owned]:
    "This IP address belongs to an IP range owned by your organization",
  [IPSource.Cert]:
    "This IP address presented an SSL certificate for one of your domains",
  [IPSource.CustomIP]: "This IP address was manually added",
  [IPSource.CustomRange]:
    "This IP address belongs to an IP range that was manually added",
  [IPSource.Cloud]:
    "This IP was discovered in one or more of your cloud environments",
};

export const IPSourceDescriptionsVendor = {
  [IPSource.DNS]:
    "This IP address was found in the DNS records of this organization's domains",
  [IPSource.Owned]:
    "This IP address belongs to an IP range owned by this organization",
  [IPSource.Cert]:
    "This IP address presented an SSL certificate for a domain owned by this organization",
};

export const IPRangeSourceDescriptionsCustomer = {
  [IPSource.DNS]:
    "This IP range contains IP addresses that were found in the DNS records of your domains",
  [IPSource.Owned]: "This IP range is owned by your organization",
  [IPSource.Cert]:
    "This IP range contains IP addresses that presented SSL certificates for your domains",
  [IPSource.CustomIP]:
    "This IP range contains IP addresses that were manually added",
  [IPSource.CustomRange]: "This IP range was manually added",
  [IPSource.Cloud]:
    "This IP was found in one or more of your cloud environments",
};

export const IPRangeSourceDescriptionsVendor = {
  [IPSource.DNS]:
    "This IP range contains IP addresses that were found in the DNS records of this organization's domains",
  [IPSource.Owned]: "This IP range is owned by this organization",
  [IPSource.Cert]:
    "This IP range contains IP addresses that presented SSL certificates for domains owned by this organization",
};

export const IPSourceColors = {
  [IPSource.DNS]: LabelColor.Blue,
  [IPSource.Owned]: LabelColor.Green,
  [IPSource.Cert]: LabelColor.Red,
  [IPSource.CustomIP]: LabelColor.Orange,
  [IPSource.CustomRange]: LabelColor.Orange,
  [IPSource.Cloud]: LabelColor.Violet,
};

// N.B. if these filters are changed, the same change must be made in ips_report_export.go
export const filterIPAddresses = (
  filters: IPAddressFilters,
  searchText: string,
  ips: IPAddress[]
): IPAddress[] => {
  return ips.filter((ip) => {
    if (
      Object.keys(filters.sources).length &&
      !ip.sources?.some((s) => filters.sources[s])
    ) {
      return false;
    }
    if (Object.keys(filters.owners).length && !filters.owners[ip.owner]) {
      return false;
    }
    if (
      Object.keys(filters.asns).length &&
      (!ip.asn || !filters.asns[ip.asn])
    ) {
      return false;
    }
    if (
      Object.keys(filters.countries).length &&
      (!ip.country || !filters.countries[ip.country])
    ) {
      return false;
    }
    if (
      Object.keys(filters.services).length &&
      !ip.services?.some((s) => filters.services[s])
    ) {
      return false;
    }
    if (filters.includeUnlabeled) {
      if (filters.labelIdsDoNotMatch) {
        if (!ip.labels?.length) {
          return false;
        }
      } else if (!filters.labelIds.length && ip.labels?.length) {
        return false;
      }
    } else if (
      !filters.labelIdsDoNotMatch &&
      filters.labelIds.length &&
      !ip.labels
    ) {
      return false;
    }
    if (filters.labelIds.length) {
      if (
        !filters.includeUnlabeled && // Unlabeled is incompatible with match all
        filters.labelIdsMatchAll &&
        !filters.labelIds.every((l) => ip.labels?.some((il) => il.id === l))
      ) {
        return false;
      }
      if (
        filters.labelIdsDoNotMatch &&
        ip.labels?.some((l) => filters.labelIds.includes(l.id))
      ) {
        return false;
      } else if (
        !filters.labelIdsDoNotMatch &&
        ip.labels?.length &&
        !ip.labels?.some((l) => filters.labelIds.includes(l.id))
      ) {
        return false;
      }
    }
    if (
      filters.portfolioIds.length &&
      (!ip.portfolios ||
        !ip.portfolios.some((p) => filters.portfolioIds.includes(p.id)))
    ) {
      return false;
    }

    if (
      filters.cloudConnectionProviderTypes.length &&
      !ip.cloudConnections?.some((cc) =>
        filters.cloudConnectionProviderTypes.includes(cc.connectionProviderType)
      )
    ) {
      return false;
    }

    if (
      filters.cloudConnectionUUIDs.length &&
      !ip.cloudConnections?.some((cc) =>
        filters.cloudConnectionUUIDs.includes(cc.connectionUUID)
      )
    ) {
      return false;
    }

    if (searchText) {
      const toSearch: string[] = [
        ...(ip.sources || []),
        ip.ip,
        ip.rangeStart,
        ip.rangeEnd,
        ip.owner,
        `AS${ip.asn}`,
        ip.asName || "",
        ip.country || "",
        ...(ip.services || []),
      ].map((s) => s.toLowerCase());
      if (!toSearch.some((s) => s.includes(searchText.toLowerCase()))) {
        return false;
      }
    }
    return true;
  });
};

export const filterIPRanges = (
  filters: IPAddressFilters,
  searchText: string,
  ranges: IPRange[]
): IPRange[] => {
  return ranges.filter((range) => {
    if (
      Object.keys(filters.sources).length &&
      !Object.keys(range.sources).some((s) => filters.sources[s as IPSource])
    ) {
      return false;
    }
    if (Object.keys(filters.owners).length && !filters.owners[range.owner]) {
      return false;
    }
    if (
      Object.keys(filters.asns).length &&
      (!range.asn || !filters.asns[range.asn])
    ) {
      return false;
    }
    if (
      Object.keys(filters.countries).length &&
      (!range.country || !filters.countries[range.country])
    ) {
      return false;
    }
    if (filters.labelIds.length) {
      if (
        !filters.includeUnlabeled && // Unlabeled is incompatible with match all
        filters.labelIdsMatchAll &&
        !filters.labelIds.every((l) => range.labels?.some((il) => il.id === l))
      ) {
        return false;
      }
      if (
        filters.labelIdsDoNotMatch &&
        range.labels?.some((l) => filters.labelIds.includes(l.id))
      ) {
        return false;
      } else if (
        !filters.labelIdsDoNotMatch &&
        range.labels?.length &&
        !range.labels?.some((l) => filters.labelIds.includes(l.id))
      ) {
        return false;
      }
    }
    if (
      filters.portfolioIds.length &&
      (!range.portfolios ||
        !range.portfolios.some((l) => filters.portfolioIds.includes(l.id)))
    ) {
      return false;
    }

    if (searchText) {
      const toSearch: string[] = [
        ...Object.keys(range.sources),
        range.start,
        range.end,
        range.owner,
        `AS${range.asn}`,
        range.asName || "",
        range.country || "",
      ].map((s) => s.toLowerCase());
      if (!toSearch.some((s) => s.includes(searchText.toLowerCase()))) {
        return false;
      }
    }
    return true;
  });
};
