import NavLink, { NewFeatureInAppMessagePopupContent } from "../NavLink";
import { pageDescriptions } from "../../pageDescriptions";
import * as Permissions from "../../permissions";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import { getSubtitleDisplay } from "../../helpers/string.helpers";
import Icon from "../core/Icon";
import {
  AssuranceType,
  organisationAccountType,
} from "../../types/organisations";
import { InAppMessageType } from "../InAppMessage";
import {
  getUserPermissionsForVendor,
  GroupAccessVendorAssetSharing,
  UserWriteVendorAssessments,
} from "../../permissions";
import { FC, useEffect } from "react";
import { useCurrentOrg } from "../../selectors/commonSelectors";
import { useAppDispatch, useAppSelector } from "../../types/reduxHooks";
import classnames from "classnames";
import { matchPath, useHistory } from "react-router-dom";
import { setViewingVendorId } from "../../reducers/commonActions";
import { useVendorWords } from "../../hooks";
import { isExpiredTrial } from "../../helpers/userOrg.helpers";

const getVendorIdFromPath = (pathname: string) => {
  const isOnVendorRouteMatch = matchPath<{ vendorId: string }>(pathname, {
    path: "/vendor/:vendorId",
  });
  if (isOnVendorRouteMatch) {
    return parseInt(isOnVendorRouteMatch.params.vendorId);
  }

  return undefined;
};

type VendorRiskNavItemsProps = {
  clickNavLinkFunc: (
    path: string,
    linkName: string,
    viewingVendorId?: string
  ) => () => void;
};

const VendorRiskNavItems: FC<VendorRiskNavItemsProps> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const currentOrg = useCurrentOrg();
  const userData = useAppSelector((state) => state.common.userData);

  const hasUserPermission = (perm: string) =>
    userData.userPermissions.includes(perm);
  const hasOrgPermission = (perm: string) =>
    userData.orgPermissions.includes(perm);

  const currentOrgIsInAccountGroup = currentOrg?.organisationGroupId;
  const currentOrgAccountGroupEntitlements =
    currentOrg?.organisationGroupEntitlements || [];
  const currentOrgIsFreeOrg =
    currentOrg?.accountType === organisationAccountType.free;

  const assuranceType = userData.assuranceType;
  const vendorWords = useVendorWords();

  const activeVendorImport = useAppSelector(
    (state) => state.cyberRisk.vendorImport
  );

  const hasActiveVendorComparison = useAppSelector((state) => {
    return (
      state.cyberRisk.vendorComparisonVendorIds?.some((v) => v !== 0) ?? false
    );
  });

  const viewingVendorId =
    useAppSelector((state) => state.common.viewingVendorId) ?? undefined;

  const canViewViewingVendor = useAppSelector((state) => {
    return viewingVendorId
      ? state.cyberRisk.vendors[viewingVendorId]?.watching?.result?.canView ??
          false
      : false;
  });

  let selectedVendorId = getVendorIdFromPath(history.location?.pathname);
  const isOnVendorRoute = !!selectedVendorId;
  if (!selectedVendorId) {
    selectedVendorId = viewingVendorId;
  }

  const vendorName = useAppSelector((state) => {
    if (selectedVendorId) {
      return state.cyberRisk.vendors[selectedVendorId]?.display_name;
    }

    return undefined;
  });

  useEffect(() => {
    dispatch(setViewingVendorId(selectedVendorId));
  }, [selectedVendorId]);

  const showVendorMenu =
    selectedVendorId &&
    canViewViewingVendor &&
    (hasOrgPermission(Permissions.OrgAccessVendors) ||
      hasOrgPermission(Permissions.OrgAccessSurveys));

  const closeVendorMenu = (isOnVendorRoute: boolean) => {
    dispatch(setViewingVendorId(null));
    if (isOnVendorRoute) {
      history.push("/vendorlist");
    }
  };

  // Get selected vendor from state
  const selectedVendor = useAppSelector((state) =>
    selectedVendorId ? state.cyberRisk.vendors[selectedVendorId] : undefined
  );

  let userPermsForVendor;
  let riskAssessmentPublished = false;
  let sharedRiskAssessmentAvailable = false;
  let sharedAssessmentPublished = false;
  const primaryHostname = selectedVendor?.primary_hostname;
  const hasWebPresence =
    (selectedVendor?.summary?.result?.totalCloudscans ?? 0) +
      (selectedVendor?.summary?.result?.totalInactiveDomains ?? 0) >
    0;

  if (selectedVendorId) {
    userPermsForVendor = getUserPermissionsForVendor(
      selectedVendorId,
      userData,
      { [selectedVendorId]: selectedVendor }
    );

    riskAssessmentPublished =
      !!selectedVendor?.summary?.result?.assessmentSummary;
    sharedRiskAssessmentAvailable =
      (selectedVendor?.summary?.result?.numSharedRiskAssessments ?? 0) > 0;
    sharedAssessmentPublished = !!selectedVendor?.sharedAssessmentPublished;
  }

  const userHasOrgCollaboration = userData.hasSurveyCollaboration;
  const currentOrgIsDistributor =
    userData.currentOrgRoles &&
    userData.currentOrgRoles.includes("Distributor");

  const showCyberResearch =
    !currentOrgIsDistributor &&
    assuranceType !== AssuranceType.MSSP &&
    ((hasOrgPermission(Permissions.OrgAccessBreachSight) &&
      hasUserPermission(Permissions.UserAccessDataLeaks)) ||
      (hasOrgPermission(Permissions.OrgAccessVendorDataLeaks) &&
        hasUserPermission(Permissions.UserAccessDataLeaks)) ||
      (hasOrgPermission(Permissions.OrgAccessVendorsUnderManagement) &&
        hasUserPermission(Permissions.UserManageVendorsUnderManagement)) ||
      (!currentOrgIsFreeOrg &&
        userData.currentOrgID !== 0 &&
        !hasOrgPermission(Permissions.OrgAccessBreachSight) &&
        !hasOrgPermission(Permissions.OrgAccessVendorDataLeaks) &&
        !hasOrgPermission(Permissions.OrgAccessVendorsUnderManagement)));
  const userHasVendorRisk =
    hasUserPermission(Permissions.UserVendorRiskEnabled) ||
    Object.keys(userData.vendorPortfolioSpecificPermissions).length > 0;
  const showVendorRisk =
    userHasVendorRisk &&
    (hasOrgPermission(Permissions.OrgAccessVendors) ||
      hasOrgPermission(Permissions.OrgAccessSurveys));

  const showVendorPortalSharedWithMeMenuItem =
    (userData.currentOrgID === 0 || currentOrgIsFreeOrg) &&
    userData.hasSharedAssessmentsAccess;
  const showAppSharedWithMeMenuItem =
    assuranceType === AssuranceType.None &&
    userData.currentOrgID > 0 &&
    !currentOrgIsFreeOrg &&
    !currentOrgIsDistributor;
  const showSharedWithMeMenuItem =
    showVendorPortalSharedWithMeMenuItem || showAppSharedWithMeMenuItem;

  const trialExpired = isExpiredTrial(currentOrg);

  // Reinstate this when re-adding vendor limit warning
  // const vendorWatchLimitIsHard = currentOrg?.vendorWatchLimitIsHard;
  // const vendorWatchLimit = currentOrg?.vendorWatchLimit ?? 0;
  // const vendorWatchCount = currentOrg?.vendorWatchCount ?? 0;
  // const vendorImmutableCount = 0; // TODO: get this value: currentOrg?.vendorImmutableCount;

  // const isAtVendorLimit =
  //   vendorWatchLimitIsHard &&
  //   vendorWatchLimit <=
  //     vendorWatchCount - (vendorImmutableCount ? vendorImmutableCount : 0);

  return (
    <>
      <div className="product-name">{vendorWords.vendorRiskModuleName}</div>
      <div className="product-navigation-items">
        {showVendorRisk && (
          <>
            <NavLink
              className="unstyled"
              disabled={trialExpired}
              text={vendorWords.vendorRiskExecutiveSummaryPageTitle}
              hoverText={pageDescriptions.ExecutiveSummaryReport}
              onClick={props.clickNavLinkFunc(
                "/summaryreport/vendorrisk",
                vendorWords.vendorRiskExecutiveSummaryPageTitle
              )}
              to="/summaryreport/vendorrisk"
            />
            <NavLink
              className="unstyled"
              disabled={
                trialExpired || hasOrgPermission(Permissions.OrgHideVendorRisks)
              }
              text={vendorWords.portfolioRiskProfilePageTitle}
              hoverText={pageDescriptions.VendorPortfolioRiskProfile}
              exact
              onClick={props.clickNavLinkFunc(
                "/vendor_portfolio",
                vendorWords.portfolioRiskProfilePageTitle
              )}
              to="/vendor_portfolio"
            />
          </>
        )}
        <div className="section-divider">
          <hr />
        </div>
        <div className="section-header">
          <span className="section-header-text">Workflows</span>
        </div>
        {currentOrgIsFreeOrg && (
          <NavLink
            className="unstyled"
            text={vendorWords.vendorsPageTitle}
            hoverText={pageDescriptions.Portfolio}
            onClick={props.clickNavLinkFunc(
              "/vendorlist",
              vendorWords.vendorsPageTitle
            )}
            to="/vendorlist"
          />
        )}

        {!showVendorRisk && userHasOrgCollaboration && (
          <NavLink
            className="unstyled"
            disabled={trialExpired}
            text="Questionnaires"
            hoverText={pageDescriptions.Questionnaires}
            onClick={props.clickNavLinkFunc(
              "/surveycollaboration",
              "Questionnaires"
            )}
            to="/surveycollaboration"
          />
        )}

        {showVendorRisk && hasOrgPermission(Permissions.OrgAccessSurveys) && (
          <>
            <NavLink
              className="unstyled"
              disabled={trialExpired}
              text="Questionnaires"
              hoverText={pageDescriptions.Questionnaires}
              onClick={props.clickNavLinkFunc("/surveys", "Questionnaires")}
              to="/surveys"
            />
            <NavLink
              className="unstyled"
              disabled={trialExpired}
              text="Questionnaire Library"
              hoverText={pageDescriptions.QuestionnaireLibrary}
              isSubMenuItem
              onClick={props.clickNavLinkFunc(
                "/surveybuilder/library",
                "Questionnaire Library"
              )}
              to="/surveybuilder/library"
            />
            {assuranceType !== AssuranceType.Assurance && (
              <NavLink
                className="unstyled"
                disabled={trialExpired}
                text="Remediation"
                hoverText={pageDescriptions.Remediation}
                onClick={props.clickNavLinkFunc("/remediation", "Remediation")}
                to="/remediation"
              />
            )}
          </>
        )}

        {showVendorRisk && (
          <>
            <div className="section-divider">
              <hr />
            </div>
            <div className="section-header">
              <span className="section-header-text">Inventory</span>
            </div>

            {hasOrgPermission(Permissions.OrgAccessVendors) && (
              <NavLink
                className="unstyled"
                disabled={trialExpired}
                text={
                  <>
                    {vendorWords.vendorsPageTitle}
                    {activeVendorImport && activeVendorImport.status === 1 && (
                      <>
                        {" "}
                        <PillLabel color={LabelColor.LightBlue} filled>
                          Importing
                        </PillLabel>
                      </>
                    )}
                  </>
                }
                hoverText={pageDescriptions.Portfolio}
                // inAppAlertText={
                //   isAtVendorLimit ? (
                //     <>
                //       You&apos;ve reached your {vendorWords.singular} limit.
                //       <br />
                //       <a onClick={handleContactUs}>Contact us</a>
                //       &nbsp;to customize your plan.
                //     </>
                //   ) : undefined
                // } // TODO: reinstate this
                onClick={props.clickNavLinkFunc(
                  "/vendorlist",
                  vendorWords.vendorsPageTitle
                )}
                to="/vendorlist"
              />
            )}
            {(hasActiveVendorComparison ||
              history.location?.pathname === "/vendorcomparison") && (
              <NavLink
                className="unstyled"
                disabled={trialExpired}
                text="Comparison"
                hoverText={pageDescriptions.VendorComparison}
                isSubMenuItem
                onClick={props.clickNavLinkFunc(
                  "/vendorcomparison",
                  "Comparison"
                )}
                to="/vendorcomparison"
              />
            )}
            {showVendorMenu && (
              <>
                <div
                  className={classnames("nav-sub-section", {
                    active: isOnVendorRoute,
                  })}
                >
                  <div
                    className={classnames("subtitle header-section-name", {
                      active: isOnVendorRoute,
                    })}
                    onClick={() => closeVendorMenu(isOnVendorRoute)}
                  >
                    <div className="subtitle vendor-name">
                      {getSubtitleDisplay(vendorName ?? "", 22)}
                    </div>
                    <Icon name="x" />
                  </div>
                  <NavLink
                    className="unstyled"
                    disabled={trialExpired}
                    text={`${vendorWords.singularTitleCase} Summary`}
                    hoverText={pageDescriptions.VendorSummary}
                    onClick={props.clickNavLinkFunc(
                      `/vendor/${selectedVendorId}/summary`,
                      `${vendorWords.singularTitleCase} Summary`,
                      selectedVendorId?.toString()
                    )}
                    to={`/vendor/${selectedVendorId}/summary`}
                  />
                  <NavLink
                    className="unstyled"
                    disabled={
                      trialExpired ||
                      hasOrgPermission(Permissions.OrgHideVendorRisks)
                    }
                    text="Risk Profile"
                    hoverText={pageDescriptions.VendorRiskProfile}
                    onClick={props.clickNavLinkFunc(
                      `/vendor/${selectedVendorId}/risk_profile`,
                      "Portfolio Risk Profile",
                      selectedVendorId?.toString()
                    )}
                    to={`/vendor/${selectedVendorId}/risk_profile`}
                  />
                  {assuranceType === AssuranceType.None && (
                    <>
                      {/* Risk waivers appear later in the navigation for assurance orgs */}
                      <NavLink
                        className="unstyled"
                        disabled={trialExpired}
                        text={"Modified Risks"}
                        hoverText={pageDescriptions.RiskWaivers}
                        onClick={props.clickNavLinkFunc(
                          `/vendor/${selectedVendorId}/riskwaivers`,
                          "Modified Risks",
                          selectedVendorId?.toString()
                        )}
                        to={`/vendor/${selectedVendorId}/riskwaivers`}
                        inAppMessageType={
                          InAppMessageType.VendorRiskAdjustmentsNewFeature
                        }
                        inAppMessageText={
                          <NewFeatureInAppMessagePopupContent
                            featureName="Severity Adjustments"
                            description="You can now adjust the severity of risks and manage risk waivers here."
                            learnMoreLink={
                              "https://help.upguard.com/en/articles/8668528-how-to-adjust-the-severity-of-a-risk"
                            }
                          />
                        }
                      />
                      <NavLink
                        className="unstyled"
                        disabled={trialExpired}
                        text="Remediation"
                        hoverText={pageDescriptions.VendorRemediation}
                        onClick={props.clickNavLinkFunc(
                          `/vendor/${selectedVendorId}/remediation`,
                          "Vendor Remediation",
                          selectedVendorId?.toString()
                        )}
                        to={`/vendor/${selectedVendorId}/remediation`}
                      />
                    </>
                  )}
                  {hasOrgPermission(Permissions.OrgAccessVendorAssessments) &&
                  ((userPermsForVendor &&
                    userPermsForVendor[UserWriteVendorAssessments]) ||
                    riskAssessmentPublished ||
                    sharedRiskAssessmentAvailable) ? (
                    <NavLink
                      className="unstyled"
                      disabled={trialExpired}
                      text="Risk Assessments"
                      hoverText={pageDescriptions.VendorAssessment}
                      onClick={props.clickNavLinkFunc(
                        `/vendor/${selectedVendorId}/assessment`,
                        "Vendor Risk Assessment",
                        selectedVendorId?.toString()
                      )}
                      to={`/vendor/${selectedVendorId}/assessment`}
                    />
                  ) : null}

                  <>
                    {(primaryHostname || hasWebPresence) && (
                      <>
                        <NavLink
                          className="unstyled"
                          disabled={
                            trialExpired ||
                            hasOrgPermission(Permissions.OrgHideVendorRisks)
                          }
                          text="Domains"
                          hoverText={pageDescriptions.VendorWebsitesAndAPIs}
                          exact
                          onClick={props.clickNavLinkFunc(
                            `/vendor/${selectedVendorId}/webscans`,
                            "Domains",
                            selectedVendorId?.toString()
                          )}
                          to={`/vendor/${selectedVendorId}/webscans`}
                        />
                        <NavLink
                          className="unstyled"
                          disabled={
                            trialExpired ||
                            hasOrgPermission(Permissions.OrgHideVendorRisks)
                          }
                          text="IP Addresses"
                          hoverText={pageDescriptions.VendorIPAddresses}
                          exact
                          onClick={props.clickNavLinkFunc(
                            `/vendor/${selectedVendorId}/ips`,
                            "IP Addresses",
                            selectedVendorId?.toString()
                          )}
                          to={`/vendor/${selectedVendorId}/ips`}
                        />
                      </>
                    )}
                  </>

                  {hasOrgPermission(Permissions.OrgAccessSurveys) && (
                    <NavLink
                      className="unstyled"
                      disabled={trialExpired}
                      text="Questionnaires"
                      hoverText={pageDescriptions.VendorQuestionnaires}
                      onClick={props.clickNavLinkFunc(
                        `/vendor/${selectedVendorId}/surveys`,
                        "Vendor Questionnaires",
                        selectedVendorId?.toString()
                      )}
                      to={`/vendor/${selectedVendorId}/surveys`}
                    />
                  )}
                  {hasOrgPermission(
                    Permissions.OrgAccessAdditionalEvidence
                  ) && (
                    <NavLink
                      className="unstyled"
                      disabled={trialExpired}
                      text="Additional Evidence"
                      hoverText={pageDescriptions.AdditionalEvidence}
                      onClick={props.clickNavLinkFunc(
                        "/vendor/${selectedVendorId}/evidence",
                        "Additional Evidence"
                      )}
                      to={`/vendor/${selectedVendorId}/evidence`}
                    />
                  )}
                  {hasOrgPermission(Permissions.OrgAccessVulns) && (
                    <>
                      {(primaryHostname || hasWebPresence) && (
                        <>
                          <NavLink
                            className="unstyled"
                            disabled={
                              trialExpired ||
                              hasOrgPermission(Permissions.OrgHideVendorRisks)
                            }
                            text="Vulnerabilities"
                            hoverText={pageDescriptions.VendorVulns}
                            exact
                            onClick={props.clickNavLinkFunc(
                              `/vendor/${selectedVendorId}/vulns`,
                              "Vendor Vulnerabilities"
                            )}
                            to={`/vendor/${selectedVendorId}/vulns`}
                          />
                        </>
                      )}
                    </>
                  )}
                  {assuranceType === AssuranceType.None &&
                    hasOrgPermission(
                      Permissions.OrgAccessVendorTechnologies
                    ) && (
                      // Fourth parties appears later in the sort order for assurance orgs

                      <>
                        {(primaryHostname || hasWebPresence) && (
                          <>
                            <NavLink
                              className="unstyled"
                              disabled={trialExpired}
                              text="Fourth Parties"
                              hoverText={pageDescriptions.SupplyChain}
                              onClick={props.clickNavLinkFunc(
                                `/vendor/${selectedVendorId}/fourthParty`,
                                "Vendor Fourth Parties",
                                selectedVendorId?.toString()
                              )}
                              to={`/vendor/${selectedVendorId}/fourthParty`}
                            />
                          </>
                        )}
                      </>
                    )}
                  {currentOrgIsInAccountGroup &&
                  currentOrgAccountGroupEntitlements.includes(
                    GroupAccessVendorAssetSharing
                  ) ? (
                    <NavLink
                      className="unstyled"
                      disabled={trialExpired}
                      text="Shared Assets"
                      hoverText={pageDescriptions.VendorSharedAssets}
                      onClick={props.clickNavLinkFunc(
                        `/vendor/${selectedVendorId}/sharedassets`,
                        "Vendor Shared Assets",
                        selectedVendorId?.toString()
                      )}
                      to={`/vendor/${selectedVendorId}/sharedassets`}
                    />
                  ) : sharedAssessmentPublished ? (
                    <NavLink
                      className="unstyled"
                      disabled={trialExpired}
                      text="Trust Page"
                      hoverText={pageDescriptions.SharedAssessment}
                      onClick={props.clickNavLinkFunc(
                        `/vendor/${selectedVendorId}/sharedassessment`,
                        "Trust Page",
                        selectedVendorId?.toString()
                      )}
                      to={`/vendor/${selectedVendorId}/sharedassessment`}
                    />
                  ) : null}
                  {assuranceType !== AssuranceType.None &&
                    hasOrgPermission(
                      Permissions.OrgAccessVendorTechnologies
                    ) && (
                      // Fourth parties appears earlier in the sort order for non-assurance orgs
                      <>
                        {(primaryHostname || hasWebPresence) && (
                          <>
                            <NavLink
                              className="unstyled"
                              disabled={trialExpired}
                              text="Fourth Parties"
                              hoverText={pageDescriptions.SupplyChain}
                              onClick={props.clickNavLinkFunc(
                                `/vendor/${selectedVendorId}/fourthParty`,
                                "Vendor Fourth Parties",
                                selectedVendorId?.toString()
                              )}
                              to={`/vendor/${selectedVendorId}/fourthParty`}
                            />
                          </>
                        )}
                      </>
                    )}
                  {assuranceType !== AssuranceType.None && (
                    <NavLink
                      className="unstyled"
                      disabled={trialExpired}
                      text={"Modified Risks"}
                      hoverText={pageDescriptions.RiskWaivers}
                      onClick={props.clickNavLinkFunc(
                        `/vendor/${selectedVendorId}/riskwaivers`,
                        "Modified Risks",
                        selectedVendorId?.toString()
                      )}
                      to={`/vendor/${selectedVendorId}/riskwaivers`}
                    />
                  )}
                  {assuranceType !== AssuranceType.MSSP && (
                    <>
                      {/* Hide documents and contacts for MSSPs only */}
                      <NavLink
                        className="unstyled"
                        disabled={trialExpired}
                        text="Documents"
                        hoverText={pageDescriptions.VendorDocuments}
                        exact
                        onClick={props.clickNavLinkFunc(
                          `/vendor/${selectedVendorId}/documents`,
                          "Vendor Documents",
                          selectedVendorId?.toString()
                        )}
                        to={`/vendor/${selectedVendorId}/documents`}
                      />
                      <NavLink
                        className="unstyled"
                        disabled={trialExpired}
                        text="Contacts"
                        hoverText={pageDescriptions.VendorContacts}
                        exact
                        onClick={props.clickNavLinkFunc(
                          `/vendor/${selectedVendorId}/contacts`,
                          "Vendor Contacts",
                          selectedVendorId?.toString()
                        )}
                        to={`/vendor/${selectedVendorId}/contacts`}
                      />
                    </>
                  )}
                  {assuranceType === AssuranceType.MSSP && (
                    <>
                      {/* Show Remediation at the bottom for MSSP */}
                      <NavLink
                        className="unstyled"
                        disabled={trialExpired}
                        text="Remediation"
                        hoverText={pageDescriptions.VendorRemediation}
                        onClick={props.clickNavLinkFunc(
                          `/vendor/${selectedVendorId}/remediation`,
                          "Vendor Remediation",
                          selectedVendorId?.toString()
                        )}
                        to={`/vendor/${selectedVendorId}/remediation`}
                      />
                    </>
                  )}
                </div>
              </>
            )}

            {hasOrgPermission(Permissions.OrgAccessVendorTechnologies) && (
              <NavLink
                className="unstyled"
                disabled={trialExpired}
                text="Fourth Parties"
                hoverText={pageDescriptions.ConcentrationRisk}
                exact
                onClick={props.clickNavLinkFunc(
                  "/concentrationrisk",
                  "Fourth Parties"
                )}
                to="/concentrationrisk"
              />
            )}
            {showSharedWithMeMenuItem && (
              <NavLink
                className="unstyled"
                text="Shared With Me"
                hoverText={pageDescriptions.SharedAssessmentsList}
                onClick={props.clickNavLinkFunc(
                  "/sharedassessments",
                  "Shared With Me"
                )}
                to="/sharedassessments"
              />
            )}
            {showCyberResearch && (
              <>
                {hasOrgPermission(Permissions.OrgAccessVendorDataLeaks) && (
                  <NavLink
                    className="unstyled"
                    disabled={trialExpired}
                    text={`${vendorWords.singularTitleCase} Data Leaks`}
                    hoverText={pageDescriptions.VendorDataLeaks}
                    onClick={props.clickNavLinkFunc(
                      "/vendordataleaks",
                      `${vendorWords.singularTitleCase} Data Leaks`
                    )}
                    to="/vendordataleaks"
                    featurePreviewAvailable
                    hasOrgPermission={hasOrgPermission(
                      Permissions.OrgAccessVendorDataLeaks
                    )}
                    hasUserPermission={hasUserPermission(
                      Permissions.UserAccessDataLeaks
                    )}
                  />
                )}
                <NavLink
                  className="unstyled"
                  disabled={trialExpired}
                  text={`Managed Assessments`}
                  hoverText={pageDescriptions.CyberResearchManagedVendors}
                  onClick={props.clickNavLinkFunc(
                    "/managedvendors",
                    `Managed Assessments`
                  )}
                  to="/managedvendors"
                  featurePreviewAvailable
                  hasOrgPermission={hasOrgPermission(
                    Permissions.OrgAccessVendorsUnderManagement
                  )}
                  hasUserPermission={hasUserPermission(
                    Permissions.UserManageVendorsUnderManagement
                  )}
                  isActive={(_, location) =>
                    !!matchPath(location.pathname, {
                      path: "/managedvendors",
                      exact: true,
                    }) ||
                    !!matchPath(location.pathname, {
                      path: "/vendor/:vendorId/managedservice/:managedServiceId",
                      exact: true,
                    })
                  }
                  inAppMessageType={
                    InAppMessageType.ManagedAssessmentNewFeature
                  }
                  inAppMessageText={
                    <NewFeatureInAppMessagePopupContent
                      featureName="Managed Assessments"
                      description="Partner with an UpGuard analyst and put your vendor assessments on autopilot. "
                      learnMoreLink="https://help.upguard.com/en/articles/4610228-how-to-add-a-managed-vendor"
                    />
                  }
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default VendorRiskNavItems;
