import { FC } from "react";
import classnames from "classnames";
import "../../style/components/navigation/ProductSpecificNavigation.scss";
import { trackEvent } from "../../tracking";
import { product } from "../../types/products";
import VendorRiskNavItems from "./VendorRiskNavItems";

interface ProductSpecificNavigationProps {
  open: boolean;
  selectedProduct?: product;
}

const ProductSpecificNavigation: FC<ProductSpecificNavigationProps> = (
  props
) => {
  const clickNavLinkFunc =
    (pathTo: any, linkName: any, viewingVendorId: any) => () => {
      // then track an event
      const properties: Record<string, any> = { pathTo, linkName };
      if (viewingVendorId) {
        properties.viewingVendorId = viewingVendorId;
      }

      trackEvent("navigation menu item clicked", properties);
    };

  return (
    <div
      className={classnames("product-specific-navigation", {
        open: props.open,
      })}
    >
      {props.open && props.selectedProduct === "breachsight" && "COMING SOON!"}
      {props.open && props.selectedProduct === "vendor_risk" && (
        <VendorRiskNavItems clickNavLinkFunc={clickNavLinkFunc} />
      )}
      {props.open &&
        props.selectedProduct === "trust_exchange" &&
        "COMING SOON!"}
      {props.open && props.selectedProduct === "user_risk" && "COMING SOON!"}
    </div>
  );
};

export default ProductSpecificNavigation;
