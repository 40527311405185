import NavLink from "../_common/components/NavLink";
import { pageDescriptions } from "../_common/pageDescriptions";

import { FC, useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import Icon from "../_common/components/core/Icon";
import { getSubtitleDisplay } from "../_common/helpers/string.helpers";
import {
  useHasOrgEntitlement,
  useHasUserOrPortfolioPermissions,
  UserUserBaseEnabled,
  UserUserBaseWrite,
} from "../_common/permissions";
import * as Permissions from "../_common/permissions";
import {
  adminUrl,
  applicationsUrl,
  appPermissionsUrl,
  appSummaryUrl,
  appUrlPrefix,
  appUsersUrl,
  dashboardUrl,
  identityBreachesUrl,
  identityBreachesUrlPrefix,
  identityBreachSummaryUrl,
  permissionsUrl,
  riskModificationUrl,
  riskProfileUrl,
  userApplicationsUrl,
  userPermissionsUrl,
  userRiskProfileUrl,
  userSummaryUrl,
  usersUrl,
  userUrlPrefix,
} from "./UserBaseAppRouter";
import userbaseApi from "./api/userbase.api";
import { useAppDispatch, useAppSelector } from "../_common/types/reduxHooks";
import { addDefaultUnknownErrorAlert } from "../_common/reducers/messageAlerts.actions";
import { isAdmin } from "../vendorrisk/helpers/roles";

export interface AppRouteParams {
  appName: string;
}

// getAppNameFromPath gets an app name from a given route path.
// If this is not a UserBase app route, undefined is returned.
const getAppNameFromPath = (pathname: string) => {
  const isOnAppRouteMatch = matchPath<AppRouteParams>(pathname, {
    path: `${appUrlPrefix}/:appName`,
  });
  return isOnAppRouteMatch ? isOnAppRouteMatch.params.appName : undefined;
};

export interface UserRouteParams {
  userUUID: string;
}

// getUserUUIDFromPath gets a user UUID from a given route path.
// If this is not a UserBase user route, undefined is returned.
const getUserUUIDFromPath = (pathname: string) => {
  const isOnUserRouteMatch = matchPath<UserRouteParams>(pathname, {
    path: `${userUrlPrefix}/:userUUID`,
  });
  return isOnUserRouteMatch ? isOnUserRouteMatch.params.userUUID : undefined;
};

export interface IdentityBreachRouteParams {
  breachID: string;
}

// getBreachIDFromPath gets a breach ID from a given route path.
// If this is not a UserBase identity breach route, undefined is returned.
export const getBreachIDFromPath = (pathname: string) => {
  const isOnIdentityBreachRouteMatch = matchPath<IdentityBreachRouteParams>(
    pathname,
    {
      path: `${identityBreachesUrlPrefix}/:breachID`,
    }
  );
  if (!isOnIdentityBreachRouteMatch) {
    return undefined;
  }

  const breachIDStr = isOnIdentityBreachRouteMatch.params.breachID;
  if (
    // check that the breach ID param is:
    // - a positive number
    // - not starting with zero
    // - max of 15 digits (max representable JSON number is 16 digits anyway so we would have problems already if we reach this)
    !/^[1-9][0-9]{0,14}$/.test(isOnIdentityBreachRouteMatch.params.breachID)
  ) {
    return undefined;
  }

  return Number(breachIDStr);
};

interface UserBaseNavItemsProps {
  clickNavLinkFunc: (path: string, linkName: string, _?: number) => () => void;
}

const UserBaseNavItems: FC<UserBaseNavItemsProps> = ({ clickNavLinkFunc }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [getUserSummary] = userbaseApi.useLazyGetUserBaseUserSummaryV1Query();
  const [getIdentityBreachSummary] =
    userbaseApi.useLazyGetUserBaseIdentityBreachSummaryV1Query();

  const [selectedAppName, setSelectedAppName] = useState<string | undefined>(
    undefined
  );

  const [selectedUserUUID, setSelectedUserUUID] = useState<string | undefined>(
    undefined
  );
  const [selectedUserName, setSelectedUserName] = useState<string | undefined>(
    undefined
  );

  const [selectedBreachID, setSelectedBreachID] = useState<number | undefined>(
    undefined
  );
  const [selectedBreachName, setSelectedBreachName] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    const appName = getAppNameFromPath(location.pathname);
    if (appName) {
      setSelectedAppName(appName);
    }
  }, [location.pathname]);

  useEffect(() => {
    const userUUID = getUserUUIDFromPath(location.pathname);
    if (userUUID) {
      setSelectedUserUUID(userUUID);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selectedUserUUID) {
      getUserSummary({
        userUUID: selectedUserUUID,
      })
        .unwrap()
        .then((data) => setSelectedUserName(data.user.name))
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("An unknown error occurred"));
        });
    }
  }, [selectedUserUUID, getUserSummary, dispatch]);

  useEffect(() => {
    const breachID = getBreachIDFromPath(location.pathname);
    if (breachID) {
      setSelectedBreachID(breachID);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (selectedBreachID) {
      getIdentityBreachSummary({
        breachID: selectedBreachID,
      })
        .unwrap()
        .then((data) => setSelectedBreachName(data.breach.name))
        .catch((e) => {
          console.error(e);
          dispatch(addDefaultUnknownErrorAlert("An unknown error occurred"));
        });
    }
  }, [selectedBreachID, getIdentityBreachSummary, dispatch]);

  const isOnAppRoute = !!selectedAppName;
  const isOnUserRoute = !!selectedUserUUID;
  const isOnIdentityBreachRoute = !!selectedBreachID;

  const closeAppMenu = () => {
    setSelectedAppName(undefined);
    history.push(applicationsUrl);
  };

  const closeUserMenu = () => {
    setSelectedUserUUID(undefined);
    setSelectedUserName(undefined);
    history.push(usersUrl);
  };

  const closeIdentityBreachMenu = () => {
    setSelectedBreachID(undefined);
    setSelectedBreachName(undefined);
    history.push(identityBreachesUrl);
  };

  const orgHasUserBase = useHasOrgEntitlement(Permissions.OrgAccessUserBase);
  const orgHasEmailExposure = useHasOrgEntitlement(
    Permissions.OrgAccessEmailExposures
  );
  const userHasUserBaseRead =
    useHasUserOrPortfolioPermissions(UserUserBaseEnabled);
  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);
  const userData = useAppSelector((state) => state.common.userData);
  const isUserAdmin = isAdmin(userData.currentOrgRoles);

  const showUserBaseSection =
    orgHasUserBase && (userHasUserBaseRead || userHasUserBaseWrite);

  if (!showUserBaseSection) {
    return <></>;
  }

  const userNameDisplay = selectedUserName ?? "User";
  const identityBreachNameDisplay = selectedBreachName ?? "Identity Breach";

  return (
    <>
      <div className="subtitle">UserBase</div>

      {isUserAdmin && (
        <NavLink
          icon="spanner"
          text="Admin"
          hoverText={pageDescriptions.UserBaseAdmin}
          onClick={clickNavLinkFunc(adminUrl, "Admin")}
          to={adminUrl}
        />
      )}
      <NavLink
        icon="analytics"
        text="Dashboard"
        hoverText={pageDescriptions.UserBaseDashboard}
        onClick={clickNavLinkFunc(dashboardUrl, "Dashboard")}
        to={dashboardUrl}
      />
      <NavLink
        icon="focus"
        text="Risk Profile"
        hoverText={pageDescriptions.UserBaseRiskProfile}
        onClick={clickNavLinkFunc(riskProfileUrl, "Risk Profile")}
        to={riskProfileUrl}
      />
      <NavLink
        icon="risk-waivers"
        text="Risk waivers"
        hoverText={pageDescriptions.UserBaseRiskModifications}
        onClick={clickNavLinkFunc(riskModificationUrl, "Risk Profile")}
        to={riskModificationUrl}
      />
      <NavLink
        icon="people"
        text="Users"
        hoverText={pageDescriptions.UserBaseUsers}
        onClick={clickNavLinkFunc(usersUrl, "Users")}
        to={usersUrl}
      />
      {isOnUserRoute && (
        <>
          <div className={"nav-sub-section active"}>
            <div
              className={"subtitle header-section-name active"}
              onClick={() => closeUserMenu()}
            >
              <div className="subtitle vendor-name">
                {getSubtitleDisplay(userNameDisplay)}
              </div>
              <Icon name="x" />
            </div>
            <NavLink
              icon="vendor-summary"
              text={`Summary`}
              hoverText={pageDescriptions.UserBaseUserOverview}
              onClick={clickNavLinkFunc(
                userSummaryUrl(selectedUserUUID),
                `${userNameDisplay} Summary`
              )}
              to={userSummaryUrl(selectedUserUUID)}
            />
            <NavLink
              icon="focus"
              text={`Risk Profile`}
              hoverText={pageDescriptions.UserBaseUserRiskProfile}
              onClick={clickNavLinkFunc(
                userRiskProfileUrl(selectedUserUUID),
                `${userNameDisplay} Risk Profile`
              )}
              to={userRiskProfileUrl(selectedUserUUID)}
            />
            <NavLink
              icon="paragraph"
              text={`Applications`}
              hoverText={pageDescriptions.UserBaseUserApplications}
              onClick={clickNavLinkFunc(
                userApplicationsUrl(selectedUserUUID),
                `${userNameDisplay} Applications`
              )}
              to={userApplicationsUrl(selectedUserUUID)}
            />
            {/*TODO: Need a permissions icon*/}
            <NavLink
              icon="magic-wand"
              text={`Permissions`}
              hoverText={pageDescriptions.UserBaseUserPermissions}
              onClick={clickNavLinkFunc(
                userPermissionsUrl(selectedUserUUID),
                `${userNameDisplay} Permissions`
              )}
              to={userPermissionsUrl(selectedUserUUID)}
            />
          </div>
        </>
      )}
      <NavLink
        icon="paragraph"
        text="Applications"
        hoverText={pageDescriptions.UserBaseApplications}
        onClick={clickNavLinkFunc(applicationsUrl, "Applications")}
        to={applicationsUrl}
      />
      {isOnAppRoute && (
        <>
          <div className={"nav-sub-section active"}>
            <div
              className={"subtitle header-section-name active"}
              onClick={() => closeAppMenu()}
            >
              <div className="subtitle vendor-name">
                {getSubtitleDisplay(selectedAppName)}
              </div>
              <Icon name="x" />
            </div>
            <NavLink
              icon="vendor-summary"
              text={`Summary`}
              hoverText={pageDescriptions.UserBaseAppOverview}
              onClick={clickNavLinkFunc(
                appSummaryUrl(selectedAppName),
                `${selectedAppName} Summary`
              )}
              to={appSummaryUrl(selectedAppName)}
            />
            <NavLink
              icon="people"
              text={`Users`}
              hoverText={pageDescriptions.UserBaseUsers}
              onClick={clickNavLinkFunc(
                appUsersUrl(selectedAppName),
                `${selectedAppName} Users`
              )}
              to={appUsersUrl(selectedAppName)}
            />
            {/* TODO: Need a permissions icon */}
            <NavLink
              icon="magic-wand"
              text={`Permissions`}
              hoverText={pageDescriptions.UserBasePermissions}
              onClick={clickNavLinkFunc(
                appPermissionsUrl(selectedAppName),
                `${selectedAppName} Permissions`
              )}
              to={appPermissionsUrl(selectedAppName)}
            />
          </div>
        </>
      )}
      <NavLink
        icon="magic-wand"
        text="Permissions"
        hoverText={pageDescriptions.UserBasePermissions}
        onClick={clickNavLinkFunc(permissionsUrl, "Permissions")}
        to={permissionsUrl}
      />
      {orgHasEmailExposure && (
        <>
          <NavLink
            icon="email"
            text="Identity Breaches"
            hoverText={pageDescriptions.UserBaseIdentityBreaches}
            onClick={clickNavLinkFunc(identityBreachesUrl, "Identity Breaches")}
            to={identityBreachesUrl}
          />
          {isOnIdentityBreachRoute && (
            <>
              <div className={"nav-sub-section active"}>
                <div
                  className={"subtitle header-section-name active"}
                  onClick={() => closeIdentityBreachMenu()}
                >
                  <div className="subtitle vendor-name">
                    {getSubtitleDisplay(identityBreachNameDisplay)}
                  </div>
                  <Icon name="x" />
                </div>
                <NavLink
                  icon="vendor-summary"
                  text={`Summary`}
                  hoverText={pageDescriptions.UserBaseIdentityBreachOverview}
                  onClick={clickNavLinkFunc(
                    identityBreachSummaryUrl(selectedBreachID),
                    `${userNameDisplay} Summary`
                  )}
                  to={identityBreachSummaryUrl(selectedBreachID)}
                />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default UserBaseNavItems;
