import { Component } from "react";
import PropTypes from "prop-types";

import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";

import Button from "../../../_common/components/core/Button";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";

import { closeModal } from "../../../_common/reducers/commonActions";

import {
  getEntitlements,
  updateEntitlements,
} from "../../reducers/adminActions";

import "../../styles/EditDeveloperTogglesModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import InfoBanner, {
  BannerType,
} from "../../../vendorrisk/components/InfoBanner";

export const EditDeveloperTogglesModalName = "EditEntitlementsModalName";

const Toggle = ({ id, name, enabled, onChange }) => {
  const onClick = () => {
    onChange(id, !enabled);
  };

  return (
    <div className="toggle">
      <h4>{name}</h4>
      <ToggleSwitch name={id} selected={enabled} onClick={onClick} />
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

class EditDeveloperTogglesModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    entitlements: null,
  };

  componentDidMount() {
    this.props
      .dispatch(getEntitlements(this.props.modalData.org.id))
      .then(({ features }) => {
        this.setState({
          entitlements: features.filter((p) => p.displayOrder >= 0),
        });
      })
      .catch(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error fetching entitlements")
        );
      });
  }

  onEntitlementChange = (name, enabled) => {
    const updated = this.state.entitlements.map((p) => {
      return p.name === name ? { ...p, enabled } : p;
    });

    this.setState({ entitlements: updated });
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });

    Promise.all(
      this.state.entitlements.map((p) => {
        return this.props.dispatch(
          updateEntitlements(this.props.modalData.org.id, p.name, p.enabled)
        );
      })
    )
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(addDefaultSuccessAlert("Entitlements updated"));
      })
      .catch(() => {
        this.setState({ loading: false });
        this.props.dispatch(
          addDefaultUnknownErrorAlert("Error updating entitlements")
        );
      });
  };

  render() {
    const { org } = this.props.modalData;
    const { entitlements } = this.state;

    if (!entitlements) {
      return (
        <div className="modal-content edit-developer-toggles-modal">
          <h2>Configure features for {org.name}</h2>
          <p />
          <LoadingBanner />
        </div>
      );
    }

    const toggles = entitlements
      .sort((p1, p2) => p1.displayOrder - p2.displayOrder)
      .map((p) => (
        <Toggle
          key={p.name}
          id={p.name}
          name={p.displayName}
          enabled={p.enabled}
          onChange={this.onEntitlementChange}
        />
      ));

    return (
      <div className="modal-content edit-developer-toggles-modal">
        <h2>Configure features for {org.name}</h2>
        <InfoBanner
          type={BannerType.WARNING}
          message={
            "These toggles include untested, in-development features that could irreversibly corrupt data, and therefore should only be used by the product team. You must double check with the product team before making any changes on this screen."
          }
        />
        {entitlements.length > 0 ? (
          <form onSubmit={this.onSubmit}>
            {toggles}
            <Button
              type="submit"
              className="btn btn-primary"
              loading={this.state.loading}
              disabled={this.state.loading}
            >
              Confirm
            </Button>
          </form>
        ) : (
          <div>There are currently no developer toggles to configure.</div>
        )}
      </div>
    );
  }
}

export default EditDeveloperTogglesModal;
