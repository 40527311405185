import { FC } from "react";
import { DefaultRouteProps, useBack } from "../../_common/types/router";
import PageHeader from "../../_common/components/PageHeader";
import RiskWaiversTable from "../../vendorrisk/components/RiskWaiversTable";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { addDefaultSuccessAlert } from "../../_common/reducers/messageAlerts.actions";
import userbaseApi from "../api/userbase.api";

interface RiskModificationsViewProps extends DefaultRouteProps {}

const RiskModificationsView: FC<RiskModificationsViewProps> = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const risks = userbaseApi.useGetUserBaseRisksV1Query();
  const riskWaivers = userbaseApi.useGetUserBaseRiskWaiversV1Query();
  const [deleteWaiver] = userbaseApi.useDeleteUserBaseRiskWaiverV1Mutation();

  const { backAction, backText } = useBack();

  return (
    <div id="risk_modifications">
      <PageHeader
        history={history}
        title="Risk waivers"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey={"userbaseRiskModifications"}
        infoSection={
          <p>
            Risk waivers let you accept specific user related risks that have
            been detected through scanning of your organisation. Waiving a risk
            will remove the risk from your risk profile.
          </p>
        }
      />
      <RiskWaiversTable
        loading={riskWaivers.isLoading || risks.isLoading}
        risks={risks.data?.risks}
        acceptedRisks={riskWaivers.data?.waivers}
        users={riskWaivers.data?.users}
        canUsePublicWaivers={false}
        userHasWriteAccess={true}
        onDelete={(r) =>
          deleteWaiver(r.id)
            .unwrap()
            .then(() => {
              dispatch(
                addDefaultSuccessAlert(
                  "Risk waiver deleted. It might take a few seconds for the security rating to update."
                )
              );
            })
        }
        orgAccessNewRiskDesigns={false} // Not used in userbase
      />
    </div>
  );
};

export default RiskModificationsView;
