import { useState, useEffect, useRef } from "react";

import "../style/components/PLGOnboardingFlow.scss";
import TextField from "../components/TextField";
import Button from "../components/core/Button";
import ModalV2 from "../components/ModalV2";

import { setPLGApplicableChecklists } from "../../vendorrisk/reducers/plgOnboardingChecklistActions";
import { addOrgUsers } from "../../vendorrisk/reducers/cyberRiskActions";
import { updateUserProfile } from "../reducers/commonActions";
import { SelectV2 } from "../components/SelectV2";
import { components } from "react-select";
import UGLogoBlack from "../images/upguard-logo-black.svg";

// is there a better way of doing this?
import Coworker1 from "../images/plg-onboarding/coworker-1.png";
import Coworker2 from "../images/plg-onboarding/coworker-2.png";
import Coworker3 from "../images/plg-onboarding/coworker-3.png";
import Coworker4 from "../images/plg-onboarding/coworker-4.png";
import Coworker5 from "../images/plg-onboarding/coworker-5.png";
import Coworker6 from "../images/plg-onboarding/coworker-6.png";
import Coworker7 from "../images/plg-onboarding/coworker-7.png";
import Coworker8 from "../images/plg-onboarding/coworker-8.png";

import { validateEmail } from "../helpers";

import ManagedVendorsIcon from "../images/plg-onboarding/managed_vendors_icon.png";
import BookIcon from "../images/plg-onboarding/book_icon.png";
import TargetIcon from "../images/plg-onboarding/target_icon.png";
import { appConnect, useAppDispatch } from "../types/reduxHooks";
import Icon from "../components/core/Icon";

import { CheckboxBig } from "../components/CheckboxBig";
import { trackEvent } from "../tracking";
import {
  clearLocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../session";

const PLGOnboardingFlow = (props: {
  firstName: string;
  lastName: string;
  userPermissionBreachSight: boolean;
  userPermissionVendorRisk: boolean;
  userPermissionWriteUsers: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageTransition, setPageTransition] = useState(false);
  const [skipUseCase, setSkipUseCase] = useState(false);

  const [otherRole, setOtherRole] = useState("");
  const otherRoleRef = useRef<HTMLInputElement>(null);

  const [questionAttackChecked, setQuestionAttackChecked] = useState(false);
  const [questionThirdPartyChecked, setQuestionThirdPartyChecked] =
    useState(false);
  const [questionOtherChecked, setQuestionOtherChecked] = useState(false);
  const [otherUseCase, setOtherUseCase] = useState("");
  const otherUseCaseRef = useRef<HTMLInputElement>(null);

  const [errorFirstName, setErrorFirstName] = useState(false);
  const [errorLastName, setErrorLastName] = useState(false);
  const [errorJobRole, setErrorJobRole] = useState(false);
  const [errorOtherRole, setErrorOtherRole] = useState(false);
  const [errorNoFocusSelected, setErrorNoFocusSelected] = useState(false);
  const [errorOtherUseCaseEmpty, setErrorOtherUseCaseEmpty] = useState(false);
  const [email, setEmail] = useState("");

  const [inviteEmails, setInviteEmails] = useState<string[]>([]);
  const inviteInputRef = useRef<HTMLInputElement>(null);
  const [inviteLimitHit, setInviteLimitHit] = useState(false);
  const [inviteRowsVisibleTags, setInviteRowsVisibleTags] = useState<string[]>(
    []
  );

  const [noInviteesModalVisible, setNoInviteesModalVisible] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const dispatch = useAppDispatch();

  const RoleOptions = [
    { value: "CISO", label: "Chief Information Security Officer (CISO)" },
    { value: "CTO", label: "Chief Technology Officer (CTO)" },
    { value: "CIO", label: "Chief Information Officer (CIO)" },
    { value: "GRC", label: "Director of Governance, Risk & Compliance (GRC)" },
    { value: "HeadOfRiskAndCompliance", label: "Head of Risk and Compliance" },
    { value: "SecurityDirector", label: "Security Director" },
    {
      value: "InformationSecurityManager",
      label: "Information Security Manager",
    },
    { value: "ITSecurityManager", label: "IT Security Manager" },
    { value: "CyberSecurityManager", label: "Cyber Security Manager" },
    { value: "RiskAndComplianceManager", label: "Risk & Compliance Manager" },
    { value: "ThirdPartyRiskManager", label: "Third Party Risk Manager" },
    { value: "OperationalRiskManager", label: "Operational Risk Manager" },
    { value: "GRCManager", label: "GRC Manager" },
    { value: "CyberSecurityAnalyst", label: "Cyber Security Analyst" },
    { value: "RiskAnalyst", label: "Risk Analyst" },
    { value: "ComplianceAnalyst", label: "Compliance Analyst" },
    { value: "GRCAnalyst", label: "GRC Analyst" },
    { value: "PenetrationTester", label: "Penetration Tester" },
    { value: "ITAuditor", label: "IT Auditor" },
    { value: "NetworkEngineer", label: "Network Engineer" },
    { value: "SystemsAdministrator", label: "Systems Administrator" },
    { value: "ITSpecialist", label: "IT Specialist" },
    { value: "Other", label: "Other" },
  ];

  const [selectedRole, setSelectedRole] = useState({ value: "", label: "" });

  useEffect(() => {
    if (selectedRole.value === "Other") {
      otherRoleRef.current!.focus();
    }
  }, [selectedRole]);

  function changePage(newPage: number) {
    if (skipUseCase && newPage === 2) {
      // no choosing use case for us. let's skip over that page
      if (newPage < currentPage) {
        // we're trying to go back, skip 2 and go back to page 1
        newPage = 1;
      } else {
        // we're trying to go forward, skip page 2 for page 3
        newPage = 3;
      }
    }

    if (!props.userPermissionWriteUsers && newPage === 3) {
      //if we're navigating to the invite page but have no permission to invite..
      newPage = 4;
    }
    setPageTransition(true);
    setTimeout(() => {
      setCurrentPage(newPage);
    }, 300);
  }

  useEffect(() => {
    if (pageTransition) {
      setTimeout(() => {
        setPageTransition(false);
      }, 300);
    }
  }, [pageTransition]);
  // focus on other use case input
  useEffect(() => {
    if (questionOtherChecked) {
      otherUseCaseRef.current!.focus();
    }
  }, [questionOtherChecked]);

  useEffect(() => {
    if (currentPage === 4) {
      setTimeout(() => {
        setLocalStorageItem("onboardingComplete", true);
        //resetting these 3 just in case you've got them set already (likely us/testing)
        clearLocalStorageItem("plgExpandedChecklist");
        // default open tasks
        setLocalStorageItem("plgExpandedTasks", [
          "Checklist_VendorRisk_Monitor",
          "Checklist_BreachSight_Risk_Profile",
        ]);
        setLocalStorageItem("plgChecklistsVisible", true);
        setTimeout(() => {
          window.location.href = "/";
        }, 1000 * inviteEmails.length);
      }, 1000);
    }

    if (currentPage === 3) {
      inviteInputRef.current!.focus();
    }
  }, [currentPage]);

  useEffect(() => {
    if (errorNoFocusSelected) {
      setErrorNoFocusSelected(false);
    }
  }, [questionOtherChecked, questionAttackChecked, questionThirdPartyChecked]);

  useEffect(() => {
    setTimeout(() => {
      updateInviteVisibleTags();
    }, 10);
  }, [inviteEmails]);

  //for the progressbar and footer nav button captions
  const thisUsersPages = [1];
  if (!skipUseCase) {
    thisUsersPages.push(2);
  }
  if (props.userPermissionWriteUsers) {
    thisUsersPages.push(3);
  }

  if (loading) {
    if (!!props.firstName && !!props.lastName) {
      setFirstName(props.firstName);
      setLastName(props.lastName);
      // they already got a name so let's skip the name step
      changePage(1);
    }

    // if user has access to only 1 of 2 products by invite, we'll skip that step
    // if user has access to neither (is trustexchange only) they should also skip the step
    // and get no checklists
    if (!props.userPermissionBreachSight || !props.userPermissionVendorRisk) {
      setSkipUseCase(true);
      setQuestionAttackChecked(props.userPermissionBreachSight);
      setQuestionThirdPartyChecked(props.userPermissionVendorRisk);
    }
    setLoading(false);
  }

  function NoOptionsMessage(props: any) {
    return (
      <components.NoOptionsMessage {...props} className={"no-options-div"}>
        <div
          className={"no-options-div"}
          onClick={() => {
            setSelectedRole({ value: "Other", label: "Other" });
          }}
        >
          <div className={"no-options no-options-header"}>Other</div>
          <div className={"no-options no-options-body"}>
            {"We couldn't find your role. Try again or select Other."}
          </div>
        </div>
      </components.NoOptionsMessage>
    );
  }

  const cleanUpBeforeGoingBack = () => {
    // this should keep expanded 'other' fields from showing up on page transition
    switch (currentPage) {
      case 1:
        if (selectedRole.value === "Other" && otherRole.length === 0) {
          //setSelectedRole({ value: "", label: "" });
        }
        changePage(currentPage - 1);
        return;
      case 2:
        if (questionOtherChecked && otherUseCase.length === 0) {
          //setQuestionOtherChecked(false);
        }
        changePage(currentPage - 1);
        return;
    }
    changePage(currentPage - 1);
  };

  const validateChoices = (currPage: number) => {
    switch (currPage) {
      case 0:
        setErrorFirstName(false);
        setErrorLastName(false);
        if (firstName === "") {
          setErrorFirstName(true);
        }
        if (lastName === "") {
          setErrorLastName(true);
        }
        if (firstName === "" || lastName === "") {
          return false;
        } else {
          dispatch(
            updateUserProfile({
              first_name: firstName,
              last_name: lastName,
            })
          ).then(() => {
            trackEvent("PLGOnboardingFlowStepComplete", {
              step: currPage,
            });
          });
          return true;
        }

      case 1:
        if (!selectedRole.value) {
          setErrorJobRole(true);
          return false;
        }
        if (selectedRole.value === "None") {
          setErrorJobRole(true);
          return false;
        }
        if (selectedRole.value === "Other" && !otherRole) {
          setErrorOtherRole(true);
          return false;
        }
        setErrorJobRole(false);
        setErrorOtherRole(false);

        // temporarily disabling job_title updates at request of marketing
        //let chosenRole = selectedRole.label;
        //if (otherRole.length > 2 && selectedRole.value === "Other") {
        //  chosenRole = otherRole;
        //}
        // adding first/last again bc they're required
        dispatch(
          updateUserProfile({
            first_name: firstName,
            last_name: lastName,
            // temporarily disabling job_title updates at request of marketing
            //job_title: chosenRole,
          })
        ).then(() => {
          trackEvent("PLGOnboardingFlowStepComplete", {
            step: currPage,
            customJobRoleEntered:
              otherRole.length > 2 && selectedRole.value === "Other",
          });
        });

        // if one of the products is unavailable and we're not able to invite,
        // we gotta set applicable checklists now bc changePage will kick us to
        // page 4 (onboarding complete)

        if (skipUseCase && !props.userPermissionWriteUsers) {
          const checklists: string[] = ["None"];
          if (questionThirdPartyChecked) {
            checklists.push("VendorRisk");
          }
          if (questionAttackChecked) {
            checklists.push("BreachSight");
          }
          dispatch(setPLGApplicableChecklists(checklists, otherUseCase)).then(
            () => {
              trackEvent("PLGOnboardingFlowStepComplete", {
                step: currPage,
                useCaseAttackSurfaceChecked: props.userPermissionBreachSight,
                useCaseThirdPartyChecked: props.userPermissionVendorRisk,
                useCaseOtherChecked: false,
                useCasePageSkipped: true,
              });
            }
          );
        }
        return true;
      case 2:
        if (
          !questionAttackChecked &&
          !questionThirdPartyChecked &&
          !questionOtherChecked
        ) {
          setErrorNoFocusSelected(true);
          setErrorOtherUseCaseEmpty(false);
          return false;
        }

        if (questionOtherChecked && otherUseCase.length === 0) {
          setErrorOtherUseCaseEmpty(true);
          setErrorNoFocusSelected(false);
          return false;
        }

        const checklists: string[] = ["None"];
        if (questionThirdPartyChecked) {
          checklists.push("VendorRisk");
        }
        if (questionAttackChecked) {
          checklists.push("BreachSight");
        }
        if (
          questionOtherChecked &&
          !questionAttackChecked &&
          !questionThirdPartyChecked
        ) {
          checklists.push("VendorRisk");
          checklists.push("BreachSight");
        }

        dispatch(setPLGApplicableChecklists(checklists, otherUseCase)).then(
          () => {
            trackEvent("PLGOnboardingFlowStepComplete", {
              step: currPage,
              useCaseAttackSurfaceChecked: questionAttackChecked,
              useCaseThirdPartyChecked: questionThirdPartyChecked,
              useCaseOtherChecked: questionOtherChecked,
            });
          }
        );

        return true;
      case 3:
        if (skipUseCase) {
          // if we skipped the use case page, we still
          // gotta set the use case
          const checklists: string[] = ["None"];
          if (questionAttackChecked) {
            checklists.push("BreachSight");
          }
          if (questionThirdPartyChecked) {
            checklists.push("VendorRisk");
          }
          dispatch(setPLGApplicableChecklists(checklists, otherUseCase)).then(
            () => {
              trackEvent("PLGOnboardingFlowStepComplete", {
                step: currPage,
                useCaseAttackSurfaceChecked: questionAttackChecked,
                useCaseThirdPartyChecked: questionThirdPartyChecked,
                useCasePageSkipped: true,
              });
            }
          );
        }

        if (inviteEmails.length == 0) {
          if (email.length > 0) {
            if (!validateEmail(email)) {
              return false;
            }
            addEmailToInviteList();
          }
          setNoInviteesModalVisible(true);
          return false;
        } else {
          // invite some users
          //if we have content in the main email box and it's shit, return false
          if (email.length > 0 && !validateEmail(email)) {
            return false;
          }
          // if any of the email boxes don't validate, it's shit
          for (const emailString of inviteEmails) {
            if (!validateEmail(emailString)) {
              return false;
            }
          }
          const emails: string[] = [];
          for (const emailString of inviteEmails) {
            emails.push(emailString);
          }

          dispatch(addOrgUsers(emails, [], [], 0))
            .catch((e) => {
              console.log("error adding org users", e, emails);
            })
            .then(() => {
              trackEvent("PLGOnboardingFlowStepComplete", {
                step: currPage,
                numInvites: emails.length,
              });
            })
            .catch((e) => console.log(e));
        }
    }
    return true;
  };

  // for adding new emails from the bottom invite field
  const addEmailToInviteList = () => {
    if (validateEmail(email)) {
      const newInviteEmails = [...inviteEmails, email];
      setInviteLimitHit(newInviteEmails.length > 7);
      setInviteEmails(newInviteEmails);
      setEmail("");
      inviteInputRef.current!.focus();
    }
  };

  // for updating the rest of the fields
  function updateInviteEmails(email: string, i: number) {
    const newInviteEmails = [...inviteEmails];
    if (email.length == 0) {
      newInviteEmails.splice(i, 1);
    } else {
      newInviteEmails[i] = email;
    }
    setInviteLimitHit(newInviteEmails.length > 7);
    setInviteEmails(newInviteEmails);
  }

  // for updating the css tags post-first-render
  function updateInviteVisibleTags() {
    const newInviteVisibleTags: Array<string> = [];
    for (let i = 0; i < inviteEmails.length; i++) {
      newInviteVisibleTags.push("form-visible");
    }
    setInviteRowsVisibleTags(newInviteVisibleTags);
  }

  function showQuestion(pageNo: number) {
    // pageNo 0: What's your full name
    // 1: choose role
    // 2: choose interests
    // 3: invite friends
    // 4: ug logo/transition
    switch (pageNo) {
      case 0:
        return (
          <div className={`plg-onboarding-question-container`}>
            <h3>What&apos;s your full name?</h3>
            <p className={"plg-onboarding-question-body"}>
              This helps us tailor your experience.
            </p>
            <label
              htmlFor="first-name"
              className={"plg-onboarding-input-label"}
            >
              First name <span className={"red-text"}>*</span>
            </label>
            <TextField
              value={firstName}
              onChanged={(val) => {
                setFirstName(val);
              }}
              placeholder={""}
              className={errorFirstName ? "input-error" : ""}
            />
            {errorFirstName ? (
              <p className={"err-text"}>
                <Icon className={"cr-icon-risk err-text"} name={"error"} />{" "}
                {"Please enter your first name."}
              </p>
            ) : (
              ""
            )}

            <label htmlFor="last-name" className={"plg-onboarding-input-label"}>
              Last name <span className={"red-text"}>*</span>
            </label>
            <TextField
              value={lastName}
              onChanged={(val) => {
                setLastName(val);
              }}
              placeholder={""}
              className={errorLastName ? "input-error" : ""}
            />
            {errorLastName ? (
              <p className={"err-text"}>
                <Icon className={"cr-icon-risk err-text"} name={"error"} />{" "}
                {"Please enter your last name."}
              </p>
            ) : (
              ""
            )}
          </div>
        );
      case 1:
        // What's your role
        return (
          <div className={`plg-onboarding-question-container`}>
            <h3>
              What&apos;s your role? <span className={"red-text"}>*</span>
            </h3>
            <p className={"plg-onboarding-question-body"}>
              This helps us personalize your experience.
            </p>
            <SelectV2
              placeholder="Choose an option"
              value={selectedRole.value ? selectedRole : undefined}
              options={RoleOptions}
              className={errorJobRole ? `input-error` : ""}
              onChange={(e) => {
                !!e && setSelectedRole({ value: e.value, label: e.label });
                setErrorJobRole(false);
              }}
              controlShouldRenderValue={true}
              components={{ NoOptionsMessage }}
              autoFocus={!!selectedRole && selectedRole.value !== "Other"}
              blurInputOnSelect={false}
              styles={{
                singleValue: (baseStyles) => ({
                  ...baseStyles,
                  color: "#131520",
                }),
              }}
            />
            {errorJobRole ? (
              <p className={"err-text"}>
                <Icon className={"cr-icon-risk err-text"} name={"error"} />{" "}
                {"You must select a role to continue."}
              </p>
            ) : (
              ""
            )}

            <div
              className={`plg-onboarding-other-field-container ${
                !!selectedRole && selectedRole.value === "Other"
                  ? "form-visible"
                  : ""
              }`}
            >
              <TextField
                ref={otherRoleRef}
                value={otherRole}
                onChanged={(val) => {
                  setOtherRole(val);
                }}
                className={`other-text-field ${
                  errorOtherRole ? "input-error" : ""
                }`}
                placeholder={"How would you describe your role?"}
                maxLength={60}
              />
              {errorOtherRole ? (
                <p className={"err-text"}>
                  <Icon className={"cr-icon-risk err-text"} name={"error"} />{" "}
                  {"Enter your role"}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      case 2:
        // What's your area(s) of focus
        return (
          <div className={`plg-onboarding-question-container`}>
            <h3>
              What will you use UpGuard for?{" "}
              <span className={"red-text"}>*</span>
            </h3>
            <p className={"plg-onboarding-question-body"}>
              We&apos;ll customize UpGuard to match your needs.
            </p>
            <div className={"plg-onboarding-checkboxbig-container"}>
              <CheckboxBig
                checked={questionAttackChecked}
                setChecked={setQuestionAttackChecked}
                label={"Attack surface management"}
                iconImage={TargetIcon}
                tooltipText={
                  "Manage your organization's security posture, assessing and mitigating risks within your digital footprint."
                }
              />
              <CheckboxBig
                checked={questionThirdPartyChecked}
                setChecked={setQuestionThirdPartyChecked}
                label={"Third-party risk management"}
                iconImage={ManagedVendorsIcon}
                tooltipText={
                  "Monitor and mitigate risks associated with your organization's vendors and partners."
                }
              />
              <CheckboxBig
                checked={questionOtherChecked}
                setChecked={setQuestionOtherChecked}
                label={"Something else"}
                iconImage={BookIcon}
                tooltipText={"I plan to use UpGuard for something else."}
              />
            </div>
            {errorNoFocusSelected ? (
              <p className={"err-text"}>
                <Icon className={"cr-icon-risk err-text"} name={"error"} />{" "}
                Choose what you&apos;ll use UpGuard for
              </p>
            ) : (
              ""
            )}
            <div
              className={`plg-onboarding-other-focus-container ${
                !questionOtherChecked ? "" : "form-visible"
              }`}
            >
              <label htmlFor={"additional-info"}>
                <span className={"please-provide-more-information"}>
                  Please provide more information{" "}
                </span>
                <span className={"red-text"}>*</span>{" "}
                {otherUseCase.length > 0 ? (
                  <>{otherUseCase.length}/200</>
                ) : (
                  <></>
                )}
              </label>

              <TextField
                ref={otherUseCaseRef}
                value={otherUseCase}
                type="text"
                hideMessageTexts
                multiLine
                placeholder=""
                maxLength={200}
                errorTexts={
                  errorOtherUseCaseEmpty
                    ? ["Tell us more about your needs"]
                    : []
                }
                className={`input-other-focus`}
                onChanged={(e) => {
                  setOtherUseCase(e);
                  if (otherUseCase.length > 0) {
                    setErrorOtherUseCaseEmpty(false);
                  }
                }}
              ></TextField>
              {errorOtherUseCaseEmpty ? (
                <p className={"err-text"}>
                  <Icon className={"cr-icon-risk err-text"} name={"error"} />{" "}
                  Tell us more about your needs
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      case 3:
        // invite teammates

        return (
          <div className={`plg-onboarding-question-container`}>
            <div className={"plg-onboarding-invite-container"}>
              <h3>Invite teammates</h3>
              <p className={"plg-onboarding-question-body"}>
                UpGuard works best when you join forces with others to improve
                your security posture.
              </p>

              {inviteEmails.map((_, i) => (
                <div
                  key={"email" + String(i)}
                  className={`input-row ${
                    !!inviteRowsVisibleTags[i] ? "form-visible" : ""
                  }`}
                >
                  <div className={"input-row-textfield"}>
                    <TextField
                      name={"enter-email-" + i}
                      type="email"
                      //className={"textfield-disabled"}
                      value={inviteEmails[i]}
                      onChanged={(e) => {
                        updateInviteEmails(e, i);
                      }}
                      enterKeyShouldBlur={true}
                      onBlur={() => {
                        updateInviteEmails(inviteEmails[i], i);
                      }}
                    />
                  </div>
                </div>
              ))}

              {!inviteLimitHit ? (
                <div className={"input-row form-visible"}>
                  <div className={"input-row-textfield"}>
                    <TextField
                      ref={inviteInputRef}
                      name={"enter-email"}
                      type="email"
                      value={email}
                      placeholder={"user@example.com"}
                      onChanged={(e) => {
                        setEmail(e);
                      }}
                      enterKeyShouldBlur={true}
                      onBlur={addEmailToInviteList}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            {noInviteesModalVisible ? (
              <ModalV2
                active={true}
                className="no-invitees-modal"
                headerContent={"Are you sure you don't want to invite anyone?"}
                onClose={() => setNoInviteesModalVisible(false)}
                footerContent={
                  <>
                    <Button
                      tertiary
                      onClick={() => {
                        setNoInviteesModalVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      filledPrimary
                      onClick={() => {
                        trackEvent("PLGOnboardingFlowStepComplete", {
                          step: 3,
                          numInvites: 0,
                        });
                        changePage(currentPage + 1);
                      }}
                    >
                      Yes, I&apos;m sure
                    </Button>
                  </>
                }
              >
                <span className={"body-text"}>
                  We recommend inviting your teammates now to get the most out
                  of UpGuard.
                </span>
              </ModalV2>
            ) : (
              <></>
            )}
          </div>
        );
      case 4:
        return <div className={"onboarding-complete"} />;
    }
    return <></>;
  }

  function OnboardingFlowProgressBar() {
    return (
      <div className={"plg-onboarding-progressbar-container"}>
        {thisUsersPages.map((page) => {
          return (
            <div
              key={page}
              className={`progressbar-segment segment-${
                currentPage >= page ? "filled" : "empty"
              } ${currentPage == page ? "leading-edge" : ""}`}
            />
          );
        })}
      </div>
    );
  }

  function OnboardingFlowFooterNav() {
    return (
      <div className={"plg-onboarding-footer"}>
        <div className={"plg-onboarding-footer-nav-container"}>
          <div className={"plg-onboarding-footer-nav-button-container"}>
            {currentPage > 0 ? (
              <Button
                className={"plg-previous-button"}
                onClick={cleanUpBeforeGoingBack}
              >
                <div className={"plg-onboarding-footer-prev-button-internal"}>
                  <div>
                    <Icon
                      className={"cr-icon-arrow-right"}
                      name={"previous-arrow"}
                      direction={180}
                    />
                  </div>
                  <div>Previous</div>
                </div>
              </Button>
            ) : (
              <></>
            )}
          </div>

          <div className={"plg-onboarding-footer-nav-button-container"}>
            <Button
              filledPrimary
              className={"plg-next-button"}
              onClick={() =>
                validateChoices(currentPage)
                  ? changePage(currentPage + 1)
                  : setCurrentPage(currentPage)
              }
            >
              {currentPage == thisUsersPages[thisUsersPages.length - 1]
                ? "Complete"
                : "Next"}
              <Icon
                className={"cr-icon-arrow-right"}
                name={"next-arrow"}
                direction={0}
              />
            </Button>
          </div>
        </div>
        <div className={"plg-onboarding-intercom-spacer"} />
      </div>
    );
  }

  return (
    <div
      id="plg_register"
      className={`${
        !!getLocalStorageItem("onboardingComplete", true)
          ? "onboarding-complete"
          : ""
      } ${!loading ? "form-visible" : ""}`}
    >
      <div className={"blurry-circle circle1"} />
      <div className={"blurry-circle circle2"} />
      <div className={"blurry-circle circle3"} />
      <div className={"blurry-circle circle4"} />
      <div className={"z-index-up"}>
        <img
          className={`coworkers coworker-1 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker1}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-2 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker2}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-3 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker3}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-4 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker4}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-5 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker5}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-6 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker6}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-7 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker7}
          alt={"Friendly Coworker"}
        />
        <img
          className={`coworkers coworker-8 ${
            currentPage === 3 ? "coworker-visible" : ""
          }`}
          src={Coworker8}
          alt={"Friendly Coworker"}
        />

        <div className={"plg-onboarding-header"}>
          <div className={"plg-onboarding-logo-container"}>
            <img src={UGLogoBlack} className={"ug-logo"} alt={"UpGuard"} />
          </div>
        </div>

        <div
          className={`plg-onboarding-content-container ${
            pageTransition ? "hidden" : ""
          }`}
        >
          {showQuestion(currentPage)}
        </div>

        <div
          className={`plg-onboarding-footer-container ${
            currentPage > 3 ? "hidden" : ""
          }`}
        >
          <OnboardingFlowProgressBar />
          <OnboardingFlowFooterNav />
        </div>
      </div>
    </div>
  );
};

export default appConnect()(PLGOnboardingFlow);
