import { Filters, WebsiteStatusFilter } from "../components/filter/types";
import { DefaultManagedVendorServiceStatusTypes } from "../../analyst_portal/components/filter/TprmsFilter.helpers";

// Get initial empty default state for app-wide filters
export const getDefaultFilters = (): Filters => {
  return {
    includeUnlabeled: false,
    minScore: undefined,
    maxScore: undefined,
    vendorLabelIds: [],
    websiteLabelIds: [],
    websiteLabelIdsDoNotMatch: false,
    websiteLabelIdsMatchAll: false,
    websiteIncludeUnlabeled: false,
    domainPortfolioIds: [],
    vendorIds: [],
    riskName: "",
    riskCategories: [],
    dataLeaksClassifications: [],
    dataLeaksSources: [],
    dataLeaksKeywords: [],
    vulnsCveNames: [],
    vulnsSoftware: [],
    vulnsExcludeVerified: false,
    vulnsExcludeUnverified: false,
    vulnsExcludeUnignored: false,
    vulnsExcludeIgnored: true,
    vulnsExcludedSeverities: [],
    vulnsEPSSScores: {},
    typosquatReasonIds: [],
    typosquatTypeIds: [],
    typosquatReasonIdsDoNotMatch: false,
    typosquatTypeIdsDoNotMatch: false,
    typosquatMaliciousActivities: [],
    typosquatMaliciousActivitiesDoNotMatch: false,
    riskIds: [],
    websiteStatus: WebsiteStatusFilter.All,
    vendorLabelIdsMatchAll: false,
    vendorLabelIdsDoNotMatch: false,
    subsidiaryLabelIds: [],
    subsidiaryCountries: [],
    subsidiaryIds: [],
    subsidiaryMinScore: undefined,
    subsidiaryMaxScore: undefined,
    vendorTiers: [],
    vendorAssessmentClassifications: [],
    vendorAssessmentAuthors: [],
    vendorAssessmentAuthorDoNotMatch: false,
    vendorReassessmentStartDate: "",
    vendorReassessmentEndDate: "",
    vendorReassessmentDateBefore: false,
    vendorReassessmentDateBetween: false,
    portfolioIds: [],
    selectedSurveyStatuses: [],
    selectedSurveyAttributes: [],
    selectedAttributes: {},
    vulnsOnlyKEV: false,
    assessedVendorsOnly: false,
    vendorSurveyTypes: [],
    vendorEvidenceTypes: [],
    orgNames: [],
    excludedServiceLevels: undefined,
    includedServiceStatuses: DefaultManagedVendorServiceStatusTypes,
    detectedProductCategory: [],
    detectedProductVendorMonitored: false,
    detectedProductVendorUnmonitored: false,
    fourthPartyProductUUIDs: [],
    cloudConnectionProviderTypes: [],
    cloudConnectionUUIDs: [],
  };
};
