import IconButton, { HoverLocation } from "../../_common/components/IconButton";
import React, { FC, HTMLProps, useState } from "react";
import MessageInput from "../../_common/components/MessageInput";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import classnames from "classnames";
import { DiffSide } from "./nodes/baseNode";
import { GetQueryParams } from "../../_common/helpers";
import { getAuthFromLocalStorage } from "../../_common/reducers/commonActions";
import { GetIconForFilename } from "../../vendorrisk/helpers/icons";
import "../style/SurveyViewerDocument.scss";

interface ISurveyViewerDocumentStylesProps extends HTMLProps<HTMLDivElement> {
  diffSide?: DiffSide;
}

export const SurveyViewerDocumentStyles: FC<
  ISurveyViewerDocumentStylesProps
> = ({ className, diffSide, ...rest }) => (
  <div
    className={classnames("survey-viewer-document", className, {
      [`${diffSide}`]: !!diffSide,
    })}
    {...rest}
  />
);

interface SurveyViewerDocumentProps {
  filename: string;
  onDelete: () => Promise<any>;
  disabled?: boolean;
  description?: React.ReactNode;
  onDescriptionEdited?: (newDesc: string) => Promise<any>;
  onDownload?: () => void;
  diffSide?: DiffSide;
}

const SurveyViewerDocument = ({
  filename,
  onDelete,
  disabled,
  description,
  onDescriptionEdited,
  onDownload,
  diffSide,
}: SurveyViewerDocumentProps) => {
  const [isEditingDesc, setIsEditingDesc] = useState(false);
  const [editedDesc, setEditedDesc] = useState(description ?? "");
  const [savingDesc, setSavingDesc] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const filenameClasses = classnames("filename", {
    downloadable: onDownload !== undefined,
  });

  return (
    <SurveyViewerDocumentStyles diffSide={diffSide}>
      <div className={"type-icon"}>
        <img src={GetIconForFilename(filename)} />
      </div>
      <div className={"filename-and-description"}>
        <div className={filenameClasses} onClick={onDownload}>
          {filename}
        </div>
        {description && !isEditingDesc && (
          <div className={"description"}>{description}</div>
        )}
        {isEditingDesc && onDescriptionEdited && (
          <MessageInput
            // @ts-ignore
            value={isEditingDesc ? editedDesc : description ?? ""}
            showActions={true}
            onChange={setEditedDesc}
            onCancel={() => {
              setIsEditingDesc(false);
              setEditedDesc(description ?? "");
            }}
            onSubmit={() => {
              setSavingDesc(true);
              setIsUpdating(true);
              onDescriptionEdited(editedDesc as string)
                .then(() => {
                  setSavingDesc(false);
                  setIsEditingDesc(false);
                  setIsUpdating(false);
                })
                .catch(() => {
                  setIsUpdating(false);
                  setSavingDesc(false);
                });
            }}
            onSubmitText={"Save"}
            loading={savingDesc}
            placeholder={"Please describe this attachment..."}
          />
        )}
      </div>
      <div className={"actions"}>
        {isUpdating && <LoadingIcon size={25} />}
        {!isUpdating && (
          <>
            {onDescriptionEdited && !disabled && (
              <IconButton
                icon={<div className={"cr-icon-pencil"} />}
                hoverLocation={HoverLocation.Top}
                hoverText={description ? "Edit description" : "Add description"}
                onClick={() => setIsEditingDesc(true)}
              />
            )}
            {!disabled && (
              <IconButton
                icon={<div className={"cr-icon-trash"} />}
                hoverLocation={HoverLocation.Top}
                hoverText={"Remove attachment"}
                onClick={() => {
                  setIsUpdating(true);
                  onDelete()
                    .then(() => {
                      setIsUpdating(false);
                    })
                    .catch(() => {
                      setIsUpdating(false);
                    });
                }}
              />
            )}

            {onDownload && (
              <IconButton
                icon={<div className={"cr-icon-export"} />}
                hoverLocation={HoverLocation.Top}
                hoverText={"Download attachment"}
                onClick={onDownload}
              />
            )}
          </>
        )}
      </div>
    </SurveyViewerDocumentStyles>
  );
};

export default SurveyViewerDocument;

export const getSurveyFileDownloadUrl = (
  surveyId: number,
  gcsObjectName: string,
  groupName: string,
  isPublicSurvey: boolean
) => {
  // Check if this is a managed vendors analyst session
  const { managed, org } = GetQueryParams(window.location.search);
  let tpvmProps = null;

  if (managed === "true" && org) {
    const orgId = parseInt(org);
    tpvmProps = { tpvm: true, tpvm_o: orgId };
  }

  const { apiKey, token } = getAuthFromLocalStorage(tpvmProps);
  let attachmentUrl = "/api/survey/attachment/v1/";
  attachmentUrl += `?survey_id=${encodeURIComponent(surveyId)}`;
  attachmentUrl += `&apikey=${encodeURIComponent(apiKey)}`;
  attachmentUrl += `&token=${encodeURIComponent(token)}`;
  attachmentUrl += `&object=${encodeURIComponent(gcsObjectName)}`;
  attachmentUrl += `&attachment_name=${encodeURIComponent(groupName)}`;
  attachmentUrl += `&public=${encodeURIComponent(isPublicSurvey)}`;

  return attachmentUrl;
};
