import { Component } from "react";
import PropTypes from "prop-types";

class StripeElements extends Component {
  static propTypes = {
    stripe: PropTypes.object.isRequired, // stripe object previously initialised with a key
  };

  // Styles for the card input need to be defined here rather than in CSS as they are passed
  // in to Stripe elements, which creates the card input in an iframe.
  static cardInputStyle = {
    base: {
      color: "#2C3238",
      fontSize: "20px",
      fontFamily: 'Inter, "Helvetica Neue", Helvetica, Arial, sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": { color: "#AAB0B8" },
    },
    invalid: {
      color: "#EF1700",
      iconColor: "#fa755a",
    },
  };

  componentDidMount() {
    const elements = this.props.stripe.elements({
      cssSrc:
        "https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap",
    });

    this.cardElement = elements.create("card", {
      style: StripeElements.cardInputStyle,
    });
    this.cardElement.mount(this.cardNumber);
  }

  // The mounter of the StripeElements component should call createToken on the component reference.
  // Pass in the user's name for Stripe to be able to do better verifications.
  async createToken(name) {
    const result = await this.props.stripe.createToken(this.cardElement, {
      name,
    });

    if (result.error) {
      throw result.error;
    }

    return result.token;
  }

  render() {
    return (
      <div
        className="stripe-elements-wrapper"
        ref={(ref) => (this.cardNumber = ref)}
      />
    );
  }
}

export default StripeElements;
