import "../../style/components/SurveyDetailsUnansweredQuestionsCard.scss";
import {
  vsaqQuestion,
  vsaqQuestionType,
} from "../../../survey_builder/vsaq/vsaqTypes";
import { forwardRef, memo, useState } from "react";
import ReportCard from "../ReportCard";
import ProgressBar from "../ProgressBar";
import XTable, {
  ISortedBy,
  IXTableColumnHeader,
  OnSortChange,
  SortDirection,
  XTableCell,
} from "../core/XTable";
import NodeTypeIcon, {
  vsaqQuestionTypeToNodeTypeIconType,
} from "../../../survey_builder/components/NodeTypeIcon";
import { usePagination } from "../../hooks";
import Button from "../core/Button";
import { History, LocationDescriptor } from "history";
import { Link } from "react-router-dom";
import { ISurveyListItemResponse, SurveyStatus } from "../../types/survey";
import { orderBy as _orderBy } from "lodash";

const vsaqQuestionTypeToText = (questionType: vsaqQuestionType): string => {
  switch (questionType) {
    case vsaqQuestionType.RadioGroup:
      return "Single-select question";
    case vsaqQuestionType.CheckGroup:
      return "Multi-select question";
    case vsaqQuestionType.Line:
    case vsaqQuestionType.Box:
      return "Text question";
    case vsaqQuestionType.Upload:
      return "File upload";
  }

  return "";
};

const questionSeparator = /^((\d+\.)+)(.+)/;

export const separateQuestionNumberFromQuestion = (text: string) => {
  const matches = questionSeparator.exec(text);
  if (matches && matches.length === 4) {
    return [matches[1], matches[3]];
  }

  return ["", text];
};

export const getViewQuestionButtonText = (
  survey: ISurveyListItemResponse,
  isNotEditable: boolean | undefined
) => {
  const isAutofilledAnalystQuestionnaire =
    survey.isAnalystWorkflowSurvey && survey.dateAnalystAutofill;
  const isOpened = !!survey.dateOpened;
  if (!survey.archived && !isNotEditable) {
    if (
      survey.status === SurveyStatus.Sent &&
      !isAutofilledAnalystQuestionnaire
    ) {
      return "Start questionnaire";
    } else if (
      survey.status === SurveyStatus.Sent &&
      isAutofilledAnalystQuestionnaire &&
      !isOpened
    ) {
      return "Review and complete";
    } else if (
      survey.status === SurveyStatus.Sent &&
      isAutofilledAnalystQuestionnaire &&
      isOpened
    ) {
      return "Continue review & complete";
    } else if (
      survey.status == SurveyStatus.InProgress &&
      !isAutofilledAnalystQuestionnaire
    ) {
      return "Continue answering";
    } else if (
      survey.status == SurveyStatus.InProgress &&
      isAutofilledAnalystQuestionnaire
    ) {
      return "Continue review & complete";
    } else {
      return "Edit answers";
    }
  } else {
    return "View answers";
  }
};

const columnHeaders: IXTableColumnHeader[] = [
  {
    id: "question",
    text: "Question",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "type",
    text: "Question type",
    sortable: true,
    startingSortDir: SortDirection.ASC,
  },
  {
    id: "chevron",
    text: "",
    sortable: false,
  },
];

export interface ISurveyDetailsUnansweredQuestionsCardProps {
  history: History;
  surveyId: number;
  surveyDeleted?: boolean;
  unansweredQuestions: vsaqQuestion[];
  isVendorPortal: boolean;
  fromCompanyName: string;
  numQuestions: number;
  numAnswers: number;
  getSurveyPath: (
    editMode: boolean,
    surveyId?: number,
    questionId?: string
  ) => LocationDescriptor;
  survey: ISurveyListItemResponse;
  isCurrentUserRecipient: boolean;
  isNotEditable?: boolean;
}

const SurveyDetailsUnansweredQuestionsCard = forwardRef<
  HTMLDivElement,
  ISurveyDetailsUnansweredQuestionsCardProps
>(function SurveyDetailsUnansweredQuestionsCard(props, ref) {
  const {
    history,
    unansweredQuestions,
    isVendorPortal,
    surveyDeleted,
    numAnswers,
    numQuestions,
    surveyId,
    getSurveyPath,
    survey,
    isCurrentUserRecipient,
    isNotEditable,
  } = props;

  // NOTE: Manually control sorting as while the data is pre-sorted question order ascending
  // we have nothing on the data items themselves to infer that initial ordering.

  const [rows, setRows] = useState(unansweredQuestions);

  const [sortedBy, setSortedBy] = useState<ISortedBy>({
    columnId: "question",
    direction: SortDirection.ASC,
  });

  const onSortChange: OnSortChange = (columnId, newSortDir) => {
    let sortedRows: vsaqQuestion[];

    switch (columnId) {
      case "type":
        sortedRows = _orderBy(unansweredQuestions, (r) => r.type, newSortDir);
        break;
      default:
        sortedRows = [...unansweredQuestions];
        if (newSortDir === SortDirection.DESC) {
          sortedRows = sortedRows.reverse();
        }
    }

    setRows(sortedRows);
    setSortedBy({
      columnId: columnId,
      direction: newSortDir,
    });
  };

  const [currentPage, currentPageNumber, totalPages, setCurrentPage] =
    usePagination(rows, 10);

  return (
    <ReportCard newStyles className="survey-details-unanswered-questions-card">
      <div className="header" ref={ref}>
        Unanswered questions
        {isCurrentUserRecipient && !surveyDeleted && (
          <Link to={getSurveyPath(!isNotEditable)}>
            <Button>
              {getViewQuestionButtonText(survey, isNotEditable)}
              <div className="cr-icon-arrow-right" />
            </Button>
          </Link>
        )}
      </div>
      <div className="top-section">
        {isVendorPortal ? (
          <div className="info-text">
            Questions left unanswered upon submission will reduce your
            questionnaire score.
          </div>
        ) : (
          <div className="info-text">
            The following questions have not yet been answered. Any questions
            left unanswered will reduce the final score for this questionnaire.
          </div>
        )}
        <ProgressBar
          progress={numAnswers / numQuestions}
          leftText={`${Math.floor(
            (numAnswers / numQuestions) * 100
          )}% complete`}
          rightText={`${numAnswers} of ${numQuestions} answered`}
        />
      </div>
      <XTable
        columnHeaders={columnHeaders}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        rows={currentPage.map((q) => {
          const [questionNumber, questionText] = q.customNumber
            ? [q.customNumber, q.text]
            : separateQuestionNumberFromQuestion(q.text);

          return {
            id: q.id,
            onClick: () =>
              history.push(
                getSurveyPath(isCurrentUserRecipient, surveyId, q.id)
              ),
            cells: [
              <XTableCell key="question" className="question-cell">
                <NodeTypeIcon
                  nodeType={vsaqQuestionTypeToNodeTypeIconType(q.type)}
                  questionNumber={questionNumber}
                />
                <div
                  className="question-text"
                  dangerouslySetInnerHTML={{ __html: questionText }}
                />
              </XTableCell>,
              <XTableCell key="type" className="question-type-cell shrink-cell">
                {vsaqQuestionTypeToText(q.type)}
              </XTableCell>,
              <XTableCell key="chevron" className="shrink-cell">
                <div className="cr-icon-chevron" />
              </XTableCell>,
            ],
          };
        })}
        pagination={{
          currentPage: currentPageNumber,
          totalPages,
          onPageChange: setCurrentPage,
          hidePaginationIfSinglePage: true,
        }}
      />
    </ReportCard>
  );
});

export default memo(SurveyDetailsUnansweredQuestionsCard);
