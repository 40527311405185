export const STARTING_NUM_VENDORS = 10;
export const MIN_NUM_VENDORS = 0;
export const MAX_NUM_VENDORS = 50;
export const BASE_SUBSCRIPTION_COST_CENTS = 19900;
export const PER_VENDOR_COST_CENTS = 7900;
export const SMB_TRIAL_LENGTH_DAYS = 7;

export const LEGACY_SMB_PLAN_ID = "smb";

export const plans = {
  "vendorrisk-sme": {
    name: "VendorRisk",
    description: "Monitor your vendors, send them questionnaires etc.",
    vendorSlots: 8,
    adHocReports: 4,
    price: 29900,
    features: [
      "Complete DIY vendor security assessment",
      "Continuous monitoring of your vendors' Internet presence",
      "Automated security questionnaires",
      "In-built workflow to remediate vendor risks",
      "Monitor up to 8 vendors",
      "One-time reporting for an additional 4 vendors",
      "7 day trial",
      "$299 per month",
    ],
  },
  "breachsight-startup": {
    name: "BreachSight",
    description: "Monitor and remediate your own assets.",
    vendorSlots: 0,
    adHocReports: 0,
    price: 39900,
    features: [
      "Continuous monitoring of your own Internet presence",
      "1800+ vectors checked daily",
      "Monitor unlimited websites",
      "Automated alerts as new risks are detected",
      "In-built workflow to remediate risks",
      "7 day trial",
      "$399 per month",
    ],
  },
};

function prettify(num) {
  return `${num}`.replace(/(\d)(?=(\d{3})$)/g, "$1,");
}

export function vendorSlotCosts(numVendors) {
  return prettify(numVendors * (PER_VENDOR_COST_CENTS / 100));
}

export function monthlyTotal(numVendors) {
  return prettify(
    numVendors * (PER_VENDOR_COST_CENTS / 100) +
      BASE_SUBSCRIPTION_COST_CENTS / 100
  );
}

// total = BASE/100 + vendorSlots x PER_VENDOR_COST/100
// total - BASE/100 = vendorSlots x PER_VENDOR_COST/100
// vendorSlots = (total - BASE/100) / (PER_VENDOR_COST/100)
export function vendorSlotsFromTotalCost(totalCost) {
  return (
    (totalCost - BASE_SUBSCRIPTION_COST_CENTS / 100) /
    (PER_VENDOR_COST_CENTS / 100)
  );
}
