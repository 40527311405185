import * as Sentry from "@sentry/browser";

// disable the sentry client. This is a utility method to be called
// on API session errors, before logging out the users, to avoid
// spamming sentry with unnecessary error logs
export const disableSentry = () => {
  const client = Sentry.getClient();
  if (client) {
    // Browser is reloading, but in-flight requests can cause spammy errors in the lame-duck application:
    // don't send these to Sentry.
    client.getOptions().enabled = false;
  }
};
