import { useEffect } from "react";

// Set the page title, defaulting to UpGuard
const usePageTitle = (title?: string) => {
  const pageTitle = title ? `${title} | UpGuard` : "UpGuard";

  useEffect(() => {
    window.document.title = pageTitle;
  }, [pageTitle]);
};

export default usePageTitle;

// Exporting a function to be used in non-functional components
export function PageTitle(props: { children: string }) {
  usePageTitle(props.children);
  return null;
}
