import {
  fetchVendorPortfolios,
  INewPortfolio,
  IPortfolioUpdateItem,
  Portfolio,
  PortfolioType,
  setPortfoliosForVendors,
} from "../../reducers/portfolios.actions";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import { FC, memo, useCallback, useEffect, useState } from "react";
import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";

import { getVendorWords } from "../../../_common/constants";
import Button from "../../../_common/components/core/Button";
import LoadingBanner from "../../../_common/components/core/LoadingBanner";
import PortfolioSelection, {
  IPortfolioSelectionSelectedItem,
} from "./PortfolioSelection";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import "../../style/components/portfolios/VendorPortfolioSelectionModal.scss";
import {
  UserVendorRiskWrite,
  UserWriteOwnOrganisation,
} from "../../../_common/permissions";
import {
  getDisabledVendorPortfolioIds,
  vendorPortfolioHelpURL,
} from "./helpers";
import { refreshVendorListsAfterChange } from "../../reducers/vendors.actions";
import { AssuranceType } from "../../../_common/types/organisations";
import { useHistory } from "react-router-dom";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IVendorPortfolioSelectionModalOwnProps extends BaseModalProps {
  selectedVendors: IPortfolioSelectionSelectedItem[];
  onClearSelectedVendors?: () => void;
}

interface IVendorPortfolioSelectionModalConnectedProps {
  portfolios?: Portfolio[];
  limit: number;
  disabledPortfolioIds: number[];
  assuranceType: AssuranceType;
  canCreatePortfolios: boolean;
}

type IVendorPortfolioSelectionModalProps =
  IVendorPortfolioSelectionModalOwnProps &
    IVendorPortfolioSelectionModalConnectedProps &
    DefaultThunkDispatchProp;

const VendorPortfolioSelectionModal: FC<
  IVendorPortfolioSelectionModalProps
> = ({
  dispatch,
  active,
  onClose,
  onClearSelectedVendors,
  selectedVendors,
  portfolios,
  limit,
  disabledPortfolioIds,
  assuranceType,
  canCreatePortfolios,
}) => {
  const history = useHistory();
  const [saving, setSaving] = useState(false);
  const [portfolioUpdateState, setPortfolioUpdateState] = useState({
    portfolioUpdateItems: [] as IPortfolioUpdateItem[],
    newPortfolios: [] as INewPortfolio[],
    valid: false,
  });

  // Reset state on active change
  useEffect(() => {
    setSaving(false);
    setPortfolioUpdateState({
      portfolioUpdateItems: [],
      newPortfolios: [],
      valid: false,
    });
  }, [active]);

  const vendorWords = getVendorWords(assuranceType);

  const onSave = async () => {
    if (!portfolioUpdateState.valid) {
      return;
    }

    setSaving(true);

    try {
      await dispatch(
        setPortfoliosForVendors(
          portfolioUpdateState.portfolioUpdateItems,
          portfolioUpdateState.newPortfolios
        )
      );

      const uniqVendorIdsMap = portfolioUpdateState.portfolioUpdateItems.reduce(
        (prev: Record<number | string, true>, next) => {
          next.itemIds.forEach((vId) => (prev[vId] = true));
          return prev;
        },
        {}
      );

      // Refresh any vendor information or lists with new data
      const uniqVendorIds = Object.keys(uniqVendorIdsMap).map((v) =>
        parseInt(v)
      );

      await Promise.all([
        dispatch(fetchVendorPortfolios(true)),
        dispatch(refreshVendorListsAfterChange(uniqVendorIds)),
      ]);
    } catch (e) {
      console.error(e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error setting portfolios. Please contact UpGuard Support."
        )
      );
      setSaving(false);
      return;
    }

    dispatch(addDefaultSuccessAlert("Portfolios updated."));
    onClose();
    if (onClearSelectedVendors) {
      onClearSelectedVendors();
    }
  };

  const onSetUpdateItems = useCallback(
    (
      portfolioUpdateItems: IPortfolioUpdateItem[],
      newPortfolios: INewPortfolio[],
      valid: boolean
    ) => {
      setPortfolioUpdateState({
        portfolioUpdateItems,
        newPortfolios,
        valid,
      });
    },
    []
  );

  if (selectedVendors.length === 0) {
    return null;
  }

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className="vendor-portfolio-selection-modal"
        footerClassName="vendor-portfolio-selection-modal-foot"
        headerContent={`Select portfolios for ${
          selectedVendors.length === 1
            ? selectedVendors[0].name
            : `${selectedVendors.length} ${vendorWords.plural}`
        }`}
        footerContent={
          <>
            {canCreatePortfolios && (
              <Button
                tertiary
                className="btn-align-left"
                onClick={() =>
                  history.push("/settings/vendors", {
                    backTo: history.location.pathname,
                    backToText: "Back",
                  })
                }
              >
                <div className="cr-icon-cog2" /> Manage Portfolios
              </Button>
            )}
            <Button tertiary onClick={onClose} disabled={saving}>
              Cancel
            </Button>
            <Button
              primary
              onClick={onSave}
              loading={saving}
              disabled={saving || !portfolioUpdateState.valid}
            >
              Save changes
            </Button>
          </>
        }
      >
        {portfolios ? (
          <>
            <p>
              Use portfolios to separate monitored {vendorWords.plural} into
              lists. Portfolios can be helpful for maintaining separate lists of{" "}
              {vendorWords.plural} by department, or for special lists such as
              Competitors.
            </p>
            <p>
              To learn more about how to use portfolios, and how to set up
              role-based access for specific portfolios,{" "}
              <a
                href={vendorPortfolioHelpURL}
                target="_blank"
                rel="nofollow noreferrer"
              >
                visit our help page
              </a>
              .
            </p>
            <PortfolioSelection
              portfolioType={PortfolioType.Vendor}
              portfolios={portfolios}
              portfolioLimit={limit}
              canCreatePortfolios={canCreatePortfolios}
              selectedItems={selectedVendors}
              disabledPortfolioIds={disabledPortfolioIds}
              onSetUpdateItems={onSetUpdateItems}
            />
          </>
        ) : (
          <LoadingBanner />
        )}
      </ModalV2>
    </>
  );
};

export default appConnect<
  IVendorPortfolioSelectionModalConnectedProps,
  never,
  IVendorPortfolioSelectionModalOwnProps
>((state) => {
  // Portfolios that should be visible but disabled are those that the current user only has read access to.
  const disabledPortfolioIds = getDisabledVendorPortfolioIds(
    state.common.userData.vendorPortfolioSpecificPermissions
  );

  return {
    portfolios: state.cyberRisk.vendorPortfolios?.portfolios,
    limit: state.cyberRisk.vendorPortfolios?.limit ?? 0,
    disabledPortfolioIds,
    assuranceType: state.common.userData.assuranceType,
    canCreatePortfolios:
      state.common.userData.userPermissions.includes(UserVendorRiskWrite) &&
      state.common.userData.userPermissions.includes(UserWriteOwnOrganisation),
  };
})(memo(VendorPortfolioSelectionModal));
