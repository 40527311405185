import BaseAPI from "../rtkQueryApi";
import { FeatureFlag } from "../types/features";

export enum AdminTagTypes {
  featureFlags = "featureFlags",
}

export interface GetFeatureFlagsV1Response {
  features: FeatureFlag[];
}

export interface GetFeatureFlagsV1Params {
  orgId: number;
}

export const AdminAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [...Object.values(AdminTagTypes)],
}).injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlagsV1: builder.query<
      GetFeatureFlagsV1Response,
      GetFeatureFlagsV1Params
    >({
      providesTags: [AdminTagTypes.featureFlags],
      query: ({ orgId }) => ({
        url: "/admin/features/v1/",
        method: "GET",
        params: {
          org_id: orgId,
        },
      }),
    }),
  }),
});
