import { Invite, IUserMini } from "./user";
import { IAttachmentGroup } from "./attachment";
import { VendorSummaryRisk } from "./vendorSummary";
import { vsaqQuestion } from "../../survey_builder/vsaq/vsaqTypes";
import { IVendorRiskWaiver } from "./vendorRiskWaivers";
import { SurveyUsageType } from "./surveyTypes";
import { LabelColor } from "./label";

export enum SurveyStatus {
  New = "new",
  Autofilled = "autofilled",
  InAutofillReview = "autofillreview",
  Cancelled = "cancelled",
  Sent = "pending",
  InProgress = "opened",
  AwaitingReview = "returned",
  InReview = "inreview",
  Complete = "complete",
  Published = "published",
}

export const surveyStatusNotIn = (
  status: SurveyStatus,
  statuses: SurveyStatus[]
): boolean => {
  const res = statuses.filter((s) => {
    return s === status;
  });
  return res.length === 0;
};

// SurveyAttributes are logical attributes that may apply to a survey based on other field values
export enum SurveyAttribute {
  Overdue = "overdue",
  Shared = "shared",
}

export enum SurveyRiskVisibility {
  Visible = "visible",
  HideSeverity = "hideSeverity",
  Hidden = "hidden",
}

export const parseRiskVisibility = (value: string) => {
  switch (value) {
    case "hideSeverity":
      return SurveyRiskVisibility.HideSeverity;
    case "hidden":
      return SurveyRiskVisibility.Hidden;
    default:
      return SurveyRiskVisibility.Visible;
  }
};

export interface SurveyUser {
  id: number;
  sub: string;
  name: string;
  avatar: string;
  email: string;
  orgId?: number;
}

export interface ISurveyVersion {
  version: number;
  answerId: number;
  shared: boolean;
  createdAt: string;
  lastUpdated: string;
}

export interface IScoreProjection {
  loading: boolean;
  initialScore: number;
  currentScore: number;
  projectedScore: number;
  projectedScoreAt: string;
}

export interface ISurveyListItemResponse {
  id: number;
  name?: string;
  description?: string;
  status: SurveyStatus;
  vendorId: number;
  vendorName?: string;
  vendorHostname?: string;
  vendorManaged?: boolean;
  type: string;
  typeId?: number;
  typeSectionCount: number;
  sections: string[];
  dateCreated: string;
  dateSubmitted?: string;
  dateCompleted?: string;
  dateOpened?: string;
  dateCancelled?: string;
  dateLastSent: string;
  dueDate?: string;
  fromEmail: string;
  fromUser?: IUserMini;
  fromCompany: string;
  sharedWith: SurveyUser[];
  collaborators: SurveyUser[];
  sharable: boolean;
  lastAuthor: string;
  openInvites?: Invite[];
  openCollaborationInvites?: Invite[];
  attachmentGroups?: IAttachmentGroup[];
  risks?: VendorSummaryRisk[];
  risksAreFromPreSendAutofill?: boolean;
  riskScore: number;
  adjustedRiskScore: number;
  risksInRemediation: number;
  resendDate?: string;
  resendInterval: number;
  originalID: number;
  numQuestions: number;
  numAnswers: number;
  unansweredQuestions?: vsaqQuestion[];
  messages: number;
  unreadMessages: number;
  versions?: ISurveyVersion[];
  archived: boolean;
  organisationId: number;
  organisationName: string;
  categoriseRisksInSummary: boolean;
  riskWaivers?: IVendorRiskWaiver[];
  mustRequestAccess: boolean;
  isPrefilledSurvey: boolean;
  isSystemSurvey: boolean;
  isImportedSurvey: boolean;
  addToSharedProfile: boolean;
  answersSharedWithVerifiedOrg?: boolean;
  isScoreMerged?: boolean;
  excludeFromVendorScore: boolean;
  canIncludeInVendorScore: boolean;
  deletedAt?: string;
  usageType: SurveyUsageType;
  automationExists?: boolean;
  automationDisabled?: boolean;
  isLatestInstanceForVendor?: boolean;
  riskVisibility: SurveyRiskVisibility;
  canEditIfComplete: boolean;
  previousStatus: SurveyStatus;
  isAnalystWorkflowSurvey: boolean;
  dateAnalystAutofill?: string;
  dateAnalystAutofillReview?: string;
}

export interface ISurveyReminder {
  id: number;
  when: string;
  status: string;
}

export interface ISurveyMini {
  id: number;
  name: string;
  dateCreated: string;
  dateCompleted: string;
  type: string;
  sections: string[];
  sharedWith: SurveyUser[];
  openInvites: Invite[];
  isPublicSurvey: boolean;
  vendorId: number;
}

// maps to surveyDetailsRespSurveyImportDetails in the survey_details_v1 endpoint.
export interface ISurveyImportDetails {
  uuid: string;
  documentFilename: string;
  assigneeUserIDs: number[];
}

export const PrefilledSurveyStatus = {
  Published: "published",
  Draft: "draft",
};

export const TimelineStatusMap: Record<
  string,
  {
    text: string;
    importedSurveyText?: string;
    labelColor: LabelColor;
  }
> = {
  [SurveyStatus.New]: {
    text: "Not Sent",
    importedSurveyText: "To do",
    labelColor: LabelColor.LightBlue,
  },
  [SurveyStatus.Autofilled]: {
    text: "Autofilled",
    importedSurveyText: "To do",
    labelColor: LabelColor.Fuchsia,
  },
  [SurveyStatus.InAutofillReview]: {
    text: "Reviewing Autofill",
    importedSurveyText: "To do",
    labelColor: LabelColor.Fuchsia,
  },
  [SurveyStatus.Sent]: {
    text: "Sent",
    importedSurveyText: "To do",
    labelColor: LabelColor.LightBlue,
  },
  [SurveyStatus.InProgress]: {
    text: "Opened",
    importedSurveyText: "In progress",
    labelColor: LabelColor.Fuchsia,
  },
  [SurveyStatus.Cancelled]: {
    text: "Cancelled",
    labelColor: LabelColor.Orange,
  },
  [SurveyStatus.AwaitingReview]: {
    text: "Awaiting Review",
    labelColor: LabelColor.Blue,
  },
  [SurveyStatus.InReview]: {
    text: "In Review",
    labelColor: LabelColor.Violet,
  },
  [SurveyStatus.Complete]: {
    text: "Completed",
    labelColor: LabelColor.Green,
  },
  inremediation: {
    text: "In Remediation",
    labelColor: LabelColor.Blue, // TODO remove this in a future release
  },
  Overdue: {
    text: "Overdue",
    labelColor: LabelColor.Red,
  },
  Submitted: {
    text: "Submitted",
    labelColor: LabelColor.Blue,
  },
  Open: {
    text: "Open",
    labelColor: LabelColor.Fuchsia,
  },
  Closed: {
    text: "Closed",
    labelColor: LabelColor.Green,
  },
  AwaitingReview: {
    text: "Awaiting Review",
    labelColor: LabelColor.Orange,
  },
  archived: {
    text: "Archived",
    labelColor: LabelColor.Red,
  },
  unarchived: {
    text: "Unarchived",
    labelColor: LabelColor.Green,
  },
  [PrefilledSurveyStatus.Published]: {
    text: "Published",
    labelColor: LabelColor.Blue,
  },
  [PrefilledSurveyStatus.Draft]: {
    text: "Draft",
    labelColor: LabelColor.Orange,
  },
  Remediated: {
    text: "Remediated",
    labelColor: LabelColor.Green,
  },
  ["Not Remediated"]: {
    text: "Not Remediated",
    labelColor: LabelColor.Red,
  },
  PublicPublishedForList: {
    text: "Shared",
    labelColor: LabelColor.Fuchsia,
  },
  // Managed vendor service statuses
  not_started: {
    text: "Not started",
    labelColor: LabelColor.Blue,
  },
  on_hold: {
    text: "On hold",
    labelColor: LabelColor.Violet,
  },
  blocked: {
    text: "Blocked",
    labelColor: LabelColor.Red,
  },
  in_progress: {
    text: "In progress",
    labelColor: LabelColor.Yellow,
  },
  // vendor assessment statuses
  ["new_assessment"]: {
    text: "New assessment",
    labelColor: LabelColor.Blue,
  },
  status: {
    text: "Status updated",
    labelColor: LabelColor.Fuchsia,
  },
  withcustomerchange: {
    text: "With customer",
    labelColor: LabelColor.Green,
  },
};

export const GetQuestionnaireStatusItem = (
  status: SurveyStatus,
  dueDate: string | undefined,
  isVendorUser = false
) => {
  let isOverdue = false;
  if (dueDate) {
    const dd = new Date(dueDate);
    // dueDate is rendered by the backend as a RFC3339 string as the start of the due date in the user's location.
    // Give 'em til the end of the day until it's considered overdue.
    dd.setDate(dd.getDate() + 1);
    isOverdue = dd < new Date();
  }

  if (
    isOverdue &&
    (status === SurveyStatus.Sent || status == SurveyStatus.InProgress)
  ) {
    return TimelineStatusMap.Overdue;
  }
  if (isVendorUser && status === SurveyStatus.AwaitingReview) {
    return TimelineStatusMap.Submitted;
  }
  return TimelineStatusMap[status];
};

// GetStatusFilterDisplayName - get the display name for a survey status, when shown as a filter option
export const GetStatusFilterDisplayName = (status: SurveyStatus) => {
  return TimelineStatusMap[status].text;
};

export const GetStatusFilterAttributeDisplayName = (
  attribute: SurveyAttribute
) => {
  if (attribute === SurveyAttribute.Overdue) {
    return "Overdue";
  }

  if (attribute === SurveyAttribute.Shared) {
    return "Shared";
  }

  return undefined;
};

// GetLabelForSurveyStatus
// Converts a questionnaire status into it's human readable form for display.
export const GetLabelForSurveyStatus = (status: SurveyStatus): string => {
  switch (status) {
    case SurveyStatus.Cancelled:
      return "Cancelled";
    case SurveyStatus.Sent:
      return "Sent";
    case SurveyStatus.InProgress:
      return "In Progress";
    case SurveyStatus.AwaitingReview:
      return "Awaiting Review";
    case SurveyStatus.InReview:
      return "In Review";
    case SurveyStatus.Complete:
      return "Complete";
    case SurveyStatus.Published:
      return "Published";
    default:
      return "";
  }
};
