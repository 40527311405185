import PropTypes from "prop-types";
import Icon from "./core/Icon";

import "../style/components/BackArrow.scss";

const BackArrow = ({ onClick, popup }) => (
  <div className="back-arrow" onClick={onClick}>
    <Icon name="chevron" direction={270} />
    {popup && <div className="popup">{popup}</div>}
  </div>
);

BackArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
  popup: PropTypes.any,
};

BackArrow.defaultProps = { popup: null };

export default BackArrow;
