import { FC, useCallback, useMemo, useState } from "react";
import ContentLibraryAPI from "../api/contentLibraryAPI";
import {
  typeNameMaxLen,
  typeNameMinLen,
} from "../../vendorrisk/components/settings/ManageContentLibraryDocumentTypesCard";
import { CreatableV2 } from "../../_common/components/SelectV2";
import { ActionMeta, ValueType } from "react-select";
import { LogError } from "../../_common/helpers";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import {
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../_common/reducers/messageAlerts.actions";

import "../styles/DocumentTypeSelector.scss";

interface IDocumentTypeSelectorProps {
  selectedDocumentType?: string;
  setSelectedDocumentType: (type: string) => void;
  staticEditable?: boolean;
  placeholder?: string;
  usePortal?: boolean;
}

interface optionType {
  label: string;
  value: string;
}

const DocumentTypeSelector: FC<IDocumentTypeSelectorProps> = ({
  selectedDocumentType,
  setSelectedDocumentType,
  staticEditable,
  placeholder,
  usePortal,
}) => {
  const dispatch = useAppDispatch();
  const [isCreatingNewType, setIsCreatingNewType] = useState(false);
  const { data: documentTypeData, isLoading: documentTypesLoading } =
    ContentLibraryAPI.useGetContentLibraryDocumentTypesListQuery();

  const [createDocumentType] =
    ContentLibraryAPI.useCreateContentLibraryDocumentTypeMutation();

  const documentTypeOpts: optionType[] = useMemo(
    () =>
      documentTypeData
        ? documentTypeData.types.map((type) => ({
            label: type.name,
            value: type.name,
          }))
        : [],
    [documentTypeData]
  );

  const isValidNewOption = useCallback(
    (val: string) =>
      val.length >= typeNameMinLen &&
      val.length <= typeNameMaxLen &&
      !documentTypeData?.types.find(
        (t) => t.name.trim().toLowerCase() === val.trim().toLowerCase()
      ),
    [documentTypeData]
  );

  const onChange = useCallback(
    async (
      opt: ValueType<optionType, false>,
      action: ActionMeta<optionType>
    ) => {
      if (!opt || opt.value === selectedDocumentType) {
        return;
      }

      // If the option is a new type, create it first before setting it
      if (action.action === "create-option") {
        if (!isValidNewOption(opt.value)) {
          dispatch(
            addDefaultWarningAlert(
              `Please enter a unique name between ${typeNameMinLen} and ${typeNameMaxLen} characters.`
            )
          );
          return;
        }

        setIsCreatingNewType(true);

        try {
          await createDocumentType({
            name: opt.value,
          }).unwrap();
        } catch (e) {
          setIsCreatingNewType(false);
          LogError("error creating new content library document type", e);
          dispatch(
            addDefaultUnknownErrorAlert(
              "Error creating new document type. Please contact UpGuard Support."
            )
          );
          return;
        }

        setIsCreatingNewType(false);
      }

      setSelectedDocumentType(opt.value);
    },
    [
      dispatch,
      selectedDocumentType,
      setSelectedDocumentType,
      createDocumentType,
      isValidNewOption,
    ]
  );

  return (
    <CreatableV2<false, optionType>
      className="document-type-selector"
      isLoading={documentTypesLoading || isCreatingNewType}
      options={documentTypeOpts}
      value={
        selectedDocumentType
          ? {
              label: selectedDocumentType,
              value: selectedDocumentType,
            }
          : undefined
      }
      onChange={onChange}
      isValidNewOption={isValidNewOption}
      formatCreateLabel={(val) => (
        <div className="new-type-label">
          <div className="cr-icon-plus" /> Create new type &apos;{val}&apos;
        </div>
      )}
      staticEditable={staticEditable}
      createHintText="Start typing to add a new document type..."
      placeholder={placeholder ?? "Select type"}
      usePortal={usePortal}
    />
  );
};

export default DocumentTypeSelector;
