import { FC } from "react";
import UserBaseAPI from "../api/userbase.api";
import { useDefaultHistory } from "../../_common/types/router";
import { applicationsUrl, dashboardUrl } from "../UserBaseAppRouter";
import SingleStat from "./SingleStat";
import { AppStatus } from "../api/types";
import { pluralise } from "../../_common/helpers";

const OrgAppsNeedsReviewCount: FC = ({}) => {
  const history = useDefaultHistory();

  const onViewApps = () => {
    history.push(applicationsUrl, {
      backContext: {
        backTo: dashboardUrl,
        backToText: `Back to Dashboard`,
      },
    });
  };

  const { data, isLoading } = UserBaseAPI.useGetUserBaseDashboardStatsV1Query();

  const numAppsNeedReview =
    (data?.stats?.appStatusCounts ?? []).find(
      (a) => a.appStatus === AppStatus.NeedsReviewAppStatus
    )?.count ?? 0;

  return (
    <SingleStat
      isLoading={isLoading}
      statNum={numAppsNeedReview}
      statDescription={pluralise(
        numAppsNeedReview,
        "App needs initial review",
        "Apps need initial review"
      )}
      onClick={onViewApps}
      statLinkText="Manage app access"
    />
  );
};

export default OrgAppsNeedsReviewCount;
