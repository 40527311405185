import { FC } from "react";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import Button from "../../_common/components/core/Button";
import classNames from "classnames";

import "./SingleStat.scss";

interface SingleStatProps {
  isLoading: boolean;
  statNum: number;
  statDescription: string;
  statLinkText?: string;
  onClick?: () => void;
  className?: string;
}
const SingleStat: FC<SingleStatProps> = ({
  isLoading,
  statNum,
  statDescription,
  statLinkText,
  onClick,
  className,
}) => {
  return (
    <div className={classNames("ub-single-stat", className)}>
      {isLoading && <LoadingBanner />}
      {!isLoading && (
        <div className={"content"}>
          <div className="count">{statNum}</div>
          <div className="subtext">{statDescription}</div>
          {statLinkText && onClick && (
            <div>
              <div className="link-button">
                <Button tertiary onClick={onClick}>
                  {statLinkText}
                </Button>
                <div className="cr-icon-arrow-right" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SingleStat;
