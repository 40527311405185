import { PureComponent, Component } from "react";
import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import { Router, matchPath, Switch, Route } from "react-router-dom";
import { get as _get, throttle as _throttle } from "lodash";
import * as resizeDetector from "resize-detector";
import Gravatar from "../components/core/Gravatar";
import LoadingIcon from "../components/core/LoadingIcon";
import { ContactUsModal } from "../../vendorrisk/components/modals/ContactUsModal";

import {
  switchUserOrg,
  setViewingVendorId,
  fetchUserInitiatedExports,
  setViewingManagedVendorIdForOrgId,
  setTPVMSessionDetails,
  fetchAnalystInitiatedExports,
  reloadCompleteActivityStream,
  fetchUserData,
} from "../reducers/commonActions";
import CommonRouter from "./CommonRouter";
import { pageDescriptions } from "../pageDescriptions";
import { getUserLastActive } from "../session";
import { loadInitialActivityStream } from "../reducers/commonActions";

import * as Permissions from "../permissions";
import { trackEvent } from "../tracking";
import PillLabel from "../../vendorrisk/components/PillLabel";

import UpGuardLogo from "../images/upguard-logo-white.svg";
import "../style/components/Navigation.scss";
import {
  UserWriteVendorAssessments,
  UserManageAuditLog,
  OrgAccessAuditLog,
  GroupAccessVendorAssetSharing,
  OrgAccessCustomDomainsIPsForVendors,
  UserVendorRiskWrite,
  UserVendorRiskEnabled,
  OrgAccessVendors,
  getUserPermissionsForVendorFromState,
  OrgAccessAppGuard,
  UserUserBaseWrite,
  UserUserBaseEnabled,
} from "../permissions";
import { userReportExportStatuses } from "../../vendorrisk/views/reporting/ReportExportsV2";
import {
  canAccessSystemAdmin,
  hasPermissionFromUserData,
} from "../selectors/permissions";
import Icon from "../components/core/Icon";
import ErrorBoundary from "../components/ErrorBoundary";
import { isAdmin } from "../../vendorrisk/helpers/roles";
import { AssuranceType, organisationAccountType } from "../types/organisations";
import OnboardingSteps, { shouldShowOnboardingScreen } from "./OnboardingSteps";
import PLGOnboardingFlow from "./PLGOnboardingFlow";
import DropdownV2, {
  DropdownAutoscroller,
  DropdownItem,
  DropdownSeparator,
  DropdownSubheader,
} from "../components/core/DropdownV2";
import HelpAndSupportDropdown from "../components/HelpAndSupportDropdown";
import { fetchVendorImport } from "../../vendorrisk/reducers/vendorImport.actions";
import { getCurrentOrgFromUserData, isExpiredTrial } from "../helpers";
import { OnboardingStepName } from "../types/onboardingStepsMeta";
import TrialAccountRevertedModal from "../components/TrialAccountRevertedModal";
import {
  fetchOrgFreeTrialEligibility,
  requestSlackNotification,
  retrieveOnboardingCalendarForUser,
} from "../reducers/freeTrial.actions";
import { fetchCustomerLimitData } from "../../vendorrisk/reducers/cyberRiskActions";
import { getVendorWords } from "../constants";
import { BookOnboardingCallModal } from "../components/navigation/BookOnboardingCallModal";
import {
  BookTrialUpgradeCallModal,
  EVENT_BOOK_TRIAL_PRESSED,
} from "../components/navigation/BookTrialUpgradeCallModal";
import RouterTriggeredModal from "../../vendorrisk/components/modals/RouterTriggeredModal";
import { InAppMessageType } from "../components/InAppMessage";
import NavLink, {
  NewFeatureInAppMessagePopupContent,
} from "../components/NavLink";
import FreeTrialUpsellNavContent from "../components/navigation/FreeTrialUpsellNavContent";
import { LabelColor } from "../types/label";
import HotjarWrapper from "../components/HotjarWrapper";
import { appConnect } from "../types/reduxHooks";
import AccountNavItems from "../components/navigation/AccountNavItems";
import BreachSightNavItems from "../components/navigation/BreachSightNavItems";
import TermsAndConditionsUpdateMessage from "../components/TermsAndConditionsUpdateMessage";
import AppGuardNavItems, {
  AppGuardBreachsightCloudNavItems,
} from "../../appguard/AppGuardNavItems";
import UserBaseNavItems from "../../userbase/UserBaseNavItems";
import Footer from "../components/Footer";
import { getSubtitleDisplay } from "../helpers/string.helpers";
import HubspotWidgetWrapper from "../components/HubspotWidgetWrapper";

class ReportExportsNavItem extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    numUnread: PropTypes.number.isRequired,
    numPending: PropTypes.number.isRequired,
  };

  static popupVisible = (numPending, pendingItemsComplete, popupDismissed) => {
    return (numPending > 0 || pendingItemsComplete) && !popupDismissed;
  };

  state = {
    popupDismissed: false,
    onboardingStepsDismissed: false,
    pendingItemsComplete: false,
    distFromTop: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.numPending !== this.props.numPending) {
      if (this.props.numPending > 0) {
        // Just reset the dismissed state
        this.setState({ popupDismissed: false, pendingItemsComplete: false });
      } else if (prevProps.numUnread === this.props.numUnread) {
        // numPending has changed but numUnread hasn't - pending items were deleted.
        this.setState({ popupDismissed: true });
      } else {
        // There were previously pending exports, now there are none. Show a popup saying the pending items are completed.
        this.setState({ popupDismissed: false, pendingItemsComplete: true });

        // Close the popup automatically in 5s.
        setTimeout(() => {
          this.setState((state) => {
            if (state.pendingItemsComplete) {
              return { popupDismissed: true };
            }
          });
        }, 5000);

        // And reset the pendingItemsComplete state in 5.25 secs (to give the popup time to hide).
        setTimeout(() => {
          this.setState({ pendingItemsComplete: false });
        }, 5250);
      }
    }

    const prevVisible = ReportExportsNavItem.popupVisible(
      prevProps.numPending,
      prevState.pendingItemsComplete,
      prevState.popupDismissed
    );
    const nowVisible = ReportExportsNavItem.popupVisible(
      this.props.numPending,
      this.state.pendingItemsComplete,
      this.state.popupDismissed
    );

    if (!prevVisible && nowVisible) {
      // The popup has just become visible. Give it the right distance from the top of the page
      this.refreshDistFromTop();
      // And start watching for scroll events
      this.addScrollWatcher();
    } else if (prevVisible && !nowVisible) {
      // The popup has disappeared. Stop watching for scroll events.
      this.removeScrollWatcher();
    }
  }

  addScrollWatcher = () => {
    const heightAdjustableEl = document.querySelector(
      "#side-navigation .height-adjustable-inner"
    );
    if (heightAdjustableEl) {
      heightAdjustableEl.addEventListener("scroll", this.scrollWatcher);
    }
  };

  removeScrollWatcher = () => {
    const heightAdjustableEl = document.querySelector(
      "#side-navigation .height-adjustable-inner"
    );
    if (heightAdjustableEl) {
      heightAdjustableEl.removeEventListener("scroll", this.scrollWatcher);
    }
  };

  refreshDistFromTop = () => {
    const distanceFromTop = this.ref.getBoundingClientRect().top;
    this.setState({ distFromTop: distanceFromTop });
  };

  scrollWatcher = _throttle(this.refreshDistFromTop, 20);

  render() {
    if (this.props.numUnread === 0 && this.props.numPending === 0) {
      return <span ref={(ref) => (this.ref = ref)}>Reports</span>;
    }

    const popupStyle = {};
    let leftTriangle = true;
    if (this.state.distFromTop !== null) {
      let { distFromTop } = this.state;
      if (distFromTop < 20) {
        distFromTop = 20;
        leftTriangle = false;
      }

      popupStyle.top = `${distFromTop + 16}px`;
    }

    const { pendingItemsComplete } = this.state;
    return (
      <span ref={(ref) => (this.ref = ref)}>
        Reports{" "}
        {this.props.numUnread > 0 && (
          <PillLabel
            color="lightblue"
            filled
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.props.history.push("/reportexports/generated");
            }}
          >
            {`${this.props.numUnread} new`}
          </PillLabel>
        )}
        <div
          style={popupStyle}
          className={classnames("exports-popup", {
            visible: ReportExportsNavItem.popupVisible(
              this.props.numPending,
              pendingItemsComplete,
              this.state.popupDismissed
            ),
            inverted: pendingItemsComplete,
            "left-triangle": leftTriangle,
          })}
        >
          <div
            className={"body"}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.props.history.push("/reportexports/generated");
            }}
          >
            <div className={"msg"}>
              {pendingItemsComplete ? (
                <>
                  {false && <Icon name="checkmark" />}
                  <div>Reports complete</div>
                </>
              ) : (
                <>
                  <LoadingIcon />
                  <div className={"message"}>
                    {this.props.numPending}{" "}
                    {this.props.numPending === 1 ? "report" : "reports"}{" "}
                    processing
                  </div>
                </>
              )}
              <Icon
                name="x"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.setState({ popupDismissed: true });
                }}
              />
            </div>
            {pendingItemsComplete && (
              <div className={"sub-msg"}>
                {"View now"}
                <Icon name="arrow" direction={90} />
              </div>
            )}
          </div>
        </div>
      </span>
    );
  }
}

// getVendorIdFromPath gets a vendor ID from a given route path.
// If this is not a vendor route, null is returned.
const getVendorIdFromPath = (pathname) => {
  const isOnVendorRouteMatch = matchPath(pathname, {
    path: "/vendor/:vendorId",
  });
  if (isOnVendorRouteMatch) {
    return parseInt(isOnVendorRouteMatch.params.vendorId);
  }

  return null;
};

const getAnalystOrgIdAndVendorIdFromPath = (pathname) => {
  const isOnVendorManagementRouteMatch = matchPath(pathname, {
    path: "/analysts/tpvm/:orgId/:vendorId",
  });

  if (isOnVendorManagementRouteMatch) {
    const managedVendorId = parseInt(
      isOnVendorManagementRouteMatch.params.vendorId
    );
    const managedOrgId = parseInt(isOnVendorManagementRouteMatch.params.orgId);
    return { managedOrgId, managedVendorId };
  }
  return { managedOrgId: null, managedVendorId: null };
};

const withVendorData = ({ render, ...otherProps }) => {
  return render(otherProps);
};

withVendorData.propTypes = {
  render: PropTypes.func.isRequired,
  vendorId: PropTypes.number.isRequired,
  isSubsidiary: PropTypes.bool,
  isManagedVendor: PropTypes.bool,
  managedOrgId: PropTypes.number,
};

const WithVendorData = appConnect((state, props) => {
  let vendorName = "";
  let sharedAssessmentPublished = false;
  let riskAssessmentPublished = false;
  let sharedRiskAssessmentAvailable = false;
  let vendorHasSubsidiaries = false;
  let primaryHostname = ""; // We can check this prior to loading the vendor summary
  let hasWebPresence = false; // Calculated after vendor summary as a fallback if the vendor has no primary domain but does have a web presence

  if (props.isSubsidiary && state.cyberRisk.subsidiaries[props.vendorId]) {
    vendorName = state.cyberRisk.subsidiaries[props.vendorId].display_name;
    primaryHostname =
      state.cyberRisk.subsidiaries[props.vendorId].primary_hostname;
    vendorHasSubsidiaries =
      state.cyberRisk.subsidiaries[props.vendorId].has_subsidiaries;
  } else if (
    props.isManagedVendor &&
    state.cyberRisk.managedVendorData[props.managedOrgId] &&
    state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId]
  ) {
    // this vendor is being displayed as a managed vendor within the analyst portal.
    vendorName =
      state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId]
        .display_name;
    primaryHostname =
      state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId]
        .primary_hostname;
    ({ sharedAssessmentPublished } =
      state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId]);
    let vendorAssessmentSummary = _get(
      state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId],
      "summary.result.assessmentSummary",
      null
    );
    let numSharedRiskAssessments = _get(
      state.cyberRisk.managedVendorData[props.managedOrgId][props.vendorId],
      "summary.result.numSharedRiskAssessments",
      0
    );

    riskAssessmentPublished = !!vendorAssessmentSummary;
    sharedRiskAssessmentAvailable = numSharedRiskAssessments > 0;
  } else if (
    !props.isSubsidiary &&
    !props.isManagedVendor &&
    state.cyberRisk.vendors[props.vendorId]
  ) {
    vendorName = state.cyberRisk.vendors[props.vendorId].display_name;
    primaryHostname = state.cyberRisk.vendors[props.vendorId].primary_hostname;
    ({ sharedAssessmentPublished } = state.cyberRisk.vendors[props.vendorId]);

    let vendorAssessmentSummary = _get(
      state.cyberRisk.vendors[props.vendorId],
      "summary.result.assessmentSummary",
      null
    );
    let numSharedRiskAssessments = _get(
      state.cyberRisk.vendors[props.vendorId],
      "summary.result.numSharedRiskAssessments",
      0
    );

    riskAssessmentPublished = !!vendorAssessmentSummary;
    sharedRiskAssessmentAvailable = numSharedRiskAssessments > 0;

    hasWebPresence =
      _get(
        state.cyberRisk.vendors[props.vendorId],
        "summary.result.totalCloudscans",
        0
      ) +
        _get(
          state.cyberRisk.vendors[props.vendorId],
          "summary.result.totalInactiveDomains",
          0
        ) >
      0;

    // If this org supports custom IPs, we should still show the domain/IP screens so they have a chance to add them.
    if (!!state.common?.permissions?.org[OrgAccessCustomDomainsIPsForVendors]) {
      hasWebPresence = true;
    }
  }

  const userPermsForVendor = getUserPermissionsForVendorFromState(
    state,
    props.isSubsidiary || props.isManagedVendor ? 0 : props.vendorId
  );

  return {
    vendorName,
    vendorHasSubsidiaries,
    sharedAssessmentPublished,
    riskAssessmentPublished,
    sharedRiskAssessmentAvailable,
    primaryHostname,
    hasWebPresence,
    userPermsForVendor,
  };
})(withVendorData);

const UserMenu = ({
  orgList,
  currentOrgID,
  impersonatingOrgID,
  history,
  onLogoutClick,
  onSwitchOrgClick,
}) => {
  // Add a dropdown item for each org the user has access to, sorted by name
  const sortedOrgs = [];
  let impersonatingOrg;

  if (orgList) {
    for (let i = 0; i < orgList.length; i++) {
      if (impersonatingOrgID > 0 && impersonatingOrgID === orgList[i].id) {
        impersonatingOrg = orgList[i];
        continue;
      }
      sortedOrgs.push(orgList[i]);
    }
  }

  sortedOrgs.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <DropdownAutoscroller popsUpFromBottom>
      {impersonatingOrgID > 0 && (
        <>
          <DropdownSubheader>Impersonating</DropdownSubheader>
          <DropdownItem>{impersonatingOrg.name}</DropdownItem>
          <DropdownSeparator />
        </>
      )}
      {sortedOrgs.length > 0 && (
        <>
          <DropdownSubheader>Switch account</DropdownSubheader>
          {sortedOrgs.map((o) => (
            <DropdownItem
              key={o.id}
              className={`user-list-dropdown-item ${
                currentOrgID === o.id ? "active-org" : ""
              }`}
              onClick={
                currentOrgID === o.id
                  ? undefined
                  : () => onSwitchOrgClick(o.id, o.name)
              }
            >
              <div>{o.name}</div>
              {currentOrgID === o.id && (
                <div className="active-check">
                  <div className="cr-icon-check" />
                </div>
              )}
            </DropdownItem>
          ))}
          <DropdownSeparator />
        </>
      )}
      <DropdownItem onClick={() => history.push("/profile")}>
        Profile
      </DropdownItem>
      <DropdownItem onClick={onLogoutClick}>Sign out</DropdownItem>
    </DropdownAutoscroller>
  );
};

UserMenu.propTypes = {
  orgList: PropTypes.array,
  currentOrgID: PropTypes.number.isRequired,
  impersonatingOrgID: PropTypes.number.isRequired,
  impersonatingOrg: PropTypes.object,
  history: PropTypes.object.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  onSwitchOrgClick: PropTypes.func.isRequired,
};

UserMenu.defaultProps = {
  orgList: [],
  impersonatingOrg: null,
};

// HeightAdjustableContainer contains the nav items and watches the height, and scroll height, so
// appropriate fader elements can be added when the element is scrolled or is scrollable.
export class HeightAdjustableContainer extends PureComponent {
  static propTypes = {
    children: PropTypes.any.isRequired,
    adjustFooter: PropTypes.bool,
  };

  state = {
    topFaderOpacity: 0,
    bottomFaderOpacity: 0,
  };

  componentDidMount() {
    this.heightAdjustableScrollWatcher();
    resizeDetector.addListener(
      this.heightAdjustableInner,
      this.heightAdjustableScrollWatcher
    );
    resizeDetector.addListener(
      this.actualHeightWatcher,
      this.heightAdjustableScrollWatcher
    );
    this.heightAdjustableInner.addEventListener(
      "scroll",
      this.heightAdjustableScrollWatcher
    );

    // Update the footer element to adjust for navbar width
    if (this.props.adjustFooter) {
      const footerElement = document.getElementById("foot");
      if (footerElement) {
        footerElement.classList.add("navbar-offset");
      }
    }
  }

  componentWillUnmount() {
    resizeDetector.removeListener(
      this.heightAdjustableInner,
      this.heightAdjustableScrollWatcher
    );
    resizeDetector.removeListener(
      this.actualHeightWatcher,
      this.heightAdjustableScrollWatcher
    );
    this.heightAdjustableInner.removeEventListener(
      "scroll",
      this.heightAdjustableScrollWatcher
    );

    // Update the footer element to adjust for no navbar
    if (this.props.adjustFooter) {
      const footerElement = document.getElementById("foot");
      if (footerElement) {
        footerElement.classList.remove("navbar-offset");
      }
    }
  }

  heightAdjustableScrollWatcher = _throttle(() => {
    if (!this.heightAdjustableInner) {
      return;
    }

    const topObscuredBy = this.heightAdjustableInner.scrollTop;
    const bottomObscuredBy =
      this.heightAdjustableInner.scrollHeight -
      this.heightAdjustableInner.scrollTop -
      this.heightAdjustableInner.clientHeight;

    let topFaderOpacity = 0;
    if (topObscuredBy > 0 && topObscuredBy < 100) {
      topFaderOpacity = topObscuredBy / 100;
    } else if (topObscuredBy >= 100) {
      topFaderOpacity = 1;
    }

    let bottomFaderOpacity = 0;
    if (bottomObscuredBy > 0 && bottomObscuredBy < 100) {
      bottomFaderOpacity = bottomObscuredBy / 100;
    } else if (bottomObscuredBy >= 100) {
      bottomFaderOpacity = 1;
    }

    this.setState({ topFaderOpacity, bottomFaderOpacity });
  }, 50);

  scrollToElement = (selector) => {
    const element = this.heightAdjustableInner.querySelector(selector);
    if (!element) {
      return;
    }

    if (
      this.heightAdjustableInner.scrollTop <=
      this.heightAdjustableInner.scrollHeight -
        this.heightAdjustableInner.clientHeight
    ) {
      this.heightAdjustableInner.scrollTo({
        top: element.getBoundingClientRect().top - 40,
        behavior: "smooth",
      });
    }
  };

  render() {
    return (
      <div className="height-adjustable">
        <div
          className="scroll-fader top-fader"
          style={{ opacity: this.state.topFaderOpacity }}
        />
        <div
          className="height-adjustable-inner"
          ref={(ref) => (this.heightAdjustableInner = ref)}
        >
          <div
            className="height-adjustable-watcher"
            ref={(ref) => (this.actualHeightWatcher = ref)}
          >
            {this.props.children}
          </div>
        </div>
        <div
          className="scroll-fader bottom-fader"
          style={{ opacity: this.state.bottomFaderOpacity }}
        />
      </div>
    );
  }
}

class Navigation extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    userData: PropTypes.object.isRequired,
    onLogoutClick: PropTypes.func.isRequired,
    viewingVendorId: PropTypes.number,
    vendorWatchLimit: PropTypes.number,
    vendorWatchLimitIsHard: PropTypes.bool,
    vendorLookupLimit: PropTypes.number,
    vendorLookupLimitIsHard: PropTypes.bool,
    vendorLookupCount: PropTypes.number,
    vendors: PropTypes.object.isRequired,
    exportItems: PropTypes.array,
    canAccessSystemAdmin: PropTypes.bool.isRequired,
    viewingManagedVendorOrgId: PropTypes.number,
    viewingManagedVendorId: PropTypes.number,
    managedVendorOrgEntitlements: PropTypes.array,
    managedOrgGroupEntitlements: PropTypes.array,
    alertsCount: PropTypes.number,
    activityStreamTaskCount: PropTypes.number,
    hasActiveVendorComparison: PropTypes.bool.isRequired,
    activeVendorImport: PropTypes.object,
    freeTrialEligibility: PropTypes.object,
    freeTrialOnboardingCalendar: PropTypes.string,
    canViewViewingVendor: PropTypes.bool.isRequired,
    permissions: PropTypes.object,
  };

  static defaultProps = {
    viewingVendorId: null,
    exportItems: null,
    viewingManagedVendorOrgId: null,
    viewingManagedVendorId: null,
    managedVendorOrgEntitlements: [],
    managedOrgGroupEntitlements: [],
    alertsCount: 0,
    activityStreamTaskCount: 0,
    freeTrialEligibility: {
      eligible: false,
    },
    freeTrialOnboardingCalendar: undefined,
    permissions: { org: {}, user: {} },
  };

  constructor(props) {
    super(props);

    this.state = {
      open: true,
      userMenuOpen: false,
      location: {
        pathname: props.history.location.pathname,
        state: props.history.location.state,
      },
      onboardingCallModalActive: false,
      trialUpgradeCallModalActive: false,
      // Show a trial account reverted modal if the onboarding step exists.
      trialAccountRevertedModalActive: !!(props.userData.onboardingSteps || {})[
        OnboardingStepName.TrialAccountRevertedToFree
      ],
      vendorRiskUpgradeModalOpen: false,
    };

    // set the initial value of our tpvm session state related data in the redux state
    props.dispatch(setTPVMSessionDetails(props.history.location.pathname));

    if (props.userData.currentOrgID) {
      const currentOrg = getCurrentOrgFromUserData(props.userData);
      if (currentOrg) {
        const isExpired = isExpiredTrial(currentOrg);
        const isTrial =
          currentOrg.accountType === organisationAccountType.trial &&
          currentOrg.trialExpiresOn;
        if (currentOrg.isVendorTrial || isTrial) {
          props.dispatch(retrieveOnboardingCalendarForUser(props.userData.id));
        }

        if (!isExpired) {
          props.dispatch(loadInitialActivityStream(false));
          props.dispatch(fetchUserInitiatedExports(false));

          if (
            props.userData.orgPermissions.includes(OrgAccessVendors) &&
            hasPermissionFromUserData(props.userData, UserVendorRiskWrite)
          ) {
            props.dispatch(fetchVendorImport());
          }

          if (
            hasPermissionFromUserData(props.userData, UserVendorRiskEnabled)
          ) {
            props.dispatch(fetchCustomerLimitData());
          }
        }

        // kick off free-trial eligibility check
        if (currentOrg && currentOrg.accountType == "free") {
          props.dispatch(
            fetchOrgFreeTrialEligibility(
              props.userData.id,
              props.userData.currentOrgID,
              false
            )
          );
        }
      }
    }

    // Load the exported reports so we can show their progress.
    if (!this.props.userData.currentOrgID) {
      props.dispatch(fetchAnalystInitiatedExports(false));
    }
  }

  componentDidMount() {
    // Create a listener on the history object to update the current path on route changes,
    // since the parent component isn't reactive to its changes.
    this.unlistenHistory = this.props.history.listen(({ pathname, state }) => {
      this.setState({
        location: {
          pathname,
          state,
        },
      });

      //
      // if the route is a tpvm analyst location (or not) update the tpvm related data in the redux state
      this.props.dispatch(setTPVMSessionDetails(pathname));
    });

    //
    // Every 'period', auto refresh the notifications list to keep things ticking over
    // Only do it if the user was active in the last minute though, so we don't keep the session open unnecessarily
    //

    const autoRefreshEvery = 1 * 60 * 1000;
    this.timer = setInterval(() => {
      const userLastActive = getUserLastActive();
      if (userLastActive > new Date().getTime() - autoRefreshEvery) {
        // refresh the activity stream for the current user so we can accurately display the tasks badge
        this.props.dispatch(reloadCompleteActivityStream());
      }
    }, autoRefreshEvery);
  }

  componentDidUpdate(prevProps, prevState) {
    const selectedVendorId = getVendorIdFromPath(this.state.location.pathname);
    if (selectedVendorId && selectedVendorId !== this.props.viewingVendorId) {
      this.props.dispatch(setViewingVendorId(selectedVendorId));
    }

    const { managedOrgId, managedVendorId } =
      getAnalystOrgIdAndVendorIdFromPath(this.state.location.pathname);
    if (
      (managedOrgId && managedOrgId !== this.props.viewingManagedVendorOrgId) ||
      (managedVendorId && managedVendorId !== this.props.viewingManagedVendorId)
    ) {
      this.props.dispatch(
        setViewingManagedVendorIdForOrgId(managedOrgId, managedVendorId)
      );
    }

    if (!prevProps.viewingVendorId && this.props.viewingVendorId) {
      // Scroll to the bottom of the nav container just in case not everything is visible
      this.heightAdjustableContainer?.scrollToElement("#vendor_risk_subtitle");
    }

    const currentOrg = getCurrentOrgFromUserData(this.props.userData);
    // has the org changed?
    if (
      this.props.userData.currentOrgID &&
      this.props.userData.currentOrgID !== prevProps.userData.currentOrgID &&
      currentOrg.accountType == "free"
    ) {
      // kick off free-trial eligibility check
      this.props.dispatch(
        fetchOrgFreeTrialEligibility(
          this.props.userData.id,
          this.props.userData.currentOrgID,
          false
        )
      );
    }
  }

  componentWillUnmount() {
    // stop the notifications update timer
    if (this.timer) {
      clearInterval(this.timer);
    }

    // Remove all our listeners
    this.unlistenHistory();
    document.removeEventListener("click", this.userMenuClickWatcher);
  }

  userMenuClickWatcher = () => {
    this.toggleUserMenu();
  };

  toggleUserMenu = () => {
    this.setState(({ userMenuOpen }) => {
      if (userMenuOpen) {
        document.removeEventListener("click", this.userMenuClickWatcher);
        return { userMenuOpen: false };
      }

      document.addEventListener("click", this.userMenuClickWatcher);
      return { userMenuOpen: true };
    });

    trackEvent("Toggled User Menu");
  };

  switchOrg = (orgId) => {
    this.props.dispatch(switchUserOrg(orgId, this.props.history));
  };

  clickNavLinkFunc = (pathTo, linkName, viewingVendorId) => () => {
    // then track an event
    const properties = { pathTo, linkName };
    if (viewingVendorId) {
      properties.viewingVendorId = viewingVendorId;
    }

    trackEvent("navigation menu item clicked", properties);
  };

  closeVendorMenu = (isOnVendorRoute) => {
    this.props.dispatch(setViewingVendorId(null));
    if (isOnVendorRoute) {
      this.props.history.push("/vendorlist");
    }
  };

  closeAnalystManagedVendorMenu = (isOnAnalystManagedVendorRoute) => {
    this.props.dispatch(setViewingManagedVendorIdForOrgId(null, null));
    if (isOnAnalystManagedVendorRoute) {
      this.props.history.push("/analysts/tpvm");
    }
  };

  determineOrgState = (props) => {
    const { userData, onLogoutClick } = props;
    let isTrial = false;
    let trialDaysRemaining = 0;
    let today = false;
    let currentOrgName = "";
    let currentOrgIsVerifiedVendor = false;
    let currentOrgIsInAccountGroup = false;
    let currentOrgAccountGroupEntitlements = [];
    let currentOrgIsFreeOrg = false;
    let trialMessage = "";
    let isVendorFreeTrial = false;
    let trialExpired = false;
    let trialOnboardingBooked = true;
    let isPLGFreeTrial = false;
    let isWebsiteSelfSignup = false;
    if (userData.orgList && userData.currentOrgID > 0) {
      for (let i = 0; i < userData.orgList.length; i++) {
        if (userData.orgList[i].id === userData.currentOrgID) {
          currentOrgName = userData.orgList[i].name;
          currentOrgIsVerifiedVendor = !!userData.orgList[i].isVerifiedVendor;
          currentOrgIsInAccountGroup =
            !!userData.orgList[i].organisationGroupId;
          currentOrgAccountGroupEntitlements =
            userData.orgList[i].organisationGroupEntitlements || [];
          currentOrgIsFreeOrg =
            userData.orgList[i].accountType === organisationAccountType.free;

          if (
            userData.orgList[i].accountType === organisationAccountType.trial &&
            userData.orgList[i].trialExpiresOn
          ) {
            isTrial = true;
            isVendorFreeTrial = userData.orgList[i].isVendorTrial;
            trialOnboardingBooked = userData.orgList[i].isOnboardingBooked;

            // only for users registering after our go-live date
            // change to the date of deploy when we go live

            isPLGFreeTrial =
              !!userData.orgList[i].freeTrialSource &&
              new Date(userData.orgList[i].addedAt) >
                new Date("2024-06-14T00:00:00Z");

            if (isPLGFreeTrial) {
              isWebsiteSelfSignup =
                userData.orgList[i].freeTrialSource === "website_self_signup";
            }
            const timeRemaining =
              new Date(userData.orgList[i].trialExpiresOn) - new Date();
            if (timeRemaining > 0) {
              trialDaysRemaining = Math.ceil(timeRemaining / 86400000);
              today = trialDaysRemaining === 1;
            }
          }
          break;
        }
      }
    }

    // determine trial status messages
    if (isTrial && today) {
      trialMessage = "Trial Ending Today";
    } else if (isTrial && trialDaysRemaining > 0) {
      trialMessage = `Trial Days Remaining: ${trialDaysRemaining}`;
    } else if (isTrial) {
      trialExpired = true;
      trialMessage = "Trial has expired";
    }

    return {
      isTrial,
      trialDaysRemaining,
      today,
      currentOrgName,
      currentOrgIsVerifiedVendor,
      currentOrgIsInAccountGroup,
      currentOrgAccountGroupEntitlements,
      currentOrgIsFreeOrg,
      trialMessage,
      trialExpired,
      isVendorFreeTrial,
      trialOnboardingBooked,
      isPLGFreeTrial,
      isWebsiteSelfSignup,
    };
  };

  bookOnboardingCall = () => {
    this.showOnboardingCallModal(true);
  };

  showOnboardingCallModal = (active) => {
    this.setState({ onboardingCallModalActive: active });
  };

  bookTrialUpgradeCall = () => {
    trackEvent("PLG_UpgradeCallFromNav");
    this.props.dispatch(requestSlackNotification(EVENT_BOOK_TRIAL_PRESSED));
    this.showTrialUpgradeModal(true);
  };

  showTrialUpgradeModal = (active) => {
    this.setState({ trialUpgradeCallModalActive: active });
  };

  dismissTrialAccountRevertedModal = () => {
    this.setState({ trialAccountRevertedModalActive: false });
  };

  handleContactUs = () => {
    this.setState({ vendorRiskUpgradeModalOpen: true });
    trackEvent("MonitorVendorLimit_ContactUs");
  };

  handleContactSent = () => {
    trackEvent("MonitorVendorLimit_ReachOutToMe");
  };

  render() {
    const { userData, onLogoutClick, freeTrialEligibility } = this.props;
    const { pathname } = this.state.location;

    const {
      isTrial,
      trialDaysRemaining,
      today,
      currentOrgName,
      currentOrgIsVerifiedVendor,
      currentOrgIsInAccountGroup,
      currentOrgAccountGroupEntitlements,
      currentOrgIsFreeOrg,
      trialMessage,
      trialExpired,
      isVendorFreeTrial,
      trialOnboardingBooked,
      isPLGFreeTrial,
      isWebsiteSelfSignup,
    } = this.determineOrgState(this.props);

    // CK: Text changes specific to Assurance orgs.
    const assuranceType = userData.assuranceType;

    const {
      vendorWatchLimit,
      vendorWatchLimitIsHard,
      vendorWatchCount,
      vendorImmutableCount,
      vendorLookupLimitIsHard,
      vendorLookupLimit,
      vendorLookupCount,
    } = this.props;

    const isAtVendorLimit =
      vendorWatchLimitIsHard &&
      vendorWatchLimit <=
        vendorWatchCount - (vendorImmutableCount ? vendorImmutableCount : 0);
    const isAtInstantReportLimit =
      vendorLookupLimitIsHard && vendorLookupLimit <= vendorLookupCount;

    // Create a map of permissions for easy checking. note that if the user is an analyst currently managing an org/vendor
    const orgPermissions = {};
    const managedOrgPerms = {};
    const managedOrgGroupPerms = {};
    const userPermissions = {};
    if (
      this.props.viewingManagedVendorOrgId &&
      this.props.managedVendorOrgEntitlements
    ) {
      // add org permissions for the account being managed
      for (const perm of this.props.managedVendorOrgEntitlements) {
        managedOrgPerms[perm] = true;
      }

      for (const perm of this.props.managedOrgGroupEntitlements) {
        managedOrgGroupPerms[perm] = true;
      }
    }
    if (userData.orgPermissions) {
      // add permissions from the current user's org
      for (const perm of userData.orgPermissions) {
        orgPermissions[perm] = true;
      }
    }

    if (userData.userPermissions) {
      for (const perm of userData.userPermissions) {
        userPermissions[perm] = true;
      }
    }

    const hasOrgPermission = (perm) => !!orgPermissions[perm];
    const hasManagedOrgPermission = (perm) => !!managedOrgPerms[perm];
    const hasManagedOrgGroupPermission = (perm) => !!managedOrgGroupPerms[perm];
    const hasUserPermission = (perm) => !!userPermissions[perm];

    let selectedVendorId = getVendorIdFromPath(pathname);
    let isOnVendorRoute = !!selectedVendorId;
    if (!selectedVendorId) {
      selectedVendorId = this.props.viewingVendorId;
    }
    const showVendorMenu =
      selectedVendorId &&
      this.props.canViewViewingVendor &&
      (hasOrgPermission(Permissions.OrgAccessVendors) ||
        hasOrgPermission(Permissions.OrgAccessSurveys));

    const currentOrgIsDistributor =
      userData.currentOrgRoles &&
      userData.currentOrgRoles.includes("Distributor");

    // Show cyberresearch if:
    // - Org is not a distributor
    // AND
    // - Org is not an MSSP type org
    // AND
    // - Org/user has access to any of the features
    // OR
    //  - The org has access to none of the features (show for feature preview purposes)
    //  - AND it is not a free/vendor-only user
    const showCyberResearch =
      !currentOrgIsDistributor &&
      assuranceType !== AssuranceType.MSSP &&
      ((hasOrgPermission(Permissions.OrgAccessBreachSight) &&
        hasUserPermission(Permissions.UserAccessDataLeaks)) ||
        (hasOrgPermission(Permissions.OrgAccessVendorDataLeaks) &&
          hasUserPermission(Permissions.UserAccessDataLeaks)) ||
        (hasOrgPermission(Permissions.OrgAccessVendorsUnderManagement) &&
          hasUserPermission(Permissions.UserManageVendorsUnderManagement)) ||
        (!currentOrgIsFreeOrg &&
          userData.currentOrgID !== 0 &&
          !hasOrgPermission(Permissions.OrgAccessBreachSight) &&
          !hasOrgPermission(Permissions.OrgAccessVendorDataLeaks) &&
          !hasOrgPermission(Permissions.OrgAccessVendorsUnderManagement)));

    // Only show the sub items of data leaks if on a data leaks path
    const showDataLeaksSubItems =
      matchPath(pathname, "/breachsight") || matchPath(pathname, "/dataleaks");

    const userHasVendorRisk =
      hasUserPermission(Permissions.UserVendorRiskEnabled) ||
      Object.keys(userData.vendorPortfolioSpecificPermissions).length > 0;
    const showVendorRisk =
      userHasVendorRisk &&
      (hasOrgPermission(Permissions.OrgAccessVendors) ||
        hasOrgPermission(Permissions.OrgAccessSurveys));
    const showMySharedProfile =
      userData.currentOrgID !== 0 &&
      currentOrgIsVerifiedVendor &&
      hasUserPermission(Permissions.UserAccessPrefilledSurveys);
    const showContentLibrary =
      hasUserPermission(Permissions.UserReadContentLibrary) &&
      !currentOrgIsDistributor;
    const userHasOrgCollaboration = userData.hasSurveyCollaboration;

    // determine if a vendor management analyst is active and viewing a specific vendor for a specific org
    const userIsVendorManagementAnalyst =
      !!userData.system_roles &&
      userData.system_roles.includes("VendorManagementAnalyst");
    let { managedOrgId, managedVendorId } =
      getAnalystOrgIdAndVendorIdFromPath(pathname);
    let isOnAnalystManagedVendorRoute = !!managedOrgId && !!managedVendorId;
    if (!managedVendorId) {
      managedVendorId = this.props.viewingManagedVendorId;
    }
    if (!managedOrgId) {
      managedOrgId = this.props.viewingManagedVendorOrgId;
    }
    const showManagedVendorAnalystVendorMenu =
      managedOrgId && managedVendorId && userIsVendorManagementAnalyst;

    const showAnswerQuestionnaires =
      userData.hasAssessments ||
      hasUserPermission(Permissions.UserReadSurveyImportExport);

    // only show the risk assessment link if a) the user is capable of writing one or b) there is an assessment
    // already attached to the vendor
    let tasksRoutes = null;
    if (
      showAnswerQuestionnaires ||
      userData.hasRemediation ||
      userData.hasAcceptedRisks ||
      userData.hasAdditionalEvidenceRequests ||
      userData.hasRiskAdjustments
    ) {
      tasksRoutes = (
        <>
          {showAnswerQuestionnaires && (
            <NavLink
              icon="checklist"
              text="Answer Questionnaires"
              onClick={this.clickNavLinkFunc(
                "/vendors/surveys",
                "Answer Questionnaires"
              )}
              to="/vendors/surveys"
              inAppMessageType={
                userData.currentOrgID !== 0 &&
                hasUserPermission(Permissions.UserReadSurveyImportExport)
                  ? InAppMessageType.ImportSurveysNewFeature
                  : undefined
              }
              inAppMessageText={
                userData.currentOrgID !== 0 &&
                hasUserPermission(Permissions.UserReadSurveyImportExport) ? (
                  <NewFeatureInAppMessagePopupContent
                    featureName="Import Excel questionnaires"
                    description="Been sent an Excel questionnaire? Use our AI Autofill tools to complete and export it in no time."
                    learnMoreLink="https://help.upguard.com/en/articles/9034980-how-to-import-and-complete-security-questionnaires"
                  />
                ) : undefined
              }
            />
          )}
          {userData.hasAdditionalEvidenceRequests && (
            <NavLink
              icon="document"
              text="Document Requests"
              onClick={this.clickNavLinkFunc(
                "/vendors/additionalevidence",
                "Document Requests"
              )}
              to="/vendors/additionalevidence"
            />
          )}
          {userData.hasRemediation && (
            <NavLink
              icon="spanner"
              text="Remediation Requests"
              onClick={this.clickNavLinkFunc(
                "/vendors/remediation",
                "Remediation Requests"
              )}
              to="/vendors/remediation"
            />
          )}
          {userData.hasAcceptedRisks && (
            <NavLink
              icon="risk-waivers"
              text="Risk Waiver Approvals"
              onClick={this.clickNavLinkFunc(
                "/vendors/riskwaiverapprovals",
                "Risk Waiver Approvals"
              )}
              to="/vendors/riskwaiverapprovals"
            />
          )}
          {userData.hasRiskAdjustments && (
            <NavLink
              icon="risk-waivers"
              text="Risk Adjustment Approvals"
              onClick={this.clickNavLinkFunc(
                "/vendors/riskadjustmentapprovals",
                "Risk Adjustment Approvals"
              )}
              to="/vendors/riskadjustmentapprovals"
            />
          )}
        </>
      );
    }

    const isUserAdmin = isAdmin(userData.currentOrgRoles);

    if (
      isPLGFreeTrial &&
      !trialExpired &&
      // those who have completed the flow will have at least the 1 free completed task
      !userData.plgOnboarding.completedTasks &&
      assuranceType === AssuranceType.None
    ) {
      return (
        <PLGOnboardingFlow
          firstName={userData.firstName}
          lastName={userData.lastName}
          userPermissionBreachSight={hasUserPermission(
            Permissions.UserBreachsightEnabled
          )}
          userPermissionVendorRisk={hasUserPermission(
            Permissions.UserVendorRiskEnabled
          )}
          userPermissionWriteUsers={hasUserPermission(
            Permissions.UserWriteUsers
          )}
        />
      );
    }

    if (
      !this.state.onboardingStepsDismissed &&
      assuranceType === AssuranceType.None &&
      !userData.plgOnboarding.completedTasks &&
      !isPLGFreeTrial &&
      shouldShowOnboardingScreen(userData.onboardingSteps || {})
    ) {
      return (
        <OnboardingSteps
          dispatch={this.props.dispatch}
          history={this.props.history}
          dismissOnboardingSteps={() => {
            this.setState({ onboardingStepsDismissed: true });
            // refresh user data only after the steps have been dismissed or they will update while open
            this.props.dispatch(fetchUserData());
          }}
          onboardingSteps={userData.onboardingSteps}
        />
      );
    }

    const vendorWords = getVendorWords(assuranceType);

    const showVendorPortalSharedWithMeMenuItem =
      (userData.currentOrgID === 0 || currentOrgIsFreeOrg) &&
      userData.hasSharedAssessmentsAccess;
    const showAppSharedWithMeMenuItem =
      assuranceType === AssuranceType.None &&
      userData.currentOrgID > 0 &&
      !currentOrgIsFreeOrg &&
      !currentOrgIsDistributor;
    const showSharedWithMeMenuItem =
      showVendorPortalSharedWithMeMenuItem || showAppSharedWithMeMenuItem;

    const userHasUserBaseRead = hasUserPermission(UserUserBaseEnabled);
    const userHasUserBaseWrite = hasUserPermission(UserUserBaseWrite);

    const showUserBaseSection =
      hasOrgPermission(Permissions.OrgAccessUserBase) &&
      (userHasUserBaseRead || userHasUserBaseWrite);

    const trustExchangeSection =
      showMySharedProfile || showContentLibrary ? (
        <>
          <div className="subtitle">Trust Exchange</div>
          {showContentLibrary && (
            <NavLink
              icon="file-search"
              text="Content Library"
              onClick={this.clickNavLinkFunc(
                "/contentlibrary",
                "Content Library"
              )}
              to="/contentlibrary"
              inAppMessageText={
                <NewFeatureInAppMessagePopupContent
                  featureName="Content Library"
                  description="Easily manage documents in one central location and build a repository of content to power AI autofill."
                  learnMoreLink={
                    "https://help.upguard.com/en/articles/8973526-what-is-the-content-library"
                  }
                />
              }
              inAppMessageType={InAppMessageType.ContentLibraryNewFeature}
            />
          )}
          {showMySharedProfile && (
            <NavLink
              // This nav item is never disabled as it shold be available to expired trials too
              // disabled={trialExpired}
              inAppMessageText={
                <NewFeatureInAppMessagePopupContent
                  featureName="Security and Privacy Pages"
                  description="You can now update security and privacy pages on your Trust Page."
                  learnMoreLink={
                    "https://help.upguard.com/en/articles/7051467-how-to-use-trust-and-security-pages-in-upguard#h_7c677b63e6"
                  }
                />
              }
              inAppMessageType={InAppMessageType.VendorEvidenceNewFeature}
              icon="shared-assessment-no-pad"
              text="Trust Page"
              hoverText={pageDescriptions.MySharedAssessment}
              onClick={this.clickNavLinkFunc("/trustpage", "Trust Page")}
              to="/trustpage"
            />
          )}
        </>
      ) : undefined;

    return (
      <Router history={this.props.history}>
        <div
          id="nav-wrapper"
          className={classnames({ "nav-open": this.state.open })}
        >
          <Switch>
            {/* Routes with custom (or no) nav */}
            <Route path="/surveys/:id/answers" />
            <Route path="/vendors/surveys/:id/answers" />
            <Route path="/vendor/:vendorId/surveys/:id/answers" />
            <Route path="/trustpage/surveys/:id/answers" />
            <Route path="/sharedassessments/:vendorId/surveys/:id/answers" />
            <Route path="/vendor/:vendorId/sharedassessment/surveys/:id/answers" />
            <Route path="/vendor/:vendorId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers" />
            <Route path="/vendor/:vendorId/sharedassets/:sharedAssetOrgId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers" />
            <Route path="/vendor/:vendorId/sharedassets/vendor/surveys/:id/answers" />
            <Route path="/vendor/:vendorId/sharedassets/:sharedAssetOrgId/surveys/:id/answers" />
            <Route path="/analysts/tpvm/:orgId/:vendorId/surveys/:id/answers" />
            <Route path="/analysts/tpvm/:orgId/:vendorId/sharedassessment/surveys/:id/answers" />
            <Route path="/analysts/tpvm/:orgId/:vendorId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers" />
            <Route path="/analysts/tpvm/:orgId/:vendorId/questionnairepreview" />
            <Route path="/analysts/tpvm/:orgId/:vendorId/:id/presendreview" />
            <Route path="/questionnairepreview" />
            <Route path="/quickstart" />
            <Route path={"/surveycollaboration/:id/answers"} />
            {/* All other routes show default nav */}
            <Route path="*">
              <div id="side-navigation">
                <div className="nav-content">
                  <HeightAdjustableContainer
                    adjustFooter
                    ref={(ref) => (this.heightAdjustableContainer = ref)}
                  >
                    <div className="height-adjustable-top">
                      <>
                        <img
                          src={UpGuardLogo}
                          alt="UpGuard Logo"
                          className="ug-logo"
                        />
                        {isPLGFreeTrial || isVendorFreeTrial
                          ? this.props.freeTrialOnboardingCalendar && (
                              <FreeTrialUpsellNavContent
                                daysRemaining={
                                  trialDaysRemaining > 0
                                    ? trialDaysRemaining
                                    : 0
                                }
                                messageText={
                                  "Book a free, bespoke walkthrough of UpGuard tailored to your needs."
                                }
                                buttonText={"Book onboarding call"}
                                showButton={true}
                                buttonAction={this.bookTrialUpgradeCall}
                              />
                            )
                          : isTrial && (
                              // Sales-led free trials are treated differently to PLG trials.
                              <div className="trial-warning">
                                <span>{trialMessage}</span>
                              </div>
                            )}
                        {isTrial && <div className={"trial-space"} />}

                        {userData.currentOrgID !== 0 &&
                          !currentOrgIsFreeOrg && (
                            <>
                              <NavLink
                                icon="home"
                                badge={
                                  this.props.activityStreamTaskCount > 0
                                    ? `${this.props.activityStreamTaskCount}`
                                    : ""
                                }
                                text={
                                  <>
                                    <span>Home</span>{" "}
                                    {false &&
                                      this.props.activityStreamTaskCount >
                                        0 && (
                                        <PillLabel color="lightblue" filled>
                                          {this.props.activityStreamTaskCount}
                                        </PillLabel>
                                      )}
                                  </>
                                }
                                hoverText={pageDescriptions.ActivityStream}
                                exact
                                onClick={this.clickNavLinkFunc("/", "Home")}
                                to="/"
                                isActive={(match, location) =>
                                  matchPath(location.pathname, "/home") ||
                                  location.pathname === "/"
                                }
                              />
                            </>
                          )}

                        {!currentOrgIsDistributor &&
                          ((userData.currentOrgID !== 0 &&
                            !currentOrgIsFreeOrg) ||
                            userData.isAnalyst ||
                            userIsVendorManagementAnalyst) && (
                            <NavLink
                              disabled={trialExpired}
                              icon="reporting"
                              className="report-exports-nav-item"
                              text={(() => {
                                let numUnread = 0;
                                let numPending = 0;

                                if (this.props.exportItems) {
                                  for (
                                    let i = 0;
                                    i < this.props.exportItems.length;
                                    i++
                                  ) {
                                    if (
                                      this.props.exportItems[i].status ===
                                      userReportExportStatuses.Pending
                                    ) {
                                      numPending += 1;
                                    } else if (
                                      this.props.exportItems[i].status ===
                                        userReportExportStatuses.Complete &&
                                      !this.props.exportItems[i].read
                                    ) {
                                      numUnread += 1;
                                    }
                                  }
                                }

                                return (
                                  <ReportExportsNavItem
                                    history={this.props.history}
                                    numUnread={numUnread}
                                    numPending={numPending}
                                  />
                                );
                              })()}
                              hoverText={pageDescriptions.ReportExports}
                              onClick={this.clickNavLinkFunc(
                                "/reportexports",
                                "Reports"
                              )}
                              to={"/reportexports"}
                            />
                          )}
                        {userData.currentOrgID !== 0 &&
                          !currentOrgIsFreeOrg &&
                          !currentOrgIsDistributor && (
                            <NavLink
                              icon="cyberrisk"
                              disabled={trialExpired}
                              text={"Incidents & News"}
                              hoverText={pageDescriptions.BreachNewsFeed}
                              onClick={this.clickNavLinkFunc(
                                "/breachnewsfeed",
                                "Incidents & News"
                              )}
                              to="/breachnewsfeed"
                            />
                          )}
                        {userData.currentOrgID === 0 ? (
                          <>{tasksRoutes}</>
                        ) : currentOrgIsFreeOrg ? (
                          <>
                            {tasksRoutes && (
                              <>
                                <div className="subtitle">Tasks</div>
                                {tasksRoutes}
                              </>
                            )}

                            {trustExchangeSection}
                          </>
                        ) : undefined}

                        <BreachSightNavItems
                          trialExpired={trialExpired}
                          clickNavLinkFunc={this.clickNavLinkFunc}
                        />

                        {showCyberResearch && (
                          <>
                            <NavLink
                              disabled={trialExpired}
                              icon="cyberrisk"
                              text="Data Leaks"
                              hoverText={pageDescriptions.DataLeaks}
                              onClick={this.clickNavLinkFunc(
                                "/breachsight",
                                "Data Leaks"
                              )}
                              to="/breachsight"
                              featurePreviewAvailable
                              hasOrgPermission={hasOrgPermission(
                                Permissions.OrgAccessBreachSight
                              )}
                              hasUserPermission={hasUserPermission(
                                Permissions.UserAccessDataLeaks
                              )}
                              isActive={(match, location) =>
                                matchPath(location.pathname, {
                                  path: "/breachsight",
                                  exact: true,
                                })
                              }
                            />
                            {showDataLeaksSubItems && (
                              <NavLink
                                disabled={trialExpired}
                                icon="cyberrisk"
                                text="Disclosures"
                                isSubMenuItem
                                hoverText={pageDescriptions.DataLeaksStatistics}
                                onClick={this.clickNavLinkFunc(
                                  "/dataleaks/disclosures",
                                  "Disclosures"
                                )}
                                to="/dataleaks/disclosures"
                                hasOrgPermission={hasOrgPermission(
                                  Permissions.OrgAccessBreachSight
                                )}
                                hasUserPermission={hasUserPermission(
                                  Permissions.UserAccessDataLeaks
                                )}
                                isActive={(match, location) => {
                                  // Temporary fix to highlight Disclosures nav
                                  // when on either findings list, or a finding.
                                  // Remove this once we properly fix up the routes for Data Leaks
                                  const result = matchPath(
                                    location.pathname,
                                    "/breachsight/:findingId"
                                  );

                                  if (result) {
                                    return result;
                                  }

                                  return matchPath(
                                    location.pathname,
                                    "/dataleaks/disclosures"
                                  );
                                }}
                              />
                            )}
                            {showDataLeaksSubItems && (
                              <NavLink
                                disabled={trialExpired}
                                icon="cyberrisk"
                                text={"Detection Reporting"}
                                isSubMenuItem
                                hoverText={pageDescriptions.DataLeaksStatistics}
                                onClick={this.clickNavLinkFunc(
                                  "/dataleaks/reporting",
                                  "Detection Reporting"
                                )}
                                to="/dataleaks/reporting"
                                hasOrgPermission={hasOrgPermission(
                                  Permissions.OrgAccessBreachSight
                                )}
                                hasUserPermission={hasUserPermission(
                                  Permissions.UserAccessDataLeaks
                                )}
                              />
                            )}
                          </>
                        )}
                        {hasOrgPermission(OrgAccessAppGuard) && (
                          <AppGuardBreachsightCloudNavItems
                            pathname={pathname}
                            navLinkClickFunc={this.clickNavLinkFunc}
                          />
                        )}

                        {(showVendorRisk ||
                          showSharedWithMeMenuItem ||
                          userHasOrgCollaboration ||
                          currentOrgIsFreeOrg) && (
                          <>
                            <div className="subtitle" id="vendor_risk_subtitle">
                              {vendorWords.vendorRiskModuleName}
                            </div>
                            {currentOrgIsFreeOrg && (
                              <NavLink
                                icon="paragraph"
                                text={vendorWords.vendorsPageTitle}
                                hoverText={pageDescriptions.Portfolio}
                                onClick={this.clickNavLinkFunc(
                                  "/vendorlist",
                                  vendorWords.vendorsPageTitle
                                )}
                                to="/vendorlist"
                              />
                            )}

                            {!showVendorRisk && userHasOrgCollaboration && (
                              <NavLink
                                disabled={trialExpired}
                                icon="checklist"
                                text="Questionnaires"
                                hoverText={pageDescriptions.Questionnaires}
                                onClick={this.clickNavLinkFunc(
                                  "/surveycollaboration",
                                  "Questionnaires"
                                )}
                                to="/surveycollaboration"
                              />
                            )}

                            {showVendorRisk && (
                              <>
                                <NavLink
                                  disabled={trialExpired}
                                  icon="analytics"
                                  text={
                                    vendorWords.vendorRiskExecutiveSummaryPageTitle
                                  }
                                  hoverText={
                                    pageDescriptions.ExecutiveSummaryReport
                                  }
                                  onClick={this.clickNavLinkFunc(
                                    "/summaryreport/vendorrisk",
                                    vendorWords.vendorRiskExecutiveSummaryPageTitle
                                  )}
                                  to="/summaryreport/vendorrisk"
                                />
                                <NavLink
                                  disabled={
                                    trialExpired ||
                                    hasOrgPermission(
                                      Permissions.OrgHideVendorRisks
                                    )
                                  }
                                  icon="focus"
                                  text={
                                    vendorWords.portfolioRiskProfilePageTitle
                                  }
                                  hoverText={
                                    pageDescriptions.VendorPortfolioRiskProfile
                                  }
                                  exact
                                  onClick={this.clickNavLinkFunc(
                                    "/vendor_portfolio",
                                    vendorWords.portfolioRiskProfilePageTitle
                                  )}
                                  to="/vendor_portfolio"
                                />
                                {hasOrgPermission(
                                  Permissions.OrgAccessVendors
                                ) && (
                                  <NavLink
                                    disabled={trialExpired}
                                    icon="paragraph"
                                    text={
                                      <>
                                        {vendorWords.vendorsPageTitle}
                                        {this.props.activeVendorImport &&
                                          this.props.activeVendorImport
                                            .status === 1 && (
                                            <>
                                              {" "}
                                              <PillLabel
                                                color={LabelColor.LightBlue}
                                                filled
                                              >
                                                Importing
                                              </PillLabel>
                                            </>
                                          )}
                                      </>
                                    }
                                    hoverText={pageDescriptions.Portfolio}
                                    inAppAlertText={
                                      isAtVendorLimit ? (
                                        <>
                                          You&apos;ve reached your{" "}
                                          {vendorWords.singular} limit.
                                          <br />
                                          <a onClick={this.handleContactUs}>
                                            Contact us
                                          </a>
                                          &nbsp;to customize your plan.
                                        </>
                                      ) : undefined
                                    }
                                    onClick={this.clickNavLinkFunc(
                                      "/vendorlist",
                                      vendorWords.vendorsPageTitle
                                    )}
                                    to="/vendorlist"
                                  />
                                )}
                                {(this.props.hasActiveVendorComparison ||
                                  pathname === "/vendorcomparison") && (
                                  <NavLink
                                    disabled={trialExpired}
                                    text="Comparison"
                                    hoverText={
                                      pageDescriptions.VendorComparison
                                    }
                                    isSubMenuItem
                                    onClick={this.clickNavLinkFunc(
                                      "/vendorcomparison",
                                      "Comparison"
                                    )}
                                    to="/vendorcomparison"
                                  />
                                )}
                                {showVendorMenu && (
                                  <>
                                    <div
                                      className={classnames("nav-sub-section", {
                                        active: isOnVendorRoute,
                                      })}
                                    >
                                      <div
                                        className={classnames(
                                          "subtitle header-section-name",
                                          { active: isOnVendorRoute }
                                        )}
                                        onClick={() =>
                                          this.closeVendorMenu(isOnVendorRoute)
                                        }
                                      >
                                        <WithVendorData
                                          vendorId={selectedVendorId}
                                          render={({ vendorName }) => (
                                            <div className="subtitle vendor-name">
                                              {getSubtitleDisplay(vendorName)}
                                            </div>
                                          )}
                                        />
                                        <Icon name="x" />
                                      </div>
                                      <NavLink
                                        disabled={trialExpired}
                                        icon="vendor-summary"
                                        text={`${vendorWords.singularTitleCase} Summary`}
                                        hoverText={
                                          pageDescriptions.VendorSummary
                                        }
                                        onClick={this.clickNavLinkFunc(
                                          `/vendor/${selectedVendorId}/summary`,
                                          `${vendorWords.singularTitleCase} Summary`,
                                          selectedVendorId
                                        )}
                                        to={`/vendor/${selectedVendorId}/summary`}
                                      />
                                      <NavLink
                                        disabled={
                                          trialExpired ||
                                          hasOrgPermission(
                                            Permissions.OrgHideVendorRisks
                                          )
                                        }
                                        icon="focus"
                                        text="Risk Profile"
                                        hoverText={
                                          pageDescriptions.VendorRiskProfile
                                        }
                                        onClick={this.clickNavLinkFunc(
                                          `/vendor/${selectedVendorId}/risk_profile`,
                                          "Portfolio Risk Profile",
                                          selectedVendorId
                                        )}
                                        to={`/vendor/${selectedVendorId}/risk_profile`}
                                      />
                                      {assuranceType === AssuranceType.None && (
                                        <>
                                          {/* Risk waivers appear later in the navigation for assurance orgs */}
                                          <NavLink
                                            disabled={trialExpired}
                                            icon="risk-waivers"
                                            text={"Modified Risks"}
                                            hoverText={
                                              pageDescriptions.RiskWaivers
                                            }
                                            onClick={this.clickNavLinkFunc(
                                              `/vendor/${selectedVendorId}/riskwaivers`,
                                              "Modified Risks",
                                              selectedVendorId
                                            )}
                                            to={`/vendor/${selectedVendorId}/riskwaivers`}
                                            inAppMessageType={
                                              InAppMessageType.VendorRiskAdjustmentsNewFeature
                                            }
                                            inAppMessageText={
                                              <NewFeatureInAppMessagePopupContent
                                                featureName="Severity Adjustments"
                                                description="You can now adjust the severity of risks and manage risk waivers here."
                                                learnMoreLink={
                                                  "https://help.upguard.com/en/articles/8668528-how-to-adjust-the-severity-of-a-risk"
                                                }
                                              />
                                            }
                                          />
                                          <NavLink
                                            disabled={trialExpired}
                                            icon="spanner"
                                            text="Remediation"
                                            hoverText={
                                              pageDescriptions.VendorRemediation
                                            }
                                            onClick={this.clickNavLinkFunc(
                                              `/vendor/${selectedVendorId}/remediation`,
                                              "Vendor Remediation",
                                              selectedVendorId
                                            )}
                                            to={`/vendor/${selectedVendorId}/remediation`}
                                          />
                                        </>
                                      )}
                                      <WithVendorData
                                        vendorId={selectedVendorId}
                                        render={({
                                          riskAssessmentPublished,
                                          sharedRiskAssessmentAvailable,
                                          userPermsForVendor,
                                        }) =>
                                          hasOrgPermission(
                                            Permissions.OrgAccessVendorAssessments
                                          ) &&
                                          ((userPermsForVendor &&
                                            !!userPermsForVendor[
                                              UserWriteVendorAssessments
                                            ]) ||
                                            riskAssessmentPublished ||
                                            sharedRiskAssessmentAvailable) ? (
                                            <NavLink
                                              disabled={trialExpired}
                                              icon="risk-assessment"
                                              text="Risk Assessments"
                                              hoverText={
                                                pageDescriptions.VendorAssessment
                                              }
                                              onClick={this.clickNavLinkFunc(
                                                `/vendor/${selectedVendorId}/assessment`,
                                                "Vendor Risk Assessment",
                                                selectedVendorId
                                              )}
                                              to={`/vendor/${selectedVendorId}/assessment`}
                                            />
                                          ) : null
                                        }
                                      />
                                      <WithVendorData
                                        vendorId={selectedVendorId}
                                        render={({
                                          primaryHostname,
                                          hasWebPresence,
                                        }) => (
                                          <>
                                            {(primaryHostname ||
                                              hasWebPresence) && (
                                              <>
                                                <NavLink
                                                  disabled={
                                                    trialExpired ||
                                                    hasOrgPermission(
                                                      Permissions.OrgHideVendorRisks
                                                    )
                                                  }
                                                  icon="globe"
                                                  text="Domains"
                                                  hoverText={
                                                    pageDescriptions.VendorWebsitesAndAPIs
                                                  }
                                                  exact
                                                  onClick={this.clickNavLinkFunc(
                                                    `/vendor/${selectedVendorId}/webscans`,
                                                    "Domains",
                                                    selectedVendorId
                                                  )}
                                                  to={`/vendor/${selectedVendorId}/webscans`}
                                                />
                                                <NavLink
                                                  disabled={
                                                    trialExpired ||
                                                    hasOrgPermission(
                                                      Permissions.OrgHideVendorRisks
                                                    )
                                                  }
                                                  icon="globe"
                                                  text="IP Addresses"
                                                  hoverText={
                                                    pageDescriptions.VendorIPAddresses
                                                  }
                                                  exact
                                                  onClick={this.clickNavLinkFunc(
                                                    `/vendor/${selectedVendorId}/ips`,
                                                    "IP Addresses",
                                                    selectedVendorId
                                                  )}
                                                  to={`/vendor/${selectedVendorId}/ips`}
                                                />
                                              </>
                                            )}
                                          </>
                                        )}
                                      />
                                      {hasOrgPermission(
                                        Permissions.OrgAccessSurveys
                                      ) && (
                                        <NavLink
                                          disabled={trialExpired}
                                          icon="checklist"
                                          text="Questionnaires"
                                          hoverText={
                                            pageDescriptions.VendorQuestionnaires
                                          }
                                          onClick={this.clickNavLinkFunc(
                                            `/vendor/${selectedVendorId}/surveys`,
                                            "Vendor Questionnaires",
                                            selectedVendorId
                                          )}
                                          to={`/vendor/${selectedVendorId}/surveys`}
                                        />
                                      )}
                                      {hasOrgPermission(
                                        Permissions.OrgAccessAdditionalEvidence
                                      ) && (
                                        <NavLink
                                          disabled={trialExpired}
                                          icon="document"
                                          text="Additional Evidence"
                                          hoverText={
                                            pageDescriptions.AdditionalEvidence
                                          }
                                          onClick={this.clickNavLinkFunc(
                                            "/vendor/${selectedVendorId}/evidence",
                                            "Additional Evidence"
                                          )}
                                          to={`/vendor/${selectedVendorId}/evidence`}
                                        />
                                      )}
                                      {hasOrgPermission(
                                        Permissions.OrgAccessVulns
                                      ) && (
                                        <WithVendorData
                                          vendorId={selectedVendorId}
                                          render={({
                                            primaryHostname,
                                            hasWebPresence,
                                          }) => (
                                            <>
                                              {(primaryHostname ||
                                                hasWebPresence) && (
                                                <>
                                                  <NavLink
                                                    disabled={
                                                      trialExpired ||
                                                      hasOrgPermission(
                                                        Permissions.OrgHideVendorRisks
                                                      )
                                                    }
                                                    icon="bug"
                                                    text="Vulnerabilities"
                                                    hoverText={
                                                      pageDescriptions.VendorVulns
                                                    }
                                                    exact
                                                    onClick={this.clickNavLinkFunc(
                                                      `/vendor/${selectedVendorId}/vulns`,
                                                      "Vendor Vulnerabilities"
                                                    )}
                                                    to={`/vendor/${selectedVendorId}/vulns`}
                                                  />
                                                </>
                                              )}
                                            </>
                                          )}
                                        />
                                      )}
                                      {assuranceType === AssuranceType.None &&
                                        hasOrgPermission(
                                          Permissions.OrgAccessVendorTechnologies
                                        ) && (
                                          // Fourth parties appears later in the sort order for assurance orgs
                                          <WithVendorData
                                            vendorId={selectedVendorId}
                                            render={({
                                              primaryHostname,
                                              hasWebPresence,
                                            }) => (
                                              <>
                                                {(primaryHostname ||
                                                  hasWebPresence) && (
                                                  <>
                                                    <NavLink
                                                      disabled={trialExpired}
                                                      icon="hyperlink"
                                                      text="Fourth Parties"
                                                      hoverText={
                                                        pageDescriptions.SupplyChain
                                                      }
                                                      onClick={this.clickNavLinkFunc(
                                                        `/vendor/${selectedVendorId}/fourthParty`,
                                                        "Vendor Fourth Parties",
                                                        selectedVendorId
                                                      )}
                                                      to={`/vendor/${selectedVendorId}/fourthParty`}
                                                    />
                                                  </>
                                                )}
                                              </>
                                            )}
                                          />
                                        )}
                                      {currentOrgIsInAccountGroup &&
                                      currentOrgAccountGroupEntitlements.includes(
                                        GroupAccessVendorAssetSharing
                                      ) ? (
                                        <NavLink
                                          disabled={trialExpired}
                                          icon="shared-assessment-no-pad"
                                          text="Shared Assets"
                                          hoverText={
                                            pageDescriptions.VendorSharedAssets
                                          }
                                          onClick={this.clickNavLinkFunc(
                                            `/vendor/${selectedVendorId}/sharedassets`,
                                            "Vendor Shared Assets",
                                            selectedVendorId
                                          )}
                                          to={`/vendor/${selectedVendorId}/sharedassets`}
                                        />
                                      ) : (
                                        <WithVendorData
                                          vendorId={selectedVendorId}
                                          render={({
                                            sharedAssessmentPublished,
                                          }) =>
                                            sharedAssessmentPublished ? (
                                              <NavLink
                                                disabled={trialExpired}
                                                icon="shared-assessment-no-pad"
                                                text="Trust Page"
                                                hoverText={
                                                  pageDescriptions.SharedAssessment
                                                }
                                                onClick={this.clickNavLinkFunc(
                                                  `/vendor/${selectedVendorId}/sharedassessment`,
                                                  "Trust Page",
                                                  selectedVendorId
                                                )}
                                                to={`/vendor/${selectedVendorId}/sharedassessment`}
                                              />
                                            ) : null
                                          }
                                        />
                                      )}
                                      {assuranceType !== AssuranceType.None &&
                                        hasOrgPermission(
                                          Permissions.OrgAccessVendorTechnologies
                                        ) && (
                                          // Fourth parties appears earlier in the sort order for non-assurance orgs
                                          <WithVendorData
                                            vendorId={selectedVendorId}
                                            render={({
                                              primaryHostname,
                                              hasWebPresence,
                                            }) => (
                                              <>
                                                {(primaryHostname ||
                                                  hasWebPresence) && (
                                                  <>
                                                    <NavLink
                                                      disabled={trialExpired}
                                                      icon="hyperlink"
                                                      text="Fourth Parties"
                                                      hoverText={
                                                        pageDescriptions.SupplyChain
                                                      }
                                                      onClick={this.clickNavLinkFunc(
                                                        `/vendor/${selectedVendorId}/fourthParty`,
                                                        "Vendor Fourth Parties",
                                                        selectedVendorId
                                                      )}
                                                      to={`/vendor/${selectedVendorId}/fourthParty`}
                                                    />
                                                  </>
                                                )}
                                              </>
                                            )}
                                          />
                                        )}
                                      {assuranceType !== AssuranceType.None && (
                                        <NavLink
                                          disabled={trialExpired}
                                          icon="risk-waivers"
                                          text={"Modified Risks"}
                                          hoverText={
                                            pageDescriptions.RiskWaivers
                                          }
                                          onClick={this.clickNavLinkFunc(
                                            `/vendor/${selectedVendorId}/riskwaivers`,
                                            "Modified Risks",
                                            selectedVendorId
                                          )}
                                          to={`/vendor/${selectedVendorId}/riskwaivers`}
                                        />
                                      )}
                                      {assuranceType !== AssuranceType.MSSP && (
                                        <>
                                          {/* Hide documents and contacts for MSSPs only */}
                                          <NavLink
                                            disabled={trialExpired}
                                            icon="document"
                                            text="Documents"
                                            hoverText={
                                              pageDescriptions.VendorDocuments
                                            }
                                            exact
                                            onClick={this.clickNavLinkFunc(
                                              `/vendor/${selectedVendorId}/documents`,
                                              "Vendor Documents",
                                              selectedVendorId
                                            )}
                                            to={`/vendor/${selectedVendorId}/documents`}
                                          />
                                          <NavLink
                                            disabled={trialExpired}
                                            icon="people"
                                            text="Contacts"
                                            hoverText={
                                              pageDescriptions.VendorContacts
                                            }
                                            exact
                                            onClick={this.clickNavLinkFunc(
                                              `/vendor/${selectedVendorId}/contacts`,
                                              "Vendor Contacts",
                                              selectedVendorId
                                            )}
                                            to={`/vendor/${selectedVendorId}/contacts`}
                                          />
                                        </>
                                      )}
                                      {assuranceType === AssuranceType.MSSP && (
                                        <>
                                          {/* Show Remediation at the bottom for MSSP */}
                                          <NavLink
                                            disabled={trialExpired}
                                            icon="spanner"
                                            text="Remediation"
                                            hoverText={
                                              pageDescriptions.VendorRemediation
                                            }
                                            onClick={this.clickNavLinkFunc(
                                              `/vendor/${selectedVendorId}/remediation`,
                                              "Vendor Remediation",
                                              selectedVendorId
                                            )}
                                            to={`/vendor/${selectedVendorId}/remediation`}
                                          />
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                                {assuranceType !== AssuranceType.Assurance && (
                                  <NavLink
                                    disabled={trialExpired}
                                    icon="spanner"
                                    text="Remediation"
                                    hoverText={pageDescriptions.Remediation}
                                    onClick={this.clickNavLinkFunc(
                                      "/remediation",
                                      "Remediation"
                                    )}
                                    to="/remediation"
                                  />
                                )}
                                {hasOrgPermission(
                                  Permissions.OrgAccessSurveys
                                ) && (
                                  <>
                                    <NavLink
                                      disabled={trialExpired}
                                      icon="checklist"
                                      text="Questionnaires"
                                      hoverText={
                                        pageDescriptions.Questionnaires
                                      }
                                      onClick={this.clickNavLinkFunc(
                                        "/surveys",
                                        "Questionnaires"
                                      )}
                                      to="/surveys"
                                    />
                                    {!!(
                                      matchPath(pathname, "/surveys") ||
                                      matchPath(pathname, "/surveybuilder")
                                    ) && (
                                      <NavLink
                                        disabled={trialExpired}
                                        text="Questionnaire Library"
                                        hoverText={
                                          pageDescriptions.QuestionnaireLibrary
                                        }
                                        isSubMenuItem
                                        onClick={this.clickNavLinkFunc(
                                          "/surveybuilder/library",
                                          "Questionnaire Library"
                                        )}
                                        to="/surveybuilder/library"
                                      />
                                    )}
                                  </>
                                )}
                                {hasOrgPermission(
                                  Permissions.OrgAccessVendorTechnologies
                                ) && (
                                  <NavLink
                                    disabled={trialExpired}
                                    icon="concentration-risk"
                                    text="Fourth Parties"
                                    hoverText={
                                      pageDescriptions.ConcentrationRisk
                                    }
                                    exact
                                    onClick={this.clickNavLinkFunc(
                                      "/concentrationrisk",
                                      "Fourth Parties"
                                    )}
                                    to="/concentrationrisk"
                                  />
                                )}
                                {showCyberResearch && (
                                  <>
                                    {hasOrgPermission(
                                      Permissions.OrgAccessVendorDataLeaks
                                    ) && (
                                      <NavLink
                                        disabled={trialExpired}
                                        icon="cyberrisk"
                                        text={`${vendorWords.singularTitleCase} Data Leaks`}
                                        hoverText={
                                          pageDescriptions.VendorDataLeaks
                                        }
                                        onClick={this.clickNavLinkFunc(
                                          "/vendordataleaks",
                                          `${vendorWords.singularTitleCase} Data Leaks`
                                        )}
                                        to="/vendordataleaks"
                                        featurePreviewAvailable
                                        hasOrgPermission={hasOrgPermission(
                                          Permissions.OrgAccessVendorDataLeaks
                                        )}
                                        hasUserPermission={hasUserPermission(
                                          Permissions.UserAccessDataLeaks
                                        )}
                                      />
                                    )}
                                    <NavLink
                                      disabled={trialExpired}
                                      icon="managed-assessment"
                                      text={`Managed Assessments`}
                                      hoverText={
                                        pageDescriptions.CyberResearchManagedVendors
                                      }
                                      onClick={this.clickNavLinkFunc(
                                        "/managedvendors",
                                        `Managed Assessments`
                                      )}
                                      to="/managedvendors"
                                      featurePreviewAvailable
                                      hasOrgPermission={hasOrgPermission(
                                        Permissions.OrgAccessVendorsUnderManagement
                                      )}
                                      hasUserPermission={hasUserPermission(
                                        Permissions.UserManageVendorsUnderManagement
                                      )}
                                      isActive={(match, location) =>
                                        matchPath(location.pathname, {
                                          path: "/managedvendors",
                                          exact: true,
                                        }) ||
                                        matchPath(location.pathname, {
                                          path: "/vendor/:vendorId/managedservice/:managedServiceId",
                                          exact: true,
                                        })
                                      }
                                      inAppMessageType={
                                        InAppMessageType.ManagedAssessmentNewFeature
                                      }
                                      inAppMessageText={
                                        <NewFeatureInAppMessagePopupContent
                                          featureName="Managed Assessments"
                                          description="Partner with an UpGuard analyst and put your vendor assessments on autopilot. "
                                          learnMoreLink="https://help.upguard.com/en/articles/4610228-how-to-add-a-managed-vendor"
                                        />
                                      }
                                    />
                                  </>
                                )}
                              </>
                            )}
                            {showSharedWithMeMenuItem && (
                              <NavLink
                                icon="shared-assessment-no-pad"
                                text="Shared With Me"
                                hoverText={
                                  pageDescriptions.SharedAssessmentsList
                                }
                                onClick={this.clickNavLinkFunc(
                                  "/sharedassessments",
                                  "Shared With Me"
                                )}
                                to="/sharedassessments"
                              />
                            )}
                          </>
                        )}
                      </>
                    </div>
                    {hasOrgPermission(OrgAccessAppGuard) && (
                      <AppGuardNavItems
                        pathname={pathname}
                        navLinkClickFunc={this.clickNavLinkFunc}
                      />
                    )}

                    {showUserBaseSection && (
                      <UserBaseNavItems
                        clickNavLinkFunc={this.clickNavLinkFunc}
                      />
                    )}

                    {((userData.currentOrgID > 0 &&
                      (!!tasksRoutes ||
                        !!trustExchangeSection ||
                        isUserAdmin)) ||
                      (hasUserPermission(UserManageAuditLog) &&
                        hasOrgPermission(OrgAccessAuditLog)) ||
                      userData.isSuperAdmin ||
                      currentOrgIsFreeOrg ||
                      this.props.canAccessSystemAdmin) && (
                      <div className="height-adjustable-bottom">
                        <hr />
                        {(userData.isAnalyst ||
                          userIsVendorManagementAnalyst) && (
                          <div className="subtitle" id="vendor_risk_subtitle">
                            Analyst Portal
                          </div>
                        )}

                        {userIsVendorManagementAnalyst && (
                          <NavLink
                            icon="breachsight"
                            text="Vendor Assessments"
                            onClick={this.clickNavLinkFunc(
                              "/analysts/tpvm",
                              "VendorManagement Analyst"
                            )}
                            to="/analysts/tpvm"
                            isActive={(match, location) =>
                              matchPath(location.pathname, {
                                path: "/analysts/tpvm",
                                exact: true,
                              }) ||
                              matchPath(location.pathname, {
                                path: "/analysts/tpvm/:managedOrgId/:managedVendorId/requestV2/:requestId",
                                exact: true,
                              }) ||
                              matchPath(location.pathname, {
                                path: "/analysts/tpvm/:managedOrgId/:managedVendorId/requestV3/:requestId",
                                exact: true,
                              })
                            }
                          />
                        )}
                        {!!showManagedVendorAnalystVendorMenu && (
                          <div
                            className={classnames("nav-sub-section", {
                              active: isOnAnalystManagedVendorRoute,
                            })}
                          >
                            <div
                              className={classnames(
                                "subtitle header-section-name",
                                {
                                  active: isOnAnalystManagedVendorRoute,
                                }
                              )}
                              onClick={() =>
                                this.closeAnalystManagedVendorMenu(
                                  isOnAnalystManagedVendorRoute
                                )
                              }
                            >
                              <WithVendorData
                                vendorId={managedVendorId}
                                managedOrgId={managedOrgId}
                                isManagedVendor
                                render={({ vendorName }) => (
                                  <div className="subtitle vendor-name">
                                    {getSubtitleDisplay(vendorName)}
                                  </div>
                                )}
                              />
                              <Icon name="x" />
                            </div>
                            <NavLink
                              icon="vendor-summary"
                              text={`${vendorWords.singularTitleCase} Summary`}
                              hoverText={pageDescriptions.VendorSummary}
                              exact
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}`,
                                `${vendorWords.singularTitleCase} Summary`,
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}`}
                            />
                            <NavLink
                              icon="focus"
                              text="Risk Profile"
                              hoverText={pageDescriptions.VendorRiskProfile}
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/risk_profile`,
                                "Portfolio Risk Profile",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/risk_profile`}
                            />
                            <NavLink
                              icon="risk-waivers"
                              text={"Modified Risks"}
                              hoverText={pageDescriptions.RiskWaivers}
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedVendorId}/riskwaivers`,
                                "Modified Risks",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/riskwaivers`}
                            />
                            <NavLink
                              icon="spanner"
                              text="Remediation"
                              hoverText={pageDescriptions.VendorRemediation}
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/remediation`,
                                "Vendor Remediation",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/remediation`}
                            />
                            <WithVendorData
                              vendorId={managedVendorId}
                              managedOrgId={managedOrgId}
                              render={({ riskAssessmentPublished }) =>
                                hasManagedOrgPermission(
                                  Permissions.OrgAccessVendorAssessments
                                ) ? (
                                  <NavLink
                                    disabled={trialExpired}
                                    icon="risk-assessment"
                                    text="Risk Assessment"
                                    hoverText={
                                      pageDescriptions.VendorAssessment
                                    }
                                    onClick={this.clickNavLinkFunc(
                                      `/analysts/tpvm/${managedOrgId}/${managedVendorId}/assessment`,
                                      "Vendor Risk Assessment",
                                      managedVendorId
                                    )}
                                    to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/assessment`}
                                  />
                                ) : null
                              }
                            />
                            <NavLink
                              icon="globe"
                              text="Domains"
                              hoverText={pageDescriptions.VendorWebsitesAndAPIs}
                              exact
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/webscans`,
                                "Domains",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/webscans`}
                            />
                            <NavLink
                              icon="globe"
                              text="IP Addresses"
                              hoverText={pageDescriptions.VendorIPAddresses}
                              exact
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/ips`,
                                "IP Addresses",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/ips`}
                            />
                            {hasManagedOrgPermission(
                              Permissions.OrgAccessSurveys
                            ) && (
                              <NavLink
                                icon="checklist"
                                text="Questionnaires"
                                hoverText={
                                  pageDescriptions.VendorQuestionnaires
                                }
                                onClick={this.clickNavLinkFunc(
                                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/surveys`,
                                  "Vendor Questionnaires",
                                  managedVendorId
                                )}
                                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/surveys`}
                              />
                            )}
                            {hasManagedOrgPermission(
                              Permissions.OrgAccessAdditionalEvidence
                            ) && (
                              <NavLink
                                icon="document"
                                text="Additional Evidence"
                                hoverText={pageDescriptions.AdditionalEvidence}
                                onClick={this.clickNavLinkFunc(
                                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/evidence`,
                                  "Additional Evidence"
                                )}
                                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/evidence`}
                              />
                            )}
                            {hasManagedOrgPermission(
                              Permissions.OrgAccessVulns
                            ) && (
                              <NavLink
                                icon="bug"
                                text="Vulnerabilities"
                                hoverText={pageDescriptions.VendorVulns}
                                exact
                                onClick={this.clickNavLinkFunc(
                                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/vulns`,
                                  "Vendor Vulnerabilities"
                                )}
                                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/vulns`}
                              />
                            )}
                            {hasManagedOrgPermission(
                              Permissions.OrgAccessVendorTechnologies
                            ) && (
                              <NavLink
                                icon="hyperlink"
                                text="Fourth Parties"
                                hoverText={pageDescriptions.SupplyChain}
                                exact
                                onClick={this.clickNavLinkFunc(
                                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/fourthParty`,
                                  "Vendor Fourth Parties",
                                  managedVendorId
                                )}
                                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/fourthParty`}
                              />
                            )}
                            <NavLink
                              icon="breachsight"
                              text="Managed Assessments"
                              exact
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/managedAssessments`,
                                "Vendor Managed Assessments",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/managedAssessments`}
                            />
                            {hasManagedOrgGroupPermission(
                              GroupAccessVendorAssetSharing
                            ) ? (
                              <NavLink
                                disabled={trialExpired}
                                icon="shared-assessment-no-pad"
                                text="Shared Assets"
                                hoverText={pageDescriptions.VendorSharedAssets}
                                onClick={this.clickNavLinkFunc(
                                  `/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassets`,
                                  "Vendor Documents",
                                  managedVendorId
                                )}
                                to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassets`}
                              />
                            ) : (
                              <WithVendorData
                                vendorId={managedVendorId}
                                managedOrgId={managedOrgId}
                                isManagedVendor
                                render={({ sharedAssessmentPublished }) =>
                                  sharedAssessmentPublished ? (
                                    <NavLink
                                      icon="shared-assessment-no-pad"
                                      text="Trust Page"
                                      hoverText={
                                        pageDescriptions.SharedAssessment
                                      }
                                      exact
                                      onClick={this.clickNavLinkFunc(
                                        `/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassessment`,
                                        "Trust Page",
                                        managedVendorId
                                      )}
                                      to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/sharedassessment`}
                                    />
                                  ) : null
                                }
                              />
                            )}
                            <NavLink
                              icon="document"
                              text="Documents"
                              hoverText={pageDescriptions.VendorDocuments}
                              exact
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/documents`,
                                "Vendor Documents",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/documents`}
                            />
                            <NavLink
                              disabled={trialExpired}
                              icon="people"
                              text="Contacts"
                              hoverText={pageDescriptions.VendorContacts}
                              exact
                              onClick={this.clickNavLinkFunc(
                                `/analysts/tpvm/${managedOrgId}/${managedVendorId}/contacts`,
                                "Vendor Contacts",
                                managedVendorId
                              )}
                              to={`/analysts/tpvm/${managedOrgId}/${managedVendorId}/contacts`}
                            />
                          </div>
                        )}

                        {userData.isAnalyst && (
                          <NavLink
                            icon="breachsight"
                            text="CyberResearch Analysis"
                            onClick={this.clickNavLinkFunc(
                              "/analysts/dataleaks",
                              "Data Leak Analysis"
                            )}
                            to="/analysts/dataleaks"
                          />
                        )}

                        {userData.currentOrgID > 0 && !currentOrgIsFreeOrg && (
                          <>
                            {trustExchangeSection}

                            {tasksRoutes && (
                              <>
                                <div
                                  className="subtitle"
                                  id="vendor_risk_subtitle"
                                >
                                  Tasks
                                </div>
                                {tasksRoutes}
                              </>
                            )}
                          </>
                        )}

                        <AccountNavItems
                          trialExpired={trialExpired}
                          clickNavLinkFunc={this.clickNavLinkFunc}
                        />
                      </div>
                    )}
                  </HeightAdjustableContainer>

                  <div className="bottom">
                    <hr />
                    <HelpAndSupportDropdown
                      dispatch={this.props.dispatch}
                      history={this.props.history}
                      userInNonFreeAccount={
                        userData.currentOrgID > 0 && !currentOrgIsFreeOrg
                      }
                      popupItem={
                        <div className="help-support-btn">
                          <div className="cr-icon-question" />
                          <div className="help-txt">Help &amp; Support</div>
                          <Icon name="chevron" direction={90} />
                        </div>
                      }
                    />
                    <DropdownV2
                      className="bottom-dropdown user-menu-dropdown"
                      popupItem={
                        <div className="user-details">
                          <Gravatar
                            className="avatar"
                            email={userData.emailAddress}
                          />
                          <div className="name-and-org">
                            <div
                              className="name"
                              title={userData.name || userData.profile.nickname}
                            >
                              {userData.name || userData.profile.nickname}
                            </div>
                            <div className="org" title={currentOrgName}>
                              {currentOrgName}
                            </div>
                          </div>
                          <Icon name="chevron" direction={90} />
                        </div>
                      }
                    >
                      <UserMenu
                        {...userData}
                        history={this.props.history}
                        onLogoutClick={this.props.onLogoutClick}
                        onSwitchOrgClick={this.switchOrg}
                      />
                    </DropdownV2>
                  </div>
                </div>
              </div>
              <div className="content-pusher" />
              <TrialAccountRevertedModal
                dispatch={this.props.dispatch}
                active={this.state.trialAccountRevertedModalActive}
                onClose={this.dismissTrialAccountRevertedModal}
              />
              <BookOnboardingCallModal
                dispatch={this.props.dispatch}
                active={this.state.onboardingCallModalActive}
                onClose={() => this.showOnboardingCallModal(false)}
                emailAddress={userData.emailAddress}
                firstName={userData.firstName}
                lastName={userData.lastName}
                calendar={this.props.freeTrialOnboardingCalendar}
              />
              <BookTrialUpgradeCallModal
                dispatch={this.props.dispatch}
                active={this.state.trialUpgradeCallModalActive}
                onClose={() => this.showTrialUpgradeModal(false)}
                emailAddress={userData.emailAddress}
                firstName={userData.firstName}
                lastName={userData.lastName}
                calendar={this.props.freeTrialOnboardingCalendar}
              />
              <ContactUsModal
                active={this.state.vendorRiskUpgradeModalOpen}
                onClose={() =>
                  this.setState({ vendorRiskUpgradeModalOpen: false })
                }
                onContactSent={this.handleContactSent}
                title={"Increase your " + vendorWords.singular + " limit"}
                message={
                  "Let us know if you'd like to add more " +
                  vendorWords.plural +
                  " and we'll be in touch."
                }
                team="customer success"
                onwardTopic="expand"
                onwardMessage="Increase monitored vendor limit."
              />
            </Route>
          </Switch>
          <div id="main-content-area">
            <div id="main-content-area-inner">
              <ErrorBoundary pathname={this.state.location.pathname}>
                <CommonRouter
                  history={this.props.history}
                  dispatch={this.props.dispatch}
                  userData={userData}
                  permissions={this.props.permissions}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
        <RouterTriggeredModal />
        <HotjarWrapper />
        <HubspotWidgetWrapper />
        <TermsAndConditionsUpdateMessage />
        <Footer />
      </Router>
    );
  }
}

const emptyArray = [];

export default appConnect((state, props) => {
  const managedOrgID = _get(
    state.common,
    "viewingManagedVendorForOrgId.orgId",
    0
  );
  const managedVendorID = _get(
    state.common,
    "viewingManagedVendorForOrgId.vendorId",
    0
  );
  let managedOrgEntitlements = emptyArray;
  let managedOrgGroupEntitlements = emptyArray;
  if (managedOrgID) {
    managedOrgEntitlements = _get(
      state.cyberRisk.managedVendorData,
      `[${managedOrgID}].entitlements`,
      emptyArray
    );
    managedOrgGroupEntitlements = _get(
      state.cyberRisk.managedVendorData,
      `[${managedOrgID}].groupEntitlements`,
      emptyArray
    );
  }

  const currentOrgID = _get(state.common, "userData.currentOrgID", 0);
  const currentUserID = _get(state.common, "userData.id", 0);
  const freeTrialEligibility = _get(
    state.common,
    `freeTrialEligibility[${currentUserID}][${currentOrgID}]`,
    undefined
  );
  const freeTrialOnboardingCalendar = _get(
    state.common,
    `freeTrialEligibility[${currentUserID}].onboardingCalendar`,
    undefined
  );

  let canViewViewingVendor = false;
  if (state.common.viewingVendorId) {
    canViewViewingVendor =
      state.cyberRisk.vendors[state.common.viewingVendorId]?.watching?.result
        ?.canView;
  }

  return {
    vendorWatchLimit: state.cyberRisk.customerData.vendorWatchLimit,
    vendorWatchLimitIsHard: state.cyberRisk.customerData.vendorWatchLimitIsHard,
    vendorWatchCount: state.cyberRisk.customerData.vendorWatchCount,
    vendorImmutableCount: state.cyberRisk.customerData.vendorImmutableCount,
    vendorLookupLimitIsHard:
      state.cyberRisk.customerData.vendorLookupLimitIsHard,
    vendorLookupLimit: state.cyberRisk.customerData.vendorLookupLimit,
    vendorLookupCount: state.cyberRisk.customerData.vendorLookupCount,
    vendors: state.cyberRisk.vendors,
    exportItems: state.common.exportItems.data,
    canAccessSystemAdmin: canAccessSystemAdmin(state),
    viewingVendorId: state.common.viewingVendorId,
    canViewViewingVendor: canViewViewingVendor ?? false,
    viewingManagedVendorOrgId: managedOrgID,
    viewingManagedVendorId: managedVendorID,
    managedVendorOrgEntitlements: managedOrgEntitlements,
    managedOrgGroupEntitlements,
    hasActiveVendorComparison:
      !!state.cyberRisk.vendorComparisonVendorIds?.filter((v) => v !== 0)
        .length,
    activeVendorImport: state.cyberRisk.vendorImport,
    freeTrialEligibility: freeTrialEligibility,
    freeTrialOnboardingCalendar: freeTrialOnboardingCalendar,
    permissions: state.common.permissions,
  };
})(Navigation);
