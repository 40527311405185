import { FC, useState } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import ReportCard from "../../_common/components/ReportCard";
import { useSortingWithPagination } from "../../_common/hooks";
import { UserScopeApp } from "../api/types";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import _ from "lodash";

import { useLocation, useRouteMatch } from "react-router-dom";
import { UserRouteParams } from "../UserBaseNavItems";
import UserBaseAPI from "../api/userbase.api";
import "./UserPermissions.scss";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import UserPermissionsTable from "./UserPermissionsTable";

const PAGE_SIZE = 50;

const EXPOSURE_LEVEL_COL = "exposure_level_col";
const PERMISSION_NAME_COL = "permission_name_col";
const NUM_APPS_FOR_PERMISSION_COL = "num_apps_for_permission_col";

const UserPermissions: FC = ({}) => {
  const { pathname } = useLocation();
  const match = useRouteMatch<UserRouteParams>();
  const userUUID = match.params.userUUID;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUserScopesV1Query({
    userUUID: userUUID,
  });

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: EXPOSURE_LEVEL_COL,
      text: "Exposure level",
      sortable: true,
    },
    {
      id: PERMISSION_NAME_COL,
      text: "Description",
      sortable: true,
    },
    {
      id: NUM_APPS_FOR_PERMISSION_COL,
      text: "Apps",
      sortable: true,
    },
  ];

  const [
    sortedPermissions,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserScopeApp,
    | typeof EXPOSURE_LEVEL_COL
    | typeof PERMISSION_NAME_COL
    | typeof NUM_APPS_FOR_PERMISSION_COL
  >(
    data?.scopes ?? [],
    EXPOSURE_LEVEL_COL,
    SortDirection.DESC,
    {
      [PERMISSION_NAME_COL]: {
        orderFuncs: [(p) => p.description.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [EXPOSURE_LEVEL_COL]: {
        orderFuncs: [(p) => p.riskLevel, (p) => p.appNames.length],
        sortDirsAsc: [SortDirection.ASC, SortDirection.DESC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [NUM_APPS_FOR_PERMISSION_COL]: {
        orderFuncs: [(p) => p.appNames.length],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  // Keep track of which users' rows are expanded
  const [expanded, setExpanded] = useState<Set<string>>(new Set());

  const toggleRowExpansion = (s: UserScopeApp) => {
    const newExpanded = _.clone(expanded);
    if (newExpanded.has(s.name)) {
      newExpanded.delete(s.name);
    } else {
      newExpanded.add(s.name);
    }
    setExpanded(newExpanded);
  };

  if (!isLoading && data?.scopes === undefined) {
    return <></>;
  }

  const rows = sortedPermissions.map((p: UserScopeApp): IXTableRow<string> => {
    return {
      id: p.name,
      onClick: () => toggleRowExpansion(p),
      expanded: expanded.has(p.name),
      expandContent: <UserPermissionsTable appNames={p.appNames} />,
      cells: [
        <XTableCell key={EXPOSURE_LEVEL_COL}>
          <ScopeRiskLevelDisplay riskLevel={p.riskLevel} />
        </XTableCell>,
        <XTableCell
          className="user-permissions-cell-with-subtext"
          key={PERMISSION_NAME_COL}
        >
          <div className="user-permission-maintext">{p.description}</div>
          <div className="user-permission-subtext">{p.name}</div>
        </XTableCell>,
        <XTableCell key={NUM_APPS_FOR_PERMISSION_COL}>
          {p.appNames.length}
        </XTableCell>,
      ],
    };
  });

  const isEmptyResult = !isLoading && sortedPermissions.length === 0;

  return (
    <div className="user-permissions">
      <ReportCard newStyles>
        {isEmptyResult && (
          <EmptyCard
            text={"There are no permissions for this user at this time."}
          />
        )}

        {!isEmptyResult && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            expandableRows
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default UserPermissions;
