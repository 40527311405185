import { find, get, isEmpty, isFinite } from "lodash";
import moment from "moment";
import { parse as urlParse } from "url";
import { stringify as queryStringify } from "querystring";
import * as Sentry from "@sentry/browser";
import SeverityIcon from "./components/SeverityIcon";
import md5 from "md5";
import { organisationAccountType } from "./types/organisations";
import { OrgAccessSubsidiaries } from "./permissions";

export {
  getCurrentOrgFromUserData,
  getCustomerOrgNameFromState,
  isExpiredTrial,
  userOrgHasSubsidiaries,
} from "./helpers/userOrg.helpers";

// LogError logs an error to the console, Google Analytics, and Sentry
export const LogError = (context, e) => {
  console.error(context, e);
};

export const LogSentryException = (e) => {
  Sentry.captureException(e);
};

export const RoundToDecimalPlaces = (num, decimalPlaces = 2) => {
  const pow = 10 ** decimalPlaces;
  return Math.round(num * pow) / pow;
};

export const CalculatePercentage = (value, total, decimalPlaces = 2) => {
  if (!isFinite(value) || !isFinite(total)) return null;

  if (total <= 0) return 0;

  return RoundToDecimalPlaces((value / total) * 100, decimalPlaces);
};

export const TruncateLargeNumber = (num) => {
  if (!isFinite(num)) {
    return "?";
  }

  // Return a 3 digit number with K for thousands, M for millions
  if (num < 1000) {
    return num.toString();
  }

  let base;
  let op;
  let dp;

  if (num < 10 ** 6) {
    base = 10 ** 3;
    op = "K"; // Thousands
  } else if (num < 10 ** 9) {
    base = 10 ** 6;
    op = "M"; // Millions
  } else {
    base = 10 ** 9;
    op = "B"; // BILLIONS?
  }

  if (num < base * 10) {
    dp = 2;
  } else if (num < base * 100) {
    dp = 1;
  } else {
    dp = 0;
  }

  const newNum = RoundToDecimalPlaces(num / base, dp);
  return `${newNum}${op}`;
};

export const NumberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const Possessive = (str) => (str.endsWith("s") ? str + "'" : str + "'s");

export const GetCSTARScoreColorClass = (score) => {
  if (score === null || score === undefined || score < 0) {
    return "unknown";
  } else if (score < 201) {
    return "low";
  } else if (score < 401) {
    return "average";
  } else if (score < 601) {
    return "fair";
  } else if (score < 801) {
    return "good";
  }

  return "excellent";
};

export const GetCSTARScoreColorHex = (score) => {
  if (typeof score !== "number" || score < 0) {
    return "#A6ABC9";
  } else if (score < 201) {
    return "#EF1700";
  } else if (score < 401) {
    return "#FF9901";
  } else if (score < 601) {
    return "#FDC106";
  } else if (score < 801) {
    return "#90CA2F";
  }

  return "#20AF5D";
};

export const Grades = [
  {
    label: "A",
    min: 801,
    max: 950,
    color: GetCSTARScoreColorHex(801),
  },
  {
    label: "B",
    min: 601,
    max: 800,
    color: GetCSTARScoreColorHex(601),
  },
  {
    label: "C",
    min: 401,
    max: 600,
    color: GetCSTARScoreColorHex(401),
  },
  {
    label: "D",
    min: 201,
    max: 400,
    color: GetCSTARScoreColorHex(201),
  },
  {
    label: "F",
    min: 0,
    max: 200,
    color: GetCSTARScoreColorHex(0),
  },
];

export const ConvertHexToRGBA = (hex, opacity) => {
  const newHex = hex.replace("#", "");
  const r = parseInt(newHex.substring(0, 2), 16);
  const g = parseInt(newHex.substring(2, 4), 16);
  const b = parseInt(newHex.substring(4, 6), 16);

  return `rgba(${r},${g},${b},${opacity / 100})`;
};

// CancelablePromise implemented to avoid setState errors on async actions
// coming back to set state on a now unmounted component.
// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export const MakeCancelablePromise = (promise) => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (val) => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      (error) => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

/**
 * DateStringToUnixDateWithoutTimezone
 * Takes a regular date string parses it ignoring the timezone. This is so we can take the
 * date and time of the string as literal and let moment assume the client's timezone.
 * @param {*} dateString - Date string in standard ISO YYYY-MM-DDTHH:mm:ssZ format
 * @param {*} [keepTime] - if true, keep the time, else default to midnight
 * @return number
 */
export const DateStringToUnixDateWithoutTimezone = (dateString, keepTime) => {
  let timezoneEscapeParser = "YYYY-MM-DD[THH:mm:ssZ]";
  if (keepTime) {
    timezoneEscapeParser = "YYYY-MM-DDTHH:mm:ss[Z]";
  }
  return parseInt(moment(dateString, timezoneEscapeParser).format("x"));
};

export const MapArrayToUnixDateWithoutTimezone = (array) => {
  if (!array || !array.length) {
    return [];
  }

  return array.map((item) => {
    if (item.CreatedAt) {
      return {
        ...item,
        CreatedAt: DateStringToUnixDateWithoutTimezone(item.CreatedAt),
      };
    }
    return {
      ...item,
      time: DateStringToUnixDateWithoutTimezone(item.time),
    };
  });
};

export const LocalisedDayMonthFormat = (unixDate, includeYear = false) => {
  const dayMonthMo = moment(unixDate, "x");
  if (!dayMonthMo.isValid()) return "?";
  return dayMonthMo.format(includeYear ? "ll" : "MMM D");
};

const domainPattern = /^([a-z0-9A-Z-]+\.)+([a-z0-9A-Z-]+\.)*([a-z0-9A-Z-]+)$/;
const urlWithOptionalProtocolPattern =
  /^(http|https):\/\/|[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?$/i;
const urlWithProtocolPattern =
  /^https?:\/\/[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,63}(:[0-9]{1,5})?(\/.*)?$/i;
const ipPattern =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
const zeroesIpPattern = /^(0+\.){3}0+$/;
const wellFormedIpPattern = /^([0-9]+\.){3}[0-9]+$/;
const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phonePattern = /^[\d()\+\-\s]+$/;

/**
 * validateDomainName()
 * This helper function take a string and validates whether it represents a well-formed
 * domain name.
 * * @param {string} domain - the domain name string to validate
 */
export function validateDomainName(domain) {
  return domainPattern.test(domain);
}

export function validateDomainOrIp(url) {
  return domainPattern.test(url) || ipPattern.test(url);
}

export function validateUrlOrIp(url) {
  return urlWithOptionalProtocolPattern.test(url) || ipPattern.test(url);
}

export function isIPAddress(hostname) {
  return ipPattern.test(hostname);
}

export function ipIsZeroes(url) {
  return zeroesIpPattern.test(url);
}

export function ipIsLocal(url) {
  if (!wellFormedIpPattern.test(url)) {
    return false;
  }
  const parts = url.split(".");
  const a = parseInt(parts[0], 10);
  const b = parseInt(parts[1], 10);
  if (a == 10) {
    return true;
  } else if (a == 127) {
    return true;
  } else if (a == 192 && b == 168) {
    return true;
  } else if (a == 172 && b >= 16 && b <= 31) {
    return true;
  }
  return false;
}

export function validateUrl(url) {
  return urlWithOptionalProtocolPattern.test(url);
}

export function validateUrlWithProtocol(url) {
  return urlWithProtocolPattern.test(url);
}

export function validateEmail(email) {
  return emailPattern.test(email);
}

export const validatePhone = (phoneNumber) => phonePattern.test(phoneNumber);

export const GetHostnameFromUrl = (urlString) => {
  if (domainPattern.test(urlString)) {
    // This already looks like a hostname, just return it
    return urlString;
  }

  /**
   * TODO - strongly suggest we move this to `new URL(urlString).host`
   * According to code hints, urlParse is deperecated and we should use
   * WHATWG URL API instead.
   * We should write some tests first.
   *
   * Example code change:
   * if (urlWithProtocolPattern.test(urlString)) return new URL(urlString).host
   * return new URL(`example://${urlString}`).host
   */
  const url = urlParse(urlString);
  return url.host;
};

// Given a query string, eg. "?thing=good&bad=true",
// extract all values into an object
export const GetQueryParams = (query) => {
  if (!query) {
    return {};
  }

  return (/^[?#]/.test(query) ? query.slice(1) : query)
    .split("&")
    .reduce((params, param) => {
      const newParams = { ...params };
      const [key, value] = param.split("=");
      newParams[key] = value
        ? decodeURIComponent(value.replace(/\+/g, " "))
        : "";
      return newParams;
    }, {});
};

// Given a History object, find the values of some params and remove them from the URL without reloading the page.
export const ConsumeQueryParams = (history, paramNames) => {
  const curParams = GetQueryParams(history.location.search);
  const ret = {};
  for (let i = 0; i < paramNames.length; i++) {
    if (Object.prototype.hasOwnProperty.call(curParams, paramNames[i])) {
      ret[paramNames[i]] = curParams[paramNames[i]];
      delete curParams[paramNames[i]];
    }
  }

  const newParamString = queryStringify(curParams);
  history.replace({
    pathname: history.location.pathname,
    search: Object.keys(curParams).length === 0 ? "" : `?${newParamString}`,
    hash: history.location.hash,
  });

  return ret;
};

// Cloudscan and Findings Severity helpers

export const severityMap = {
  waived: {
    icon: <SeverityIcon severity="waived" />,
    label: <SeverityIcon severity="waived" label />,
    labelColor: "grey",
    name: "waived",
    num: -1,
  },
  pass: {
    icon: <SeverityIcon severity="pass" />,
    label: <SeverityIcon severity="pass" label />,
    labelColor: "green",
    name: "pass",
    num: 0,
  },
  info: {
    icon: <SeverityIcon severity="info" />,
    label: <SeverityIcon severity="info" label />,
    labelColor: "blue",
    name: "info",
    num: 1,
  },
  low: {
    icon: <SeverityIcon severity="low" />,
    label: <SeverityIcon severity="low" label />,
    labelColor: "gimblet",
    name: "low",
    num: 2,
  },
  medium: {
    icon: <SeverityIcon severity="medium" />,
    label: <SeverityIcon severity="medium" label />,
    labelColor: "yellow",
    name: "medium",
    num: 3,
  },
  high: {
    icon: <SeverityIcon severity="high" />,
    label: <SeverityIcon severity="high" label />,
    labelColor: "orange",
    name: "high",
    num: 4,
  },
  critical: {
    icon: <SeverityIcon severity="critical" />,
    label: <SeverityIcon severity="critical" label />,
    labelColor: "red",
    name: "critical",
    num: 5,
  },
};

export const factCategories = {
  WebsiteSec: "website_sec",
  EmailSec: "email_sec",
  NetworkSec: "network_sec",
  Phishing: "phishing",
  BrandProtect: "brand_protect",
  Vulns: "vulns",
  EmailExposures: "emailexposures",
  DataLeaks: "dataleaks",
  QuestionnaireRisks: "questionnaire_risks",
  AdditionalEvidenceRisks: "additional_evidence",
  SaaSRisks: "saas",
  AppguardPackageVulns: "appguard_package_vuln",
  AppguardRepoConfig: "appguard_repo_config",
  Website: "website_sec_v2",
  Email: "email_sec_v2",
  Network: "network_sec_v2",
  BrandReputation: "brand_protect_v2",
  IPDomainReputation: "ip_domain_reputation",
  Encryption: "encryption",
  DNS: "dns",
  DataLeakage: "data_leakage",
  VulnerabilityManagement: "patch_management",
  AttackSurface: "attack_surface",
};

// oldCategories are categories that may be displayed
// until an org/vendor is rescored with the new category algorithm.
export const oldCategories = [
  factCategories.WebsiteSec,
  factCategories.NetworkSec,
  factCategories.BrandProtect,
  factCategories.EmailSec,
  factCategories.Phishing,
];

// defaultShownCategories are categories introduced
// in the v5 scoring algorithm.
export const defaultShownCategories = [
  factCategories.Website,
  factCategories.IPDomainReputation,
  factCategories.Encryption,
  factCategories.VulnerabilityManagement,
  factCategories.AttackSurface,
  factCategories.Network,
  factCategories.Email,
  factCategories.DataLeakage,
  factCategories.DNS,
  factCategories.BrandReputation,
];

// taken from categories.go
export const defaultCategoryWeights = {
  [factCategories.Website]: 19,
  [factCategories.IPDomainReputation]: 19,
  [factCategories.Encryption]: 17,
  [factCategories.VulnerabilityManagement]: 13,
  [factCategories.AttackSurface]: 11,
  [factCategories.Network]: 8,
  [factCategories.Email]: 7,
  [factCategories.DataLeakage]: 3,
  [factCategories.DNS]: 2,
  [factCategories.BrandReputation]: 1,
};

export const findingSeverityNumberToText = (severity) => {
  if (severity < 3) {
    return "low";
  } else if (severity < 7) {
    return "medium";
  } else if (severity < 9) {
    return "high";
  }

  return "critical";
};

// TODO deprecated as this is a duplicate of SeverityAsString?
export const cloudscanSeverityNumberToText = (severity) => {
  switch (severity) {
    case 0:
      return "pass";
    case 1:
      return "info";
    case 2:
      return "low";
    case 3:
      return "medium";
    case 4:
      return "high";
    default:
      return "critical";
  }
};

export const cvssToText = (severity) => {
  if (severity < 4) {
    return "low";
  } else if (severity < 7) {
    return "medium";
  } else if (severity < 9) {
    return "high";
  }
  return "critical";
};

export const cvssToTextCapitalized = (severity) => {
  if (severity < 4) {
    return "Low";
  } else if (severity < 7) {
    return "Medium";
  } else if (severity < 9) {
    return "High";
  }
  return "Critical";
};

export const formattedCVSS = (cvss) => (cvss === 10 ? 10 : cvss.toFixed(1));

export const formattedEPSS = (epss) => (100 * epss).toFixed(1) + "%";

// Document visibility API compatibility for various browsers.
let hidden;
let visibilityChange;
if (typeof document.hidden !== "undefined") {
  // Opera 12.10 and Firefox 18 and later support
  hidden = "hidden";
  visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
  hidden = "msHidden";
  visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
  hidden = "webkitHidden";
  visibilityChange = "webkitvisibilitychange";
}

export const hiddenDocumentProperty = hidden;
export const visibilityChangeEvent = visibilityChange;

// Boolean XOR
export const XOR = (a, b) => a !== b;

export const hasTruthyValue = (obj) =>
  Object.values(obj).some((value) => {
    switch (typeof value) {
      case "object":
        return !isEmpty(value);
      default:
        return !!value;
    }
  });

export const hashCode = (obj) => {
  if (!obj) {
    return 0;
  }
  const s = JSON.stringify(obj);
  let hash = 0;
  if (s.length === 0) {
    return hash;
  }
  for (let i = 0; i < s.length; i++) {
    const char = s.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
};

// Format a date in the user's locale (date portion)
export const formatDateAsLocal = (dateStr) => {
  return moment(dateStr).local().format("ll");
};

// Format a date in the user's locale (date portion) using the users current timezone as the target timezone
export const formatDateAsLocalCurrentTimezone = (dateStr) => {
  return moment(dateStr).utcOffset(moment().utcOffset()).format("ll");
};

// Format a date in the user's locale (time portion)
export const formatTimeAsLocal = (dateStr) => {
  return moment(dateStr).local().format("h:mma");
};

// deprecated - DO NOT use this for regular timestamps, as it will discard the timezone and
// parse the date as if it's already in the user's current timezone.
// TODO: Audit all usages of this function and rename this to make it clear it discards the timezone
export const formatDate = (dateStr) => {
  const date = moment(DateStringToUnixDateWithoutTimezone(dateStr, "x"));
  return date.year() === 1 ? "" : date.format("ll");
};

/**
 * @see formatTimeAsLocal
 */
export const formatTime = (dateStr) => {
  return moment(DateStringToUnixDateWithoutTimezone(dateStr, true)).format(
    "h:mma"
  );
};

export const RFC3339Format = '"YYYY-MM-DD[T]HH:mm:ss[Z]Z"';

export const userToName = (user, showBothEmailAndName) => {
  if (user.name) {
    if (showBothEmailAndName) {
      return `${user.name} (${user.email})`;
    }

    return user.name;
  }
  return user.email;
};

export const truncateText = (value, truncateAt) => {
  let truncatedText = "";

  if (value && value.length > 0) {
    // Get first segment if line breaks
    truncatedText = value.split(/\r?\n/)[0];

    if (truncatedText.length > truncateAt) {
      truncatedText = truncatedText.substr(0, truncateAt - 3);

      const lastIndexOfSpace = truncatedText.lastIndexOf(" ");
      const lastIndexOfPeriod = truncatedText.lastIndexOf(".");

      if (lastIndexOfSpace === -1 && lastIndexOfPeriod === -1) {
        // No space/period so just truncate with ellipses where we are
        truncatedText = `${truncatedText}...`;
      } else if (lastIndexOfSpace > lastIndexOfPeriod) {
        // Truncate to the space and substitute for the ellipses
        truncatedText = `${truncatedText.substr(0, lastIndexOfSpace)}...`;
      } else {
        // We can just truncate to the last period within the range
        truncatedText = truncatedText.substr(0, lastIndexOfPeriod);
      }
    } else {
      // Segment is shorter than truncate length, so just return
      return truncatedText;
    }
  } else {
    return "";
  }

  return truncatedText;
};

export const truncateSimpleTextWithElipsis = (str, n) => {
  if (str && n > 0) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }
  return str;
};

export const vendorUrlPrefix = (
  vendorId,
  isManagementSession,
  managedOrgId,
  isSubsidiary = false
) => {
  if (isSubsidiary && vendorId) {
    return `/subsidiaries/${vendorId}`;
  } else if (vendorId) {
    if (isManagementSession) {
      return `/analysts/tpvm/${managedOrgId}/${vendorId}`;
    }

    return `/vendor/${vendorId}`;
  }

  return "";
};

export const sharedAssessmentsUrlPrefix = (vendorId) => {
  return `/sharedassessments/${vendorId}`;
};

export const getGravatarURL = (email) =>
  `https://www.gravatar.com/avatar/${md5(email)}?d=mp`;

export const pluralise = (count, singular, multiple) => {
  if (count === 1) {
    return singular;
  } else {
    return multiple;
  }
};

export const commaSeparatedList = (list) => {
  if (!list || list.length === 0) {
    return "";
  } else if (list.length === 1) {
    return list[0];
  } else if (list.length === 2) {
    return `${list[0]} and ${list[1]}`;
  } else {
    return `${list.slice(0, -1).join(", ")}, and ${list.slice(-1)}`;
  }
};

export const contactSupport = () => {
  if (typeof window.Intercom === "function") {
    window.Intercom("showNewMessage");
  } else {
    window.open("mailto:support@upguard.com");
  }
};

export const bookADemo = () => {
  window
    .open(
      "https://www.upguard.com/demo?utm_campaign=vendor-account&utm_source=product",
      "_blank"
    )
    ?.focus();
};

// GT: snaffled this function from stackoverflow.com. it seems to overcome chrome's shortcomings in window placement
export const popupCenter = (url, title, w, h) => {
  // Fixes dual-screen position
  const dualScreenLeft = window.screenLeft ? window.screenLeft : 0;
  const dualScreenTop = window.screenTop ? window.screenTop : window.screenY;
  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const left = width / 2 - w / 2 + dualScreenLeft;
  const top = height / 2 - h / 2 + dualScreenTop;
  const features =
    "scrollbars=yes, width=" +
    w +
    ", height=" +
    h +
    ", top=" +
    top +
    ", left=" +
    left;
  const newWindow = window.open(url, title, features);
  // Puts focus on the newWindow
  if (newWindow) {
    newWindow.focus();
  }
  return newWindow;
};
