import { Severity, SeverityInt } from "../../_common/types/severity";
import {
  BreachDataClass,
  BreachSource,
  BreachType,
} from "../../_common/types/emailExposures";
import { IScore } from "../../_common/types/score";

export interface SaaSUser {
  name: string;
  email: string;
  firstDetected?: string;
  teams: string[];
  roles: string[];
  uuid: string;
}

export interface UserWithStats {
  name: string;
  email: string;
  teams: string[] | null;
  roles: string[] | null;
  uuid: string;
  rating: number;
  adjustedRating: number;
  trend: number;
  totalApps: number;
  notApprovedApps: number;
  waivedApps: number;
}

export interface Scope {
  name: string;
  description: string;
  riskLevel: ScopeRiskLevel;
  externalID: string;
}

export interface AppUser {
  uuid: string;
  name: string;
  email: string;
  teams: string[];
  roles: string[];
  rating: number;
  adjustedRating: number;
  approved: boolean;
  waived: boolean;
  highestRiskLevel: ScopeRiskLevel;
  firstDetected: string;
}

export interface App {
  orgID: number;
  name: string;
  category?: string;
  description: string;
  summary: string;
  domain?: string;
  securityScore: number;
  industry?: string;
  status: AppStatus;
  approvedForAll: boolean;
  approvedForTeams: Team[];
  approvedForRoles: Role[];
  numCurrentUsers: number;
  numUnapprovedUsers: number;
  numWaivedUsers: number;
  highestScopeRiskLevel?: ScopeRiskLevel;
  note?: string;
}

export enum AppStatus {
  ApprovedAppStatus = "approved",
  NeedsReviewAppStatus = "needs_review",
  NotApprovedAppStatus = "not_approved",
  InReviewAppStatus = "in_review",
}

export interface AppStatusWithCount {
  appStatus: AppStatus;
  count: number;
}

export enum ScopeRiskLevel {
  LowScopeRiskLevel = 1,
  MediumLowScopeRiskLevel = 2,
  MediumScopeRiskLevel = 3,
  MediumHighScopeRiskLevel = 4,
  HighScopeRiskLevel = 5,
}

export interface Team {
  id: number;
  name: string;
}

export interface Role {
  id: number;
  name: string;
}

export enum AppEventType {
  ReviewedAppUpdateEvent = "reviewed_app_update",
  AppFirstDetectedEvent = "app_first_detected",
}

interface BaseAppEvent {
  at: string;
  userName?: string;
  userEmail?: string;
}

interface ReviewedAppUpdateEvent extends BaseAppEvent {
  eventType: AppEventType.ReviewedAppUpdateEvent;
  metadata: ReviewedAppUpdateMetadata;
}

export interface ReviewedAppUpdateMetadata {
  status: AppStatus;
  note?: string;
  approvedForAll?: boolean;
  approvedTeams?: string[];
  approvedRoles?: string[];
}

interface AppFirstDetectedEvent extends BaseAppEvent {
  eventType: AppEventType.AppFirstDetectedEvent;
  metadata: null;
}

export type AppEvent = ReviewedAppUpdateEvent | AppFirstDetectedEvent;

export interface RiskBreakdown {
  numRisks: number;
  severity: Severity;
  severityNum: SeverityInt;
}

export interface AppScope extends Scope {
  numUsers: number;
}

export interface UserScopeApp extends Scope {
  appNames: string[];
}
export interface AppScopeUser {
  uuid: string;
  name: string;
  email: string;
  roles: string[];
  teams: string[];

  rating: number;
  adjustedRating: number;
  approved: boolean;
  waived: boolean;
}

export interface UserSummary {
  name: string;
  email: string;
  teams: string[];
  roles: string[];
  uuid: string;
  rating: number;
  adjustedRating: number;

  scores: IScore[];
  adjustedScores: IScore[];
  categoryScores: Record<string, IScore[]>;
  adjustedCategoryScores: Record<string, IScore[]>;
}

export interface UserApp {
  name: string;
  description: string;
  domain?: string;
  industry?: string;
  highestScopeRiskLevel: ScopeRiskLevel;
  firstDetected: string;
  securityScore: number;
  userIsApproved: boolean;
  userIsWaived: boolean;
}

export interface IdentityBreach {
  id: number;
  title: string;
  name: string;
  domain: string;
  breachDate?: string;
  dataClasses?: BreachDataClass[];
  exposuresCount: number;
  riskScore: number;
  description: string;
  isBreach: boolean;
  source: BreachSource;
  publishedDate: string;
  orgCount: number;
  archived: boolean;
  breachType: BreachType;
  isDarkWebBreach: boolean;
  pasteURL?: string;
}
export interface DashboardStats {
  numUsers: number;
  numApps: number;
  numPermissions: number;
  appStatusCounts: AppStatusWithCount[];
}

export interface OrgScope extends Scope {
  userUUIDs: string[];
}
