import { FC } from "react";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../types/label";
import "../style/components/BetaLabel.scss";
import { SidePopupV2 } from "./DismissablePopup";

interface IBetaLabelProps {
  onClickShouldOpenIntercom?: boolean;
  onClickUrlOpen?: string;
  featureName?: string;
}

export const openIntercomForFeedback = (featureName: string) => {
  if (typeof window.Intercom === "function") {
    window.Intercom(
      "showNewMessage",
      `Hi there, I have some feedback on the ${featureName} feature:`
    );
  }
};

const BetaLabel: FC<IBetaLabelProps> = ({
  onClickShouldOpenIntercom,
  featureName,
  onClickUrlOpen,
}) => {
  let onClick;
  let popupContent;

  if (onClickShouldOpenIntercom && featureName) {
    onClick = () => openIntercomForFeedback(featureName);

    popupContent =
      "We'd love to hear your feedback on this beta feature. Click here to send us a message.";
  } else if (onClickUrlOpen) {
    popupContent =
      "We'd love to hear your feedback on this beta feature. Click here to send us a message.";

    onClick = () => window.open(onClickUrlOpen, "_blank");
  }

  return (
    <SidePopupV2
      className="beta-label"
      text={popupContent}
      position={"bottom"}
      width={200}
    >
      <PillLabel
        filled
        className="beta-label-inner"
        color={LabelColor.MountainMeadow}
        onClick={onClick}
      >
        BETA
      </PillLabel>
    </SidePopupV2>
  );
};

export default BetaLabel;
