import { FC, useState, useCallback } from "react";
import {
  OrganisationAcceptedRisk,
  OrganisationAcceptedRiskStatus,
  RiskAssetType,
} from "../../reducers/customerAcceptedRisks.actions";
import { IUserMini, IUserMiniMap } from "../../../_common/types/user";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import UserAvatar from "../../../_common/components/UserAvatar";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import moment from "moment";
import Button from "../../../_common/components/core/Button";
import SlidePanel from "../../../_common/components/SlidePanel";
import "../../style/components/RiskWaiversTable.scss";
import {
  formatDateAsLocalCurrentTimezone,
  LogError,
} from "../../../_common/helpers";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../../_common/components/InfoTable";
import UserDisplay from "../UserDisplay";
import CommentDisplay, { CommentDisplayStyling } from "../CommentDisplay";
import InfoBanner from "../InfoBanner";
import ExpandableItem from "../../../_common/components/ExpandableItem";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";
import classNames from "classnames";
import ScrollableDiv from "../ScrollableDiv";
import { SlidePanelSection } from "../filter/SlidePanelSection";
import { RiskDescription } from "../RiskSummaryDetails";
import { VendorSummaryRiskType } from "../../../_common/types/vendorSummary";

interface breachSightRiskWaiverPanelRowProps {
  title: string; // Shown at the top of the row
  startExpanded: boolean; // Whether the row should start expanded
  children?: React.ReactNode;
}

// Represents a collapsable row within the RiskPanel.
const BreachSightRiskWaiverPanelRow: FC<breachSightRiskWaiverPanelRowProps> = (
  props
) => {
  const [expanded, setExpanded] = useState(props.startExpanded);

  const onToggleExpanded = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  return (
    <div className="check-item">
      <SlidePanelSection
        toggleExpand={onToggleExpanded}
        title={<div className={"section-title"}>{props.title}</div>}
        expanded={expanded}
      >
        {props.children}
      </SlidePanelSection>
    </div>
  );
};

export default function BreachSightRiskWaiverPanel(props: {
  active: boolean;
  selectedWaiver?: OrganisationAcceptedRisk;
  showApproveRejectPanelOptions: boolean;
  userHasWriteAccess: boolean;
  hideStatusAndUserDetails: boolean;
  canUsePublicWaivers: boolean;
  usersMap: IUserMiniMap;
  onClose: () => void;
  onEdit?: () => void;
  onDelete: () => void;
  onApprove: (isApproving: boolean) => void;
  orgAccessNewRiskDesigns?: boolean;
}) {
  const {
    selectedWaiver,
    showApproveRejectPanelOptions,
    userHasWriteAccess,
    hideStatusAndUserDetails,
    canUsePublicWaivers,
  } = props;

  const creatorUser = getUserOrDefault(
    props.usersMap,
    selectedWaiver?.createdBy
  );
  const approverUser = getUserOrDefault(
    props.usersMap,
    selectedWaiver?.approverId
  );

  let assetsList: React.ReactNode = <></>;

  if (selectedWaiver) {
    switch (selectedWaiver.riskAssetType) {
      case RiskAssetType.cloudscan:
        if (selectedWaiver.allWebsites) {
          assetsList = "All domains & IPs";
        } else if (selectedWaiver.websites.length === 1) {
          assetsList = selectedWaiver.websites[0];
        } else if (selectedWaiver) {
          assetsList = (
            <ExpandableItem
              header={`${selectedWaiver.websites.length} domains & IPs`}
              content={
                <ul className={"website-list"}>
                  {selectedWaiver.websites.map((w) => (
                    <li key={w}>{w}</li>
                  ))}
                </ul>
              }
            />
          );
        }
        break;
      case RiskAssetType.appguardRepo:
        assetsList = (
          <ExpandableItem
            header={`${selectedWaiver.repoNames.length} repositories`}
            content={
              <ul className={"website-list"}>
                {selectedWaiver.repoNames.map((w) => (
                  <li key={w}>{w}</li>
                ))}
              </ul>
            }
          />
        );
        break;
      case RiskAssetType.appguardManifest:
        assetsList = (
          <ExpandableItem
            header={`${selectedWaiver.manifestLocations.length} inventories`}
            content={
              <ul className={"website-list"}>
                {selectedWaiver.manifestLocations.map((w) => (
                  <li key={w}>{w}</li>
                ))}
              </ul>
            }
          />
        );
        break;
      case RiskAssetType.userbaseUser:
        assetsList = (
          <ExpandableItem
            header={`${selectedWaiver.userInfos.length} users`}
            content={
              <ul className={"website-list"}>
                {selectedWaiver.userInfos.map((w) => (
                  <li key={w.uuid}>{w.name}</li>
                ))}
              </ul>
            }
          />
        );
        break;
      default:
        LogError(
          `unexpected risk waiver asset type: ${selectedWaiver.riskAssetType}`
        );
        assetsList = <></>;
    }
  }

  const orgAccessNewRiskDesigns = props.orgAccessNewRiskDesigns;

  const waiverHasApprover =
    selectedWaiver?.approverEmail || selectedWaiver?.approverId;
  const waiverWasApprovedByApprover =
    waiverHasApprover && selectedWaiver?.status === "active";
  const waiverWasRejectedByApprover =
    waiverHasApprover && selectedWaiver?.status === "rejected";
  const waiverApproverHasDecided =
    waiverWasApprovedByApprover || waiverWasRejectedByApprover;
  const waiverCreatorJustification = waiverHasApprover
    ? selectedWaiver.requesterReason
    : selectedWaiver?.approverReason;
  const waiverApproverJustification = selectedWaiver?.approverReason;

  return (
    <SlidePanel
      active={props.active}
      className={classNames({
        "risk-waivers-panel-new-risk-designs": orgAccessNewRiskDesigns,
        "risk-waivers-panel": !orgAccessNewRiskDesigns,
      })}
      dimContent
      onClose={props.onClose}
      title={
        !orgAccessNewRiskDesigns ? (
          <div className={"btn-group"}>
            {!showApproveRejectPanelOptions &&
              (userHasWriteAccess || !!selectedWaiver?.canWrite) && (
                <>
                  {(selectedWaiver?.status ===
                    OrganisationAcceptedRiskStatus.Active ||
                    selectedWaiver?.status ===
                      OrganisationAcceptedRiskStatus.AwaitingApproval) &&
                    props.onEdit && (
                      <Button onClick={props.onEdit}>
                        <i className={"cr-icon-pencil"} />
                        Edit waiver
                      </Button>
                    )}
                  <Button danger onClick={props.onDelete}>
                    <i className={"cr-icon-trash-2"} />
                    Delete
                  </Button>
                </>
              )}
            {showApproveRejectPanelOptions && (
              <>
                <Button
                  onClick={() => {
                    props.onApprove(true);
                  }}
                >
                  <i className={"cr-icon-accepted"} />
                  Approve
                </Button>
                <Button
                  danger
                  onClick={() => {
                    props.onApprove(false);
                  }}
                >
                  <i className={"cr-icon-exclamation"} />
                  Reject
                </Button>
              </>
            )}
          </div>
        ) : undefined
      }
      newStyles={orgAccessNewRiskDesigns}
    >
      {orgAccessNewRiskDesigns && selectedWaiver && (
        <>
          <div className="title-container">
            <h2 className="title">{selectedWaiver.riskName}</h2>
            <div className="button-container">
              {!showApproveRejectPanelOptions &&
                (userHasWriteAccess || !!selectedWaiver?.canWrite) && (
                  <>
                    {(selectedWaiver?.status ===
                      OrganisationAcceptedRiskStatus.Active ||
                      selectedWaiver?.status ===
                        OrganisationAcceptedRiskStatus.AwaitingApproval) &&
                      props.onEdit && (
                        <Button onClick={props.onEdit}>
                          <i className={"cr-icon-pencil"} />
                          Edit waiver
                        </Button>
                      )}
                    <Button danger onClick={props.onDelete}>
                      <i className={"cr-icon-trash-2"} />
                      Delete
                    </Button>
                  </>
                )}
              {showApproveRejectPanelOptions && (
                <>
                  <Button
                    onClick={() => {
                      props.onApprove(true);
                    }}
                  >
                    <i className={"cr-icon-accepted"} />
                    Approve
                  </Button>
                  <Button
                    danger
                    onClick={() => {
                      props.onApprove(false);
                    }}
                  >
                    <i className={"cr-icon-exclamation"} />
                    Reject
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="content-container">
            <ScrollableDiv newStyles>
              <BreachSightRiskWaiverPanelRow
                title="Waiver details"
                startExpanded={true}
              >
                {selectedWaiver.domainsFilteredOut && (
                  <InfoBanner message="This waiver includes other domains in portfolios you have limited or no access to." />
                )}
                <InfoTable styling={InfoTableStyling.New} bordered>
                  <InfoTableRow
                    label={"WAIVER STATUS"}
                    value={
                      <PillAcceptedRiskStatus status={selectedWaiver.status} />
                    }
                    hide={hideStatusAndUserDetails}
                  />
                  <InfoTableRow
                    label={"VISIBILITY"}
                    value={
                      <PillAcceptedRiskVisibility
                        risk={selectedWaiver}
                        canUsePublicWaivers={canUsePublicWaivers}
                      />
                    }
                    hide={!canUsePublicWaivers}
                  />
                  <InfoTableRow
                    label={"EXPIRY"}
                    value={<RiskExpires risk={selectedWaiver} />}
                  />
                  <InfoTableRow
                    label={"DATE CREATED"}
                    value={
                      <DateTimeFormat
                        dateTime={selectedWaiver.createdAt}
                        dateOnly
                      />
                    }
                  />
                  <InfoTableRow
                    hide={hideStatusAndUserDetails}
                    label={"CREATOR"}
                    value={getUserIcon(
                      props.usersMap,
                      selectedWaiver.createdBy
                    )}
                  />
                  <InfoTableRow
                    label={
                      waiverHasApprover
                        ? "JUSTIFICATION (CREATOR)"
                        : "JUSTIFICATION"
                    }
                    value={
                      <CommentDisplay
                        styling={CommentDisplayStyling.Shaded}
                        name={creatorUser?.name}
                        avatar={creatorUser?.avatar}
                        date={selectedWaiver.createdAt}
                        comment={waiverCreatorJustification}
                      />
                    }
                  />
                  <InfoTableRow
                    hide={!waiverHasApprover || hideStatusAndUserDetails}
                    label={"APPROVER"}
                    value={getApprover(props.usersMap, selectedWaiver, true)}
                  />
                  <InfoTableRow
                    hide={!waiverApproverHasDecided}
                    label={
                      waiverWasRejectedByApprover
                        ? "REASON FOR REJECTION (APPROVER)"
                        : "JUSTIFICATION (APPROVER)"
                    }
                    value={
                      <CommentDisplay
                        styling={CommentDisplayStyling.Shaded}
                        name={approverUser?.name}
                        avatar={approverUser?.avatar}
                        date={
                          selectedWaiver.activeAt || selectedWaiver.updatedAt
                        }
                        comment={waiverApproverJustification}
                      />
                    }
                  />
                  {/* TODO handle other asset types */}
                  <InfoTableRow label={"WAIVED FOR"} value={assetsList} />
                </InfoTable>
              </BreachSightRiskWaiverPanelRow>
              <BreachSightRiskWaiverPanelRow
                title="Risk information"
                startExpanded={true}
              >
                <InfoTable styling={InfoTableStyling.New} bordered>
                  <InfoTableRow
                    label={"SUMMARY"}
                    value={
                      <RiskDescription
                        risk={{
                          riskType: VendorSummaryRiskType.Cloudscan,
                          description: selectedWaiver.riskDescription,
                          summary: selectedWaiver.riskSummary,
                          riskDetails: selectedWaiver.riskDetails,
                          recommendedRemediation:
                            selectedWaiver.riskRecommendedRemediation,
                        }}
                        orgAccessNewRiskDesigns={true}
                      />
                    }
                  />
                  <InfoTableRow
                    label={"RISK"}
                    value={<p>{selectedWaiver.riskDetails}</p>}
                    hide={!selectedWaiver.riskDetails}
                  />
                  <InfoTableRow
                    label={"REMEDIATION"}
                    value={<p>{selectedWaiver.riskRecommendedRemediation}</p>}
                    hide={!selectedWaiver.riskRecommendedRemediation}
                  />
                </InfoTable>
              </BreachSightRiskWaiverPanelRow>
            </ScrollableDiv>
          </div>
        </>
      )}
      {!orgAccessNewRiskDesigns && selectedWaiver && (
        <>
          {!hideStatusAndUserDetails ? (
            <PillAcceptedRiskStatus status={selectedWaiver.status} />
          ) : (
            <></>
          )}
          <br />
          <h2>{selectedWaiver.riskName}</h2>
          <div
            className={"risk-desc"}
            dangerouslySetInnerHTML={{
              __html: selectedWaiver.riskDescription,
            }}
          />
          {selectedWaiver.domainsFilteredOut && (
            <InfoBanner message="This waiver includes other domains in portfolios you have limited or no access to." />
          )}
          <InfoTable styling={InfoTableStyling.New} bordered>
            <InfoTableRow
              label={"Visibility"}
              value={
                <PillAcceptedRiskVisibility
                  risk={selectedWaiver}
                  canUsePublicWaivers={canUsePublicWaivers}
                />
              }
              hide={!canUsePublicWaivers}
            />
            <InfoTableRow
              label={"Expiry"}
              value={<RiskExpires risk={selectedWaiver} />}
            />
            <InfoTableRow
              label={"Date created"}
              value={
                <DateTimeFormat dateTime={selectedWaiver.createdAt} dateOnly />
              }
            />
            <InfoTableRow
              hide={hideStatusAndUserDetails}
              label={"Creator"}
              value={getUserIcon(props.usersMap, selectedWaiver.createdBy)}
            />
            <InfoTableRow
              hide={
                !(selectedWaiver.approverId || selectedWaiver.approverEmail) ||
                hideStatusAndUserDetails
              }
              label={"Approver"}
              value={getApprover(props.usersMap, selectedWaiver, true)}
            />
            <InfoTableRow
              hide={!selectedWaiver.requesterReason}
              label={"Why this risk was waived"}
              value={
                <CommentDisplay
                  styling={CommentDisplayStyling.Shaded}
                  name={creatorUser?.name}
                  avatar={creatorUser?.avatar}
                  date={selectedWaiver.createdAt}
                  comment={selectedWaiver.requesterReason}
                />
              }
            />
            <InfoTableRow
              hide={
                (selectedWaiver.status !== "active" &&
                  selectedWaiver.status !== "rejected") ||
                !selectedWaiver.approverReason
              }
              label={
                selectedWaiver.status === "rejected"
                  ? "Reason for rejection"
                  : selectedWaiver.approverId
                    ? "Justification (Approver)"
                    : "Why this risk was waived"
              }
              value={
                <CommentDisplay
                  styling={CommentDisplayStyling.Shaded}
                  name={approverUser ? approverUser.name : creatorUser?.name}
                  avatar={
                    approverUser ? approverUser.avatar : creatorUser?.avatar
                  }
                  date={selectedWaiver.activeAt || selectedWaiver.updatedAt}
                  comment={selectedWaiver.approverReason}
                />
              }
            />
            {/* TODO handle other asset types */}
            <InfoTableRow label={"Waived for"} value={assetsList} />
          </InfoTable>
        </>
      )}
    </SlidePanel>
  );
}

export function PillAcceptedRiskStatus(props: {
  status: OrganisationAcceptedRiskStatus;
}) {
  switch (props.status) {
    case "active": {
      return <PillLabel color={LabelColor.Blue}>Active</PillLabel>;
    }

    case "awaitingapproval": {
      return <PillLabel color={LabelColor.Yellow}>Awaiting approval</PillLabel>;
    }
    case "expired": {
      return <PillLabel color={LabelColor.Red}>Expired</PillLabel>;
    }
    case "rejected": {
      return <PillLabel color={LabelColor.Red}>Rejected</PillLabel>;
    }
    case "cancelled": {
      return <PillLabel color={LabelColor.Grey}>Cancelled</PillLabel>;
    }

    default:
      return <PillLabel color={LabelColor.Grey}>Inactive</PillLabel>;
  }
}

export function PillAcceptedRiskVisibility(props: {
  risk: OrganisationAcceptedRisk;
  canUsePublicWaivers: boolean;
}) {
  if (props.risk.isPublic && props.canUsePublicWaivers) {
    return (
      <PillLabel
        color={LabelColor.Fuchsia}
        popupContent={
          "Anyone viewing your page in Vendor Risk will see the waiver and justification that has been provided."
        }
      >
        Public
      </PillLabel>
    );
  } else if (props.canUsePublicWaivers) {
    return (
      <PillLabel
        popupContent={"This risk waiver is only visible to your organization."}
      >
        Private
      </PillLabel>
    );
  } else {
    return <></>;
  }
}

export function RiskExpires(props: { risk: OrganisationAcceptedRisk }) {
  const risk = props.risk;

  let expiresInDays = risk.expiresAt
    ? moment(risk.expiresAt).diff(moment(), "days")
    : 0;

  if (expiresInDays < 0) {
    expiresInDays = 0;
  }

  return risk.expiresAt ? (
    <>
      {formatDateAsLocalCurrentTimezone(risk.expiresAt)}
      {(risk.status === "active" || risk.status === "awaitingapproval") &&
        expiresInDays < 14 && (
          <SidePopupV2
            className="expires-soon"
            text={`This waiver will expire in ${expiresInDays} days.`}
            noWrap
          >
            <span className={"cr-icon-clock bouncing"} />
          </SidePopupV2>
        )}
    </>
  ) : (
    <>Never</>
  );
}

const getUserIcon = (usersMap: IUserMiniMap, userId: number) => {
  const user = usersMap[userId];
  if (!user) {
    return null;
  }

  return (
    <UserDisplay avatar={user.avatar} name={user.name} email={user.email} />
  );
};

const getApprover = (
  usersMap: IUserMiniMap,
  r: OrganisationAcceptedRisk,
  showName = false
) => {
  if (r.approverId) {
    return getUserIcon(usersMap, r.approverId);
  } else if (r.approverEmail) {
    if (showName) {
      return (
        <span>
          {" "}
          {r.approverEmail} <em>(invited)</em>
        </span>
      );
    }

    return (
      <UserAvatar
        hoverPopup={
          <span>
            {r.approverEmail} <em>(invited)</em>
          </span>
        }
      />
    );
  }

  // Self approved
  return getUserIcon(usersMap, r.createdBy);
};

const getUserOrDefault = (
  usersMap: IUserMiniMap,
  userId?: number
): IUserMini | undefined => {
  if (!userId) {
    return undefined;
  }

  const user = usersMap[userId];
  if (user) {
    return user;
  } else {
    return undefined;
  }
};
