import { FC, useMemo, useState } from "react";
import userbaseApi from "../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import _ from "lodash";

import { usePagination, useSorting } from "../../_common/hooks";
import { OrgScope } from "../api/types";
import ReportCard from "../../_common/components/ReportCard";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import "./OrgPermissionsTable.scss";
import UsersTable from "./UsersTableForScope";

const OrgPermissionsTable: FC = ({}) => {
  const [searchText, setSearchText] = useState("");

  const { data, isLoading } = userbaseApi.useGetUserBaseScopesV1Query();

  const scopesToUse = data?.scopes ?? [];

  const filteredPermissions = useMemo(
    () =>
      searchText.length > 1
        ? scopesToUse.filter(
            (a) =>
              a.description.toLowerCase().indexOf(searchText.toLowerCase()) >
                -1 ||
              a.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1
          )
        : scopesToUse,
    [data, searchText]
  );

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "exposure_level",
      text: "Exposure level",
      sortable: true,
    },
    {
      id: "permission_name",
      text: "Description",
      sortable: true,
    },
    {
      id: "num_users_granted_permission",
      text: "Users",
      sortable: true,
    },
  ];

  const [sortedPermissions, sortedBy, onSortChange] = useSorting<
    OrgScope,
    "permission_name" | "exposure_level" | "num_users_granted_permission"
  >(filteredPermissions, "num_users_granted_permission", SortDirection.DESC, {
    permission_name: {
      orderFuncs: [
        (p) => p.description.toLocaleLowerCase(),
        (p) => p.name.toLocaleLowerCase(),
      ],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.DESC],
    },
    exposure_level: {
      orderFuncs: [(p) => p.riskLevel],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    num_users_granted_permission: {
      orderFuncs: [(p) => p.userUUIDs.length],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedPermissions,
    100
  );

  // Keep track of which users' rows are expanded
  const [expanded, setExpanded] = useState<Set<string>>(new Set());

  const toggleRowExpansion = (s: OrgScope) => {
    const newExpanded = _.clone(expanded);
    if (newExpanded.has(s.name)) {
      newExpanded.delete(s.name);
    } else {
      newExpanded.add(s.name);
    }
    setExpanded(newExpanded);
  };

  const rows = pageItems.map((scope: OrgScope): IXTableRow<string> => {
    return {
      id: scope.name,
      onClick: () => toggleRowExpansion(scope),
      expanded: expanded.has(scope.name),
      expandContent: <UsersTable userUUIDs={scope.userUUIDs} scope={scope} />,
      cells: [
        <XTableCell key={"riskLevel"}>
          <ScopeRiskLevelDisplay riskLevel={scope.riskLevel} />
        </XTableCell>,
        <XTableCell className="description-with-subtext" key="permission">
          <div className="description-maintext">{scope.description}</div>
          <div className="description-subtext">{scope.name}</div>
        </XTableCell>,
        <XTableCell key={"numApps"}>{scope.userUUIDs.length}</XTableCell>,
      ],
    };
  });

  return (
    <div className="org-permissions-table">
      <ReportCard newStyles>
        <SearchBox
          onChanged={(v) => setSearchText(v)}
          value={searchText}
          disabled={isLoading}
        />
        {!isLoading &&
        searchText.length > 0 &&
        sortedPermissions.length == 0 ? (
          <SearchEmptyCard
            onClear={() => setSearchText("")}
            searchItemText={"permission"}
          />
        ) : (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            expandableRows
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default OrgPermissionsTable;
