import userbaseApi, { UpdateReviewedAppV1Req } from "../api/userbase.api";
import { App, AppStatus } from "../api/types";

import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import Button from "../../_common/components/core/Button";
import TextField, { MaxLengthType } from "../../_common/components/TextField";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";

import { useState } from "react";

interface IAppInReviewModalProps extends BaseModalProps {
  app: App;
}

const AppInReviewModal = ({ active, onClose, app }: IAppInReviewModalProps) => {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const [note, setNote] = useState("");
  const [updateReviewedAppV1] =
    userbaseApi.useUpdateUserBaseReviewedAppV1Mutation();

  const onNoteChange = (value: string) => {
    setNote(value);
  };

  const onSubmit = () => {
    setIsUpdating(true);
    const updateReq: UpdateReviewedAppV1Req = {
      appName: app.name,
      approvedForAll: false,
      status: AppStatus.InReviewAppStatus,
      note: note,
    };

    updateReviewedAppV1(updateReq)
      .unwrap()
      .then(() => {
        dispatch(addDefaultSuccessAlert(`${app.name} marked as in review`));
      })
      .catch(() =>
        dispatch(
          addDefaultUnknownErrorAlert("Error updating application approval")
        )
      )
      .finally(() => {
        setIsUpdating(false);
        onClose();
      });
  };

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className={"app-approval-modal"}
        headerContent={`Mark ${app.name} as in review`}
        footerClassName={"app-approval-modal-footer"}
        footerContent={
          <>
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
            <Button filledPrimary onClick={onSubmit} loading={isUpdating}>
              Confirm
            </Button>
          </>
        }
      >
        <div className={"description"}>
          Are you sure you want to mark {app.name} as in review?
        </div>
        <>
          <div className={"note"}>
            <div>
              <div className={"header"}>Add note (optional)</div>
              <div className={"sub-header"}>
                This will appear in the timeline log
              </div>
            </div>

            <TextField
              value={note ?? ""}
              onChanged={(val) => onNoteChange(val)}
              multiLine
              placeholder="Enter a note"
              maxLength={MaxLengthType.reasonableLength}
            />
          </div>
        </>
      </ModalV2>
    </>
  );
};

export default AppInReviewModal;
