import { DefaultRouteProps, useBack } from "../../_common/types/router";
import { UserRouteParams } from "../UserBaseNavItems";
import { FC, useCallback, useState } from "react";
import userbaseApi from "../api/userbase.api";
import UserBaseUserHeader from "../components/UserBaseUserHeader";
import UserScoresChart from "../components/UserScoresChart";
import {
  createRiskModificationUrl,
  userRiskProfileUrl,
  usersUrl,
  userUrl,
  userUrlPrefix,
} from "../UserBaseAppRouter";
import RiskSummaryTable from "../../vendorrisk/components/RiskSummaryTable";
import { useHistory } from "react-router-dom";
import {
  useHasUserOrPortfolioPermissions,
  UserUserBaseWrite,
} from "../../_common/permissions";
import { trackEvent } from "../../_common/tracking";
import { IRisk } from "../../vendorrisk/components/RiskSummaryDetails";
import { CreateRiskModificationLocationState } from "./CreateRiskModificationView";
import "./UserRiskProfileView.scss";

type UserRiskProfileViewProps = DefaultRouteProps<UserRouteParams>;

const UserRiskProfileView: FC<UserRiskProfileViewProps> = ({ match }) => {
  const history = useHistory();

  const userUUID = match.params.userUUID;

  const { data: userSummary } = userbaseApi.useGetUserBaseUserSummaryV1Query({
    userUUID,
  });
  const { data: userRiskProfile, isLoading: userRiskProfileLoading } =
    userbaseApi.useGetUserBaseUserRiskProfileV1Query({
      userUUID,
    });
  const { data: riskWaiverData, isLoading: isRiskWaiversLoading } =
    userbaseApi.useGetUserBaseRiskWaiversV1Query();

  const userHasUserBaseWrite =
    useHasUserOrPortfolioPermissions(UserUserBaseWrite);

  const [includePassedRisks, setIncludePassedRisks] = useState(false);
  const [showWaivedRisks, setShowWaivedRisks] = useState(false);

  const { backAction, backText } = useBack();

  const getUrlPrefix = () => `${userUrlPrefix}/${userUUID}`;

  const onIncludePassedRisk = (val: boolean) => {
    setIncludePassedRisks(val);
    trackEvent("UserBase_UserRiskProfile_IncludePassedRisks_Toggled", {
      value: val,
    });
  };

  const onShowWaivedRisks = (val: boolean) => {
    setShowWaivedRisks(val);
    trackEvent("UserBase_UserRiskProfile_ShowWaivedRisks_Toggled", {
      value: val,
    });
  };

  const onCreateWaiverForRisk = useCallback(
    (risk: IRisk) => {
      const context: CreateRiskModificationLocationState = {
        riskId: risk.id,
        userUUID: userUUID,
      };

      history.push(createRiskModificationUrl, {
        backContext: {
          goBack: true,
          backToText: `Back to Risk Profile`,
        },
        ...context,
      });
    },
    [history]
  );

  const userRiskWaivers = (riskWaiverData?.waivers ?? []).filter((w) =>
    w.userInfos.some((ui) => ui.uuid === userUUID)
  );

  return (
    <div className="user-profile">
      <UserBaseUserHeader
        breadcrumbs={[
          { text: "Users", to: usersUrl },
          {
            text: userSummary?.user.name ?? "User",
            to: userUrl(userUUID),
          },
          {
            text: "Risk Profile",
            to: userRiskProfileUrl(userUUID),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Risk Profile"}
      />

      <UserScoresChart userUUID={userUUID} />

      <RiskSummaryTable
        className={"user-base-user-risk-profile"}
        history={history}
        loading={userRiskProfileLoading || isRiskWaiversLoading}
        title="Risk details"
        risks={userRiskProfile?.risks ?? []}
        filterActive={false}
        urlPrefix={getUrlPrefix}
        canManageVendorRiskWaivers={userHasUserBaseWrite}
        userHasWriteRiskWaiversPermission={userHasUserBaseWrite}
        hideShowWaivedRisksOption={false}
        hideShowPassedChecksOption={false}
        includePassedRisks={includePassedRisks}
        setIncludePassedRisks={onIncludePassedRisk}
        onCreateRiskWaiver={onCreateWaiverForRisk}
        showWaivedRisks={showWaivedRisks}
        onShowWaivedRisks={onShowWaivedRisks}
        customerRiskWaivers={userRiskWaivers}
        expandable={false}
      />
    </div>
  );
};

export default UserRiskProfileView;
