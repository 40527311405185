import { LabelColor } from "../../_common/types/label";
import { AppStatus } from "../api/types";

interface ColorStyles {
  labelColor: LabelColor;
  hexColor: string;
}

// NOTE: Hex colors source from _variables.scss
const appStatusColors: Map<AppStatus, ColorStyles> = new Map([
  [
    AppStatus.ApprovedAppStatus,
    {
      labelColor: LabelColor.Green,
      hexColor: "#20af5d",
    },
  ],
  [
    AppStatus.NotApprovedAppStatus,
    {
      labelColor: LabelColor.Red,
      hexColor: "#ef1700",
    },
  ],
  [
    AppStatus.InReviewAppStatus,
    {
      labelColor: LabelColor.Grey,
      hexColor: "#595c62",
    },
  ],
  [
    AppStatus.NeedsReviewAppStatus,
    {
      labelColor: LabelColor.Orange,
      hexColor: "#ff9901",
    },
  ],
]);

export function labelColorForAppStatus(status: AppStatus): LabelColor {
  return appStatusColors.get(status)?.labelColor ?? LabelColor.Black;
}

export function hexColorForAppStatus(status: AppStatus): string {
  return appStatusColors.get(status)?.hexColor ?? "#FFFFFF";
}
