import ModalV2, { BaseModalProps } from "../ModalV2";
import { SurveyUser } from "../../types/survey";
import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../types/reduxHooks";
import surveyAPI from "../../../vendorrisk/reducers/surveyAPI";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import Button from "../core/Button";
import ModalForm from "../../../vendorrisk/components/modals/ModalForm";
import UsersAPI from "../../api/usersAPI";
import "../../style/components/surveydetails/SurveyDetailsInviteCollaboratorModal.scss";
import CustomSelect, { OptionItem, SelectItemType } from "../CustomSelect";
import { UserAvatarAndName } from "../UserAvatar";
import { Surface } from "../Surface";
import TextField, { MaxLengthType, useTextWithValid } from "../TextField";
import { getGravatarURL } from "../../helpers";
import { fetchOrganisationDefaultTexts } from "../../../vendorrisk/reducers/orgDefaultTexts.actions";
import { DefaultTextType } from "../../types/orgDefaultTexts";

interface SurveyDetailsInviteCollaboratorModalProps extends BaseModalProps {
  surveyID: number;
  collaborators: SurveyUser[];
}

const SurveyDetailsInviteCollaboratorModal: FC<
  SurveyDetailsInviteCollaboratorModalProps
> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOrganisationDefaultTexts());
  }, []);

  type contactToAdd = {
    name: string;
    emailAddress: string;
    avatar: string;
  };

  const [contactsToAdd, setContactsToAdd] = useState<contactToAdd[]>([]);
  const [contactSurfaceActive, setContactSurfaceActive] = useState(false);
  const [newContactName, newContactNameValid, setNewContactName] =
    useTextWithValid("");
  const [newContactEmail, newContactEmailValid, setNewContactEmail] =
    useTextWithValid("");

  const defaultTexts = useAppSelector(
    (state) => state.cyberRisk.orgDefaultTexts
  );
  useEffect(() => {
    if (
      defaultTexts &&
      defaultTexts[DefaultTextType.QuestionnaireCollaboratorMessage]
    ) {
      setMessage(
        defaultTexts[DefaultTextType.QuestionnaireCollaboratorMessage]
          ?.defaultText,
        true
      );
    }
  }, [defaultTexts]);

  const [message, messageValid, setMessage] = useTextWithValid("");

  const { data: orgUsers } = UsersAPI.useListOrganisationUsersV1Query();
  const currentUser = useAppSelector((state) => state.common.userData);

  const filteredUsers = useMemo(() => {
    if (orgUsers) {
      return orgUsers.users.filter(
        (u) =>
          u.roles.some((r) =>
            ["VendorRiskFull", "VendorRiskReadOnly"].includes(r)
          ) &&
          !Object.values(u.portfolioSpecificRoles)
            .reduce((roles, p) => {
              roles.push(...p.r);
              return roles;
            }, [] as string[])
            .some((r) =>
              ["VendorRiskFull", "VendorRiskReadOnly"].includes(r)
            ) &&
          u.emailAddress != currentUser?.emailAddress &&
          !contactsToAdd.find((c) => c.emailAddress == u.emailAddress) &&
          !props.collaborators.find((c) => c.email == u.emailAddress)
      );
    }
    return [];
  }, [orgUsers, contactsToAdd]);

  const [addCollaboratorMutation] = surveyAPI.useAddCollaboratorMutation();

  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    if (!contactsToAdd || contactsToAdd.length < 1) {
      return;
    }

    setLoading(true);

    return addCollaboratorMutation({
      surveyId: props.surveyID,
      usersToAdd: contactsToAdd.map((c) => c.emailAddress),
      message,
    })
      .unwrap()
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error adding collaborator to questionnaire"
          )
        );
      })
      .then(() => {
        dispatch(addDefaultSuccessAlert("Added collaborator to questionnaire"));
        props.onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const options: OptionItem[] = filteredUsers.map((c) => ({
    id: c.id,
    onClick: () =>
      setContactsToAdd((prev) => [
        ...prev,
        {
          emailAddress: c.emailAddress,
          name: c.name,
          avatar: c.avatar,
        },
      ]),
    type: SelectItemType.Option,
    content: (
      <UserAvatarAndName
        name={c.name}
        avatar={c.avatar}
        email={c.emailAddress}
      />
    ),
  }));

  return (
    <ModalV2
      active={props.active}
      onClose={props.onClose}
      headerContent={"Add internal collaborator"}
      className={"survey-details-invite-collaborator"}
      width={800}
      footerContent={
        <>
          <Button tertiary onClick={props.onClose} disabled={loading}>
            Cancel
          </Button>
          <Button
            disabled={contactsToAdd.length == 0 || !messageValid}
            filledPrimary
            onClick={onSubmit}
            loading={loading}
          >
            Add collaborator
          </Button>
        </>
      }
    >
      <ModalForm>
        <div className={"form-section"}>
          <div className={"form-section-desc"}>
            <span>Collaborators</span>
            <p>Select from past collaborators or invite a new collaborator.</p>
          </div>
          <div className={"form-section-input users"}>
            <div className={"existing-users"}>
              {contactsToAdd.map((c) => (
                <div
                  key={c.emailAddress}
                  className={"user-card"}
                  onClick={() =>
                    setContactsToAdd((prev) =>
                      prev.filter((cc) => cc.emailAddress != c.emailAddress)
                    )
                  }
                >
                  <i className={"icon-x"} />
                  <UserAvatarAndName
                    name={c.name}
                    avatar={c.avatar}
                    email={c.emailAddress}
                  />
                </div>
              ))}
            </div>
            <div className={"select-input"}>
              <CustomSelect items={options} />
            </div>
            <div className={"or-part"}>
              <div className={"line"} />
              OR
              <div className={"line"} />
            </div>
            <div className={"invite-btn"}>
              <Button onClick={() => setContactSurfaceActive(true)}>
                <i className={"cr-icon-plus"} /> Invite new collaborator
              </Button>
              <Surface
                classNames={"new-contact-surface"}
                active={contactSurfaceActive}
              >
                <TextField
                  value={newContactName}
                  onChanged={setNewContactName}
                  required
                  placeholder={"Name"}
                />
                <TextField
                  value={newContactEmail}
                  onChanged={setNewContactEmail}
                  required
                  placeholder={"Email"}
                  type={"email"}
                />
                <div className={"buttons"}>
                  <Button
                    tertiary
                    onClick={() => {
                      setNewContactName("", false);
                      setNewContactEmail("", false);
                      setContactSurfaceActive(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!(newContactNameValid && newContactEmailValid)}
                    onClick={() => {
                      setContactsToAdd((prev) => [
                        ...prev,
                        {
                          emailAddress: newContactEmail,
                          name: newContactName,
                          avatar: getGravatarURL(newContactEmail),
                        },
                      ]);
                      setNewContactName("", false);
                      setNewContactEmail("", false);
                      setContactSurfaceActive(false);
                    }}
                  >
                    Invite
                  </Button>
                </div>
              </Surface>
            </div>
          </div>
        </div>
        <div className={"form-section"}>
          <div className={"form-section-desc"}>
            <span>Message</span>
            <p>
              This message will only be sent to new collaborators of this
              questionnaire
            </p>
          </div>
          <div className={"form-section-input"}>
            <TextField
              value={message}
              onChanged={setMessage}
              required
              placeholder={"Message"}
              maxLength={MaxLengthType.message}
              multiLine
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export default SurveyDetailsInviteCollaboratorModal;
