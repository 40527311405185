import { FC } from "react";
import UpGuardLogo from "../../images/upguard-logo-white.svg";
import UpGuardLogoIcon from "../../images/upguard-logo-icon-white.svg";
import classnames from "classnames";
import "../../style/components/navigation/TopLevelNavigation.scss";
import { pageDescriptions } from "../../pageDescriptions";
import { trackEvent } from "../../tracking";
import NavLink from "../../components/NavLink";
import { product } from "../../types/products";

export type topLevelNavigationItem =
  | product
  | "home"
  | "reports"
  | "incidents_and_news";

interface TopLevelNavigationProps {
  open?: boolean;
  selectedItem?: topLevelNavigationItem;
}

const TopLevelNavigation: FC<TopLevelNavigationProps> = (props) => {
  const clickNavLinkFunc =
    (pathTo: any, linkName: any, viewingVendorId: any) => () => {
      // then track an event
      const properties: Record<string, any> = { pathTo, linkName };
      if (viewingVendorId) {
        properties.viewingVendorId = viewingVendorId;
      }

      trackEvent("navigation menu item clicked", properties);
    };

  const getTopLevelItemIcon = (
    item: topLevelNavigationItem,
    iconName: string
  ) => {
    if (props.selectedItem === item) {
      return `${iconName}-fill`;
    }
    return `${iconName}-outline`;
  };

  return (
    <div className="top-level-navigation">
      <div className="logo-container">
        <img
          src={props.open ? UpGuardLogo : UpGuardLogoIcon}
          alt="UpGuard Logo"
          className={classnames("ug-logo", {
            open: props.open,
          })}
        />
      </div>
      <div className="navlinks-container">
        <NavLink
          className="unstyled"
          // TODO: restore the activity stream count badge
          icon={getTopLevelItemIcon("home", "home")}
          text={props.open ? "Home" : ""}
          hoverText={pageDescriptions.ActivityStream}
          exact
          onClick={clickNavLinkFunc("/", "Home", undefined)}
          to="/"
        />
        <NavLink
          className="unstyled reports-exports-nav-item"
          icon={getTopLevelItemIcon("reports", "reports")}
          text={props.open ? "Reports" : ""}
          hoverText={pageDescriptions.ReportExports}
          onClick={clickNavLinkFunc("/reportexports", "Reports", undefined)}
          to={"/reportexports"}
        />
        <NavLink
          className="unstyled"
          icon={getTopLevelItemIcon("incidents_and_news", "incidents-and-news")}
          text={props.open ? "Incidents & News" : ""}
          hoverText={pageDescriptions.BreachNewsFeed}
          onClick={clickNavLinkFunc(
            "/breachnewsfeed",
            "Incidents & News",
            undefined
          )}
          to="/breachnewsfeed"
        />
        <hr />
        <NavLink
          className={classnames("unstyled", { open: props.open })}
          icon={getTopLevelItemIcon("breachsight", "breachsight")}
          text={props.open ? "BreachSight" : ""}
          to="/risk_profile"
        />
        <NavLink
          className="unstyled"
          icon={getTopLevelItemIcon("vendor_risk", "vendor-risk")}
          text={props.open ? "Vendor Risk" : ""}
          to="/vendorlist"
        />
        <NavLink
          className="unstyled"
          icon={getTopLevelItemIcon("trust_exchange", "trust-center")}
          text={props.open ? "Trust Exchange" : ""}
          to="/trustpage"
        />
      </div>
    </div>
  );
};

export default TopLevelNavigation;
