import SlidePanel, {
  IBaseSlidePanelProps,
} from "../../../_common/components/SlidePanel";
import {
  PublicRiskWaiver,
  PublicWaiverAcceptStatus,
} from "../../types/sharedAssessment";
import Button, { TooltipButton } from "../../../_common/components/core/Button";
import InfoTable, {
  InfoTableRow,
  InfoTableStyling,
} from "../../../_common/components/InfoTable";
import DateTimeFormat from "../../../_common/components/core/DateTimeFormat";
import CommentDisplay from "../CommentDisplay";
import { FC, useEffect, useState } from "react";
import PillLabel from "../PillLabel";
import { LabelColor } from "../../../_common/types/label";
import moment from "moment";
import {
  acceptPublicRiskWaiver,
  ignorePublicRiskWaiver,
} from "../../reducers/verifiedVendors.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";
import "../../style/components/risk_waivers/PublicRiskWaiverPanel.scss";
import {
  fetchSharedAssessmentForVendor,
  openModal,
} from "../../../_common/reducers/commonActions";
import { reloadAllRemediationRequestDetails } from "../../../_common/reducers/remediationRequest.actions";
import { ConfirmationModalName } from "../../../_common/components/modals/ConfirmationModal";
import { deleteVendorRiskWaiver } from "../../reducers/vendorRiskWaiver.actions";
import {
  clearCloudscanData,
  fetchAlertsOrActivityStreamForOrgUser,
  fetchVendorSummaryAndCloudscans,
} from "../../reducers/cyberRiskActions";
import {
  fetchVendorRiskBreakdownReport,
  fetchVendorRiskOverviewReport,
} from "../../reducers/reportsActions";
import { fetchVendorPortfolioRiskProfile } from "../../reducers/vendorRiskPortfolio.actions";
import { clearDomains } from "../../reducers/domains.actions";
import { IVendorWords } from "../../../_common/constants";
import { useAppDispatch } from "../../../_common/types/reduxHooks";
import { SidePopupV2 } from "../../../_common/components/DismissablePopup";

export const getPublicRiskWaiverStatusPill = (
  waiver: PublicRiskWaiver,
  vendorName?: string
) => {
  let publicStatusPill: React.ReactNode;
  switch (waiver.status) {
    case PublicWaiverAcceptStatus.Shared:
      // No pill needed for this
      break;
    case PublicWaiverAcceptStatus.Accepted:
      publicStatusPill = (
        <PillLabel color={LabelColor.Blue}>Accepted</PillLabel>
      );
      break;
    case PublicWaiverAcceptStatus.Ignored:
      publicStatusPill = (
        <PillLabel color={LabelColor.Grey}>Rejected</PillLabel>
      );
      break;
    default:
      publicStatusPill = (
        <PillLabel color={LabelColor.Orange}>Pending</PillLabel>
      );
  }
  return (
    <>
      {publicStatusPill}
      <PillLabel
        color={LabelColor.Fuchsia}
        popupPosition={"top"}
        popupContent={
          vendorName
            ? `This waiver has been shared by ${vendorName}.`
            : undefined
        }
      >
        Shared
      </PillLabel>
    </>
  );
};

const getExpires = (r: PublicRiskWaiver) => {
  let expiresInDays = r.expiresAt
    ? moment(r.expiresAt).diff(moment(), "days")
    : 0;

  if (expiresInDays < 0) {
    expiresInDays = 0;
  }

  return r.expiresAt ? (
    <>
      <DateTimeFormat dateTime={r.expiresAt} dateOnly />
      {expiresInDays < 14 && (
        <SidePopupV2
          className="expires-soon"
          text={`This waiver will expire in ${expiresInDays} days.`}
          noWrap
        >
          <span className={"cr-icon-clock bouncing"} />
        </SidePopupV2>
      )}
    </>
  ) : (
    <em>Never</em>
  );
};

const getPopupWebsitesList = (websites: string[]) => {
  const websitesToDisplay = websites
    .slice(0, 10)
    .map((w) => <div key={w}>{w}</div>);
  if (websites.length > 10) {
    websitesToDisplay.push(
      <div key={"more"}>
        <br />
        <span>and {websites.length - 10} more...</span>
      </div>
    );
  }
  return <div>{websitesToDisplay}</div>;
};

interface PublicWaiverPanelProps extends IBaseSlidePanelProps {
  waiver?: PublicRiskWaiver;
  vendorId?: number;
  vendorName?: string;
  userHasWriteAccess?: boolean;
  vendorWords: IVendorWords;
}

const PublicWaiverPanel: FC<PublicWaiverPanelProps> = ({
  active,
  onClose,
  vendorId,
  vendorName,
  waiver,
  userHasWriteAccess,
  vendorWords,
}) => {
  const dispatch = useAppDispatch();
  const [accepting, setAccepting] = useState(false);
  const [ignoring, setIgnoring] = useState(false);

  // Reset state when active
  useEffect(() => {
    if (active) {
      setAccepting(false);
      setIgnoring(false);
    }
  }, [active]);

  const deleteWaiver = () => {
    dispatch(
      openModal(
        ConfirmationModalName,
        {
          title: "Delete Risk Waiver",
          description: "Are you sure you want to delete this risk waiver?",
          buttonText: "Delete",
          dangerousAction: true,
          buttonAction: () => {
            return dispatch(
              deleteVendorRiskWaiver(
                vendorId ?? 0,
                waiver?.acceptedVendorRiskWaiverId ?? 0
              )
            )
              .then(() => {
                onClose();

                dispatch(addDefaultSuccessAlert("Waiver deleted"));

                dispatch(fetchAlertsOrActivityStreamForOrgUser(true, true));
                dispatch(fetchVendorSummaryAndCloudscans(vendorId ?? 0, true));
                dispatch(fetchVendorRiskOverviewReport(true));
                dispatch(fetchVendorRiskBreakdownReport(true));
                dispatch(reloadAllRemediationRequestDetails());
                dispatch(clearCloudscanData());
                dispatch(fetchVendorPortfolioRiskProfile());
                dispatch(clearDomains());
                dispatch(fetchSharedAssessmentForVendor(vendorId ?? 0));
              })
              .catch(() => {
                dispatch(addDefaultUnknownErrorAlert("Error deleting waiver"));
              });
          },
        },
        true
      )
    );
  };

  return (
    <SlidePanel
      active={active}
      className="public-risk-waivers-panel"
      dimContent
      onClose={onClose}
      title={
        <div className={"btn-group"}>
          {userHasWriteAccess &&
            waiver?.status !== PublicWaiverAcceptStatus.Accepted && (
              <>
                {(waiver?.status === PublicWaiverAcceptStatus.Pending ||
                  PublicWaiverAcceptStatus.Ignored) && (
                  <TooltipButton
                    loading={accepting}
                    popupPosition={"bottom"}
                    tooltipContent={
                      <>
                        Accepting this waiver will <b>remove</b> this risk from
                        the {vendorWords.possessive} risk profile
                      </>
                    }
                    onClick={() => {
                      setAccepting(true);
                      dispatch(
                        acceptPublicRiskWaiver(vendorId ?? 0, waiver?.id ?? 0)
                      )
                        .then(() => {
                          dispatch(
                            addDefaultSuccessAlert(
                              `Public risk waiver accepted.`
                            )
                          );
                          onClose();
                        })
                        .catch(() => {
                          setAccepting(false);
                          dispatch(
                            addDefaultUnknownErrorAlert(
                              "Error accepting risk waiver"
                            )
                          );
                        });
                    }}
                  >
                    <i className={"cr-icon-check"} />
                    Accept waiver
                  </TooltipButton>
                )}
                {waiver?.status === PublicWaiverAcceptStatus.Pending && (
                  <TooltipButton
                    danger
                    loading={ignoring}
                    popupPosition={"bottom"}
                    tooltipContent={
                      <>
                        Rejecting this waiver will <b>add</b> this risk to the{" "}
                        {vendorWords.possessive} risk profile
                      </>
                    }
                    onClick={() => {
                      setIgnoring(true);
                      dispatch(
                        ignorePublicRiskWaiver(
                          vendorId ?? 0,
                          waiver?.id ?? 0,
                          waiver?.status !== PublicWaiverAcceptStatus.Ignored
                        )
                      )
                        .then(() => {
                          dispatch(
                            addDefaultSuccessAlert(
                              `Public risk waiver rejected.`
                            )
                          );
                          onClose();
                        })
                        .catch(() => {
                          setIgnoring(false);
                          dispatch(
                            addDefaultUnknownErrorAlert(
                              "Error ignoring risk waiver"
                            )
                          );
                        });
                    }}
                  >
                    <i className={"cr-icon-minus-circle"} />
                    Reject waiver
                  </TooltipButton>
                )}
              </>
            )}
          {userHasWriteAccess &&
            waiver?.status === PublicWaiverAcceptStatus.Accepted &&
            waiver?.acceptedVendorRiskWaiverId && (
              <Button danger onClick={deleteWaiver}>
                <i className={"cr-icon-trash-2"} />
                Delete
              </Button>
            )}
        </div>
      }
    >
      {waiver && (
        <>
          {getPublicRiskWaiverStatusPill(waiver, vendorName)}
          <br />
          <h2>{waiver.riskName}</h2>
          <div
            className={"risk-desc"}
            dangerouslySetInnerHTML={{
              __html: waiver.riskText,
            }}
          />
          <InfoTable styling={InfoTableStyling.New} bordered>
            <InfoTableRow label={"Expiry"} value={getExpires(waiver)} />
            <InfoTableRow
              label={"Date created"}
              value={<DateTimeFormat dateTime={waiver.createdAt} dateOnly />}
            />
            <InfoTableRow
              label={"Justification"}
              singleCol
              value={
                <CommentDisplay
                  date={waiver.createdAt}
                  comment={waiver.justification}
                  name={vendorName}
                />
              }
            />
            <InfoTableRow
              label={"Waived for"}
              value={getPopupWebsitesList(waiver.domains)}
            />
          </InfoTable>
        </>
      )}
    </SlidePanel>
  );
};

export default PublicWaiverPanel;
