import ImgImproveSecurityPosture from "../../_common/images/plg-onboarding/improve-security-posture.png";
import ImgLeaksDetectedBySource from "../../_common/images/plg-onboarding/leaks-detected-by-source.png";
import ImgProjectedRating from "../../_common/images/plg-onboarding/projected-rating.png";
import ImgRequestRemediation from "../../_common/images/plg-onboarding/request-remediation.png";
import ImgRiskManagement from "../../_common/images/plg-onboarding/risk-management.png";
import ImgSecurityQuestionnaires from "../../_common/images/plg-onboarding/security-questionnaires.png";
import ImgShareFindings from "../../_common/images/plg-onboarding/share-findings.png";
import ImgTrustPage from "../../_common/images/plg-onboarding/trust-page.png";
import ImgUpGuardOrbit from "../../_common/images/plg-onboarding/upguard-orbit.png";
import ImgVendorGrid from "../../_common/images/plg-onboarding/vendor-grid.png";
import ImgVendorOverview from "../../_common/images/plg-onboarding/vendor-overview.png";
import ImgVendorReports from "../../_common/images/plg-onboarding/vendor-reports.png";
import ImgVendorRiskSummary from "../../_common/images/plg-onboarding/vendor-risk-summary.png";
import ImgSecurityRating from "../../_common/images/plg-onboarding/security-rating.png";

// Adding new tasks or checklists? Make sure you add their ids to the validator(s) in
// api/accountapi/plg_onboarding_checklist_v1.go
const OnboardingTasks = {
  BreachSight: {
    RiskProfile: {
      id: "Checklist_BreachSight_Risk_Profile",
      title: "Check your risk profile",
      description:
        "Instantly understand your organization’s security posture and monitor changes over time.",
      buttonText: "Visit risk profile",
      buttonUrl: "/risk_profile",
      img: ImgProjectedRating,
    },
    Rescan: {
      id: "Checklist_BreachSight_Rescan",
      title: "Rescan a domain on-demand",
      description:
        "We scan your domains daily, but you can use on-demand rescanning to detect and respond to changes instantly.",
      buttonText: "Rescan a domain",
      buttonUrl: "/webscans",
      img: ImgSecurityRating,
    },
    Report: {
      id: "Checklist_BreachSight_Report",
      title: "Share findings with stakeholders",
      description:
        "Our reports provide a detailed overview of the key factors impacting your security posture.",
      buttonText: "Generate your first report",
      buttonUrl: "/summaryreport/breachsight",
      img: ImgShareFindings,
    },
    IdentityBreaches: {
      id: "Checklist_BreachSight_Identity_Breaches",
      title: "Check for exposed credentials",
      description:
        "Identity breaches helps you identify and alert employees who have had their credentials exposed in a third-party data breach.",
      buttonText: "View identity breaches",
      buttonUrl: "/email_exposures",
      img: ImgLeaksDetectedBySource,
    },
    Remediation: {
      id: "Checklist_BreachSight_Remediation",
      title: "Improve your security posture",
      description:
        "Use our remediation planner to select risks to remediate, see the impact on your security rating in real time, and track progress.",
      buttonText: "Request remediation",
      buttonUrl: "/selfremediation/add",
      img: ImgImproveSecurityPosture,
    },
    AddDomains: {
      id: "Checklist_BreachSight_Add_Domains",
      title: "Add your domains",
      description:
        "We’ve automatically added your domains based on an analysis of your external attack surface.",
      buttonText: "Domains added",
      buttonUrl: "/webscans",
      img: ImgUpGuardOrbit,
    },
  },
  TrustExchange: {
    Profile: {
      id: "Checklist_Trust_Exchange_Profile",
      title: "Publish your Trust Page",
      description:
        "Proactively communicate your security posture and spend less time responding to requests for information.",
      buttonText: "Publish Trust Page",
      buttonUrl: "/trustpage",
      img: ImgTrustPage,
    },
  },
  VendorRisk: {
    Monitor: {
      id: "Checklist_VendorRisk_Monitor",
      title: "Monitor your first vendor",
      description:
        "Get real-time insights into the security posture and risks of your vendors, and start monitoring new vendors instantly.",
      buttonText: "Monitor first vendor",
      buttonUrl: "/vendorlist",
      img: ImgVendorGrid,
    },
    Tier: {
      id: "Checklist_VendorRisk_Tier",
      title: "Tier your vendors",
      description:
        "Tier your vendors based on their criticality to business operations, data access, compliance requirements, or access to your organization.",
      buttonText: "Tier your vendors",
      buttonUrl: "/vendorlist",
      img: ImgVendorOverview,
    },
    Profile: {
      id: "Checklist_VendorRisk_Profile",
      title: "View a vendor's risk summary",
      description:
        "Get an executive-level overview of a vendor's security posture and gathered documentation like risk assessments, questionnaires, and additional evidence.",
      buttonText: "Visit risk profile",
      buttonUrl: "/vendorlist",
      img: ImgVendorRiskSummary,
    },
    Report: {
      id: "Checklist_VendorRisk_Report",
      title: "Export your first vendor report",
      description:
        "Our vendor risk reports provide a detailed overview of the key risks that a vendor poses.",
      buttonText: "Generate your first report",
      buttonUrl: "/vendorlist",
      img: ImgVendorReports,
    },
    Assessment: {
      id: "Checklist_VendorRisk_Assessment",
      title: "Publish your first risk assessment",
      description:
        "Improve your risk assessments with our auditable end-to-end workflows. Follow clear, guided steps to build in-depth assessments without blind spots.",
      buttonText: "Launch your risk assessment",
      buttonUrl: "/vendorlist",
      img: ImgRiskManagement,
    },
    Questionnaire: {
      id: "Checklist_VendorRisk_Questionnaire",
      title: "Send a security questionnaire",
      description:
        "Use industry-leading automation and a library of questionnaires — including NIST, SIG, and ISO — to get vendor responses with 90% less manual labor.",
      buttonText: "Send security questionnaire",
      buttonUrl: "/surveys/create",
      img: ImgSecurityQuestionnaires,
    },
    Remediation: {
      id: "Checklist_VendorRisk_Remediation",
      title: "Request remediation from a vendor",
      description:
        "Rely on our powerful remediation workflow to identify risks, track progress, and get notified when issues are fixed.",
      buttonText: "Request remediation",
      buttonUrl: "/remediation/add",
      img: ImgRequestRemediation,
    },
  },
};

const ChecklistTitles = {
  BreachSight: "Assess your attack surface",
  VendorRisk: "Manage your third party risk",
  TrustExchange: "Join the Trust Exchange", //placeholder until we actually use this sometime
};

export const PLGOnboardingChecklistContent = [
  {
    checklistName: "BreachSight",
    title: ChecklistTitles.BreachSight,
    tasks: [
      OnboardingTasks.BreachSight.RiskProfile,
      OnboardingTasks.BreachSight.Rescan,
      OnboardingTasks.BreachSight.Report,
      OnboardingTasks.BreachSight.IdentityBreaches,
      OnboardingTasks.BreachSight.Remediation,
      OnboardingTasks.BreachSight.AddDomains,
    ],
    numTasks: 6,
    defaultSelected: OnboardingTasks.BreachSight.RiskProfile,
  },
  {
    checklistName: "BreachSightReadOnly",
    title: ChecklistTitles.BreachSight,
    tasks: [
      OnboardingTasks.BreachSight.RiskProfile,
      OnboardingTasks.BreachSight.AddDomains,
    ],
    numTasks: 2,
    defaultSelected: OnboardingTasks.BreachSight.RiskProfile,
  },
  {
    checklistName: "BreachSightNoIDBreaches",
    title: ChecklistTitles.BreachSight,
    tasks: [
      OnboardingTasks.BreachSight.RiskProfile,
      OnboardingTasks.BreachSight.Rescan,
      OnboardingTasks.BreachSight.Report,
      OnboardingTasks.BreachSight.Remediation,
      OnboardingTasks.BreachSight.AddDomains,
    ],
    numTasks: 5,
    defaultSelected: OnboardingTasks.BreachSight.RiskProfile,
  },
  {
    checklistName: "VendorRisk",
    title: ChecklistTitles.VendorRisk,
    tasks: [
      OnboardingTasks.VendorRisk.Monitor,
      OnboardingTasks.VendorRisk.Tier,
      OnboardingTasks.VendorRisk.Profile,
      OnboardingTasks.VendorRisk.Report,
      OnboardingTasks.VendorRisk.Assessment,
      OnboardingTasks.VendorRisk.Questionnaire,
      OnboardingTasks.VendorRisk.Remediation,
    ],
    numTasks: 7,
    defaultSelected: OnboardingTasks.VendorRisk.Monitor,
  },
  {
    checklistName: "VendorRiskReadOnly",
    title: ChecklistTitles.VendorRisk,
    tasks: [OnboardingTasks.VendorRisk.Profile],
    numTasks: 1,
    defaultSelected: OnboardingTasks.VendorRisk.Profile,
  },
  {
    checklistName: "VendorRiskNoAssessments",
    title: ChecklistTitles.VendorRisk,
    tasks: [
      OnboardingTasks.VendorRisk.Monitor,
      OnboardingTasks.VendorRisk.Tier,
      OnboardingTasks.VendorRisk.Profile,
      OnboardingTasks.VendorRisk.Report,
      OnboardingTasks.VendorRisk.Questionnaire,
      OnboardingTasks.VendorRisk.Remediation,
    ],
    numTasks: 6,
    defaultSelected: OnboardingTasks.VendorRisk.Monitor,
  },
  {
    checklistName: "TrustExchange",
    title: ChecklistTitles.TrustExchange,
    tasks: [OnboardingTasks.TrustExchange.Profile],
    numTasks: 1,
    defaultSelected: OnboardingTasks.TrustExchange.Profile,
  },
];
