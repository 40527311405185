import { produce } from "immer";
import {
  debounce as _debounce,
  get as _get,
  isEqual as _isEqual,
  pickBy as _pickBy,
  set as _set,
  find as _find,
} from "lodash";
import moment from "moment";
import { FetchCyberRiskUrl, stringifyQuery } from "../../_common/api";
import {
  DateStringToUnixDateWithoutTimezone,
  getCustomerOrgNameFromState,
  hashCode,
  ipIsLocal,
  ipIsZeroes,
  isIPAddress,
  LogError,
  MapArrayToUnixDateWithoutTimezone,
  severityMap,
  validateUrlOrIp,
} from "../../_common/helpers";
import {
  fetchPrefilledSurveyDetails,
  loadUserOrg,
  openModal,
  setCurrentOrgName,
  setCyberRiskAuthInfo,
  setRemediationRequestData,
  setSurveyData,
  setModalData,
  fetchUserInitiatedExports,
  grabTPVMSession,
  reloadCompleteActivityStream,
  conditionalRefreshActivityStreamForOrgUser,
  setProjectionForRemediationRequest,
  setProjectionForSurvey,
  setProjectionRequestTimestamp,
  clearRemediationRequestData,
} from "../../_common/reducers/commonActions";
import {
  fetchSurveyMessages,
  fetchSurveyDetails,
  fetchSurveyReminders,
  fetchSurveyTimeline,
} from "../../_common/reducers/surveyDetails.actions";
import {
  clearLocalStorageItem,
  getLocalStorageItem,
  setLocalStorageItem,
} from "../../_common/session";
import { trackEvent } from "../../_common/tracking";
import * as ApiResponses from "../constants/apiResponses";
import * as ReportTypes from "../constants/reportTypes";
import {
  fetchBreachSightCompetitorAnalysisReport,
  fetchBreachSightRiskBreakdownReport,
  fetchVendorRiskBreakdownReport,
  fetchVendorRiskOverviewReport,
  fetchVendorRiskSupplyChainReport,
  fetchBreachSightOverviewReport,
  fetchBreachSightSubsidiariesScoreDistributionReport,
  fetchBreachSightSubsidiaryScoreRatingsReport,
  fetchBreachSightSubsidiariesRiskBreakdownReport,
  fetchBreachSightSubsidiariesOverviewReport,
  fetchBreachSightSubsidiariesCompetitorReport,
  fetchBreachSightSubsidiariesOrganisationAnalysisReport,
  fetchVendorRiskRiskMatrixReport,
  refreshVendorRiskMatrix,
} from "./reportsActions";
import {
  getOAuthConnectionData,
  getOAuth2MessagingChannelData,
  getPopulatedMessagingExpected,
} from "./cyberRiskSelectors";
import { stringify } from "querystring";
import {
  clearCustomerIPAddresses,
  clearVendorIPAddresses,
} from "./ipAddresses.actions";
import { clearDomains, fetchDomains } from "./domains.actions";
import { surveyListPageLimit } from "../views/Questionnaires";
import {
  OrgAccessScoringChangeNotification,
  OrgAccessVendorPortfolios,
} from "../../_common/permissions";
import { getDefaultFilters } from "./defaults";
import {
  getFiltersFromState,
  setSubsidiaryFilters,
  setVendorDataFiltersAndRefreshData,
  setVendorFilters,
} from "./filters.actions";
import { isAdmin } from "../helpers/roles";
import {
  fetchCustomerGeolocationReport,
  fetchCustomerWithSubsidiariesGeolocationReport,
} from "./ipGeolocation.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
  addMessageAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { BannerType } from "../components/InfoBanner";
import { fetchBreachsightSubsidiaries } from "./breachsightSubsidiaries.actions";
import { getQualifiedBreachsightSubsidiariesReportName } from "../helpers/reportTypes";
import { fetchVendorTiers } from "./vendorTiers.actions";
import {
  currentDomainPortfolioLocalStorageKey,
  currentVendorPortfolioLocalStorageKey,
  fetchDomainPortfolios,
  fetchVendorPortfolios,
} from "./portfolios.actions";
import {
  getSurveyListV2,
  refreshSurveyListsIfNecessary,
} from "./survey.actions";
import {
  containsNonAsciiCharacters,
  shouldBypassSearch,
} from "../helpers/util";
import { refreshVendorListsAfterChange } from "./vendors.actions";
import { refreshLatestVendorAssessmentForVendor } from "../../analyst_portal/reducers/analystManagedVendors.actions";
import { fetchVendorPortfolioRiskProfile } from "./vendorRiskPortfolio.actions";
import { fetchCustomerAcceptedRisks } from "./customerAcceptedRisks.actions";
import { fetchDataLeakClassifiedResultsForOrgByDateRange } from "./dataLeaks.actions";
import {
  fetchAssessmentBreakdown,
  refreshEvidenceForLatestAssessment,
} from "./vendorAssessment.actions";
import { fetchAllOrgNotifications } from "./org.actions";
import {
  fetchRemediationRequestListForAllVendors,
  fetchRemediationRequestListForSelf,
} from "./remediation.actions";
import {
  fetchAdditionalEvidenceForVendor,
  fetchAdditionalEvidenceTypes,
} from "./additionalEvidence.actions";
import { getCustomerRiskHostnames } from "./risks.actions";
import { fetchCustomerSummaryAndCloudscans } from "./customer.actions";
import { VendorAttributeSortByPrefix } from "./vendorAttributes.actions";
import { SurveyStatus } from "../../_common/types/survey";
import { setVendorManagementContacts } from "./vendorContacts.actions";
import { cyberRiskInitialState } from "./cyberRiskReducer.initialState";
import {
  clearExposuresHistoryAction,
  fetchEmailExposureHistory,
} from "./emailExposures.actions";
import { Filters } from "../components/filter/types";
import { OrgFlagType } from "../../_common/types/organisations";
import OrganisationFlagsAPI, {
  OrganisationFlagsTags,
} from "../../_common/api/organisationFlagsAPI";
import UsersAPI, { UsersTagTypes } from "../../_common/api/usersAPI";
import ApproversAPI, {
  ApproversTagTypes,
} from "../../_common/api/approversAPI";
import { setPLGTaskCompleteIfIncomplete } from "./plgOnboardingChecklistActions";

/** @typedef { import('../../_common/types/redux.ts').IBaseSingleVendorData } IBaseSingleVendorData */

export const SET_CLOUDSCAN_DATA = "CYBERRISK_SET_CLOUDSCAN_DATA";
export const SET_CLOUDSCAN_ERROR = "CYBERRISK_SET_CLOUDSCAN_ERROR";
export const CLEAR_CLOUDSCAN_DATA = "CLEAR_CLOUDSCAN_DATA";
export const SET_VULN_DATA = "CYBERRISK_SET_VULN_DATA";
export const SET_VULNS_BY_CPE = "CYBERRISK_SET_VULNS_BY_CPE";
export const SET_VULN_RISKS_IDS_BY_CVE = "CYBERRISK_SET_VULN_RISKS_IDS_BY_CVE";
export const SET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES =
  "CYBERRISK_SET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES";
export const RESET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES =
  "CYBERRISK_RESET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES";
export const SET_CUSTOMER_DATA = "CYBERRISK_SET_CUSTOMER_DATA";
export const SET_CUSTOMER_RISK_HOSTNAMES = "SET_CUSTOMER_RISK_HOSTNAMES";
export const CLEAR_ALL_DATA = "CYBERRISK_CLEAR_ALL_DATA";
export const CLEAR_CUSTOMER_DATA = "CYBERRISK_CLEAR_CUSTOMER_DATA";
export const CLEAR_VENDORS_DATA = "CYBERRISK_CLEAR_VENDORS_DATA";
export const SET_CUSTOMER_DATA_FILTERS = "CYBERRISK_SET_CUSTOMER_DATA_FILTERS";
export const RESET_VENDORS_PAGE_NUM = "CYBERRISK_RESET_VENDORS_PAGE_NUM";
export const SET_CUSTOMER_DATA_FILTER_OPTIONS =
  "CYBERRISK_SET_CUSTOMER_DATA_FILTER_OPTIONS";
export const SET_HELP_MODAL = "CYBERRISK_SET_HELP_MODAL";
export const SET_SURVEY_TYPES = "CYBERRISK_SET_SURVEY_TYPES";
export const SET_TABLE_PAGE = "CYBERRISK_SET_TABLE_PAGE";
export const SET_TABLE_SORT = "CYBERRISK_SET_TABLE_SORT";
export const SET_TOUR_STEPS = "CYBERRISK_SET_TOUR_STEPS";
export const SET_VENDOR_DATA = "CYBERRISK_SET_VENDOR_DATA";
export const SET_VENDOR_SURVEYS_DATA = "CYBERRISK_SET_VENDOR_SURVEYS_DATA";
export const SET_VENDOR_LABELS = "CYBERRISK_SET_VENDOR_LABELS";
export const SET_VENDOR_REASSESSMENT_DATE =
  "CYBERRISK_SET_VENDOR_REASSESSMENT_DATE";
export const SET_VENDOR_TECHNOLOGIES = "CYBERRISK_SET_VENDOR_TECHNOLOGIES";
export const SET_VENDOR_TECHNOLOGY_SORTING =
  "CYBERRISK_SET_VENDOR_TECHNOLOGY_SORTING";
export const SET_VENDORS_DATA = "CYBERRISK_SET_VENDORS_DATA";
export const SET_VENDOR_FILTER_OPTIONS = "CYBERRISK_SET_VENDOR_FILTER_OPTIONS";
export const SET_VENDOR_SEARCH = "CYBERRISK_SET_VENDOR_SEARCH";
export const SET_PLANS = "CYBERRISK_SET_PLANS";
export const SET_ORG_SUBSCRIPTION = "CYBERRISK_SET_ORG_SUBSCRIPTION";
export const SET_ORG_USERS = "CYBERRISK_SET_ORG_USERS";
export const SET_ORG_API = "CYBERRISK_SET_ORG_API";
export const SET_ORG_USER_INVITES = "CYBERRISK_SET_ORG_USER_INVITES";
export const SET_AVAILABLE_LABELS = "CYBERRISK_SET_AVAILABLE_LABELS";
export const TOGGLE_TOUR = "CYBERRISK_TOGGLE_TOUR";
export const SET_VENDOR_MGT_DATA = "CYBERRISK_SET_VENDOR_MGT_DATA";
export const SET_VENDOR_MGT_DOCUMENTS = "CYBERRISK_SET_VENDOR_MGT_DOCUMENTS";
export const SET_VENDOR_MGT_ATTACHMENTS =
  "CYBERRISK_SET_VENDOR_MGT_ATTACHMENTS";
export const SET_CHANGES_ACTIVE = "CYBERRISK_SET_CHANGES_ACTIVE";
export const SET_CHANGES_START = "CYBERRISK_SET_CHANGES_START";
export const SET_CHANGES_END = "CYBERRISK_SET_CHANGES_END";
export const SET_CHANGES_LOADING = "CYBERRISK_SET_CHANGES_LOADING";
export const SET_CHANGES_DATA = "CYBERRISK_SET_CHANGES_DATA";
export const SET_SCORING_PERIOD = "CYBERRISK_SET_SCORING_PERIOD";
export const SET_EXPOSURES_ACCOUNT_DATA =
  "CYBERRISK_SET_EXPOSURES_ACCOUNT_DATA";
export const SET_EXPOSURES_PAGED_ACCOUNT_DATA =
  "CYBERRISK_SET_EXPOSURES_PAGED_ACCOUNT_DATA";
export const SET_EXPOSURES_BREACH_DATA = "CYBERRISK_SET_EXPOSURES_BREACH_DATA";
export const SET_EXPOSURES_VIP_ACCOUNT_DATA =
  "CYBERRISK_SET_EXPOSURES_VIP_ACCOUNT_DATA";
export const SET_EXPOSURES_MONITORED_DOMAINS =
  "CYBERRISK_SET_EXPOSURES_MONITORED_DOMAINS";
export const SET_EXPOSURES_AVAILABLE_ASSIGNEES =
  "CYBERRISK_SET_EXPOSURES_AVAILABLE_ASSIGNEES";
export const SET_EXPOSURES_PAGE_DETAILS =
  "CYBERRISK_SET_EXPOSURES_PAGE_DETAILS";
export const SET_EXPOSURES_PAGE_NUM = "CYBERRISK_SET_EXPOSURES_PAGE_NUM";
export const SET_EXPOSURES_ACCOUNTS_LOADING =
  "CYBERRISK_EXPOSURES_ACCOUNTS_LOADNG";
export const SET_EXPOSURES_ACCOUNTS_SEARCHDATA =
  "CYBERRISK_SET_EXPOSURES_ACCOUNTS_SEARCHDATA";
export const SET_EXPOSURE_NOTIFICATION_STATUS =
  "CYBERRISK_SET_EXPOSURE_NOTIFICATION_STATUS";
export const SET_CUSTOMERDATA_SURVEYS = "CYBERRISK_SET_CUSTOMERDATA_SURVEYS";
export const CLEAR_EXPOSURES_PAGED_ACCOUNT_DATA =
  "CYBERRISK_CLEAR_EXPOSURES_PAGED_ACCOUNT_DATA";
export const SET_VENDOR_BREAKDOWN_DATA = "CYBERRISK_SET_VENDOR_BREAKDOWN_DATA";
export const SET_ORG_NOTIFICATIONS_SETTINGS =
  "CYBERRISK_SET_ORG_NOTIFICATIONS_SETTINGS";
export const CLEAR_ORG_NOTIFICATIONS_SETTINGS =
  "CYBERRISK_SET_CLEAR_ORG_NOTIFICATIONS_SETTINGS";
export const SET_USER_NOTIFICATIONS_SETTINGS =
  "CYBERRISK_SET_USER_NOTIFICATIONS_SETTINGS";
export const SET_ALL_ORG_NOTIFICATIONS = "CYBERRISK_SET_ALL_ORG_NOTIFICATIONS";
export const REMOVE_REMEDIATION_RISK_ID =
  "CYBERRISK_REMOVE_REMEDIATION_RISK_ID";
export const SET_ORGANISATION_FLAGS = "CYBERRISK_SET_ORGANISATION_FLAGS";
export const SET_ORG_INTEGRATIONS_SETTINGS =
  "CYBERRISK_SET_ORG_INTEGRATIONS_SETTINGS";
export const SET_WEBHOOK_TEST_RESPONSE = "CYBERRISK_SET_WEBHOOK_TEST_RESPONSE";
export const SET_TYPOSQUAT_HISTORY_DATA =
  "CYBERRISK_SET_TYPOSQUAT_HISTORY_DATA";
export const SET_TYPOSQUAT_DOMAIN_DIRTY =
  "CYBERRISK_SET_TYPOSQUAT_DOMAIN_DIRTY";
export const DELETE_VENDOR_ASSESSMENT_DATA = "DELETE_VENDOR_ASSESSMENT_DATA";
export const SET_VENDOR_ASSESSMENT_EVIDENCE_DATA =
  "CYBERRISK_SET_VENDOR_ASSESSMENT_EVIDENCE_DATA";
export const TOGGLE_VENDOR_ASSESSMENT_SURVEY_SELECTED =
  "CYBERRISK_TOGGLE_VENDOR_ASSESSMENT_SURVEY_SELECTED";
export const TOGGLE_VENDOR_ASSESSMENT_ADDITIONAL_SELECTED =
  "CYBERRISK_TOGGLE_VENDOR_ASSESSMENT_ADDITIONAL_SELECTED";
export const TOGGLE_VENDOR_ASSESSMENT_EVIDENCE_PAGE_SELECTED =
  "CYBERRISK_TOGGLE_VENDOR_ASSESSMENT_EVIDENCE_PAGE_SELECTED";
export const SET_ALL_VENDORS = "CYBERRISK_SET_ALL_VENDORS";
export const SET_ALL_VENDOR_RISKS = "CYBERRISK_SET_ALL_VENDOR_RISKS";
export const SET_VENDOR_ASSESSMENT_RISK_HOSTNAMES =
  "SET_VENDOR_ASSESSMENT_RISK_HOSTNAMES";

export const SET_REPORTS_CONFIG = "CYBERRISK_SET_REPORTS_CONFIG";
export const SET_REPORTS_DATA = "CYBERRISK_SET_REPORTS_DATA";
export const RESET_REPORTS_DATA = "CYBERRISK_RESET_REPORTS_DATA";
export const SET_WHOIS_OVERRIDE_DATA = "CYBERRISK_SET_WHOIS_OVERRIDE_DATA";
export const REPLACE_WHOIS_OVERRIDE_DATA =
  "CYBERRISK_REPLACE_WHOIS_OVERRIDE_DATA";
export const SET_WHOIS_DATA = "CYBERRISK_SET_WHOIS_DATA";
export const SET_WHOIS_OVERRIDE_LOADING =
  "CYBERRISK_SET_WHOIS_OVERRIDE_LOADING";

export const SET_REVERSE_WHOIS_LOOKUP_DATA =
  "CYBERRISK_SET_REVERSE_WHOIS_LOOKUP_DATA";
export const REMOVE_REVERSE_WHOIS_LOOKUP_DATA =
  "CYBERRISK_REMOVE_REVERSE_WHOIS_LOOKUP_DATA";
export const UPDATE_REVERSE_WHOIS_LOOKUP_DATA =
  "CYBERRISK_UPDATE_REVERSE_WHOIS_LOOKUP_DATA";
export const SET_REVERSE_WHOIS_LOOKUP_LOADING =
  "CYBERRISK_SET_REVERSE_WHOIS_LOOKUP_LOADING";

export const SET_DATA_LEAKS_STATISTICS = "CYBERRISK_SET_DATA_LEAKS_STATISTICS";
export const SET_DATA_LEAKS_SCANRESULTS =
  "CYBERRISK_SET_DATA_LEAKS_SCANRESULTS";
export const SET_DATA_LEAKS_SERVICES = "CYBERRISK_SET_DATA_LEAKS_SERVICES";
export const SET_DATA_LEAKS_TAB = "CYBERRISK_SET_DATA_LEAKS_TAB";
export const SET_DATA_LEAKS_PAGESORT = "CYBERRISK_SET_DATA_LEAKS_PAGESORT";
export const SET_DATA_LEAKS_SUMMARY = "CYBERRISK_SET_DATA_LEAKS_SUMMARY";

export const SET_EVIDENCE_TAB = "CYBERRISK_SET_EVIDENCE_TAB";
export const SET_VENDOR_ADDITIONAL_EVIDENCE =
  "CYBERRISK_SET_VENDOR_ADDITIONAL_EVIDENCE";
export const SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE =
  "CYBERRISK_SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE";
export const SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_VIRUS_SCANS =
  "CYBERRISK_SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_VIRUS_SCANS";
export const SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_NEWDOCUMENT =
  "CYBERRISK_SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_NEWDOCUMENT";
export const SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_ARCHIVED =
  "CYBERRISK_SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_ARCHIVED";
export const REMOVE_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_DOCUMENT =
  "CYBERRISK_REMOVE_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_DOCUMENT";
export const SET_VENDOR_DOCUMENTS_PAGING =
  "CYBER_RISK_SET_VENDOR_DOCUMENTS_PAGING";
export const SET_VENDOR_CONTACTS_PAGING =
  "CYBER_RISK_SET_VENDOR_CONTACTS_PAGING";
export const SET_AUDIT_TRAIL_FILTERS = "CYBER_RISK_SET_AUDIT_TRAIL_FILTERS";
export const SET_MANAGED_VENDORS_REQUEST_SEARCH =
  "CYBER_RISK_SET_MANAGED_VENDORS_REQUEST_SEARCH";

export const SET_ORG_LOGO_URL = "CYBER_RISK_SET_ORG_LOGO_URL";
export const SET_ORG_DEFAULT_TEXTS = "CYBER_RISK_SET_ORG_DEFAULT_TEXTS";
export const SET_ORG_USER_LIMITS = "CYBER_RISK_SET_ORG_USER_LIMITS";

export const SET_OAUTH_CONNECTION_DATA = "CYBERRISK_SET_OAUTH_CONNECTION_DATA";
export const SET_OAUTH_CONNECTION_LOADING =
  "CYBERRISK_SET_OAUTH_CONNECTION_LOADING";
export const SET_OAUTH_REQUESTURL_DATA = "CYBERRISK_SET_OAUTH_REQUESTURL_DATA";
export const SET_OAUTH_ERROR = "CYBERRISK_SET_OAUTH_ERROR";
export const SET_OAUTH_MESSAGING_CHANNELS =
  "CYBERRISK_SET_OAUTH_MESSAGING_CHANNELS";
export const SET_OAUTH_MESSAGING_POSTING_STATUS =
  "CYBERRISK_SET_OAUTH_MESSAGING_POSTING_STATUS";
export const SET_OAUTH_MESSAGING_POPULATED_DATA =
  "CYBERRISK_SET_OAUTH_MESSAGING_POPULATED_DATA";
export const SET_OAUTH_MESSAGING_POPULATED_EXPECTED =
  "CYBERRISK_SET_OAUTH_MESSAGING_POPULATED_EXPECTED";
export const SET_OAUTH_JIRA_PROJECTS = "CYBERRISK_SET_OAUTH_JIRA_PROJECTS";
export const SET_OAUTH_JIRA_ISSUETYPES = "CYBERRISK_SET_OAUTH_JIRA_ISSUETYPES";
export const SET_OAUTH_JIRA_COMPONENTS = "CYBERRISK_SET_OAUTH_JIRA_COMPONENTS";
export const SET_OAUTH_JIRA_POSTING_STATUS =
  "CYBERRISK_SET_OAUTH_JIRA_POSTING_STATUS";
export const SET_VENDOR_SUBDOMAINS_STARTED =
  "CYBERRISK_SET_VENDOR_SUBDOMAINS_STARTED";
export const SET_SURVEYTYPE_AUTOMATION_LIST =
  "CYBERRISK_SET_SURVEYTYPE_AUTOMATION_LIST";
export const SWAP_IN_SURVEYTYPE_AUTOMATION_LIST =
  "CYBERRISK_SWAP_IN_SURVEYTYPE_AUTOMATION_LIST";
export const MOVE_IN_SURVEYTYPE_AUTOMATION_LIST =
  "CYBERRISK_MOVE_IN_SURVEYTYPE_AUTOMATION_LIST";
export const SET_SURVEYTYPE_AUTOMATION_ENABLED =
  "CYBERRISK_SET_SURVEYTYPE_AUTOMATION_ENABLED";
export const REMOVE_FROM_SURVEYTYPE_AUTOMATION_LIST =
  "CYBERRISK_REMOVE_FROM_SURVEYTYPE_AUTOMATION_LIST";
export const SET_AUTOMATION_RECIPE = "CYBERRISK_SET_AUTOMATION_RECIPE";
export const SET_AUTOMATION_RECIPE_STATE =
  "CYBERRISK_SET_AUTOMATION_RECIPE_STATE";
export const SET_AUTOMATION_RECIPE_ENABLED =
  "CYBERRISK_SET_AUTOMATION_RECIPE_ENABLED";
export const SET_SURVEYTYPE_AUTOMATION_QUESTIONS =
  "CYBERRISK_SET_SURVEYTYPE_AUTOMATION_QUESTIONS";
export const SET_AUTOMATION_RECIPE_AUTOSAVE_STATE =
  "CYBERRISK_SET_AUTOMATION_RECIPE_AUTOSAVE_STATE";
export const SET_AUTOMATION_RECIPE_SYNTAX_STATE =
  "CYBERRISK_SET_AUTOMATION_RECIPE_SYNTAX_STATE";
export const SET_AUTOMATION_TEST_SUBMISSIONS =
  "CYBERRISK_SET_AUTOMATION_TEST_SUBMISSIONS";
export const SET_AUTOMATION_TEST_RESULTS =
  "CYBERRISK_SET_AUTOMATION_TEST_RESULTS";
export const SET_AUTOMATION_LIST_RECIPE_EDITS_EXIST =
  "CYBERRISK_SET_AUTOMATION_LIST_RECIPE_EDITS_EXIST";
export const DELETE_AUTOMATION_TEST_RESULTS =
  "CYBERRISK_DELETE_AUTOMATION_TEST_RESULTS";

// the default window for validity of notifications
const DEFAULT_NOTIFICATIONS_PERIOD = 30;

/* CYBER RISK ACTION CREATORS */

export const setTourSteps = (tourSteps) => {
  return {
    type: SET_TOUR_STEPS,
    tourSteps,
  };
};

export const toggleTour = (enabled = false) => {
  return {
    type: TOGGLE_TOUR,
    enabled,
  };
};

export const setCustomerData = (data) => {
  return {
    type: SET_CUSTOMER_DATA,
    data,
  };
};

export const clearAllData = () => {
  return { type: CLEAR_ALL_DATA };
};

export const clearCustomerData = () => {
  return { type: CLEAR_CUSTOMER_DATA };
};

export const clearVendorsData = () => {
  return { type: CLEAR_VENDORS_DATA };
};

export const resetVendorsPageNum = () => {
  return {
    type: RESET_VENDORS_PAGE_NUM,
  };
};

export const setCustomerDataFilters = (filters) => {
  return {
    type: SET_CUSTOMER_DATA_FILTERS,
    filters,
  };
};

export const setCustomerDataFilterOptions = (filterOptions) => {
  return {
    type: SET_CUSTOMER_DATA_FILTER_OPTIONS,
    filterOptions,
  };
};

/**
 * @returns {IBaseSingleVendorData}
 * @param tpvmSession {ITpvmSession}
 * */
export const getVendorData = (
  getState,
  vendorId,
  isSubsidiary = false,
  tpvmSession = null
) => {
  let vendor;
  if (isSubsidiary) {
    vendor = getState().cyberRisk.subsidiaries[vendorId];
  } else if (tpvmSession && tpvmSession.tpvm_o > 0) {
    vendor = _get(
      getState().cyberRisk.managedVendorData,
      `[${tpvmSession.tpvm_o}][${vendorId}]`,
      null
    );
  } else {
    vendor = getState().cyberRisk.vendors[vendorId];
  }
  return vendor;
};

export const setVendorData = (
  vendorId,
  data,
  isSubsidiary = false,
  tpvmSession = null,
  orgEntitlements = null,
  orgGroupEntitlements = null,
  orgName = null
) => {
  return {
    type: SET_VENDOR_DATA,
    vendorId,
    isSubsidiary,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
    orgEntitlements,
    orgGroupEntitlements,
    orgName,
    data,
  };
};

export const getSubdomainsScanStarted = (
  state,
  vendorId,
  isSubsidiary = false,
  tpvmSession = null
) => {
  let started;
  if (isSubsidiary) {
    started = state.cyberRisk.subsidiaries[vendorId]?.subdomainScanStarted;
  } else if (tpvmSession && tpvmSession.tpvm_o > 0) {
    started =
      state.cyberRisk.managedVendorData[tpvmSession.tpvm_o][vendorId]
        ?.subdomainScanStarted;
  } else {
    started = state.cyberRisk.vendors[vendorId]?.subdomainScanStarted;
  }
  return started;
};

export const setSubdomainsScanStarted = (
  vendorId,
  isSubsidiary = false,
  tpvmSession = null,
  flag = undefined
) => {
  return {
    type: SET_VENDOR_SUBDOMAINS_STARTED,
    vendorId,
    isSubsidiary,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
    flag,
  };
};

export const setVendorLabels = (vendorId, labels) => {
  return {
    type: SET_VENDOR_LABELS,
    vendorId,
    labels,
  };
};

export const setVendorTechnologies = (vendorId, technologies, tpvmSession) => {
  return {
    type: SET_VENDOR_TECHNOLOGIES,
    vendorId,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
    technologies,
  };
};

export const setVendorTechnologySorting = (
  vendorId,
  technologySorting,
  isManagementAnalystSession = false,
  managedOrgId = 0
) => {
  return {
    type: SET_VENDOR_TECHNOLOGY_SORTING,
    vendorId,
    technologySorting,
    isManagementAnalystSession,
    managedOrgId,
  };
};

export const setVendorsData = (vendors) => {
  return {
    type: SET_VENDORS_DATA,
    vendors,
  };
};

export const setVendorFilterOptions = (
  vendorId,
  filterOptions,
  isSubsidiary,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_FILTER_OPTIONS,
    vendorId,
    filterOptions,
    isSubsidiary,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setCloudscanData = (webscanId, data) => {
  return {
    type: SET_CLOUDSCAN_DATA,
    webscanId,
    data,
  };
};

export const setCloudscanError = (webscanId, data) => {
  return {
    type: SET_CLOUDSCAN_ERROR,
    webscanId,
    data,
  };
};

export const clearCloudscanData = () => {
  return {
    type: CLEAR_CLOUDSCAN_DATA,
  };
};

export const setVulnData = (cveName, data) => {
  return {
    type: SET_VULN_DATA,
    cveName,
    data,
  };
};

export const setVulnRiskIDsByCVE = (cveName, data) => {
  return {
    type: SET_VULN_RISKS_IDS_BY_CVE,
    cveName,
    data,
  };
};

export const setVulnsByCPE = (cpeName, data) => {
  return {
    type: SET_VULNS_BY_CPE,
    cpeName,
    data,
  };
};

export const setCustomerVulnsByCPEByHostnames = (cpeName, hostsHash, data) => {
  return {
    type: SET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES,
    cpeName,
    hostsHash,
    data,
  };
};

export const resetCustomerVulnsByCPEByHostnames = () => {
  return {
    type: RESET_CUSTOMER_VULNS_BY_CPE_BY_HOSTNAMES,
  };
};

export const setTableSort = (tableName, sortObj) => {
  return {
    type: SET_TABLE_SORT,
    tableName,
    sortObj,
  };
};

export const setTablePage = (tableName, pageIndex) => {
  return {
    type: SET_TABLE_PAGE,
    tableName,
    pageIndex,
  };
};

export const setPlans = (plans) => {
  return {
    type: SET_PLANS,
    plans,
  };
};

export const setVendorSearch = (data) => {
  return {
    type: SET_VENDOR_SEARCH,
    data,
  };
};

export const setSurveyTypes = (
  context,
  surveyTypes,
  surveyRiskVisibility,
  tpvmSession
) => {
  const data = {};
  data[context] = surveyTypes;
  return {
    type: SET_SURVEY_TYPES,
    surveyTypes: data,
    surveyRiskVisibility,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setOrgSubscription = (orgSubscription) => {
  return {
    type: SET_ORG_SUBSCRIPTION,
    orgSubscription,
  };
};

export const setOrgApi = (orgApi) => {
  return {
    type: SET_ORG_API,
    orgApi,
  };
};

export const setOrgUsers = (orgUsers) => {
  return {
    type: SET_ORG_USERS,
    orgUsers,
  };
};

export const setOrgUserInvites = (orgUserInvites) => {
  return {
    type: SET_ORG_USER_INVITES,
    orgUserInvites,
  };
};

export const setAvailableLabels = (labels) => {
  return {
    type: SET_AVAILABLE_LABELS,
    labels,
  };
};

export const setVendorManagementData = (vendorId, data, tpvmSession) => {
  return {
    type: SET_VENDOR_MGT_DATA,
    vendorId,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
    data,
  };
};

export const setVendorManagementDocuments = (vendorId, data, tpvmSession) => {
  return {
    type: SET_VENDOR_MGT_DOCUMENTS,
    vendorId,
    data,
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setVendorManagementAttachments = (vendorId, data) => {
  return {
    type: SET_VENDOR_MGT_ATTACHMENTS,
    vendorId,
    data,
  };
};

export const setChangesActive = (active) => {
  return {
    type: SET_CHANGES_ACTIVE,
    active,
  };
};

export const setChangesStart = (startDate, startDateIndex) => {
  return {
    type: SET_CHANGES_START,
    startDate,
    startDateIndex,
  };
};

export const setChangesEnd = (endDate, endDateIndex) => {
  return {
    type: SET_CHANGES_END,
    endDate,
    endDateIndex,
  };
};

export const setChangesLoading = (loading) => {
  return {
    type: SET_CHANGES_LOADING,
    loading,
  };
};

export const setChangesData = (data) => {
  return {
    type: SET_CHANGES_DATA,
    data,
  };
};

export const setScoringPeriod = (days, page) => {
  // Store these in localStorage so they persist between page loads.
  const existingItem = getLocalStorageItem("cyberRiskScoringPeriods");
  setLocalStorageItem("cyberRiskScoringPeriods", {
    ...existingItem,
    [page]: days,
  });

  return {
    type: SET_SCORING_PERIOD,
    page,
    days,
  };
};

export const setExposuresAccountData = (data) => {
  return {
    type: SET_EXPOSURES_ACCOUNT_DATA,
    data,
  };
};

export const setExposuresPagedAccountData = (breachID, pageNum, data) => {
  return {
    type: SET_EXPOSURES_PAGED_ACCOUNT_DATA,
    breachID,
    pageNum,
    data,
  };
};

export const clearExposuresPagedAccountData = (breachID) => {
  return {
    type: CLEAR_EXPOSURES_PAGED_ACCOUNT_DATA,
    breachID,
  };
};

export const clearExposuresPagedAccountDataNonZero = () => {
  return {
    type: CLEAR_EXPOSURES_PAGED_ACCOUNT_DATA,
    breachID: -1,
  };
};

export const setExposuresBreachData = (data, assigneeSharedUsers) => {
  return {
    type: SET_EXPOSURES_BREACH_DATA,
    data,
    assigneeSharedUsers,
  };
};

export const setExposuresVIPAccountData = (data) => {
  return {
    type: SET_EXPOSURES_VIP_ACCOUNT_DATA,
    data,
  };
};

export const setExposuresMonitoredDomains = (domains) => {
  return {
    type: SET_EXPOSURES_MONITORED_DOMAINS,
    domains,
  };
};

export const setExposuresAvailableAssignees = (availableAssignees) => {
  return {
    type: SET_EXPOSURES_AVAILABLE_ASSIGNEES,
    availableAssignees,
  };
};

export const setExposuresCurrentPageDetails = (
  breachID,
  pageNum,
  totalCount,
  totalVip,
  totalNotNotifiedCount,
  sortedBy,
  sortedDesc,
  textFilter,
  domainFilter
) => {
  return {
    type: SET_EXPOSURES_PAGE_DETAILS,
    breachID,
    pageNum,
    totalCount,
    totalVip,
    totalNotNotifiedCount,
    sortedBy,
    sortedDesc,
    textFilter,
    domainFilter,
  };
};

export const setExposuresCurrentPageNum = (pageNum) => {
  return {
    type: SET_EXPOSURES_PAGE_NUM,
    pageNum,
  };
};

export const setExposuresAccountsLoading = (loading, context) => {
  return {
    type: SET_EXPOSURES_ACCOUNTS_LOADING,
    loading,
    context,
  };
};

export const setExposuresAccountsSearchCriteria = (searchData) => {
  return {
    type: SET_EXPOSURES_ACCOUNTS_SEARCHDATA,
    searchData,
  };
};

export const setExposureNotificationStatus = (breachId, status) => {
  return {
    type: SET_EXPOSURE_NOTIFICATION_STATUS,
    breachId,
    status,
  };
};

export const setCustomerSurveys = (result, usageType) => {
  return {
    type: SET_CUSTOMERDATA_SURVEYS,
    result,
    usageType,
  };
};

export const setVendorBreakdownData = (vendorBreakdown) => {
  return {
    type: SET_VENDOR_BREAKDOWN_DATA,
    vendorBreakdown,
  };
};

export const setOrgIntegrationSettings = (settings) => {
  return {
    type: SET_ORG_INTEGRATIONS_SETTINGS,
    settings,
  };
};

export const setOrgNotificationSettings = (settings) => {
  return {
    type: SET_ORG_NOTIFICATIONS_SETTINGS,
    settings,
  };
};

export const clearOrgNotificationSettings = () => {
  return {
    type: CLEAR_ORG_NOTIFICATIONS_SETTINGS,
  };
};

export const setUserNotificationSettings = (settings) => {
  return {
    type: SET_USER_NOTIFICATIONS_SETTINGS,
    settings,
  };
};

export const setAllOrgNotifications = (settings) => {
  return {
    type: SET_ALL_ORG_NOTIFICATIONS,
    settings,
  };
};

export const removeRemediationRiskId = (vendorId, riskId) => {
  return {
    type: REMOVE_REMEDIATION_RISK_ID,
    vendorId,
    riskId,
  };
};

export const setOrganisationFlags = (orgFlags) => {
  return {
    type: SET_ORGANISATION_FLAGS,
    orgFlags,
  };
};

export const setWebhookTestResponse = (response) => {
  return {
    type: SET_WEBHOOK_TEST_RESPONSE,
    response,
  };
};

export const setTyposquatHistoryData = (domain, data) => {
  return {
    type: SET_TYPOSQUAT_HISTORY_DATA,
    domain,
    data,
  };
};

export const setTyposquatDomainDirtyAction = (hostname, isDirty) => {
  return {
    type: SET_TYPOSQUAT_DOMAIN_DIRTY,
    hostname,
    isDirty,
  };
};

export const setReportsConfig = (data) => {
  return {
    type: SET_REPORTS_CONFIG,
    data,
  };
};

export const setReportsData = (reportType, data) => {
  return {
    type: SET_REPORTS_DATA,
    reportType,
    data,
  };
};

export const resetReportsData = () => {
  return {
    type: RESET_REPORTS_DATA,
  };
};

export const setVendorWhoisOverrideData = (hostname, data) => {
  return {
    type: SET_WHOIS_OVERRIDE_DATA,
    hostname,
    data,
  };
};

export const setVendorWhoisOverrideLoading = (hostname, loading) => {
  return {
    type: SET_WHOIS_OVERRIDE_LOADING,
    hostname,
    loading,
  };
};

export const replaceVendorWhoisOverride = (hostname, data) => {
  return {
    type: REPLACE_WHOIS_OVERRIDE_DATA,
    hostname,
    data,
  };
};

export const setWhoisData = (hostname, data) => {
  return {
    type: SET_WHOIS_DATA,
    hostname,
    data,
  };
};

export const setReverseWhoisResults = (hostname, data) => {
  return {
    type: SET_REVERSE_WHOIS_LOOKUP_DATA,
    hostname,
    data,
  };
};

export const setReverseWhoisResultsLoading = (hostname, loading) => {
  return {
    type: SET_REVERSE_WHOIS_LOOKUP_LOADING,
    hostname,
    loading,
  };
};

export const removeReverseWhoisResults = (
  primaryHostname,
  searchResultHostnames,
  selectedVendorDomain,
  selectedVendorName,
  selectedVendorId
) => {
  return {
    type: REMOVE_REVERSE_WHOIS_LOOKUP_DATA,
    hostname: primaryHostname,
    removedHostnames: searchResultHostnames,
    selectedVendorDomain,
    selectedVendorName,
    selectedVendorId,
  };
};

export const updateReverseWhoisResults = (
  primaryHostname,
  searchResultHostnames,
  newVendorDetails
) => {
  return {
    type: UPDATE_REVERSE_WHOIS_LOOKUP_DATA,
    hostname: primaryHostname,
    updatedHostnames: searchResultHostnames,
    newVendorDetails: newVendorDetails,
  };
};

export const setAllVendors = (loading, error, data) => ({
  type: SET_ALL_VENDORS,
  loading,
  error,
  data,
});

export const setAllVendorRisks = (loading, error, data) => ({
  type: SET_ALL_VENDOR_RISKS,
  loading,
  error,
  data,
});

export const setDataLeakStatistics = (loading, days, error, results) => {
  if (loading) {
    error = null;
    results = null;
  }
  return {
    type: SET_DATA_LEAKS_STATISTICS,
    days: days,
    data: {
      loading: loading,
      error: error,
      results: results,
    },
  };
};

export const setDataLeakClassifiedScanResults = (
  loading,
  days,
  error,
  results
) => {
  if (loading) {
    error = null;
    results = null;
  }
  return {
    type: SET_DATA_LEAKS_SCANRESULTS,
    days: days,
    data: {
      loading: loading,
      error: error,
      results: results,
    },
  };
};

export const setDataLeakServicesList = (loading, error, services, keywords) => {
  if (loading) {
    error = null;
    services = {};
    keywords = [];
  }
  return {
    type: SET_DATA_LEAKS_SERVICES,
    data: {
      loading: loading,
      error: error,
      services: services,
      keywords: keywords,
    },
  };
};

export const setDataLeaksSelectedTab = (tab) => {
  return {
    type: SET_DATA_LEAKS_TAB,
    data: {
      tab: tab,
    },
  };
};

export const setDataLeaksOverviewPageSort = (pageNum, sortedBy) => {
  return {
    type: SET_DATA_LEAKS_PAGESORT,
    data: {
      pageNum: pageNum,
      sortedBy: sortedBy,
    },
  };
};

export const setDataLeaksSummary = (isFiltered, isByService, dateKey, data) => {
  return {
    type: SET_DATA_LEAKS_SUMMARY,
    isFiltered: isFiltered,
    isByService: isByService,
    dateKey: dateKey,
    data: data,
  };
};

export const setAdditionalEvidenceForVendor = (
  loading,
  vendorId,
  error,
  evidence,
  sharedAdditionalEvidence,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_ADDITIONAL_EVIDENCE,
    data: {
      loading: loading,
      vendorId: vendorId,
      error: error,
      evidence: evidence,
      sharedAdditionalEvidence,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setDetailedAdditionalEvidenceForVendor = (
  loading,
  saving,
  vendorId,
  evidenceId,
  error,
  evidence,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE,
    data: {
      loading: loading,
      saving: saving,
      vendorId: vendorId,
      evidenceId: evidenceId,
      error: error,
      evidence: evidence,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setVirusScanDataForDetailedAdditionalEvidence = (
  vendorId,
  evidenceId,
  documents,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_VIRUS_SCANS,
    data: {
      vendorId: vendorId,
      evidenceId: evidenceId,
      documents: documents,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const addDocumentForDetailedAdditionalEvidence = (
  vendorId,
  evidenceId,
  document,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_NEWDOCUMENT,
    data: {
      vendorId: vendorId,
      evidenceId: evidenceId,
      document: document,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setArchivedFlagForDetailedAdditionalEvidence = (
  vendorId,
  evidenceId,
  archived,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_ARCHIVED,
    data: {
      vendorId: vendorId,
      evidenceId: evidenceId,
      archived: archived,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const removeDocumentForDetailedAdditionalEvidence = (
  vendorId,
  evidenceId,
  documentId,
  tpvmSession
) => {
  return {
    type: REMOVE_VENDOR_DETAILED_ADDITIONAL_EVIDENCE_DOCUMENT,
    data: {
      vendorId: vendorId,
      evidenceId: evidenceId,
      documentId: documentId,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setVendorDocumentsPageSort = (
  vendorId,
  currentPage,
  sortObj,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_DOCUMENTS_PAGING,
    vendorId: vendorId,
    data: {
      paging: {
        currentPage: currentPage,
        sortObj: sortObj,
      },
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};
export const setVendorContactsPageSort = (
  vendorId,
  currentPage,
  sortObj,
  tpvmSession
) => {
  return {
    type: SET_VENDOR_CONTACTS_PAGING,
    vendorId: vendorId,
    data: {
      paging: {
        currentPage: currentPage,
        sortObj: sortObj,
      },
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const setOrgUserLimits = (orgUserLimits) => ({
  type: SET_ORG_USER_LIMITS,
  orgUserLimits,
});

export const setAuditTrailFilters = (filters) => ({
  type: SET_AUDIT_TRAIL_FILTERS,
  filters,
});

export const setManagedVendorsRequestSearch = (data) => ({
  type: SET_MANAGED_VENDORS_REQUEST_SEARCH,
  data,
});

export const setOAuthConnectionData = (
  service,
  connections,
  loading,
  error
) => ({
  type: SET_OAUTH_CONNECTION_DATA,
  service,
  connections,
  loading,
  error,
});
export const setOAuthConnectionLoading = (service, loading) => ({
  type: SET_OAUTH_CONNECTION_LOADING,
  service,
  loading,
});
export const setOAuthRequestURL = (service, url, uuid, error) => ({
  type: SET_OAUTH_REQUESTURL_DATA,
  service,
  url,
  uuid,
  error,
});
export const setOAuthError = (service, error) => ({
  type: SET_OAUTH_ERROR,
  service,
  error,
});
export const setMessagingChannelsData = (
  service,
  channels,
  loading,
  error
) => ({
  type: SET_OAUTH_MESSAGING_CHANNELS,
  service,
  loading,
  channels,
  error,
});
export const setMessageSendingStatus = (service, posting, error) => ({
  type: SET_OAUTH_MESSAGING_POSTING_STATUS,
  service,
  posting,
  error,
});
export const setPopulatedMessagingData = (
  service,
  integrationId,
  uuid,
  populatedText,
  error
) => ({
  type: SET_OAUTH_MESSAGING_POPULATED_DATA,
  service,
  integrationId,
  uuid,
  populatedText,
  error,
});
export const setPopulatedMessagingExpected = (
  service,
  integrationId,
  uuid,
  expected
) => ({
  type: SET_OAUTH_MESSAGING_POPULATED_EXPECTED,
  service,
  integrationId,
  uuid,
  expected,
});
export const setJiraProjectData = (projects, loading, error) => ({
  type: SET_OAUTH_JIRA_PROJECTS,
  loading,
  projects,
  error,
});
export const setJiraIssueTypesData = (
  projectId,
  issueTypes,
  loading,
  error
) => ({
  type: SET_OAUTH_JIRA_ISSUETYPES,
  projectId,
  loading,
  issueTypes,
  error,
});
export const setJiraComponentsData = (
  projectId,
  components,
  loading,
  error
) => ({
  type: SET_OAUTH_JIRA_COMPONENTS,
  projectId,
  loading,
  components,
  error,
});
export const setJiraIssueSendingStatus = (posting, error) => ({
  type: SET_OAUTH_JIRA_POSTING_STATUS,
  posting,
  error,
});

export const setSurveyTypeAutomationList = (
  surveyTypeId,
  loading,
  error,
  draftChangesExist,
  numDraftDeletions,
  published,
  draft
) => ({
  type: SET_SURVEYTYPE_AUTOMATION_LIST,
  surveyTypeId,
  loading,
  error,
  draftChangesExist,
  numDraftDeletions,
  published,
  draft,
});

export const swapRecipeEntries = (surveyTypeId, aIdx, bIdx) => ({
  type: SWAP_IN_SURVEYTYPE_AUTOMATION_LIST,
  surveyTypeId,
  aIdx,
  bIdx,
});

export const moveRecipeEntry = (surveyTypeId, aIdx, bIdx) => ({
  type: MOVE_IN_SURVEYTYPE_AUTOMATION_LIST,
  surveyTypeId,
  aIdx,
  bIdx,
});

export const removeRecipeFromAutomationList = (surveyTypeId, recipeUUID) => ({
  type: REMOVE_FROM_SURVEYTYPE_AUTOMATION_LIST,
  surveyTypeId,
  recipeUUID,
});

export const setAutomationQuestionsForSurveyType = (
  surveyTypeId,
  data,
  loading,
  error
) => ({
  type: SET_SURVEYTYPE_AUTOMATION_QUESTIONS,
  surveyTypeId,
  loading,
  error,
  data,
});

export const setSurveyTypeAutomationEnabledFlag = (
  surveyTypeId,
  recipeUUID,
  enabled
) => ({
  type: SET_SURVEYTYPE_AUTOMATION_ENABLED,
  surveyTypeId,
  recipeUUID,
  enabled,
});

export const setAutomationRecipe = (
  recipeUUID,
  loading,
  error,
  data,
  remove = false
) => ({
  type: SET_AUTOMATION_RECIPE,
  recipeUUID,
  loading,
  error,
  data,
  remove,
});
export const setAutomationRecipeState = (recipeUUID, loading, error) => ({
  type: SET_AUTOMATION_RECIPE_STATE,
  recipeUUID,
  loading,
  error,
});

export const setAutomationRecipeEnabledFlag = (recipeUUID, enabled) => ({
  type: SET_AUTOMATION_RECIPE_ENABLED,
  recipeUUID,
  enabled,
});

export const setAutosaveStatus = (recipeUUID, loading, error) => ({
  type: SET_AUTOMATION_RECIPE_AUTOSAVE_STATE,
  recipeUUID,
  loading,
  error,
});
export const setEditsExistOnAutomation = (recipeUUID, surveyTypeId, exist) => ({
  type: SET_AUTOMATION_LIST_RECIPE_EDITS_EXIST,
  recipeUUID,
  surveyTypeId,
  exist,
});

export const setSyntaxStatus = (recipeUUID, error) => ({
  type: SET_AUTOMATION_RECIPE_SYNTAX_STATE,
  recipeUUID,
  error,
});
export const setTestSubmissionsList = (surveyTypeID, loading, error, data) => ({
  type: SET_AUTOMATION_TEST_SUBMISSIONS,
  surveyTypeID,
  loading,
  error,
  data,
});

export const setAutomationTestResults = (
  surveyTypeID,
  surveyID,
  loading,
  error,
  mappedResults,
  appliedResults
) => ({
  type: SET_AUTOMATION_TEST_RESULTS,
  surveyTypeID,
  surveyID,
  loading,
  error,
  mappedResults,
  appliedResults,
});

export const deleteAutomationTestResults = (surveyTypeID, surveyID) => ({
  type: DELETE_AUTOMATION_TEST_RESULTS,
  surveyTypeID,
  surveyID,
});

/* CYBER RISK ASYNC ACTION CREATORS */
export const showVendorWatchLimitExceededError = (history) => {
  return (dispatch, getState) => {
    const userData = getState().common.userData || {};
    const currentOrgRoles = userData.currentOrgRoles || [];
    const invoiced = userData.isInvoicedOrg === true;

    let content;

    if (invoiced && isAdmin(currentOrgRoles)) {
      content = (
        <span>
          You have reached the maximum number of monitored vendors.
          <br />
          To monitor this vendor, you&apos;ll need to stop monitoring another
          vendor or contact support@upguard.com to upgrade your account.
        </span>
      );
    } else if (invoiced) {
      content = (
        <span>
          You have reached the maximum number of monitored vendors.
          <br />
          Please contact the administrator of your account to upgrade.
        </span>
      );
    } else if (isAdmin(currentOrgRoles)) {
      content = (
        <span>
          You have reached the maximum number of monitored vendors.
          <br />
          To monitor this vendor, you&apos;ll need to stop monitoring another
          vendor or{" "}
          <a onClick={() => history.push("/settings/subscription")}>
            upgrade your account
          </a>
          .
        </span>
      );
    } else {
      content = (
        <span>
          You have reached the maximum number of monitored vendors.
          <br />
          Please contact the administrator of your account to upgrade.
        </span>
      );
    }

    dispatch(
      addMessageAlert({
        message: content,
        type: BannerType.WARNING,
      })
    );
  };
};

export const fetchCustomerVulns = (forced, filters) => {
  return async (dispatch, getState) => {
    const { vulns, filters: currentFilters } =
      getState().cyberRisk.customerData;
    let newVulnsData = {};
    let json;

    if (!forced && vulns && !vulns.loading && !vulns.error) {
      // We have cached data, don't fetch again
      return vulns;
    }

    const newFilters = filters || currentFilters;

    dispatch(setCustomerData({ vulns: { ...vulns, loading: true } }));

    try {
      json = await FetchCyberRiskUrl(
        "vendor/vulns/v1/",
        {
          customer: true,
          website_label_ids: newFilters.websiteLabelIds,
          website_portfolio_ids: newFilters.domainPortfolioIds,
          cve_names: newFilters.vulnsCveNames,
          cpe_names: newFilters.vulnsSoftware.map((cpe) => cpe.name),
          exclude_verified: newFilters.vulnsExcludeVerified,
          exclude_unverified: newFilters.vulnsExcludeUnverified,
          only_kev: newFilters.vulnsOnlyKEV,
          exclude_unignored: newFilters.vulnsExcludeUnignored,
          exclude_ignored: newFilters.vulnsExcludeIgnored,
          excluded_cvss_severities: newFilters.vulnsExcludedSeverities,
          epss_score_range_lower: newFilters.vulnsEPSSScores.lowerVal,
          epss_score_range_upper: newFilters.vulnsEPSSScores.upperVal,
          website_label_ids_match_all: newFilters.websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: newFilters.websiteLabelIdsDoNotMatch,
          website_include_unlabeled: newFilters.websiteIncludeUnlabeled,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vulns data", e);
      dispatch(
        setCustomerData({
          vulns: {
            loading: false,
            error: {
              errorText: "Error fetching vulnerabilities",
              actionText: "Try again",
              actionOnClick: () => dispatch(fetchCustomerVulns(true)),
            },
          },
        })
      );

      return vulns;
    }

    newVulnsData.result = json.result;
    newVulnsData.loading = false;
    newVulnsData.error = null;

    dispatch(setCustomerData({ vulns: newVulnsData }));

    return newVulnsData;
  };
};

/**
 * @param {number} vendorId
 * @param {boolean} forceRefresh
 * @param {boolean} isSubsidiary
 * @param {Filters=} newFilters
 */
export const fetchVendorVulns = (
  vendorId,
  forceRefresh,
  isSubsidiary = false,
  newFilters = undefined
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, isSubsidiary, tpvmSession);

    const filters =
      newFilters || getFiltersFromState(getState(), vendorId, isSubsidiary);
    const newVulnsData = {};
    let json;

    if (
      !forceRefresh &&
      vendor &&
      vendor.vulns &&
      !vendor.vulns.loading &&
      !vendor.vulns.error
    ) {
      // We have cached vulns data for this vendor, don't fetch again
      return vendor.vulns;
    }

    const opts = {
      vendor_id: vendorId,
      is_subsidiary: isSubsidiary,
      website_label_ids: filters.websiteLabelIds,
      cve_names: filters.vulnsCveNames,
      cpe_names: filters.vulnsSoftware.map((cpe) => cpe.name),
      exclude_verified: filters.vulnsExcludeVerified,
      exclude_unverified: filters.vulnsExcludeUnverified,
      only_kev: filters.vulnsOnlyKEV,
      exclude_unignored: filters.vulnsExcludeUnignored,
      exclude_ignored: filters.vulnsExcludeIgnored,
      excluded_cvss_severities: filters.vulnsExcludedSeverities,
      epss_score_range_lower: filters.vulnsEPSSScores?.lowerVal,
      epss_score_range_upper: filters.vulnsEPSSScores?.upperVal,
      website_label_ids_match_all: filters.websiteLabelIdsMatchAll,
      website_label_ids_do_not_match: filters.websiteLabelIdsDoNotMatch,
      website_include_unlabeled: filters.websiteIncludeUnlabeled,
    };

    dispatch(
      setVendorData(
        vendorId,
        { vulns: { loading: true } },
        isSubsidiary,
        tpvmSession
      )
    );

    try {
      json = await FetchCyberRiskUrl(
        "vendor/vulns/v1/",
        opts,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setVendorData(
          vendorId,
          {
            vulns: {
              loading: false,
              error: {
                errorText: "Error fetching vendor vulnerabilities",
                actionText: "Try again",
                actionOnClick: () => dispatch(fetchVendorVulns(vendorId, true)),
              },
            },
          },
          isSubsidiary,
          tpvmSession
        )
      );
      LogError("Error fetching vendor vulns", e);
      return { error: "Error fetching vendor vulnerabilities" };
    }

    newVulnsData.result = json.result;
    newVulnsData.loading = false;
    newVulnsData.error = null;

    dispatch(
      setVendorData(
        vendorId,
        { vulns: newVulnsData },
        isSubsidiary,
        tpvmSession
      )
    );

    return newVulnsData;
  };
};

export const fetchCustomerVulnsFilterOptions = (forced) => {
  return async (dispatch, getState) => {
    const { vulns } = getState().cyberRisk.customerData.availableFilterOptions;

    if (!forced && vulns && !vulns.loading) {
      // We have cached data, don't fetch again
      return;
    }

    dispatch(setCustomerDataFilterOptions({ vulns: { loading: true } }));

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/vulns/filter_options/v1/",
        { customer: true },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vulns filter options", e);
      throw e;
    }

    dispatch(
      setCustomerDataFilterOptions({
        vulns: {
          loading: false,
          vulnsCveNames: json.result.cve_names,
          vulnsSoftware: json.result.cpes,
        },
      })
    );
  };
};

export const fetchVendorVulnsFilterOptions = (
  vendorId,
  force,
  isSubsidiary = false
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, isSubsidiary, tpvmSession);

    if (
      !force &&
      vendor &&
      vendor.availableFilterOptions &&
      vendor.availableFilterOptions.vulns &&
      !vendor.availableFilterOptions.vulns.loading
    ) {
      // We have cached data for this vendor, don't fetch again
      return;
    }

    dispatch(
      setVendorFilterOptions(
        vendorId,
        { vulns: { loading: true } },
        isSubsidiary,
        tpvmSession
      )
    );

    const opts = {
      vendor_id: vendorId,
      is_subsidiary: isSubsidiary,
    };

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/vulns/filter_options/v1/",
        opts,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vulns filter options", e);
      throw e;
    }

    dispatch(
      setVendorFilterOptions(
        vendorId,
        {
          vulns: {
            loading: false,
            vulnsCveNames: json.result.cve_names,
            vulnsSoftware: json.result.cpes,
          },
        },
        isSubsidiary,
        tpvmSession
      )
    );
  };
};

export const fetchPortfolioVulnsFilterOptions = (forced) => {
  return async (dispatch, getState) => {
    const { portfolioVulns } =
      getState().cyberRisk.customerData.availableFilterOptions;

    if (!forced && portfolioVulns && !portfolioVulns.loading) {
      // We have cached data, don't fetch again
      return;
    }

    dispatch(
      setCustomerDataFilterOptions({ portfolioVulns: { loading: true } })
    );

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vulns/filter_options/vendorportfolio/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching portfolio vulns filter options", e);
      throw e;
    }

    dispatch(
      setCustomerDataFilterOptions({
        portfolioVulns: {
          loading: false,
          vulnsCveNames: json.result.cve_names,
          risksByCVE: json.result.risks_by_cve,
        },
      })
    );
  };
};

export const fetchVulnsByCPE = (
  cpeName,
  isCustomer,
  hostnames = [],
  forceRefresh = false
) => {
  return async (dispatch, getState) => {
    const hostsHash = hashCode(hostnames);
    let currentData = getState().cyberRisk.vulnsByCPE[cpeName];
    if (isCustomer) {
      currentData =
        getState().cyberRisk.customerVulnsByCPEByHostnames[cpeName] &&
        getState().cyberRisk.customerVulnsByCPEByHostnames[cpeName][hostsHash];
    }

    if (
      !forceRefresh &&
      currentData &&
      !currentData.loading &&
      !currentData.error
    ) {
      // We have cached CVEs for this CPE
      return currentData;
    }

    let action = setVulnsByCPE;
    if (isCustomer) {
      action = (cpeName, newData) =>
        setCustomerVulnsByCPEByHostnames(cpeName, hostsHash, newData);
    }

    dispatch(action(cpeName, { loading: true }));

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vulns/by_cpe/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            customer: isCustomer,
            cpe_name: cpeName,
            hostnames: hostnames,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        action(cpeName, {
          loading: false,
          error: e,
        })
      );
      LogError("Error fetching vulns by CPE", e);
      return;
    }

    const newData = json.result;
    newData.loading = false;
    newData.error = null;

    dispatch(action(cpeName, newData));

    return newData;
  };
};

export const fetchCPEKnownExploitedVulnCountsByRiskID = (
  isCustomer,
  isSubsidiary,
  vendorId,
  forceRefresh = false
) => {
  return async (dispatch, getState) => {
    let orgData;
    const tpvmSession = grabTPVMSession(getState);
    let vendor;
    if (isCustomer) {
      orgData = getState().cyberRisk.customerData;
    } else {
      orgData = getVendorData(getState, vendorId, isSubsidiary, tpvmSession);
    }

    if (
      !forceRefresh &&
      orgData &&
      orgData.kevCounts &&
      !orgData.kevCounts.loading &&
      !orgData.kevCounts.error
    ) {
      // We have cached CVEs for this set of risks
      return orgData.kevCounts;
    }

    let setDataAction;
    if (isCustomer) {
      setDataAction = setCustomerData;
    } else {
      setDataAction = (data) =>
        setVendorData(vendorId, data, isSubsidiary, tpvmSession);
    }
    dispatch(setDataAction({ kevCounts: { loading: true } }));

    const riskIds = [];
    const risks = orgData?.summary?.result?.risks;
    if (!risks) {
      const e = "no risks to lookup";
      dispatch(setDataAction({ kevCounts: { loading: false, error: e } }));
      LogError("Error fetching kev counts by CPE", e);
      return;
    }

    risks.forEach((r) => {
      if (
        r.id.startsWith("vulnerable_software_version:") ||
        r.id.startsWith("verified_vuln:")
      ) {
        riskIds.push(r.id);
      }
    });

    let json;
    if (riskIds.length > 0) {
      try {
        json = await FetchCyberRiskUrl(
          "vulns/kevs_by_cpe/v1/",
          {},
          {
            method: "POST",
            body: JSON.stringify({
              is_customer: isCustomer,
              risk_ids: riskIds,
            }),
          },
          dispatch,
          getState
        );
      } catch (e) {
        dispatch(
          setDataAction({
            kevCounts: {
              loading: false,
              error: e,
            },
          })
        );
        LogError("Error fetching kev counts by CPE", e);
        return;
      }
    }

    const newData = {
      loading: false,
      error: null,
      counts: json?.counts,
    };
    dispatch(setDataAction({ kevCounts: newData }));
    return newData;
  };
};

export const fetchVulnRiskIDsByCVE = (cveName) => {
  return async (dispatch, getState) => {
    let currentData = getState().cyberRisk.vulnRiskIDsByCVE[cveName];

    if (currentData && !currentData.loading && !currentData.error) {
      // We have cached risks for this CVE
      return currentData;
    }

    dispatch(setVulnRiskIDsByCVE(cveName, { loading: true }));

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vulns/risks/v1/",
        { cve_name: cveName },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setVulnRiskIDsByCVE(cveName, {
          loading: false,
          error: e,
        })
      );
      LogError("Error fetching vulns by CPE", e);
      return;
    }

    const newData = json.result;
    newData.loading = false;
    newData.error = null;

    dispatch(setVulnRiskIDsByCVE(cveName, newData));

    return newData;
  };
};

export const fetchCustomerScoringAlgorithmChange = (forceRefresh) => {
  return async (dispatch, getState) => {
    const { scoringChange } = getState().cyberRisk.customerData;
    const newScoringChange = {};
    let json;

    if (scoringChange && scoringChange.loading) {
      return scoringChange;
    }

    if (
      !forceRefresh &&
      scoringChange &&
      !scoringChange.loading &&
      !scoringChange.error
    ) {
      // We have cached scoringChange data, don't fetch again
      return scoringChange;
    }

    dispatch(setCustomerData({ scoringChange: { loading: true } }));

    try {
      json = await FetchCyberRiskUrl(
        "vendor/scoring_algorithm_change/v1/",
        { customer: true },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setCustomerData({
          scoringChange: {
            loading: false,
            error: {
              errorText: "Error fetching scoring change information",
              actionText: "Try again",
              actionOnClick: () =>
                dispatch(fetchCustomerScoringAlgorithmChange(true)),
            },
          },
        })
      );
      LogError("Error fetching scoring change", e);
      return { error: "Error fetching scoring change" };
    }

    newScoringChange.result = json.result;
    newScoringChange.loading = false;
    newScoringChange.error = null;

    dispatch(setCustomerData({ scoringChange: newScoringChange }));

    return newScoringChange;
  };
};

export const fetchVendorScoringAlgorithmChange = (vendorId, forceRefresh) => {
  return async (dispatch, getState) => {
    const vendor = getState().cyberRisk.vendors[vendorId];
    const newScoringChange = {};
    let json;

    if (vendor && vendor.scoringChange && vendor.scoringChange.loading) {
      return vendor.scoringChange;
    }

    if (
      !forceRefresh &&
      vendor &&
      vendor.scoringChange &&
      !vendor.scoringChange.loading &&
      !vendor.scoringChange.error
    ) {
      // We have cached scoringChange data for this vendor, don't fetch again
      return vendor.scoringChange;
    }

    dispatch(setVendorData(vendorId, { scoringChange: { loading: true } }));

    try {
      json = await FetchCyberRiskUrl(
        "vendor/scoring_algorithm_change/v1/",
        { vendor_id: vendorId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setVendorData(vendorId, {
          scoringChange: {
            loading: false,
            error: {
              errorText: "Error fetching vendor scoring change information",
              actionText: "Try again",
              actionOnClick: () => dispatch(fetchVendorVulns(vendorId, true)),
            },
          },
        })
      );
      LogError("Error fetching vendor scoring change", e);
      return { error: "Error fetching vendor scoring change" };
    }

    newScoringChange.result = json.result;
    newScoringChange.loading = false;
    newScoringChange.error = null;

    dispatch(setVendorData(vendorId, { scoringChange: newScoringChange }));

    return newScoringChange;
  };
};

export const setCompanyDetails = (orgName, mainHostname) => {
  return async (dispatch, getState) => {
    const params = {};
    if (orgName) {
      params.org_name = orgName;
    }
    if (mainHostname) {
      params.main_hostname = mainHostname;
    }

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "accounts/organisation/v1/",
        params,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting company details", e);

      // rethrow so the caller can catch in a promise
      throw e;
    }

    if (json && json.status === "ERROR") {
      throw new Error(json.error);
    }

    if (mainHostname) {
      // Main hostname has changed, refresh the view data
      dispatch(fetchCustomerSummaryAndCloudscans(true));
    }

    if (orgName) {
      const { authInfo } = getState().common;
      if (authInfo && authInfo.orgId) {
        // for appliances there's...
        dispatch(
          setCyberRiskAuthInfo({
            ...authInfo,
            orgName,
          })
        );
      } else {
        // for everyone else there's...
        dispatch(setCurrentOrgName(orgName));
      }
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// addCustomerCloudscan adds a cloudscan to a customers list of websites.
// This will result in the cloudscan being used as part of the customers CSTAR score.
export const addCustomerCloudscan = (url, vendorId = 0) => {
  return async (dispatch, getState) => {
    let json;

    if (
      url.length === 0 ||
      !validateUrlOrIp(url) ||
      ipIsZeroes(url) ||
      ipIsLocal(url)
    ) {
      throw new Error(
        isIPAddress(url)
          ? "Please enter a valid, public IP address"
          : "Please enter a valid domain"
      );
    }

    try {
      json = await FetchCyberRiskUrl(
        "customer/cloudscan/v1/",
        { url, vendor_id: vendorId },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error adding customer cloudscan", e);
      throw new Error(e);
    }

    if (!json || json.status === ApiResponses.NO_VENDOR_FOUND) {
      throw new Error("Please check your domain or IP address and try again.");
    }

    if (json.status === ApiResponses.DUPLICATE_CLOUDSCAN_EXISTS) {
      throw "That domain already exists. If the domain does not appear in the list, try adjusting your filters or refreshing the page.";
    }

    if (!json.result) {
      throw new Error("Please check your domain or IP address and try again.");
    }

    if (json.result.pending) {
      if (isIPAddress(url)) {
        dispatch(
          addMessageAlert({
            message: `${url} has been added, but it is currently inactive`,
            type: BannerType.WARNING,
          })
        );
      } else {
        dispatch(
          addMessageAlert({
            message: `${url} is taking longer than expected to scan`,
            type: BannerType.WARNING,
            subMessages: ["It has been added, but it is currently inactive."],
          })
        );
      }
    } else {
      dispatch(addDefaultSuccessAlert(`Successfully added ${url}.`));
    }

    dispatch(clearDomains());

    if (vendorId) {
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
      dispatch(clearVendorIPAddresses(vendorId));
    } else {
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(fetchBreachsightSubsidiaries(true, true));
      dispatch(fetchBreachSightOverviewReport(true));
      dispatch(fetchBreachSightCompetitorAnalysisReport(true));
      dispatch(fetchBreachSightRiskBreakdownReport(true));
      dispatch(clearCustomerIPAddresses());
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const fetchVendorSurveyCounts = (vendorId, forceRefresh = false) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const surveyCounts = _get(vendor, "surveyCounts", null);

    if (!forceRefresh && !!surveyCounts) {
      // cached, dont fetch again
      return surveyCounts;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/list/counts/v1/",
        {
          vendor_id: vendorId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendors survey counts", e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          surveyCounts: json.counts,
        },
        false,
        tpvmSession
      )
    );

    return json;
  };
};

export const fetchVendorSurveyUnreadMessageCount = (
  vendorId,
  forceRefresh = false
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const unreadCounts = _get(vendor, "unreadSurveyMessageCount", null);

    if (!forceRefresh && !!unreadCounts) {
      // cached, dont fetch again
      return unreadCounts;
    }
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/unread_message_count/v1/",
        {
          vendor_id: vendorId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendors survey unread message count", e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          unreadSurveyMessageCount: json.unreadMessageCount,
        },
        false,
        tpvmSession
      )
    );
    return json;
  };
};

export const fetchResendableSurveysForVendor = (vendorId, force = false) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    if (!force && vendor && vendor.resendableSurveys) {
      return vendor.resendableSurveys;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/list/mini/v1",
        {
          vendor_id: vendorId,
          has_status: [SurveyStatus.Complete, SurveyStatus.Cancelled].join(","),
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching resendable surveys for vendor", e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          resendableSurveys: json || [],
        },
        false,
        tpvmSession
      )
    );

    return json || [];
  };
};

export const fetchPendingSurveysForVendor =
  (vendorId, force = false) =>
  async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    if (!force && vendor && vendor.resendableSurveys) {
      return vendor.resendableSurveys;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/list/mini/v1",
        {
          vendor_id: vendorId,
          has_status: [SurveyStatus.Sent, SurveyStatus.InProgress].join(","),
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching pending surveys for vendor", e);
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          pendingSurveys: json || [],
        },
        false,
        tpvmSession
      )
    );

    return json || [];
  };

export const fetchVendorSurveysForRequestRemediation = (
  vendorId,
  force = false
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    if (!force && vendor && vendor.surveysForRequestRemediation) {
      return vendor.surveysForRequestRemediation;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          surveysForRemediationLoading: true,
        },
        false,
        tpvmSession
      )
    );

    let json;
    try {
      // Get all surveys that are not archived, not cancelled, and have risks
      json = await FetchCyberRiskUrl(
        "surveys/list/v1",
        {
          vendorId: vendorId,
          limit: 0,
          offset: 0,
          sort_col: "date_due",
          sort_dir: "desc",
          archived: false,
          not_has_status: SurveyStatus.Cancelled,
          has_risks: true,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching surveys for request remediation", e);
      dispatch(
        setVendorData(
          vendorId,
          {
            surveysForRemediationLoading: false,
          },
          false,
          tpvmSession
        )
      );
      throw e;
    }

    dispatch(
      setVendorData(
        vendorId,
        {
          surveysForRequestRemediation: json.result || [],
          surveysForRemediationLoading: false,
        },
        false,
        tpvmSession
      )
    );

    return json.result || [];
  };
};

export const fetchSurveysForRemediation = (
  vendorId,
  force = false,
  filters = undefined
) => {
  return async (dispatch, getState) => {
    let tpvmSession;

    const { surveysForRemediation, filters: currentFilters } =
      getState().cyberRisk.customerData;
    const newFilters = filters || currentFilters;

    if (vendorId) {
      tpvmSession = grabTPVMSession(getState);
      const vendor = getVendorData(getState, vendorId, false, tpvmSession);
      if (!force && vendor && vendor.surveysForRemediation) {
        return vendor.surveysForRemediation;
      }
    } else {
      if (
        !force &&
        surveysForRemediation &&
        (!filters || _isEqual(filters, currentFilters))
      ) {
        return surveysForRemediation;
      }
    }

    const opts = {
      limit: 0,
      offset: 0,
      sort_col: "date_due",
      sort_dir: "desc",
      archived_and_unarchived: true,
      not_has_status: SurveyStatus.Cancelled,
      has_risks_in_remediation: true,
      vendor_label_ids: newFilters.vendorLabelIds,
      vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
      vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
      include_unlabeled: newFilters.includeUnlabeled,
      min_score: newFilters.minScore,
      max_score: newFilters.maxScore,
      vendor_tiers: newFilters.vendorTiers,
      vendor_assessment_classifications:
        newFilters.vendorAssessmentClassifications,
      vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
      vendor_assessment_authors_not:
        newFilters.vendorAssessmentAuthorDoNotMatch,
      vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
      vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
      vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
      vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
      portfolio_ids: newFilters.portfolioIds,
      // since the attribute filters are complex we serialise them to json
      attributes: JSON.stringify(newFilters.selectedAttributes),
      survey_type_ids: newFilters.vendorSurveyTypes,
      evidence_type_ids: newFilters.vendorEvidenceTypes,
      fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
    };

    if (vendorId) {
      dispatch(
        setVendorData(
          vendorId,
          { surveysForRemediationLoading: true },
          false,
          tpvmSession
        )
      );
      opts.vendorId = vendorId;
    } else {
      dispatch(setCustomerData({ surveysForRemediationLoading: true }));
    }

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "surveys/list/v1",
        opts,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching surveys for remediation", e);
      if (vendorId) {
        dispatch(
          setVendorData(
            vendorId,
            { surveysForRemediationLoading: false },
            false,
            tpvmSession
          )
        );
      } else {
        dispatch(setCustomerData({ surveysForRemediationLoading: false }));
      }
      throw e;
    }

    if (vendorId) {
      dispatch(
        setVendorData(
          vendorId,
          {
            surveysForRemediation: json.result || [],
            surveysForRemediationLoading: false,
          },
          false,
          tpvmSession
        )
      );
    } else {
      dispatch(
        setCustomerData({
          surveysForRemediation: json.result || [],
          surveysForRemediationLoading: false,
        })
      );
    }

    return json.result || [];
  };
};

export const addExistingSurveyToSharedAssessment = (
  surveyId,
  name,
  description = ""
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "prefilledsurvey/add/existing/v1/",
        {
          name,
          description,
          survey_id: surveyId,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error adding survey to Trust Page", e);

      throw new Error(
        "Error adding survey to Trust Page. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return;
  };
};

export const addNewSurveyToSharedAssessment = (
  surveyTypeId,
  sections,
  name,
  description = ""
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "prefilledsurvey/draft/new/v1/",
        {
          name,
          description,
          survey_type_id: surveyTypeId,
          sections: sections.join(","),
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error adding survey to Trust Page", e);

      throw new Error(
        "Error adding survey to Trust Page. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// createVendorSurvey starts a new survey for the given vendorId, sent to the given email address.
export const createVendorSurvey = (
  name,
  vendorId,
  toEmails,
  toFirstNames,
  message,
  surveyTypeId,
  sections,
  dueDate,
  resendInterval,
  resendDate,
  recipientReminderDate,
  isManagementAnalystSession,
  managedOrgId,
  riskVisibility
) => {
  return async (dispatch, getState) => {
    const { authInfo, userData } = getState().common;
    const { customerData } = getState().cyberRisk;
    let customerVendorName;
    if (isManagementAnalystSession) {
      customerVendorName = _get(
        getState().cyberRisk.managedVendorData,
        `[${managedOrgId}].name`,
        ""
      );
    } else {
      customerVendorName = getCustomerOrgNameFromState(
        authInfo,
        userData,
        customerData
      );
    }

    let resp;
    try {
      resp = await FetchCyberRiskUrl(
        "newsurvey/v1/",
        _pickBy({
          name: name,
          vendor_id: vendorId,
          recipient_email_addresses: toEmails,
          recipient_first_names: toFirstNames,
          email_text: message,
          type_id: surveyTypeId,
          sections: sections.join(","),
          customer_vendor_name: customerVendorName,
          due_date: dueDate,
          recipient_reminder_date: recipientReminderDate,
          resend_interval: resendInterval,
          resend_date: resendDate,
          risk_visibility: riskVisibility,
        }),
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating vendor survey", e);

      throw e;
    }

    // refresh survey data
    dispatch(getSurveyListV2(true));
    dispatch(getSurveyListV2(true, vendorId));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // if it's a management session refresh the assessment list
    if (isManagementAnalystSession) {
      dispatch(refreshLatestVendorAssessmentForVendor(vendorId));
    }
    // Return the surveyId if provided in the response
    return resp ? resp.surveyId : null;
  };
};

// cancelSurvey takes a survey id and cancels it.
export const cancelSurvey = (surveyID, vendorID) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "cancelsurvey/v1/",
        { survey_id: surveyID },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error cancelling survey", e);

      throw new Error(
        "Error cancelling the questionnaire. Please try again later."
      );
    }

    if (!json || json.status !== "OK") {
      throw new Error(
        "Error cancelling the questionnaire. Please try again later."
      );
    }

    // update global survey data
    // update global survey data. Also fetch the reminders, timeline and messages
    // as they will me updated by this action.
    dispatch(fetchSurveyDetails(surveyID, true));
    dispatch(fetchSurveyMessages(surveyID, true, true));
    dispatch(fetchSurveyTimeline(surveyID, true));
    dispatch(fetchSurveyReminders(surveyID, true));

    // Refresh any questionnaire lists
    dispatch(refreshSurveyListsIfNecessary(vendorID));

    // clear the remediation requests in case this affects them
    dispatch(clearRemediationRequestData());

    // refresh vendor data - summary may need to update as questionnaire values may change
    dispatch(refreshVendorListsAfterChange([vendorID]));

    // If there's an assessment draft in progress for this vendor, refresh the evidence list
    dispatch(refreshEvidenceForLatestAssessment(vendorID));
  };
};

export const setSurveyArchived = (surveyID, archived = false) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "surveyarchive/v1/",
        {
          survey_id: surveyID,
          archived: archived,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting survey archived status", e);

      throw new Error(
        "Error setting questionnaire archived status. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // need refresh remediation redux state in case things are changed here
    dispatch(fetchRemediationRequestListForAllVendors(true));

    return dispatch(fetchSurveyDetails(surveyID, true));
  };
};

export const deleteSurvey = (surveyID) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/v1/",
        {
          survey_id: surveyID,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting survey archived status", e);
      throw new Error(
        "Error deleting questionnaire. Please contact UpGuard Support."
      );
    }
  };
};

// excludeSurveyFromScoring takes a survey id and excludes it from / includes it for vendor scoring
export const excludeSurveyFromScoring = (surveyID, exclude) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "excludesurvey/v1/",
        { survey_id: surveyID, exclude: exclude },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      const errMsg = `Error ${exclude ? "excluding" : "including"} survey ${
        exclude ? "from" : "for"
      } scoring`;

      LogError(errMsg, e);

      throw new Error(`${errMsg}. Please try again later.`);
    }

    if (!json || json.status !== "OK") {
      throw new Error(`${errMsg}. Please try again later.`);
    }

    // update global survey data
    dispatch(fetchSurveyDetails(surveyID, true));
    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// togglePrefilledSurveyInclusionInScoring toggles the inclusion status of a public survey in the vendor scoring/risks
export const togglePrefilledSurveyInclusionInScoring = (surveyID, include) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "publicsurveyinclusion/v1/",
        { survey_id: surveyID, include },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      const errMsg = `Error ${include ? "including" : "excluding"} survey ${
        include ? "in" : "from"
      } scoring.`;

      LogError(errMsg, e);

      throw new Error(`${errMsg}. Please try again later.`);
    }

    if (!json || json.status !== "OK") {
      throw new Error(`${errMsg}. Please try again later.`);
    }
  };
};

export const setPrefilledSurveyPublished = (surveyID, published = false) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "prefilledsurvey/publish/v1/",
        {
          survey_id: surveyID,
          published: published,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting survey publish status", e);

      throw new Error(
        "Error publishing shared assessment. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const setPrefilledSurveyDeleted = (surveyID) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "prefilledsurvey/delete/v1/",
        {
          survey_id: surveyID,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting prefilled survey", e);

      throw new Error(
        "Error deleting shared assessment. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// removeCustomerCloudscan removes a cloudscan from a customers list of websites and from their CSTAR score calculation.
export const removeCustomerCloudscan = (url, vendorId = 0) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "customer/cloudscan/v1/",
        { url, vendor_id: vendorId },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error removing customer cloudscan", e);
    }

    if (!json || json.status !== "OK") {
      throw new Error(`Error removing ${url}`);
    }

    dispatch(clearDomains());

    if (vendorId) {
      dispatch(fetchVendorSummaryAndCloudscans(vendorId, true));
      dispatch(clearVendorIPAddresses(vendorId));
    } else {
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(clearCustomerIPAddresses());
      dispatch(fetchBreachsightSubsidiaries(true, true));
      dispatch(fetchBreachSightOverviewReport(true));
      dispatch(fetchBreachSightCompetitorAnalysisReport(true));
      dispatch(fetchBreachSightRiskBreakdownReport(true));
      dispatch(fetchDomainPortfolios(true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const triggerVendorPrimaryDomainRescan = (
  id,
  initScanOnly = false,
  history,
  isCustomer
) => {
  return async (dispatch, getState) => {
    // Set the vendor into a loading state. If initScanOnly is the case, it should already be in one.
    if (isCustomer) {
      dispatch(setCustomerData({ summary: null }));
    } else {
      dispatch(setVendorData(id, { summary: null }));
    }

    let json = {};

    try {
      json = await FetchCyberRiskUrl(
        "vendor/rescan/v1",
        { id, init_scan: initScanOnly },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error triggering vendor primary domain rescan", e);
    }

    if (json && json.result === "REFRESH") {
      // Add a quick whisper for successful scan returned
      dispatch(
        addDefaultSuccessAlert(`${json.hostname} was scanned successfully.`)
      );

      // If the returned vendor_id is different from the id passed in,
      // it means that the original vendor was migrated away during the rescan
      // and its cloudscans have been moved under a different vendor.
      // So we need to redirect the browser to the new vendor and
      // remove the old vendor from the customers watchlist (so they cant click it again).
      if (json.vendor_id && json.vendor_id !== parseInt(id)) {
        dispatch(setVendorData(id, { summary: null }));
        history.push(`/vendor/${json.vendor_id}`);
        dispatch(setCustomerData({ vendors: { loading: true } }));
      }
      return;
    }

    if (!initScanOnly) {
      // We didn't get a scan back. Likely a timeout.
      dispatch(
        addMessageAlert({
          message: "This scan appears to be taking a long time",
          type: BannerType.WARNING,
          subMessages: ["Please check back again later."],
        })
      );
    }
  };
};

export const firstViewVendorRescan = (id) => {
  return async (dispatch, getState) => {
    const vendor = getState().cyberRisk.vendors[id];
    if (vendor && vendor.firstViewScanned) {
      // no need to do anything
      return Promise.resolve();
    }

    dispatch(setVendorData(id, { firstViewScanned: true }));

    // Trigger vendor primary domain rescan with initScanOnly flag so it will only rescan if it hasn't been
    // initScanned yet
    return dispatch(triggerVendorPrimaryDomainRescan(id, true, history));
  };
};

export const fetchCustomerLimitData = () => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "customerlimit/v1/",
        {},
        null,
        dispatch,
        getState
      );
      dispatch(
        setCustomerData({
          vendorWatchLimit: json.vendorWatchLimit,
          vendorWatchLimitIsHard: json.vendorWatchLimitIsHard,
          vendorWatchCount: json.vendorWatchCount,
          vendorLookupLimit: json.vendorLookupLimit,
          vendorLookupLimitIsHard: json.vendorLookupLimitIsHard,
          vendorLookupsOneTime: json.vendorLookupsOneTime,
          vendorLookupCount: json.vendorLookupCount,
          billingPeriodEndDate: json.billingPeriodEndDate,
          instantReportsAvailable: json.instantReportsAvailable,
          vendorManagementLimit: json.vendorManagementLimit,
          vendorManagementCount: json.vendorManagementCount,
        })
      );
    } catch (e) {
      LogError("Error fetching customer limit data", e);
    }
  };
};

export const fetchCustomerVendorsDataSimple = (
  paging,
  vendorNameFilter,
  cveNameFilter
) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "watchlist/v1/",
        {
          num_points: 30,
          page_num: paging.pageNum,
          page_size: paging.pageSize,
          sort_by: paging.sortBy,
          sort_dir: paging.sortDir,
          name_prefix: vendorNameFilter,
          cve_name: cveNameFilter,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching customer vendors data", e);

      throw e;
    }

    return json;
  };
};

export const fetchCustomerVendorsData = (
  forced = false,
  paging,
  filters,
  srch = undefined
) => {
  return async (dispatch, getState) => {
    const { vendors, filters: currentFilters } =
      getState().cyberRisk.customerData;
    const newVendors = {};
    let json;

    if (
      !forced &&
      (!paging || _isEqual(paging, vendors.paging)) &&
      (!filters || _isEqual(filters, currentFilters)) &&
      (!srch || srch === getState().cyberRisk.vendorSearch) &&
      vendors &&
      !vendors.loading &&
      !vendors.error
    ) {
      // We have cached vendors, don't fetch again
      return;
    }

    if (paging && !_isEqual(paging, vendors.paging)) {
      // Store paging deets in localStorage to persist between sessions
      setLocalStorageItem("vendorsPaging", {
        sortBy: paging.sortBy,
        sortDir: paging.sortDir,
      });
    }

    let newPaging = paging || vendors.paging;
    const newFilters = filters || currentFilters;

    // check if we're sorting by a vendor attribute
    if (newPaging.sortBy.startsWith(VendorAttributeSortByPrefix)) {
      const { vendorAttributeDefinitions } = getState().cyberRisk;
      const sortByAttribute = newPaging.sortBy.replace(
        VendorAttributeSortByPrefix,
        ""
      );

      // if this is not a valid vendor attribute, fall back to the default paging
      if (
        vendorAttributeDefinitions &&
        !vendorAttributeDefinitions.find((d) => d.name === sortByAttribute)
      ) {
        newPaging = cyberRiskInitialState.customerData.vendors.paging;
      }
    }

    dispatch(
      setCustomerData({
        vendors: {
          ...vendors,
          loading: true,
          paging: newPaging,
          filters: newFilters,
        },
      })
    );

    try {
      json = await FetchCyberRiskUrl(
        "watchlist/v1/",
        {
          num_points: 30,
          page_num: newPaging.pageNum,
          page_size: newPaging.pageSize,
          sort_by: newPaging.sortBy,
          sort_dir: newPaging.sortDir,
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          name_prefix: newFilters.namePrefix,
          vendor_tiers: newFilters.vendorTiers,
          vendor_assessment_classifications:
            newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          portfolio_ids: newFilters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
          srch,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching customer vendors data", e);
      dispatch(
        setCustomerData({
          vendors: {
            loading: false,
            error: {
              errorText: "Error fetching vendor list",
              actionText: "Try again",
              actionOnClick: () => dispatch(fetchCustomerVendorsData()),
            },
            paging: vendors.paging,
            filters: vendors.filters,
          },
        })
      );

      return;
    }

    newVendors.result = (json.vendors || []).map((thisVendor) => ({
      ...thisVendor,
      scores: MapArrayToUnixDateWithoutTimezone(thisVendor.scores || []).map(
        (item) => ({
          overall_score: item.overall_score,
          time: item.time,
        })
      ),
    }));

    newVendors.loading = false;
    newVendors.error = null;
    newVendors.paging = json.paging;
    newVendors.filters = json.filters;

    dispatch(setCustomerData({ vendors: newVendors }));

    // Let's also cache the name for each vendor we've retrieved too
    dispatch(
      setVendorsData(
        newVendors.result.reduce(
          (obj, vendor) => ({
            ...obj,
            [vendor.id]: {
              name: vendor.name,
              display_name: vendor.display_name,
              labels: vendor.labels,
              verified: vendor.verified,
              primary_hostname: vendor.primary_hostname,
            },
          }),
          {}
        )
      )
    );
  };
};

export const fetchCustomerLookupsData = (
  forced = false,
  paging,
  filters,
  tab
) => {
  return async (dispatch, getState) => {
    const { lookups, filters: currentFilters } =
      getState().cyberRisk.customerData;

    if (
      !forced &&
      (!paging || _isEqual(paging, lookups.paging)) &&
      (!filters || _isEqual(filters, currentFilters)) &&
      lookups &&
      (!tab || tab === lookups.tab) &&
      !lookups.loading &&
      !lookups.error
    ) {
      // We have cached lookups, don't fetch again
      return;
    }

    if (paging && !_isEqual(paging, lookups.paging)) {
      // Store paging deets in localStorage to persist between sessions
      setLocalStorageItem("lookupsPaging", {
        sortBy: paging.sortBy,
        sortDir: paging.sortDir,
      });
    }

    const newPaging = paging || lookups.paging;
    const newFilters = filters || currentFilters;
    const newTab = tab || lookups.tab;

    dispatch(
      setCustomerData({
        lookups: {
          ...lookups,
          loading: true,
          paging: newPaging,
          filters: newFilters,
          tab: tab,
        },
      })
    );

    let json;
    const newLookups = {};
    try {
      json = await FetchCyberRiskUrl(
        "vendor/lookups/v1/",
        {
          num_points: 30,
          page_num: newPaging.pageNum,
          page_size: newPaging.pageSize,
          sort_by: newPaging.sortBy,
          sort_dir: newPaging.sortDir,
          name_prefix: "",
          tab: tab,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching customer lookups data", e);

      dispatch(
        setCustomerData({
          lookups: {
            loading: false,
            error: {
              errorText: "Error fetching snapshot data",
              actionText: "Try again",
              actionOnClick: () => dispatch(fetchCustomerLookupsData()),
            },
            paging: lookups.paging,
            filters: lookups.filters,
            tab: lookups.tab,
            tabCounts: lookups.tabCounts,
          },
        })
      );
      throw e;
    }

    newLookups.result = (json.lookups || []).map((thisLookup) => ({
      ...thisLookup,
      scores: MapArrayToUnixDateWithoutTimezone(thisLookup.scores || []).map(
        (item) => ({
          overall_score: item.overall_score,
          time: item.time,
        })
      ),
    }));

    newLookups.loading = false;
    newLookups.error = null;
    newLookups.paging = json.paging;
    newLookups.filters = json.filters;
    newLookups.tab = json.tab;
    newLookups.tabCounts = json.tabCounts;

    dispatch(setCustomerData({ lookups: newLookups }));
    return json.lookups;
  };
};

/**
 * @param {number} vendorId
 * @param {boolean} forceRefresh
 * @param {boolean=false} useAdhocLookup
 * @param {boolean=false} noLoadingState
 * @param {boolean=false} isSubsidiary
 * @param {Partial<Filters>=} filters
 * @params {boolean=false} noUpdateState
 */
export const fetchVendorSummaryAndCloudscans = (
  vendorId,
  forceRefresh,
  useAdhocLookup = false,
  noLoadingState = false, // If set, this refresh will not change the loading state. Ie it will be done quietly.
  isSubsidiary = false,
  filters = undefined,
  noUpdateState = false
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, isSubsidiary, tpvmSession);
    const newSummaryData = {};
    let json;

    if (
      !forceRefresh &&
      vendor &&
      vendor.summary &&
      (vendor.summary.result || vendor.summary.loading) &&
      !vendor.summary.error
    ) {
      // We have cached cloudscans and summary data, don't fetch again
      return vendor.summary;
    }

    if (!noLoadingState && !noUpdateState) {
      dispatch(
        setVendorData(
          vendorId,
          { summary: { loading: true } },
          isSubsidiary,
          tpvmSession
        )
      );
    }

    const currentFilters = vendor?.filters || getDefaultFilters();
    const newFilters = filters || currentFilters;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/summary/v2/",
        {
          vendor_id: vendorId,
          num_days: 365,
          adhoc_lookup: useAdhocLookup,
          subsidiary: isSubsidiary,
          website_label_ids: newFilters.websiteLabelIds,
          website_label_ids_match_all: newFilters.websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: newFilters.websiteLabelIdsDoNotMatch,
          website_include_unlabeled: newFilters.websiteIncludeUnlabeled,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendor summary", e);

      if (noUpdateState) {
        throw e;
      }

      dispatch(
        setVendorData(
          vendorId,
          {
            summary: {
              loading: false,
              error: {
                errorText: "Error fetching vendor information",
                actionText: "Try again",
                actionOnClick: () =>
                  dispatch(fetchVendorSummaryAndCloudscans(vendorId)),
              },
            },
          },
          isSubsidiary,
          tpvmSession
        )
      );
      return { error: "Error fetching vendor information" };
    }

    if (json.status === "NOVENDOR") {
      LogError(
        `fetchVendorSummaryAndCloudscans: No linked vendor found with vendorId=${vendorId}`
      );
      throw new Error("No linked vendor");
    }

    newSummaryData.result = {
      ...json.result,
      totalFoundTargets: _get(vendor, "summary.result.totalFoundTargets", 0),
      overallScores:
        json.result && json.result.overallScores
          ? MapArrayToUnixDateWithoutTimezone(json.result.overallScores)
          : [],
      scoringChanges: json.result
        ? MapArrayToUnixDateWithoutTimezone(json.result.scoringChanges)
        : [],
    };

    if (newSummaryData.result.categorySummaries) {
      for (let cat in newSummaryData.result.categorySummaries) {
        newSummaryData.result.categorySummaries[cat].scores =
          MapArrayToUnixDateWithoutTimezone(
            newSummaryData.result.categorySummaries[cat].scores
          );
        newSummaryData.result.categorySummaries[cat].scoringChanges =
          MapArrayToUnixDateWithoutTimezone(
            newSummaryData.result.categorySummaries[cat].scoringChanges
          );
      }
    }

    if (newSummaryData.result.adjustedOverallScores) {
      newSummaryData.result.adjustedOverallScores =
        MapArrayToUnixDateWithoutTimezone(
          newSummaryData.result.adjustedOverallScores
        );
    }

    if (newSummaryData.result.adjustedBaseVendorScores) {
      newSummaryData.result.adjustedBaseVendorScores =
        MapArrayToUnixDateWithoutTimezone(
          newSummaryData.result.adjustedBaseVendorScores
        );
    }

    if (newSummaryData.result.baseSurveyScores) {
      newSummaryData.result.baseSurveyScores =
        MapArrayToUnixDateWithoutTimezone(
          newSummaryData.result.baseSurveyScores
        );
    }

    if (newSummaryData.result.customerPublicScores) {
      newSummaryData.result.customerPublicScores =
        MapArrayToUnixDateWithoutTimezone(
          newSummaryData.result.customerPublicScores
        );
    }

    const subdomainScansPreviouslyPending = _get(
      vendor,
      "summary.result.subdomainScansPending",
      false
    );
    if (
      (subdomainScansPreviouslyPending &&
        !newSummaryData.result.subdomainScansPending) ||
      (newSummaryData.result.totalFoundTargets === 0 &&
        newSummaryData.result.foundTargetsPending > 0)
    ) {
      // We've transitioned from the first stage of the subdomain scans to the next.
      // Store the original total pending targets so that we can report the percentage complete as we go.
      newSummaryData.result.totalFoundTargets =
        newSummaryData.result.foundTargetsPending;
    }

    newSummaryData.loading = false;
    newSummaryData.error = null;

    if (!noUpdateState) {
      dispatch(
        setVendorData(
          vendorId,
          {
            summary: newSummaryData,
            name: json.result.business.name,
            display_name: json.result.business.display_name,
            primary_hostname: json.result.business.primaryHostname,
            has_subsidiaries: !!json.result.vendorHasSubsidiaries,
            labels: json.result.labels,
            verified: !!json.result.verified,
            sharedAssessmentPublished:
              !!json.result.business.sharedAssessmentPublished,
            immutable: !!json.result.immutable,
          },
          isSubsidiary,
          tpvmSession
        )
      );

      // set subdomainScanStarted flag separately so that it isn't clobbered by subsequent requests
      // (and is therefore persistent for this vendor)
      dispatch(
        setSubdomainsScanStarted(
          vendorId,
          isSubsidiary,
          tpvmSession,
          newSummaryData?.result?.subdomainScanStarted
        )
      );

      const orgPerms = getState().common.userData.orgPermissions
        ? getState().common.userData.orgPermissions
        : [];
      if (orgPerms.includes(OrgAccessScoringChangeNotification)) {
        dispatch(fetchVendorScoringAlgorithmChange(vendorId, forceRefresh));
      }

      // get the kevs counts that we need for the risk profile
      dispatch(
        fetchCPEKnownExploitedVulnCountsByRiskID(
          false,
          isSubsidiary,
          vendorId,
          forceRefresh
        )
      );
    }

    return newSummaryData;
  };
};

// When setting cloudscan data, there are various locations that need to be updated.
// Abstracting that to its own function helps to avoid some things getting missed
const setCloudscanDataEverywhere = (
  dispatch,
  hostname,
  newResult,
  forceVendorRefresh,
  isCustomer
) => {
  if (forceVendorRefresh) {
    if (isCustomer) {
      dispatch(fetchCustomerSummaryAndCloudscans(true));
      dispatch(fetchBreachsightSubsidiaries(true, true));
      dispatch(clearCustomerIPAddresses());
    } else if (newResult.vendorId) {
      dispatch(fetchVendorSummaryAndCloudscans(newResult.vendorId, true));
      dispatch(clearVendorIPAddresses(newResult.vendorId));
    }
    dispatch(clearDomains());
  }

  // Finally update the cloudscan data
  dispatch(
    setCloudscanData(hostname, {
      result: newResult,
      rescanning: false,
      loading: false,
      error: null,
    })
  );
};

export const fetchVuln = (cveName) => {
  return async (dispatch, getState) => {
    const vuln = getState().cyberRisk.vulns[cveName];
    // no need to ever refresh vuln data during the lifecycle of a page
    if (vuln) {
      return vuln;
    }

    dispatch(
      setVulnData(cveName, {
        loading: true,
        error: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vulns/v1/",
        { cve_names: cveName },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setVulnData(cveName, {
          loading: false,
          error: {
            errorText:
              "Sorry, there was an error fetching data for this vulnerability.",
            actionText: "Try again",
            actionOnClick: () => dispatch(fetchVuln(cveName)),
          },
        })
      );

      LogError("Error fetching vuln", e);
      return;
    }

    dispatch(
      setVulnData(cveName, {
        loading: false,
        error: null,
        vulnData: json.result[cveName],
      })
    );
  };
};

export const ignoreVulns = (vulnsToIgnore) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vulns/ignore/v1/",
        null,
        { method: "POST", body: JSON.stringify(vulnsToIgnore) },
        dispatch,
        getState
      );
      // reset all the CVE lists we currently have in state as they might now be invalid
      dispatch(resetCustomerVulnsByCPEByHostnames());
    } catch (e) {
      LogError("Error ignoring vuln", e);
      throw e;
    }
  };
};

export const fetchCloudscanByHostname = (
  hostname,
  forceVendorRefresh,
  isCustomer,
  vendorId
) => {
  return async (dispatch, getState) => {
    const webscan = getState().cyberRisk.webscans[hostname];
    let json;

    if (webscan && !webscan.loading && !webscan.error) {
      // We have cached webscan data, don't fetch again
      return;
    }

    dispatch(setCloudscanData(hostname, { loading: true }));

    try {
      json = await FetchCyberRiskUrl(
        "cloudscan/details/v2/",
        { hostname, for_customer: isCustomer, vendor_id: vendorId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setCloudscanData(hostname, {
          loading: false,
          error: {
            errorText: "Sorry, there was an error fetching this cloudscan.",
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(
                fetchCloudscanByHostname(
                  hostname,
                  forceVendorRefresh,
                  isCustomer,
                  vendorId
                )
              ),
          },
        })
      );

      LogError("Error fetching cloudscan by hostname", e);
      return;
    }

    if (!json.result) {
      // Grab the vendorId and vendorName from the response - they're provided as top level keys
      // when there is no cloudscan record.
      const { vendorId, vendorName } = json;

      // Ask if the user should trigger a rescan
      dispatch(
        setCloudscanData(hostname, {
          loading: false,
          vendorId,
          vendorName,
          error: { errorText: `Sorry, ${hostname} hasn't been scanned yet.` },
        })
      );
      return;
    }

    setCloudscanDataEverywhere(
      dispatch,
      hostname,
      json.result,
      forceVendorRefresh,
      isCustomer
    );
  };
};

export const VENDOR_SEARCH_RESPONSE_LIMIT = 30;

export const runFetchVendorSearchResults = async (
  query,
  getState,
  dispatch,
  filters,
  maxResults = 0
) => {
  let json;

  if (!filters) {
    filters = getState().cyberRisk.customerData.filters;
  }

  const {
    vendorLabelIds,
    vendorLabelIdsMatchAll,
    vendorLabelIdsDoNotMatch,
    includeUnlabeled,
    minScore,
    maxScore,
    vendorTiers,
    portfolioIds,
    selectedAttributes,
    vendorAssessmentClassifications,
    vendorSurveyTypes,
    vendorEvidenceTypes,
    vendorAssessmentAuthors,
    vendorAssessmentAuthorDoNotMatch,
    vendorReassessmentStartDate,
    vendorReassessmentEndDate,
    vendorReassessmentDateBefore,
    vendorReassessmentDateBetween,
    assessedVendorsOnly,
    fourthPartyProductUUIDs,
  } = filters;
  try {
    json = await FetchCyberRiskUrl(
      "vendorsearch/v1/",
      {
        srch: query,
        limit: maxResults > 0 ? maxResults : VENDOR_SEARCH_RESPONSE_LIMIT,
        vendor_label_ids: vendorLabelIds,
        vendor_label_ids_match_all: vendorLabelIdsMatchAll,
        vendor_label_ids_do_not_match: vendorLabelIdsDoNotMatch,
        include_unlabeled: includeUnlabeled,
        min_score: minScore,
        max_score: maxScore,
        consider_empty_cloudscan_vendors: true,
        include_org_custom_vendors: true,
        vendor_tiers: vendorTiers,
        vendor_assessment_classifications: vendorAssessmentClassifications,
        portfolio_ids: portfolioIds,
        // since the attribute filters are complex we serialise them to json
        attributes: JSON.stringify(selectedAttributes),
        survey_type_ids: vendorSurveyTypes,
        evidence_type_ids: vendorEvidenceTypes,
        vendor_assessment_authors: vendorAssessmentAuthors,
        vendor_assessment_authors_not: vendorAssessmentAuthorDoNotMatch,
        vendor_reassessment_start: vendorReassessmentStartDate,
        vendor_reassessment_end: vendorReassessmentEndDate,
        vendor_reassessment_before: vendorReassessmentDateBefore,
        vendor_reassessment_between: vendorReassessmentDateBetween,
        assessed_vendors_only: assessedVendorsOnly,
        fourth_party_product_uuids: fourthPartyProductUUIDs,
      },
      null,
      dispatch,
      getState
    );
  } catch (e) {
    LogError("Error retrieving search results", e);
  }

  // Only update the results the query still matches what's in the state
  if (
    json &&
    json.status === "OK" &&
    query === getState().cyberRisk.vendorSearch.query
  ) {
    const watchedVendors = (json.watchedVendors || []).map((thisVendor) => ({
      ...thisVendor,
      scores: MapArrayToUnixDateWithoutTimezone(thisVendor.scores || []).map(
        (item) => ({
          overall_score: item.overall_score,
          time: item.time,
        })
      ),
    }));
    const lookups = (json.vendorLookups || []).map((thisLookup) => ({
      ...thisLookup,
      scores: MapArrayToUnixDateWithoutTimezone(thisLookup.scores || []).map(
        (item) => ({
          overall_score: item.overall_score,
          time: item.time,
        })
      ),
    }));

    const results = {
      vendors: json.vendors,
      watchedVendors,
      lookups,
      vendorsTotal: json.vendorsTotal,
    };
    dispatch(
      setVendorSearch({
        loading: false,
        results: results,
      })
    );
    return results;
  }
  return undefined;
};

const fetchCustomerVendorSearch = async (query, getState, dispatch) => {
  const {
    vendorLabelIds,
    vendorLabelIdsMatchAll,
    vendorLabelIdsDoNotMatch,
    includeUnlabeled,
    minScore,
    maxScore,
    vendorTiers,
    portfolioIds,
    selectedAttributes,
    vendorAssessmentClassifications,
    vendorAssessmentAuthors,
    vendorAssessmentAuthorDoNotMatch,
    vendorReassessmentStartDate,
    vendorReassessmentEndDate,
    vendorReassessmentDateBefore,
    vendorReassessmentDateBetween,
    vendorSurveyTypes,
    vendorEvidenceTypes,
    fourthPartyProductUUIDs,
  } = getState().cyberRisk.customerData.filters;

  const { vendors, filters: currentFilters } =
    getState().cyberRisk.customerData;

  let json;
  try {
    json = await FetchCyberRiskUrl(
      "watchlist/v1/",
      {
        num_points: 30,
        vendor_label_ids: vendorLabelIds,
        vendor_label_ids_match_all: vendorLabelIdsMatchAll,
        vendor_label_ids_do_not_match: vendorLabelIdsDoNotMatch,
        include_unlabeled: includeUnlabeled,
        min_score: minScore,
        max_score: maxScore,
        vendor_tiers: vendorTiers,
        vendor_assessment_classifications: vendorAssessmentClassifications,
        vendor_assessment_authors: vendorAssessmentAuthors,
        vendor_assessment_authors_not: vendorAssessmentAuthorDoNotMatch,
        vendor_reassessment_start: vendorReassessmentStartDate,
        vendor_reassessment_end: vendorReassessmentEndDate,
        vendor_reassessment_before: vendorReassessmentDateBefore,
        vendor_reassessment_between: vendorReassessmentDateBetween,
        portfolio_ids: portfolioIds,
        // since the attribute filters are complex we serialise them to json
        attributes: JSON.stringify(selectedAttributes),
        survey_type_ids: vendorSurveyTypes,
        evidence_type_ids: vendorEvidenceTypes,
        fourth_party_product_uuids: fourthPartyProductUUIDs,
        srch: query,
      },
      null,
      dispatch,
      getState
    );
  } catch (e) {
    LogError("Error searching for monitored vendors", e);
    dispatch(
      addDefaultUnknownErrorAlert("Error searching for monitored vendors")
    );
    dispatch(
      setCustomerData({
        searchVendors: {
          loading: false,
        },
      })
    );
    return;
  }

  const newVendors = {};
  newVendors.result = (json.vendors || []).map((thisVendor) => ({
    ...thisVendor,
    scores: MapArrayToUnixDateWithoutTimezone(thisVendor.scores || []).map(
      (item) => ({
        overall_score: item.overall_score,
        time: item.time,
      })
    ),
  }));

  newVendors.loading = false;

  dispatch(setCustomerData({ searchVendors: newVendors }));
};

// debounce the actual fetch used in the action below so we wait until the user has not typed a character for more than half a second
const debouncedRunFetchVendorSearchResults = _debounce(
  (query, dispatch, getState) => {
    runFetchVendorSearchResults(query, dispatch, getState);
    fetchCustomerVendorSearch(query, dispatch, getState);
  },
  500
);

/**
 * Fetch all vendors whose names start with a given search query. Save results to state.
 * @param {string} q - search query
 */
export const fetchVendorSearchResults = (q, force = false) => {
  return async (dispatch, getState) => {
    const prevVendorSearch = getState().cyberRisk.vendorSearch;
    const prevQuery = prevVendorSearch.query;
    const query = q.toLowerCase().trim();

    // If the query hasn't changed, leave it alone
    if (!force && prevQuery === query) return;

    if (shouldBypassSearch(query)) {
      // Don't kick off a huge search for a 2 character query. We'll just filter the frontend.
      // Kill off any previous loading state or ajax search results though.
      dispatch(
        setVendorSearch({
          loading: false,
          query,
          results: {},
        })
      );
      return {};
    }

    // If the prevQuery is a substring of the new query,
    // check if we have less than the Limit for each of the sections in the search results
    if (!force && query.startsWith(prevQuery)) {
      const filterFunc = (vendor) =>
        (vendor.display_name || vendor.name || "")
          .toLowerCase()
          .startsWith(query) ||
        (
          vendor.primary_hostname ||
          vendor.primaryHostname ||
          vendor.primaryDomain ||
          ""
        )
          .toLowerCase()
          .startsWith(query);
      let newVendors = prevVendorSearch.results.vendors || [];
      let newWatchedVendors = prevVendorSearch.results.watchedVendors || [];
      let newVendorLookups = prevVendorSearch.results.lookups || [];

      if (
        newVendors.length > 0 &&
        newVendors.length < VENDOR_SEARCH_RESPONSE_LIMIT
      ) {
        newVendors = newVendors.filter(filterFunc);
        newWatchedVendors = newWatchedVendors.filter(filterFunc);
        newVendorLookups = newVendorLookups.filter(filterFunc);
        // Vendors results were under the response limit and now filtered on the frontend,
        // so we needn't call the API again
        const results = {
          vendors: newVendors,
          watchedVendors: newWatchedVendors,
          lookups: newVendorLookups,
          vendorsTotal: newVendors.length,
        };
        dispatch(
          setVendorSearch({
            query,
            results: {
              vendors: newVendors,
              watchedVendors: newWatchedVendors,
              lookups: newVendorLookups,
              vendorsTotal: newVendors.length,
            },
          })
        );
        return results;
      }
    }

    dispatch(
      setVendorSearch({
        query,
        loading: true,
      })
    );

    dispatch(
      setCustomerData({
        searchVendors: {
          loading: true,
        },
      })
    );

    // Kick off a new search since we haven't filtered both, or either, of the previous search results
    debouncedRunFetchVendorSearchResults(query, getState, dispatch);
  };
};

export const refreshVendorSearchResults = () => {
  return async (dispatch, getState) => {
    const { query } = getState().cyberRisk.vendorSearch;

    // If the query hasn't changed, leave it alone
    if (shouldBypassSearch(query)) return;

    dispatch(
      setVendorSearch({
        query,
        loading: true,
      })
    );

    // Kick off a new search since we haven't filtered both, or either, of the previous search results
    debouncedRunFetchVendorSearchResults(query, getState, dispatch);
  };
};

// scanHostname can either scan a new hostname for the first time or
// forcibly rescan an existing hostname.
// If estimatedTimeCallback is provided, when we receive an estimate of the time it will
// take for the scan to complete, estimatedTimeCallback is called with that time.
export const scanHostname = (hostname, isCustomer, estimatedTimeCallback) => {
  return async (dispatch, getState) => {
    dispatch(setCloudscanData(hostname, { rescanning: true }));

    const handleTimeout = () => {
      dispatch(
        addMessageAlert({
          message: "This scan appears to be taking a long time",
          type: BannerType.WARNING,
          subMessages: ["Please check back again later."],
        })
      );
      dispatch(setCloudscanData(hostname, { rescanning: false }));
    };

    const handleUnknownError = () => {
      dispatch(
        setCloudscanData(hostname, {
          rescanning: false,
          error: {
            errorText: `Error performing scan on ${hostname}`,
            actionText: "Try again",
            actionOnClick: () => dispatch(scanHostname(hostname, isCustomer)),
          },
        })
      );

      // GT: this is required because of disperate pop-up requirements on error..
      dispatch(
        setCloudscanError(hostname, {
          scanError: true,
          scanErrorDate: new Date(),
        })
      );
    };

    const handleScanError = (errorCount, isDisabled) => {
      //dead scan
      dispatch(
        setCloudscanData(hostname, {
          rescanning: false,
          error: {
            errorText: `Host ${hostname} has failed to scan ${errorCount} times`,
            actionText: "Try again",
            actionOnClick: () => dispatch(scanHostname(hostname, isCustomer)),
          },
        })
      );

      const label = isIPAddress(hostname) ? "IP address" : "Domain";

      if (isDisabled) {
        const scanResult = getState().cyberRisk.webscans[hostname].result || {};
        const wasActive = scanResult.noResult === false;
        if (wasActive) {
          dispatch(
            addSimpleErrorAlert(`${label} is now inactive`, [
              `${hostname} is now inactive. The host may be offline. It will no longer appear in the Active list.`,
            ])
          );
          setCloudscanDataEverywhere(
            dispatch,
            hostname,
            { ...scanResult, noResult: true },
            true,
            isCustomer
          );
        } else {
          dispatch(
            addSimpleErrorAlert(`${label} is inactive`, [
              `${hostname} is still inactive. The host may be offline.`,
            ])
          );
        }
      } else {
        dispatch(
          addDefaultWarningAlert("Rescan failed", [
            `${hostname} has failed to scan ${errorCount} ` +
              (errorCount === 1 ? `time` : `times`) +
              `. The host may be offline. After failing 3 times it will no longer appear in the Active list.`,
          ])
        );
      }
    };

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "cloudscan/run_scan/v1/",
        { hostname },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      if (e.response.status === 502) {
        handleTimeout();
        return;
      } else if (e.response.status === 422) {
        if (e.json.error_count && e.json.error_count > 0) {
          handleScanError(e.json.error_count);
          return;
        }

        // Some other invalid param
        dispatch(setCloudscanData(hostname, { rescanning: false }));
        dispatch(
          addDefaultUnknownErrorAlert(`Error scanning ${hostname}`, [
            e.json.error,
          ])
        );
        return;
      } else {
        LogError("Error scanning hostname", e);
        handleUnknownError();
        return;
      }
    }

    // We've just queued a scan. We now need to poll for results.
    hostname = json.requested_host;
    const {
      error_count: errorCount,
      requested_at: requestedAt,
      estimated_time_ms: estimatedTimeMs,
    } = json;

    if (estimatedTimeCallback) {
      estimatedTimeCallback(estimatedTimeMs);
    }

    // Stop polling after 2 minutes
    const stopPollingAt = moment().add(2, "m");
    while (stopPollingAt.isAfter(moment())) {
      // Wait a couple of seconds between requests
      await new Promise((resolve) => setTimeout(resolve, 2000));

      let pollJson;
      try {
        pollJson = await FetchCyberRiskUrl(
          "cloudscan/poll_scan/v1/",
          { hostname, requested_at: requestedAt, for_customer: isCustomer },
          { method: "GET" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError("error polling for scan", e);
        handleUnknownError();
        return;
      }

      if (pollJson.status === "NORESULT") {
        // This is a new scan error. Treat it as having an error count one more than the one
        // we know the existing target had.
        handleScanError(errorCount + 1, pollJson.isDisabled);
        return;
      }

      if (pollJson.status === "OK") {
        setCloudscanDataEverywhere(
          dispatch,
          hostname,
          pollJson.cloudscan,
          true,
          isCustomer
        );
        // Add a quick whisper for successful scan returned
        dispatch(
          addMessageAlert({
            type: BannerType.SUCCESS,
            message: `${hostname} was scanned successfully`,
            subMessages: ["Some data may not update immediately."],
            linkUrl:
              "https://help.upguard.com/en/articles/3800663-how-frequently-does-upguard-scan-websites",
            linkText: "UpGuard Scanning Frequency",
            hideDelay: 10000,
          })
        );
        return;
      }
    }

    // We didn't get a scan back. Likely a timeout.
    handleTimeout();
  };
};

export const fetchVendorWatchStatus = (vendorId, force = false) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    if (!force) {
      const vendor = getVendorData(getState, vendorId, false, tpvmSession);
      const currentWatchStatus = _get(vendor, "watching.result", null);

      if (currentWatchStatus) {
        // We have the current watch status cached already, no need to fetch
        return currentWatchStatus;
      }
    }

    const watchStatus = {};
    let json;

    if (!force) {
      dispatch(
        setVendorData(
          vendorId,
          { watching: { loading: true } },
          false,
          tpvmSession
        )
      );
    }

    try {
      json = await FetchCyberRiskUrl(
        "watch/v1",
        {
          vendor_id: vendorId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setVendorData(
          vendorId,
          {
            watching: {
              loading: false,
              error: { errorText: "Error fetching data" },
            },
          },
          false,
          tpvmSession
        )
      );

      LogError("Error fetching vendor watch status");
      return null;
    }

    watchStatus.result = json.result;
    watchStatus.loading = false;
    watchStatus.error = null;

    const dataToSet = { watching: watchStatus };
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    let name = _get(vendor, "name", null);

    if (json.result) {
      // We should set the name, display_name and primary_hostname too as we've fetched it here, in case we don't already have it
      dataToSet.name = json.result.vendorName;
      dataToSet.display_name = json.result.vendorName;
      dataToSet.primary_hostname = json.result.vendorPrimaryDomain;
      dataToSet.labels = json.result.vendorLabels;
      dataToSet.vendorTier = json.result.vendorTier;
      dataToSet.vendorTierName = json.result.vendorTierName;
      dataToSet.vendorPortfolios = json.result.vendorPortfolios;
      dataToSet.verified = json.result.verifiedVendor;
      dataToSet.sharedAssessmentPublished =
        json.result.sharedAssessmentPublished;
      dataToSet.customForOrgId = json.result.customForOrgId;
    }

    dispatch(
      setVendorData(
        vendorId,
        dataToSet,
        false,
        tpvmSession,
        json.entitlements,
        json.groupEntitlements,
        json.orgName
      )
    );
    return json.result;
  };
};

/**
 * updateVendorWatchStatus
 * Sets a given vendorId as watched or unwatched. This reflects the change in state on the frontend immediately before firing the fetch call.
 * If the fetch errors out, we set the state back to what it was before.
 * If this is a newly watched vendor, we ensure the redux watchlist cache has this vendor added to it, and if it is a removed vendor, we ensure the opposite.
 * @param {number} vendorId
 * @param {boolean} newWatchStatus - to watch, or unwatch this vendorId
 * @param {object} history - history object
 * @param {array} systemLabelIds - array of label IDs to apply to this vendor (such as In-Use)
 */
export const updateVendorWatchStatus = (
  vendorId,
  newWatchStatus,
  history,
  systemLabelIds = [],
  vendorName,
  vendorPrimaryDomain
) => {
  return async (dispatch, getState) => {
    // Get old watching status from state, otherwise assume opposite of newWatchStatus
    const oldWatchStatus = _get(
      getState().cyberRisk.vendors[vendorId],
      "watching.result.watching",
      !newWatchStatus
    );
    const oldCanView = _get(
      getState().cyberRisk.vendors[vendorId],
      "watching.result.canView",
      !newWatchStatus
    );

    // Assume that the oldScored is false as this action should never be able to be triggered if the vendor is being Scored
    const oldScored = false;

    const oldWatching = _get(
      getState().cyberRisk.vendors[vendorId],
      "watching",
      {}
    );
    const oldResult = _get(oldWatching, "result", {});
    const managedWatch = _get(oldResult, "managedWatch", false);
    const managedBillingInactive = _get(
      oldResult,
      "managedBillingInactive",
      false
    );

    // if managedWatch is true, then we are going to the 'watched' state
    // if it is false, then we are going to 'unwatched' but the vendor will remain as watched and be managedWatched
    // We need to ensure that the right endpoint is called by setting newWatchStatus but putting an extra step for the UI update
    if (managedWatch) {
      newWatchStatus = true;
    }
    const newManagedWatch = managedBillingInactive ? !managedWatch : false;

    const watchStatus = {
      ...oldWatching,
      loading: true,
      result: {
        ...oldResult,
        watching: managedBillingInactive ? true : newWatchStatus,
        scored: false,
        managedWatch: newManagedWatch,
      },
    };
    dispatch(setVendorData(vendorId, { watching: watchStatus }));

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "watch/v1",
        {
          vendor_ids: [vendorId],
          vendor_label_ids: systemLabelIds,
          scored: false,
        },
        { method: newWatchStatus ? "POST" : "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      if (e.response && e.response.status === 402) {
        dispatch(showVendorWatchLimitExceededError(history));
      } else {
        LogError("Error setting vendor watched status", e);
        dispatch(addDefaultUnknownErrorAlert("Error monitoring vendor"));
      }
      dispatch(
        setVendorData(vendorId, {
          watching: {
            loading: false,
            result: {
              watching: managedBillingInactive ? true : oldWatchStatus,
              scored: oldScored,
              canView: oldCanView,
              managedWatch: managedWatch,
            },
          },
        })
      );

      return false;
    }

    if (json.status === "PENDING_SURVEYS") {
      // Revert back to the previous set and show a whisper
      dispatch(
        setVendorData(vendorId, {
          watching: {
            loading: false,
            result: {
              watching: managedBillingInactive ? true : oldWatchStatus,
              scored: oldScored,
              canView: oldCanView,
              managedWatch: managedWatch,
            },
          },
        })
      );
      dispatch(
        addSimpleErrorAlert(
          "Cannot stop monitoring this vendor because there are pending questionnaires",
          [
            "All pending questionnaires must be completed or cancelled in order to stop monitoring this vendor.",
          ]
        )
      );
      return false;
    }

    watchStatus.loading = false;
    const newVendorData = { watching: watchStatus };
    // if the vendor is being unwatched make sure we clear its cached data
    if (!newWatchStatus) {
      newVendorData.customForOrgId = undefined;
      newVendorData.filters = getDefaultFilters();
      newVendorData.geolocationData = undefined;
      newVendorData.has_subsidiaries = undefined;
      newVendorData.immutable = undefined;
      newVendorData.sharedAssessmentPublished = undefined;
      newVendorData.summary = undefined;
      newVendorData.watching = undefined;
    }
    dispatch(setVendorData(vendorId, newVendorData));

    // Let's also update our cached watched vendors data
    dispatch(
      refreshFilteredCustomerData(
        getState().cyberRisk.customerData.filters,
        false,
        true
      )
    );

    // update customer limit data impacted by watchlist changes
    dispatch(fetchCustomerLimitData());

    if (newWatchStatus) {
      trackEvent("added a vendor", {
        vendorId,
        vendorName,
        vendorPrimaryDomain,
        systemLabelIds,
        inUse: systemLabelIds.includes(1),
      });
    }

    return true;
  };
};

// updateVendorWatchStatusWithRefresh is like updateVendorWatchStatus, but does not try to update state itself.
// Instead we finish off with a call to fetchVendorWatchStatus to update state.
export const updateVendorWatchStatusWithRefresh = (
  vendorId,
  newWatchStatus,
  history,
  systemLabelIds = [],
  vendorName,
  vendorPrimaryDomain,
  tier,
  portfolioIds = [],
  note
) => {
  return async (dispatch, getState) => {
    let json;

    const opts = {
      vendor_ids: [vendorId],
      vendor_label_ids: systemLabelIds,
      scored: false,
      tier: tier,
      note: note,
    };

    if (portfolioIds.length > 0) {
      opts.portfolio_ids = portfolioIds;
    }

    try {
      json = await FetchCyberRiskUrl(
        "watch/v1",
        opts,
        { method: newWatchStatus ? "POST" : "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setVendorWatchStatusBusy(false, e));
      if (e.response && e.response.status === 402) {
        dispatch(showVendorWatchLimitExceededError(history));
      } else {
        LogError("Error setting vendor watched status", e);
        throw e;
      }
      return;
    }

    if (json.status === "PENDING_SURVEYS") {
      const msg =
        "Cannot stop monitoring this vendor because there are pending questionnaires";
      dispatch(
        addSimpleErrorAlert(msg, [
          "All pending questionnaires must be completed or cancelled in order to stop monitoring this vendor.",
        ])
      );
    }

    // Let's also update our cached watched vendors data
    dispatch(
      refreshFilteredCustomerData(
        getState().cyberRisk.customerData.filters,
        false,
        true
      )
    );

    // update customer limit data impacted by watchlist changes
    dispatch(fetchCustomerLimitData());

    // plg onboarding
    dispatch(
      setPLGTaskCompleteIfIncomplete("Checklist_VendorRisk_Monitor", [])
    );

    if (newWatchStatus) {
      trackEvent("added a vendor", {
        vendorId,
        vendorName,
        vendorPrimaryDomain,
        systemLabelIds,
        inUse: systemLabelIds.includes(1),
      });
    }

    return dispatch(fetchVendorWatchStatus(vendorId, true));
  };
};

/**
 * updateVendorScoredStatus
 * Sets a given vendorId as scored on unscored. This reflects the change in state on the frontend immediately before firing the fetch call.
 * If the fetch errors out, we set the state back to what it was before.
 * If this is a newly watched vendor, we ensure the redux watchlist cache has this vendor added to it.
 * @param {number} vendorId
 * @param {boolean} newScoredStatus - to score, or unscore this vendorId
 */
export const updateVendorScoredStatus = (
  vendorId,
  newScoredStatus,
  history
) => {
  return async (dispatch, getState) => {
    const vendor = getState().cyberRisk.vendors[vendorId] || {};

    const oldWatching = _get(vendor, "watching.result.watching", false);

    // Get old scored status from state, otherwise assume opposite of newScoredStatus
    const oldScored = _get(vendor, "watching.result.scored", !newScoredStatus);

    // Below, we assume that watching is true because:
    // If newScoredStatus is true, then watching must become true even if it wasn't before
    // If newScoreStatus is false, watching must have been true for this action to be called
    const watchStatus = {
      loading: false,
      result: {
        watching: true,
        scored: newScoredStatus,
      },
    };

    // Set the new state before making the fetch to give the instantaneous illusion
    dispatch(setVendorData(vendorId, { watching: watchStatus }));

    // Actually update the data

    try {
      await FetchCyberRiskUrl(
        "watch/v1",
        { vendor_ids: [vendorId], scored: true },
        { method: newScoredStatus ? "POST" : "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      if (e.response && e.response.status === 402) {
        dispatch(showVendorWatchLimitExceededError(history));
      } else {
        LogError("Error setting vendor watched status", e);
      }
      // If the fetch failed, let's revert back to what the previous status was
      dispatch(
        setVendorData(vendorId, {
          watching: {
            loading: false,
            result: {
              watching: oldWatching,
              scored: oldScored,
            },
          },
        })
      );

      return false;
    }

    // Show a message up top to indicate the vendor's now contributing to the score. Remove it after 5 secs.
    if (newScoredStatus) {
      dispatch(
        addDefaultSuccessAlert("You've marked this vendor as being in-use", [
          "Their risk factors will contribute to your own score and reports",
        ])
      );
    }

    dispatch(
      refreshFilteredCustomerData(
        getState().cyberRisk.customerData.filters,
        false,
        true
      )
    );

    return true;
  };
};

export const updateVendorWatchStatusMulti = (
  vendorIdsAdded,
  vendorIdsRemoved,
  scored = false
) => {
  return async (dispatch, getState) => {
    let json;

    if (vendorIdsRemoved && vendorIdsRemoved.length > 0) {
      // Wait on removing before adding in case we hit limits
      try {
        json = await FetchCyberRiskUrl(
          "watch/v1",
          { vendor_ids: vendorIdsRemoved, scored },
          { method: "DELETE" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError("Error unwatching vendors", e);

        throw new Error(
          "Unknown error updating vendor watchlist. Please try again."
        );
      }
    }

    if (json && json.status === "PENDING_SURVEYS") {
      throw new Error(
        "One or more vendors to stop monitoring have pending questionnaires. All pending questionnaires must be completed or cancelled before you can stop monitoring a vendor."
      );
    }

    if (vendorIdsAdded && vendorIdsAdded.length > 0) {
      try {
        await FetchCyberRiskUrl(
          "watch/v1",
          { vendor_ids: vendorIdsAdded, scored },
          { method: "POST" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError("Error watching vendors", e);

        throw new Error(
          "Unknown error updating vendor watchlist. Please try again."
        );
      }
    }

    dispatch(
      refreshFilteredCustomerData(
        getState().cyberRisk.customerData.filters,
        false,
        true
      )
    );

    // update customer limit data impacted by watchlist changes
    dispatch(fetchCustomerLimitData());

    for (let i = 0; i < vendorIdsAdded.length; i++) {
      trackEvent("added a vendor", {
        vendorId: vendorIdsAdded[i],
        inUse: false,
        bulkAdd: true,
      });
    }
  };
};

// getVendorOpenWorkflowStatuses
// For a set of datastoreVendorIDs (of watched vendors) determine if any on the workflows for those vendors are currently open.
// Workflows supported are: security questionnaires, relationship questionnaires, remediation requests.
export const getVendorOpenWorkflowStatuses = (vendorIds) => {
  return async (dispatch, getState) => {
    const params = { vendor_ids: vendorIds };
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "watch/open_workflows/v1/",
        params,
        { method: "POST", body: JSON.stringify(params) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting vendor workflow statuses", e);
      throw e;
    }
    return json.results;
  };
};

export const setVendorAttributeOverrides = (
  vendorId,
  isCustomer,
  overrides
) => {
  return async (dispatch, getState) => {
    const params = { ...overrides };
    if (isCustomer) {
      params.IsCustomer = true;
    } else {
      params.VendorID = vendorId;
    }

    try {
      await FetchCyberRiskUrl(
        "customer/vendor_overrides/v1/",
        params,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting vendor attribute overrides", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const resetVendorAttributeOverrides = (vendorId, isCustomer, fields) => {
  return async (dispatch, getState) => {
    const params = { Fields: fields };
    if (isCustomer) {
      params.IsCustomer = true;
    } else {
      params.VendorID = vendorId;
    }

    try {
      await FetchCyberRiskUrl(
        "customer/vendor_overrides/v1/",
        params,
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting vendor attribute overrides", e);

      return;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

/**
 * updateVendorDisplayName
 * Sets a new custom name for a vendor.
 */
export const updateVendorDisplayName = (vendorId, newVendorName) => {
  return async (dispatch, getState) => {
    // Get old watching status from state, otherwise assume opposite of newWatchStatus
    const oldDisplayName = _get(
      getState().cyberRisk.vendors[vendorId],
      "display_name",
      null
    );

    // check if there has actually been a change made
    if (newVendorName === oldDisplayName) {
      return;
    }

    // update the state with new vendor data
    dispatch(setVendorData(vendorId, { display_name: newVendorName }));

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "customer/vendorname/v1/",
        { vendor_id: vendorId, name: newVendorName },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      json = null;
      LogError("Error setting new vendor display name", e);

      return;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    if (!json || json.status !== "OK") {
      dispatch(
        addDefaultUnknownErrorAlert(
          "Your custom vendor name could not be saved"
        )
      );
      return;
    }

    // Update the display name for the vendor
    const vendors = { ...getState().cyberRisk.customerData.vendors };
    if (vendors.result) {
      let found = false;
      for (let i = 0; i < vendors.result.length; i++) {
        if (vendors.result[i].id.toString() === vendorId.toString()) {
          vendors.result[i].display_name = newVendorName;
          found = true;
          break;
        }
      }
      if (found) {
        dispatch(setCustomerData({ vendors }));
      }
    }
  };
};

export const fetchSurveyTypes = (context, force = false) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const state = getState();
    let surveyTypes;
    if (!force) {
      if (tpvmSession && tpvmSession.tpvm_o > 0) {
        surveyTypes = _get(
          state.cyberRisk.managedVendorData,
          `[${tpvmSession.tpvm_o}].surveyTypes[${context}]`,
          null
        );
      } else {
        surveyTypes = _get(
          state.cyberRisk.customerData,
          `surveyTypes[${context}]`,
          null
        );
      }

      if (surveyTypes) {
        return surveyTypes;
      }
    }

    surveyTypes = [];
    try {
      surveyTypes = await FetchCyberRiskUrl(
        "survey/types/v1",
        {
          context,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching survey types", e);
      surveyTypes = null;
    }

    if (surveyTypes) {
      dispatch(
        setSurveyTypes(
          context,
          surveyTypes.surveyTypes || [],
          surveyTypes.surveyRiskVisibility,
          tpvmSession
        )
      );
    }
  };
};

export const toggleSurveyLibrarySurveyType = (surveyTypeId, enable) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/types/v1",
        { survey_type_id: surveyTypeId, enable },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error toggling survey library survey type", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const setSurveyTypeOverrideName = (surveyTypeId, name) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/types/name/v1",
        { survey_type_id: surveyTypeId, name },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting survey type override", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

/**
 * Post an add vendor request. This is not currently saved to state but we'll use redux for convenience of accessing
 * dispatch and getState functions.
 * This method takes a url entered by the user and posts it to the CR endpoint for onboarding (adding) new vendors by URL.
 * This will return an object containing "error" or "vendorId" depending on the status.
 * @param {string} vendorUrl - Main URL of the vendor to onboard
 */
export const postAddVendorRequestAndGetVendorId = (vendorUrl) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/add/v1",
        { url: vendorUrl },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error calling the add vendor service", e);

      return null;
    }

    if (!json || !json.status || !json.vendorId) {
      return null;
    }

    return json.vendorId;
  };
};

export const registerForCyberRiskWithStripeToken = (
  email,
  companyUrl,
  token
) => {
  const emailAddress = email.trim().toLowerCase();
  return async (dispatch, getState) => {
    // This function will eventually hit a cyber risk registration endpoint.
    await new Promise((resolve) => setTimeout(() => resolve(), 3000));

    // return { success: false, error: "An error occurred" };

    return { success: true };
  };
};

export const getOrgApi = () => {
  return async (dispatch, getState) => {
    dispatch(
      setOrgApi({
        loading: true,
        error: null,
        data: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "account/apikey/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching org api", e);
    }

    if (!json || json.status !== "OK") {
      dispatch(
        setOrgApi({
          loading: false,
          error: "error fetching org api details",
          data: null,
        })
      );
      return;
    }

    dispatch(
      setOrgApi({
        loading: false,
        error: null,
        data: {
          keys: json.keys,
        },
      })
    );
  };
};

export const generateOrgApi = (name, permissions) => {
  return async (dispatch, getState) => {
    dispatch(
      setOrgApi({
        loading: true,
        error: null,
        data: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "account/apikey/v1",
        { name, permissions },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error generating org API", e);
    }

    if (!json || !json.key) {
      dispatch(
        setOrgApi({
          loading: false,
          error: "error generating org api details",
          data: null,
        })
      );
      return;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    await dispatch(getOrgApi());
  };
};

export const deleteApiKey = (key) => {
  return async (dispatch, getState) => {
    dispatch(
      setOrgApi({
        loading: true,
        error: null,
        data: null,
      })
    );

    try {
      await FetchCyberRiskUrl(
        "account/apikey/delete/v1",
        { key: key },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(
        setOrgApi({
          loading: false,
          error: "Error deleting API key",
          data: null,
        })
      );
      LogError("Error deleting API key", e);
      throw new Error("Error deleting API key: " + e.message);
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    await dispatch(getOrgApi());
  };
};

export const fetchOrgLogo = () => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "account/logo/v1",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching org logo", e);
      throw new Error("Error fetching organization logo.");
    }

    return json;
  };
};

export const updateOrgLogo = (type, file, replaceAll) => {
  return async (dispatch, getState) => {
    const fetchOpts = { method: "POST" };
    fetchOpts.body = new FormData();
    fetchOpts.body.append("file", file);

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "account/logo/v1",
        { logo_type: type, replace_all: !!replaceAll },
        fetchOpts,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating org logo", e);
      throw new Error("Error updating organization logo: " + e.message);
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return json.logoUrl;
  };
};

export const deleteOrgLogo = () => {
  return async (dispatch, getState) => {
    const fetchOpts = { method: "DELETE" };

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "account/logo/v1",
        {},
        fetchOpts,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting org logo", e);
      throw new Error("Error deleting organization logo: " + e.message);
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return json.logoUrl;
  };
};

export const setOrgLogo = (url) => {
  return {
    type: SET_ORG_LOGO_URL,
    url: url,
  };
};

export const getOrgUsers = () => {
  return async (dispatch, getState) => {
    dispatch(
      setOrgUsers({
        loading: true,
        error: null,
        // data: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/users/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching org users", e);
    }

    if (!json || !json.users) {
      dispatch(
        setOrgUsers({
          loading: false,
          error: "error fetching users",
          data: null,
        })
      );
      return;
    }

    dispatch(
      setOrgUsers({
        loading: false,
        error: null,
        data: json.users,
        freeRoles: json.freeRoles,
        entitlementRoles: json.entitlementRoles,
        entitlementRolesMultiple: json.entitlementRolesMultiple,
      })
    );
  };
};

export const getOrgUserInvites = () => {
  return async (dispatch, getState) => {
    dispatch(
      setOrgUserInvites({
        loading: true,
        error: null,
        // data: null,
      })
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/invites/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting org user invites", e);
    }

    if (!json || !json.invites) {
      dispatch(
        setOrgUserInvites({
          loading: false,
          error: "error fetching user invites",
          data: null,
        })
      );
      return;
    }

    dispatch(
      setOrgUserInvites({
        loading: false,
        error: null,
        data: json.invites,
      })
    );
  };
};

export const cancelUserInvite = (id) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/invite/v1",
        { id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error cancelling user invite", e);

      throw new Error("Error occured");
    }

    dispatch(getOrgUserInvites());

    if (json.status === "ERROR") {
      throw new Error(`Error occured: ${json.desc}`);
    }

    // invalidate RTK-Query users and invites cache because an invite has just
    // been removed from the organisation
    dispatch(
      UsersAPI.util.invalidateTags([
        UsersTagTypes.orgUserEmailAddresses,
        UsersTagTypes.orgUserInvites,
      ])
    );
    // invalidate RTK-Query approvers cache because existing nominated approver
    // invites may have just been removed from the organisation
    dispatch(ApproversAPI.util.invalidateTags([ApproversTagTypes.approvers]));
    // invalidate RTK-Query org flags cache because removing an invite can cause
    // the BreachSightNominateApprovers or VendorRiskNominateApprovers org flags
    // to be turned off (when approver records get cleaned up)
    dispatch(
      OrganisationFlagsAPI.util.invalidateTags([OrganisationFlagsTags.orgFlags])
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const resendRequestedInvite = (inviteId) => {
  return async (dispatch, getState) => {
    try {
      return await FetchCyberRiskUrl(
        "accounts/resend_invite/v1",
        { invite_id: inviteId },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error resending invite", e);

      throw new Error("Error occured");
    }
  };
};

export const SubscriptionNoSelfService =
  "This account does not support self service subscription management. Please contact support@upguard.com for any queries or to make changes to your account.";

export const getActiveSubscription = () => {
  return async (dispatch, getState) => {
    let json;

    dispatch(
      setOrgSubscription({
        loading: true,
        error: null,
        data: {},
      })
    );

    try {
      json = await FetchCyberRiskUrl(
        "account/subscription/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      if (e.response.status === 404) {
        dispatch(
          setOrgSubscription({
            loading: false,
            error: SubscriptionNoSelfService,
            data: {},
          })
        );
      } else {
        LogError("Error getting active subscription", e);
        dispatch(
          setOrgSubscription({
            loading: false,
            error: `An error occured loading subscription details: ${e.message}`,
            data: {},
          })
        );
      }

      return;
    }

    dispatch(
      setOrgSubscription({
        loading: false,
        error: null,
        data: json.sub,
      })
    );
  };
};

export const cancelSubscription = () => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "account/subscription/cancel/v1",
        {},
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error cancelling subscription", e);

      throw new Error(
        "An error occured while cancelling your subscription. Please try again later and contact support@upguard.com if the problem persists."
      );
    }

    dispatch(getActiveSubscription());

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const reactivateSubscription = () => {
  return async (dispatch, getState) => {
    const user = getState().common.userData;

    try {
      await FetchCyberRiskUrl(
        "account/subscription/reactivate/v1",
        {},
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error reactivating subscription", e);

      throw new Error(
        "An error occured while reactivating your subscription. Please try again later and contact support@upguard.com if the problem persists."
      );
    }

    dispatch(getActiveSubscription());
    dispatch(loadUserOrg(user.currentOrgID));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const updateCreditCardDetails = (stripeToken) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "account/card-details/v1",
        { stripe_token: stripeToken },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      // credit card errors are returned as 402 errors (eg. card expired)
      if (e.response && e.response.status === 402) {
        throw new Error(
          `There was an error updating your card details: ${e.message}`
        );
      }
      LogError("Error updating credit card details", e);
      throw new Error(
        "There was an error updating your card details. Please try again later and contact support@upguard.com if the problem persists."
      );
    }

    dispatch(getActiveSubscription());

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const updateBillingEmail = (email) => {
  const emailAddress = email.trim().toLowerCase();
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "account/billing-email/v1",
        { email: emailAddress },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating billing email", e);

      throw new Error(
        "There was an error updating your billing email. Please try again later and contact support@upguard.com if the problem persists."
      );
    }

    dispatch(getActiveSubscription());

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const fetchBreachSightList = () => {
  return async (dispatch, getState) => {
    dispatch(
      setCustomerData({
        breachsight: {
          ...getState().cyberRisk.customerData.breachsight,
          loading: true,
          error: null,
          data: null,
        },
      })
    );

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/list/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching data leaks list", e);
      dispatch(
        setCustomerData({
          breachsight: {
            ...getState().cyberRisk.customerData.breachsight,
            loading: false,
            error: "error loading Data Leaks data",
            data: null,
          },
        })
      );
      return;
    }

    if (json && json.status === "OK") {
      dispatch(
        setCustomerData({
          breachsight: {
            ...getState().cyberRisk.customerData.breachsight,
            loading: false,
            error: null,
            data: json,
          },
        })
      );
      return;
    }

    // Clear the data in case we've switched orgs from somewhere that had it
    dispatch(
      setCustomerData({
        breachsight: {
          loading: true,
          error: null,
          data: null,
        },
      })
    );
  };
};

export const fetchBreachSightCounts = () => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "breachsight/counts/v1",
        { months_back: 11 },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching breachsight counts", e);
      return;
    }

    dispatch(
      setCustomerData({
        breachsight: {
          ...getState().cyberRisk.customerData.breachsight,
          counts: json,
        },
      })
    );
  };
};

export const fetchBreachSightFinding = (id) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/finding/v1",
        { id },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching data leaks finding", e);
      throw new Error("Error loading Data Leaks data.");
    }

    if (json && json.status === "OK") {
      return json;
    }

    throw new Error("Error loading Data Leaks data.");
  };
};

export const addBreachSightFindingComment = (id, comment) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/comment/v1",
        { id, comment },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error adding breachsight comment", e);
      throw new Error("Error adding BreachSight comment.");
    }

    if (!json || json.status !== "OK") {
      throw new Error("Error adding BreachSight comment.");
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const updateBreachSightFindingStatus = (id, status, comment) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "breachsight/status/v1",
        { id, status, comment },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating breachsight status", e);
      throw new Error("Error updating BreachSight status.");
    }

    if (!json || json.status !== "OK") {
      throw new Error("Error updating BreachSight status.");
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const getAvailablePlans = () => {
  return async (dispatch, getState) => {
    let json;

    dispatch(
      setPlans({
        loading: true,
        error: null,
        data: null,
      })
    );

    try {
      json = await FetchCyberRiskUrl(
        "account/products/v1",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting available plans", e);

      dispatch(
        setPlans({
          loading: false,
          error: "There was an error loading the available plans.",
          data: null,
        })
      );
    }

    dispatch(
      setPlans({
        loading: false,
        error: null,
        data: json,
      })
    );
  };
};

export const updatePlan = (numVendorSlots) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "account/subscription/product/v1",
        { vendor_slots: numVendorSlots },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      if (e.response && e.response.status === 422) {
        throw new Error(
          `There was an error updating your subscription. ${e.message}`
        );
      } else {
        LogError("Error updating subscription plan", e);
        throw new Error(
          "There was an error updating your subscription. Please try again later and contact support@upguard.com if the problem persists."
        );
      }
    }

    dispatch(getActiveSubscription());
  };
};

const userResource = (
  trackingName,
  method,
  payload,
  body = undefined,
  noReload = false
) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/user/v1",
        payload,
        { method, body: body ? JSON.stringify(body) : undefined },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error interacting with user API", e);

      throw e;
    }

    if (!noReload) {
      dispatch(getOrgUsers());
      dispatch(getOrgUserInvites());
    }

    if (json.status === "ERROR") {
      throw new Error(`Error occured: ${json.desc}`);
    }

    // invalidate RTK-Query users and invites cache because users/invites may have
    // just been added to, or removed from, the organisation
    dispatch(
      UsersAPI.util.invalidateTags([
        UsersTagTypes.orgUserEmailAddresses,
        UsersTagTypes.orgUserInvites,
      ])
    );
    // invalidate RTK-Query approvers cache because existing nominated approver
    // users may have just been removed from the organisation
    dispatch(ApproversAPI.util.invalidateTags([ApproversTagTypes.approvers]));
    // invalidate RTK-Query org flags cache because removing a user can cause
    // the BreachSightNominateApprovers or VendorRiskNominateApprovers org flags
    // to be turned off (when approver records get cleaned up)
    dispatch(
      OrganisationFlagsAPI.util.invalidateTags([OrganisationFlagsTags.orgFlags])
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const resendOrgUserInvite = (inviteId) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "organisation/reinvite/v1/",
        { invite_id: inviteId },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error attempting to resend user invitation", e);

      throw e;
    }

    if (json.status === "ERROR") {
      throw new Error(`Error occured: ${json.desc}`);
    }
  };
};

export const addOrgUsers = (
  emails,
  roles,
  portfolioSpecificRoles,
  namedRoleId,
  noReload = false
) => {
  const emailAddresses = emails.map((email) => email.trim().toLowerCase());
  return userResource(
    "CyberRisk_addOrgUser",
    "POST",
    undefined,
    {
      emails: emailAddresses,
      roles,
      portfolioSpecificRoles,
      namedRoleId,
    },
    noReload
  );
};

export const editOrgUsers = (
  ids,
  roles,
  portfolioSpecificRoles,
  namedRoleId,
  sendNotificationEmail = false
) => {
  return userResource(
    "CyberRisk_editOrgUser",
    "PUT",
    undefined,
    {
      ids: ids,
      roles: roles,
      portfolioSpecificRoles,
      namedRoleId,
      sendNotificationEmail,
    },
    false
  );
};

export const deleteOrgUsers = (ids, noReload = false) => {
  return userResource(
    "CyberRisk_deleteOrgUser",
    "DELETE",
    { ids: ids.join() },
    undefined,
    noReload
  );
};

export const fetchAvailableLabels = () => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "labels/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching available labels", e);

      return [];
    }

    // just combine user and system labels for now, and sort alphabetically
    const labels = [
      ...json.systemLabels,
      ...json.vendorLabels,
      ...json.websiteLabels,
    ].sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));

    dispatch(setAvailableLabels(labels));

    return labels;
  };
};

export const addNewLabel = (name, classification) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "labels/v1",
        { name, classification },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error adding new label", e);

      if (e.message.indexOf("[EXISTS]") === 0) {
        throw new Error("A label with that name already exists.");
      }
      throw e;
    }

    // just combine user and system labels for now, and sort alphabetically
    const labels = [
      ...json.systemLabels,
      ...json.vendorLabels,
      ...json.websiteLabels,
    ].sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));

    dispatch(setAvailableLabels(labels));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // Return the newly added label
    for (let i = 0; i < labels.length; i++) {
      if (
        labels[i].name.toLowerCase() === name.toLowerCase() &&
        labels[i].classification === classification
      ) {
        return labels[i];
      }
    }

    return null;
  };
};

export const renameLabel = (id, newName) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "labels/rename/v1",
        { label_id: id, new_name: newName },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      if (e.message.indexOf("[NOTEXISTS]") === 0) {
        throw new Error("The existing label could not be located.");
      } else if (e.message.indexOf("[EXISTS]") === 0) {
        throw new Error("A label with that name already exists.");
      }

      LogError("error renaming existing label", e);
      throw e;
    }

    // just combine user and system labels for now, and sort alphabetically
    const labels = [
      ...json.systemLabels,
      ...json.vendorLabels,
      ...json.websiteLabels,
    ].sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));

    dispatch(setAvailableLabels(labels));

    // Return the newly renamed label
    for (let i = 0; i < labels.length; i++) {
      if (labels[i].id === id) {
        return labels[i];
      }
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return null;
  };
};

export const deleteLabel = (id) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "labels/v1",
        { label_id: id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting existing label", e);

      throw e;
    }

    // just combine user and system labels for now, and sort alphabetically
    const labels = [
      ...json.systemLabels,
      ...json.vendorLabels,
      ...json.websiteLabels,
    ].sort((a, b) => b.name.toLowerCase().localeCompare(a.name.toLowerCase()));

    dispatch(setAvailableLabels(labels));

    // If we deleted a label, we should remove it from the filters list
    const vendorLabelIds = [
      ...getState().cyberRisk.customerData.filters.vendorLabelIds,
    ];
    let idx = vendorLabelIds.indexOf(id);
    if (idx > -1) {
      vendorLabelIds.splice(idx, 1);
      dispatch(setCustomerDataFilters({ vendorLabelIds }));
    }
    const websiteLabelIds = [
      ...getState().cyberRisk.customerData.filters.websiteLabelIds,
    ];
    idx = websiteLabelIds.indexOf(id);
    if (idx > -1) {
      websiteLabelIds.splice(idx, 1);
      dispatch(setCustomerDataFilters({ websiteLabelIds }));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return null;
  };
};

export const countVendorsForLabel = (id) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vendorlabels/count/v1/",
        { labels_ids: [id] },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching count of vendors for label", e);

      throw e;
    }

    const { count } = json.labelCounts[0];
    return count;
  };
};

export const countWebsitesForLabel = (id) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "websitelabels/count/v1/",
        { labels_ids: [id] },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching count of websites for label", e);

      throw e;
    }

    const { count } = json.labelCounts[0];
    return count;
  };
};

export const setLabelsForVendors = (
  vendorIds,
  addedLabelIds,
  removedLabelIds
) => {
  return async (dispatch, getState) => {
    if (
      vendorIds.length === 0 ||
      (addedLabelIds.length === 0 && removedLabelIds.length === 0)
    ) {
      return;
    }

    // Now determine if the In-Use label has been added or removed from anything.
    // We need to trigger a refresh of the customer data if this is the case, as
    // the overall score may have changed.
    let inUseLabelAltered = false;
    for (let i = 0; i < addedLabelIds.length; i++) {
      if (addedLabelIds[i] === 1) {
        inUseLabelAltered = true;
        break;
      }
    }
    if (!inUseLabelAltered) {
      for (let i = 0; i < removedLabelIds.length; i++) {
        if (removedLabelIds[i] === 1) {
          inUseLabelAltered = true;
          break;
        }
      }
    }

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vendorlabels/v1",
        {
          vendor_ids: vendorIds,
          add_labels: addedLabelIds,
          remove_labels: removedLabelIds,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error setting labels for vendors", e);

      throw e;
    }

    trackEvent("Vendor_SetLabels", { vendorIds: JSON.stringify(vendorIds) });

    // Success - set the labels on each of the vendors in the redux cache
    const { vendorLabels } = json;

    try {
      const watchlistVendors = [
        ..._get(getState(), "cyberRisk.customerData.vendors.result", []),
      ];
      let hasChanged = false;
      for (let i = 0; i < watchlistVendors.length; i++) {
        if (vendorLabels[watchlistVendors[i].id]) {
          watchlistVendors[i].labels = vendorLabels[watchlistVendors[i].id];
          hasChanged = true;
        }
      }

      if (hasChanged) {
        dispatch(
          setCustomerData({
            vendors: {
              ...getState().cyberRisk.customerData.vendors,
              result: watchlistVendors,
            },
          })
        );
      }

      // If we're in search mode, fetch the results again to update
      const prevQuery = getState().cyberRisk.vendorSearch.query;
      if (prevQuery) {
        dispatch(fetchVendorSearchResults(prevQuery, true));
      }

      // Then also update any full vendors cached in state
      for (let vendorId in vendorLabels) {
        dispatch(setVendorLabels(vendorId, vendorLabels[vendorId]));
      }

      // refresh the summary for the vendors we updated the labels of
      dispatch(refreshVendorListsAfterChange(vendorIds));
    } catch (e) {
      LogError("error updating state after setting label for vendor", e);
    }

    // Finally refresh the customer data if the in-use set of vendors has changed
    if (inUseLabelAltered) {
      // And only if we have previously loaded the customer summary
      if (!_get(getState().cyberRisk.customerData, "summary.loading", true)) {
        dispatch(fetchCustomerSummaryAndCloudscans(true));
      }
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return vendorLabels;
  };
};

export const fetchVendorTechnologies = (
  vendorId,
  tryRescanIfEmptyResult,
  force = false
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    if (!force) {
      const vendor = getVendorData(getState, vendorId, false, tpvmSession);
      const technologies = _get(vendor, "technologies.technologies", null);
      if (technologies && !technologies.loading) {
        return technologies;
      }
    }

    dispatch(
      setVendorTechnologies(
        vendorId,
        {
          loading: true,
          error: null,
          data: null,
        },
        tpvmSession
      )
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/technology/v1",
        {
          vendor_id: vendorId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching vendor technolgies", e);

      dispatch(
        setVendorTechnologies(
          vendorId,
          {
            loading: false,
            error: e,
            technologies: null,
            vendors: null,
          },
          tpvmSession
        )
      );
      return;
    }

    if (json.status !== "OK") {
      dispatch(
        setVendorTechnologies(
          vendorId,
          {
            loading: false,
            error: "error fetching vendor technologies",
            technologies: null,
            vendors: null,
          },
          tpvmSession
        )
      );
    }

    if (
      tryRescanIfEmptyResult &&
      (!json.technologies || json.technologies.length === 0)
    ) {
      try {
        json = await FetchCyberRiskUrl(
          "vendor/rescan/v1",
          {
            id: vendorId,
          },
          { method: "POST" },
          dispatch,
          getState
        );
      } catch (e) {
        LogError(
          "Error triggering vendor primary domain rescan to fetch vendor technologies",
          e
        );
      }

      return dispatch(fetchVendorTechnologies(vendorId, false, false));
    }

    dispatch(
      setVendorTechnologies(
        vendorId,
        {
          loading: false,
          error: null,
          technologies: json.technologies || [],
          vendors: json.vendors || [],
        },
        tpvmSession
      )
    );
  };
};

export const refreshFilteredCustomerData = (
  filters,
  breachsightOnly = false,
  vendorRiskOnly = false
) => {
  return async (dispatch, getState) => {
    // Dispatch refreshes for various customer data endpoints, only if we have previously loaded data.
    dispatch(fetchAlertsOrActivityStreamForOrgUser(true, false, 0, filters));
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // Vendor risk specific stuff
    if (!breachsightOnly) {
      const surveyData = getState().cyberRisk.customerData?.surveyList;
      if (surveyData) {
        dispatch(
          getSurveyListV2(
            true,
            undefined,
            0,
            surveyData.limit ?? surveyListPageLimit,
            surveyData.sortCol,
            surveyData.sortDir,
            surveyData.archived,
            surveyData.filterText,
            filters
          )
        );
      }

      if (!_get(getState().cyberRisk.customerData, "vendors.loading", true)) {
        dispatch(fetchCustomerVendorsData(true, null, filters));
        dispatch(fetchVendorTiers(true, filters));
        dispatch(fetchAssessmentBreakdown(filters));
      }

      if (_get(getState().cyberRisk.vendorPortfolioRiskProfile, "data", null)) {
        // Force a refresh of the portfolio risk profile
        dispatch(fetchVendorPortfolioRiskProfile(filters));
      }

      // Check if we need to re-fetch search results
      if (
        getState().cyberRisk.vendorSearch &&
        getState().cyberRisk.vendorSearch.query
      ) {
        dispatch(
          fetchVendorSearchResults(
            getState().cyberRisk.vendorSearch.query,
            true
          )
        );
      }

      if (
        _get(getState().cyberRisk.customerData, "remediationRequestIds", null)
      ) {
        dispatch(fetchRemediationRequestListForAllVendors(true, filters));
        dispatch(fetchSurveysForRemediation(undefined, true, filters));
      }

      if (_get(getState().cyberRisk.customerData, "vendorBreakdown", null)) {
        dispatch(fetchVendorBreakdown(true, filters));
      }

      if (_get(getState().cyberRisk.customerData, "concentrationRisk", null)) {
        dispatch(fetchConcentrationRisk(true, filters));
      }

      if (_get(getState().cyberRisk, "vendorAssessmentClassifications", null)) {
        dispatch(fetchAssessmentBreakdown(filters));
      }

      if (_get(getState().cyberRisk, "vendorTiers", null)) {
        dispatch(fetchVendorTiers(true, filters));
      }

      if (
        getState().common.userData.orgPermissions.includes(
          OrgAccessVendorPortfolios
        )
      ) {
        dispatch(fetchVendorPortfolios(true));
      }
    }

    // BreachSight specific stuff
    if (!vendorRiskOnly) {
      // See if we have any cached customer changes data that we need to refresh
      if (
        _get(getState().cyberRisk.changes, "data", null) &&
        !_get(getState().cyberRisk.changes, "vendorId", null)
      ) {
        // Refresh the changes data with the provided filters
        const { startDate, endDate } = getState().cyberRisk.changes;
        const actualStartDate = startDate < endDate ? startDate : endDate;
        const actualEndDate = startDate < endDate ? endDate : startDate;

        dispatch(
          fetchChangesData(null, actualStartDate, actualEndDate, filters)
        );
      }

      if (!_get(getState().cyberRisk.customerData, "summary.loading", true)) {
        dispatch(fetchCustomerSummaryAndCloudscans(true, filters));
      }

      if (
        _get(
          getState().cyberRisk.customerData,
          "selfRemediationRequestIds",
          null
        )
      ) {
        dispatch(fetchRemediationRequestListForSelf(true, filters));
      }

      if (!_get(getState().cyberRisk.customerData, "vulns.loading", true)) {
        dispatch(fetchCustomerVulns(true, filters));
      }

      if (_get(getState().cyberRisk.customerData, "acceptedRisks", null)) {
        dispatch(fetchCustomerAcceptedRisks(true, filters));
      }

      [1, 7, 30].forEach((num) => {
        if (_get(getState().cyberRisk, `dataLeakScanResults.${num}`, null)) {
          dispatch(fetchDataLeakClassifiedResultsForOrg(true, num, filters));
        }
      });

      // There is a custom date range tab on the data leaks search results - refresh it if we have a valid date range
      if (_get(getState().cyberRisk, "dataLeakScanResults.custom", null)) {
        const customDateRange = _get(
          getState().cyberRisk,
          "dataLeakScanResults.customDateRange",
          null
        );
        if (
          customDateRange &&
          customDateRange.startDate &&
          customDateRange.endDate
        ) {
          dispatch(
            fetchDataLeakClassifiedResultsForOrgByDateRange(
              true,
              customDateRange.startDate,
              customDateRange.endDate,
              filters
            )
          );
        }
      }
    }

    const { reports } = getState().cyberRisk;
    if (reports) {
      // Vendor Risk specific stuff
      if (!breachsightOnly) {
        if (reports[ReportTypes.VendorRiskOverview]) {
          dispatch(fetchVendorRiskOverviewReport(true, filters));
        }
        if (reports[ReportTypes.VendorRiskRatingsBreakdown]) {
          dispatch(fetchVendorRiskBreakdownReport(true, filters));
        }
        if (reports[ReportTypes.VendorRiskSupplyChain]) {
          dispatch(fetchVendorRiskSupplyChainReport(true, filters));
        }
        if (reports[ReportTypes.VendorRiskRiskMatrix]) {
          dispatch(fetchVendorRiskRiskMatrixReport(true, filters));
        }
      }

      // Breachsight specific stuff
      if (!vendorRiskOnly) {
        if (reports[ReportTypes.BreachSightOverview]) {
          dispatch(fetchBreachSightOverviewReport(true, filters));
        }
        if (reports[ReportTypes.BreachSightRiskBreakdown]) {
          dispatch(fetchBreachSightRiskBreakdownReport(true, filters));
        }
        if (reports[ReportTypes.BreachSightCompetitorAnalysis]) {
          dispatch(fetchBreachSightCompetitorAnalysisReport(true, filters));
        }
        if (reports[ReportTypes.BreachSightGeolocation]) {
          dispatch(fetchCustomerGeolocationReport(true, filters));
        }

        if (reports[ReportTypes.BreachSightSubsidiariesAnalysis]) {
          dispatch(
            fetchBreachSightSubsidiariesOrganisationAnalysisReport(
              true,
              filters,
              "subsidiary"
            )
          );
        }
        if (reports[ReportTypes.BreachSightSubsidiariesGeolocation]) {
          dispatch(
            fetchCustomerWithSubsidiariesGeolocationReport(true, filters, false)
          );
        }
        if (reports[ReportTypes.BreachSightSubsidiariesScoreDistribution]) {
          dispatch(
            fetchBreachSightSubsidiariesScoreDistributionReport(true, filters)
          );
        }
        if (reports[ReportTypes.BreachSightSubsidiariesRatings]) {
          dispatch(fetchBreachSightSubsidiaryScoreRatingsReport(true, filters));
        }
        if (reports[ReportTypes.BreachSightSubsidiariesRiskBreakdown]) {
          dispatch(
            fetchBreachSightSubsidiariesRiskBreakdownReport(true, filters)
          );
        }
        if (reports[ReportTypes.BreachSightSubsidiariesOverview]) {
          dispatch(fetchBreachSightSubsidiariesOverviewReport(true, filters));
        }
        if (reports[ReportTypes.BreachSightSubsidiariesCompetitors]) {
          dispatch(
            fetchBreachSightSubsidiariesCompetitorReport(
              true,
              filters,
              "subsidiary"
            )
          );
        }

        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesAnalysis,
              true
            )
          ]
        ) {
          dispatch(
            fetchBreachSightSubsidiariesOrganisationAnalysisReport(
              true,
              filters,
              "subsidiaries_only"
            )
          );
        }
        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesGeolocation,
              true
            )
          ]
        ) {
          dispatch(
            fetchCustomerWithSubsidiariesGeolocationReport(true, filters, true)
          );
        }
        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesScoreDistribution,
              true
            )
          ]
        ) {
          dispatch(
            fetchBreachSightSubsidiariesScoreDistributionReport(
              true,
              filters,
              true
            )
          );
        }
        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesRatings,
              true
            )
          ]
        ) {
          dispatch(
            fetchBreachSightSubsidiaryScoreRatingsReport(true, filters, true)
          );
        }
        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesRiskBreakdown,
              true
            )
          ]
        ) {
          dispatch(
            fetchBreachSightSubsidiariesRiskBreakdownReport(true, filters, true)
          );
        }
        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesOverview,
              true
            )
          ]
        ) {
          dispatch(
            fetchBreachSightSubsidiariesOverviewReport(true, filters, true)
          );
        }
        if (
          reports[
            getQualifiedBreachsightSubsidiariesReportName(
              ReportTypes.BreachSightSubsidiariesCompetitors,
              true
            )
          ]
        ) {
          dispatch(
            fetchBreachSightSubsidiariesCompetitorReport(
              true,
              filters,
              "subsidiaries_only"
            )
          );
        }
      }
    }
  };
};

export const updateWebsiteStatusFilter = (
  newStatus,
  vendorId,
  isSubsidiary
) => {
  return async (dispatch, getState) => {
    const currentFilters = getFiltersFromState(
      getState(),
      vendorId,
      isSubsidiary
    );

    const filters = { ...currentFilters, websiteStatus: newStatus };

    if (_isEqual(currentFilters, filters)) {
      // No change = no refresh necessary
      return;
    }

    if (!vendorId) {
      dispatch(setCustomerDataFilters(filters));
    } else {
      if (isSubsidiary) {
        dispatch(setSubsidiaryFilters(vendorId, filters));
      } else {
        dispatch(setVendorFilters(vendorId, filters));
      }
    }
  };
};

export const setCustomerDataFiltersAndRefreshData = (
  newFilters,
  breachsightOnly = false,
  vendorRiskOnly = false
) => {
  return async (dispatch, getState) => {
    const currentFilters = getState().cyberRisk.customerData.filters;
    const filters = { ...currentFilters, ...newFilters };
    if (_isEqual(currentFilters, filters)) {
      // No change = no refrüche necessary
      return;
    }

    // If we've cleared the portfolio filter, or selected multiple, unset
    // our saved portfolio in local storage.
    if (filters.portfolioIds.length !== 1) {
      clearLocalStorageItem(currentVendorPortfolioLocalStorageKey);
    }

    if (filters.domainPortfolioIds.length !== 1) {
      clearLocalStorageItem(currentDomainPortfolioLocalStorageKey);
    }

    // if the filters were changed, and we weren't on the first page
    // go back to the first page
    const currentPaging = getState().cyberRisk.customerData.vendors.paging;
    if (currentPaging && currentPaging.pageNum !== 1) {
      dispatch(resetVendorsPageNum());
    }

    dispatch(setCustomerDataFilters(filters));
    dispatch(
      refreshFilteredCustomerData(filters, breachsightOnly, vendorRiskOnly)
    );
  };
};

// fetchVendorDocumentsAndContacts fetches all the documents/contacts associated with a vendor.
export const fetchVendorMgtDocumentsAndContacts = (
  vendorId,
  forceRefresh = false
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const mgtlists = _get(vendor, "mgtlists", null);

    let json;
    if (
      !forceRefresh &&
      mgtlists &&
      !mgtlists.loading &&
      mgtlists.allDocuments &&
      mgtlists.contacts
    ) {
      // cached, dont fetch again
      return;
    }

    dispatch(
      setVendorManagementData(
        vendorId,
        {
          mgtlists: {
            allDocuments: {
              loading: true,
              error: null,
              result: null,
            },
            contacts: {
              loading: true,
              error: null,
              result: null,
            },
          },
        },
        tpvmSession
      )
    );

    try {
      json = await FetchCyberRiskUrl(
        "watchedvendor/mgtdetails/v1/",
        {
          datastore_vendor_id: vendorId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        "Error retrieving vendors management lists - documents and contacts",
        e
      );
    }

    if (!json || json.status !== "OK") {
      dispatch(
        setVendorManagementData(vendorId, tpvmSession, {
          mgtlists: {
            allDocuments: {
              loading: false,
              error: {
                errorText: "Error fetching documents for vendor",
                actionText: "Retry",
                actionOnClick: () =>
                  dispatch(fetchVendorMgtDocumentsAndContacts(vendorId)),
              },
              result: null,
            },
            contacts: {
              loading: false,
              error: {
                errorText: "Error fetching contacts for vendor",
                actionText: "Retry",
                actionOnClick: () =>
                  dispatch(fetchVendorMgtDocumentsAndContacts(vendorId)),
              },
              result: null,
            },
          },
        })
      );
      return;
    }

    dispatch(
      setVendorManagementData(
        vendorId,
        {
          mgtlists: {
            allDocuments: {
              loading: false,
              error: null,
              result: json.allDocuments,
            },
            contacts: {
              loading: false,
              error: null,
              result: json.contacts,
            },
          },
        },
        tpvmSession
      )
    );
  };
};

// fetchVendorMgtDocuments fetches all documents associated with a vendor.
export const fetchVendorMgtDocuments = (vendorId, forceRefresh = false) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    const vendor = getVendorData(getState, vendorId, false, tpvmSession);
    const mgtlists = _get(vendor, "mgtlists", null);

    let json;
    if (!forceRefresh && mgtlists && !mgtlists.loading && mgtlists.documents) {
      // cached, dont fetch again
      return;
    }

    dispatch(
      setVendorManagementDocuments(vendorId, {
        documents: {
          loading: true,
          error: null,
          result: null,
        },
        tpvmSession,
      })
    );

    try {
      json = await FetchCyberRiskUrl(
        "watchedvendor/documents/v1/",
        { datastore_vendor_id: vendorId },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving vendors management lists - documents", e);
    }

    if (!json || json.status !== "OK") {
      dispatch(
        setVendorManagementDocuments(vendorId, {
          documents: {
            loading: false,
            error: {
              errorText: "Error fetching documents for vendor",
              actionText: "Retry",
              actionOnClick: () =>
                dispatch(fetchVendorMgtDocuments(vendorId, true)),
            },
            result: null,
          },
          tpvmSession,
        })
      );
      return;
    }
    dispatch(
      setVendorManagementDocuments(vendorId, {
        documents: {
          loading: false,
          error: null,
          result: json.allDocuments,
        },
        tpvmSession,
      })
    );
  };
};

export const createVendorContact = (vendorId, contact) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "watchedvendor/contact/v1/",
        {
          datastore_vendor_id: vendorId,
          name: contact.name,
          title: contact.title,
          email_address: contact.emailAddress,
          phone_mobile: contact.phoneMobile,
          notes: contact.notes,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error creating new vendor contact", e);

      throw new Error(
        "Error creating new contact. Please ensure this contact does not already exist and try again."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return json.contact;
  };
};

export const updateVendorContact = (contact) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "watchedvendor/contact/v1/",
        {
          contact_id: contact.id,
          name: contact.name,
          title: contact.title,
          email_address: contact.emailAddress,
          phone_mobile: contact.phoneMobile,
          notes: contact.notes,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating existing vendor contact", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return json.contact;
  };
};

export const setVendorContactDeleted = (vendorId, contactId) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let vendor = getVendorData(getState, vendorId, false, tpvmSession);
    let contacts = _get(vendor, "mgtlists.contacts.result", null);

    let i = 0;
    for (i = 0; i < contacts.length; i++) {
      if (contacts[i].id === contactId) {
        contacts[i].deleting = true;
        break;
      }
    }
    dispatch(
      setVendorManagementContacts(
        vendorId,
        {
          contacts: {
            loading: false,
            error: null,
            result: contacts,
          },
        },
        tpvmSession
      )
    );
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "watchedvendor/contact/v1/",
        {
          contact_id: contactId,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting existing vendor contact", e);

      for (i = 0; i < contacts.length; i++) {
        if (contacts[i].id === contactId) {
          contacts[i].deleting = false;
          break;
        }
      }
      throw e;
    }

    vendor = getVendorData(getState, vendorId, false, tpvmSession);
    contacts = _get(vendor, "mgtlists.contacts.result", null);
    for (i = 0; i < contacts.length; i++) {
      if (contacts[i].id === contactId) {
        if (contacts.length > 1) {
          contacts.splice(i, 1);
        } else {
          contacts = null;
        }
        break;
      }
    }
    dispatch(
      setVendorManagementContacts(
        vendorId,
        {
          contacts: {
            loading: false,
            error: null,
            result: contacts,
          },
        },
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const getCachedSurveyDetailsByID = (vendorId, surveyID) => {
  return async (dispatch, getState) => {
    const surveyData =
      getState().cyberRisk.vendors[vendorId].surveys.result || [];
    if (surveyData.length > 0) {
      let i = 0;
      for (i = 0; i < surveyData.length; i++) {
        if (surveyData[i].id === surveyID) {
          return surveyData[i];
        }
      }
    }
    return null;
  };
};

export const fetchChangesData = (
  vendorId,
  startDateUnix,
  endDateUnix,
  filters,
  isSuppliedDateRange = false,
  isSubsidiary = false
) => {
  return async (dispatch, getState) => {
    // First up set our loading state
    dispatch(setChangesLoading(true));

    const opts = {};
    if (isSubsidiary) {
      opts.vendor_id = vendorId;
      opts.subsidiary = true;
    } else if (vendorId) {
      opts.vendor_id = vendorId;
    } else {
      let theFilters;
      if (filters) {
        theFilters = filters;
      } else {
        theFilters = getState().cyberRisk.customerData.filters;
      }

      opts.customer = true;
      opts.vendor_label_ids = theFilters.vendorLabelIds;
      opts.vendor_label_ids_match_all = theFilters.vendorLabelIdsMatchAll;
      opts.vendor_label_ids_do_not_match = theFilters.vendorLabelIdsDoNotMatch;
      opts.include_unlabeled = theFilters.includeUnlabeled;
      opts.min_score = theFilters.minScore;
      opts.max_score = theFilters.maxScore;
    }

    opts.start_date = moment(startDateUnix).toISOString();
    opts.end_date = moment(endDateUnix).toISOString();
    opts.exact_date_range = isSuppliedDateRange;

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/diff/v1/",
        opts,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setChangesLoading(false));
      dispatch(addDefaultUnknownErrorAlert("Error fetching changes"));
      LogError("error fetching changes", e);

      throw e;
    }

    dispatch(setChangesLoading(false));
    if (getState().cyberRisk.changes.active) {
      // Only set the changes data if changes mode is still active when the request completes
      dispatch(
        setChangesData({
          vendorId,
          isSubsidiary,
          ...json,
        })
      );
    }
  };
};

export const fetchChangesForCloudscan = (
  hostname,
  startDateUnix,
  endDateUnix,
  isSuppliedDateRange,
  isCustomer,
  isSubsidiary,
  vendorId
) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "cloudscan/diff/v1/",
        {
          hostname,
          customer: isCustomer,
          subsidiary: isSubsidiary,
          vendor_id: vendorId,
          start_date: moment(startDateUnix).toISOString(),
          end_date: moment(endDateUnix).toISOString(),
          exact_date_range: !!isSuppliedDateRange,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching changes for cloudscan", e);

      throw e;
    }

    return json;
  };
};

export const resetPagedExposuresAccountData = (breachID) => {
  return async (dispatch, getState) => {
    dispatch(setExposuresAccountsLoading(true, "RESETBREACH" + breachID));
    dispatch(clearExposuresPagedAccountData(breachID));
    dispatch(setExposuresAccountsLoading(false, "RESETBREACH" + breachID));
  };
};

export const resetPagedExposuresAccountDataForBreaches = () => {
  return async (dispatch, getState) => {
    dispatch(setExposuresAccountsLoading(true, "CLEARBREACH"));
    dispatch(clearExposuresPagedAccountDataNonZero());
    dispatch(setExposuresAccountsLoading(false, "CLEARBREACH"));
  };
};

export const runFetchPagedExposuresAccountData = async (
  searchCriteria,
  getState,
  dispatch
) => {
  let json;

  //
  // get the cached search  criteria here. we'll see if it still matches what we were given
  //

  const {
    domainsFilter,
    breachID,
    pageSize,
    pageNum,
    sortBy,
    sortDesc,
    nameFilter,
    archivedBreachFilter,
    ignoredAccountFilter,
  } = getState().cyberRisk.customerData.exposures.searchData;

  const domainFilterString = domainsFilter ? domainsFilter.join(",") : "";
  try {
    json = await FetchCyberRiskUrl(
      "emailexposures/accounts/v1/",
      {
        breach_id: breachID,
        page_size: pageSize,
        page_number: pageNum,
        sort_by: sortBy,
        sort_desc: sortDesc,
        name_filter: nameFilter,
        domain_filter: domainFilterString,
        exclude_ignored_accounts: ignoredAccountFilter === "active_only",
        only_ignored_accounts: ignoredAccountFilter === "ignored_only",
        exclude_archived_breaches: archivedBreachFilter === "active_only",
      },
      null,
      dispatch,
      getState
    );
  } catch (e) {
    let { context } = getState().cyberRisk.customerData.exposures.searchData;
    // if the current search is not the latest, then just fail quietly..
    if (searchCriteria.context == context) {
      dispatch(setExposuresAccountsLoading(false, context));
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching email account exposures data"
        )
      );
    }
    LogError("error fetching email account exposures", e);

    return;
  }

  let exposures = json.accountExposures ? json.accountExposures : [];
  let { context } = getState().cyberRisk.customerData.exposures.searchData;
  if (searchCriteria.context == context) {
    dispatch(setExposuresAccountsLoading(false, context));
    dispatch(
      setExposuresBreachData(json.breaches, json.breachAssigneeSharedUsers)
    );
    dispatch(setExposuresAvailableAssignees(json.availableAssignees));
    dispatch(setExposuresVIPAccountData(json.vipAccounts));
    dispatch(setExposuresMonitoredDomains(json.monitoredDomains));
    dispatch(
      setExposuresCurrentPageDetails(
        breachID,
        pageNum,
        json.totalNumExposures,
        json.totalVipExposures,
        json.totalNumNotNotifiedExposures,
        sortBy,
        sortDesc
      )
    );
    dispatch(setExposuresPagedAccountData(breachID, pageNum, exposures));

    // check if any breaches have a sending notification, and if so start polling for completion
    (json.breaches || []).forEach((b) => {
      if (b.NotificationStatus === "sending") {
        dispatch(trackInProgressEmailExposureNotification(b.ID));
      }
    });
  }
};

// debounce the actual fetch used in the action below so we wait until the user has not typed a character for more than half a second
const debouncedRunFetchPagedExposuresAccountData = _debounce(
  runFetchPagedExposuresAccountData,
  500
);

export const fetchPagedExposuresAccountData = (
  breachID,
  currentPagedExposuresData,
  pageNum,
  pageSize,
  sortBy,
  sortDesc,
  nameFilter,
  domainsFilter,
  archivedBreachFilter,
  ignoredAccountFilter
) => {
  return async (dispatch, getState) => {
    if (!breachID) {
      breachID = 0;
    }
    const context =
      nameFilter +
      "." +
      domainsFilter +
      "." +
      archivedBreachFilter +
      "." +
      ignoredAccountFilter +
      "." +
      breachID;

    // First up set our loading state
    dispatch(setExposuresAccountsLoading(true, context));

    let accounts = currentPagedExposuresData[breachID]
      ? currentPagedExposuresData[breachID][pageNum]
      : [];
    if (accounts && accounts.length > 0) {
      dispatch(setExposuresCurrentPageNum(pageNum));
      dispatch(setExposuresAccountsLoading(false, context));
      return;
    }

    //
    // cache the search criteria, and debounce the bastasd
    //

    const searchCriteria = {
      domainsFilter,
      breachID,
      pageSize,
      pageNum,
      sortBy,
      sortDesc,
      nameFilter,
      archivedBreachFilter,
      ignoredAccountFilter,
      context,
    };
    dispatch(setExposuresAccountsSearchCriteria(searchCriteria));
    debouncedRunFetchPagedExposuresAccountData(
      searchCriteria,
      getState,
      dispatch
    );
  };
};

export const setIgnoredEmailAccounts = (accountIds, ignored) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "emailexposures/accounts/ignore/v1/",
        {
          account_ids: accountIds.join(","),
          ignored,
        },
        { method: "PUT" },
        dispatch,
        getState
      );

      dispatch(clearExposuresHistoryAction());
    } catch (e) {
      LogError("Error setting accounts ignored", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return null;
  };
};

export const setVipAccounts = (emails, vip) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "emailexposures/accounts/vip/v1/",
        {
          emails: emails.join(","),
          vip,
        },
        { method: "PUT" },
        dispatch,
        getState
      );

      dispatch(clearExposuresHistoryAction());
    } catch (e) {
      LogError("Error setting vip accounts", e);

      throw e;
    }

    return null;
  };
};

export const setArchivedIdentityBreaches = (breachIds, archived) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "emailexposures/breaches/archive/v1/",
        {
          breach_ids: breachIds.join(","),
          archived,
        },
        { method: "PUT" },
        dispatch,
        getState
      );

      dispatch(clearExposuresHistoryAction());
    } catch (e) {
      LogError("Error setting breaches archived", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return null;
  };
};

export const sendEmailExposureNotificationToSpecificAccounts = (
  breachId,
  subject,
  message,
  accountIds
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "emailexposures/notifications/specific_accounts/v1/",
        {
          breach_id: breachId,
          subject,
          message,
          account_ids: accountIds.join(","),
        },
        { method: "POST" },
        dispatch,
        getState
      );

      dispatch(clearExposuresHistoryAction());
    } catch (e) {
      LogError("Error sending breach notification to specific accounts", e);

      throw e;
    }

    return null;
  };
};

export const sendEmailExposureNotificationToAllNotNotified = (
  breachId,
  subject,
  message,
  nameFilter,
  domainFilter,
  excludeIgnoredAccounts,
  onlyIgnoredAccounts,
  excludeAccountIds,
  notifiedAccountIdExceptions
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "emailexposures/notifications/all_not_notified/v1/",
        {
          breach_id: breachId,
          subject,
          message,
          name_filter: nameFilter,
          domain_filter: domainFilter.join(","),
          exclude_ignored_accounts: excludeIgnoredAccounts,
          only_ignored_accounts: onlyIgnoredAccounts,
          exclude_account_ids: excludeAccountIds.join(","),
          notified_account_id_exceptions: notifiedAccountIdExceptions.join(","),
        },
        { method: "POST" },
        dispatch,
        getState
      );

      dispatch(clearExposuresHistoryAction());
    } catch (e) {
      LogError("Error sending breach notification to all not notified", e);

      throw e;
    }

    return null;
  };
};

export const trackInProgressEmailExposureNotification = (breachId) => {
  return async (dispatch, getState) => {
    let { exposures } = getState().cyberRisk.customerData;

    if (
      exposures.notificationStatuses[breachId] &&
      exposures.notificationStatuses[breachId].notificationStatus === "sending"
    ) {
      // we're already tracking this breach
      return;
    }

    dispatch(
      setExposureNotificationStatus(breachId, {
        notificationStatus: "sending",
      })
    );

    setTimeout(
      () => dispatch(pollForEmailExposureNotificationStatus(breachId, 5000)),
      5000
    );
  };
};

export const pollForEmailExposureNotificationStatus = (breachId, lastSleep) => {
  return async (dispatch, getState) => {
    const newStatus = await dispatch(
      fetchEmailExposureNotificationStatus(breachId)
    );
    const nextSleep = lastSleep * 1.5;

    // if the notification is still sending, queue up the next poll
    if (newStatus.notificationStatus === "sending") {
      setTimeout(
        () =>
          dispatch(pollForEmailExposureNotificationStatus(breachId, nextSleep)),
        nextSleep
      );
      return;
    }

    let { exposures } = getState().cyberRisk.customerData;
    let breachName = _find(
      exposures.breach_data,
      (b) => b.ID === breachId
    ).Title;

    if (newStatus.notificationStatus === "error") {
      dispatch(
        addDefaultUnknownErrorAlert(
          `There was an error sending the notification for breach ${breachName}`
        )
      );
    }
    if (
      newStatus.notificationStatus === "sent" ||
      newStatus.notificationStatus === "error"
    ) {
      const currentSearchData = exposures.searchData;

      // if user is currently looking at the breach, reload it
      if (currentSearchData.breachID === breachId) {
        dispatch(setExposuresAccountsLoading(true, currentSearchData.context));
        await runFetchPagedExposuresAccountData(
          currentSearchData,
          getState,
          dispatch
        );
        dispatch(fetchEmailExposureHistory(breachId, true));
      } else {
        // otherwise just remove the existing state so it can be reloaded next time
        dispatch(clearExposuresPagedAccountData(breachId));
        dispatch(clearExposuresHistoryAction());
      }
    }
  };
};

export const fetchEmailExposureNotificationStatus = (breachId) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "emailexposures/notifications/status/v1/",
        {
          breach_id: breachId,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting breach notification to all not notified", e);

      throw e;
    }

    if (json.result) {
      dispatch(setExposureNotificationStatus(breachId, json.result));
      return json.result;
    }

    return null;
  };
};

export const updateVendorSurveyResendSchedule = (
  surveyID,
  vendorID,
  resendEnabled,
  resendDate,
  resendInterval
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/resend/update/v1/",
        {
          survey_id: surveyID,
          resend_enabled: resendEnabled,
          resend_date: resendDate,
          resend_interval: resendInterval,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating vendor survey resend schedule", e);

      throw new Error(
        "Error updating questionnaire resend schedule. Please try again later."
      );
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const updateVendorSurveyAddToSharedProfile = (
  surveyID,
  addToSharedProfile
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/add_to_shared_profile/v1/",
        {
          survey_id: surveyID,
          add_to_shared_profile: addToSharedProfile,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating add to Trust Page setting", e);
      throw e;
    }
  };
};

export const updateVendorSurveyShareAnswersWithOrg = (
  surveyID,
  shareAnswersWithOrg
) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "survey/share_answers_with_org/v1/",
        {
          survey_id: surveyID,
          share_answers_with_org: shareAnswersWithOrg,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating share answers with org setting", e);
      throw e;
    }
  };
};

export const fetchProjectionForExistingRemediatingSurvey = (surveyId) => {
  return async (dispatch, getState) => {
    const timestamp = new Date();
    const timestampKey = `survey_${surveyId}`;
    dispatch(setProjectionRequestTimestamp(timestamp, timestampKey));
    dispatch(setProjectionForSurvey(surveyId, true, null));
    const opts = {
      survey_id: surveyId,
    };
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "projection/remediatingsurvey/v1",
        opts,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setProjectionForSurvey(surveyId, false, e));
      LogError("Error retrieving score projection for remediation request", e);

      throw e;
    }

    if (
      timestamp == getState().common.projection.requestTimestamps[timestampKey]
    ) {
      dispatch(
        setProjectionForSurvey(
          surveyId,
          false,
          null,
          json.initialScore,
          json.currentScore,
          json.projectedScore
        )
      );
    }

    return null;
  };
};

export const fetchVendorBreakdown = (force = false, filters) => {
  return async (dispatch, getState) => {
    const {
      vendorBreakdown: existingVendorBreakdown,
      filters: currentFilters,
    } = getState().cyberRisk.customerData;

    if (
      !force &&
      existingVendorBreakdown &&
      (!filters || _isEqual(filters, currentFilters))
    ) {
      return existingVendorBreakdown;
    }

    const newFilters = filters || currentFilters;

    // Unset the vendor breakdown while we wait
    dispatch(setVendorBreakdownData(null));

    let resp;
    try {
      resp = await FetchCyberRiskUrl(
        "watchlist/breakdown/v1",
        {
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendor breakdown", e);

      if (resp.status === 402) {
        console.log("Error fetching vendor breakdown: not authorised");
      } else {
        dispatch(
          addDefaultUnknownErrorAlert("Error fetching vendor breakdown")
        );
      }
      return null;
    }

    dispatch(setVendorBreakdownData(resp));

    return resp;
  };
};

export const fetchOrgIntegrationSettings = (force = false) => {
  return async (dispatch, getState) => {
    const { orgIntegrationSettings } = getState().cyberRisk;
    if (!force && orgIntegrationSettings) {
      return orgIntegrationSettings;
    }

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "integrations/list/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching org integration data", e);
      dispatch(
        addDefaultUnknownErrorAlert(
          "Error fetching list of configured integrations"
        )
      );
      return null;
    }

    const settingsObj = {
      integrations: json.integrations,
    };

    dispatch(setOrgIntegrationSettings(settingsObj));
    return settingsObj;
  };
};

export const deleteIntegration = (id) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "integration/v1/",
        {
          id,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting integration for org", e);
      dispatch(addDefaultUnknownErrorAlert("Error deleting integration"));
      throw e;
    }
    dispatch(fetchAllOrgNotifications(true));
    await dispatch(fetchOrgIntegrationSettings(true));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return null;
  };
};

export const updateIntegrationPayloadTemplates = (
  integrationID,
  uuid,
  payloadTemplates
) => {
  return async (dispatch, getState) => {
    let json;
    try {
      // create the post body
      const body = {
        integration_id: integrationID,
        uuid: uuid,
        ignore_cert_check: ignoreCerts,
        payload_templates: payloadTemplates,
      };

      json = await FetchCyberRiskUrl(
        "integration/payload/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify(body),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating integration payload templates", e);
      dispatch(
        addDefaultUnknownErrorAlert("Error saving integration payload template")
      );
      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const fetchAlertsOrActivityStreamForOrgUser = (
  forced,
  background,
  days,
  filters
) => {
  return async (dispatch, getState) => {
    dispatch(reloadCompleteActivityStream(background));
  };
};

export const dismissSingleNotification = (notificationId, noGroups) => {
  return async (dispatch, getState) => {
    let json;

    try {
      json = await FetchCyberRiskUrl(
        "alerts/dismiss/v1",
        {
          notification_ids: [notificationId].join(", "),
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error dismissing notification for user", e);

      dispatch(addDefaultUnknownErrorAlert("Error dismissing notification"));
      return null;
    }

    // success - remove the notification from the existing set of groups
    if (!!!noGroups) {
      dispatch(removeNotificationFromAlertGroups(notificationId));
    }
    return null;
  };
};

export const dismissGroup = (groupIdx) => {
  return async (dispatch, getState) => {
    let json;

    let { alerts } = getState().cyberRisk.customerData;
    if (groupIdx >= alerts.groups.length) {
      dispatch(addDefaultUnknownErrorAlert("Error dismissing notifications"));
      return null;
    }
    let group = alerts.groups[groupIdx];
    const notificationIds = [];
    for (let i = 0; i < group.alerts.length; i++) {
      notificationIds.push(group.alerts[i].id);
    }
    const opts = {
      notification_ids: notificationIds,
    };
    try {
      json = await FetchCyberRiskUrl(
        "alerts/dismiss/v1",
        {},
        {
          method: "POST",
          body: opts,
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error dismissing notification for user", e);

      dispatch(addDefaultUnknownErrorAlert("Error dismissing notification"));
      return null;
    }

    // success - remove the notification from the existing set of groups
    dispatch(removeNotificationGroupFromAlerts(groupIdx));
    return null;
  };
};

export const removeNotificationFromAlertGroups = (notificationId) => {
  return async (dispatch, getState) => {
    // remove notifications from groups where the notificationId matches
    let { alerts } = getState().cyberRisk.customerData;
    let { count } = alerts;
    const newAlertsData = produce(alerts, (draft) => {
      draft.loading = false;
      draft.error = null;

      for (var j = 0; j < draft.groups.length; j++) {
        for (var i = 0; i < draft.groups[j].alerts.length; i++) {
          if (draft.groups[j].alerts[i].id === notificationId) {
            delete draft.groups[j].alerts[i].busy;
            draft.groups[j].alerts.splice(i, 1);
            count = count - 1;
            break;
          }
        }
        if (draft.groups[j].alerts.length == 0) {
          draft.groups.splice(j, 1);
          break;
        }
      }
      draft.count = count;
    });

    dispatch(setCustomerData({ alerts: newAlertsData }));
  };
};

export const removeNotificationGroupFromAlerts = (groupIdx) => {
  return async (dispatch, getState) => {
    // set the notification to busy
    let { alerts } = getState().cyberRisk.customerData;
    let { count } = alerts;
    const newAlertsData = produce(alerts, (draft) => {
      draft.loading = false;
      draft.error = null;
      if (groupIdx < draft.groups.length) {
        count = count - draft.groups[groupIdx].alerts.length;
        draft.groups.splice(groupIdx, 1);
      }
      draft.count = count;
    });
    dispatch(setCustomerData({ alerts: newAlertsData }));
  };
};

export const ignoreNotificationGroup = (groupIdx) => {
  return async (dispatch, getState) => {
    let json;

    let { alerts } = getState().cyberRisk.customerData;
    if (groupIdx >= alerts.groups.length) {
      dispatch(
        addDefaultUnknownErrorAlert("Error forgetting notification group")
      );
      return null;
    }
    let group = alerts.groups[groupIdx];
    let { uuid } = group;
    try {
      json = await FetchCyberRiskUrl(
        "alerts/ignore_uuid/v1",
        {
          uuid: uuid,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error ignoring notification uuid (type) for user", e);

      dispatch(addDefaultUnknownErrorAlert("Error ignoring notification type"));
      return null;
    }

    return null;
  };
};

export const fetchOrganisationFlags = (force = false) => {
  return async (dispatch, getState) => {
    if (!force && getState().cyberRisk.orgFlags) {
      return getState().cyberRisk.orgFlags;
    }

    // Invalidate RTK-Query cached org flags
    dispatch(
      OrganisationFlagsAPI.util.invalidateTags([OrganisationFlagsTags.orgFlags])
    );

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "account/flags/v1",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching organisation flags", e);

      dispatch(addDefaultUnknownErrorAlert("Error fetching account settings"));
      return null;
    }

    dispatch(setOrganisationFlags(json));

    return json;
  };
};

export const setOrganisationFlag = (name, value) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "account/flags/v1",
        { name, value },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting organisation flag", e);

      dispatch(addDefaultUnknownErrorAlert("Error updating account settings"));
      throw e;
    }

    dispatch(
      setOrganisationFlags({
        ...getState().cyberRisk.orgFlags,
        [name]: value,
      })
    );

    // Invalidate RTK-Query cached org flags
    dispatch(
      OrganisationFlagsAPI.util.invalidateTags([OrganisationFlagsTags.orgFlags])
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

//======================================= Typosquatting ===========================================

export const scanTyposquatDomain = (url) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "typosquat/domain_scan/v1/",
        { url: url },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error scanning domain for typosquats : ", e);
      throw e;
    }
  };
};

export const changeOrgSquatFlags = (aOwnershipIds, hostName, riskStatus) => {
  return async (dispatch, getState) => {
    const typosquat =
      getState().cyberRisk.customerData &&
      getState().cyberRisk.customerData.typosquat
        ? getState().cyberRisk.customerData.typosquat
        : null;
    //set whole screen to loading
    dispatch(setCustomerData({ typosquat: { ...typosquat, loading: true } }));

    try {
      let json = await FetchCyberRiskUrl(
        "typosquat/orgflags/v1/",
        { ownership_ids: aOwnershipIds, risk_status: riskStatus },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error modifying org squat flags : ", e);
      dispatch(
        setCustomerData({
          typosquat: {
            ...typosquat,
            loading: false,
            error: {
              errorText: "Error modifying org squat flags",
              actionText: "Try again",
              actionOnClick: () =>
                dispatch(
                  changeOrgSquatFlags(aOwnershipIds, hostName, riskStatus)
                ),
            },
          },
        })
      );

      return typosquat;
    }
    //now we must update the typosquat instance inside our global state to the new risk status
    dispatch(setTyposquatDomainDirty(hostName, true));
    dispatch(setCustomerData({ typosquat: { ...typosquat, loading: false } }));
  };
};

export const disableSquatDomain = (cloudscanId) => {
  return async (dispatch, getState) => {
    const typosquat =
      getState().cyberRisk.customerData &&
      getState().cyberRisk.customerData.typosquat
        ? getState().cyberRisk.customerData.typosquat
        : null;
    //call disable endpoint
    //set whole screen to loading
    dispatch(setCustomerData({ typosquat: { ...typosquat, loading: true } }));
    //call disable endpoint

    try {
      let json = await FetchCyberRiskUrl(
        "typosquat/disable_scan/v1/",
        { cloudscan_id: cloudscanId },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error Disabling Typosquat Scan", e);
      dispatch(
        setCustomerData({
          typosquat: {
            ...typosquat,
            loading: false,
            error: {
              errorText: "Error Disabling Typosquat Scan",
              actionText: "Try again",
              actionOnClick: () => dispatch(disableSquatDomain(cloudscanId)),
            },
          },
        })
      );

      return typosquat;
    }
    dispatch(setCustomerData({ typosquat: { ...typosquat, loading: false } }));

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const enableSquatDomain = (hostname) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "typosquat/enable_scan/v1/",
        { hostname: hostname },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error Enabling Typosquat Scan", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const fetchSquatDetails = (hostnameId, hostname, forced) => {
  return async (dispatch, getState) => {
    const typosquat =
      getState().cyberRisk.customerData &&
      getState().cyberRisk.customerData.typosquat
        ? getState().cyberRisk.customerData.typosquat
        : null;
    let newTyposquatDomainDetails = {};
    let json;

    //set typosquat state to "loading"
    dispatch(setCustomerData({ typosquat: { ...typosquat, loading: true } }));

    try {
      json = await FetchCyberRiskUrl(
        "typosquat/details/v1/",
        {
          hostname_id: hostnameId,
          hostname: hostname,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      //set typosquat state to "loading"
      dispatch(
        setCustomerData({ typosquat: { ...typosquat, loading: false } })
      );
      LogError("Error fetching typosquat details data", e);
      dispatch(
        setCustomerData({
          typosquat: {
            loading: false,
            error: {
              errorText:
                hostname != ""
                  ? `Error fetching typosquatting data for domain '${hostname}'`
                  : "Error fetching typosquatting data for domain",
              actionText: "Try again",
              actionOnClick: () =>
                dispatch(fetchSquatDetails(hostnameId, true)),
            },
          },
        })
      );
    }
    //locate our domain within the typosquat state
    //we want to append the JSON we got to it
    newTyposquatDomainDetails.results = {
      domains: typosquat.results.domains.map((domain) => {
        if (domain.hostname_id === hostnameId) {
          return { ...domain, ...json };
        } else {
          return { ...domain };
        }
      }),
    };
    dispatch(
      setCustomerData({
        typosquat: {
          ...newTyposquatDomainDetails,
          loading: false,
          error: null,
        },
      })
    );
    return newTyposquatDomainDetails;
  };
};

export const fetchCustomerTyposquatSummary = (
  forced,
  hostname,
  showLoading
) => {
  return async (dispatch, getState) => {
    const typosquat =
      getState().cyberRisk.customerData &&
      getState().cyberRisk.customerData.typosquat
        ? getState().cyberRisk.customerData.typosquat
        : null;
    let newTyposquatSummary = {};
    let json;

    if (!forced && typosquat && !typosquat.loading && !typosquat.error) {
      // We have cached cloudscans and summary data, don't fetch again
      return typosquat;
    }

    //set typosquat state to "loading" only if this isn't a background operation for one hostname
    dispatch(
      setCustomerData({
        typosquat: { ...typosquat, loading: !hostname || showLoading },
      })
    );

    try {
      json = await FetchCyberRiskUrl(
        "typosquat/summary/v1/",
        hostname ? { hostname } : {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      //set typosquat state to "loading"
      dispatch(
        setCustomerData({ typosquat: { ...typosquat, loading: false } })
      );
      LogError("Error fetching typosquat summary data", e);
      dispatch(
        setCustomerData({
          typosquat: {
            loading: false,
            error: {
              errorText: "Failed to retrieve typosquatting data",
              actionText: "Try again",
              actionOnClick: () =>
                dispatch(
                  fetchCustomerTyposquatSummary(true, hostname, showLoading)
                ),
            },
          },
        })
      );

      return typosquat;
    }

    if (!json) {
      newTyposquatSummary = {
        loading: false,
        error: {
          errorText: "Failed to retrieve typosquatting data",
          actionText: "Try again",
          actionOnClick: () =>
            dispatch(
              fetchCustomerTyposquatSummary(true, hostname, showLoading)
            ),
        },
      };
      dispatch(setCustomerData({ typosquat: newTyposquatSummary }));
      return newTyposquatSummary;
    }

    if (hostname) {
      let modifiedDomain = json.domains.find((d) => d.hostname === hostname);
      newTyposquatSummary.results = {
        domains: typosquat.results.domains.map((d) =>
          d.hostname === hostname ? modifiedDomain : d
        ),
      };
    } else {
      newTyposquatSummary.results = {
        ...json,
      };
    }

    newTyposquatSummary.loading = false;
    newTyposquatSummary.error = null;

    dispatch(setCustomerData({ typosquat: newTyposquatSummary }));
    return newTyposquatSummary;
  };
};

//This sets a dirty flag for a squat domain if any changes were made to it
//(such as moving typosquats between categories etc)
export const setTyposquatDomainDirty = (hostname, isDirty) => {
  return async (dispatch, getState) => {
    dispatch(setTyposquatDomainDirtyAction(hostname, isDirty));
  };
};

export const fetchHistoryForTyposquatDomain = (
  rootDomain,
  squatDomain,
  force = false
) => {
  return async (dispatch, getState) => {
    if (
      !force &&
      getState().cyberRisk.customerData &&
      getState().cyberRisk.customerData.typosquat &&
      getState().cyberRisk.customerData.typosquat.history &&
      getState().cyberRisk.customerData.typosquat.history[squatDomain] &&
      !getState().cyberRisk.customerData.typosquat.history[squatDomain]
        .loading &&
      !getState().cyberRisk.customerData.typosquat.history[squatDomain].error
    ) {
      return getState().cyberRisk.customerData.typosquat.history[squatDomain]
        .history;
    }

    let json;

    try {
      dispatch(
        setTyposquatHistoryData(squatDomain, {
          loading: true,
          error: null,
        })
      );
      json = await FetchCyberRiskUrl(
        "typosquat/events/v1/",
        {
          root_domain: rootDomain,
          squat_domain: squatDomain,
        },
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching typosquat events", e);

      dispatch(
        setTyposquatHistoryData(squatDomain, {
          loading: false,
          error: {
            errorText: "Error fetching history",
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(
                fetchHistoryForTyposquatDomain(rootDomain, squatDomain, true)
              ),
          },
        })
      );
      dispatch(
        addDefaultUnknownErrorAlert("Error fetching typosquat domain history")
      );
      return null;
    }

    dispatch(
      setTyposquatHistoryData(squatDomain, {
        loading: false,
        error: null,
        history: json.events,
      })
    );

    return json.events;
  };
};

export const setHomepageYoutubeVideo = (
  videoId,
  videoTitle,
  orgType,
  permission
) => {
  return async (dispatch, getState) => {
    let json = "";
    try {
      json = await FetchCyberRiskUrl(
        "admin/youtube/set/v1",
        {
          video_id: videoId,
          video_title: videoTitle,
          org_type: orgType,
          permission: permission,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting YouTube videos", e);
      throw e;
    }
    if (json.status != "OK") {
      LogError("Did not receive OK from /youtube/set/v1");
    }
    return json;
  };
};

export const getAllHomepageYoutubeVideos = () => {
  return async (dispatch, getState) => {
    let json = "";
    try {
      json = await FetchCyberRiskUrl(
        "admin/youtube/get_all/v1",
        {},
        { method: "GET" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting YouTube videos", e);
      throw e;
    }
    return json;
  };
};

export const publishReleaseNotes = (
  releaseNotesURL,
  screenshotURL,
  releaseNotesTitle,
  releaseNotesBlurb,
  buttonText,
  buttonLink,
  buttonUserPerms,
  buttonEntitlements
) => {
  return async (dispatch, getState) => {
    let json = "";
    try {
      json = await FetchCyberRiskUrl(
        "alerts/new_features/v1/",
        {},
        {
          method: "POST",
          body: JSON.stringify({
            url: releaseNotesURL,
            screenshotURL: screenshotURL,
            title: releaseNotesTitle,
            blurb: releaseNotesBlurb,
            buttonText,
            buttonLink,
            buttonUserPerms,
            buttonEntitlements,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error posting release Notes", e);

      throw e;
    }
    if (json.status != "OK") {
      LogError("Did not receive OK response from alerts/new_features/v1/");
    }

    return json;
  };
};

export const fetchConcentrationRisk = (forced, filters) => {
  return async (dispatch, getState) => {
    const {
      concentrationRisk,
      concentrationRiskLoading,
      filters: currentFilters,
    } = getState().cyberRisk.customerData;

    if (concentrationRiskLoading || (!forced && !!concentrationRisk)) {
      // We have cached cloudscans and summary data, don't fetch again
      return concentrationRisk;
    }

    const newFilters = filters || currentFilters;

    dispatch(setCustomerData({ concentrationRiskLoading: true }));

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "concentrationrisk/v1/",
        {
          vendor_label_ids: newFilters.vendorLabelIds,
          vendor_label_ids_match_all: newFilters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: newFilters.vendorLabelIdsDoNotMatch,
          include_unlabeled: newFilters.includeUnlabeled,
          min_score: newFilters.minScore,
          max_score: newFilters.maxScore,
          vendor_tiers: newFilters.vendorTiers,
          vendor_assessment_classifications:
            newFilters.vendorAssessmentClassifications,
          vendor_assessment_authors: newFilters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            newFilters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: newFilters.vendorReassessmentStartDate,
          vendor_reassessment_end: newFilters.vendorReassessmentEndDate,
          vendor_reassessment_before: newFilters.vendorReassessmentDateBefore,
          vendor_reassessment_between: newFilters.vendorReassessmentDateBetween,
          portfolio_ids: newFilters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(newFilters.selectedAttributes),
          survey_type_ids: newFilters.vendorSurveyTypes,
          evidence_type_ids: newFilters.vendorEvidenceTypes,
          fourth_party_product_uuids: newFilters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching concentration risk", e);

      dispatch(setCustomerData({ concentrationRiskLoading: false }));
      throw e;
    }

    dispatch(
      setCustomerData({
        concentrationRisk: json,
        concentrationRiskLoading: false,
      })
    );

    return json;
  };
};

export const fetchOwnSharedAssessmentIfNecessary = () => {
  return async (dispatch, getState) => {
    let { sharedAssessment } = getState().cyberRisk.customerData;

    if (!sharedAssessment) {
      return;
    }

    try {
      sharedAssessment = await FetchCyberRiskUrl(
        "mysharedassessment/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching own shared assessment data", e);

      throw e;
    }

    dispatch(setCustomerData({ sharedAssessment }));
  };
};

export const fetchOwnSharedAssessment = (
  force = false,
  refetchOnlyIfCached = false
) => {
  return async (dispatch, getState) => {
    let { sharedAssessment } = getState().cyberRisk.customerData;

    if (!sharedAssessment && refetchOnlyIfCached) {
      return;
    }

    if (!!sharedAssessment && !force) {
      return sharedAssessment;
    }

    try {
      sharedAssessment = await FetchCyberRiskUrl(
        "mysharedassessment/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching own shared assessment data", e);

      throw e;
    }

    dispatch(setCustomerData({ sharedAssessment }));

    return sharedAssessment;
  };
};

export const setOwnSharedAssessmentMetadata = (
  contactName,
  contactEmail,
  companyDescription,
  includeRiskRating,
  includeIndustryAverage,
  profileLogo
) => {
  return async (dispatch, getState) => {
    const opts = {};

    if (contactName !== undefined) {
      opts.contact_name = contactName;
      if (contactName === "") {
        opts.clear_contact_name = true;
      }
    }
    if (contactEmail !== undefined) {
      opts.contact_email = contactEmail;
      if (contactEmail === "") {
        opts.clear_contact_email = true;
      }
    }
    if (companyDescription !== undefined) {
      opts.company_description = companyDescription;
      if (companyDescription === "") {
        opts.clear_company_description = true;
      }
    }
    if (includeRiskRating !== undefined) {
      opts.include_risk_rating = includeRiskRating;
    }
    if (includeIndustryAverage !== undefined) {
      opts.include_industry_average = includeIndustryAverage;
    }
    if (profileLogo !== undefined) {
      opts.profile_logo = profileLogo;
    }

    try {
      await FetchCyberRiskUrl(
        "mysharedassessment/meta/v1/",
        opts,
        {
          method: "PUT",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error setting own shared assessment data", e);
      throw e;
    }
  };
};

export const fetchOwnSharedAssessmentAccessIfNecessary = () => {
  return async (dispatch, getState) => {
    let { sharedAssessmentAccess } = getState().cyberRisk.customerData;

    if (!sharedAssessmentAccess) {
      return;
    }

    try {
      sharedAssessmentAccess = await FetchCyberRiskUrl(
        "verifiedvendor/access/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching own shared assessment access data", e);

      throw e;
    }

    dispatch(setCustomerData({ sharedAssessmentAccess }));
  };
};

export const fetchOwnSharedAssessmentAccess = (force = false) => {
  return async (dispatch, getState) => {
    let { sharedAssessmentAccess } = getState().cyberRisk.customerData;

    if (!!sharedAssessmentAccess && !force) {
      return sharedAssessmentAccess;
    }

    try {
      sharedAssessmentAccess = await FetchCyberRiskUrl(
        "verifiedvendor/access/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      console.error(e);
      LogError("Error fetching own shared assessment access data", e);

      throw e;
    }

    dispatch(setCustomerData({ sharedAssessmentAccess }));

    return sharedAssessmentAccess;
  };
};

export const fetchOwnSharedAssessmentAccessLog = (
  force = false,
  overwrite = false
) => {
  return async (dispatch, getState) => {
    let { sharedAssessmentAccessLog } = getState().cyberRisk.customerData;

    if (!!sharedAssessmentAccessLog && !force) {
      return sharedAssessmentAccessLog;
    }

    let usersById = {};
    let accessLog = [];

    if (sharedAssessmentAccessLog && sharedAssessmentAccessLog.usersById) {
      usersById = { ...sharedAssessmentAccessLog.usersById };
    }

    if (sharedAssessmentAccessLog && sharedAssessmentAccessLog.accessLog) {
      accessLog = [...sharedAssessmentAccessLog.accessLog];
    }

    try {
      sharedAssessmentAccessLog = await FetchCyberRiskUrl(
        "verifiedvendor/accesslog/v1/",
        {
          limit: 40,
          offset: overwrite ? 0 : accessLog.length,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching own shared assessment access data", e);

      throw e;
    }

    if (!overwrite) {
      sharedAssessmentAccessLog = {
        usersById: { ...usersById, ...sharedAssessmentAccessLog.usersById },
        accessLog: [...accessLog, ...sharedAssessmentAccessLog.accessLog],
        more: sharedAssessmentAccessLog.more,
      };
    }

    dispatch(setCustomerData({ sharedAssessmentAccessLog }));

    return sharedAssessmentAccessLog;
  };
};

export const inviteVerifiedVendorUser = (email, noRefresh = false) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "verifiedvendor/users/v1/",
        { recipient_email: email },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error inviting user", e);

      throw e;
    }

    // kick off call to update the activity stream
    if (!noRefresh) {
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    }

    if (!noRefresh) {
      await dispatch(fetchOwnSharedAssessmentAccess(true));
    }
  };
};

export const inviteVerifiedVendorOrg = (orgID) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "verifiedvendor/orgs/v1/",
        { org_id: orgID },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error inviting org", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    await dispatch(fetchOwnSharedAssessmentAccess(true));
  };
};

export const cancelVerifiedVendorInvite = (email) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "verifiedvendor/invites/v1/",
        { email_address: email },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting invite", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    await dispatch(fetchOwnSharedAssessmentAccess(true));
  };
};

export const fetchWhoisOverrideForVendor = (primaryHostname, force = false) => {
  return async (dispatch, getState) => {
    if (!primaryHostname) {
      // we need primary hostname to both identify the current vendor, and to key the results cache
      LogError(
        "performReverseWhoisLookup() - Hostname of associated vendor was not supplied."
      );
      return null;
    }

    const { cyberRisk } = getState();
    let whoisOverride = _get(cyberRisk, "whoisOverride");
    if (whoisOverride) {
      whoisOverride = whoisOverride[primaryHostname];
    }
    if (!!whoisOverride && !whoisOverride.error && !force) {
      return whoisOverride;
    }

    try {
      dispatch(
        setVendorWhoisOverrideData(primaryHostname, {
          loading: true,
          error: null,
        })
      );

      whoisOverride = await FetchCyberRiskUrl(
        "whois/override/v1/",
        {
          primary_hostname: primaryHostname,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching whois override data for ${primaryHostname}`, e);
      dispatch(
        setVendorWhoisOverrideData(primaryHostname, {
          loading: false,
          error: `Failed to retrieve whois domain data for vendor`,
        })
      );

      throw e;
    }
    dispatch(
      setVendorWhoisOverrideData(primaryHostname, {
        loading: false,
        data: whoisOverride,
        error: null,
      })
    );

    return whoisOverride;
  };
};

export const fetchWhoisLookupForHostnameNoState = (hostname, getAbusePage) => {
  return async (dispatch, getState) => {
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "whois/lookup/v1/",
        {
          hostname: hostname,
          get_abuse_page: getAbusePage,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching whois data", e);

      throw e;
    }

    return json;
  };
};

export const fetchWhoisLookupForHostname = (hostname, force = false) => {
  return async (dispatch, getState) => {
    const { cyberRisk } = getState();
    let whoisData = _get(cyberRisk, `whoisData[${hostname}]`);

    if (!!whoisData && !whoisData.error && !force) {
      return whoisData;
    }

    try {
      dispatch(
        setWhoisData(hostname, {
          loading: true,
          error: null,
        })
      );

      whoisData = await FetchCyberRiskUrl(
        "whois/lookup/v1/",
        {
          hostname: hostname,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching whois override data", e);
      dispatch(
        setWhoisData(hostname, {
          loading: false,
          error: "Failed to retrieve whois domain data for vendor",
        })
      );

      throw e;
    }

    dispatch(
      setWhoisData(hostname, {
        loading: false,
        data: whoisData,
        error: null,
      })
    );

    return whoisData;
  };
};

export const performReverseWhoisLookup = (
  primaryHostname,
  searchText,
  page,
  mergeResults
) => {
  return async (dispatch, getState) => {
    if (page <= 1) {
      page = 1;
    }
    if (!primaryHostname) {
      // we need primary hostname to both identify the current vendor, and to key the results cache
      LogError(
        "performReverseWhoisLookup() - Hostname of associated vendor was not supplied."
      );
      return null;
    }

    const state = getState();
    let searchResults;

    let existingResults = _get(state.cyberRisk, `reverseWhoisResults`);
    if (existingResults) {
      existingResults = _get(existingResults[primaryHostname], "searchResults");
    }

    try {
      if (!mergeResults) {
        dispatch(
          setReverseWhoisResults(primaryHostname, {
            loading: true,
            error: null,
            searchResults: null,
          })
        );
      } else {
        dispatch(
          setReverseWhoisResults(primaryHostname, {
            loading: true,
            error: null,
            searchResults: existingResults,
          })
        );
      }

      searchResults = await FetchCyberRiskUrl(
        "whois/domainsearch/v1",
        {
          primary_hostname: primaryHostname,
          search_string: searchText,
          page: page,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching whois override data", e);
      if (!mergeResults) {
        dispatch(
          setReverseWhoisResults(primaryHostname, {
            loading: false,
            error: "Failed to retrieve whois domain data for vendor",
            searchResults: null,
          })
        );
      } else {
        dispatch(
          setReverseWhoisResults(primaryHostname, {
            loading: false,
            error: "Failed to retrieve whois domain data for vendor",
            searchResults: existingResults,
          })
        );
      }

      throw e;
    }

    if (mergeResults) {
      const existingDomains = _get(existingResults, "domains");
      searchResults.domains = searchResults.domains
        ? searchResults.domains
        : [];

      if (existingDomains) {
        searchResults.domains.push(...existingDomains);
      }
    }

    await dispatch(
      setReverseWhoisResults(primaryHostname, {
        loading: false,
        searchResults: searchResults,
        error: null,
      })
    );
    return searchResults;
  };
};

export const addHostnamesToWhoisOverride = (
  resultsPrimaryHostname,
  overridePrimaryHostname,
  overrideVendorName,
  hostnamesToAdd
) => {
  return async (dispatch, getState) => {
    let json;
    try {
      dispatch(setReverseWhoisResultsLoading(resultsPrimaryHostname, true));
      json = await FetchCyberRiskUrl(
        "whois/override/domains/v1/",
        {
          primary_hostname: overridePrimaryHostname,
        },
        {
          method: "POST",
          body: JSON.stringify(hostnamesToAdd),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error adding new domains to whois override data", e);
      dispatch(setReverseWhoisResultsLoading(resultsPrimaryHostname, false));

      throw e;
    }

    if (resultsPrimaryHostname === overridePrimaryHostname) {
      await dispatch(
        removeReverseWhoisResults(resultsPrimaryHostname, hostnamesToAdd)
      );
    }
    dispatch(setReverseWhoisResultsLoading(resultsPrimaryHostname, false));

    return json.destinationVendorDetails;
  };
};

export const unionArrays = (x, y) => {
  var obj = {};
  for (var i = x.length - 1; i >= 0; --i) obj[x[i]] = x[i];
  for (var i = y.length - 1; i >= 0; --i) obj[y[i]] = y[i];
  var res = [];
  for (var k in obj) {
    res.push(obj[k]);
  }
  return res;
};

// This action returns a year's worth of category score data for a
// given vendorID (or null for the customer).
// This data is not persisted in state.
export const fetchCustomerOrVendorDailyCategoryScores = (
  vendorID,
  filters,
  category,
  includeCustomerData,
  isSubsidiary = false,
  includeCustomerPublicData = false,
  specificDates = []
) => {
  return async (dispatch, getState) => {
    const params = { category };
    if (isSubsidiary) {
      params.vendor_id = vendorID;
      params.subsidiary = true;
    } else if (vendorID) {
      params.vendor_id = vendorID;
      params.include_customer_data = includeCustomerData;
    } else {
      params.customer = true;
      params.include_customer_data = includeCustomerData;
      params.include_customer_public_data = includeCustomerPublicData;
      params.website_label_ids = filters.websiteLabelIds;
      params.website_portfolio_ids = filters.domainPortfolioIds;
    }

    const specificISODates = [];
    specificDates.map((date) => {
      specificISODates.push(moment(date).toISOString());
    });
    params.specific_dates = specificISODates.join(",");

    let result;

    try {
      result = await FetchCyberRiskUrl(
        "vendor/categoryscores/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching category scores", e);
      throw e;
    }

    const categoryScores = MapArrayToUnixDateWithoutTimezone(result.scores).map(
      (item) => ({
        overall_score: item.overall_score,
        time: item.time,
      })
    );
    const scoringChanges = MapArrayToUnixDateWithoutTimezone(
      result.scoringChanges
    );
    const categoryCurrentTotal = result.filteredTotalScore
      ? result.filteredTotalScore
      : -1;

    return {
      scores: categoryScores,
      scoringChanges: scoringChanges,
      filteredTotalScore: categoryCurrentTotal,
      specificDailyScores: result.specificDailyScores,
    };
  };
};

export const dismissFindingScansBanner = (vendorId, isSubsidiary = false) => {
  return async (dispatch, getState) => {
    // If a vendorID was provided, set the subdomainScanStarted property.
    if (vendorId) {
      if (!getState().cyberRisk.vendors[vendorId]) {
        return;
      }

      const tpvmSession = grabTPVMSession(getState);

      dispatch(
        setSubdomainsScanStarted(vendorId, isSubsidiary, tpvmSession, undefined)
      );

      const existingSummary = getState().cyberRisk.vendors[vendorId].summary;
      const newSummary = {
        ...existingSummary,
        result: {
          ...existingSummary.result,
          subdomainScanStarted: false,
        },
      };

      dispatch(
        setVendorData(vendorId, {
          summary: newSummary,
        })
      );
      return;
    }

    // Otherwise, set it for the customer.
    const newCustomerDataSummary = produce(
      getState().cyberRisk.customerData.summary,
      (draft) => {
        draft.result.subdomainScanStarted = false;
      }
    );

    dispatch(setCustomerData({ summary: newCustomerDataSummary }));
  };
};

export const requestExecSummaryPDFExport = (
  selectedReportConfig,
  useFilters,
  emailRecipients,
  isIncludingSubsidiaries = false,
  isOnlySubsidiaries = false
) => {
  return async (dispatch, getState) => {
    const { filters } = getState().cyberRisk.customerData;
    const opts = {
      ...selectedReportConfig,
      vendor_label_ids: useFilters ? filters.vendorLabelIds : null,
      vendor_label_ids_match_all: useFilters
        ? filters.vendorLabelIdsMatchAll
        : null,
      vendor_label_ids_do_not_match: filters
        ? filters.vendorLabelIdsDoNotMatch
        : null,
      website_label_ids: useFilters ? filters.websiteLabelIds : null,
      website_label_ids_match_all: useFilters
        ? filters.websiteLabelIdsMatchAll
        : null,
      website_label_ids_do_not_match: useFilters
        ? filters.websiteLabelIdsDoNotMatch
        : null,
      website_include_unlabeled: useFilters
        ? filters.websiteIncludeUnlabeled
        : null,
      website_portfolio_ids: useFilters ? filters.domainPortfolioIds : null,
      include_unlabeled: useFilters ? filters.includeUnlabeled : null,
      min_score: useFilters ? filters.minScore : null,
      max_score: useFilters ? filters.maxScore : null,
      is_including_subsidiaries: isIncludingSubsidiaries,
      subsidiaries_only: isOnlySubsidiaries,
      subsidiary_ids: useFilters ? filters.subsidiaryIds : null,
      subsidiary_label_ids: useFilters ? filters.subsidiaryLabelIds : null,
      subsidiary_min_score: useFilters ? filters.subsidiaryMinScore : null,
      subsidiary_max_score: useFilters ? filters.subsidiaryMaxScore : null,
      email_recipients: emailRecipients,
      vendor_tiers: useFilters ? filters.vendorTiers : null,
      vendor_assessment_classifications: useFilters
        ? filters.vendorAssessmentClassifications
        : null,
      vendor_assessment_authors: useFilters
        ? filters.vendorAssessmentAuthors
        : null,
      vendor_assessment_authors_not: useFilters
        ? filters.vendorAssessmentAuthorDoNotMatch
        : null,
      vendor_reassessment_start: useFilters
        ? filters.vendorReassessmentStartDate
        : null,
      vendor_reassessment_end: useFilters
        ? filters.vendorReassessmentEndDate
        : null,
      vendor_reassessment_before: useFilters
        ? filters.vendorReassessmentDateBefore
        : null,
      vendor_reassessment_between: useFilters
        ? filters.vendorReassessmentDateBetween
        : null,
      portfolio_ids: useFilters
        ? filters.portfolioIds
        : selectedReportConfig.portfolio_ids,
      attributes: useFilters
        ? JSON.stringify(filters.selectedAttributes)
        : null,
      survey_type_ids: useFilters ? filters.vendorSurveyTypes : [],
      evidence_type_ids: useFilters ? filters.vendorEvidenceTypes : [],
      fourth_party_product_uuids: useFilters
        ? filters.fourthPartyProductUUIDs
        : [],
    };
    try {
      await FetchCyberRiskUrl(
        "reports/export/pdf/v1",
        opts,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error requesting exec summary pdf export");

      throw e;
    }

    // Now refresh the pending exports
    await dispatch(fetchUserInitiatedExports(true));
  };
};

export const fetchAssessmentCloudscanByHostname = (
  vendorId,
  hostname,
  assessmentId,
  publishDate,
  force
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let vendor = getVendorData(getState, vendorId, false, tpvmSession);

    let { publishedAssessmentScanData } = vendor;
    if (
      !force &&
      publishedAssessmentScanData &&
      publishedAssessmentScanData.assessmentId === assessmentId &&
      publishedAssessmentScanData[hostname] &&
      !publishedAssessmentScanData[hostname].loading &&
      !publishedAssessmentScanData[hostname].error
    ) {
      // We have cached webscan data, don't fetch again
      return;
    }

    publishedAssessmentScanData = {
      assessmentId,
    };
    publishedAssessmentScanData[hostname] = {
      loading: true,
    };

    dispatch(
      setVendorData(
        vendorId,
        {
          publishedAssessmentScanData,
        },
        false,
        tpvmSession
      )
    );

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "cloudscan/details/v2/",
        {
          hostname,
          for_customer: false,
          at: publishDate,
          assessment_id: assessmentId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      publishedAssessmentScanData = {
        assessmentId,
      };
      publishedAssessmentScanData[hostname] = {
        loading: false,
        error: {
          errorText: "Sorry, there was an error fetching this cloudscan.",
          actionText: "Try again",
          actionOnClick: () =>
            dispatch(
              fetchAssessmentCloudscanByHostname(hostname, assessmentId, force)
            ),
        },
      };

      dispatch(
        setVendorData(
          vendorId,
          {
            publishedAssessmentScanData,
          },
          false,
          tpvmSession
        )
      );

      LogError("Error fetching cloudscan by hostname", e);
      return;
    }

    if (!json.result) {
      // Grab the vendorId and vendorName from the response - they're provided as top level keys
      // when there is no cloudscan record.
      const { vendorId, vendorName } = json;

      publishedAssessmentScanData = {
        assessmentId,
      };
      publishedAssessmentScanData[hostname] = {
        loading: false,
        error: {
          errorText: `Sorry, ${hostname} hasn't been scanned yet.`,
        },
      };

      // Ask if the user should trigger a rescan
      dispatch(
        setVendorData(
          vendorId,
          {
            publishedAssessmentScanData,
          },
          false,
          tpvmSession
        )
      );
      return;
    }

    publishedAssessmentScanData = {
      assessmentId,
    };
    publishedAssessmentScanData[hostname] = {
      loading: false,
      result: json.result,
    };

    dispatch(
      setVendorData(
        vendorId,
        {
          publishedAssessmentScanData,
        },
        false,
        tpvmSession
      )
    );
  };
};

export const fetchAllVendorNames = () => {
  return async (dispatch, getState) => {
    dispatch(setAllVendors(true, null, null));

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "watchlist/names/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setAllVendors(false, e, null));
      LogError("Error fetching vendor hostnames with risk", e);
    }

    if (!json) {
      dispatch(setAllVendors(false, { errorText: "no data" }, null));
      return;
    }

    dispatch(setAllVendors(false, null, json));
  };
};

export const fetchAllVendorRisks = () => {
  return async (dispatch, getState) => {
    dispatch(setAllVendorRisks(true, null, null));

    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendorriskprofile/overview/v1/",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(setAllVendorRisks(false, e, null));
      LogError("Error fetching vendor hostnames with risk", e);
    }

    if (!json) {
      dispatch(setAllVendorRisks(false, { errorText: "no data" }, null));
      return;
    }

    dispatch(setAllVendorRisks(false, null, json));
  };
};

export const fetchDataLeakKeywordAndServicesList = (force) => {
  return async (dispatch, getState) => {
    if (!force) {
      const existing = _get(
        getState().cyberRisk,
        `dataLeakScanResults.lookupData`
      );
      if (existing && !existing.error) {
        return;
      }
    }

    dispatch(setDataLeakServicesList(true));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/keywords/v1/",
        {},
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching list of data leaks keywords and services`, e);
      dispatch(
        setDataLeakServicesList(
          false,
          {
            errorText: `Error fetching the set of available keywords for your account.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(fetchDataLeakKeywordAndServicesList(true)),
          },
          null,
          null
        )
      );
      return;
    }
    dispatch(
      setDataLeakServicesList(false, null, json.services, json.keywords)
    );
    return;
  };
};

export const fetchDataLeakStatsForOrg = (force, days) => {
  return async (dispatch, getState) => {
    if (!force) {
      const existing = _get(getState().cyberRisk, `dataLeakStatistics.${days}`);
      if (existing && !existing.error) {
        return;
      }
    }

    dispatch(setDataLeakStatistics(true, days, null, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/stats/v1/",
        {
          days: days,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        `Error fetching data leaks summary statistics for the last ${days} days`,
        e
      );
      dispatch(
        setDataLeakStatistics(
          false,
          days,
          {
            errorText: `Error fetching classified scan results overview for the selected period.`,
            actionText: "Try again",
            actionOnClick: () => dispatch(fetchDataLeakStatsForOrg(true, days)),
          },
          null
        )
      );
      return;
    }

    if (!json) {
      dispatch(
        setDataLeakStatistics(false, days, { errorText: "no data" }, null)
      );
      return;
    }

    dispatch(
      setDataLeakStatistics(false, days, null, {
        scan_counts: json.scan_counts,
        totals: json.totals,
      })
    );
  };
};

export const fetchDataLeakClassifiedResultsForOrg = (force, days, filters) => {
  return async (dispatch, getState) => {
    if (!force) {
      const existing = _get(
        getState().cyberRisk,
        `dataLeakScanResults.${days}`
      );
      if (existing && !existing.error) {
        return;
      }
    }

    const classifications = [];
    if (filters && filters.dataLeaksClassifications) {
      filters.dataLeaksClassifications.forEach(({ value }) => {
        classifications.push(value);
      });
    }
    const services = [];
    if (filters && filters.dataLeaksSources) {
      filters.dataLeaksSources.forEach(({ value }) => {
        services.push(value);
      });
    }
    const keywords = [];
    if (filters && filters.dataLeaksKeywords) {
      filters.dataLeaksKeywords.forEach(({ value }) => {
        keywords.push(value);
      });
    }

    dispatch(setDataLeakClassifiedScanResults(true, days, null, null));
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "cyberresearch/scans/v1/",
        {
          days: days,
          keywords: keywords ? keywords : null,
          classifications: classifications ? classifications : null,
          services: services ? services : null,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(
        `Error fetching summary statistics for the last ${days} days`,
        e
      );
      dispatch(
        setDataLeakClassifiedScanResults(
          false,
          days,
          {
            errorText: `Error fetching classified search results for the selected period.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(setDataLeakClassifiedScanResults(true, days, filters)),
          },
          null
        )
      );
      return;
    }

    if (!json) {
      dispatch(
        setDataLeakClassifiedScanResults(
          false,
          days,
          { errorText: "no data" },
          null
        )
      );
      return;
    }

    dispatch(
      setDataLeakClassifiedScanResults(false, days, null, {
        scans: json.scans,
      })
    );
  };
};

export const setAdditionalEvidenceSelectedTab = (
  vendorId,
  tab,
  tpvmSession
) => {
  return {
    type: SET_EVIDENCE_TAB,
    data: {
      vendorId: vendorId,
      tab: tab,
    },
    isManagementAnalystSession: tpvmSession && tpvmSession.tpvm,
    managedOrgId: tpvmSession ? tpvmSession.tpvm_o : 0,
  };
};

export const deleteAdditionalEvidenceForVendor = (vendorId, evidenceId) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    dispatch(
      setAdditionalEvidenceForVendor(
        true,
        vendorId,
        null,
        null,
        null,
        tpvmSession
      )
    );
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/v1",
        {
          evidence_id: evidenceId,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error deleting additional evidence for vendor`, e);
      dispatch(
        setAdditionalEvidenceForVendor(
          false,
          vendorId,
          {
            errorText: `Error deleting additional evidence.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(deleteAdditionalEvidenceForVendor(vendorId, evidenceId)),
          },
          null,
          null,
          tpvmSession
        )
      );
      throw `Error deleting} additional evidence.`;
      return;
    }

    if (!json) {
      dispatch(
        setAdditionalEvidenceForVendor(
          false,
          vendorId,
          {
            errorText: `Error deleting additional evidence for vendor.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(deleteAdditionalEvidenceForVendor(vendorId, evidenceId)),
          },
          null,
          null,
          tpvmSession
        )
      );
      throw `Error deleting} additional evidence.`;
      return;
    }
    dispatch(
      setAdditionalEvidenceForVendor(
        false,
        vendorId,
        null,
        json.evidence,
        null,
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const deleteDocumentFromAdditionalEvidence = (
  vendorId,
  evidenceId,
  documentId
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/document/v1",
        {
          document_id: documentId,
        },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error deleting document from additional evidence`, e);
      throw e;
    }

    if (!json) {
      throw `Error deleting additional evidence document.`;
    }
    dispatch(
      removeDocumentForDetailedAdditionalEvidence(
        vendorId,
        evidenceId,
        documentId,
        tpvmSession
      )
    );
    dispatch(
      setAdditionalEvidenceForVendor(
        false,
        vendorId,
        null,
        json.evidence,
        null,
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

// refreshEvidenceData silently fetches the data for a specific evidence instance
export const refreshAdditionalEvidenceData = (vendorId, evidenceId) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let vendor = getVendorData(getState, vendorId, false, tpvmSession);
    let existingEvidence = _get(vendor, "evidence.data");
    if (!existingEvidence) {
      return;
    }

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/v1/",
        {
          evidence_id: evidenceId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error retrieving additional evidence instance", e);
    }

    if (!json || json.status !== "OK") {
      return null;
    }

    // update the summary evidence lists
    const refreshedEvidence = json.evidence;
    vendor = getVendorData(getState, vendorId, false, tpvmSession);
    let { active, archived } = _get(vendor, "evidence.data");
    let updated = false;
    if (active != null) {
      active = active.map((evidence) => {
        if (evidence.id === evidenceId) {
          updated = true;
          return refreshedEvidence;
        }
        return evidence;
      });
    }
    if (!updated && archived != null) {
      archived = archived.map((evidence) => {
        if (evidence.id === evidenceId) {
          updated = true;
          return refreshedEvidence;
        }
        return evidence;
      });
    }
    if (updated) {
      const newEvidence = {
        active: active,
        archived: archived,
      };
      dispatch(
        setAdditionalEvidenceForVendor(
          false,
          vendorId,
          null,
          newEvidence,
          null,
          tpvmSession
        )
      );
    }
    return refreshedEvidence;
  };
};

export const fetchDetailedEvidenceData = (
  vendorId,
  evidenceId,
  byAssessmentId,
  force
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let vendor = getVendorData(getState, vendorId, false, tpvmSession);
    if (!force) {
      const existing = _get(vendor, `detailedEvidence[${evidenceId}]`);
      if (
        existing &&
        !existing.error &&
        existing.byAssessmentId === byAssessmentId
      ) {
        return;
      }
    }

    dispatch(
      setDetailedAdditionalEvidenceForVendor(
        true,
        false,
        vendorId,
        evidenceId,
        null,
        null,
        tpvmSession
      )
    );
    let json;
    const options = {
      evidence_id: evidenceId,
    };
    if (byAssessmentId) {
      options.by_assessment_id = byAssessmentId;
    }

    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/detailed/v1/",
        options,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching additional evidence details`, e);
      dispatch(
        setDetailedAdditionalEvidenceForVendor(
          false,
          false,
          vendorId,
          evidenceId,
          {
            errorText: `Error fetching additional evidence details.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(
                fetchDetailedEvidenceData(
                  vendorId,
                  evidenceId,
                  byAssessmentId,
                  true
                )
              ),
          },
          null,
          tpvmSession
        )
      );
      return;
    }

    if (!json) {
      dispatch(
        setDetailedAdditionalEvidenceForVendor(
          false,
          false,
          vendorId,
          evidenceId,
          {
            errorText: `Error fetching additional evidence details.`,
            actionText: "Try again",
            actionOnClick: () =>
              dispatch(
                fetchAdditionalEvidenceForVendor(vendorId, evidenceId, true)
              ),
          },
          null,
          tpvmSession
        )
      );
      return;
    }
    // if we are loading according to a published assessment, store the fact here
    if (byAssessmentId) {
      json.evidence.byAssessmentId = byAssessmentId;
      json.evidence.assessmentPublishedAt = json.assessment.publishedAt;
    }
    // Just set the users on the evidence object for convenience.
    json.evidence.usersById = json.usersById;
    json.evidence.readOnly = json.readOnly;
    json.evidence.isSharedEvidence = json.isSharedEvidence;

    dispatch(
      setDetailedAdditionalEvidenceForVendor(
        false,
        false,
        vendorId,
        evidenceId,
        null,
        json.evidence,
        tpvmSession
      )
    );
  };
};

export const refreshDetailedEvidenceVirusScanData = (vendorId, evidenceId) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let json;
    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/detailed/v1/",
        {
          evidence_id: evidenceId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error fetching additional evidence details`, e);
      return;
    }
    if (!json) {
      return;
    }
    dispatch(
      setVirusScanDataForDetailedAdditionalEvidence(
        vendorId,
        evidenceId,
        json.evidence.documents,
        tpvmSession
      )
    );
  };
};

export const updateAdditionalEvidenceDetails = (
  vendorId,
  evidence,
  documentNames
) => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);
    let json;
    dispatch(
      setDetailedAdditionalEvidenceForVendor(
        false,
        true,
        vendorId,
        evidence.id,
        null,
        null,
        tpvmSession
      )
    );

    // Remove extra fields so they don't get sent to backend
    delete evidence.usersById;
    delete evidence.readOnly;
    delete evidence.isSharedEvidence;

    try {
      json = await FetchCyberRiskUrl(
        "vendor/evidence/v1/",
        {},
        {
          method: "PUT",
          body: JSON.stringify({
            evidence: evidence,
            documentNames: documentNames,
          }),
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError(`Error updating additional evidence details`, e);
      dispatch(
        setDetailedAdditionalEvidenceForVendor(
          false,
          false,
          vendorId,
          evidence.id,
          {
            errorText: "Error updating additional evidence details.",
          },
          null,
          tpvmSession
        )
      );
      throw e;
    }
    if (!json) {
      LogError(`Error updating additional evidence details - empty response`);
      dispatch(
        setDetailedAdditionalEvidenceForVendor(
          false,
          false,
          vendorId,
          evidence.id,
          {
            errorText: "Error updating additional evidence details.",
          },
          null,
          tpvmSession
        )
      );
      return;
    }

    // Set the usersById on the evidence for convenience
    json.evidence.usersById = json.usersById;

    dispatch(
      setDetailedAdditionalEvidenceForVendor(
        false,
        false,
        vendorId,
        evidence.id,
        null,
        json.evidence,
        tpvmSession
      )
    );

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
    // update additional evidence type usage counts
    dispatch(fetchAdditionalEvidenceTypes(true));

    return evidence.id;
  };
};

export const addRiskToAdditionalEvidence = (
  evidenceId,
  riskId,
  riskName,
  riskImpact,
  riskSeverity
) => {
  return async (dispatch, getState) => {
    let opts = { evidence_id: evidenceId };
    if (riskId) {
      opts = { ...opts, risk_id: riskId };
    } else {
      opts = {
        ...opts,
        risk_name: riskName,
        risk_impact: riskImpact,
        risk_severity: riskSeverity,
      };
    }
    try {
      await FetchCyberRiskUrl(
        "vendor/evidence/risk/v1/",
        opts,
        { method: "POST" },
        dispatch,
        getState
      );

      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      if (e.response.status !== 422) {
        LogError("error adding risk to additional evidence", e);
        dispatch(addDefaultUnknownErrorAlert("Error adding risk"));
      } else {
        dispatch(addSimpleErrorAlert(e.message));
      }
      throw e;
    }
  };
};

export const removeRiskFromAdditionalEvidence = (evidenceId, riskId) => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vendor/evidence/risk/v1/",
        { evidence_id: evidenceId, risk_id: riskId },
        { method: "DELETE" },
        dispatch,
        getState
      );

      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      LogError("error removing risk from additional evidence", e);
      throw e;
    }
  };
};

export const fetchAuditTrail = ({
  startDate,
  endDate,
  eventTypes,
  userIds,
  apiKeyIds,
  searchText,
  offset,
  limit,
}) => {
  return async (dispatch, getState) => {
    dispatch(
      setAuditTrailFilters({
        startDate,
        endDate,
        eventTypes,
        userIds,
        apiKeyIds,
        searchText,
        offset,
      })
    );

    const opts = {
      offset,
      limit,
    };

    if (startDate) {
      // Start date should be clamped to the beginning of the specified day
      opts.start_date = moment(startDate, "YYYY-MM-DD")
        .startOf("day")
        .toISOString();
    }
    if (endDate) {
      // End date should be clamped to the end of the specified day
      opts.end_date = moment(endDate, "YYYY-MM-DD")
        .add(1, "day")
        .startOf("day")
        .toISOString();
    }
    if (eventTypes) {
      opts.event_types = eventTypes;
    }
    if (userIds) {
      opts.user_ids = userIds;
    }
    if (apiKeyIds) {
      opts.api_key_ids = apiKeyIds;
    }
    if (searchText) {
      opts.search_text = searchText;
    }

    let result;
    try {
      result = FetchCyberRiskUrl("audit_trail", opts, null, dispatch, getState);
    } catch (e) {
      LogError("error fetching audit trail", e);
      throw e;
    }

    return result;
  };
};

export const fetchAuditTrailEventTypesAndUsers = () => {
  return async (dispatch, getState) => {
    let result;
    try {
      result = FetchCyberRiskUrl(
        "audit_trail/event_types_users",
        null,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching audit trail event types", e);
      throw e;
    }

    return result;
  };
};

// TODO - still used in V2, should be moved to TS
export const runThirdPartyManagedVendorsRequestSearch = async (
  query,
  getState,
  dispatch
) => {
  let results;
  try {
    dispatch(setManagedVendorsRequestSearch({ loading: true, query: query }));
    results = await FetchCyberRiskUrl(
      "managedvendors/search/v1",
      { query: query },
      {
        method: "GET",
      },
      dispatch,
      getState
    );
  } catch (e) {
    LogError("error searching for vendors to manage", e);
    dispatch(
      setManagedVendorsRequestSearch({
        loading: false,
        error: {
          errorText: "Error searching for vendors managed vendors",
        },
      })
    );
    return;
  }

  // Don't update results if the query is stale
  if (
    results &&
    results.status === "OK" &&
    query === getState().cyberRisk.managedVendorRequestSearch.query
  ) {
    dispatch(
      setManagedVendorsRequestSearch({
        loading: false,
        error: null,
        results: results.vendors || [],
        totalMatches: results.totalMatches,
      })
    );
  }
};

const debouncedRunThirdPartyManagedVendorsRequestSearch = _debounce(
  runThirdPartyManagedVendorsRequestSearch,
  500
);

export const fetchThirdPartyManagedVendorsRequestSearch = (query) => {
  return async (dispatch, getState) => {
    dispatch(setManagedVendorsRequestSearch({ loading: true, query: query }));
    debouncedRunThirdPartyManagedVendorsRequestSearch(
      query,
      getState,
      dispatch
    );
  };
};

export const updateVerifiedVendorLogo = (file) => {
  return async (dispatch, getState) => {
    const fetchOpts = { method: "POST" };
    fetchOpts.body = new FormData();
    fetchOpts.body.append("file", file);

    let json;

    try {
      json = await FetchCyberRiskUrl(
        "mysharedassessment/logo/v1/",
        {},
        fetchOpts,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating verified vendor logo", e);

      throw new Error("Error updating verified vendor logo: " + e.message);
    }

    return json.logoUrl;
  };
};

export const deleteVerifiedVendorLogo = () => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "mysharedassessment/logo/v1/",
        {},
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error deleting verified vendor logo", e);

      throw new Error("Error deleting verified vendor logo: " + e.message);
    }
  };
};
