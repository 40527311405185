import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";

import { closeModal } from "../../../_common/reducers/commonActions";
import StripeElements from "../../../_common/components/StripeElements";
import { updateCreditCardDetails } from "../../reducers/cyberRiskActions";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const UpdateCreditCardModalName = "UpdateCreditCardModalName";

class UpdateCreditCardModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const Stripe = require("stripe"); // eslint-disable-line
    this.stripe = Stripe(window.STRIPE_PUBLISHABLE_KEY);
  }

  state = { loading: false };

  onSubmit = async (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });

    let token;
    try {
      token = await this.stripeElements.createToken(this.nameField.value);
    } catch (e) {
      this.props.dispatch(addSimpleErrorAlert(e.message));
      this.setState({ loading: false });
      return;
    }

    this.props
      .dispatch(updateCreditCardDetails(token.id))
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultSuccessAlert(
            "Your card details have been updated successfully"
          )
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  render() {
    return (
      <div className="modal-content">
        <h2>Update your credit card details</h2>
        <p />
        <form onSubmit={this.onSubmit}>
          <label htmlFor="name">Name on card</label>
          <input
            type="text"
            name="name"
            required
            ref={(ref) => (this.nameField = ref)}
          />
          <label htmlFor="elements">Credit card</label>
          <StripeElements
            stripe={this.stripe}
            ref={(ref) => (this.stripeElements = ref)}
          />
          <Button
            type="submit"
            primary
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            Update
          </Button>
        </form>
      </div>
    );
  }
}

export default UpdateCreditCardModal;
