import { isEqual } from "lodash";
import memoize from "memoize-one";
import {
  BreachSightPortfolioSpecificFeatures,
  PortfolioSpecificRole,
  Role,
  RoleFeatures,
  splitPortfolioSpecificRoles,
  VendorPortfolioSpecificFeatures,
} from "../types/roles";
import { NamedRole } from "../../_common/types/namedRole";
import { LabelColor } from "../../_common/types/label";
import { getColorForKey } from "../components/PillLabel";
import * as Permissions from "../../_common/permissions";
import { DefaultRootState } from "react-redux";
import { PortfolioType } from "../reducers/portfolios.actions";
import { sortBy as _sortBy } from "lodash";

const Admin = "RbacAdmin";
const BreachSightReadOnly = "BreachSightReadOnly";
const BreachSightFull = "BreachSightFull";
const BreachSightIdentityBreaches = "BreachSightIdentityBreaches";
const BreachSightTypoSquatting = "BreachSightTypoSquatting";
const BreachSightSharedProfileReadOnly = "BreachSightSharedProfileReadOnly";
const BreachSightSharedProfileSurveyManager =
  "BreachSightSharedProfileSurveyManager";
const BreachSightSharedProfileAccessManager =
  "BreachSightSharedProfileAccessManager";
const VendorRiskReadOnly = "VendorRiskReadOnly";
export const VendorRiskFull = "VendorRiskFull";
const VendorRiskCreateRiskAssessments = "VendorRiskCreateRiskAssessments";
const VendorRiskCreateQuestionnaireBuilder =
  "VendorRiskCreateQuestionnaireBuilder";
const CyberResearchDataLeaks = "CyberResearchDataLeaks";
const CyberResearchManagedVendors = "CyberResearchManagedVendors";

const ContentLibraryReadOnly = "VendorPortalDocRepositoryReadOnly";
const ContentLibraryFull = "VendorPortalDocRepositoryFull";
const SurveyImportExportReadOnly = "VendorPortalSurveyImportExportReadOnly";
const SurveyImportExportFull = "VendorPortalSurveyImportExportFull";
const SurveyShareAnswers = "VendorPortalSurveyShareAnswers";
const UserBaseReadOnly = "UserBaseReadOnly";
const UserBaseFull = "UserBaseFull";
const OrgCollaborator = "OrgCollaborator";

export enum CannedTypes {
  Admin = "admin",
  Standard = "standard",
  ReadOnly = "readonly",
  TrustExchangeStandard = "trustexchangestandard",
  TrustExchangeReadOnly = "trustexchangereadonly",
  // TODO - add collaborator role once dev toggle for survey collaborators is removed
}

// List of canned types that cannot be edited (enforced in backend)
export const NonEditableCannedTypes: ReadonlyArray<CannedTypes> = [
  CannedTypes.Admin,
  CannedTypes.TrustExchangeReadOnly,
];

export const FreeAccountCannedTypes: ReadonlyArray<CannedTypes> = [
  CannedTypes.Admin,
  CannedTypes.TrustExchangeStandard,
  CannedTypes.TrustExchangeReadOnly,
];

export const isAdmin = (roles: string[]): boolean => {
  if (!roles) return false;
  // check for old admin role named first and then the new admin role name
  return roles.includes("Admin") || roles.includes(Admin);
};

export const freeRolesSelector = (state: DefaultRootState) => {
  return [
    state.cyberRisk.orgUsers.freeRoles ?? [],
    state.cyberRisk.orgUsers.entitlementRoles ?? {},
    state.cyberRisk.orgUsers.entitlementRolesMultiple ?? {},
  ] as const;
};

const FilterRolesForEntitlements = (
  roles: string[],
  orgEntitlements: string[],
  entitlementRoles: Record<string, string[]>,
  entitlementRolesMultiple: Record<string, string[]>
): string[] => {
  const rolesToRemove = [] as string[];

  Object.entries(entitlementRoles).forEach(([entitlement, roles]) => {
    if (!orgEntitlements.includes(entitlement)) {
      roles.forEach((r) => {
        if (r in entitlementRolesMultiple) {
          const multiEnt = entitlementRolesMultiple[r];
          if (!multiEnt.every((e) => orgEntitlements.includes(e))) {
            return;
          }
        }
        rolesToRemove.push(r);
      });
    }
  });

  return roles.filter((r) => !rolesToRemove.includes(r));
};

export const namedRoleIsFree = (
  role: NamedRole,
  freeRoles: string[],
  orgEntitlements: string[],
  entitlementRoles: Record<string, string[]>,
  entitlementRolesMultiple: Record<string, string[]>
): boolean => {
  const filteredRoles = FilterRolesForEntitlements(
    role.roles,
    orgEntitlements,
    entitlementRoles,
    entitlementRolesMultiple
  );
  const filteredPortfolioRoles = (role.portfolioSpecificRoles ?? []).map((pr) =>
    FilterRolesForEntitlements(
      pr.r,
      orgEntitlements,
      entitlementRoles,
      entitlementRolesMultiple
    )
  );

  return (
    role.cannedType === CannedTypes.ReadOnly ||
    (filteredRoles.every((r) => freeRoles.includes(r)) &&
      filteredPortfolioRoles.every((psr) =>
        psr.every((rr) => freeRoles.includes(rr))
      ))
  );
};

export const roleIsFreeRole = (
  r: Role,
  freeRoles: string[],
  orgEntitlements: string[],
  entitlementRoles: Record<string, string[]>,
  entitlementRolesMultiple: Record<string, string[]>
) => {
  const roleList = roleToRoleList(r);
  const filteredRoles = FilterRolesForEntitlements(
    roleList.roles,
    orgEntitlements,
    entitlementRoles,
    entitlementRolesMultiple
  );
  const filteredPortfolioRoles = roleList.portfolioSpecificRoles.map((pr) =>
    FilterRolesForEntitlements(
      pr.r,
      orgEntitlements,
      entitlementRoles,
      entitlementRolesMultiple
    )
  );
  return (
    filteredRoles.every((rr) => freeRoles.includes(rr)) &&
    filteredPortfolioRoles.every((psr) =>
      psr.every((rr) => freeRoles.includes(rr))
    )
  );
};

const allFeaturesDisabled: RoleFeatures = {
  breachsightEnabled: false,
  breachsightPortfolioSpecificAccess: false,
  breachsightPortfolioSpecificFeatures: {},
  breachsightCoreFeaturesFullAccess: false,
  breachsightIdentityEnabled: false,
  breachsightTyposquattingEnabled: false,
  breachsightSharedProfileEnabled: false,
  breachsightSharedProfileAccessManagementEnabled: false,
  breachsightSharedProfileSurveyManagementEnabled: false,
  contentLibraryEnabled: false,
  contentLibraryWrite: false,
  surveyImportExportEnabled: false,
  surveyImportExportWrite: false,
  surveyShareAnswersWithOrg: false,
  vendorRiskEnabled: false,
  vendorRiskPortfolioSpecificAccess: false,
  vendorRiskPortfolioSpecificFeatures: {},
  vendorRiskCoreFeaturesFullAccess: false,
  vendorRiskCreateAssessmentEnabled: false,
  vendorRiskQuestionnaireBuilderEnabled: false,
  cyberResearchEnabled: false,
  cyberResearchDataLeaksEnabled: false,
  cyberResearchManagedVendorsEnabled: false,
  userBaseEnabled: false,
  userBaseCoreFeaturesFullAccess: false,
  collaborationSurveys: false,
};

export const emptyRole: Role = {
  isAdmin: false,
  features: allFeaturesDisabled,
};

export const newDefaultRole = (
  showBreachsight: boolean,
  showSharedProfile: boolean,
  showVendorRisk: boolean
): Role => {
  const role = { isAdmin: false, features: { ...allFeaturesDisabled } };
  if (showBreachsight) {
    role.features.breachsightEnabled = true;
    role.features.breachsightCoreFeaturesFullAccess = true;
  }
  if (showSharedProfile) {
    role.features.breachsightSharedProfileEnabled = true;
    role.features.breachsightSharedProfileAccessManagementEnabled = true;
    role.features.breachsightSharedProfileSurveyManagementEnabled = true;
  }
  if (showVendorRisk) {
    role.features.vendorRiskEnabled = true;
    role.features.vendorRiskCoreFeaturesFullAccess = true;
  }

  role.features.contentLibraryEnabled = true;
  role.features.contentLibraryWrite = true;
  role.features.surveyImportExportEnabled = true;
  role.features.surveyImportExportWrite = true;
  role.features.surveyShareAnswersWithOrg = true;

  return role;
};

// provides a default role but with only options for free user accounts enabled
export const newFreeDefaultRole = (
  showBreachsight: boolean,
  showSharedProfile: boolean,
  showVendorRisk: boolean
): Role => {
  const role = { isAdmin: false, features: { ...allFeaturesDisabled } };
  if (showBreachsight) {
    role.features.breachsightEnabled = true;
    role.features.breachsightCoreFeaturesFullAccess = false;
  }
  if (showSharedProfile) {
    role.features.breachsightSharedProfileEnabled = true;
    role.features.breachsightSharedProfileAccessManagementEnabled = true;
    role.features.breachsightSharedProfileSurveyManagementEnabled = true;
  }
  if (showVendorRisk) {
    role.features.vendorRiskEnabled = true;
    role.features.vendorRiskCoreFeaturesFullAccess = false;
  }

  role.features.contentLibraryEnabled = true;
  role.features.contentLibraryWrite = true;
  role.features.surveyImportExportEnabled = true;
  role.features.surveyImportExportWrite = true;
  role.features.surveyShareAnswersWithOrg = true;

  return role;
};

export const newAdminDefaultRole = (): Role => {
  const role = { isAdmin: true, features: { ...allFeaturesDisabled } };
  role.features.breachsightEnabled = true;
  role.features.breachsightCoreFeaturesFullAccess = false;

  role.features.breachsightSharedProfileEnabled = true;
  role.features.breachsightSharedProfileAccessManagementEnabled = true;
  role.features.breachsightSharedProfileSurveyManagementEnabled = true;

  role.features.vendorRiskEnabled = true;
  role.features.vendorRiskCoreFeaturesFullAccess = false;

  role.features.contentLibraryEnabled = true;
  role.features.contentLibraryWrite = true;
  role.features.surveyImportExportEnabled = true;
  role.features.surveyImportExportWrite = true;
  role.features.surveyShareAnswersWithOrg = true;

  return role;
};

export const isEmptyRole = (role: Role): boolean => isEqual(role, emptyRole);

// convert a list of roles coming from the backend to a Role object
export const roleListToRole = (
  roles: string[],
  allPortfolioSpecificRoles?: PortfolioSpecificRole[]
): Role => {
  const includesAnyOf = (elementsToBeContained: string[]): boolean =>
    elementsToBeContained.some((e) => roles.includes(e));

  const { vendorPortfolioSpecificRoles, domainPortfolioSpecificRoles } =
    splitPortfolioSpecificRoles(allPortfolioSpecificRoles ?? []);

  return {
    isAdmin: includesAnyOf([Admin]),
    features: {
      breachsightEnabled:
        domainPortfolioSpecificRoles.length > 0 ||
        includesAnyOf([
          BreachSightReadOnly,
          BreachSightFull,
          BreachSightIdentityBreaches,
          BreachSightTypoSquatting,
        ]),
      breachsightPortfolioSpecificAccess:
        domainPortfolioSpecificRoles.length > 0,
      breachsightPortfolioSpecificFeatures: domainPortfolioSpecificRoles.reduce(
        (
          prev: Record<number, BreachSightPortfolioSpecificFeatures>,
          portfolioRole
        ) => {
          prev[portfolioRole.p] = {
            breachsightCoreFeaturesFullAccess:
              portfolioRole.r.indexOf(BreachSightFull) > -1,
          };

          return prev;
        },
        {}
      ),
      breachsightCoreFeaturesFullAccess: includesAnyOf([BreachSightFull]),
      breachsightIdentityEnabled: includesAnyOf([BreachSightIdentityBreaches]),
      breachsightTyposquattingEnabled: includesAnyOf([
        BreachSightTypoSquatting,
      ]),
      breachsightSharedProfileEnabled: includesAnyOf([
        BreachSightSharedProfileReadOnly,
        BreachSightSharedProfileSurveyManager,
        BreachSightSharedProfileAccessManager,
      ]),
      breachsightSharedProfileSurveyManagementEnabled: includesAnyOf([
        BreachSightSharedProfileSurveyManager,
      ]),
      breachsightSharedProfileAccessManagementEnabled: includesAnyOf([
        BreachSightSharedProfileAccessManager,
      ]),
      contentLibraryEnabled: includesAnyOf([
        BreachSightSharedProfileSurveyManager,
        ContentLibraryReadOnly,
        ContentLibraryFull,
      ]),
      contentLibraryWrite: includesAnyOf([
        BreachSightSharedProfileSurveyManager,
        ContentLibraryFull,
      ]),
      surveyImportExportEnabled: includesAnyOf([
        SurveyImportExportReadOnly,
        SurveyImportExportFull,
      ]),
      surveyImportExportWrite: includesAnyOf([SurveyImportExportFull]),
      surveyShareAnswersWithOrg: includesAnyOf([SurveyShareAnswers]),
      vendorRiskEnabled:
        vendorPortfolioSpecificRoles.length > 0 ||
        includesAnyOf([
          VendorRiskReadOnly,
          VendorRiskFull,
          VendorRiskCreateRiskAssessments,
          VendorRiskCreateQuestionnaireBuilder,
        ]),
      vendorRiskPortfolioSpecificAccess:
        vendorPortfolioSpecificRoles.length > 0,
      vendorRiskPortfolioSpecificFeatures: vendorPortfolioSpecificRoles.reduce(
        (
          prev: Record<number, VendorPortfolioSpecificFeatures>,
          portfolioRole
        ) => {
          prev[portfolioRole.p] = {
            vendorRiskCoreFeaturesFullAccess:
              portfolioRole.r.indexOf(VendorRiskFull) > -1,
            vendorRiskCreateAssessmentEnabled:
              portfolioRole.r.indexOf(VendorRiskCreateRiskAssessments) > -1,
          };

          return prev;
        },
        {}
      ),
      vendorRiskCoreFeaturesFullAccess: includesAnyOf([VendorRiskFull]),
      vendorRiskCreateAssessmentEnabled: includesAnyOf([
        VendorRiskCreateRiskAssessments,
      ]),
      vendorRiskQuestionnaireBuilderEnabled: includesAnyOf([
        VendorRiskCreateQuestionnaireBuilder,
      ]),
      cyberResearchEnabled: includesAnyOf([
        CyberResearchDataLeaks,
        CyberResearchManagedVendors,
      ]),
      cyberResearchDataLeaksEnabled: includesAnyOf([CyberResearchDataLeaks]),
      cyberResearchManagedVendorsEnabled: includesAnyOf([
        CyberResearchManagedVendors,
      ]),
      userBaseEnabled: includesAnyOf([UserBaseReadOnly, UserBaseFull]),
      userBaseCoreFeaturesFullAccess: includesAnyOf([UserBaseFull]),
      collaborationSurveys: includesAnyOf([OrgCollaborator]),
    },
  };
};

// convert a Role object to a list of roles and portfolio-specific roles for the backend
export const roleToRoleList = (role: Role) => {
  const roles: string[] = [];
  const portfolioSpecificRoles: PortfolioSpecificRole[] = [];

  if (role.isAdmin) roles.push(Admin);

  if (role.features.breachsightEnabled) {
    if (role.features.breachsightPortfolioSpecificAccess) {
      portfolioSpecificRoles.push(
        ...Object.entries(
          role.features.breachsightPortfolioSpecificFeatures
        ).map(([portfolioIdStr, features]) => {
          const portfolioRoles = [];
          if (features.breachsightCoreFeaturesFullAccess) {
            portfolioRoles.push(BreachSightFull);
          } else {
            portfolioRoles.push(BreachSightReadOnly);
          }

          return {
            p: parseInt(portfolioIdStr),
            t: PortfolioType.Domain,
            r: portfolioRoles,
          };
        })
      );
    } else {
      roles.push(BreachSightReadOnly);

      if (role.features.breachsightCoreFeaturesFullAccess)
        roles.push(BreachSightFull);
    }

    if (role.features.breachsightIdentityEnabled)
      roles.push(BreachSightIdentityBreaches);
    if (role.features.breachsightTyposquattingEnabled)
      roles.push(BreachSightTypoSquatting);

    if (role.features.cyberResearchDataLeaksEnabled) {
      roles.push(CyberResearchDataLeaks);
    }
  }

  if (role.features.breachsightSharedProfileEnabled) {
    roles.push(BreachSightSharedProfileReadOnly);

    if (role.features.breachsightSharedProfileSurveyManagementEnabled)
      roles.push(BreachSightSharedProfileSurveyManager);
    if (role.features.breachsightSharedProfileAccessManagementEnabled)
      roles.push(BreachSightSharedProfileAccessManager);
  }

  if (role.features.contentLibraryEnabled) {
    roles.push(ContentLibraryReadOnly);

    if (role.features.contentLibraryWrite) {
      roles.push(ContentLibraryFull);
    }
  }

  if (role.features.surveyImportExportEnabled) {
    roles.push(SurveyImportExportReadOnly);

    if (role.features.surveyImportExportWrite) {
      roles.push(SurveyImportExportFull);
    }
  }

  if (role.features.surveyShareAnswersWithOrg) {
    roles.push(SurveyShareAnswers);
  }

  if (role.features.vendorRiskEnabled) {
    if (role.features.vendorRiskPortfolioSpecificAccess) {
      portfolioSpecificRoles.push(
        ...Object.entries(
          role.features.vendorRiskPortfolioSpecificFeatures
        ).map(([portfolioIdStr, features]) => {
          const portfolioRoles = [];
          if (features.vendorRiskCoreFeaturesFullAccess) {
            portfolioRoles.push(VendorRiskFull);
          } else {
            portfolioRoles.push(VendorRiskReadOnly);
          }

          if (features.vendorRiskCreateAssessmentEnabled) {
            portfolioRoles.push(VendorRiskCreateRiskAssessments);
          }

          return {
            p: parseInt(portfolioIdStr),
            t: PortfolioType.Vendor,
            r: portfolioRoles,
          };
        })
      );
    } else {
      roles.push(VendorRiskReadOnly);
      if (role.features.vendorRiskCoreFeaturesFullAccess)
        roles.push(VendorRiskFull);
      if (role.features.vendorRiskCreateAssessmentEnabled)
        roles.push(VendorRiskCreateRiskAssessments);

      if (role.features.cyberResearchManagedVendorsEnabled) {
        roles.push(CyberResearchManagedVendors);
      }
    }
    if (role.features.vendorRiskQuestionnaireBuilderEnabled)
      roles.push(VendorRiskCreateQuestionnaireBuilder);
  }

  if (role.features.userBaseEnabled) {
    roles.push(UserBaseReadOnly);
    if (role.features.userBaseCoreFeaturesFullAccess) roles.push(UserBaseFull);
  }

  return { roles, portfolioSpecificRoles };
};

export const getColorForRole = (namedRole: NamedRole): LabelColor =>
  getColorForKey(namedRole.name);

export const mergeFeatures = (
  oldFeatures: RoleFeatures,
  newValues: {
    [key: string]:
      | boolean
      | Record<number, VendorPortfolioSpecificFeatures>
      | Record<number, BreachSightPortfolioSpecificFeatures>;
  }
): RoleFeatures => {
  const mergedFeatures: RoleFeatures = {
    ...oldFeatures,
    ...newValues,
  };

  const breachsightPortfolioSpecificAccess =
    mergedFeatures.breachsightEnabled &&
    mergedFeatures.breachsightPortfolioSpecificAccess;

  const features: RoleFeatures = {
    breachsightEnabled: mergedFeatures.breachsightEnabled,
    breachsightCoreFeaturesFullAccess:
      mergedFeatures.breachsightEnabled &&
      mergedFeatures.breachsightCoreFeaturesFullAccess,
    breachsightPortfolioSpecificAccess: breachsightPortfolioSpecificAccess,
    breachsightPortfolioSpecificFeatures: mergedFeatures.breachsightEnabled
      ? mergedFeatures.breachsightPortfolioSpecificFeatures
      : {},
    breachsightIdentityEnabled:
      mergedFeatures.breachsightEnabled &&
      mergedFeatures.breachsightIdentityEnabled,
    breachsightTyposquattingEnabled:
      mergedFeatures.breachsightEnabled &&
      mergedFeatures.breachsightTyposquattingEnabled,
    breachsightSharedProfileEnabled:
      mergedFeatures.breachsightSharedProfileEnabled,
    breachsightSharedProfileSurveyManagementEnabled:
      !breachsightPortfolioSpecificAccess &&
      mergedFeatures.breachsightSharedProfileEnabled &&
      mergedFeatures.breachsightSharedProfileSurveyManagementEnabled,
    breachsightSharedProfileAccessManagementEnabled:
      !breachsightPortfolioSpecificAccess &&
      mergedFeatures.breachsightSharedProfileEnabled &&
      mergedFeatures.breachsightSharedProfileAccessManagementEnabled,

    contentLibraryEnabled: mergedFeatures.contentLibraryEnabled,
    contentLibraryWrite:
      mergedFeatures.contentLibraryEnabled &&
      mergedFeatures.contentLibraryWrite,

    surveyImportExportEnabled: mergedFeatures.surveyImportExportEnabled,
    surveyImportExportWrite:
      mergedFeatures.surveyImportExportEnabled &&
      mergedFeatures.surveyImportExportWrite,

    surveyShareAnswersWithOrg: mergedFeatures.surveyShareAnswersWithOrg,

    vendorRiskEnabled: mergedFeatures.vendorRiskEnabled,
    vendorRiskPortfolioSpecificAccess:
      mergedFeatures.vendorRiskEnabled &&
      mergedFeatures.vendorRiskPortfolioSpecificAccess,
    vendorRiskPortfolioSpecificFeatures: mergedFeatures.vendorRiskEnabled
      ? mergedFeatures.vendorRiskPortfolioSpecificFeatures
      : {},
    vendorRiskCoreFeaturesFullAccess:
      mergedFeatures.vendorRiskEnabled &&
      !mergedFeatures.vendorRiskPortfolioSpecificAccess &&
      mergedFeatures.vendorRiskCoreFeaturesFullAccess,
    vendorRiskCreateAssessmentEnabled:
      mergedFeatures.vendorRiskEnabled &&
      !mergedFeatures.vendorRiskPortfolioSpecificAccess &&
      mergedFeatures.vendorRiskCreateAssessmentEnabled,
    vendorRiskQuestionnaireBuilderEnabled:
      mergedFeatures.vendorRiskEnabled &&
      mergedFeatures.vendorRiskQuestionnaireBuilderEnabled,
    cyberResearchEnabled: mergedFeatures.cyberResearchEnabled,
    cyberResearchDataLeaksEnabled:
      mergedFeatures.breachsightEnabled &&
      mergedFeatures.cyberResearchDataLeaksEnabled,
    cyberResearchManagedVendorsEnabled:
      mergedFeatures.vendorRiskEnabled &&
      mergedFeatures.cyberResearchManagedVendorsEnabled,
    userBaseEnabled: mergedFeatures.userBaseEnabled,
    userBaseCoreFeaturesFullAccess:
      mergedFeatures.userBaseEnabled &&
      mergedFeatures.userBaseCoreFeaturesFullAccess,
    collaborationSurveys: mergedFeatures.collaborationSurveys,
  };

  // Ensure content library write permissions for anyone with breachsightSharedProfileSurveyManagement
  if (features.breachsightSharedProfileSurveyManagementEnabled) {
    features.contentLibraryEnabled = true;
    features.contentLibraryWrite = true;
  }

  return features;
};

export const getShownPermissionSectionsFromOrgPermissions = memoize(
  (orgPermissions: Record<string, boolean>) => {
    const hasPermission = (perm: string) => !!orgPermissions[perm];

    const showCustomerInfo = hasPermission(Permissions.OrgAccessCustomerInfo);
    const showVulnerabilities =
      hasPermission(Permissions.OrgAccessCustomerInfo) &&
      hasPermission(Permissions.OrgAccessVulns);
    const showIdentityBreaches = hasPermission(
      Permissions.OrgAccessEmailExposures
    );
    const showTypoSquatting = hasPermission(Permissions.OrgAccessTyposquatting);
    const showBreachsight =
      showCustomerInfo ||
      showVulnerabilities ||
      showIdentityBreaches ||
      showTypoSquatting;

    const showSurveys = hasPermission(Permissions.OrgAccessSurveys);
    const showVendors = hasPermission(Permissions.OrgAccessVendors);
    const showQuestionnaireBuilder = hasPermission(
      Permissions.OrgAccessQuestionnaireBuilder
    );
    const showSharedWithMe = !hasPermission(Permissions.OrgHideVendorRisks);
    const showConcentratedRisk = hasPermission(
      Permissions.OrgAccessVendorTechnologies
    );
    const showCreateAssessment = hasPermission(
      Permissions.OrgAccessVendorAssessments
    );
    const showVendorRisk = showVendors || showSurveys;

    const showDataLeaks =
      hasPermission(Permissions.OrgAccessBreachSight) ||
      hasPermission(Permissions.OrgAccessVendorDataLeaks);
    const showManageVendors = hasPermission(
      Permissions.OrgAccessVendorsUnderManagement
    );
    const showCyberResearch = showDataLeaks || showManageVendors;

    const showDomainPortfolioOptions = hasPermission(
      Permissions.OrgAccessDomainPortfolios
    );
    const showVendorPortfolioOptions = hasPermission(
      Permissions.OrgAccessVendorPortfolios
    );

    const showUserBase = hasPermission(Permissions.OrgAccessUserBase);

    const showCollaboration = hasPermission(
      Permissions.OrgAccessSurveyInternalCollaborators
    );

    return {
      showCustomerInfo,
      showVulnerabilities,
      showIdentityBreaches,
      showTypoSquatting,
      showBreachsight,
      showSurveys,
      showVendors,
      showQuestionnaireBuilder,
      showSharedWithMe,
      showConcentratedRisk,
      showCreateAssessment,
      showVendorRisk,
      showCyberResearch,
      showDomainPortfolioOptions,
      showVendorPortfolioOptions,
      showDataLeaks,
      showManageVendors,
      showUserBase,
      showCollaboration,
    };
  }
);

export const sortNamedRoles = (roles: NamedRole[]): NamedRole[] =>
  _sortBy(
    roles,
    // Always show Admin first
    (namedRole) => namedRole.cannedType !== CannedTypes.Admin,
    // Then any canned roles
    (namedRole) => !namedRole.cannedType,
    // Then sort alphabetically
    (namedRole) => namedRole.name.toLowerCase()
  );
