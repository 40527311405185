import { History } from "history";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { RiskProfileRiskTypes } from "../../_common/constants";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { factCategories, severityMap } from "../../_common/helpers";
import PillLabel from "./PillLabel";
import RiskSummaryDetails, { IRisk, IRiskSurvey } from "./RiskSummaryDetails";
import ReportCard from "../../_common/components/ReportCard";
import Button from "../../_common/components/core/Button";
import Icon from "../../_common/components/core/Icon";
import classnames from "classnames";
import moment from "moment/moment";
import "../style/components/RiskSummaryTable.scss";
import { VendorSummaryData } from "../../_common/types/redux";
import { columnSortDef, useSorting } from "../../_common/hooks";
import KnownExploitedVulnPill from "./KnownExploitedVulnPill";
import { IStringKeyedVerifiedCount } from "../../_common/types/kevs";
import { IRiskHostnames } from "../../_common/types/risks";
import {
  RiskClassificationGroup,
  RiskClassificationString,
} from "../../_common/types/risk/classification";
import {
  IVendorRiskWaiver,
  WaiverType,
} from "../../_common/types/vendorRiskWaivers";
import { PublicRiskWaiver } from "../types/sharedAssessment";
import { OrganisationAcceptedRisk } from "../reducers/customerAcceptedRisks.actions";

import SearchBox from "../../_common/components/SearchBox";
import ToggleWithLabel from "./ToggleWithLabel";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import RiskName from "./risk_profile/RiskName";
import {
  calculateWaivedAssetsForRisks,
  isFullyCoveredByAdjustments,
  isFullyCoveredByWaivers,
  WaivedAssets,
  WaivedRiskAssetsMap,
} from "../reducers/risks.actions";
import RiskStatusCounts from "./risk_profile/RiskStatusCounts";
import {
  factCategoryMeta,
  newFactCategoryMeta,
} from "../../_common/factCategoryHelpers";
import { RemediationRequestWithMeta } from "../../_common/types/remediation";
import { useRemediationProgress } from "../../_common/components/remediationDetails/helpers";
import {
  RemediationRequestTableV2Mode,
  statusToPill,
} from "./RemediationRequestTableV2";
import IconButton, { HoverLocation } from "../../_common/components/IconButton";
import { SeverityAsString, SeverityInt } from "../../_common/types/severity";
import { AdjustedSeverityIcon } from "../../_common/components/SeverityIcon";
import RiskSummaryComment from "./RiskSummaryComment";
import MultiSelectionButton, {
  IOption,
  IOptionGroupConfig,
} from "../../_common/components/MultiSelectionButton";
import { cloneDeep as _cloneDeep } from "lodash";
import TabButtons, { tabButton } from "../../_common/components/TabButtons";
import CircledIcon from "../../_common/components/CircledIcon";
import { VendorAssessmentKeyRisk } from "../types/vendorAssessments";
import { PopupPosition } from "../../_common/components/DismissablePopup";
import ManageRiskButton from "./risk_profile/ManageRiskButton";
import {
  hasOrgPermission,
  OrgAccessAdditionalEvidenceRiskRemediation,
  OrgNewRiskClassifications,
  usePermissions,
} from "../../_common/permissions";
import { appConnect } from "../../_common/types/reduxHooks";
import { LabelColor } from "../../_common/types/label";
import { getRepoManifestLocation } from "../../appguard/api/manifestLocation.helper";

const emptyObj = {};
const emptyArr: never[] = [];

export const enum RiskTableRiskFilter {
  Comments = "comments",
  SeverityCritical = "severityCritical",
  SeverityHigh = "severityHigh",
  SeverityMedium = "severityMedium",
  SeverityLow = "severityLow",
  StatusInRemediation = "inRemediation",
  StatusWaived = "waived",
  StatusWaiverPending = "waiverPending",
  CategoryWebsiteSecurity = "website",
  CategoryNetworkSecurity = "networkSecurity",
  CategoryBrandAndReputationRisk = "brandAndReputation",
  CategoryEmailSecurity = "emailSecurity",
  CategoryPhishingAndMalware = "phishingAndMalware",
  CategoryAttackSurface = "attackSurface",
  CategoryEncryption = "encryption",
  CategoryDNS = "dns",
  CategoryVulnerabilityManagement = "vulnerabilityManagement",
  CategoryQuestionnaireRisk = "questionnaireRisk",
  CategoryAdditionalEvidenceRisk = "additionalEvidenceRisk",
  CategoryDataLeakage = "dataLeakage",
}

// Match filters to factCategories so we can generate some dynamic filters later
const FactCategoryRiskFilterMap: Record<string, RiskTableRiskFilter> = {
  [factCategories.WebsiteSec]: RiskTableRiskFilter.CategoryWebsiteSecurity,
  [factCategories.Website]: RiskTableRiskFilter.CategoryWebsiteSecurity,
  [factCategories.NetworkSec]: RiskTableRiskFilter.CategoryNetworkSecurity,
  [factCategories.Network]: RiskTableRiskFilter.CategoryNetworkSecurity,
  [factCategories.BrandProtect]:
    RiskTableRiskFilter.CategoryBrandAndReputationRisk,
  [factCategories.BrandReputation]:
    RiskTableRiskFilter.CategoryBrandAndReputationRisk,
  [factCategories.EmailSec]: RiskTableRiskFilter.CategoryEmailSecurity,
  [factCategories.Email]: RiskTableRiskFilter.CategoryEmailSecurity,
  [factCategories.Phishing]: RiskTableRiskFilter.CategoryPhishingAndMalware,
  [factCategories.IPDomainReputation]:
    RiskTableRiskFilter.CategoryPhishingAndMalware,
  [factCategories.DataLeakage]: RiskTableRiskFilter.CategoryDataLeakage,
  [factCategories.DNS]: RiskTableRiskFilter.CategoryDNS,
  [factCategories.AttackSurface]: RiskTableRiskFilter.CategoryAttackSurface,
  [factCategories.VulnerabilityManagement]:
    RiskTableRiskFilter.CategoryVulnerabilityManagement,
  [factCategories.Encryption]: RiskTableRiskFilter.CategoryEncryption,
  [factCategories.QuestionnaireRisks]:
    RiskTableRiskFilter.CategoryQuestionnaireRisk,
  [factCategories.AdditionalEvidenceRisks]:
    RiskTableRiskFilter.CategoryAdditionalEvidenceRisk,
};

interface IRiskFilter {
  option: IOption;
  optionGroup: number;
}

interface IRiskFilterFunctions {
  [optionValue: string]: (risk: IRisk) => boolean;
}

interface IRiskFilters {
  [optionValue: string]: IRiskFilter;
}

const calculateOccurred = (
  risk: IRisk,
  changesMode: boolean,
  includeWaived: boolean,
  waivedAssets?: WaivedAssets
): number => {
  if (changesMode) {
    return risk.cloudscanDiffs ? risk.cloudscanDiffs.length : 0;
  }
  if (
    !risk.passed &&
    (risk.riskType === RiskProfileRiskTypes.Survey ||
      risk.id === "questionnaires_incomplete")
  ) {
    return (risk.surveys?.filter((s) => includeWaived || !s.isWaived) || [])
      .length;
  }
  if (!risk.passed && risk.riskType === RiskProfileRiskTypes.Cloudscan) {
    if (risk.failedCloudscans) {
      // We have the full domain info so we need to work out which of the current domains have been waived
      let numFailed = 0;

      risk.failedCloudscans.forEach((w) => {
        if (
          includeWaived ||
          waivedAssets?.assetsWaived[w.hostname] === undefined
        ) {
          numFailed = numFailed + 1;
        }
      });

      return numFailed;
    } else {
      // We don't have the full domain info so just return numFailed (best effort)
      return risk.numFailedCloudscans;
    }
  } else if (!risk.passed && risk.riskType === RiskProfileRiskTypes.Evidence) {
    return (risk.additionalEvidences || []).length;
  } else if (!risk.passed && risk.riskType === RiskProfileRiskTypes.SaaS) {
    return (
      risk.saasUsers?.filter(
        (u) => includeWaived || waivedAssets?.assetsWaived[u.uuid] === undefined
      ) || []
    ).length;
  } else if (
    !risk.passed &&
    risk.riskType === RiskProfileRiskTypes.AppguardPackageVuln
  ) {
    return (
      risk.manifests?.filter(
        (m) =>
          includeWaived ||
          waivedAssets?.assetsWaived[
            `${getRepoManifestLocation(m.repoName, m.manifestLocation)}`
          ] === undefined
      ) || []
    ).length;
  } else if (
    !risk.passed &&
    risk.riskType === RiskProfileRiskTypes.AppguardRepoConfig
  ) {
    return (
      risk.repositories?.filter(
        (r) =>
          includeWaived || waivedAssets?.assetsWaived[r.repoName] === undefined
      ) || []
    ).length;
  }
  return -1;
};

const calculateNumInRemediation = (
  risk: IRisk,
  cloudscansInRemediation:
    | VendorSummaryData["cloudscansInRemediation"]
    | undefined,
  additionalEvidenceInRemediation:
    | VendorSummaryData["additionalEvidenceInRemediation"]
    | undefined,
  waivedAssets: WaivedAssets | undefined,
  showWaived: boolean
) => {
  if (
    risk.riskType === RiskProfileRiskTypes.Survey ||
    risk.id === "questionnaires_incomplete"
  ) {
    return (risk.surveys || []).reduce((sum, c) => {
      if (c.inRemediation) {
        return sum + 1;
      }
      return sum;
    }, 0);
  } else if (risk.riskType === RiskProfileRiskTypes.Cloudscan) {
    const remediation = cloudscansInRemediation?.[risk.id];
    if (remediation?.isAllWebsites) {
      return calculateOccurred(risk, false, showWaived, waivedAssets);
    }
    const sitesInRemediation = remediation?.websites ?? emptyObj;

    const allSites = Object.keys(sitesInRemediation);
    let sites: string[] = [];

    if (waivedAssets && !showWaived) {
      // Work out which ones remain under remediation after taking into account waivers
      allSites.forEach((s) => {
        if (!waivedAssets.assetsWaived[s]) {
          sites.push(s);
        }
      });
    } else {
      sites = allSites;
    }

    return sites.length;
  } else if (risk.riskType === RiskProfileRiskTypes.Evidence) {
    return additionalEvidenceInRemediation?.[risk.baseId ?? risk.id]
      ? risk.additionalEvidences?.length ?? 0
      : 0;
  }
  return -1;
};

type tableColumnId = "severity" | "finding" | "category" | "detected" | "sites";

const useTableSortingFuncs = (
  changesMode: boolean,
  includeWaived: boolean,
  waivedAssetsMap: WaivedRiskAssetsMap
) =>
  useMemo<Record<tableColumnId, columnSortDef<IRisk>>>(
    () => ({
      severity: {
        orderFuncs: [
          (r) => (!r.passed ? r.severity : -1),
          (r) =>
            calculateOccurred(
              r,
              changesMode,
              changesMode ? false : includeWaived,
              waivedAssetsMap[r.id]
            ),
        ],
        sortDirsAsc: ["asc", "asc"],
        sortDirsDesc: ["desc", "desc"],
      },
      finding: {
        orderFuncs: [
          (r) => r.title,
          (r) => (r.passed ? "" : r.categoryTitle ? r.categoryTitle : ""),
          (r) => r.passed,
          (r) => -r.severity,
          (r) =>
            newFactCategoryMeta[r.factCategory]
              ? newFactCategoryMeta[r.factCategory].name
              : factCategoryMeta[r.factCategory]
                ? factCategoryMeta[r.factCategory].name
                : "",
        ],
        sortDirsAsc: ["asc", "asc", "asc", "asc", "asc"],
        sortDirsDesc: ["desc", "asc", "asc", "asc", "asc"],
      },
      detected: {
        orderFuncs: [
          (r) => r.dateDetected,
          (r) => r.passed,
          (r) => -r.severity,
          (r) => r.title,
        ],
        sortDirsAsc: ["asc", "asc", "asc", "asc"],
        sortDirsDesc: ["desc", "asc", "asc", "asc"],
      },
      category: {
        orderFuncs: [
          (r) =>
            newFactCategoryMeta[r.factCategory]
              ? newFactCategoryMeta[r.factCategory].name
              : factCategoryMeta[r.factCategory]
                ? factCategoryMeta[r.factCategory].name
                : "",
          (r) => r.passed,
          (r) => -r.severity,
          (r) => r.categoryTitle,
          (r) => r.title,
        ],
        sortDirsAsc: ["asc", "asc", "asc", "asc", "asc"],
        sortDirsDesc: ["desc", "asc", "asc", "asc", "asc"],
      },
      sites: {
        orderFuncs: [
          (r) =>
            calculateOccurred(
              r,
              changesMode,
              changesMode ? false : includeWaived,
              waivedAssetsMap[r.id]
            ),
          (r) => r.passed,
          (r) => -r.severity,
          (r) =>
            newFactCategoryMeta[r.factCategory]
              ? newFactCategoryMeta[r.factCategory].name
              : factCategoryMeta[r.factCategory]
                ? factCategoryMeta[r.factCategory].name
                : "",
          (r) => r.categoryTitle,
          (r) => r.title,
        ],
        sortDirsAsc: ["asc", "asc", "asc", "asc", "asc", "asc"],
        sortDirsDesc: ["desc", "asc", "asc", "asc", "asc", "asc"],
      },
    }),
    [changesMode, includeWaived]
  );

interface IRiskSummaryTableOwnProps {
  history: History;
  loading?: boolean;
  vendorId?: number;
  isSubsidiary?: boolean;
  forSpecificAssessment?: number;
  forPublishedAssessment?: boolean;
  title: string;
  risks: IRisk[];
  riskKEVCounts?: IStringKeyedVerifiedCount;
  filterByCategory?: string;
  filterActive: boolean;
  onClearFilter?: () => void;
  cloudscansInRemediation?: VendorSummaryData["cloudscansInRemediation"];
  additionalEvidenceInRemediation?: VendorSummaryData["additionalEvidenceInRemediation"];
  onOpenCloudscanPanel?: (hostname: string, riskId: string) => void;
  onToggleCloudscanDiffPanel?: (
    hostname: string,
    startDate: number,
    endDate: number,
    openInitialId: string
  ) => void;
  onOpenCVEPanel?: (vuln: any, verified: any) => void;
  onOpenRiskPanel?: (riskId: string) => void;
  onRequestRemediationForRisk?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean
  ) => void;
  onMarkRiskAsRemediated?: (risk: IRisk) => void;
  onCreateRiskWaiver?: (
    risk: IRisk,
    hostname?: string,
    surveyId?: number,
    isPublicSurvey?: boolean,
    waiverType?: WaiverType
  ) => void;
  onRequestRemediation?: () => void;
  onSetComplianceFramework?: () => void;
  onWaiveRisks?: () => void;
  changesMode?: boolean;
  changesStartDate?: number;
  changesEndDate?: number;
  urlPrefix: () => string;
  isManagedAssessment?: boolean;
  includePassedRisks?: boolean;
  setIncludePassedRisks?: (includePassedRisks: boolean) => void;
  isPassive?: boolean; // isPassive indicates that the vendor is not monitored and should not show any features from monitoring (risk waiver etc)
  readOnly?: boolean; // readOnly indicates that the vendor is monitored and we should show statuses but NOT any actions (ie, show risk waiver status but not button)
  canManageVendorRiskWaivers: boolean;
  userHasWriteRiskWaiversPermission?: boolean;
  editableDomainPortfolioIds?: number[];
  userHasWriteRemediationPermission?: boolean;
  initialOpenRisks?: Record<string, true | undefined>;
  onToggleRisk?: (riskId: string | number) => void;
  vendorRiskWaivers?: IVendorRiskWaiver[];
  vendorPublicRiskWaivers?: PublicRiskWaiver[];
  customerRiskWaivers?: OrganisationAcceptedRisk[];
  isRisksNowScored?: boolean;
  hideShowWaivedRisksOption?: boolean;
  hideShowPassedChecksOption?: boolean;
  showWaivedRisks?: boolean;
  onShowWaivedRisks?: (val: boolean) => void;
  hideFilters?: boolean;
  noDetectedDates?: boolean;
  remediationRequest?: RemediationRequestWithMeta;
  showRemediationSummary?: boolean;
  backToText?: string;
  hideColumnHeaders?: boolean;
  riskComments?: Record<string, string>;
  onUpdateComment?: (riskID: string, val: string) => void;
  showComponentFilters?: boolean;
  excludedFilters?: Set<RiskTableRiskFilter>;
  displayKeyRiskSelection?: boolean;
  displayKeyRiskTabByDefault?: boolean;
  enableKeyRiskSelection?: boolean;
  keyRisks?: VendorAssessmentKeyRisk[]; // key risks on a vendor assessment
  onToggleKeyRisk?: (risk: VendorAssessmentKeyRisk, selected: boolean) => void; // callback for toggling key risk status
  hideWaivedOrAdjustedStatus?: boolean; // If waived/adjusted risks are shown, and hideWaivedOrAdjustedStatus is true, the risk will appear as normal, but without remediate/waive actions and no annotations
  onAdjustRisks?: () => void;
  showRiskClassification?: boolean;
  showRiskAdjustmentJustification?: boolean;
  hideRepositoryColumn?: boolean;
  expandable?: boolean;
  className?: string;
  usePrevCategory?: boolean;
  orgAccessNewRiskDesigns?: boolean;
}

interface IRiskSummaryTableConnectedProps {
  customerRiskHostnames: IRiskHostnames;
}

type IRiskSummaryTableProps = IRiskSummaryTableOwnProps &
  IRiskSummaryTableConnectedProps;

const RiskSummaryTable: FC<IRiskSummaryTableProps> = ({
  history,
  loading = false,
  vendorId,
  isSubsidiary = false,
  forSpecificAssessment,
  forPublishedAssessment,
  title,
  risks: risksProp,
  riskKEVCounts = undefined,
  filterByCategory = "",
  filterActive,
  onClearFilter,
  cloudscansInRemediation = {},
  additionalEvidenceInRemediation = {},
  onOpenCloudscanPanel,
  onToggleCloudscanDiffPanel,
  onOpenCVEPanel,
  onOpenRiskPanel,
  onRequestRemediationForRisk,
  onMarkRiskAsRemediated,
  onCreateRiskWaiver,
  onRequestRemediation,
  onWaiveRisks,
  onSetComplianceFramework,
  changesMode,
  changesStartDate,
  changesEndDate,
  urlPrefix,
  isManagedAssessment = false,
  includePassedRisks = false,
  setIncludePassedRisks,
  isPassive = false,
  readOnly = false,
  canManageVendorRiskWaivers,
  userHasWriteRiskWaiversPermission = false,
  editableDomainPortfolioIds,
  userHasWriteRemediationPermission = false,
  customerRiskHostnames,
  initialOpenRisks,
  onToggleRisk,
  vendorRiskWaivers,
  vendorPublicRiskWaivers,
  customerRiskWaivers,
  isRisksNowScored,
  hideShowWaivedRisksOption,
  hideShowPassedChecksOption,
  showWaivedRisks = false,
  onShowWaivedRisks,
  hideFilters = false,
  noDetectedDates = false,
  remediationRequest,
  showRemediationSummary,
  backToText,
  hideColumnHeaders = false,
  riskComments,
  onUpdateComment,
  showComponentFilters, // Show component level filter controls.  Has no effect if hideFilters = true
  excludedFilters,
  displayKeyRiskSelection,
  displayKeyRiskTabByDefault,
  enableKeyRiskSelection,
  keyRisks,
  onToggleKeyRisk,
  hideWaivedOrAdjustedStatus,
  onAdjustRisks,
  showRiskClassification,
  showRiskAdjustmentJustification,
  hideRepositoryColumn,
  expandable = true,
  className,
  usePrevCategory,
  orgAccessNewRiskDesigns,
}) => {
  const allRisksTabId = "allRisks";
  const keyRisksTabId = "keyRisks";

  const perms = usePermissions();
  const orgAccessAdditionalEvidenceRiskRemediation = hasOrgPermission(
    perms.permissions,
    OrgAccessAdditionalEvidenceRiskRemediation
  );
  const orgNewRiskClassification = hasOrgPermission(
    perms.permissions,
    OrgNewRiskClassifications
  );

  const keyRiskCount = useMemo(() => {
    return (
      keyRisks?.filter((key) => {
        // Lookup risk in props
        return risksProp.some((r) => {
          return (
            r.id === key.riskID && (!r.isWaived || hideWaivedOrAdjustedStatus)
          );
        });
      }).length ?? 0
    );
  }, [risksProp, keyRisks, hideWaivedOrAdjustedStatus]);

  // Show key risks icons if the props is set, and key risks are editable OR we have at least one valid selected key risk
  const shouldDisplayKeyRisksSelection =
    displayKeyRiskSelection && (enableKeyRiskSelection || keyRiskCount > 0);

  const [openRows, setOpenRows] = useState<Record<string, true | undefined>>(
    initialOpenRisks ?? {}
  );
  const [searchVal, setSearchVal] = useState("");
  const [totalPassedRisks, setTotalPassedRisks] = useState(0);
  const [activeRiskDisplayTab, setActiveRiskDisplayTab] = useState(
    displayKeyRiskTabByDefault && keyRiskCount > 0
      ? keyRisksTabId
      : allRisksTabId
  );

  useEffect(() => {
    setActiveRiskDisplayTab(
      displayKeyRiskTabByDefault && keyRiskCount > 0
        ? keyRisksTabId
        : allRisksTabId
    );
  }, [displayKeyRiskTabByDefault]);

  const toggleRow = useCallback(
    (rowId: string | number) => {
      if (onToggleRisk) {
        onToggleRisk(rowId);
      }
      setOpenRows((openRows) => {
        const newOpenRows = { ...openRows };
        if (newOpenRows[rowId]) {
          delete newOpenRows[rowId];
        } else {
          newOpenRows[rowId] = true;
        }
        return newOpenRows;
      });
    },
    [onToggleRisk]
  );

  const clickRow = useCallback(
    (riskId: string) => {
      if (onOpenRiskPanel) {
        onOpenRiskPanel(riskId);
      }
    },
    [onOpenRiskPanel]
  );

  const onQuestionnaireClicked = useCallback(
    (survey: IRiskSurvey) => {
      if (!vendorId || isSubsidiary) {
        // Weird - these risks should only be shown on vendor pages.
        return;
      }

      const byAssessmentSuffix =
        forSpecificAssessment && forPublishedAssessment
          ? `/byassessment/${forSpecificAssessment}`
          : "";
      if (survey.publicSurvey) {
        // If this is a public survey, just go to this vendor's Shared Profile page.
        history.push(
          `${urlPrefix()}/sharedassessment/surveys/${
            survey.surveyId
          }${byAssessmentSuffix}`,
          {
            backContext: {
              goBack: true,
              backToText: backToText ?? "Back to Risk Profile",
            },
          }
        );
      } else {
        // Otherwise, go to the survey details page.
        history.push(
          `${urlPrefix()}/surveys/${survey.surveyId}${byAssessmentSuffix}`,
          {
            backContext: {
              goBack: true,
              backToText: backToText ?? "Back to Risk Profile",
            },
          }
        );
      }
    },
    [vendorId, isSubsidiary, urlPrefix, history]
  );

  const getCustomerRiskHostnames = useCallback(
    (riskId: string) => {
      const riskHostnames = customerRiskHostnames[riskId];
      if (riskHostnames && !riskHostnames.loading && riskHostnames.data) {
        return riskHostnames.data.hostnamesWithRisk || emptyArr;
      }
      return undefined;
    },
    [customerRiskHostnames]
  );

  const getRiskDisplayTabs = useMemo(() => {
    const tabs: tabButton[] = [
      {
        id: allRisksTabId,
        text: `All risks (${
          risksProp.filter((r) =>
            hideWaivedOrAdjustedStatus ? true : !r.isWaived
          ).length
        })`,
      },
    ];

    if (enableKeyRiskSelection || keyRiskCount > 0) {
      tabs.push({
        id: keyRisksTabId,
        text: `Key risks (${keyRiskCount})`,
        popup:
          "Select key risks to highlight in your final assessment. You can also choose to create a Risk Assessment Report using key risks only.",
        popupPosition: "top" as PopupPosition,
        popupDelay: 600,
        popupNoWrap: false,
        popupWidth: 240,
      });
    }
    return tabs;
  }, [risksProp, keyRisks, keyRiskCount]);

  const waivedRiskAssetsMap = useMemo(
    () =>
      calculateWaivedAssetsForRisks(
        risksProp,
        !vendorId && !isSubsidiary,
        !hideWaivedOrAdjustedStatus ? vendorRiskWaivers : undefined,
        !hideWaivedOrAdjustedStatus ? vendorPublicRiskWaivers : undefined,
        !hideWaivedOrAdjustedStatus ? customerRiskWaivers : undefined
      ),
    [
      risksProp,
      vendorId,
      isSubsidiary,
      vendorRiskWaivers,
      vendorPublicRiskWaivers,
      customerRiskWaivers,
      hideWaivedOrAdjustedStatus,
    ]
  );

  const columnHeaders = useMemo(
    () => [
      ...(shouldDisplayKeyRisksSelection
        ? [
            {
              id: "keyRisk",
              text: "",
              sortable: false,
              className: "key-risk-cell",
            },
          ]
        : []),
      {
        id: "severity",
        text: "Sev.",
        sortable: true,
        className: "severity-col",
        startingSortDir: SortDirection.DESC,
      },
      {
        id: "finding",
        text: "Finding / Risk",
        sortable: true,
        className: "finding-col",
        startingSortDir: SortDirection.ASC,
      },
      {
        id: "category",
        text: "Category",
        sortable: true,
        className: "category-col",
        startingSortDir: SortDirection.ASC,
      },
      ...(!changesMode && !noDetectedDates
        ? [
            {
              id: "detected",
              text: "First detected",
              sortable: true,
              className: "detected-cell",
              startingSortDir: SortDirection.ASC,
            },
          ]
        : []),
      {
        id: "sites",
        text: changesMode
          ? "Changes"
          : orgAccessNewRiskDesigns
            ? "Assets affected"
            : "Occurrences",
        sortable: !changesMode,
        className: "sites-col",
        startingSortDir: SortDirection.ASC,
      },
      ...(!changesMode && !isPassive
        ? [
            {
              id: "status",
              text: "Status",
              className: "status-col",
            },
            {
              id: "action",
              text: "",
              className: "actions-col",
            },
          ]
        : []),
    ],
    [changesMode, displayKeyRiskSelection]
  );

  const riskFilterFunctions: IRiskFilterFunctions = useMemo(() => {
    const filterFunctions: IRiskFilterFunctions = {};

    filterFunctions[RiskTableRiskFilter.Comments] = (risk: IRisk) => {
      return !!riskComments?.[risk.id];
    };

    //Get the necessary fact categories
    Object.keys(newFactCategoryMeta).forEach((category) => {
      if (
        category !== factCategories.Vulns &&
        category !== factCategories.DataLeaks &&
        category !== factCategories.EmailExposures &&
        category !== ""
      ) {
        const filter = FactCategoryRiskFilterMap[category];

        filterFunctions[filter] = (risk: IRisk) => {
          return usePrevCategory
            ? risk.prevFactCategory == category
            : risk.factCategory == category;
        };
      }
    });

    // Add severities
    filterFunctions[RiskTableRiskFilter.SeverityCritical] = (risk: IRisk) => {
      return risk.severity === SeverityInt.CriticalSeverity;
    };

    filterFunctions[RiskTableRiskFilter.SeverityHigh] = (risk: IRisk) => {
      return risk.severity === SeverityInt.HighSeverity;
    };

    filterFunctions[RiskTableRiskFilter.SeverityMedium] = (risk: IRisk) => {
      return risk.severity === SeverityInt.MediumSeverity;
    };

    filterFunctions[RiskTableRiskFilter.SeverityLow] = (risk: IRisk) => {
      return risk.severity === SeverityInt.LowSeverity;
    };

    // Add statuses
    filterFunctions[RiskTableRiskFilter.StatusInRemediation] = (
      risk: IRisk
    ) => {
      return (
        calculateNumInRemediation(
          risk,
          cloudscansInRemediation,
          additionalEvidenceInRemediation,
          waivedRiskAssetsMap[risk.id],
          showWaivedRisks
        ) > 0
      );
    };

    filterFunctions[RiskTableRiskFilter.StatusWaived] = (risk: IRisk) => {
      return risk.isWaived ?? false;
    };

    filterFunctions[RiskTableRiskFilter.StatusWaiverPending] = (
      risk: IRisk
    ) => {
      const waivedAssets = waivedRiskAssetsMap[risk.id];
      return (
        Object.keys(waivedAssets.assetsPendingWaiver).length +
          Object.keys(waivedAssets.assetsPendingSharedWaiver).length >
        0
      );
    };

    return filterFunctions;
  }, [riskComments]);

  // Generate the filter group configuration to provide to the filter button
  const initialiseRiskFilterGroups: IOptionGroupConfig[] = useMemo(() => {
    const groups: IOptionGroupConfig[] = [];

    // Comments
    groups.push({ column: 1 });

    // Category
    groups.push({ column: 1, heading: "CATEGORY" });

    // Severity
    groups.push({ column: 2, heading: "SEVERITY" });

    // Status
    groups.push({ column: 2, heading: "STATUS" });

    return groups;
  }, []);

  // Generate the filter options to provide to the filter button
  const initialRiskFilters: IRiskFilters = useMemo(() => {
    const filters: IRiskFilters = {};

    if (!excludedFilters?.has(RiskTableRiskFilter.Comments)) {
      filters[RiskTableRiskFilter.Comments] = {
        option: {
          label: "Contains Comments",
          value: RiskTableRiskFilter.Comments,
          selected: false,
        },
        optionGroup: 1,
      };
    }

    //Get the necessary fact categories
    Object.keys(newFactCategoryMeta).forEach((category) => {
      if (
        category !== factCategories.Vulns &&
        category !== factCategories.DataLeaks &&
        category !== factCategories.EmailExposures &&
        category !== ""
      ) {
        const categoryMeta = newFactCategoryMeta[category];
        const filter = FactCategoryRiskFilterMap[category];

        if (!excludedFilters?.has(filter)) {
          filters[filter] = {
            option: {
              label: categoryMeta.name,
              value: filter,
              selected: false,
            },
            optionGroup: 2,
          };
        }
      }
    });

    // Add severities
    if (!excludedFilters?.has(RiskTableRiskFilter.SeverityCritical)) {
      filters[RiskTableRiskFilter.SeverityCritical] = {
        option: {
          label: "Critical Risk",
          value: RiskTableRiskFilter.SeverityCritical,
          selected: false,
        },
        optionGroup: 3,
      };
    }

    if (!excludedFilters?.has(RiskTableRiskFilter.SeverityHigh)) {
      filters[RiskTableRiskFilter.SeverityHigh] = {
        option: {
          label: "High Risk",
          value: RiskTableRiskFilter.SeverityHigh,
          selected: false,
        },
        optionGroup: 3,
      };
    }

    if (!excludedFilters?.has(RiskTableRiskFilter.SeverityMedium)) {
      filters[RiskTableRiskFilter.SeverityMedium] = {
        option: {
          label: "Medium Risk",
          value: RiskTableRiskFilter.SeverityMedium,
          selected: false,
        },
        optionGroup: 3,
      };
    }

    if (!excludedFilters?.has(RiskTableRiskFilter.SeverityLow)) {
      filters[RiskTableRiskFilter.SeverityLow] = {
        option: {
          label: "Low Risk",
          value: RiskTableRiskFilter.SeverityLow,
          selected: false,
        },
        optionGroup: 3,
      };
    }

    if (!excludedFilters?.has(RiskTableRiskFilter.StatusInRemediation)) {
      // Add statuses
      filters[RiskTableRiskFilter.StatusInRemediation] = {
        option: {
          label: "In Remediation",
          value: RiskTableRiskFilter.StatusInRemediation,
          selected: false,
        },
        optionGroup: 4,
      };
    }

    if (!excludedFilters?.has(RiskTableRiskFilter.StatusWaived)) {
      filters[RiskTableRiskFilter.StatusWaived] = {
        option: {
          label: "Waived",
          value: RiskTableRiskFilter.StatusWaived,
          selected: false,
          disabled: !showWaivedRisks,
          disabledText: !showWaivedRisks
            ? "Waived risks are not shown in the table"
            : undefined,
        },
        optionGroup: 4,
      };
    }

    if (!excludedFilters?.has(RiskTableRiskFilter.StatusWaiverPending)) {
      filters[RiskTableRiskFilter.StatusWaiverPending] = {
        option: {
          label: "Waiver Pending",
          value: RiskTableRiskFilter.StatusWaiverPending,
          selected: false,
        },
        optionGroup: 4,
      };
    }

    return filters;
  }, [riskComments, hideShowWaivedRisksOption]);

  const riskFilterOptions: (filters: IRiskFilter[]) => IOption[][] = (
    filters: IRiskFilter[]
  ) => {
    const options: IOption[][] = [];
    let currentGroupId = -1;
    filters.forEach((filter) => {
      if (filter.optionGroup !== currentGroupId) {
        options.push([]);
        currentGroupId = filter.optionGroup;
      }

      options[options.length - 1].push(filter.option);
    });

    return options;
  };

  const [selectedRiskFilters, setSelectedRiskFilters] = useState<IRiskFilters>(
    _cloneDeep(initialRiskFilters)
  );

  const componentFiltersActive = Object.values(selectedRiskFilters).some(
    (f) => f.option.selected
  );

  // First sort our risks based on the sort state.
  const filteredRisks = useMemo(() => {
    let totalPassedRisks = 0;
    const filtered = risksProp.filter((r) => {
      if (
        filterByCategory &&
        r.factCategory !== filterByCategory &&
        r.prevFactCategory !== filterByCategory
      ) {
        return false;
      }

      // temporarily remove the display of pass checks for surveys (until data migration is fixed)
      if (r.passed && r.title === "") {
        // don't show passed Qn checks with no name (passed checks from custom Qns)
        return false;
      }

      if (!showWaivedRisks && r.isWaived) {
        return false;
      }

      // Are we showing key risks only?
      if (activeRiskDisplayTab === keyRisksTabId) {
        // Does the current risk+severity combo appear in the keyRisks set?
        if (
          !keyRisks?.some((risk) => {
            return r.id === risk.riskID && r.severity === risk.severity;
          })
        ) {
          return false;
        }
      }

      const searchValLower = searchVal.toLocaleLowerCase();
      if (
        searchVal.length > 0 &&
        r.title.toLocaleLowerCase().indexOf(searchValLower) === -1 &&
        r.categoryTitle.toLocaleLowerCase().indexOf(searchValLower) === -1
      ) {
        const meta =
          newFactCategoryMeta[r.factCategory] ??
          factCategoryMeta[r.factCategory];
        if (
          !meta ||
          meta.name.toLocaleLowerCase().indexOf(searchValLower) === -1
        ) {
          return false;
        }
      }

      if (changesMode) {
        return true;
      }

      // Check the component level filters
      const groupFilterResults: Record<number, boolean> = {};

      // Run selected filters - a filter group passes if any selected filter in the group passes
      Object.values(selectedRiskFilters)
        .filter((filter) => filter.option.selected)
        .forEach((filter) => {
          // Get the current result for this filter group
          const groupResult = groupFilterResults[filter.optionGroup];
          if (!groupResult) {
            // We haven't already passed this group, so run this filter and set the group result
            const filterFunc = riskFilterFunctions[filter.option.value];
            if (filterFunc) {
              groupFilterResults[filter.optionGroup] = filterFunc(r);
            }
          }
        });

      // Pass the risk if there were no selected filters, or all selected filter groups passed
      if (
        Object.keys(groupFilterResults).length > 0 &&
        !Object.values(groupFilterResults).every((value: boolean) => value)
      ) {
        return false;
      }

      totalPassedRisks += r.passed ? 1 : 0;
      return includePassedRisks || !r.passed;
    });
    setTotalPassedRisks(totalPassedRisks);
    return filtered;
  }, [
    changesMode,
    filterByCategory,
    showWaivedRisks,
    includePassedRisks,
    risksProp,
    searchVal,
    selectedRiskFilters,
    activeRiskDisplayTab,
    keyRisks,
  ]);

  const tableSortingFuncs = useTableSortingFuncs(
    changesMode ?? false,
    showWaivedRisks,
    waivedRiskAssetsMap
  );
  const [sortedRisks, sortedBy, onSortChange] = useSorting<
    IRisk,
    tableColumnId
  >(filteredRisks, "severity", SortDirection.DESC, tableSortingFuncs);

  const [remediationProgress, remediationTotal, remediationResolved] =
    useRemediationProgress(remediationRequest);

  const rows: IXTableRow[] = !loading
    ? sortedRisks.map((r) => {
        let waivedAssets: WaivedAssets = {
          assetsWaived: {},
          assetsPendingWaiver: {},
          assetsPendingSharedWaiver: {},
          assetsAdjusted: {},
          assetsPendingAdjustment: {},
        };
        if (waivedRiskAssetsMap && waivedRiskAssetsMap[r.id]) {
          waivedAssets = waivedRiskAssetsMap[r.id];
        }

        const kevCount =
          riskKEVCounts && !r.passed ? riskKEVCounts[r.id] : undefined;

        const numFailed = calculateOccurred(
          r,
          changesMode ?? false,
          changesMode ? false : showWaivedRisks,
          waivedAssets
        );

        const numInRemediation = calculateNumInRemediation(
          r,
          cloudscansInRemediation,
          additionalEvidenceInRemediation,
          waivedAssets,
          showWaivedRisks
        );

        const riskAsKeyRisk: VendorAssessmentKeyRisk = {
          riskID: r.id,
          severity: r.severity,
        };

        const selected = keyRisks?.some((risk) => {
          return (
            (r.id === risk.riskID && r.severity === risk.severity) ?? false
          );
        });

        const rowId = `${r.id}_${r.passed}`;
        const isExpanded = !!openRows[rowId];

        // If we are showing risk classifications (instead of categories), look up the values
        let showClassification =
          showRiskClassification &&
          orgNewRiskClassification &&
          isManagedAssessment;

        const catName =
          usePrevCategory && r.prevFactCategory
            ? factCategoryMeta[r.prevFactCategory].name
            : newFactCategoryMeta[r.factCategory].name ??
              factCategoryMeta[r.factCategory].name;

        const classificationGroup = RiskClassificationString(
          RiskClassificationGroup(r.classification),
          catName,
          showClassification ?? false
        );

        const classification = RiskClassificationString(
          r.classification,
          catName,
          showClassification ?? false
        );

        // Don't show Group and Classification if they are the same
        if (classificationGroup == classification) {
          showClassification = false;
        }

        const cells = [
          ...(shouldDisplayKeyRisksSelection
            ? [
                [
                  <XTableCell key="keyRisk" className="key-risk-cell">
                    {(enableKeyRiskSelection || selected) && (
                      <IconButton
                        icon={
                          <span
                            className={
                              selected
                                ? "cr-icon-shortlist-active"
                                : "cr-icon-shortlist"
                            }
                          />
                        }
                        onClick={
                          enableKeyRiskSelection
                            ? () => {
                                onToggleKeyRisk?.(riskAsKeyRisk, !selected);
                              }
                            : undefined
                        }
                        disabled={!enableKeyRiskSelection}
                        hoverText={
                          enableKeyRiskSelection
                            ? selected
                              ? "Remove from key risks"
                              : "Add to key risks"
                            : "Appears in key risks"
                        }
                        hoverMicro={true}
                        hoverLocation={HoverLocation.Top}
                      />
                    )}
                  </XTableCell>,
                ],
              ]
            : []),
          <XTableCell key="severity" className="severity-cell">
            {r.passed ? (
              severityMap.pass.icon
            ) : (
              <AdjustedSeverityIcon
                severity={SeverityAsString(r.severity)}
                baseSeverity={
                  r.baseSeverity ? SeverityAsString(r.baseSeverity) : undefined
                }
              />
            )}
          </XTableCell>,
          <XTableCell className={"finding-cell"} key="finding">
            <div className={"finding"}>
              <RiskName
                riskName={r.title}
                riskCategoryGroupName={
                  !r.passed || r.factCategory === factCategories.SaaSRisks
                    ? r.categoryTitle
                    : undefined
                }
                isWaived={r.isWaived && !hideWaivedOrAdjustedStatus}
              />
              {kevCount && kevCount.count > 0 && !kevCount.isVerified && (
                <KnownExploitedVulnPill count={kevCount.count} />
              )}
              {kevCount && kevCount.count > 0 && kevCount.isVerified && (
                <KnownExploitedVulnPill />
              )}
            </div>
          </XTableCell>,
          <XTableCell key="category" className="category-cell">
            {newFactCategoryMeta[r.factCategory] ? (
              <div className="category-cell-labels">
                {showClassification && (
                  <div>
                    <PillLabel color={LabelColor.Grey}>
                      {classificationGroup}
                    </PillLabel>
                  </div>
                )}
                <div>
                  <PillLabel
                    color={
                      showRiskClassification
                        ? LabelColor.Grey
                        : newFactCategoryMeta[r.factCategory]
                          ? newFactCategoryMeta[r.factCategory].pillColor
                          : factCategoryMeta[r.factCategory].pillColor
                    }
                  >
                    {classification}
                  </PillLabel>
                </div>
              </div>
            ) : null}
          </XTableCell>,
          ...(!changesMode && !noDetectedDates
            ? [
                <XTableCell
                  key="detected"
                  className={classnames("detected-cell", {
                    waived: r.isWaived && !hideWaivedOrAdjustedStatus,
                  })}
                >
                  {!r.passed && r.dateDetected
                    ? moment(r.dateDetected).format("D MMM, YYYY")
                    : ""}
                </XTableCell>,
              ]
            : []),
          <XTableCell key="sites" className="sites-cell">
            <div
              className={classnames("sites-cell-inner", {
                waived: r.isWaived && !hideWaivedOrAdjustedStatus,
              })}
            >
              {changesMode ? (
                <>
                  {r.cloudscanDiffs && r.cloudscanDiffs.length > 0
                    ? `${r.cloudscanDiffs.length} ${
                        r.cloudscanDiffs.length === 1 ? "risk" : "risks"
                      } ${
                        r.passed
                          ? "resolved"
                          : isRisksNowScored
                            ? "now scored"
                            : "introduced"
                      }`
                    : `1 risk ${
                        r.passed
                          ? "resolved"
                          : isRisksNowScored
                            ? "now scored"
                            : "introduced"
                      }`}
                </>
              ) : (
                <>
                  {(r.riskType === RiskProfileRiskTypes.Survey ||
                    r.id === "questionnaires_incomplete") &&
                    !r.passed && <>{numFailed}</>}
                  {(r.riskType === RiskProfileRiskTypes.Survey ||
                    r.id === "questionnaires_incomplete") &&
                    r.passed && <>-</>}
                  {r.riskType === RiskProfileRiskTypes.Cloudscan &&
                    !r.passed && <>{numFailed}</>}
                  {r.riskType === RiskProfileRiskTypes.Cloudscan &&
                    r.passed && <>-</>}
                  {r.riskType === RiskProfileRiskTypes.Evidence && (
                    <>{numFailed}</>
                  )}
                  {r.riskType === RiskProfileRiskTypes.SaaS && !r.passed && (
                    <>{numFailed}</>
                  )}
                  {r.riskType === RiskProfileRiskTypes.SaaS && r.passed && (
                    <>-</>
                  )}
                  {r.riskType === RiskProfileRiskTypes.AppguardPackageVuln && (
                    <>{numFailed}</>
                  )}
                  {r.riskType === RiskProfileRiskTypes.AppguardRepoConfig && (
                    <>{!r.passed ? numFailed : "-"}</>
                  )}
                </>
              )}
            </div>
          </XTableCell>,
        ];
        if (!changesMode && !isPassive) {
          cells.push(
            <XTableCell key="status">
              {r.passed ? (
                "-"
              ) : (
                <div className={"status-cell-content"}>
                  <RiskStatusCounts
                    numInRemediation={numInRemediation}
                    numWaived={Object.keys(waivedAssets.assetsWaived).length}
                    isAllWaived={r?.isWaived && !hideWaivedOrAdjustedStatus}
                    numPendingWaived={
                      Object.keys(waivedAssets.assetsPendingWaiver).length +
                      Object.keys(waivedAssets.assetsPendingSharedWaiver).length
                    }
                    numAdjusted={
                      Object.keys(waivedAssets.assetsAdjusted).length
                    }
                    numPendingAdjusted={
                      Object.keys(waivedAssets.assetsPendingAdjustment).length
                    }
                    hideWaived={!showWaivedRisks}
                  />
                </div>
              )}
            </XTableCell>
          );

          // Work out what risk actions are possible for risk row

          let isCoveredByWaivers = false;
          let isCoveredByAdjustments = false;
          if (waivedAssets) {
            isCoveredByWaivers = isFullyCoveredByWaivers(r, waivedAssets);
            isCoveredByAdjustments = isFullyCoveredByAdjustments(
              r,
              waivedAssets
            );
          }

          const canRemediateFunc = () => {
            if (numInRemediation === numFailed) {
              return false;
            }
            if (
              r.riskType === RiskProfileRiskTypes.Survey ||
              r.id === "questionnaires_incomplete"
            ) {
              return r.surveys?.some((s) => !s.inRemediation) ?? false;
            } else if (r.riskType === RiskProfileRiskTypes.Cloudscan) {
              return true;
            } else if (r.riskType === RiskProfileRiskTypes.Evidence) {
              return true;
            } else if (
              r.riskType === RiskProfileRiskTypes.AppguardPackageVuln
            ) {
              return true;
            } else if (r.riskType === RiskProfileRiskTypes.AppguardRepoConfig) {
              return true;
            }

            return false;
          };

          const canRemediate = canRemediateFunc();

          const canCreateRemediation =
            onRequestRemediationForRisk &&
            !isPassive &&
            !readOnly &&
            !r.isWaived &&
            (r.numFailedCloudscans > 0 ||
              (r.surveys && !r.passed) ||
              (r.repositories && !r.passed) ||
              (r.manifests && !r.passed) ||
              (r.additionalEvidences &&
                !r.passed &&
                canRemediate &&
                orgAccessAdditionalEvidenceRiskRemediation));

          const createRemediation = onRequestRemediationForRisk
            ? () => onRequestRemediationForRisk(r, undefined, undefined)
            : undefined;

          const canCreateAdjustment =
            !isPassive &&
            !readOnly &&
            !r.passed &&
            r.riskType !== RiskProfileRiskTypes.Cloudscan &&
            r.riskType !== RiskProfileRiskTypes.SaaS &&
            !r.baseSeverity;

          const createAdjustment = onCreateRiskWaiver
            ? () =>
                onCreateRiskWaiver(
                  r,
                  undefined,
                  undefined,
                  undefined,
                  WaiverType.SeverityAdjustment
                )
            : undefined;

          const canCreateWaiver =
            onCreateRiskWaiver &&
            !isPassive &&
            !readOnly &&
            !r.isWaived &&
            (r.numFailedCloudscans > 0 ||
              (r.surveys && !r.passed) ||
              (r.repositories && !r.passed) ||
              (r.manifests && !r.passed) ||
              (r.saasUsers && !r.passed) ||
              r.riskType === "evidence") &&
            canManageVendorRiskWaivers &&
            (userHasWriteRiskWaiversPermission ||
              !!editableDomainPortfolioIds?.length);

          const createWaiver = onCreateRiskWaiver
            ? () =>
                onCreateRiskWaiver(
                  r,
                  undefined,
                  undefined,
                  undefined,
                  WaiverType.RiskWaiver
                )
            : undefined;

          const canMarkAsRemediated =
            onMarkRiskAsRemediated &&
            !isPassive &&
            !readOnly &&
            !r.isWaived &&
            r.additionalEvidences &&
            !r.passed &&
            !canRemediate &&
            orgAccessAdditionalEvidenceRiskRemediation;

          const markAsRemediated = onMarkRiskAsRemediated
            ? () => onMarkRiskAsRemediated(r)
            : undefined;

          if (orgAccessNewRiskDesigns) {
            cells.push(
              <XTableCell key={"chevron"} className={"chevron-cell"}>
                <Icon name="chevron" direction={90} />
              </XTableCell>
            );
          } else {
            cells.push(
              <XTableCell
                key={"action"}
                className={"shrink-cell"}
                showContentOnlyOnHover
              >
                <ManageRiskButton
                  autoCloseOnMouseLeave
                  hideRemediate={!canCreateRemediation || !canRemediate}
                  hideAdjust={
                    !canCreateAdjustment ||
                    isCoveredByWaivers ||
                    isCoveredByAdjustments
                  }
                  hideWaive={!canCreateWaiver || isCoveredByWaivers}
                  hideMarkAsRemediated={!canMarkAsRemediated}
                  onRemediate={createRemediation}
                  onAdjust={createAdjustment}
                  onWaive={createWaiver}
                  onMarkAsRemediated={markAsRemediated}
                />
              </XTableCell>
            );
          }
        }

        // Show comments or risk adjustment justifications as additional content
        let extraContent;

        if (readOnly) {
          // Show any comment passed in for the risk
          const comment = !!riskComments?.[r.id] ? (
            <RiskSummaryComment
              comment={riskComments?.[r.id]}
              title={"risk treatment plan"}
              readonly
              readOnlyClassname={
                shouldDisplayKeyRisksSelection ? "with-key-risks" : undefined
              }
            />
          ) : undefined;

          let adjustmentReason;
          // Show the justification for a risk adjustment, if requested
          if (showRiskAdjustmentJustification && r.baseId) {
            // Get the first adjustment which covers any of this risk's surveys
            const adjustment = vendorRiskWaivers?.find(
              (w) =>
                w.riskID === r.baseId &&
                w.waiverType === WaiverType.SeverityAdjustment &&
                w.surveys?.some(
                  (s) => r.surveys?.some((rs) => rs.surveyId === s)
                )
            );
            if (adjustment) {
              adjustmentReason = (
                <RiskSummaryComment
                  comment={adjustment.justification}
                  title={"compensating control information"}
                  readonly
                  readOnlyClassname={
                    shouldDisplayKeyRisksSelection
                      ? "with-key-risks"
                      : undefined
                  }
                />
              );
            }
          }

          if (comment || adjustmentReason) {
            extraContent = (
              <>
                {comment}
                {adjustmentReason}
              </>
            );
          }
        }

        return {
          id: rowId,
          onClick: !orgAccessNewRiskDesigns
            ? expandable
              ? () => toggleRow(rowId)
              : undefined
            : () => clickRow(r.id),
          className: r.passed ? "passed" : "",
          cells: cells,
          alwaysShowCellContent: isExpanded,
          extraContent: extraContent,
          expanded: isExpanded,
          expandContent: (
            <RiskSummaryDetails
              risk={r}
              vendorId={vendorId}
              forSpecificAssessment={forSpecificAssessment}
              forPublishedAssessment={forPublishedAssessment}
              isSubsidiary={isSubsidiary}
              cloudscansInRemediation={cloudscansInRemediation}
              onOpenCloudscanPanel={onOpenCloudscanPanel}
              onOpenCVEPanel={onOpenCVEPanel}
              onQuestionnaireClicked={onQuestionnaireClicked}
              userHasWriteRiskWaiversPermission={
                userHasWriteRiskWaiversPermission
              }
              editableDomainPortfolioIds={editableDomainPortfolioIds}
              getCustomerRiskHostnames={getCustomerRiskHostnames}
              history={history}
              urlPrefix={urlPrefix}
              onToggleCloudscanDiffPanel={onToggleCloudscanDiffPanel}
              changesMode={changesMode}
              changesStartDate={changesStartDate}
              changesEndDate={changesEndDate}
              onRequestRemediationForRisk={onRequestRemediationForRisk}
              onCreateRiskWaiver={
                canManageVendorRiskWaivers ? onCreateRiskWaiver : undefined
              }
              onCreateRiskAdjustment={onCreateRiskWaiver}
              isPassive={isPassive}
              readOnly={readOnly}
              waivedAssets={waivedAssets}
              isRisksNowScored={isRisksNowScored}
              showWaived={showWaivedRisks}
              noDetectedDates={noDetectedDates}
              backToText={backToText}
              riskComment={riskComments?.[r.id]}
              onUpdateComment={
                onUpdateComment
                  ? (val) => onUpdateComment(r.id, val)
                  : undefined
              }
              hideWaivedOrAdjustedStatus={hideWaivedOrAdjustedStatus}
              hideRepositoryColumn={hideRepositoryColumn}
            />
          ),
        };
      })
    : [];

  return (
    <ReportCard
      newStyles
      className={classnames(
        "risk-summary-table",
        {
          "changes-mode": changesMode,
        },
        className,
        {
          "risk-summary-table-new-risk-designs": orgAccessNewRiskDesigns,
        }
      )}
    >
      <div className="header">
        <div className={"title"}>{title}</div>
        {!changesMode && (
          <>
            {onSetComplianceFramework && (
              <Button
                disabled={loading || filterActive}
                onClick={onSetComplianceFramework}
              >
                View compliance report
              </Button>
            )}
            <ManageRiskButton
              multiRiskText={true}
              hideRemediate={
                !userHasWriteRemediationPermission ||
                isPassive ||
                readOnly ||
                showRemediationSummary
              }
              onRemediate={onRequestRemediation}
              hideWaive={isPassive || readOnly}
              onWaive={onWaiveRisks}
              hideAdjust={isPassive || readOnly}
              onAdjust={onAdjustRisks}
            />
          </>
        )}
        {shouldDisplayKeyRisksSelection && (
          <TabButtons
            tabs={getRiskDisplayTabs}
            onChangeTab={(tabId) => {
              setActiveRiskDisplayTab(tabId);
            }}
            className="displayed-risks-tabs"
            activeTabId={activeRiskDisplayTab}
          />
        )}
      </div>
      {remediationRequest && showRemediationSummary && (
        <div className="remediation-summary">
          <div className="title">Remediation request for this assessment</div>
          <div className="status">
            {statusToPill(
              remediationRequest.status,
              remediationRequest.archived,
              RemediationRequestTableV2Mode.singlevendor
            )}
          </div>
          <div className="risk-counts">
            {remediationProgress}% complete, {remediationResolved} of{" "}
            {remediationTotal} remediated
          </div>
          <div className="navigation" onClick={onRequestRemediation}>
            <IconButton icon={<i className={"cr-icon-chevron"} />} />
          </div>
        </div>
      )}
      {!hideFilters && (
        <div className={"table-filters"}>
          {(!changesMode || sortedRisks.length > 0) && (
            <SearchBox onChanged={setSearchVal} value={searchVal} />
          )}
          {!changesMode && !hideShowPassedChecksOption && (
            <ToggleWithLabel
              onClick={
                setIncludePassedRisks
                  ? () => setIncludePassedRisks(!includePassedRisks)
                  : () => {}
              }
              selected={includePassedRisks}
              name={"passed-checks"}
              label={"Show passed checks"}
              disabled={totalPassedRisks === 0}
            />
          )}
          {!hideShowWaivedRisksOption && (
            <ToggleWithLabel
              name={"tgl-waived"}
              selected={showWaivedRisks}
              onClick={() => {
                if (onShowWaivedRisks) {
                  onShowWaivedRisks(!showWaivedRisks);
                }
              }}
              label={"Show waived risks"}
            />
          )}
          {showComponentFilters && (
            <MultiSelectionButton
              primary={false}
              text={"Filters"}
              optionGroups={riskFilterOptions(
                Object.values(selectedRiskFilters)
              )}
              onResetDefault={() => {
                setSelectedRiskFilters(_cloneDeep(initialRiskFilters));
              }}
              onSelectionChangedByValue={(value, selected) => {
                const filter = selectedRiskFilters[value];
                filter.option.selected = selected;
                setSelectedRiskFilters({
                  ...selectedRiskFilters,
                  [value]: filter,
                });
              }}
              optionGroupsConfig={initialiseRiskFilterGroups}
              autoWidth={true}
              resetLabel={"Clear all"}
            ></MultiSelectionButton>
          )}
        </div>
      )}
      <XTable
        stickyColumnHeaders
        loading={loading}
        columnHeaders={columnHeaders}
        hideColumnHeaders={
          hideColumnHeaders ||
          (rows.length == 0 && activeRiskDisplayTab === keyRisksTabId)
        }
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        rows={rows}
        expandableRows={!orgAccessNewRiskDesigns && expandable}
        onExpandToggle={
          !orgAccessNewRiskDesigns && expandable ? toggleRow : undefined
        }
        emptyContent={
          <>
            {shouldDisplayKeyRisksSelection &&
            activeRiskDisplayTab === keyRisksTabId ? (
              <EmptyCardWithAction
                className="empty-key-risks-card"
                emptyText="No key risks"
                iconJSX={<CircledIcon iconClass={"cr-icon-shortlist"} />}
                emptySubText={
                  <>
                    <div className="empty-subtext">
                      Select key risks to highlight in your final assessment.
                      You can also choose to create a Risk Assessment Report
                      using key risks only.
                    </div>
                  </>
                }
              />
            ) : filterActive ||
              filterByCategory ||
              searchVal.length > 0 ||
              componentFiltersActive ? (
              <SearchEmptyCard
                searchItemText={"risks"}
                clearText={
                  filterActive || filterByCategory || componentFiltersActive
                    ? "Clear filter"
                    : "Clear search"
                }
                onClear={() => {
                  setSearchVal("");
                  setSelectedRiskFilters(_cloneDeep(initialRiskFilters));
                  if (onClearFilter) {
                    onClearFilter();
                  }
                }}
              />
            ) : (
              <EmptyCardWithAction
                emptyText={"No risks detected"}
                className="empty-risks-card"
              />
            )}
          </>
        }
      />
    </ReportCard>
  );
};

export default appConnect<
  IRiskSummaryTableConnectedProps,
  never,
  IRiskSummaryTableOwnProps
>((state, props) => {
  return {
    customerRiskHostnames:
      !props.vendorId && !props.isSubsidiary
        ? state.cyberRisk.customerData.riskHostnames
        : emptyObj,
  };
})(RiskSummaryTable);
