import { ReactNode } from "react";
import "../style/components/UserAvatar.scss";
import classnames from "classnames";

export interface UserAvatarUser {
  id: number | string;
  avatar: string;
  name: string;
  email: string;
  isInvite?: boolean;
}

export interface IUserAvatarAndNameProps extends IUserAvatarProps {
  email?: string;
  title?: string;
}

export const UserAvatarAndName = (props: IUserAvatarAndNameProps) => {
  const { email, title, ...rest } = props;

  return (
    <div className="user-avatar-and-name">
      <UserAvatar {...rest} />
      <div className={"user-name"}>
        {rest.name}
        {title && (
          <div className={"title"} title={title}>
            {title}
          </div>
        )}
        {!!email && (
          <div title={email} className="user-email">
            {email}
          </div>
        )}
      </div>
    </div>
  );
};

export interface IUserAvatarProps {
  avatar?: string;
  name?: string;
  more?: number;
  invites?: number;
  hoverPopup?: ReactNode;
  onClick?: () => void;
  large?: boolean;
}

// UserAvatar
// Implements a react component that renders either a user's avatar from an image URL-encoded
// or renders the (+###) text. This is controlled by supplying either a 'avatar' prop with the URL
// or a value in the 'more' prop indicating the number to be displaye din the +## text.
const UserAvatar = (props: IUserAvatarProps) => {
  const classes = classnames("user-avatar", {
    large: props.large,
    clickable: props.onClick !== undefined,
  });

  return (
    <div className={classes} onClick={props.onClick}>
      {(props.more || 0) > 0 ? (
        <span className="more_pill">{`+${props.more}`}</span>
      ) : (props.invites || 0) > 0 ? (
        <span className="invites_pill">{`+${props.invites} ${
          props.invites === 1 ? "invite" : "invites"
        }`}</span>
      ) : !props.avatar || props.avatar === "missing" ? (
        <span className="icon-user" />
      ) : (
        <img src={props.avatar} alt={props.name} className="avatar" />
      )}
      {props.hoverPopup && (
        <div className="hover-popup">{props.hoverPopup}</div>
      )}
    </div>
  );
};

export default UserAvatar;
