import {
  CloudscanInRemediation,
  RemediationRequestRisk,
} from "../../../_common/types/remediation";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import PillLabel from "../../components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import "../../style/views/remediation_request/RiskDomainTable.scss";
import classNames from "classnames";

export interface IRiskDomainTableProps {
  riskHostnames: string[];
  numLoadingRows?: number;
  cloudscanInRemediation?: CloudscanInRemediation;
  selectedRiskHostnames: string[];
  allSelected: boolean;
  onSelectRiskHostname: (selection: string[]) => void;
  loading: boolean;
  selectedRisk?: RemediationRequestRisk;
  maxSelectableWebsites?: number;
  orgAccessNewRiskDesigns?: boolean;
}

export const RiskDomainTable = ({
  onSelectRiskHostname,
  cloudscanInRemediation,
  allSelected,
  riskHostnames,
  selectedRiskHostnames,
  loading,
  numLoadingRows,
  selectedRisk,
  maxSelectableWebsites = Infinity,
  orgAccessNewRiskDesigns,
}: IRiskDomainTableProps) => {
  const shouldEnforceSelectionLimit = maxSelectableWebsites !== Infinity;

  // does selecting a new domain bring the selection beyond the allowed limit?
  const canSelectNewDomains =
    !shouldEnforceSelectionLimit ||
    selectedRiskHostnames.length < maxSelectableWebsites;

  // assuming a situation where all domains are selected, would deselecting one
  // domain bring the state beyond the allowed limit?
  // Note: this is a helper flag to be used alongside the allSelected prop
  const canDeselectFromAll =
    !shouldEnforceSelectionLimit ||
    riskHostnames.length <= maxSelectableWebsites;

  const columns: IXTableColumnHeader[] = [
    { id: "assets", text: "Affected assets" },
    { id: "status", text: "Remediation Status" },
  ];

  const onSelectHostname = (hostname: string) => {
    if (allSelected) {
      // If all are selected and user has clicked a single one
      // -> that means the single hostname should be deselected
      // -> therefore we should "select" only the remaining hostnames
      if (canDeselectFromAll) {
        onSelectRiskHostname(riskHostnames.filter((h) => h !== hostname));
      }
      return;
    }

    const idx = selectedRiskHostnames.indexOf(hostname);
    if (idx >= 0) {
      onSelectRiskHostname(selectedRiskHostnames.filter((h) => h !== hostname));
    } else if (canSelectNewDomains) {
      onSelectRiskHostname(selectedRiskHostnames.concat(hostname));
    }
  };

  const rows: IXTableRow[] = riskHostnames.map((h) => {
    const isSelected = selectedRiskHostnames.includes(h);
    // we want the users to either select all domains or if domains are selected
    // individually select less than maxSelectableWebsites.
    // however design require we don't want to disable checkbox outright for domains
    // that cannot be select as it can be confusing with other cases. As such we separate
    // the enabled/disabled state from the clickable/non-clickable state
    const isDomainSelectionAllowed = allSelected
      ? canDeselectFromAll
      : isSelected || canSelectNewDomains;

    const isInRemediation = !!(
      cloudscanInRemediation &&
      (cloudscanInRemediation.websites[h] ||
        cloudscanInRemediation.isAllWebsites) &&
      !selectedRisk?.websites.includes(h)
    );

    const cells = [];

    if (orgAccessNewRiskDesigns) {
      cells.push(
        <XTableCell key={"assets"}>
          <div className={"asset-container"}>
            <p>{h}</p>
            {orgAccessNewRiskDesigns && isInRemediation && (
              <PillLabel color={LabelColor.Blue}>In remediation</PillLabel>
            )}
          </div>
        </XTableCell>
      );
    } else {
      cells.push(
        <XTableCell key={"assets"}>{h}</XTableCell>,
        <XTableCell key={"status"} className={"shrink-cell"}>
          {isInRemediation && (
            <PillLabel color={LabelColor.Blue}>In remediation</PillLabel>
          )}
        </XTableCell>
      );
    }

    const rowID = h;

    return {
      id: rowID,
      className: classNames({
        "domain-selection-not-allowed": !isDomainSelectionAllowed,
      }),
      cells: cells,
      selected: (isSelected || allSelected) && !isInRemediation,
      selectionDisabled: isInRemediation,
      selectionDisabledHelpText: isInRemediation
        ? "This risk is already under remediation"
        : undefined,
      selectionHelpText: !isDomainSelectionAllowed
        ? `Individual domain selection is limited to ${maxSelectableWebsites} domains`
        : undefined,
      onClick:
        orgAccessNewRiskDesigns && !isInRemediation
          ? () => onSelectHostname(rowID)
          : undefined,
    };
  });

  return (
    <XTable
      className={classNames({
        "risk-domain-table": true,
        "risk-domain-table-new-risk-designs": orgAccessNewRiskDesigns,
      })}
      rows={rows}
      columnHeaders={columns}
      loading={loading}
      numLoadingRows={numLoadingRows}
      hideColumnHeaders
      selectable
      onSelectClick={(id) => onSelectHostname(id as string)}
    />
  );
};
