import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import Breadcrumbs, { IBreadcrumbsProps } from "../Breadcrumbs";
import { SidePopupV2 } from "../DismissablePopup";
import { ButtonSeparator } from "../PageHeader";
import Button from "../core/Button";
import {
  getLocalStorageItemString,
  setLocalStorageItemString,
} from "../../session";
import { dismissableBannerLocalStorageKey } from "../DismissableBanner";
import "../../style/components/pageHeader/PageTitleSection.scss";

const getInfoSectionActiveStatus = (infoSectionPageKey: string | undefined) => {
  // If there is no local storage key, we should default the info section to closed.
  if (!infoSectionPageKey) {
    return false;
  }

  // Check localStorage to see if this info section has been dismissed before
  const isActive = getLocalStorageItemString(
    dismissableBannerLocalStorageKey(infoSectionPageKey)
  );

  // Default to open unless it's been explicitly dismissed
  return isActive !== "false";
};

export interface PageTitleSectionProps extends Partial<IBreadcrumbsProps> {
  title?: ReactNode;
  backAction?: () => void;
  backText?: string;
  rightSection?: ReactNode;

  // Content for the page description section
  infoSection?: ReactNode;
  hideInfoSectionButtons?: boolean;

  // LocalStorage key for remembering that the info section is dismissed for a page
  infoSectionPageKey?: string;

  // Optional buttons for the page description section
  infoSectionButtons?: ReactNode;
}

const PageTitleSection: FC<PageTitleSectionProps> = ({
  breadcrumbs,
  title,
  backAction,
  backText,
  rightSection,
  infoSection,
  infoSectionButtons,
  hideInfoSectionButtons,
  infoSectionPageKey,
}) => {
  const hasInfoSection = !!infoSection;

  const [infoSectionVisible, setInfoSectionVisible] = useState(() =>
    getInfoSectionActiveStatus(infoSectionPageKey)
  );

  useEffect(() => {
    //  Make sure we update the info section status when the page key changes
    setInfoSectionVisible(getInfoSectionActiveStatus(infoSectionPageKey));
  }, [infoSectionPageKey]);

  const toggleInfoSection = useCallback(() => {
    setInfoSectionVisible(!infoSectionVisible);

    // And set this to active/dismissed in localStorage
    if (infoSectionPageKey) {
      setLocalStorageItemString(
        dismissableBannerLocalStorageKey(infoSectionPageKey),
        infoSectionVisible ? "false" : "true"
      );
    }
  }, [setInfoSectionVisible, infoSectionVisible, infoSectionPageKey]);

  return (
    <div className="page-title-section">
      {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      {title && (
        <div className="page-title">
          {backAction && (
            <SidePopupV2
              text={backText || "Back"}
              className="back-btn-popup"
              position={"right"}
              noWrap
            >
              <div className="back-btn" onClick={backAction} />
            </SidePopupV2>
          )}
          <h2>{title}</h2>
          <div className="page-title-right">{rightSection}</div>
          {hasInfoSection && !hideInfoSectionButtons && (
            <>
              {rightSection && <ButtonSeparator />}
              <Button
                className="info-button"
                primary={infoSectionVisible}
                tertiary={!infoSectionVisible}
                onClick={toggleInfoSection}
              >
                <div className="cr-icon-info" />
              </Button>
            </>
          )}
        </div>
      )}
      {infoSectionVisible && (infoSection || infoSectionButtons) && (
        <div className="info-section">
          <div className="info-desc">{infoSection}</div>
          {!hideInfoSectionButtons && (
            <div className="btns-and-control">
              {infoSectionButtons}
              <div className="collapse-control" onClick={toggleInfoSection}>
                Collapse <div className="cr-icon-chevron rot270" />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PageTitleSection;
