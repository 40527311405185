import { Surface, SurfaceProps } from "../../../_common/components/Surface";
import { FC, useEffect, useState } from "react";
import TextField, {
  TextFieldData,
} from "../../../_common/components/TextField";
import Button from "../../../_common/components/core/Button";

interface NewRecipientSurfaceProps extends SurfaceProps {
  emailAddressesInUse: string[];
  onContactAdd: (
    name: string,
    title: string,
    emailAddress: string,
    isVendorContact: boolean
  ) => void;
  // validateNewContactEmail should return an error string
  // if the contact is invalid or undefined if valid
  validateNewContactEmail?: (emailAddress?: string) => string | undefined;
}

const NewRecipientSurface: FC<NewRecipientSurfaceProps> = ({
  active,
  onClickOutside,
  emailAddressesInUse,
  onContactAdd,
  validateNewContactEmail,
}) => {
  const [vendorContact, setVendorContact] = useState(false);
  const [emailAddressCustomError, setEmailAddressCustomError] = useState<
    string[] | undefined
  >(undefined);
  const [name, setName] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });
  const [title, setTitle] = useState<TextFieldData>({
    value: "",
    isValid: true,
  });
  const [email, setEmail] = useState<TextFieldData>({
    value: "",
    isValid: false,
  });

  // Reset on hide
  useEffect(() => {
    if (!active) {
      setVendorContact(false);
      setEmailAddressCustomError([]);
      setName({ value: "", isValid: false });
      setTitle({ value: "", isValid: true });
      setEmail({ value: "", isValid: false });
    }
  }, [active]);

  // Check email address usage
  useEffect(() => {
    if (emailAddressesInUse.indexOf(email.value) !== -1) {
      setEmailAddressCustomError(["Email address already exists"]);
    } else {
      setEmailAddressCustomError(undefined);
    }
  }, [email, emailAddressesInUse]);

  const addContact = () => {
    onContactAdd(name.value, title.value, email.value, vendorContact);

    if (onClickOutside) {
      onClickOutside();
    }
  };

  let isValid =
    name.isValid && title.isValid && email.isValid && !emailAddressCustomError;
  // if the state is valid and validateNewContactEmail
  // was provided run it as last check of validity
  if (isValid && validateNewContactEmail) {
    const validationError = validateNewContactEmail(email.value);
    if (validationError) {
      isValid = false;
      setEmailAddressCustomError([validationError]);
    }
  }

  return (
    <Surface active={active} onClickOutside={onClickOutside}>
      <div className={"surface-title"}>Add new recipient</div>
      <div className={"surface-content"}>
        <TextField
          required
          placeholder={"Name"}
          value={name.value}
          onChanged={(value, isValid) => setName({ value, isValid })}
        />
        <TextField
          placeholder={"Title (optional)"}
          value={title.value}
          onChanged={(value, isValid) => setTitle({ value, isValid })}
        />
        <TextField
          required
          type={"email"}
          placeholder={"Email"}
          value={email.value}
          onChanged={(value, isValid) => setEmail({ value, isValid })}
          errorTexts={emailAddressCustomError}
        />
        {/* KW: disabled vendor contacts for now */}
        {/*<ColorCheckbox*/}
        {/*  checked={vendorContact}*/}
        {/*  onClick={() => setVendorContact(!vendorContact)}*/}
        {/*  label={"Save to Vendor Contacts"}*/}
        {/*  helpPopup={"We'll add this contact to the contact list"}*/}
        {/*/>*/}
      </div>
      <div className={"surface-btn-group"}>
        <Button tertiary onClick={onClickOutside}>
          Cancel
        </Button>
        <Button primary disabled={!isValid} onClick={addContact}>
          Add
        </Button>
      </div>
    </Surface>
  );
};

export default NewRecipientSurface;
