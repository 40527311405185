import { Invite, IUserMini } from "../../types/user";
import { FC, useState } from "react";
import ModalV2, { BaseModalProps } from "../ModalV2";
import ModalForm from "../../../vendorrisk/components/modals/ModalForm";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import { DefaultThunkDispatch } from "../../types/redux";
import Button from "../core/Button";

import "../../style/components/SurveyDetailsInviteUsersModal.scss";
import { validateEmail } from "../../helpers";
import {
  fetchSurveyDetails,
  fetchSurveyTimeline,
  resendShareInviteForSurvey,
} from "../../reducers/surveyDetails.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addDefaultWarningAlert,
} from "../../reducers/messageAlerts.actions";
import { handleKnownErrors } from "../../../vendorrisk/reducers/errors.actions";
import TextField, { MaxLengthType } from "../TextField";

interface ISurveyDetailsInviteUsersModalProps extends BaseModalProps {
  dispatch: DefaultThunkDispatch;
  isVendorPortal: boolean;
  surveyId: number;
  sharedWith: IUserMini[];
  openInvites: Invite[];
  fromCompany: string;
}

const SurveyDetailsInviteUsersModal: FC<
  ISurveyDetailsInviteUsersModalProps
> = ({
  dispatch,
  surveyId,
  sharedWith,
  openInvites,
  fromCompany,
  active,
  onClose,
  isVendorPortal,
}) => {
  const [loading, setLoading] = useState(false);
  const [newRecipients, setNewRecipients] = useState<
    {
      firstName: string;
      lastName: string;
      emailAddress: string;
    }[]
  >([
    {
      firstName: "",
      lastName: "",
      emailAddress: "",
    },
  ]);
  const [message, setMessage] = useState(
    `As discussed, please contribute to the completion of this security questionnaire from ${fromCompany}. Thanks.`
  );

  let anyValidRecipients = false;
  let anyInvalidRecipients = false;
  for (let i = 0; i < newRecipients.length; i++) {
    const { firstName, lastName, emailAddress } = newRecipients[i];

    if (!firstName && !lastName && !emailAddress) {
      continue;
    }

    if (firstName && lastName && validateEmail(emailAddress)) {
      anyValidRecipients = true;
    } else {
      anyInvalidRecipients = true;
    }
  }

  const onSubmit = async () => {
    if (!anyValidRecipients || anyInvalidRecipients || !message) {
      return;
    }

    for (let i = 0; i < newRecipients.length; i++) {
      const { emailAddress } = newRecipients[i];
      if (
        sharedWith.some(
          (s) => s.email.toLowerCase() === emailAddress.toLowerCase()
        ) ||
        openInvites.some(
          (i) => i.recipientEmail.toLowerCase() === emailAddress.toLowerCase()
        )
      ) {
        dispatch(
          addDefaultWarningAlert(`${emailAddress} has already been invited.`)
        );
        return;
      }
    }

    setLoading(true);

    const proms: Promise<void>[] = [];

    for (let i = 0; i < newRecipients.length; i++) {
      const { firstName, emailAddress } = newRecipients[i];
      if (!emailAddress) {
        continue;
      }

      proms.push(
        dispatch(
          resendShareInviteForSurvey(
            surveyId,
            firstName,
            emailAddress.trim().toLowerCase(),
            message,
            fromCompany
          )
        )
      );
    }

    try {
      await Promise.all(proms);
      // Grab the timeline, but don't wait for it
      dispatch(fetchSurveyTimeline(surveyId, true));

      await dispatch(fetchSurveyDetails(surveyId, true));
      dispatch(addDefaultSuccessAlert("Successfully invited collaborators"));
      onClose();
    } catch (e) {
      if (!dispatch(handleKnownErrors(e))) {
        console.error(e);
        dispatch(addDefaultUnknownErrorAlert("Error inviting collaborators"));
      }
      setLoading(false);
    }
  };

  const recipientNoun = isVendorPortal ? "collaborators" : "recipients";

  return (
    <ModalV2
      active={active}
      onClose={onClose}
      className="survey-details-invite-users-modal"
      headerContent={`Invite ${recipientNoun}`}
      footerContent={
        <div className="btn-group">
          <Button tertiary onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!anyValidRecipients || anyInvalidRecipients || !message}
            loading={loading}
            onClick={onSubmit}
          >
            Send invites
          </Button>
        </div>
      }
    >
      <ModalForm>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Existing {recipientNoun}</span>
            <p>
              These people already have access to fill out the questionnaire.
            </p>
          </div>
          <div className="form-section-input">
            <div className="email-list">
              {sharedWith.map((s) => (
                <PillLabel
                  capitalized={false}
                  key={s.email}
                  color={LabelColor.Blue}
                >
                  {s.email}
                </PillLabel>
              ))}
              {openInvites.map((invite) => (
                <PillLabel
                  capitalized={false}
                  key={invite.recipientEmail}
                  color={LabelColor.Grey}
                >
                  {invite.recipientEmail} (invited)
                </PillLabel>
              ))}
            </div>
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Add new {recipientNoun}</span>
            <p>
              Enter in details of any new {recipientNoun}. We will send them an
              email notification.
            </p>
          </div>
          <div className="form-section-input">
            {newRecipients.map((r, i) => (
              <div key={i} className="recipient-form">
                <div className="first-last-name">
                  <input
                    type="text"
                    value={r.firstName}
                    onChange={(e) => {
                      const val = e.target.value;
                      setNewRecipients((existingRecipients) => {
                        const recipients = [...existingRecipients];
                        recipients[i] = {
                          ...recipients[i],
                          firstName: val,
                        };
                        return recipients;
                      });
                    }}
                    placeholder="First name"
                  />
                  <input
                    type="text"
                    value={r.lastName}
                    onChange={(e) => {
                      const val = e.target.value;
                      setNewRecipients((existingRecipients) => {
                        const recipients = [...existingRecipients];
                        recipients[i] = {
                          ...recipients[i],
                          lastName: val,
                        };
                        return recipients;
                      });
                    }}
                    placeholder="Last name"
                  />
                </div>
                <input
                  type="email"
                  className="email-input"
                  value={r.emailAddress}
                  onChange={(e) => {
                    const val = e.target.value;
                    setNewRecipients((existingRecipients) => {
                      const recipients = [...existingRecipients];
                      recipients[i] = {
                        ...recipients[i],
                        emailAddress: val,
                      };
                      return recipients;
                    });
                  }}
                  placeholder="Email address"
                />
              </div>
            ))}
            {newRecipients.length < 3 && (
              <Button
                tertiary
                className="add-another-btn"
                onClick={() =>
                  setNewRecipients((recipients) => [
                    ...recipients,
                    {
                      firstName: "",
                      lastName: "",
                      emailAddress: "",
                    },
                  ])
                }
              >
                + Add another {isVendorPortal ? "collaborator" : "recipient"}
              </Button>
            )}
          </div>
        </div>
        <div className="form-section">
          <div className="form-section-desc">
            <span>Add a message to new {recipientNoun}</span>
            <p>
              This message will be send to new {recipientNoun} of this
              questionnaire.
            </p>
          </div>
          <div className="form-section-input">
            <TextField
              multiLine
              maxRows={4}
              maxLength={MaxLengthType.message}
              value={message}
              onChanged={(val, _) => setMessage(val)}
            />
          </div>
        </div>
      </ModalForm>
    </ModalV2>
  );
};

export default SurveyDetailsInviteUsersModal;
