import {
  GetQuestionnaireStatusItem,
  ISurveyImportDetails,
  ISurveyListItemResponse,
  ISurveyReminder,
  SurveyStatus,
  surveyStatusNotIn,
} from "../../types/survey";
import { FC, memo, useRef, useState } from "react";
import { getVendorWords } from "../../constants";
import ReportCard from "../ReportCard";
import InfoTable, { InfoTableRow } from "../InfoTable";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import moment from "moment/moment";
import { UserAvatarAndName } from "../UserAvatar";
import Button from "../core/Button";

import "../../style/components/SurveyDetailsOverviewCard.scss";
import Score from "../../../vendorrisk/components/Score";
import ColorGrade, {
  ColorGradeSize,
} from "../../../vendorrisk/components/executive_summary/ColorGrade";
import SurveyDetailsStatusDropdown from "./SurveyDetailsStatusDropdown";
import SurveyDetailsScoreDropdown from "./SurveyDetailsScoreDropdown";
import { DefaultThunkDispatch } from "../../types/redux";
import { History } from "history";
import { Link } from "react-router-dom";
import DatePicker from "../DatePicker";
import DropdownV2, { DropdownItem, DropdownV2Handle } from "../core/DropdownV2";
import LoadingOverlay from "../LoadingOverlay";
import {
  fetchSurveyDetails,
  fetchSurveyTimeline,
  updateSurveyDueDate,
} from "../../reducers/surveyDetails.actions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import ModifyResendDateModal from "./ModifyResendDateModal";
import { useModalV2 } from "../ModalV2";
import QuestionnaireSharedListModal from "../modals/QuestionnaireSharedListModal";
import { vendorUrlPrefix } from "../../helpers";
import SurveyDetailsReminderModal from "./SurveyDetailsRemindersModal";
import SurveyName from "../../../vendorrisk/components/surveys/SurveyName";
import { SurveyUsageType } from "../../types/surveyTypes";
import { AssuranceType } from "../../types/organisations";
import { SidePopupV2 } from "../DismissablePopup";
import SharedSurveyDetailsStatusDropdown from "./SharedSurveyDetailsStatusDropdown";
import { getDisplayForQuestionnaireRiskVisibility } from "../../../vendorrisk/components/surveys/QuestionnaireRiskVisibilitySelector";
import { useVendorDataSelector } from "../../selectors/vendorSelectors";
import { GetIconForFilename } from "../../../vendorrisk/helpers/icons";
import { downloadSurveyImportExcelFile } from "../../../vendor_portal/api/surveyImportDownload";
import ImportedSurveyCollaborators from "../../../vendor_portal/components/ImportedSurveyCollaborators";
import SurveyStatusDisplay from "../../../vendorrisk/components/surveys/SurveyStatusDisplay";
import SurveyDetailsInviteCollaboratorModal from "./SurveyDetailsInviteCollaboratorModal";
import {
  OrgAccessSurveyInternalCollaborators,
  useBasicPermissions,
} from "../../permissions";
import { ManagedVendorAssessmentPill } from "../../../vendorrisk/components/ManagedVendorAssessmentPill";
import SurveyCollaboratorListModal from "./SurveyCollaboratorListModal";

interface IDueDateSelectorProps {
  dispatch: DefaultThunkDispatch;
  surveyId: number;
  dueDate: string;
}

const DueDateSelector: FC<IDueDateSelectorProps> = ({
  dueDate,
  surveyId,
  dispatch,
}) => {
  const [loading, setLoading] = useState(false);
  const dropdownRef = useRef<DropdownV2Handle>(null);

  const onChange = async (newDueDate: string) => {
    setLoading(true);

    try {
      await dispatch(updateSurveyDueDate(surveyId, newDueDate));
      await Promise.all([
        dispatch(fetchSurveyDetails(surveyId, true)),
        dispatch(fetchSurveyTimeline(surveyId, true)),
      ]);
      dispatch(addDefaultSuccessAlert("Due date changed"));
    } catch (e) {
      dispatch(
        addDefaultUnknownErrorAlert("An error occurred updating the due date")
      );
    } finally {
      setLoading(false);
      if (dropdownRef.current) {
        dropdownRef.current.setOpen(false);
      }
    }
  };

  return (
    <DropdownV2
      ref={dropdownRef}
      className="due-date-selector"
      noCloseOnClickInside
      popupItem={
        <div className="clickable-value">
          {moment(dueDate).format("ll")} <div className="cr-icon-pencil" />
        </div>
      }
    >
      <LoadingOverlay loading={loading}>
        <DatePicker
          value={moment(dueDate).format("YYYY-MM-DD")}
          onChange={(e) => onChange(e.target.value)}
          min={moment().format("YYYY-MM-DD")}
          inline
        />
      </LoadingOverlay>
    </DropdownV2>
  );
};

interface ISurveyDetailsOverviewCardProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  survey: ISurveyListItemResponse;
  surveyImport?: ISurveyImportDetails;
  reminders?: ISurveyReminder[];
  currentUserId: number;
  isVendorPortal: boolean;
  hasScoresFeatureEnabled: boolean;
  openShareSurveyModal: () => void;
  userHasWritePermission: boolean;
  isManagementAnalystSession: boolean;
  userIsManagedVendorAnalyst: boolean;
  managedOrgId: number;
  hasUnansweredQuestions: boolean;
  vendorId?: number;
  assuranceType: AssuranceType;
  orgHasAutomationEnabled?: boolean;
  isSharedSurvey: boolean;
  orgHasExtraQuestionnaireStatusChanges?: boolean;
  isCollaboratorPortal?: boolean;
}

const SurveyDetailsOverviewCard: FC<ISurveyDetailsOverviewCardProps> = ({
  dispatch,
  history,
  isVendorPortal,
  survey,
  surveyImport,
  reminders,
  openShareSurveyModal,
  hasScoresFeatureEnabled,
  userHasWritePermission,
  isManagementAnalystSession,
  managedOrgId,
  currentUserId,
  hasUnansweredQuestions,
  vendorId,
  assuranceType,
  orgHasAutomationEnabled,
  isSharedSurvey,
  orgHasExtraQuestionnaireStatusChanges,
  userIsManagedVendorAnalyst,
  isCollaboratorPortal = false,
}) => {
  const vendorWords = getVendorWords(assuranceType);
  const [openModifyResendDateModal, modifyResendDateModal] = useModalV2(
    ModifyResendDateModal
  );
  const [openSharedListModal, sharedListModal] = useModalV2(
    QuestionnaireSharedListModal
  );
  const [openReminderModal, reminderModal] = useModalV2(
    SurveyDetailsReminderModal
  );
  const [openAddCollaboratorModal, addCollaboratorModal] = useModalV2(
    SurveyDetailsInviteCollaboratorModal
  );
  const [openCollaboratorListModal, collaboratorListModal] = useModalV2(
    SurveyCollaboratorListModal
  );

  const permissions = useBasicPermissions();
  const hasCollaborators =
    !!permissions.orgPermissions[OrgAccessSurveyInternalCollaborators];

  const vendorName =
    useVendorDataSelector((vendorData) => vendorData?.name, vendorId ?? 0) ||
    survey.vendorName;

  const timelineStatusMapItem = GetQuestionnaireStatusItem(
    survey.status,
    survey.dueDate,
    isVendorPortal
  );

  const statusLabels = (
    <>
      {isSharedSurvey && (
        <PillLabel color={LabelColor.Fuchsia}>Shared</PillLabel>
      )}
      <PillLabel color={timelineStatusMapItem.labelColor}>
        {surveyImport && timelineStatusMapItem.importedSurveyText
          ? timelineStatusMapItem.importedSurveyText
          : timelineStatusMapItem.text}
      </PillLabel>
      {survey.risksInRemediation > 0 && (
        <PillLabel color={LabelColor.Green}>In Remediation</PillLabel>
      )}
      {survey.archived && (
        <PillLabel color={LabelColor.Red}>Archived</PillLabel>
      )}
    </>
  );

  // if this is an analyst workflow questionnaire, and the current user is not an analyst, then they shouldnt be editing the status or any other data point.
  const shouldNotBeChangingStatusEtc =
    isVendorPortal ||
    (survey.isAnalystWorkflowSurvey &&
      (!isManagementAnalystSession || !userIsManagedVendorAnalyst));

  const cannotEditDueDate =
    survey.status !== SurveyStatus.Sent &&
    survey.status !== SurveyStatus.InProgress;

  const hasRisks = !!survey.risks;
  const isSecuritySurvey = survey.usageType === SurveyUsageType.Security;
  let statusRow = <></>;

  if (isVendorPortal || isCollaboratorPortal || !userHasWritePermission) {
    statusRow = statusLabels;
  } else if (isSecuritySurvey && isSharedSurvey) {
    statusRow = (
      <SharedSurveyDetailsStatusDropdown
        dispatch={dispatch}
        popupItem={
          <>
            {statusLabels}
            <div className="cr-icon-typosquatting-1 rot90" />
          </>
        }
        survey={survey}
      />
    );
  } else {
    statusRow = (
      <>
        {shouldNotBeChangingStatusEtc && (
          <>
            <SurveyStatusDisplay survey={survey} />
            {!isVendorPortal &&
              survey.isAnalystWorkflowSurvey &&
              ManagedVendorAssessmentPill(true, "right")}
          </>
        )}
        {!shouldNotBeChangingStatusEtc && (
          <>
            {!isVendorPortal &&
              survey.isAnalystWorkflowSurvey &&
              ManagedVendorAssessmentPill(true, "right")}
            <SurveyDetailsStatusDropdown
              dispatch={dispatch}
              history={history}
              isManagementAnalystSession={isManagementAnalystSession}
              managedOrgId={managedOrgId}
              popupItem={
                <>
                  {statusLabels}
                  <div className="cr-icon-typosquatting-1 rot90" />
                </>
              }
              survey={survey}
              orgHasExtraQuestionnaireStatusChanges={
                orgHasExtraQuestionnaireStatusChanges
              }
            />
          </>
        )}
      </>
    );
  }

  return (
    <>
      <ReportCard newStyles className="survey-details-overview-card">
        <div className="header">Details</div>
        <InfoTable className="overview-table" bordered>
          <InfoTableRow
            label="Name"
            rowClass={"no-max-height"}
            value={<SurveyName survey={survey} />}
          />
          <InfoTableRow
            label="Status"
            rowClass="status-row"
            value={statusRow}
          />
          {hasScoresFeatureEnabled &&
            survey.usageType !== SurveyUsageType.Relationship &&
            !surveyImport && (
              <InfoTableRow
                label="Score"
                value={
                  <>
                    {hasRisks &&
                    (!survey.isAnalystWorkflowSurvey ||
                      surveyStatusNotIn(survey.status, [
                        SurveyStatus.New,
                        SurveyStatus.Autofilled,
                        SurveyStatus.InAutofillReview,
                        SurveyStatus.Sent,
                      ])) ? (
                      <>
                        <SidePopupV2
                          className={"questionnaire-score-container"}
                          position={"top"}
                          width={250}
                          text={
                            hasUnansweredQuestions
                              ? "This score has been reduced due to unanswered questions"
                              : ""
                          }
                        >
                          <ColorGrade
                            score={Math.floor(survey.adjustedRiskScore * 950)}
                            size={ColorGradeSize.Small}
                          />
                          <Score
                            score={Math.floor(survey.adjustedRiskScore * 950)}
                            small
                            qualifierText={hasUnansweredQuestions ? "*" : ""}
                          />
                        </SidePopupV2>
                        {!isVendorPortal && survey.excludeFromVendorScore && (
                          <>
                            <div className={"no-score"}>
                              {"(Not included in score)"}
                            </div>
                          </>
                        )}
                        {!isVendorPortal &&
                          !isCollaboratorPortal &&
                          userHasWritePermission &&
                          !survey.archived && (
                            <SurveyDetailsScoreDropdown
                              dispatch={dispatch}
                              survey={survey}
                              vendorId={vendorId}
                              assuranceType={assuranceType}
                              popupItem={
                                <>
                                  <div className="cr-icon-typosquatting-1 rot90" />
                                </>
                              }
                            />
                          )}
                      </>
                    ) : hasRisks ? (
                      <>
                        This questionnaire has no score until submitted by the
                        vendor
                      </>
                    ) : (
                      <>
                        This questionnaire has no score as there are no
                        associated risks.
                      </>
                    )}
                  </>
                }
              />
            )}
          {survey.status !== SurveyStatus.Complete &&
            survey.status !== SurveyStatus.Cancelled &&
            !survey.archived &&
            (isVendorPortal || survey.numQuestions > 0) && (
              <InfoTableRow
                label="Progress"
                value={`${Math.floor(
                  (survey.numQuestions > 0
                    ? survey.numAnswers / survey.numQuestions
                    : 0) * 100
                )}%`}
              />
            )}
          {survey.dueDate && (
            <InfoTableRow
              label="Date due"
              value={
                isVendorPortal ||
                isCollaboratorPortal ||
                !userHasWritePermission ||
                cannotEditDueDate ? (
                  moment(survey.dueDate).format("ll")
                ) : (
                  <DueDateSelector
                    dispatch={dispatch}
                    surveyId={survey.id}
                    dueDate={survey.dueDate}
                  />
                )
              }
            />
          )}
          {!isVendorPortal && (
            <InfoTableRow
              label={"Risk visibility"}
              value={getDisplayForQuestionnaireRiskVisibility(
                survey.riskVisibility
              )}
            />
          )}

          {!isVendorPortal &&
            !isCollaboratorPortal &&
            userHasWritePermission &&
            survey.status !== SurveyStatus.Cancelled &&
            survey.usageType === SurveyUsageType.Security && (
              <InfoTableRow
                label="Resend date"
                value={
                  <div
                    className="clickable-value"
                    onClick={
                      !shouldNotBeChangingStatusEtc
                        ? () =>
                            openModifyResendDateModal({
                              dispatch: dispatch,
                              surveyId: survey.id,
                              vendorId: survey.vendorId,
                              resendDate: survey.resendDate,
                            })
                        : undefined
                    }
                  >
                    {survey.resendDate ? (
                      moment(survey.resendDate).format("ll")
                    ) : (
                      <span className="not-set">No resend date set</span>
                    )}{" "}
                    {!shouldNotBeChangingStatusEtc && (
                      <div className="cr-icon-pencil" />
                    )}
                  </div>
                }
              />
            )}
          {!isVendorPortal &&
            !isCollaboratorPortal &&
            userHasWritePermission &&
            (survey.status === SurveyStatus.Sent ||
              survey.status === SurveyStatus.InProgress) &&
            survey.usageType === SurveyUsageType.Security && (
              <InfoTableRow
                label="Reminders"
                value={
                  <div
                    className="clickable-value"
                    onClick={
                      reminders
                        ? () =>
                            openReminderModal({
                              dispatch,
                              surveyId: survey.id,
                              reminders,
                              shouldNotBeChangingStatusEtc,
                            })
                        : undefined
                    }
                  >
                    <span className="not-set">{`View${
                      shouldNotBeChangingStatusEtc ? "" : "/schedule"
                    } reminders`}</span>{" "}
                    {!shouldNotBeChangingStatusEtc && (
                      <div className="cr-icon-pencil" />
                    )}
                  </div>
                }
              />
            )}
          {survey.usageType === SurveyUsageType.Relationship &&
            orgHasAutomationEnabled &&
            survey.isLatestInstanceForVendor &&
            !survey.archived && (
              <InfoTableRow
                label="Automation"
                value={
                  <>
                    {!survey.automationExists ? (
                      <PillLabel color={LabelColor.DarkGrey}>none</PillLabel>
                    ) : survey.automationDisabled ? (
                      <SidePopupV2
                        text={
                          "Either the structure of the relationship questionnaire template or the automation rules defined for it have changed since this questionnaire version was sent."
                        }
                        position={"top"}
                      >
                        <PillLabel color={LabelColor.DarkGrey}>
                          disabled
                        </PillLabel>
                      </SidePopupV2>
                    ) : (
                      <PillLabel capitalized={true} color={LabelColor.Green}>
                        enabled
                      </PillLabel>
                    )}
                  </>
                }
              />
            )}
          {surveyImport && (
            <InfoTableRow
              label="Imported document"
              labelMiddleAlign
              value={(() => {
                const hasAnswersAvailable =
                  survey.status === SurveyStatus.Complete;

                const importedDocLink = (
                  <div
                    className="imported-doc-link"
                    onClick={
                      !hasAnswersAvailable
                        ? () =>
                            dispatch(
                              downloadSurveyImportExcelFile(
                                surveyImport.uuid,
                                false
                              )
                            )
                        : undefined
                    }
                  >
                    <img
                      className="file-type"
                      alt="File type icon"
                      src={GetIconForFilename(surveyImport.documentFilename)}
                    />
                    <div>{surveyImport.documentFilename}</div>
                    <div className="cr-icon-export-thin" />
                  </div>
                );
                return hasAnswersAvailable ? (
                  <DropdownV2 popupItem={importedDocLink}>
                    <DropdownItem
                      onClick={() =>
                        dispatch(
                          downloadSurveyImportExcelFile(surveyImport.uuid, true)
                        )
                      }
                    >
                      Download file with new answers
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        dispatch(
                          downloadSurveyImportExcelFile(
                            surveyImport.uuid,
                            false
                          )
                        )
                      }
                    >
                      Download original file
                    </DropdownItem>
                  </DropdownV2>
                ) : (
                  importedDocLink
                );
              })()}
            />
          )}
          {survey.fromUser && (
            <InfoTableRow
              label={surveyImport ? "Imported by" : "Sent by"}
              value={
                <>
                  <UserAvatarAndName
                    avatar={survey.fromUser.avatar}
                    name={survey.fromUser.name}
                    email={survey.fromUser.email}
                  />
                </>
              }
            />
          )}
          {!isVendorPortal &&
            hasCollaborators &&
            !(
              // hide this section if the user does not have write permission and there are no collaborators
              (
                !userHasWritePermission &&
                survey.collaborators.length +
                  (survey.openCollaborationInvites?.length ?? 0) ==
                  0
              )
            ) && (
              <InfoTableRow
                label={"Collaborators"}
                rowClass="sent-to-list"
                value={
                  <>
                    <div
                      className={`shared-with-list ${
                        !survey.deletedAt &&
                        !isCollaboratorPortal &&
                        userHasWritePermission
                          ? "clickable"
                          : ""
                      }`}
                      onClick={
                        !survey.deletedAt &&
                        !isCollaboratorPortal &&
                        userHasWritePermission
                          ? () =>
                              openCollaboratorListModal({
                                surveyId: survey.id,
                                sharedWith: survey.collaborators ?? [],
                                onOpenInviteModal: () =>
                                  openAddCollaboratorModal({
                                    surveyID: survey.id,
                                    collaborators: survey.collaborators ?? [],
                                  }),
                              })
                          : undefined
                      }
                    >
                      {(survey.collaborators || []).map((u) => (
                        <UserAvatarAndName
                          key={u.id}
                          avatar={u.avatar}
                          name={u.name}
                          email={u.email}
                        />
                      ))}

                      {(survey.openCollaborationInvites || []).map((i) => (
                        <UserAvatarAndName
                          key={i.recipientEmail}
                          avatar=""
                          name={i.recipientEmail}
                          email={"(invited)"}
                        />
                      ))}
                    </div>
                    {!isCollaboratorPortal && userHasWritePermission && (
                      <Button
                        tertiary
                        onClick={() =>
                          openAddCollaboratorModal({
                            surveyID: survey.id,
                            collaborators: survey.collaborators,
                          })
                        }
                      >
                        + Add collaborator
                      </Button>
                    )}
                  </>
                }
              />
            )}
          {survey.vendorId > 0 && (
            <InfoTableRow
              label={
                isVendorPortal
                  ? survey.usageType === SurveyUsageType.Relationship
                    ? "Vendor"
                    : "Sent to company"
                  : vendorWords.singularTitleCase
              }
              value={
                isVendorPortal || isCollaboratorPortal ? (
                  vendorName
                ) : (
                  <Link
                    to={{
                      pathname: `${vendorUrlPrefix(
                        survey.vendorId,
                        isManagementAnalystSession,
                        managedOrgId
                      )}`,
                      state: {
                        backContext: {
                          backTo: history.location.pathname,
                          backToText: "Back to Questionnaire",
                        },
                      },
                    }}
                  >
                    {vendorName}
                  </Link>
                )
              }
            />
          )}
          {surveyImport ? (
            <ImportedSurveyCollaborators
              surveyID={survey.id}
              surveyImportUUID={surveyImport.uuid}
              assigneeUserIDs={surveyImport.assigneeUserIDs}
            />
          ) : (
            <InfoTableRow
              label="Sent to"
              rowClass="sent-to-list"
              value={
                <>
                  <div
                    className={`shared-with-list ${
                      !survey.deletedAt &&
                      (isVendorPortal || userHasWritePermission)
                        ? "clickable"
                        : ""
                    }`}
                    onClick={
                      !survey.deletedAt &&
                      (isVendorPortal || userHasWritePermission)
                        ? () =>
                            openSharedListModal({
                              dispatch,
                              surveyId: survey.id,
                              currentUserId,
                              sharedWith: survey.sharedWith || [],
                              openInvites: survey.openInvites || [],
                              fromCompany: survey.fromCompany,
                              isReadOnly: false,
                              openInviteCollaboratorModal: openShareSurveyModal,
                            })
                        : undefined
                    }
                  >
                    {(survey.sharedWith || []).map((u) => (
                      <UserAvatarAndName
                        key={u.id}
                        avatar={u.avatar}
                        name={u.name}
                        email={u.email}
                      />
                    ))}
                    {(survey.openInvites || []).map((i) => (
                      <UserAvatarAndName
                        key={i.recipientEmail}
                        avatar=""
                        name={i.recipientEmail}
                        email={"(invited)"}
                      />
                    ))}
                  </div>
                  {survey.sharable &&
                    !isCollaboratorPortal &&
                    (!survey.isAnalystWorkflowSurvey ||
                      isManagementAnalystSession) && (
                      <Button tertiary onClick={openShareSurveyModal}>
                        {isVendorPortal
                          ? "+ Add collaborator"
                          : "+ Add recipient"}
                      </Button>
                    )}
                </>
              }
            />
          )}
        </InfoTable>
      </ReportCard>
      {modifyResendDateModal}
      {sharedListModal}
      {reminderModal}
      {addCollaboratorModal}
      {collaboratorListModal}
    </>
  );
};

export default memo(SurveyDetailsOverviewCard);
