import "./AppApprovalModal.scss";
import ModalV2, { BaseModalProps } from "../../_common/components/ModalV2";
import { App, AppStatus } from "../api/types";
import { useState } from "react";
import Button from "../../_common/components/core/Button";
import userbaseApi, { UpdateReviewedAppV1Req } from "../api/userbase.api";
import LoadingIcon from "../../_common/components/core/LoadingIcon";
import ColorCheckbox from "../../vendorrisk/components/ColorCheckbox";
import { produceArrayWithFind } from "../../_common/helpers/array.helpers";
import TextField, { MaxLengthType } from "../../_common/components/TextField";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { useAppDispatch } from "../../_common/types/reduxHooks";
import { pluralise } from "../../_common/helpers";
import { useDefaultHistory } from "../../_common/types/router";
import { appUsersUrl } from "../UserBaseAppRouter";
import InfoBanner, { BannerType } from "../../vendorrisk/components/InfoBanner";

export enum AppApprovalModalMode {
  ApproveApp = "approve_app",
  DoNotApprovedApp = "do_not_approve_app",
}

interface IAppApprovalModalProps extends BaseModalProps {
  app: App;
  modalMode: AppApprovalModalMode;
}

const AppApprovalModal = ({
  active,
  onClose,
  app,
  modalMode,
}: IAppApprovalModalProps) => {
  const history = useDefaultHistory();
  const dispatch = useAppDispatch();

  const [hasChanges, setHasChanges] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isApprovedForAll, setIsApprovedForAll] = useState(app.approvedForAll);
  const [isApprovedForTeams, setIsApprovedForTeams] = useState(
    app.approvedForTeams.length > 0
  );
  const [approvedTeams, setApprovedTeams] = useState(
    app.approvedForTeams.map((t) => t.name)
  );
  const [note, setNote] = useState("");

  const { data, isLoading } = userbaseApi.useGetUserBaseTeamsV1Query();

  const [updateReviewedAppV1] =
    userbaseApi.useUpdateUserBaseReviewedAppV1Mutation();

  const onApproveForAllClick = () => {
    if (!isApprovedForAll) setHasChanges(true);
    setIsApprovedForAll(true);
    setIsApprovedForTeams(false);
    setApprovedTeams([]);
  };
  const onApproveForTeamsClick = () => {
    if (!isApprovedForTeams) setHasChanges(true);
    setIsApprovedForAll(false);
    setIsApprovedForTeams(true);
  };
  const onApproveForTeamClick = (teamName: string) => {
    setHasChanges(true);
    setApprovedTeams((prev) =>
      produceArrayWithFind(
        prev,
        teamName,
        (item, arrayItem) =>
          item.toLocaleLowerCase() == arrayItem.toLocaleLowerCase()
      )
    );
  };
  const onNoteChange = (value: string) => {
    setHasChanges(true);
    setNote(value);
  };

  const onImpactedUsersClick = () => {
    history.push(appUsersUrl(app.name), {
      backContext: {
        backTo: "/userbase/applications",
        backToText: "Back to Applications",
      },
    });
  };

  const onSubmit = () => {
    setIsUpdating(true);
    let updateReq: UpdateReviewedAppV1Req;
    switch (modalMode) {
      case AppApprovalModalMode.ApproveApp:
        updateReq = {
          appName: app.name,
          status: AppStatus.ApprovedAppStatus,
          approvedForAll: isApprovedForAll,
          approvedForTeams: approvedTeams,
          approvedForRoles: [],
          note: note,
        };
        break;
      case AppApprovalModalMode.DoNotApprovedApp:
        updateReq = {
          appName: app.name,
          status: AppStatus.NotApprovedAppStatus,
          approvedForAll: false,
          approvedForTeams: [],
          approvedForRoles: [],
          note: note,
        };
        break;
    }

    updateReviewedAppV1(updateReq)
      .unwrap()
      .then(() => {
        let successMsg: string;
        switch (modalMode) {
          case AppApprovalModalMode.ApproveApp:
            successMsg = isApprovedForAll
              ? `${app.name} is approved for all`
              : `${app.name} is approved for specific teams`;
            break;
          case AppApprovalModalMode.DoNotApprovedApp:
            successMsg = `${app.name} is not approved`;
            break;
        }
        dispatch(addDefaultSuccessAlert(successMsg));
      })
      .catch(() =>
        dispatch(
          addDefaultUnknownErrorAlert("Error updating application approval")
        )
      )
      .finally(() => {
        setIsUpdating(false);
        onClose();
      });
  };

  const isValidApprovalState =
    (isApprovedForAll && approvedTeams.length === 0) ||
    (!isApprovedForAll && approvedTeams.length > 0);
  const canSubmit =
    // not approval doesn't need required info so it's always possible to submit
    modalMode === AppApprovalModalMode.DoNotApprovedApp ||
    (hasChanges && isValidApprovalState);

  return (
    <>
      <ModalV2
        active={active}
        onClose={onClose}
        className={"app-approval-modal"}
        headerContent={
          modalMode === AppApprovalModalMode.ApproveApp
            ? `Approve access for ${app.name}`
            : `Do not approve access for ${app.name}`
        }
        footerClassName={"app-approval-modal-footer"}
        footerContent={
          <>
            <Button tertiary onClick={onClose}>
              Cancel
            </Button>
            <Button
              filledPrimary
              onClick={onSubmit}
              loading={isUpdating}
              disabled={!canSubmit}
            >
              Update access
            </Button>
          </>
        }
      >
        <div className={"description"}>
          {modalMode === AppApprovalModalMode.ApproveApp ? (
            <p>
              Applications can be approved for all users, or for selected teams
              in your organization.
            </p>
          ) : (
            <p>
              Setting an application as not approved means no one in your
              organization should be using this application.
            </p>
          )}
        </div>
        {isLoading && <LoadingIcon />}
        {!isLoading && (
          <>
            {modalMode === AppApprovalModalMode.ApproveApp && (
              <div className={"approve"}>
                <div className={"header"}>Select approval type:</div>
                <div className={"options"}>
                  <ColorCheckbox
                    radio
                    label="Approve for all"
                    onClick={() => onApproveForAllClick()}
                    checked={isApprovedForAll}
                  />
                  <ColorCheckbox
                    radio
                    label="Approve for specific teams"
                    onClick={() => onApproveForTeamsClick()}
                    checked={isApprovedForTeams}
                  />
                  <div className={"teams"}>
                    {data?.teams.map((t) => (
                      <ColorCheckbox
                        key={t.name}
                        label={t.name}
                        disabled={!isApprovedForTeams}
                        className={"team"}
                        onClick={() => onApproveForTeamClick(t.name)}
                        checked={
                          !!approvedTeams.find(
                            (approvedTeam) =>
                              approvedTeam.toLocaleLowerCase() ===
                              t.name.toLocaleLowerCase()
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
            )}
            {modalMode === AppApprovalModalMode.DoNotApprovedApp && (
              <>
                <InfoBanner
                  className={"do-not-approve"}
                  type={BannerType.WARNING}
                  message={
                    <>
                      This impacts{" "}
                      <span
                        className={"impacted"}
                        onClick={onImpactedUsersClick}
                      >
                        {app.numCurrentUsers}{" "}
                        {pluralise(app.numCurrentUsers, "user", "users")}
                      </span>
                      {` who currently ${pluralise(
                        app.numCurrentUsers,
                        "has",
                        "have"
                      )} access to this app`}
                    </>
                  }
                />
              </>
            )}
            <div className={"note"}>
              <div>
                <div className={"header"}>Add note (optional)</div>
                <div className={"sub-header"}>
                  This will appear in the timeline log
                </div>
              </div>

              <TextField
                value={note ?? ""}
                onChanged={(val) => onNoteChange(val)}
                multiLine
                placeholder="Enter a note"
                maxLength={MaxLengthType.reasonableLength}
              />
            </div>
          </>
        )}
      </ModalV2>
    </>
  );
};

export default AppApprovalModal;
