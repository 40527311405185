import React, { useState, useMemo, useCallback } from "react";
import "../style/components/TourHighlight.scss";
import DismissablePopup, { PopupPosition } from "./DismissablePopup";
import { useAppSelector } from "../types/reduxHooks";
import {
  OnboardingTaskMap,
  PLGOnboardingChecklistContent,
} from "../../vendorrisk/constants/plgOnboardingChecklistContent";
import { isTaskIncomplete } from "../../vendorrisk/reducers/plgOnboardingChecklistActions";

type TourHighlightProps = {
  tourHighlightId: string;
  tooltipHeader: string;
  tooltipText: string;
  stepNum: number;
  stepTotal: number;
  visible: boolean;
  position?: PopupPosition;
  children: React.ReactNode;
};

type TourHighlightFromPLGContentProps = {
  onboardingTaskId: string;
  highlightIndex: number;
  visible: boolean;
  position?: PopupPosition;
  children: React.ReactNode;
};

const StepDots = (props: {
  tooltipHeader: string;
  stepNum: number;
  stepTotal: number;
}) => {
  if (!props.stepTotal) {
    return <></>;
  }
  if (props.stepTotal < 2) {
    return <></>;
  }
  return (
    <div className={"progress-dots"}>
      {Array.from({ length: props.stepTotal }, (_, i) => (
        <span key={props.tooltipHeader + i.toString()}>
          {i == props.stepNum - 1 ? (
            <div className={"progress-dot active-dot"} />
          ) : (
            <div className={"progress-dot inactive-dot"} />
          )}
        </span>
      ))}
    </div>
  );
};

export const TourHighlightFromPLGContent = ({
  onboardingTaskId,
  highlightIndex,
  visible,
  position,
  children,
}: TourHighlightFromPLGContentProps) => {
  const plgOnboarding = useAppSelector(
    (state) => state.common.userData.plgOnboarding
  );

  function getTask(taskId: string) {
    const p = PLGOnboardingChecklistContent;
    for (let i = 0; i < p.length; i++) {
      for (let taskIdx = 0; taskIdx < p[i].tasks.length; taskIdx++) {
        if (p[i].tasks[taskIdx].id == taskId) {
          return p[i].tasks[taskIdx];
        }
      }
    }
    return {} as OnboardingTaskMap;
  }
  // let's return now to avoid extra work
  if (!visible) {
    return children;
  }

  // if the url we're on doesn't match the button url for the task at all, we bail
  //if (window.location.pathname != task.buttonUrl.split("?")[0]) {
  //  return children;
  //}

  let task = getTask(onboardingTaskId);
  const highlight = task.tourHighlights[highlightIndex];
  if (!!task.dependsOn) {
    // if this task depends on another and THAT one is incomplete, that becomes the task
    // this prevents tasks we can't/shouldn't complete yet from being highlighted
    if (isTaskIncomplete(task.dependsOn, plgOnboarding)) {
      task = getTask(task.dependsOn);
      if (!task) {
        return children;
      }
    }
  }
  if (visible && isTaskIncomplete(task.id, plgOnboarding)) {
    return (
      <TourHighlight
        tourHighlightId={highlight.id}
        tooltipHeader={highlight.tooltipHeader}
        tooltipText={highlight.tooltipText}
        visible={visible}
        stepNum={highlightIndex + 1}
        stepTotal={task.tourHighlights.length}
        position={position}
      >
        {children}
      </TourHighlight>
    );
  }
  return children;
};

export const TourHighlight = ({
  tourHighlightId,
  tooltipHeader,
  tooltipText,
  stepNum,
  stepTotal,
  visible,
  position,
  children,
}: TourHighlightProps) => {
  const [urlArgs, setUrlArgs] = useState(
    new URLSearchParams(window.location.search)
  );
  const [visibility, setVisibility] = useState(visible);

  function getTourArgs() {
    const freshArgs = new URLSearchParams(window.location.search);
    if (!freshArgs.has("th")) {
      return [];
    }
    const valString = freshArgs.get("th");
    if (!valString) {
      return [];
    }
    return valString.split("|");
  }

  const amIRendering = useMemo(() => {
    // if the condition in visibility is not met, we are not rendering
    if (!visibility) {
      return false;
    }

    const tourArgs = getTourArgs();

    // if there are multiple tour highlight url args,
    // only render if we're the first one.
    // this prevents multiple tooltips from popping at the same time on pages
    // where multiple actions need to be performed
    if (tourArgs.length > 0) {
      if (tourArgs[0] != tourHighlightId) {
        return false;
      }
    }

    if (stepNum == 1 && tourArgs.length == 0) {
      // all step 1 tasks should have an arg in them
      return false;
    }
    if (tourArgs.length > 0) {
      if (tourArgs.indexOf(tourHighlightId) == -1) {
        return false;
      }
    }
    // okay we're rendering
    return true;
  }, [visibility, tourHighlightId, stepNum, children]);

  const removeTourArg = useCallback(() => {
    const argArray = getTourArgs();
    argArray.splice(argArray.indexOf(tourHighlightId), 1);

    const newUrlArgs = urlArgs;
    if (argArray.length > 0) {
      newUrlArgs.set("th", argArray.join());
    } else {
      newUrlArgs.delete("th");
    }
    setUrlArgs(newUrlArgs);
    setVisibility(false);

    const url = new URL(window.location.href);
    if (argArray.length > 0) {
      url.searchParams.set("th", argArray.join());
    }
    window.history.pushState({}, "", url.href);
  }, [tourHighlightId, urlArgs]);

  // if we don't have any business being here let's bail

  const tooltipContent = (
    <>
      {tooltipText}{" "}
      <StepDots
        tooltipHeader={tooltipHeader}
        stepNum={stepNum}
        stepTotal={stepTotal}
      />
    </>
  );

  if (amIRendering) {
    return (
      <div className={"tour-highlight"}>
        <DismissablePopup
          popupClassName={"tour-highlight popup-" + position}
          text={tooltipContent}
          className={"tour-highlight"}
          title={tooltipHeader}
          popupStyle={"dark"}
          position={position}
          inline={false}
          width={"300px"}
          onDismiss={removeTourArg}
        >
          <div className={"highlight-outline"}>{children}</div>
        </DismissablePopup>
      </div>
    );
  }
  return children;
};
