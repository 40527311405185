import { RiskDetail } from "../../../_common/types/vendor";
import {
  isFullyCoveredByAdjustments,
  isFullyCoveredByWaivers,
  WaivedAssets,
  WaivedRiskAssetsMap,
} from "../../reducers/risks.actions";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { factCategories, LogError } from "../../../_common/helpers";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { FC, ReactText, useEffect, useState } from "react";
import RiskHostnameSelector, {
  RiskHostnameSelection,
} from "./RiskHostnameSelector";
import { sortBy as _sortBy } from "lodash";
import QuestionnaireSelection from "./QuestionnaireSelection";
import { VendorSummaryRiskType } from "../../../_common/types/vendorSummary";
import "../../style/components/risk_waivers/SelectRiskTable.scss";
import { SeverityAsString } from "../../../_common/types/severity";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import PillLabel from "../PillLabel";
import { Filters } from "../filter/types";
import RepoRiskAssetSelector, {
  RepoRiskAssetSelection,
} from "./RepoRiskAssetSelector";
import ManifestRiskAssetSelector, {
  ManifestRiskAssetSelection,
} from "./ManifestRiskAssetSelector";
import BreachSightDomainSelection from "./BreachSightDomainSelection";
import { SaaSRiskAssetSelection } from "../../../userbase/views/CreateRiskModificationView";

export interface AssetSelection {
  hostnameSelection: RiskHostnameSelection;
  allQuestionnairesSelected: boolean; // NOTE: No longer supported but we have old waivers with this value
  selectedQuestionnaires: number[];
  selectedPublicQuestionnaires: number[];
  repositorySelection?: RepoRiskAssetSelection;
  manifestSelection?: ManifestRiskAssetSelection;
  saasSelection?: SaaSRiskAssetSelection;
}

export const isAssetSelectionValid = (
  risk: RiskDetail,
  sel: AssetSelection
) => {
  switch (risk.riskType) {
    case VendorSummaryRiskType.Cloudscan:
      return (
        sel.hostnameSelection.selected.length > 0 ||
        sel.hostnameSelection.includeFuture
      );
    case VendorSummaryRiskType.Survey:
      return (
        sel.selectedQuestionnaires.length > 0 ||
        sel.selectedPublicQuestionnaires.length > 0
      );
    default:
      return true;
  }
};

export const getBlankAssetSelection = (): AssetSelection => {
  return {
    hostnameSelection: {
      allSelected: false,
      includeFuture: false,
      selected: [],
      customDomains: [],
    },
    allQuestionnairesSelected: false,
    selectedQuestionnaires: [],
    selectedPublicQuestionnaires: [],
  };
};

export interface ISelectRiskTableProps {
  isCustomer?: boolean;
  isLoading?: boolean;
  risks?: RiskDetail[];
  selectedRisk?: RiskDetail;
  selection: AssetSelection;
  onSelectRisk?: (risk: RiskDetail, selection: AssetSelection) => void;
  readonly?: boolean;
  isVendorManagementAnalyst?: boolean;
  managedOrgId?: number;
  datastoreVendorId?: number;
  waivedRiskAssets: WaivedRiskAssetsMap;
  hideRiskSelection?: boolean;
  waiverType: WaiverType;
  orgSupportsDomainPortfolios?: boolean;
  editableDomainPortfolioIds?: number[];
  filters?: Filters;
  initiallyExpandedRiskId?: string;
}

const SelectRiskTable = (props: ISelectRiskTableProps) => {
  const [sortDir, setSortDir] = useState(SortDirection.DESC);
  const [sortCol, setSortCol] = useState("sev");
  const [expandedRowId, setExpandedRowId] = useState(
    props.initiallyExpandedRiskId ?? ""
  );

  const onExpandToggle = (rowId: string | number) => {
    if (expandedRowId === rowId) {
      setExpandedRowId("");
    } else {
      setExpandedRowId(rowId as string);
    }
  };

  useEffect(() => {
    if (props.initiallyExpandedRiskId) {
      setExpandedRowId(props.initiallyExpandedRiskId);
    }
  }, [props.initiallyExpandedRiskId]);

  const riskSelected = (riskId: ReactText, expandDisabled: boolean) => {
    const risk = (props.risks ?? []).find((r) => r.id === riskId);

    if (props.onSelectRisk && risk && props.selectedRisk?.id !== risk.id) {
      props.onSelectRisk(risk, {
        hostnameSelection: {
          allSelected: false,
          includeFuture: false,
          selected: [],
          customDomains: [],
        },
        allQuestionnairesSelected: false,
        selectedQuestionnaires: risk.surveys
          ? risk.surveys
              .filter((s) => !s.publicSurvey && !s.isWaived)
              .map((s) => s.surveyId)
          : [],
        selectedPublicQuestionnaires: risk.surveys
          ? risk.surveys
              .filter((s) => s.publicSurvey && !s.isWaived)
              .map((s) => s.surveyId)
          : [],
      });

      if (!expandDisabled) {
        setExpandedRowId(risk.id);
      }
    } else if (risk && props.selectedRisk?.id === risk.id) {
      onExpandToggle(risk.id);
    }
  };

  const getRows = (): IXTableRow[] => {
    const rows: IXTableRow[] = [];

    let sortedRisks = props.risks;

    switch (sortCol) {
      case "risk":
        sortedRisks = _sortBy(sortedRisks, (r) => r.title);
        break;
      default:
        sortedRisks = _sortBy(sortedRisks, (r) => r.severity);
        break;
    }

    if (sortDir === SortDirection.DESC) {
      sortedRisks.reverse();
    }

    sortedRisks.forEach((risk) => {
      let assetCountDisplay: JSX.Element;
      let riskExpandAdditionalDisplay: React.ReactNode = undefined;
      let expandDisabled = false;
      const expanded = expandedRowId === risk.id;

      const isSelected = risk.id === props.selectedRisk?.id;
      let allowAssetSelection = false;

      switch (risk.factCategory) {
        case factCategories.AdditionalEvidenceRisks:
          assetCountDisplay = (
            <div className={"asset-display"}>
              {getRiskAssetText(
                risk,
                isSelected,
                props.selection,
                props.waivedRiskAssets
              )}
            </div>
          );
          expandDisabled = true;
          break;
        default:
          assetCountDisplay = (
            <div className={"asset-display"}>
              {getRiskAssetText(
                risk,
                isSelected,
                props.selection,
                props.waivedRiskAssets
              )}
            </div>
          );

          if (expanded) {
            allowAssetSelection = true;
          }
      }

      const waivedAssets = props.waivedRiskAssets[risk.id] ?? {};
      const isFullyCovered =
        props.waiverType === WaiverType.SeverityAdjustment
          ? isFullyCoveredByAdjustments(risk, waivedAssets)
          : isFullyCoveredByWaivers(risk, waivedAssets);
      const pendingWaiverTypeText =
        props.waiverType === WaiverType.SeverityAdjustment
          ? "adjustment"
          : "waiver";

      if (allowAssetSelection) {
        riskExpandAdditionalDisplay = (
          <RiskAssetSelectionForWaiver
            isCustomer={props.isCustomer}
            orgSupportsDomainPortfolios={props.orgSupportsDomainPortfolios}
            editableDomainPortfolioIds={props.editableDomainPortfolioIds ?? []}
            vendorId={props.datastoreVendorId}
            risk={risk}
            selection={props.selection}
            selected={isSelected}
            onSelectionChange={(sel) => {
              if (props.onSelectRisk && props.selectedRisk) {
                props.onSelectRisk(props.selectedRisk, sel);
              }
            }}
            readonly={props.readonly || isFullyCovered || false}
            isVendorManagementAnalyst={props.isVendorManagementAnalyst}
            managedOrgId={props.managedOrgId}
          />
        );
      }

      let riskDesc = risk.description;
      if (!riskDesc) {
        riskDesc =
          risk.riskType === VendorSummaryRiskType.Evidence
            ? "This risk was raised as a result of reviewing additional evidence."
            : "No description provided for this risk.";
      }

      const newRow: IXTableRow = {
        id: risk.id,
        selected: !props.readonly && isSelected && !props.hideRiskSelection,
        onClick:
          !props.readonly && !isFullyCovered
            ? () => riskSelected(risk.id, expandDisabled)
            : undefined,
        cells: [
          <XTableCell key="sev">
            <AdjustedSeverityIcon
              severity={SeverityAsString(risk.severity)}
              baseSeverity={
                risk.baseSeverity
                  ? SeverityAsString(risk.baseSeverity)
                  : undefined
              }
            />
          </XTableCell>,
          <XTableCell key="risk">
            <div className={"risk-name-cell"} data-risk-id={risk.id}>
              <div className={"risk-name-cell-title"}>{risk.title}</div>
              {risk.categoryTitle && (
                <div className={"risk-name-cell-desc"}>
                  {risk.categoryTitle}
                </div>
              )}
            </div>
          </XTableCell>,
          <XTableCell key="status">
            {isFullyCovered && (
              <PillLabel
                popupContent={`A pending risk ${pendingWaiverTypeText} already covers the assets with this risk`}
              >
                Pending {pendingWaiverTypeText}
              </PillLabel>
            )}
          </XTableCell>,
          <XTableCell key="related">{assetCountDisplay}</XTableCell>,
        ],
        expandDisabled,
        expanded,
        selectionDisabled: isFullyCovered,
        expandContent: (
          <div className={"risk-table-expand-content"}>
            <div className={"risk-description"}>
              <h4>Overview</h4>
              <div
                className={"risk-text"}
                dangerouslySetInnerHTML={{
                  __html: riskDesc,
                }}
              />
            </div>
            {riskExpandAdditionalDisplay}
          </div>
        ),
      };

      rows.push(newRow);
    });

    return rows;
  };

  return (
    <XTable
      className={"select-risk-table"}
      loading={props.isLoading}
      selectable={!props.readonly && !props.hideRiskSelection}
      hideColumnHeaders={props.readonly}
      radioSelector={true}
      onSelectClick={(rowId) => {
        riskSelected(
          rowId,
          (props.risks ?? []).find((r) => r.id === rowId)?.factCategory ===
            factCategories.AdditionalEvidenceRisks
        );
      }}
      columnHeaders={[
        {
          id: "sev",
          text: "Sev.",
          sortable: true,
          startingSortDir: SortDirection.DESC,
          className: "col-severity",
        },
        {
          id: "risk",
          text: "Risk",
          sortable: true,
          startingSortDir: SortDirection.ASC,
        },
        {
          id: "status",
          text: "",
        },
        {
          id: "related",
          text: "Related assets",
          sortable: false,
          className: "col-related-assets",
        },
      ]}
      rows={getRows()}
      sortedBy={{ columnId: sortCol, direction: sortDir }}
      onSortChange={(col, dir) => {
        setSortCol(col);
        setSortDir(dir);
      }}
      expandableRows={true}
      onExpandToggle={onExpandToggle}
    />
  );
};

export const getRiskAssetText = (
  risk: RiskDetail,
  isSelected: boolean,
  selection: AssetSelection,
  waivedRiskAssets: WaivedRiskAssetsMap
) => {
  const waivedRiskAssetsMap = waivedRiskAssets[risk.id]
    ? waivedRiskAssets[risk.id]
    : undefined;
  if (!isSelected) {
    return getAssetText(risk, undefined, waivedRiskAssetsMap);
  }

  switch (risk.factCategory) {
    case factCategories.AdditionalEvidenceRisks:
      return "All documents";
    case factCategories.QuestionnaireRisks:
      return selection.allQuestionnairesSelected
        ? "All questionnaires"
        : getAssetText(
            risk,
            selection.selectedQuestionnaires.length +
              selection.selectedPublicQuestionnaires.length,
            waivedRiskAssetsMap
          );
    case factCategories.AppguardPackageVulns:
      return getAssetText(
        risk,
        (selection.manifestSelection?.selectedUuids ?? []).length,
        waivedRiskAssetsMap
      );
    case factCategories.AppguardRepoConfig:
      return getAssetText(
        risk,
        (selection.repositorySelection?.selectedUuids ?? []).length,
        waivedRiskAssetsMap
      );
    default:
      return selection.hostnameSelection.allSelected
        ? "All domains & IPs"
        : getAssetText(
            risk,
            selection.hostnameSelection.selected.length,
            waivedRiskAssetsMap
          );
  }
};

const getAssetText = (
  risk: RiskDetail,
  numSelected?: number,
  waivedAssets?: WaivedAssets
) => {
  let assetText = "";
  let plural = "";
  let count: number | undefined = 0;

  switch (risk.factCategory) {
    case factCategories.AdditionalEvidenceRisks:
      assetText = "document";
      plural = "documents";
      count = risk.additionalEvidences?.length;
      break;
    case factCategories.QuestionnaireRisks:
      assetText = "questionnaire";
      plural = "questionnaires";
      count =
        (risk.surveys?.length ?? 0) -
        (waivedAssets ? Object.keys(waivedAssets.assetsWaived).length : 0);
      break;
    case factCategories.AppguardPackageVulns:
      assetText = "manifest";
      plural = "manifests";
      count = risk.manifests?.length ?? 0;
      break;
    case factCategories.AppguardRepoConfig:
      assetText = "repository";
      plural = "repositories";
      count = risk.repositories?.length ?? 0;
      break;
    default:
      if (numSelected !== undefined) {
        return `${numSelected}/${
          risk.numFailedCloudscans -
          (waivedAssets ? Object.keys(waivedAssets.assetsWaived).length : 0)
        } ${risk.numFailedCloudscans === 1 ? "domain/IP" : "domains & IPs"}`;
      }

      return `${
        risk.numFailedCloudscans -
        (waivedAssets ? Object.keys(waivedAssets.assetsWaived).length : 0)
      } ${risk.numFailedCloudscans === 1 ? "domain/IP" : "domains & IPs"}`;
  }

  if (count === undefined) {
    return "";
  }

  let countText = `${count}`;
  if (numSelected !== undefined) {
    countText = `${numSelected} / ${count}`;
  }

  const assetWord = count === 0 || count > 1 ? plural : assetText;

  return `${countText} ${assetWord}`;
};

interface RiskAssetSelectionForWaiverProps {
  isCustomer?: boolean;
  orgSupportsDomainPortfolios?: boolean;
  editableDomainPortfolioIds?: number[];
  vendorId?: number;
  risk: RiskDetail;
  selection: AssetSelection;
  onSelectionChange?: (sel: AssetSelection) => void;
  readonly?: boolean;
  selected: boolean;
  isVendorManagementAnalyst?: boolean;
  managedOrgId?: number;
  skipWaiverId?: number;
}

export const RiskAssetSelectionForWaiver: FC<
  RiskAssetSelectionForWaiverProps
> = ({
  isCustomer,
  orgSupportsDomainPortfolios,
  editableDomainPortfolioIds,
  vendorId,
  risk,
  selection,
  onSelectionChange,
  readonly,
  selected,
  isVendorManagementAnalyst,
  managedOrgId,
  skipWaiverId,
}) => {
  const hostnameSelectionChanged = (
    hostnameSelection: RiskHostnameSelection
  ) => {
    if (onSelectionChange) {
      onSelectionChange({
        hostnameSelection,
        allQuestionnairesSelected: false,
        selectedQuestionnaires: [],
        selectedPublicQuestionnaires: [],
      });
    }
  };

  const questionnairesSelected = (
    questionnaires: (string | number)[],
    publicSurvey: boolean
  ) => {
    const privateQuestionnaires = !publicSurvey
      ? (questionnaires as number[])
      : selection.selectedQuestionnaires;
    const publicQuestionnaires = publicSurvey
      ? (questionnaires as number[])
      : selection.selectedPublicQuestionnaires;

    if (onSelectionChange) {
      onSelectionChange({
        hostnameSelection: {
          allSelected: false,
          includeFuture: false,
          selected: [],
          customDomains: [],
        },
        allQuestionnairesSelected: false,
        selectedQuestionnaires: privateQuestionnaires,
        selectedPublicQuestionnaires: publicQuestionnaires,
      });
    }
  };

  const reposSelected = (repoSelection: RepoRiskAssetSelection) => {
    if (onSelectionChange) {
      onSelectionChange({
        ...selection,
        repositorySelection: repoSelection,
      });
    }
  };

  const manifestsSelected = (manifestSelection: ManifestRiskAssetSelection) => {
    if (onSelectionChange) {
      onSelectionChange({
        ...selection,
        manifestSelection: manifestSelection,
      });
    }
  };

  if (isCustomer) {
    // BreachSight waiver
    switch (risk.riskType) {
      case VendorSummaryRiskType.Cloudscan:
        return (
          <BreachSightDomainSelection
            orgSupportsDomainPortfolios={orgSupportsDomainPortfolios}
            editableDomainPortfolioIds={editableDomainPortfolioIds ?? []}
            riskId={risk.id}
            selection={selection.hostnameSelection}
            onChange={hostnameSelectionChanged}
            skipWaiverId={skipWaiverId}
          />
        );
      case VendorSummaryRiskType.AppguardPackageVuln:
        return (
          <ManifestRiskAssetSelector
            manifests={risk.manifests ?? []}
            selection={
              selection.manifestSelection ?? {
                isAllSelected: false,
                selectedUuids: [],
              }
            }
            onChange={manifestsSelected}
          />
        );
      case VendorSummaryRiskType.AppguardRepoConfig:
        return (
          <RepoRiskAssetSelector
            repos={risk.repositories ?? []}
            selection={
              selection.repositorySelection ?? {
                isAllSelected: false,
                selectedUuids: [],
              }
            }
            onChange={reposSelected}
          />
        );
      default:
        LogError(
          `missing asset selection for BreachSight risk type: ${risk.riskType}`
        );
        return <></>;
    }
  } else {
    // Assume vendor risk waiver
    switch (risk.riskType) {
      case VendorSummaryRiskType.Survey:
        return (
          <div className={"questionnaire-selection-container"}>
            <h4>Questionnaires</h4>
            <QuestionnaireSelection
              readonly={!selected || readonly}
              questionnaires={risk.surveys || []}
              onQuestionnairesSelected={questionnairesSelected}
              selectedQuestionnaireIds={selection.selectedQuestionnaires || []}
              selectedPublicQuestionnaireIds={
                selection.selectedPublicQuestionnaires || []
              }
              showLinks
              datastoreVendorId={vendorId}
              managedOrgId={managedOrgId}
              isVendorManagementAnalyst={isVendorManagementAnalyst}
              showWaived={skipWaiverId !== undefined}
            />
          </div>
        );
      case VendorSummaryRiskType.Cloudscan:
        return (
          <RiskHostnameSelector
            riskId={risk.id}
            vendorId={vendorId ?? 0}
            selection={selection.hostnameSelection}
            onSelectionChanged={hostnameSelectionChanged}
            readonly={!selected || readonly}
            skipWaiverId={skipWaiverId}
          />
        );
      default:
        // Additional evidence doesn't currently allow specific asset selection
        return <></>;
    }
  }
};

export default SelectRiskTable;
