import { FC } from "react";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { usePagination, useSorting } from "../../_common/hooks";
import { UserApp } from "../api/types";
import CompanyLogo from "../../_common/components/CompanyLogo";
import SecurityRatingDisplay, { RatingSize } from "./SecurityRatingDisplay";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import UserApprovalPill, { userApprovalStatus } from "./UserApprovalPill";
import ReportCard from "../../_common/components/ReportCard";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import { useLocation } from "react-router-dom";

interface UserAppsTableProps {
  // Whether the data is loading
  isLoading: boolean;

  // The apps to show
  appsToShow: UserApp[];

  // Action to perform when an app is clicked
  onClick?: (app: UserApp) => void;

  // Allow for custom class names for the table, list and row
  className?: string;
}

/**
 * Table to display the apps for a user
 */
const UserAppsTable: FC<UserAppsTableProps> = ({
  isLoading,
  appsToShow,
  onClick,
  className,
}) => {
  const { pathname } = useLocation();

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "app_name",
      text: "App name",
      sortable: true,
    },
    {
      id: "security_rating",
      text: "Security rating",
      sortable: true,
    },
    {
      id: "user_exposure",
      text: "User exposure",
      sortable: true,
    },
    {
      id: "first_detected",
      text: "First detected",
      sortable: true,
    },
    {
      id: "user_status",
      text: "User Status",
      sortable: true,
    },
  ];

  const [sortedApps, sortedBy, onSortChange] = useSorting<
    UserApp,
    "app_name" | "security_rating" | "user_exposure" | "user_status"
  >(
    appsToShow,
    "app_name",
    SortDirection.ASC,
    {
      app_name: {
        orderFuncs: [(a) => a.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      security_rating: {
        orderFuncs: [(a) => a.securityScore],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      user_exposure: {
        orderFuncs: [(a) => a.highestScopeRiskLevel],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      user_status: {
        orderFuncs: [
          (a) => userApprovalStatus(a.userIsApproved, a.userIsWaived),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    pathname
  );

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedApps,
    10,
    undefined,
    pathname
  );

  const rows = pageItems.map((userApp: UserApp): IXTableRow<string> => {
    return {
      id: userApp.name,
      onClick: () => (onClick !== undefined ? onClick(userApp) : null),
      cells: [
        <XTableCell key={"appName"}>
          <CompanyLogo
            domain={userApp.domain}
            name={userApp.name}
            displayCategory={true}
            category={userApp.industry}
          />
        </XTableCell>,
        <XTableCell key={"rating"}>
          <SecurityRatingDisplay
            rating={userApp.securityScore}
            size={RatingSize.Small}
          />
        </XTableCell>,
        <XTableCell key={"userExposure"}>
          <ScopeRiskLevelDisplay riskLevel={userApp.highestScopeRiskLevel} />
        </XTableCell>,
        <XTableCell key="first_detected">
          <DateTimeFormat dateTime={userApp.firstDetected} dateOnly />
        </XTableCell>,
        <XTableCell key={"user_status"}>
          <UserApprovalPill
            approved={userApp.userIsApproved}
            waived={userApp.userIsWaived}
          />
        </XTableCell>,
      ],
    };
  });

  const showEmptyCard = !isLoading && appsToShow.length === 0;

  return (
    <div className={className}>
      <ReportCard newStyles>
        {showEmptyCard && (
          <EmptyCard text={"There are no applications for this user."} />
        )}

        {!showEmptyCard && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default UserAppsTable;
