export enum OnboardingStepName {
  SetFirstLastName = "SetFirstLastName",
  QuickStart = "QuickStart",
  BreachSight = "BreachSight",
  VendorRisk = "VendorRisk",
  TrustExchange = "TrustExchange",
  InviteUsersToOrg = "InviteUsersToOrg",
  AddSurveyToSharedProfile = "AddSurveyToSharedProfile",
  TrialAccountRevertedToFree = "TrialAccountRevertedToFree",
  TermsConditionsMar24 = "TermsConditionsMar24",
}

export interface SetFirstLastNameMeta {
  isVendor: boolean;
}

export interface InviteUsersToOrgMeta {
  orgName: string;
  orgPrimaryHostname: string;
}

export interface AddSurveyToSharedProfileMeta {
  surveyId: number;
  senderCompanyName: string;
}

export interface OnboardingStepsMeta {
  [OnboardingStepName.QuickStart]?: Record<string, never>;
  [OnboardingStepName.SetFirstLastName]?: SetFirstLastNameMeta;
  [OnboardingStepName.BreachSight]?: Record<string, never>;
  [OnboardingStepName.VendorRisk]?: Record<string, never>;
  [OnboardingStepName.TrustExchange]?: Record<string, never>;
  [OnboardingStepName.InviteUsersToOrg]?: InviteUsersToOrgMeta;
  [OnboardingStepName.AddSurveyToSharedProfile]?: AddSurveyToSharedProfileMeta;
  [OnboardingStepName.TrialAccountRevertedToFree]?: Record<string, never>;
  [OnboardingStepName.TermsConditionsMar24]?: Record<string, never>;
}

// Defines the steps for quick start, these onboarding steps can be shown at any time though the help menu
export const QuickStartSteps: OnboardingStepsMeta = {
  [OnboardingStepName.BreachSight]: {},
  [OnboardingStepName.VendorRisk]: {},
};
