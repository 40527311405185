import { useEffect } from "react";

// Set the tag for the page, defaulting to UpGuard
const useTitleTag = (title?: string) => {
  const pageTitle = title ? `${title} | UpGuard` : "UpGuard";

  useEffect(() => {
    window.document.title = pageTitle;
  }, [pageTitle]);
};

export default useTitleTag;

// Exporting a function to be used in non-functional components
export function PageTitle(props: { children: string }) {
  useTitleTag(props.children);
  return null;
}
