import { DefaultThunkDispatch } from "../../_common/types/redux";
import {
  gptAutofillAnswerForQuestion,
  GptAutofillSuggestion,
  GptSourceTypeString,
  GptUsedContext,
  markGptSuggestionAsUsed,
} from "../reducers/autofill.actions";
import { FC, useCallback, useState } from "react";

import Button from "../../_common/components/core/Button";
import { setAnswer, setRightPanelState } from "../reducers/actions";
import { surveyViewerRightPanelMode } from "../reducers/reducer";
import Icon from "../../_common/components/core/Icon";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import "../style/SurveyViewerGptAutofillPanel.scss";
import { formatDateAsLocal } from "../../_common/helpers";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { NodeSummaryAndNode } from "../surveyViewer.helpers";
import { separateQuestionNumberFromQuestion } from "../../_common/components/surveydetails/SurveyDetailsUnansweredQuestionsCard";
import { NodeType } from "../../survey_builder/types/types";
import { NodeTypeIconType } from "../../survey_builder/components/NodeTypeIcon";
import { trackEvent } from "../../_common/tracking";
import { appConnect, useAppDispatch } from "../../_common/types/reduxHooks";
import IconButton from "../../_common/components/IconButton";
import { downloadContentLibraryDocumentFile } from "../../contentlibrary/api/downloadDocument";

interface GptSourceBoxProps {
  context: GptUsedContext;
  onUseContext?: () => void;
}

export const GptSourceBox: FC<GptSourceBoxProps> = ({
  context,
  onUseContext,
}) => {
  // if the source of this is a survey we need to split the question and answer from the text
  let questionText = "";
  let answerText = context.displayText;
  if (
    context.sourceType != "additional_evidence" &&
    context.sourceType != "doc_repo_doc"
  ) {
    const parts = context.text.split("ANSWER:");
    if (parts.length == 2) {
      const questionParts = parts[0].split("QUESTION: ");
      if (questionParts.length == 2) {
        questionText = questionParts[1];
      }
      answerText = parts[1];
    }
  }

  const dispatch = useAppDispatch();
  const followDocLink = useCallback(() => {
    if (context.sourceType == "doc_repo_doc") {
      dispatch(downloadContentLibraryDocumentFile(context.documentUUID ?? ""));
    } else if (
      context.sourceType == "survey" ||
      context.sourceType == "public_survey"
    ) {
      window.open(`/surveys/${context.sourceID}`, "_blank");
    }
  }, [dispatch, context]);

  return (
    <div className={"gpt-source-box"}>
      <div className={"header"}>
        <div className={"title"}>{context.sourceName}</div>
        {context.sourceType != "external_survey" && (
          <IconButton
            className={"link"}
            icon={<i className={"cr-icon-external-link"} />}
            onClick={followDocLink}
          />
        )}
      </div>
      <div className={"sub"}>{GptSourceTypeString(context.sourceType)}</div>
      <div className={"sub"}>
        Last updated {formatDateAsLocal(context.sourceDate)}
        {context.sourceAuthor != "" && <>, {context.sourceAuthor}</>}
      </div>
      <div className={"context"}>
        {context.sourceType != "additional_evidence" &&
          context.sourceType != "doc_repo_doc" && (
            <div className={"question"}>
              <div className={"letter"}>Q</div>
              <div>{questionText}</div>
            </div>
          )}
        <div className={"question answer"}>
          {context.sourceType != "additional_evidence" &&
            context.sourceType != "doc_repo_doc" && (
              <div className={"letter"}>A</div>
            )}
          <div className={"the-answer"}>{answerText}</div>
        </div>
      </div>
      {onUseContext && (
        <Button onClick={onUseContext} tertiary className={"use-button"}>
          <i className={"cr-icon-edit-doc"} />
          Use this source
        </Button>
      )}
    </div>
  );
};

interface SurveyViewerGptAutofillPanelOwnProps {
  dispatch: DefaultThunkDispatch;
  questionId: string;
  nodeSummary: NodeSummaryAndNode;
  isManagementAnalystSession: boolean;
  managedOrgId?: number;
}

interface SurveyViewerGptAutofillPanelConnectedProps {
  suggestion?: GptAutofillSuggestion;
  isLocked: boolean;
}

type SurveyViewerGptAutofillPanelProps = SurveyViewerGptAutofillPanelOwnProps &
  SurveyViewerGptAutofillPanelConnectedProps;

const SurveyViewerGptAutofillPanel: FC<SurveyViewerGptAutofillPanelProps> = ({
  dispatch,
  questionId,
  suggestion,
  isLocked,
  nodeSummary,
}) => {
  let [questionNumber] = separateQuestionNumberFromQuestion(
    nodeSummary.titleOrMainText
  );

  if (!questionNumber) {
    questionNumber = nodeSummary.questionNumber;
  }

  // Check if we should hide the numbering for this question
  if (nodeSummary.skipNumbering) {
    questionNumber = "";
  } else if (nodeSummary.nodeType == NodeTypeIconType.Risk) {
    questionNumber = " / Risk";
  } else {
    questionNumber = " / " + questionNumber;
  }
  const [loading, setLoading] = useState<"accept" | "reject">();
  const acceptReject = useCallback(
    (accept: boolean) => {
      if (!suggestion || isLocked) {
        return;
      }

      setLoading(accept ? "accept" : "reject");
      trackEvent("AiAutofillSuggestionUsed", {
        action: accept ? "accept" : "reject",
      });
      if (accept) {
        dispatch(
          gptAutofillAnswerForQuestion(suggestion.questionID, suggestion.result)
        );
      }
      dispatch(markGptSuggestionAsUsed(suggestion?.id, accept))
        .catch(() =>
          dispatch(addDefaultUnknownErrorAlert("error accepting suggestion"))
        )
        .finally(() => {
          setLoading(undefined);
          dispatch(
            setRightPanelState(
              surveyViewerRightPanelMode.GptAutofillSuggestions,
              questionId,
              false
            )
          );
        });
    },
    [dispatch, questionId, suggestion]
  );

  const answerUsed = suggestion?.used || suggestion?.rejected;

  const onUseContext = useCallback(
    (ctx: GptUsedContext) => {
      if (!answerUsed) {
        acceptReject(false);
      }
      dispatch(setAnswer(nodeSummary.nodeId, ctx.displayText));

      trackEvent("AiAutofillContextUsed", {
        type: ctx.sourceType,
      });
    },
    [answerUsed]
  );

  return (
    <div className={"gpt-right-panel"}>
      <div className={"top-section"}>
        <div className={"gpt-panel-header"}>
          <div className={"question-title"}>
            <i className={"cr-icon-q-builder-radio"} />
            Question {nodeSummary.questionNumber}
          </div>
          <Button
            className={"close-button"}
            tertiary
            onClick={() =>
              dispatch(
                setRightPanelState(
                  surveyViewerRightPanelMode.GptAutofillSuggestions,
                  questionId,
                  false
                )
              )
            }
          >
            <Icon name="x" className="close" />
          </Button>
        </div>
        <div className={"question"}>
          <div className={"letter"}>Q</div>
          <div
            className={"main-text"}
            dangerouslySetInnerHTML={{ __html: nodeSummary.node.mainText }}
          />
        </div>
      </div>
      <div className={"autofill-gpt-panel"}>
        {!suggestion ? (
          <LoadingBanner />
        ) : (
          <>
            <div className={"suggestion-container"}>
              <div className={"suggestion"}>
                <div className={"suggestion-heading"}>
                  <i className={"cr-icon-stars"} />
                  <div className={"sub-title"}>suggestion</div>
                  {!answerUsed && (
                    <div className={"buttons"}>
                      <Button
                        loading={loading == "reject"}
                        disabled={loading == "accept" || isLocked}
                        danger
                        tight
                        onClick={() => acceptReject(false)}
                      >
                        <i className={"icon-x"} />
                        Reject
                      </Button>
                      <Button
                        loading={loading == "accept"}
                        disabled={loading == "reject" || isLocked}
                        green
                        tight
                        onClick={() => acceptReject(true)}
                      >
                        <i className={"cr-icon-check"} />
                        Accept
                      </Button>
                    </div>
                  )}
                </div>
                <div className={"suggestion-text"}>
                  {nodeSummary.node.type == NodeType.Select ? (
                    <ul>
                      {suggestion.result.map((s) => (
                        <li key={s} className={"suggestion-line"}>
                          {s}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <>
                      {suggestion.result.map((s) => (
                        <div key={s} className={"suggestion-line"}>
                          {s}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              {answerUsed && (
                <Button
                  onClick={() => acceptReject(true)}
                  tertiary
                  className={"use-button"}
                >
                  <i className={"cr-icon-edit-doc"} />
                  Use this answer
                </Button>
              )}
            </div>
            <div className={"sources"}>
              <div className={"sub-title"}>
                sources used ({suggestion.sources.length})
              </div>
              {suggestion.sources.map((source, i) => (
                <GptSourceBox
                  key={i}
                  context={source}
                  onUseContext={
                    nodeSummary.nodeType == NodeTypeIconType.InputText
                      ? () => onUseContext(source)
                      : undefined
                  }
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default appConnect<
  SurveyViewerGptAutofillPanelConnectedProps,
  never,
  SurveyViewerGptAutofillPanelOwnProps
>((state, ownProps) => {
  return {
    suggestion:
      state.surveyViewer.gptAutofill?.suggestions[ownProps.questionId],
    isLocked: state.surveyViewer.lock.isLocked,
  };
})(SurveyViewerGptAutofillPanel);
