import { FC, useState } from "react";
import Modal, { BaseModalProps } from "../../../_common/components/ModalV2";
import Button from "../../../_common/components/core/Button";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import TextField, {
  MaxLengthType,
  TextFieldData,
} from "../../../_common/components/TextField";
import { getDownloadNdaLink } from "./NdasButton";
import { addDefaultUnknownErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { updateAccessRequest } from "../../reducers/verifiedVendors.actions";
import InfoBanner, { BannerType } from "../InfoBanner";
import "../../style/components/shared_profile/NdaSignedModal.scss";

interface NdaSignedModalProps extends BaseModalProps, DefaultThunkDispatchProp {
  vendorId: number;
  vendorName: string;
  userEmailAddress: string;
  mustRequestAccess: boolean;
  signedNdaId: number;
}

const NdaSignedModal: FC<NdaSignedModalProps> = ({
  dispatch,
  active,
  onClose,
  vendorId,
  vendorName,
  userEmailAddress,
  mustRequestAccess,
  signedNdaId,
}) => {
  const [requestAccessMessage, setRequestAccessMessage] =
    useState<TextFieldData>({ value: "", isValid: true });
  const [isSaving, setIsSaving] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const downloadNda = () => {
    window.open(getDownloadNdaLink(signedNdaId), "_blank");
  };

  const updateMessage = () => {
    setIsSaving(true);
    dispatch(updateAccessRequest(vendorId, requestAccessMessage.value))
      .then(() => {
        setIsSaving(false);
        setIsMessageSent(true);
      })
      .catch(() => {
        setIsSaving(false);
        dispatch(addDefaultUnknownErrorAlert("Error sending message"));
      });
  };

  return (
    <Modal
      headerContent={`NDA for ${vendorName}`}
      className={"nda-signed-modal"}
      footerClassName={
        mustRequestAccess ? "nda-signed-modal-footer" : undefined
      }
      active={active}
      onClose={onClose}
      footerContent={
        mustRequestAccess ? (
          <>
            {!isMessageSent && (
              <div className={"btn-group"}>
                <Button
                  onClick={updateMessage}
                  disabled={
                    !requestAccessMessage.isValid ||
                    requestAccessMessage.value.length === 0
                  }
                  loading={isSaving}
                >
                  Send message
                </Button>
              </div>
            )}
            <div>
              <label>Leave an additional message for {vendorName}</label>
              {!isMessageSent && (
                <TextField
                  value={requestAccessMessage.value}
                  onChanged={(val, valid) =>
                    setRequestAccessMessage({ value: val, isValid: valid })
                  }
                  multiLine
                  maxLength={MaxLengthType.message}
                  placeholder={"Provide an optional message here..."}
                />
              )}
              {isMessageSent && (
                <InfoBanner
                  type={BannerType.SUCCESS}
                  message={`Your message to ${vendorName} has been sent`}
                />
              )}
            </div>
          </>
        ) : undefined
      }
    >
      <p>
        Thank you for taking the time to read and accept the NDA for{" "}
        {vendorName}.
      </p>
      <p>
        A copy of the document has been emailed to your account email{" "}
        {userEmailAddress} for your records. You may also download and view your
        agreed NDA from {vendorName}’s Trust Page.
      </p>
      <div className={""}>
        <Button primary onClick={downloadNda}>
          <i className={"cr-icon-export"} />
          Download accepted NDA
        </Button>
      </div>
    </Modal>
  );
};

export default NdaSignedModal;
