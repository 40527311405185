import { DefaultAction } from "../types/redux";
import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../types/reduxHooks";

type HubspotWidgetToken = {
  token: string;
};

const fetchConversationToken = (): DefaultAction<
  HubspotWidgetToken | undefined
> => {
  return async (dispatch, getState) => {
    let token: HubspotWidgetToken;

    try {
      token = await FetchCyberRiskUrl(
        "plg/v1/hubspot/token",
        null,
        { method: "POST" },
        dispatch,
        getState
      );
      return token;
    } catch (e) {
      LogError("Error getting identification token", e);
      return undefined;
    }
  };
};

const HubspotWidgetWrapper = () => {
  // Check if the Hubspot script is currently loaded
  const isWidgetPresent = !!window.HubSpotConversations;
  const [widgetLoaded, setWidgetLoaded] = useState(false);
  const dispatch = useAppDispatch();

  const userEmail = useAppSelector(
    (state) => state.common.userData.emailAddress
  );

  const loadWidget = () => {
    if (!widgetLoaded) {
      window.HubSpotConversations.widget.load();
      setWidgetLoaded(true);
    }
  };

  const removeWidget = () => {
    if (widgetLoaded) {
      window.HubSpotConversations.widget.remove();
      setWidgetLoaded(false);
    }
  };

  useEffect(() => {
    if (window.HUBSPOT_APP_ID && isWidgetPresent) {
      dispatch(fetchConversationToken())
        .then((resp) => {
          if (!!resp) {
            window.hsConversationsSettings = {
              identificationEmail: userEmail,
              identificationToken: resp.token,
            };
            loadWidget();
          }
        })
        .catch((e) => {
          console.warn(
            "Error loading hubspot widget token. Widget is not active.",
            e
          );
          window.hsConversationsSettings = {
            loadImmediately: false,
          };
          removeWidget();
        });
    }
  }, [userEmail]);

  return <></>;
};

export default HubspotWidgetWrapper;
