import { Component } from "react";

import moment from "moment/moment";
import "../style/components/RiskVendorDomains.scss";
import {
  getRiskVendorWebsites,
  getVendorsFiltersHash,
} from "../reducers/vendorRiskPortfolio.actions";
import { fetchRiskVendorWebsites } from "../reducers/vendorRiskPortfolio.actions";
import PillLabel from "./PillLabel";
import XTable, {
  IIconOption,
  ISortedBy,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import {
  fetchVendorAssessmentRiskHostnames,
  getVendorAssessmentRiskHostnamesFromState,
} from "../reducers/vendorAssessment.actions";
import {
  getCustomerRiskHostnames,
  WaivedAssets,
} from "../reducers/risks.actions";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import {
  IRiskHostnameKeyData,
  RiskHostnameDetailMap,
} from "../../_common/types/risks";
import { LabelColor } from "../../_common/types/label";
import classnames from "classnames";
import { fetchCustomerRiskHostnames } from "../reducers/domains.actions";
import { WaiverType } from "../../_common/types/vendorRiskWaivers";
import { DropdownItem } from "../../_common/components/core/DropdownV2";
import { HoverLocation } from "../../_common/components/IconButton";
import { appConnect } from "../../_common/types/reduxHooks";

interface RiskVendorDomainsOwnProps {
  riskId: string;
  vendorId?: number;
  // riskHostnames may be passed in. If they are, we will use this instead of fetching from the API.
  // setting riskHostnames takes precedence over the other ways of getting risk hostnames
  riskHostnames?: IRiskHostnameKeyData | null;
  onClickDomain: (
    riskId: string,
    vendorId: number | undefined,
    hostname: string
  ) => void;
  allDomainsRemediationID?: number;
  domainsInRemediation?: { [key: string]: number[] };
  onRequestRemediation?: (hostname: string) => void;
  onCreateWaiver?: (hostname: string) => void;
  userHasWriteRiskWaiversPermission: boolean | undefined;
  editableDomainPortfolioIds?: any[];
  isPassive?: boolean;
  readonly?: boolean;
  passed?: boolean;
  isWaived: boolean;
  waivedAssets?: WaivedAssets;
  onViewWaivers?: (
    id?: number,
    isPublic?: boolean,
    waiverType?: WaiverType
  ) => void;
  onViewRemediations?: (id?: number) => void;
  isSubsidiary: boolean;
  forSpecificAssessment?: number;
  showWaived?: boolean;
  noDetectedDates?: boolean;
  hideWaivedOrAdjustedStatus?: boolean;
  orgAccessNewRiskDesigns?: boolean;
}

interface RiskVendorDomainsConnectedProps {
  loading: boolean;
  error?: any;
  websites:
    | {
        hostnamesWithRisk?: string[];
        riskDetailsByHostname?: RiskHostnameDetailMap;
      }
    | null
    | undefined;
}

type RiskVendorDomainsProps = RiskVendorDomainsOwnProps &
  RiskVendorDomainsConnectedProps &
  DefaultThunkDispatchProp;

interface RiskVendorDomainsState {
  currentPage: number;
  sortedBy: ISortedBy;
}

class RiskVendorDomains extends Component<
  RiskVendorDomainsProps,
  RiskVendorDomainsState
> {
  static defaultProps = {
    domainsInRemediation: {},
    isPassive: false,
    passed: false,
    isWaived: false,
    showWaived: false,
    noDetectedDates: false,
  };

  constructor(props: RiskVendorDomainsProps) {
    super(props);
    this.state = {
      currentPage: 1,
      sortedBy: props.orgAccessNewRiskDesigns
        ? {
            columnId: "detected",
            direction: SortDirection.DESC,
          }
        : {
            columnId: "domain",
            direction: SortDirection.ASC,
          },
    };
  }

  fetchRiskVendorWebsites = () => {
    if (this.props.forSpecificAssessment && this.props.vendorId) {
      this.props.dispatch(
        fetchVendorAssessmentRiskHostnames(
          this.props.vendorId,
          this.props.forSpecificAssessment,
          this.props.riskId
        )
      );
    } else if (this.props.vendorId) {
      this.props.dispatch(
        fetchRiskVendorWebsites(
          this.props.riskId,
          this.props.vendorId,
          this.props.isSubsidiary,
          true
        )
      );
    } else if (this.props.riskHostnames !== undefined) {
      // do nothing - riskHostnames were passed in as a prop
    } else {
      this.props.dispatch(
        fetchCustomerRiskHostnames(
          this.props.riskId,
          undefined,
          undefined,
          true
        )
      );
    }
  };

  componentDidMount() {
    this.fetchRiskVendorWebsites();
    this.setState({ currentPage: 1 });
  }

  componentDidUpdate(prevProps: RiskVendorDomainsProps) {
    // fetch if websites state is emptied
    if (prevProps.websites !== null && this.props.websites === null) {
      this.fetchRiskVendorWebsites();
    }
  }

  getRows = () => {
    const {
      websites,
      riskId,
      vendorId,
      onClickDomain,
      allDomainsRemediationID,
      domainsInRemediation,
      userHasWriteRiskWaiversPermission,
      editableDomainPortfolioIds,
      isPassive,
      readonly,
      waivedAssets,
      onViewWaivers,
      onViewRemediations,
    } = this.props;
    const rows: IXTableRow[] = [];

    if (websites && websites.hostnamesWithRisk) {
      const domains = websites.hostnamesWithRisk.slice();

      switch (this.state.sortedBy.columnId) {
        case "domain":
          domains.sort(function (a, b) {
            return a.localeCompare(b);
          });
          break;
        case "detected":
          domains.sort(function (a, b) {
            const aDate =
              websites.riskDetailsByHostname?.[a]?.dateDetected ?? "";
            const bDate =
              websites.riskDetailsByHostname?.[b]?.dateDetected ?? "";
            return aDate.localeCompare(bDate);
          });
          break;
        default:
          domains.sort(function (a, b) {
            return a.localeCompare(b);
          });
          break;
      }
      if (this.state.sortedBy.direction !== SortDirection.ASC) {
        domains.reverse();
      }

      domains.forEach((hostname) => {
        const dateDetected =
          websites.riskDetailsByHostname?.[hostname]?.dateDetected;

        let canWaiveThisDomain = userHasWriteRiskWaiversPermission && !readonly;
        let canRemediateThisDomain =
          !!this.props.onRequestRemediation && !readonly;

        if (
          editableDomainPortfolioIds &&
          editableDomainPortfolioIds.length > 0
        ) {
          const domainInEditablePortfolio = !!websites.riskDetailsByHostname?.[
            hostname
          ]?.portfolios?.find((p) => editableDomainPortfolioIds.includes(p.id));
          canWaiveThisDomain = domainInEditablePortfolio;
          canRemediateThisDomain = domainInEditablePortfolio;
        }

        const isInRemediation =
          !!domainsInRemediation?.[hostname] || !!allDomainsRemediationID;
        // We should only have one remediation request per hostname per risk.
        // Just in case, let's grab the one with the highest ID (ie most recent) and link through to that
        const latestRemediationRequestId =
          domainsInRemediation?.[hostname]?.sort((a, b) => {
            return b - a;
          })?.[0] || allDomainsRemediationID;

        const isWaivedId =
          waivedAssets?.assetsWaived[hostname] ??
          waivedAssets?.allDomainsWaiverId;
        const isPendingWaiverId =
          waivedAssets?.assetsPendingWaiver[hostname] ?? 0;
        const isPendingSharedWaiverId =
          waivedAssets?.assetsPendingSharedWaiver[hostname] ?? 0;
        const isWaived = this.props.isWaived || !!isWaivedId;

        if (!this.props.showWaived && isWaived) {
          return; // Skip waived assets if not showing waived risks
        }

        const cells = [];
        cells.push(
          <XTableCell
            key={"hostname"}
            className={classnames("domain-cell", {
              waived: isWaived && !this.props.hideWaivedOrAdjustedStatus,
            })}
            onClick={() => onClickDomain(riskId, vendorId, hostname)}
          >
            {hostname}
          </XTableCell>
        );

        if (!this.props.noDetectedDates) {
          cells.push(
            <XTableCell
              key={"detected"}
              className={classnames("detected-cell", {
                waived: isWaived && !this.props.hideWaivedOrAdjustedStatus,
              })}
            >
              {dateDetected
                ? moment(dateDetected).format("D MMM, YYYY")
                : undefined}
            </XTableCell>
          );
        }

        const rowDropdownItems: React.ReactNode[] = [];

        const canRemediate =
          canRemediateThisDomain &&
          !isInRemediation &&
          !readonly &&
          !isWaived &&
          !this.props.passed;

        if (canRemediate && this.props.onRequestRemediation) {
          const requestRemediation = () =>
            this.props.onRequestRemediation
              ? this.props.onRequestRemediation(hostname)
              : undefined;

          rowDropdownItems.push(
            <DropdownItem
              onClick={requestRemediation}
              key={"request-remediation"}
            >
              Request remediation
            </DropdownItem>
          );
        }

        const canWaive =
          canWaiveThisDomain &&
          !isWaived &&
          !isPendingWaiverId &&
          !isPendingSharedWaiverId &&
          !this.props.passed;

        if (canWaive && this.props.onCreateWaiver) {
          const waive = () =>
            this.props.onCreateWaiver
              ? this.props.onCreateWaiver(hostname)
              : undefined;

          rowDropdownItems.push(
            <DropdownItem onClick={waive} key={"waive-risk"}>
              Waive risk
            </DropdownItem>
          );
        }

        const iconOpts: IIconOption[] = [
          {
            id: `${hostname}-action`,
            icon: <i className={"cr-icon-dots-menu"} />,
            dropdownItems: rowDropdownItems,
            hoverText: "Manage this risk",
            hoverLocation: HoverLocation.Left,
          },
        ];

        if (!isPassive) {
          cells.push(
            <XTableCell key={"status"} className={"status-cell"}>
              {!this.props.passed && (
                <>
                  {isInRemediation && (
                    <PillLabel
                      color={LabelColor.Blue}
                      capitalized={false}
                      onClick={() => {
                        if (onViewRemediations) {
                          onViewRemediations(latestRemediationRequestId);
                        }
                      }}
                    >
                      In Remediation{" "}
                      {onViewRemediations && (
                        <i className={"cr-icon-chevron"} />
                      )}
                    </PillLabel>
                  )}
                  {isWaived && !this.props.hideWaivedOrAdjustedStatus && (
                    <PillLabel
                      color={LabelColor.Grey}
                      capitalized={false}
                      onClick={
                        onViewWaivers
                          ? () => onViewWaivers(isWaivedId)
                          : undefined
                      }
                    >
                      Waived{" "}
                      {onViewWaivers && <i className={"cr-icon-chevron"} />}
                    </PillLabel>
                  )}
                  {isPendingWaiverId > 0 &&
                    !this.props.hideWaivedOrAdjustedStatus && (
                      <PillLabel
                        color={LabelColor.Orange}
                        capitalized={false}
                        onClick={
                          onViewWaivers
                            ? () => onViewWaivers(isPendingWaiverId)
                            : undefined
                        }
                      >
                        Waiver Pending{" "}
                        {onViewWaivers && <i className={"cr-icon-chevron"} />}
                      </PillLabel>
                    )}
                  {isPendingSharedWaiverId > 0 &&
                    !this.props.hideWaivedOrAdjustedStatus && (
                      <PillLabel
                        color={LabelColor.Orange}
                        capitalized={false}
                        onClick={
                          onViewWaivers
                            ? () => onViewWaivers(isPendingSharedWaiverId, true)
                            : undefined
                        }
                      >
                        Shared Waiver Pending{" "}
                        {onViewWaivers && <i className={"cr-icon-chevron"} />}
                      </PillLabel>
                    )}
                </>
              )}
              {this.props.passed && !this.props.isWaived && (
                <PillLabel color={LabelColor.Green} capitalized={false}>
                  Passed
                </PillLabel>
              )}
              {this.props.passed &&
                this.props.isWaived &&
                !this.props.hideWaivedOrAdjustedStatus && (
                  <PillLabel color={LabelColor.Grey} capitalized={false}>
                    Waived
                  </PillLabel>
                )}
            </XTableCell>
          );
        }
        rows.push({
          id: hostname,
          cells: cells,
          iconOptions: rowDropdownItems.length > 0 ? iconOpts : undefined,
        });
      });
    }
    return rows;
  };

  onPageChange = (newPage: number) => {
    this.setState({ currentPage: newPage });
  };

  onSortChange = (columnId: string, direction: SortDirection) => {
    this.setState({
      sortedBy: {
        columnId: columnId,
        direction: direction,
      },
    });
  };

  render() {
    const pageSize = 10;
    const { loading, passed } = this.props;
    const rows = this.getRows();
    const paginatedRows =
      rows.length > pageSize
        ? rows.slice(
            (this.state.currentPage - 1) * pageSize,
            this.state.currentPage * pageSize
          )
        : rows;
    const columnHeaders = [];

    if (!this.props.orgAccessNewRiskDesigns) {
      if (!passed) {
        columnHeaders.push({
          id: "domain",
          text: "Domains & IPs triggering this risk",
          sortable: true,
          startingSortDir: SortDirection.ASC,
        });
      } else {
        columnHeaders.push({
          id: "domain",
          text: "Domains & IPs",
          sortable: true,
          startingSortDir: SortDirection.ASC,
        });
      }
    } else {
      columnHeaders.push({
        id: "assets",
        text: "Assets",
        sortable: true,
        startingSortDir: SortDirection.ASC,
      });
    }

    if (!this.props.noDetectedDates) {
      columnHeaders.push({
        id: "detected",
        text: "First detected",
        sortable: true,
        className: "detected-cell",
        startingSortDir: this.props.orgAccessNewRiskDesigns
          ? SortDirection.DESC
          : SortDirection.ASC,
      });
    }

    if (!this.props.isPassive) {
      columnHeaders.push({ id: "status", text: "Status", sortable: false });
    }

    return (
      <div
        className={classnames("domains-container", {
          "domains-container-new-risk-designs":
            this.props.orgAccessNewRiskDesigns,
        })}
      >
        <XTable
          stickyColumnHeaders={false}
          loading={loading}
          numLoadingRows={3}
          sortedBy={this.state.sortedBy}
          onSortChange={this.onSortChange}
          pagination={{
            currentPage: this.state.currentPage,
            totalPages: rows.length > 0 ? Math.ceil(rows.length / pageSize) : 1,
            hidePaginationIfSinglePage: true,
            onPageChange: this.onPageChange,
          }}
          columnHeaders={columnHeaders}
          rows={paginatedRows}
          iconOptionsHeader={
            this.props.orgAccessNewRiskDesigns && !this.props.readonly
              ? "Actions"
              : undefined
          }
          iconOptions
        />
      </div>
    );
  }
}

export default appConnect<
  RiskVendorDomainsConnectedProps,
  never,
  RiskVendorDomainsOwnProps
>(
  (
    state,
    { riskId, vendorId, isSubsidiary, forSpecificAssessment, riskHostnames }
  ) => {
    let toReturn: RiskVendorDomainsConnectedProps;
    // riskHostnames may be passed in. If they are, we will use this instead of fetching from the API.
    // riskHostnames === undefined means that we're using one of the other ways of getting risk hostnames
    if (riskHostnames !== undefined) {
      // riskHostnames === null means that the data is loading
      if (riskHostnames === null) {
        toReturn = { loading: true, websites: null };
      } else {
        toReturn = {
          loading: riskHostnames.loading,
          error: riskHostnames.error,
          websites: riskHostnames.data,
        };
      }
    } else if (forSpecificAssessment && vendorId) {
      const assessmentRiskHostnames = getVendorAssessmentRiskHostnamesFromState(
        state,
        vendorId,
        forSpecificAssessment,
        riskId
      );
      if (!assessmentRiskHostnames) {
        toReturn = { loading: true, websites: null };
      } else {
        toReturn = {
          loading: assessmentRiskHostnames.hostnamesSnapshotPending,
          websites: {
            hostnamesWithRisk: assessmentRiskHostnames.hostnamesFailed,
            riskDetailsByHostname: {},
          },
        };
      }
    } else if (vendorId) {
      const filtersHash = getVendorsFiltersHash(state, vendorId, isSubsidiary);

      const riskVendorWebsites = getRiskVendorWebsites(
        state,
        riskId,
        vendorId,
        isSubsidiary,
        true,
        undefined,
        filtersHash
      );

      toReturn = {
        loading: riskVendorWebsites.loading,
        error: riskVendorWebsites.error,
        websites: riskVendorWebsites.data,
      };
    } else {
      const customerData = getCustomerRiskHostnames(
        state,
        riskId,
        undefined,
        undefined,
        true
      );
      toReturn = {
        loading: customerData.loading,
        error: customerData.error,
        websites: customerData.data !== null ? customerData.data : null,
      };
    }

    return toReturn;
  }
)(RiskVendorDomains);
