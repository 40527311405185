import { FC } from "react";
import DropdownV2, { DropdownItem } from "./core/DropdownV2";
import Icon from "./core/Icon";
import Button from "./core/Button";
import { SidePopupV2 } from "./DismissablePopup";

import "../style/components/ActionsButton.scss";

interface IActionsButtonProps {
  actions: {
    text: string;
    onClick?: () => void;
    disabled?: boolean;
    popupText?: string;
  }[];
  disabled?: boolean;
}

const ActionsButton: FC<IActionsButtonProps> = ({
  actions,
  disabled = false,
}) => {
  return (
    <DropdownV2
      className="actions-btn"
      popupItem={
        <Button
          primary
          disabled={disabled || actions.length === 0}
          className="actions-button"
        >
          Actions <Icon name="chevron" direction={180} />
        </Button>
      }
      disabled={disabled || actions.length === 0}
      stopPropagation
      shouldBottomAlign
    >
      {actions.map((a) => (
        <SidePopupV2 key={a.text} text={a.popupText} noWrap position="left">
          <DropdownItem
            onClick={a.disabled || !a.onClick ? undefined : a.onClick}
            stopPropagation
            className={a.disabled || !a.onClick ? "disabled" : undefined}
          >
            {a.text}
          </DropdownItem>
        </SidePopupV2>
      ))}
    </DropdownV2>
  );
};

export default ActionsButton;
