import { DefaultAction } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { NodeType } from "../../survey_builder/types/types";
import {
  AnswersForNodes,
  findNodeByID,
  getAnswersForNodes,
  NodeSummaryAndNode,
  SelectAnswers,
} from "../surveyViewer.helpers";
import {
  setAnswer,
  setAnswers,
  setExcelSourceDoc,
  setGptAutofillCache,
  setGptSources,
  setLatestGptSuggestions,
  updateGptAcceptRejectSuggestion,
  updateGptAutofillCache,
  updateGptUserPreferences,
} from "./actions";
import {
  fetchPublicQuestionnaire,
  fetchQuestionnaire,
  QuestionnaireAndAnswers,
} from "../../vendorrisk/reducers/questionnaireAnswers.actions";
import {
  ExcelQnExtractorConfig,
  ExternalDocument,
  GptUserPreferences,
} from "../components/types/autofill.types";
import { ContentLibraryDocument } from "../../contentlibrary/types/contentLibrary.types";
import { IUserMiniMap } from "../../_common/types/user";

export interface SurveyIDWithPublic {
  surveyID: number;
  isPublic: boolean;
  name?: string;
  publishedAt?: string;
}

export const SurveyIDWithPublicKey = (id: SurveyIDWithPublic) =>
  `${id.isPublic ? "p" : "s"}:${id.surveyID}`;

type GptAutofillSourceType =
  | "survey"
  | "public_survey"
  | "external_survey"
  | "additional_evidence"
  | "doc_repo_doc";

export const GptSourceTypeString = (type: GptAutofillSourceType) => {
  switch (type) {
    case "survey":
      return "Past questionnaire";
    case "public_survey":
      return "Public questionnaire";
    case "external_survey":
      return "Uploaded questionnaire";
    case "additional_evidence":
      return "Additional evidence";
    case "doc_repo_doc":
      return "Content library";
  }
};

export interface GptSourceContext {
  id: number;
  sourceType: GptAutofillSourceType;
  sourceName: string;
  question: string;
  answer: string;
  similarity: number;
}

export interface GptUsedContext {
  text: string;
  displayText: string;
  similarity: number;
  sourceID: number;
  sourceType: GptAutofillSourceType;
  sourceName: string;
  sourceDate: string;
  sourceAuthor: string;
  documentID?: number;
  documentUUID?: string;
  virusScanned?: boolean;
  virusSafe?: boolean;
}

export interface GptAutofillSuggestion {
  id: number;
  cacheID: number;
  questionID: string;
  sources: GptUsedContext[];
  result: string[];
  rawResult: string;
  noSuggestion: boolean;
  used: boolean;
  rejected: boolean;
}

export type GptSuggestions = Record<string, GptAutofillSuggestion>;

export interface GptAutofillCacheStatus {
  jobStarted: boolean;
  jobFinished: boolean;
  cacheDirty: boolean;
  asyncJobID?: number;
  jobProgress: number;
  suggestions: GptSuggestions;
  overrideAnswers: boolean;
}

export const getAllAnswersFromGptSuggestions = (
  suggestions: GptSuggestions,
  nodeTree: NodeSummaryAndNode
): AnswersForNodes =>
  Object.values(suggestions).reduce((answers, suggestion) => {
    if (!suggestion.noSuggestion && !suggestion.used && !suggestion.rejected) {
      try {
        answers[suggestion.questionID] = getAnswerFromGptSuggestion(
          suggestion.questionID,
          suggestion.result,
          nodeTree
        );
      } catch {}
    }

    return answers;
  }, {} as AnswersForNodes);

// getAnswerFromGptSuggestion
// gets an answer from a GPT suggestion
// This should mirror the logic in gpt_auto_cache.go::GetAnswersFromSuggestions
export const getAnswerFromGptSuggestion = (
  questionId: string,
  answer: string[],
  nodeTree: NodeSummaryAndNode
): string | SelectAnswers => {
  const question = findNodeByID(nodeTree, questionId);
  if (!question) {
    throw new Error("could not find question");
  }

  let result: string | SelectAnswers;

  switch (question.node.type) {
    case NodeType.InputText:
    case NodeType.Risk:
      // Text questions are easy - just set the answer as is, separated by "---" if multiple answers
      result = answer.join("\n---\n");
      break;
    case NodeType.Select:
      if (question.node.radio && answer.length > 1) {
        throw new Error("multiple answers set for radio question");
      }

      const selectAnswers: SelectAnswers = {};
      const foundAnswers = [];

      for (let i = 0; i < question.node.answers.length; i++) {
        const opt = question.node.answers[i];

        if (answer.includes(opt.text)) {
          foundAnswers.push(opt.text);
          selectAnswers[opt.id] = "checked";
        } else {
          selectAnswers[opt.id] = "";
        }
      }

      // see if we can match a yes/no answer if we haven't matched anything else
      if (foundAnswers.length === 0) {
        for (let i = 0; i < question.node.answers.length; i++) {
          const opt = question.node.answers[i];
          if (
            (opt.text.toLowerCase().startsWith("yes") &&
              answer[0].toLowerCase().startsWith("yes")) ||
            (opt.text.toLowerCase().startsWith("no") &&
              answer[0].toLowerCase().startsWith("no"))
          ) {
            foundAnswers.push(opt.text);
            selectAnswers[opt.id] = "checked";
            break;
          }
        }
      }

      if (foundAnswers.length !== answer.length) {
        throw new Error("some answers not found as options on question");
      }

      result = selectAnswers;

      break;
    default:
      throw new Error(`unsupported question type: ${question.node.type}`);
  }

  return result;
};

// a lot like autofillAnswerForQuestion but for gpt autofill instead
export const gptAutofillAnswerForQuestion =
  (questionId: string, answer: string[]): DefaultAction =>
  async (dispatch, getState) => {
    const nodeTree = getState().surveyViewer.nodeTree;

    if (!nodeTree) {
      throw new Error("no nodeTree");
    }

    const answerToSet = getAnswerFromGptSuggestion(
      questionId,
      answer,
      nodeTree
    );

    dispatch(setAnswer(questionId, answerToSet, true));
  };

export interface UploadExternalDocV1Resp {
  document: ExternalDocument;
}

export const uploadExternalDoc =
  (file: File): DefaultAction<ExternalDocument> =>
  async (dispatch, getState) => {
    let json: UploadExternalDocV1Resp;

    try {
      const data = new FormData();
      data.append("file", file);
      json = await FetchCyberRiskUrl(
        "surveyresponse/doc/upload/v1",
        {},
        {
          body: data,
          method: "POST",
        },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error uploading external doc", e);
      throw e;
    }

    dispatch(setExcelSourceDoc(json.document));
    return json.document;
  };

export const deleteExternalDocument =
  (uuid: string): DefaultAction =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "surveyresponse/doc/v1",
        { uuid },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting external document", e);
      throw e;
    }
  };

interface extractExcelAnswersResp {
  document: ExternalDocument;
}

export const ExtractExcelAnswers =
  (extractorConfig: ExcelQnExtractorConfig[], uuid: string): DefaultAction =>
  async (dispatch, getState) => {
    let json: extractExcelAnswersResp;
    try {
      const body = {
        extractorConfig,
      };

      json = await FetchCyberRiskUrl(
        "surveyresponse/excel/config/v1",
        { uuid },
        { method: "POST", body: JSON.stringify(body) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error extracting excel answers", e);
      throw e;
    }

    dispatch(setExcelSourceDoc(json.document));
  };

interface pollGptDocumentExtractionV1Resp {
  done: boolean;
  document: ExternalDocument;
}

export const pollGptDocumentExtraction =
  (documentUUID: string): DefaultAction<boolean> =>
  async (dispatch, getState) => {
    let json: pollGptDocumentExtractionV1Resp;
    try {
      json = await FetchCyberRiskUrl(
        "surveyresponse/document/poll/v1",
        { uuid: documentUUID },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error polling document extraction", e);
      throw e;
    }

    dispatch(setExcelSourceDoc(json.document));
    return json.done;
  };

export interface GetGptAutofillSourcesResp {
  excelSources?: ExternalDocument[]; // TODO - rename this - can now include PDFs as well
  pdfSources?: ContentLibraryDocument[];
  platformSurveys?: SurveyIDWithPublic[];
  preferences: GptUserPreferences;
  users: IUserMiniMap;
  surveyUsers: Record<number, number>;
}

export const GetGptAutofillSources =
  (
    survey_id?: number,
    is_public?: boolean
  ): DefaultAction<ExternalDocument[]> =>
  async (dispatch, getState) => {
    let json: GetGptAutofillSourcesResp;
    const params: Record<string, any> = {};
    if (survey_id) {
      params["survey_id"] = survey_id;
    }
    if (is_public) {
      params["is_public"] = is_public;
    }

    try {
      json = await FetchCyberRiskUrl(
        "surveyresponse/sources/v1",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting autofill sources for survey", e);
      throw e;
    }

    dispatch(
      setGptSources(json.excelSources ?? [], json.platformSurveys ?? [])
    );
    dispatch(updateGptUserPreferences(json.preferences));
    return json.excelSources ?? [];
  };

interface GptAutofillBeginReq {
  surveyID: number;
  isPublic: boolean;
  sources: GptUserPreferences;
  overrideExistingAnswers: boolean;
}

interface GptAutofillBeginResp {
  asyncJobID: number;
  jobFinished: boolean;
  cacheDirty: boolean;
}

export const GptAutofillBegin =
  (
    surveyID: number,
    isPublic: boolean,
    platformSurveys: Record<string, boolean>,
    externalDocuments: Record<string, boolean>,
    overrideExistingAnswers: boolean
  ): DefaultAction =>
  async (dispatch, getState) => {
    let json: GptAutofillBeginResp;

    const body: GptAutofillBeginReq = {
      surveyID,
      isPublic,
      sources: {
        platformSurveys,
        externalDocuments,
        contentLibraryDocuments: {},
      },
      overrideExistingAnswers,
    };

    try {
      json = await FetchCyberRiskUrl(
        "surveyresponse/gpt/start/v1",
        {},
        { method: "POST", body: JSON.stringify(body) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error beginning gpt autofill", e);
      throw e;
    }

    // todo - this seems wrong, we might already have some state and be starting a new job
    dispatch(
      setGptAutofillCache({
        cacheDirty: json.jobFinished,
        jobStarted: true,
        jobFinished: json.jobFinished,
        jobProgress: 0.0,
        suggestions: {},
        asyncJobID: json.asyncJobID,
        overrideAnswers: overrideExistingAnswers,
      })
    );
  };

interface fetchGptAutofillStatusResp {
  suggestions?: Record<string, GptAutofillSuggestion>;
  asyncJobID?: number;
  overrideAnswers: boolean;
  jobStarted: boolean;
  jobFinished: boolean;
  cacheDirty: boolean;
  progress: number;
}

export const FetchGptAutofillStatus =
  (surveyID: number, isPublic: boolean, force = false): DefaultAction =>
  async (dispatch, getState) => {
    if (!force && getState().surveyViewer.gptAutofill) {
      return;
    }

    let json: fetchGptAutofillStatusResp;

    try {
      json = await FetchCyberRiskUrl(
        "surveyresponse/gpt/status/v1",
        { survey_id: surveyID, is_public: isPublic },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching gpt autofill status");
      throw e;
    }

    dispatch(
      setGptAutofillCache({
        suggestions: json.suggestions ?? {},
        asyncJobID: json.asyncJobID,
        jobStarted: json.jobStarted,
        jobProgress: json.progress,
        jobFinished: json.jobFinished,
        cacheDirty: json.cacheDirty,
        overrideAnswers: json.overrideAnswers,
      })
    );
  };

interface autofillPollSuggestions {
  newSuggestions?: Record<string, GptAutofillSuggestion>;
  progress: number;
  started: boolean;
  done: boolean;
}

export const pollGptAutofillSuggestions =
  (surveyID: number, isPublic: boolean): DefaultAction<boolean> =>
  async (dispatch, getState) => {
    let json: autofillPollSuggestions;
    try {
      json = await FetchCyberRiskUrl(
        "surveyresponse/gpt/poll/v1",
        { survey_id: surveyID, is_public: isPublic },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error polling for gpt suggestions", e);
      throw e;
    }

    if (!json.started) {
      return false;
    }

    dispatch(
      updateGptAutofillCache(
        json.newSuggestions ?? {},
        json.progress,
        json.done
      )
    );

    // also fill in our latest suggestions
    dispatch(
      setLatestGptSuggestions(
        Object.values(json.newSuggestions ?? {})
          .filter((s) => !s.noSuggestion)
          .map((s) => s.questionID)
      )
    );

    return json.done;
  };

interface getUserPreferencesV1Resp {
  preferences: GptUserPreferences;
}

export const fetchGptUserPreferences =
  (): DefaultAction<GptUserPreferences> => async (dispatch, getState) => {
    let json: getUserPreferencesV1Resp;
    try {
      json = await FetchCyberRiskUrl(
        "surveyresponse/gpt/prefs",
        {},
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error getting gpt user prefs", e);
      throw e;
    }

    dispatch(updateGptUserPreferences(json.preferences));
    return json.preferences;
  };

export const markGptSuggestionAsUsed =
  (suggestion_id: number, accept: boolean): DefaultAction =>
  async (dispatch, getState) => {
    // we want to update redux BEFORE calling the endpoint as this will get the suggestion box to be removed
    dispatch(updateGptAcceptRejectSuggestion(suggestion_id, accept));

    try {
      await FetchCyberRiskUrl(
        "surveyresponse/gpt/usesuggestion/v1",
        { suggestion_id, accept },
        { method: "PUT" },
        dispatch,
        getState
      );

      // if (accept) {
      //   const suggestion = Object.values(
      //     getState().surveyViewer.gptAutofill?.suggestions ?? {}
      //   ).find((s) => s.id == suggestion_id);
      //
      //   if (!suggestion) {
      //     throw new Error("error finding suggestion");
      //   }
      //
      //   await dispatch(
      //     gptAutofillAnswerForQuestion(suggestion.questionID, suggestion.result)
      //   );
      // }
    } catch (e) {
      LogError("error marking suggestion as used", e);
      throw e;
    }
  };

export const markAllGptSuggestionsAsUsed =
  (surveyID: number, isPublic: boolean): DefaultAction<AnswersForNodes> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "surveyresponse/gpt/acceptall/v1",
        { survey_id: surveyID, is_public: isPublic },
        { method: "PUT" },
        dispatch,
        getState
      );

      // fill in our new answers
      let surveyAndAnswers: QuestionnaireAndAnswers;
      if (isPublic) {
        surveyAndAnswers = await dispatch(
          fetchPublicQuestionnaire(surveyID, true)
        );
      } else {
        surveyAndAnswers = await dispatch(
          fetchQuestionnaire(surveyID, true, 0, true)
        );
      }

      const answersForNodes = getAnswersForNodes(
        surveyAndAnswers.answers,
        surveyAndAnswers.structure[0]
      );

      dispatch(setAnswers(answersForNodes));
      return answersForNodes;
    } catch (e) {
      LogError("error marking all suggestions as used", e);
      throw e;
    }
  };
