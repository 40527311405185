import { FC, useEffect } from "react";
import userbaseApi from "../api/userbase.api";
import { formatDateAsLocal, LogError } from "../../_common/helpers";
import { useHistory } from "react-router-dom";
import { riskProfileUrl } from "../UserBaseAppRouter";
import ReportCard from "../../_common/components/ReportCard";
import { SeverityAsString } from "../../_common/types/severity";
import { AdjustedSeverityIcon } from "../../_common/components/SeverityIcon";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import "./SaaSRiskWaiverUsersSelection.scss";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";
import { SaaSUser } from "../api/types";
import TeamsPills from "./TeamsPills";
import { calculateWaivedAssetsForRisks } from "../../vendorrisk/reducers/risks.actions";

const PAGE_SIZE = 50;

const NAME_COL = "name_col";
const TEAM_COL = "team_col";
const FIRST_DETECTED_COL = "first_detected_col";

interface SaaSRiskWaiverUsersSelectionProps {
  riskId: string;
  selectedUserUUIDs: string[];
  onSelectionChange: (selectedUUIDs: string[]) => void;
}

const SaaSRiskWaiverUsersSelection: FC<SaaSRiskWaiverUsersSelectionProps> = ({
  riskId,
  selectedUserUUIDs,
  onSelectionChange,
}) => {
  const history = useHistory();

  const { data: risksData, isLoading: risksLoading } =
    userbaseApi.useGetUserBaseRisksV1Query();
  const { data: riskUsers, isLoading: riskUsersLoading } =
    userbaseApi.useGetUserBaseRiskUsersV1Query({
      riskId,
    });
  const { data: riskWaivers } = userbaseApi.useGetUserBaseRiskWaiversV1Query();

  const columHeaders: IXTableColumnHeader[] = [
    { id: NAME_COL, text: "Name", sortable: true, className: "col-name" },
    { id: TEAM_COL, text: "Team", sortable: true, className: "col-team" },
    {
      id: FIRST_DETECTED_COL,
      text: "First detected",
      sortable: true,
      className: "col-first-detected",
    },
  ];

  const isLoading = risksLoading || riskUsersLoading;
  const risks = risksData?.risks ?? [];
  const riskBeingWaived = risks.find((r) => r.id === riskId);
  const waivedRiskAssets = calculateWaivedAssetsForRisks(
    riskBeingWaived ? [riskBeingWaived] : [],
    true,
    undefined,
    undefined,
    riskWaivers?.waivers ?? []
  );
  const usersWaived =
    riskBeingWaived && waivedRiskAssets[riskBeingWaived.id]
      ? waivedRiskAssets[riskBeingWaived.id].assetsWaived
      : {};
  const usersPendingWaiver =
    riskBeingWaived && waivedRiskAssets[riskBeingWaived.id]
      ? waivedRiskAssets[riskBeingWaived.id].assetsPendingWaiver
      : {};
  const waivedUserUUIDs = Object.keys({
    ...usersWaived,
    ...usersPendingWaiver,
  });

  const users = riskUsers?.users ?? [];
  const nonWaivedUsers = users.filter((u) => !waivedUserUUIDs.includes(u.uuid));

  useEffect(() => {
    // if there is only one user and no current selection pre-select it
    if (nonWaivedUsers.length === 1 && selectedUserUUIDs.length === 0) {
      onSelectionChange([nonWaivedUsers[0].uuid]);
    }
  }, [nonWaivedUsers]); // we are explicitly not depending on selectedUserUUIDs to avoid a loop from updating the parent component state

  const [
    sortedUsers,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    SaaSUser,
    typeof NAME_COL | typeof TEAM_COL | typeof FIRST_DETECTED_COL
  >(
    nonWaivedUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase() ?? u.email],
        sortDirsDesc: ["desc"],
        sortDirsAsc: ["asc"],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            u.teams
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(",") ?? "",
        ],
        sortDirsDesc: ["desc"],
        sortDirsAsc: ["asc"],
      },
      [FIRST_DETECTED_COL]: {
        orderFuncs: [(u) => u.firstDetected ?? ""],
        sortDirsDesc: ["desc"],
        sortDirsAsc: ["asc"],
      },
    },
    PAGE_SIZE
  );

  if (isLoading) {
    return <LoadingBanner />;
  }

  // we landed on the create risk waiver page for a specific risk so
  // make sure the risk can be found in the active list of risks
  // if not go back to the risk profile
  if (!riskBeingWaived) {
    LogError(
      `Cannot find UserBase risk ID ${riskId}`,
      new Error(`Cannot find UserBase risk ID`)
    );
    history.push(riskProfileUrl);
    return;
  }

  const rows = sortedUsers.map<IXTableRow<string>>((u) => {
    return {
      id: u.uuid,
      selected: selectedUserUUIDs.includes(u.uuid),
      cells: [
        <XTableCell key={NAME_COL}>{u.name || u.email}</XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : "-"}
        </XTableCell>,
        <XTableCell key={FIRST_DETECTED_COL}>
          {u.firstDetected ? formatDateAsLocal(u.firstDetected) : "-"}
        </XTableCell>,
      ],
    };
  });

  const onSelectUser = (userUUID: string) => {
    if (selectedUserUUIDs.indexOf(userUUID) === -1) {
      onSelectionChange([...selectedUserUUIDs, userUUID]);
    } else {
      onSelectionChange(
        selectedUserUUIDs.filter(
          (selectedUserUUID) => selectedUserUUID != userUUID
        )
      );
    }
  };

  const onSelectToggle = (selectAll: boolean) => {
    if (selectAll) {
      onSelectionChange(nonWaivedUsers.map((u) => u.uuid));
    } else {
      onSelectionChange([]);
    }
  };

  return (
    <ReportCard newStyles className={"userbase-select-risk-card"}>
      <div className={"header"}>Select users to waive</div>
      <div className={"risk"}>
        <AdjustedSeverityIcon
          severity={SeverityAsString(riskBeingWaived.severity)}
          baseSeverity={
            riskBeingWaived.baseSeverity
              ? SeverityAsString(riskBeingWaived.baseSeverity)
              : undefined
          }
        />
        <div className={"name"}>{riskBeingWaived.title}</div>
        <div className={"category"}>{riskBeingWaived.categoryTitle}</div>
        <div className={"occurrences"}>
          {(riskUsers?.users || []).length} occurrences{" "}
          {waivedUserUUIDs.length > 0
            ? ` (${waivedUserUUIDs.length} already waived or pending waiver)`
            : ""}
        </div>
      </div>
      <XTable
        className={"select-risk-table"}
        loading={riskUsersLoading}
        selectable={true}
        hideColumnHeaders={false}
        onSelectToggle={onSelectToggle}
        onSelectAllClick={() => onSelectToggle(true)}
        onSelectNoneClick={() => onSelectToggle(false)}
        onSelectClick={onSelectUser}
        columnHeaders={columHeaders}
        rows={rows}
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        expandableRows={false}
        pagination={{
          currentPage,
          totalPages,
          onPageChange,
          hidePaginationIfSinglePage: true,
        }}
      />
    </ReportCard>
  );
};

export default SaaSRiskWaiverUsersSelection;
