import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { FilterTypes, isFilterActive } from "../components/filter";
import { setAllVendorRisks } from "./cyberRiskActions";
import { getFiltersFromState } from "./filters.actions";
import {
  DefaultThunkDispatch,
  RiskVendorWebsitesData,
  VendorPortfolioKEVCountsData,
  VendorPortfolioRiskProfileData,
  VendorPortfolioRiskVendors,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { Filters } from "../components/filter/types";
import { RiskType } from "../../_common/types/risks";
import hash from "object-hash";
import { SeverityInt } from "../../_common/types/severity";

export const SET_VENDOR_PORTFOLIO_RISK_PROFILE =
  "SET_VENDOR_PORTFOLIO_RISK_PROFILE";
export const SET_RISK_VENDORS = "SET_RISK_VENDORS";
export const SET_RISK_VENDOR_WEBSITES = "SET_RISK_VENDOR_WEBSITES";
export const CLEAR_RISK_VENDOR_WEBSITES = "CLEAR_RISK_VENDOR_WEBSITES";
export const SET_VENDOR_PORTFOLIO_KEV_COUNTS =
  "SET_VENDOR_PORTFOLIO_KEV_COUNTS";

export const fetchVendorPortfolioRiskProfile =
  (filters?: Filters) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    dispatch(setVendorPortfolioRiskProfile(true, null, null));

    if (!filters) {
      filters = getState().cyberRisk.customerData.filters;
    }

    let json;
    try {
      json = await FetchCyberRiskUrl<VendorPortfolioRiskProfileData>(
        "vendorriskprofile/overview/v1/",
        {
          vendor_label_ids: filters.vendorLabelIds,
          vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
          website_label_ids: filters.websiteLabelIds,
          include_unlabeled: filters.includeUnlabeled,
          min_score: filters.minScore,
          max_score: filters.maxScore,
          vendor_ids: filters.vendorIds,
          vendor_tiers: filters.vendorTiers,
          vendor_assessment_classifications:
            filters.vendorAssessmentClassifications,
          vendor_assessment_authors: filters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            filters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: filters.vendorReassessmentStartDate,
          vendor_reassessment_end: filters.vendorReassessmentEndDate,
          vendor_reassessment_before: filters.vendorReassessmentDateBefore,
          vendor_reassessment_between: filters.vendorReassessmentDateBetween,
          portfolio_ids: filters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(filters.selectedAttributes),
          survey_type_ids: filters.vendorSurveyTypes,
          evidence_type_ids: filters.vendorEvidenceTypes,
          fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendor portfolio risk profile", e);
    }

    if (!json) {
      dispatch(
        setVendorPortfolioRiskProfile(
          false,
          {
            errorText: "no data",
          },
          null
        )
      );
      return;
    }

    dispatch(setVendorPortfolioRiskProfile(false, null, json));

    // get the portfolio kev counts by risk
    dispatch(fetchCPEKnownExploitedVulnCountsForPortfolio());

    // Preemptively store vendor all vendor risks if no filters are active
    if (!isFilterActive(filters, Object.values(FilterTypes))) {
      dispatch(setAllVendorRisks(false, null, json));
    }
  };

export const fetchCPEKnownExploitedVulnCountsForPortfolio = () => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    dispatch(setVendorPortfolioKEVCounts(true, null, null));
    const data = getState().cyberRisk.vendorPortfolioRiskProfile?.data;
    const riskIds: string[] = [];
    if (!data || !data.risks) {
      const e = "no risks to lookup";
      dispatch(setVendorPortfolioKEVCounts(false, e as any, null));
      LogError("Error fetching kev counts by CPE", e);
      return;
    }
    data.risks.forEach((r) => {
      if (
        r.id.startsWith("vulnerable_software_version:") ||
        r.id.startsWith("verified_vuln:")
      ) {
        riskIds.push(r.id);
      }
    });
    let json;
    if (riskIds.length > 0) {
      try {
        json = await FetchCyberRiskUrl<VendorPortfolioKEVCountsData>(
          "vulns/kevs_by_cpe/v1/",
          {},
          {
            method: "POST",
            body: JSON.stringify({
              risk_ids: riskIds,
            }),
          },
          dispatch,
          getState
        );
      } catch (e) {
        dispatch(setVendorPortfolioKEVCounts(false, e as any, null));
        LogError("Error fetching kev counts by CPE", e as any);
        return;
      }
    }

    dispatch(setVendorPortfolioKEVCounts(false, null, json ?? null));
  };
};

export const fetchRiskVendors =
  (riskId: string, riskType: RiskType, severity: SeverityInt) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    // Strip the severity suffix if present

    let baseRiskId = riskId;
    if (riskId.indexOf("|") !== -1) {
      const parts = riskId.split("|");
      if (parts.length !== 2) {
        throw new Error(`invalid risk id: ${riskId}`);
      }
      baseRiskId = parts[0];
    }

    dispatch(setRiskVendors(riskId, true, null, null));

    const { filters } = getState().cyberRisk.customerData;

    let json;
    try {
      json = await FetchCyberRiskUrl<VendorPortfolioRiskVendors>(
        "vendorriskprofile/vendorswithrisk/v1/",
        {
          risk_id: baseRiskId,
          risk_severity: severity,
          risk_type: riskType,
          vendor_label_ids: filters.vendorLabelIds,
          vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
          vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
          website_label_ids: filters.websiteLabelIds,
          include_unlabeled: filters.includeUnlabeled,
          min_score: filters.minScore,
          max_score: filters.maxScore,
          vendor_ids: filters.vendorIds,
          vendor_tiers: filters.vendorTiers,
          vendor_assessment_classifications:
            filters.vendorAssessmentClassifications,
          vendor_assessment_authors: filters.vendorAssessmentAuthors,
          vendor_assessment_authors_not:
            filters.vendorAssessmentAuthorDoNotMatch,
          vendor_reassessment_start: filters.vendorReassessmentStartDate,
          vendor_reassessment_end: filters.vendorReassessmentEndDate,
          vendor_reassessment_before: filters.vendorReassessmentDateBefore,
          vendor_reassessment_between: filters.vendorReassessmentDateBetween,
          portfolio_ids: filters.portfolioIds,
          // since the attribute filters are complex we serialise them to json
          attributes: JSON.stringify(filters.selectedAttributes),
          survey_type_ids: filters.vendorSurveyTypes,
          evidence_type_ids: filters.vendorEvidenceTypes,
          fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendors with risk", e);
    }

    if (!json) {
      dispatch(
        setRiskVendors(
          riskId,
          false,
          {
            errorText: "no data",
          },
          null
        )
      );
      return;
    }

    dispatch(setRiskVendors(riskId, false, null, json));
  };

export const fetchRiskVendorWebsites =
  (
    riskId: string,
    vendorId: number,
    isSubsidiary = false,
    includeWaived = false,
    noFilter = false,
    skipWaiverId: number | undefined = undefined
  ) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    const filtersHash = noFilter
      ? ""
      : getVendorsFiltersHash(getState(), vendorId, isSubsidiary);

    const existing = getRiskVendorWebsites(
      getState(),
      riskId,
      vendorId,
      isSubsidiary,
      includeWaived,
      skipWaiverId,
      filtersHash
    );

    if (existing.data) {
      return existing.data;
    }

    dispatch(
      setRiskVendorWebsites(
        riskId,
        vendorId,
        isSubsidiary,
        includeWaived,
        skipWaiverId,
        filtersHash,
        true,
        null,
        null
      )
    );

    const filters = getFiltersFromState(getState(), vendorId, isSubsidiary);

    let json: RiskVendorWebsitesData | undefined;
    try {
      json = await FetchCyberRiskUrl<RiskVendorWebsitesData>(
        "vendorriskprofile/vendorhostnameswithrisk/v1/",
        {
          risk_id: riskId,
          vendor_id: vendorId,
          is_subsidiary: isSubsidiary,
          website_label_ids: noFilter
            ? undefined
            : filters?.websiteLabelIds ?? undefined,
          website_label_ids_match_all: noFilter
            ? undefined
            : filters?.websiteLabelIdsMatchAll ?? undefined,
          website_label_ids_do_not_match: noFilter
            ? undefined
            : filters?.websiteLabelIdsDoNotMatch ?? undefined,
          website_include_unlabeled: noFilter
            ? undefined
            : filters?.websiteIncludeUnlabeled ?? undefined,
          include_waived: includeWaived,
          skip_waiver_id: skipWaiverId,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendor hostnames with risk", e);
    }

    if (!json) {
      dispatch(
        setRiskVendorWebsites(
          riskId,
          vendorId,
          isSubsidiary,
          includeWaived,
          skipWaiverId,
          filtersHash,
          false,
          {
            errorText: "no data",
          },
          null
        )
      );
      return;
    }

    dispatch(
      setRiskVendorWebsites(
        riskId,
        vendorId,
        isSubsidiary,
        includeWaived,
        skipWaiverId,
        filtersHash,
        false,
        null,
        json
      )
    );

    return json;
  };

export const setVendorPortfolioRiskProfile = (
  loading: boolean,
  error: IError | null,
  data: VendorPortfolioRiskProfileData | null
) => ({
  type: SET_VENDOR_PORTFOLIO_RISK_PROFILE,
  loading,
  error,
  data,
});

export const setVendorPortfolioKEVCounts = (
  loading: boolean,
  error: IError | null,
  counts: VendorPortfolioKEVCountsData | null
) => ({
  type: SET_VENDOR_PORTFOLIO_KEV_COUNTS,
  loading,
  error,
  counts,
});

export const setRiskVendors = (
  riskId: string,
  loading: boolean,
  error: IError | null,
  data: VendorPortfolioRiskVendors | null
) => ({
  type: SET_RISK_VENDORS,
  riskId,
  loading,
  error,
  data,
});

export const setRiskVendorWebsites = (
  riskId: string,
  vendorId: number,
  isSubsidiary: boolean,
  includeWaivers: boolean,
  skipWaiverId: number | undefined,
  filtersHash: any,
  loading: boolean,
  error: IError | null,
  data: RiskVendorWebsitesData | null
) => ({
  type: SET_RISK_VENDOR_WEBSITES,
  riskId,
  vendorId,
  isSubsidiary,
  includeWaivers,
  skipWaiverId,
  filtersHash,
  loading,
  error,
  data,
});

// Clear any cached websites lists for vendor/subsidiary combination
export const clearRiskVendorWebsites = (
  vendorId: number,
  isSubsidiary: boolean
) => ({
  type: CLEAR_RISK_VENDOR_WEBSITES,
  vendorId,
  isSubsidiary,
});

export const getRiskVendors = (state: DefaultRootState, riskId: string) =>
  state.cyberRisk.riskVendors[riskId] || {
    loading: false,
    error: null,
    data: null,
  };

export const getVendorsFiltersHash = (
  state: DefaultRootState,
  vendorId: number,
  isSubsidiary: boolean
) => {
  return hash(getFiltersFromState(state, vendorId, isSubsidiary));
};

const emptyObj = {
  loading: false,
  error: null,
  data: null,
};

export const getRiskVendorWebsites = (
  state: DefaultRootState,
  riskId: string,
  vendorId: number,
  isSubsidiary: boolean,
  includeWaived = false,
  skipWaiverId: number | undefined,
  filtersHash = ""
) =>
  state.cyberRisk.riskVendorWebsites[
    `${riskId}/${vendorId}/${isSubsidiary}/${includeWaived}/${skipWaiverId}/${filtersHash}`
  ] || emptyObj;
