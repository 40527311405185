import ModalV2, { BaseModalProps, useModalV2 } from "../ModalV2";
import { SurveyUser } from "../../types/survey";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../types/reduxHooks";
import Button from "../core/Button";
import UsersAPI from "../../api/usersAPI";
import "../../style/components/surveydetails/SurveyDetailsInviteCollaboratorModal.scss";
import CustomSelect, { OptionItem, SelectItemType } from "../CustomSelect";
import { UserAvatarAndName } from "../UserAvatar";
import TextField, { useTextWithValid } from "../TextField";
import { fetchOrganisationDefaultTexts } from "../../../vendorrisk/reducers/orgDefaultTexts.actions";
import { DefaultTextType } from "../../types/orgDefaultTexts";
import orgIcon from "../../images/building-city-town.svg";
import { Invite, IUserMini } from "../../types/user";
import surveyAPI from "../../../vendorrisk/reducers/surveyAPI";
import { useCurrentOrg } from "../../selectors/commonSelectors";
import IconButton, { HoverColor, HoverLocation } from "../IconButton";
import { getGravatarURL, validateEmail } from "../../helpers";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import SurveyAPI from "../../../vendorrisk/reducers/surveyAPI";
import ConfirmationModalV2 from "../modals/ConfirmationModalV2";

interface SurveyDetailsInviteCollaboratorModalProps extends BaseModalProps {
  surveyID: number;
  surveyName: string;
  collaborators: SurveyUser[];
  openCollaborationInvites: Invite[];
  numOrgUsersWithAccess: number;
  orgName: string;
  fromUser?: IUserMini;
}

const SurveyDetailsInviteCollaboratorModal: FC<
  SurveyDetailsInviteCollaboratorModalProps
> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchOrganisationDefaultTexts());
  }, []);

  const currentOrg = useCurrentOrg();

  const { data: orgCollaborators, isFetching: collaboratorsLoading } =
    UsersAPI.useGetOrgCollaboratorsQuery();
  const [deleteCollaborator] = SurveyAPI.useDeleteCollaboratorMutation();

  const [openConfirmationModal, confirmationModal] =
    useModalV2(ConfirmationModalV2);

  const onDeleteCollaborator = useCallback(
    (userEmail: string, isInvite: boolean) =>
      openConfirmationModal({
        title: isInvite ? "Remove invite?" : "Delete collaborator?",
        description: isInvite
          ? "Would you like to uninvite this user from collaboration?"
          : "Would you like to remove this user from collaboration?",
        dangerousAction: true,
        iconClass: "cr-icon-trash",
        buttonText: "Delete",
        width: 600,
        buttonAction: () =>
          deleteCollaborator({ surveyId: props.surveyID, userEmail })
            .unwrap()
            .then(() => {
              dispatch(addDefaultSuccessAlert("Removed collaborator"));
            })
            .catch(() => {
              dispatch(
                addDefaultUnknownErrorAlert("Error removing collaborator")
              );
            }),
      }),
    [props.surveyID]
  );

  const [searchVal, setSearchVal] = useState("");
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [newUser, setNewUser] = useState(false);

  const clearState = useCallback(() => {
    setSelectedUserEmail("");
    setNewUser(false);
    setSearchVal("");
  }, []);

  const selectedUser = orgCollaborators?.find(
    (c) => c.emailAddress == selectedUserEmail
  );

  const defaultTexts = useAppSelector(
    (state) => state.cyberRisk.orgDefaultTexts
  );
  useEffect(() => {
    if (
      defaultTexts &&
      defaultTexts[DefaultTextType.QuestionnaireCollaboratorMessage]
    ) {
      setMessage(
        defaultTexts[DefaultTextType.QuestionnaireCollaboratorMessage]
          ?.defaultText,
        true
      );
    }
  }, [defaultTexts]);

  const [message, messageValid, setMessage] = useTextWithValid("");

  const currentUser = useAppSelector((state) => state.common.userData);

  const filteredUsers = useMemo(() => {
    if (orgCollaborators) {
      return orgCollaborators.filter(
        (u) =>
          u.emailAddress != currentUser?.emailAddress &&
          !props.collaborators.find((c) => c.email == u.emailAddress) &&
          (searchVal == "" || u.emailAddress.includes(searchVal.toLowerCase()))
      );
    }
    return [];
  }, [orgCollaborators, searchVal]);

  const [addCollaboratorMutation] = surveyAPI.useAddCollaboratorMutation();

  const [loading, setLoading] = useState(false);
  const onSubmit = async () => {
    setLoading(true);

    return addCollaboratorMutation({
      surveyId: props.surveyID,
      usersToAdd: [selectedUserEmail],
      message,
    })
      .unwrap()
      .catch(() => {
        dispatch(
          addDefaultUnknownErrorAlert(
            "Error adding collaborator to questionnaire"
          )
        );
      })
      .then(() => {
        dispatch(addDefaultSuccessAlert("Added collaborator to questionnaire"));
        clearState();
        props.onClose();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const options: OptionItem[] = filteredUsers.map((c) => ({
    id: c.id,
    onClick: () => {
      setSelectedUserEmail(c.emailAddress);
      setNewUser(false);
    },
    type: SelectItemType.Option,
    content: (
      <UserAvatarAndName
        name={c.name}
        avatar={c.avatar}
        email={c.emailAddress}
      />
    ),
  }));

  if (searchVal != "") {
    options.push({
      id: 0,
      onClick: () => {
        setSelectedUserEmail(`${searchVal}@${currentOrg?.mainHostname}`);
        setNewUser(true);
      },
      type: SelectItemType.Option,
      content: (
        <Button tertiary>
          <i className={"cr-icon-mail"} /> Invite {searchVal}@
          {currentOrg?.mainHostname ?? ""}
        </Button>
      ),
    });
  }

  return (
    <ModalV2
      active={props.active}
      onClose={() => {
        clearState();
        props.onClose();
      }}
      headerContent={`Share ${props.surveyName}`}
      subHeaderContent={
        "Keep others in your organization up-to-date on the progress of this questionnaire"
      }
      className={"survey-details-invite-collaborator"}
      width={800}
    >
      <div className={"section"}>
        <div className={"label"}>Invite with email</div>
        {selectedUserEmail != "" || newUser ? (
          <div className={"user-section"}>
            <IconButton
              icon={<i className={"icon-x"} />}
              onClick={() => {
                setSelectedUserEmail("");
                setNewUser(false);
              }}
            />
            {newUser ? (
              <TextField
                value={selectedUserEmail}
                onChanged={setSelectedUserEmail}
                type={"email"}
              />
            ) : (
              <UserAvatarAndName
                name={selectedUser?.name}
                avatar={
                  selectedUser?.avatar ?? getGravatarURL(selectedUserEmail)
                }
                email={selectedUserEmail}
              />
            )}
            <PillLabel color={LabelColor.Grey}>Collaborator</PillLabel>
          </div>
        ) : (
          <CustomSelect
            disabled={collaboratorsLoading}
            items={options}
            onSearchChange={setSearchVal}
          />
        )}
      </div>
      <div className={"section"}>
        <div className={"label"}>Message</div>
        <TextField value={message} onChanged={setMessage} multiLine />
      </div>
      <Button
        className={"share-btn"}
        onClick={onSubmit}
        loading={loading}
        filledPrimary
        disabled={
          selectedUserEmail == "" ||
          !messageValid ||
          !validateEmail(selectedUserEmail)
        }
      >
        <i className={"cr-icon-mail"} /> Share
      </Button>
      <div className={"divider"} />
      <div className={"label"}>People with access</div>
      <div className={"shared-with-list"}>
        <UserAvatarAndName
          name={`Account users at ${props.orgName}`}
          email={`${props.numOrgUsersWithAccess} account users`}
          avatar={orgIcon}
          circleStyle
        />
        {props.fromUser && (
          <UserAvatarAndName
            name={props.fromUser.name + " (Sender)"}
            avatar={props.fromUser.avatar}
            email={props.fromUser.email}
          />
        )}
        {props.collaborators.map((u) => (
          <div className={"avatar-and-pill"} key={u.id}>
            <UserAvatarAndName
              avatar={u.avatar}
              name={u.name}
              email={u.email}
            />
            <PillLabel color={LabelColor.Grey}>Collaborator</PillLabel>
            <IconButton
              icon={<i className={"cr-icon-trash"} />}
              hoverText={"Delete collaborator"}
              hoverMicro
              hoverLocation={HoverLocation.Top}
              hoverColor={HoverColor.Red}
              onClick={() => onDeleteCollaborator(u.email, false)}
            />
          </div>
        ))}
        {props.openCollaborationInvites.map((i) => (
          <div className={"avatar-and-pill"} key={i.recipientEmail}>
            <UserAvatarAndName
              key={i.recipientEmail}
              avatar=""
              name={i.recipientEmail + " (invited)"}
              email={i.recipientEmail}
            />
            <PillLabel color={LabelColor.Grey}>Collaborator</PillLabel>
            <IconButton
              icon={<i className={"cr-icon-trash"} />}
              hoverText={"Delete collaborator"}
              hoverMicro
              hoverLocation={HoverLocation.Top}
              hoverColor={HoverColor.Red}
              onClick={() => onDeleteCollaborator(i.recipientEmail, true)}
            />
          </div>
        ))}
      </div>
      {confirmationModal}
    </ModalV2>
  );
};

export default SurveyDetailsInviteCollaboratorModal;
