import { DependencyList, EffectCallback, useEffect, useRef } from "react";

export const selectArray: <t>(array: t[], val: t, select: boolean) => t[] = (
  array,
  val,
  select
) => {
  const result = [...array];
  const idx = result.indexOf(val);
  if (idx > -1 && !select) {
    result.splice(idx, 1);
  } else if (idx === -1 && select) {
    result.push(val);
  }
  return result;
};

// helper to get the correct pural of a noun based on num
export const getPlural = (num: number, single: string, plural: string) =>
  num != 1 ? plural : single;

export const containsNonAsciiCharacters = (s: string): boolean =>
  [...s].some((char) => char.charCodeAt(0) > 127);

export const minVendorSearchLength = 3;

// check whether a vendor search should be bypassed, e.g. the searched string is too short and would result in a timeout.
// For ascii search terms we only allow searches of 3 characters or more to avoid the performance penalty
// For   non-ascii search terms we allow 2 characters or more to cater for international vendors
export const shouldBypassSearch = (q: string) => {
  const runeLength = [...q].length;
  return containsNonAsciiCharacters(q)
    ? runeLength < 2
    : runeLength < minVendorSearchLength;
};

export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// taken from https://stackoverflow.com/questions/55187563/determine-which-dependency-array-variable-caused-useeffect-hook-to-fire
const usePreviousDebugger = (value: any, initialValue: any) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useEffectDebugger = (
  effectHook: EffectCallback,
  dependencies: DependencyList,
  dependencyNames: string[] = []
) => {
  const previousDeps = usePreviousDebugger(dependencies, []);

  const changedDeps = dependencies.reduce(
    (
      accum: Record<
        string,
        {
          before: unknown;
          after: unknown;
        }
      >,
      dependency,
      index
    ) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        };
      }

      return accum;
    },
    {}
  );

  if (Object.keys(changedDeps).length) {
    console.log("[use-effect-debugger] ", changedDeps);
  }

  useEffect(effectHook, dependencies);
};

// assign copy the property values of source onto the target object
// it will not copy any property with undefined values
export const assign = (
  target: Record<string, any>,
  source: Record<string, any>
): Record<string, any> =>
  Object.assign(
    target,
    Object.entries(source)
      .filter(([_, value]) => value !== undefined)
      .reduce(
        (obj, [key, value]) => ((obj[key] = value), obj),
        {} as Record<string, any>
      )
  );

export const nilUUID = "00000000-0000-0000-0000-000000000000";

export function validateUrl(urlString: string): boolean {
  try {
    const url = new URL(urlString);
    return url.protocol === "https:" || url.protocol === "http:";
  } catch (e) {
    return false;
  }
}
