import { Filters } from "../components/filter/types";
import {
  DefaultAction,
  DefaultThunkDispatch,
  ISingleVendorData,
} from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { isEqual as _isEqual } from "lodash";
import {
  fetchVendorSummaryAndCloudscans,
  fetchVendorVulns,
  getVendorData,
  setCustomerData,
  setVendorData,
} from "./cyberRiskActions";
import {
  fetchDomains,
  fetchDomainsParams,
  getDomainParamsKey,
} from "./domains.actions";
import { getDefaultFilters } from "./defaults";
import { grabTPVMSession } from "../../_common/reducers/commonActions";
import { clearRiskVendorWebsites } from "./vendorRiskPortfolio.actions";
import { fetchSingleVendorGeolocationData } from "./ipGeolocation.actions";
import { getSurveyListV2 } from "./survey.actions";
import { surveyListPageLimit } from "../views/Questionnaires";
import { RiskMini } from "../../_common/types/risks";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { IdAndName } from "../../_common/types/vendor";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../_common/types/reduxStore";

export const SET_VENDOR_FILTERS = "SET_VENDOR_FILTER";
export const setVendorFilters = (vendorId: number, filters: Filters) => {
  return {
    type: SET_VENDOR_FILTERS,
    vendorId,
    filters,
  };
};

export const SET_SUBSIDIARY_FILTERS = "SET_SUBSIDIARY_FILTER";
export const setSubsidiaryFilters = (vendorId: number, filters: Filters) => {
  return {
    type: SET_SUBSIDIARY_FILTERS,
    vendorId,
    filters,
  };
};

// Update global-state vendor filter and refresh any data that is needed
export const setVendorDataFiltersAndRefreshData = (
  vendorId: number,
  isSubsidiary: boolean,
  newFilters: Partial<Filters>
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const currentFilters = getFiltersFromState(
      getState(),
      vendorId,
      isSubsidiary
    );
    let filters = newFilters as Filters;

    if (currentFilters) {
      filters = { ...currentFilters, ...newFilters };
    }

    if (_isEqual(currentFilters, filters)) {
      return;
    }

    if (vendorId && isSubsidiary) {
      dispatch(setSubsidiaryFilters(vendorId, filters));
    } else if (vendorId) {
      dispatch(setVendorFilters(vendorId, filters));
    }

    dispatch(refreshVendorFilteredData(vendorId, isSubsidiary, filters));
  };
};

// Refresh any vendor, subsidiary or TPVM vendor data that needs to be re-filtered
const refreshVendorFilteredData = (
  vendorId: number,
  isSubsidiary: boolean,
  newFilters: Filters
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const vendorData = getVendorData(
      getState,
      vendorId,
      isSubsidiary,
      grabTPVMSession(getState)
    ) as ISingleVendorData | undefined;

    if (vendorData) {
      // Check if we need to refresh the vendor summary
      if (vendorData.summary?.result) {
        dispatch(
          fetchVendorSummaryAndCloudscans(
            vendorId,
            true,
            false,
            false,
            isSubsidiary,
            newFilters
          )
        );
      }

      // Check if we need to refresh the vendor vulns data
      if (vendorData.vulns?.result) {
        dispatch(fetchVendorVulns(vendorId, true, isSubsidiary, newFilters));
      }

      // And the geolocation data
      if (vendorData.geolocationData) {
        dispatch(
          fetchSingleVendorGeolocationData(
            true,
            isSubsidiary,
            vendorId,
            newFilters
          )
        );
      }
    }

    // Check if we need to refresh the active domains list
    const vendorDomainsActiveParams = {
      vendor_id: vendorId,
      active: true,
      website_label_ids: newFilters.websiteLabelIds,
      website_include_unlabeled: newFilters.websiteIncludeUnlabeled,
      is_subsidiary: isSubsidiary,
    } as fetchDomainsParams;

    const vendorDomainsKeyActive = getDomainParamsKey(
      vendorDomainsActiveParams
    );

    if (getState().cyberRisk.domains[vendorDomainsKeyActive]) {
      dispatch(fetchDomains(vendorDomainsActiveParams));
    }

    // Check if we need to refresh the inactive domains list
    const vendorDomainsInactiveParams = {
      ...vendorDomainsActiveParams,
      active: false,
    } as fetchDomainsParams;

    const vendorDomainsKeyInactive = getDomainParamsKey(
      vendorDomainsInactiveParams
    );

    if (getState().cyberRisk.domains[vendorDomainsKeyInactive]) {
      dispatch(fetchDomains(vendorDomainsInactiveParams));
    }

    // Don't need to refresh IP's as we do that in the page

    // Invalidate any cached risk website lists
    dispatch(clearRiskVendorWebsites(vendorId, isSubsidiary));

    // see if we need to refresh surveys
    const surveysData = vendorData?.surveyList;
    if (surveysData) {
      dispatch(
        getSurveyListV2(
          true,
          vendorId,
          0,
          surveysData.limit ?? surveyListPageLimit,
          surveysData.sortCol,
          surveysData.sortDir,
          surveysData.archived,
          surveysData.filterText,
          newFilters
        )
      );
    }
  };
};

// Get the app-wide filters for an org/vendor/subsidiary/TPRMS
export const getFiltersFromState = createSelector(
  [
    // Get non-tprms filters
    (state: RootState, vendorId?: number, isSubsidiary?: boolean) => {
      let filters: Filters | undefined;

      if (vendorId && isSubsidiary) {
        filters = state.cyberRisk.subsidiaries[vendorId]?.filters;
      } else if (vendorId) {
        filters = state.cyberRisk.vendors[vendorId]?.filters;
      } else {
        filters = state.cyberRisk.customerData.filters;
      }

      return filters;
    },
    // Get tprms filter
    (state: RootState) => state.analystPortal.managedServiceRequests.filter,
  ],
  (vendorFilters, tprmsFilters) => {
    // Need to work with a clone of filters otherwise we may be mutating state between dispatches.
    if (vendorFilters) {
      const mergedFilters = { ...vendorFilters };

      // Merge the TPRMS filter in
      mergedFilters.namePrefix = tprmsFilters.namePrefix;
      mergedFilters.orgNames = tprmsFilters.orgNames;
      mergedFilters.excludedServiceLevels = tprmsFilters.excludedServiceLevels;
      mergedFilters.includedServiceStatuses =
        tprmsFilters.includedServiceStatuses;

      return mergedFilters;
    } else {
      return {
        ...getDefaultFilters(),
        ...tprmsFilters,
      } as unknown as Filters;
    }
  }
);

// getVendorFilterParams gets the vendor specific filtering params from the filters as expected by filtering.VendorFilterParams
export const getVendorFilterParams = (filters: Filters) => ({
  vendor_tiers: filters.vendorTiers,
  vendor_assessment_classifications: filters.vendorAssessmentClassifications,
  vendor_assessment_authors: filters.vendorAssessmentAuthors,
  vendor_assessment_authors_not: filters.vendorAssessmentAuthorDoNotMatch,
  vendor_reassessment_start: filters.vendorReassessmentStartDate,
  vendor_reassessment_end: filters.vendorReassessmentEndDate,
  vendor_reassessment_before: filters.vendorReassessmentDateBefore,
  vendor_reassessment_between: filters.vendorReassessmentDateBetween,
  vendor_ids: filters.vendorIds,
  portfolio_ids: filters.portfolioIds,
  min_score: filters.minScore,
  max_score: filters.maxScore,
  vendor_label_ids: filters.vendorLabelIds,
  vendor_label_ids_match_all: filters.vendorLabelIdsMatchAll,
  vendor_label_ids_do_not_match: filters.vendorLabelIdsDoNotMatch,
  include_unlabeled: filters.includeUnlabeled,
  // since the attribute filters are complex we serialise them to json
  attributes: JSON.stringify(filters.selectedAttributes),
  survey_type_ids: filters.vendorSurveyTypes,
  evidence_type_ids: filters.vendorEvidenceTypes,
  fourth_party_product_uuids: filters.fourthPartyProductUUIDs,
});

export const getRisksList =
  (
    force = false,
    vendorId?: number,
    isSubsidiary = false
  ): DefaultAction<RiskMini[]> =>
  async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);

    if (!force) {
      if (vendorId) {
        const vendor = getVendorData(
          getState,
          vendorId,
          isSubsidiary,
          tpvmSession
        ) as ISingleVendorData;

        if (vendor?.riskList?.risks) {
          return vendor.riskList.risks;
        }
      } else {
        const risks = getState().cyberRisk.customerData?.riskList?.risks;
        if (risks) {
          return risks;
        }
      }
    }

    if (vendorId) {
      dispatch(
        setVendorData(
          vendorId,
          { riskList: { loading: true } },
          isSubsidiary,
          tpvmSession as any
        )
      );
    } else {
      dispatch(setCustomerData({ riskList: { loading: true } }));
    }

    let json: { status: string; risks: RiskMini[] };
    const url = vendorId ? "vendorrisks/all" : "orgrisks/all";
    try {
      json = await FetchCyberRiskUrl(
        url,
        {
          vendor_id: vendorId,
        },
        {},
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting risks list", e);

      if (vendorId) {
        dispatch(
          setVendorData(
            vendorId,
            { riskList: { loading: false, error: {} } },
            isSubsidiary,
            tpvmSession as any
          )
        );
      } else {
        dispatch(setCustomerData({ riskList: { loading: false, error: {} } }));
      }
      throw e;
    }

    if (vendorId) {
      dispatch(
        setVendorData(
          vendorId,
          { riskList: { risks: json.risks, loading: false } },
          isSubsidiary,
          tpvmSession as any
        )
      );
    } else {
      dispatch(
        setCustomerData({ riskList: { risks: json.risks, loading: false } })
      );
    }

    return json.risks;
  };

export const SET_VENDOR_ASSET_TYPES = "SET_VENDOR_ASSET_TYPES";
export const setVendorAssetTypes = (
  surveyTypes: IdAndName[],
  evidenceTypes: IdAndName[]
) => {
  return {
    type: SET_VENDOR_ASSET_TYPES,
    surveyTypes,
    evidenceTypes,
  };
};

interface WatchlistAssetTypesV1Resp {
  surveyTypes: IdAndName[];
  evidenceTypes: IdAndName[];
}

let assetTypesGetProm: Promise<WatchlistAssetTypesV1Resp> | undefined =
  undefined;

export const getVendorAssetTypes =
  (force = false): DefaultAction =>
  async (dispatch, getState) => {
    const sTypes = getState().cyberRisk.vendorCurrentSurveyTypes;
    const eTypes = getState().cyberRisk.vendorCurrentEvidenceTypes;

    if (sTypes && eTypes && !force) {
      return;
    }

    let json: WatchlistAssetTypesV1Resp;

    try {
      if (!assetTypesGetProm) {
        assetTypesGetProm = FetchCyberRiskUrl<WatchlistAssetTypesV1Resp>(
          "watchlist/assettypes/v1",
          {},
          {},
          dispatch,
          getState
        );
      }

      json = await assetTypesGetProm;
    } catch (e) {
      LogError("Error getting vendor survey types", e);
      throw e;
    }

    assetTypesGetProm = undefined;

    dispatch(setVendorAssetTypes(json.surveyTypes, json.evidenceTypes));
  };
