import { Component } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { closeModal } from "../reducers/commonActions";
import AddCustomCloudscanModal, {
  AddCustomCloudscanModalName,
} from "../../vendorrisk/components/modals/AddCustomCloudscanModal";
import AddCyberEyeModal, {
  AddCyberEyeModalName,
} from "../../admin_portal/components/modals/AddCyberEyeModal";
import AddEditVendorAttributeOverrideModal, {
  AddEditVendorAttributeOverrideModalName,
} from "../../admin_portal/components/modals/AddEditVendorAttributeOverrideModal";
import MoveDomainsModal, {
  MoveDomainsModalName,
} from "../../admin_portal/components/modals/MoveDomainsModal";
import CancelSubscriptionModal, {
  CancelSubscriptionModalName,
} from "../../vendorrisk/components/modals/CancelSubscriptionModal";
import ConfirmationModal, {
  ConfirmationModalName,
} from "./modals/ConfirmationModal";
import EditCompanyDetailsModal, {
  EditCompanyDetailsModalName,
} from "../../vendorrisk/components/modals/EditCompanyDetailsModal";
import EditOrganisationModal, {
  EditOrganisationModalName,
} from "../../admin_portal/components/modals/EditOrganisationModal";
import EditOrganisationSystemLabelsModal, {
  EditOrganisationSystemLabelsModalName,
} from "../../admin_portal/components/modals/EditOrganisationSystemLabelsModal";
import EditDeveloperTogglesModal, {
  EditDeveloperTogglesModalName,
} from "../../admin_portal/components/modals/EditDeveloperTogglesModal";
import EditAccountAuthDetailsModal, {
  EditAccountAuthDetailsModalName,
} from "../../admin_portal/components/modals/EditAccountAuthDetailsModal";
import EditUserModal, {
  EditUserModalName,
} from "../../vendorrisk/components/modals/EditUserModal";
import GetFindingsModal, {
  GetFindingsModalName,
} from "../../analyst_portal/components/modals/GetFindingsModal";
import IntegrationDetailsModal, {
  IntegrationDetailsModalName,
} from "../../vendorrisk/components/modals/IntegrationDetailsModal";
import ManageLabelsModal, {
  ManageLabelsModalName,
} from "../../vendorrisk/components/modals/ManageLabelsModal";
import NewOrganisationModal, {
  NewOrganisationModalName,
} from "../../admin_portal/components/modals/NewOrganisationModal";
import NewUserModal, {
  NewUserModalName,
} from "../../vendorrisk/components/modals/NewUserModal";
import RemediationAddUserModal, {
  RemediationAddUserModalName,
} from "../../vendorrisk/components/modals/RemediationAddUserModal";
import RenameVendorModal, {
  RenameVendorModalName,
} from "../../vendorrisk/components/modals/RenameVendorModal";
import ReviewPublishModal, {
  ReviewPublishModalName,
} from "../../analyst_portal/components/modals/ReviewPublishModal";
import S3ObjectsModal, {
  S3ObjectsModalName,
} from "../../analyst_portal/components/modals/S3ObjectsModal";
import GCSObjectsModal, {
  GCSObjectsModalName,
} from "../../analyst_portal/components/modals/GCSObjectsModal";
import SetDueDateModal, {
  SetDueDateModalName,
} from "../../analyst_portal/components/modals/SetDueDateModal";
import ShareAssessmentModal, {
  ShareAssessmentModalName,
} from "../../vendor_portal/components/ShareAssessmentModal";
import UpdateBillingEmailModal, {
  UpdateBillingEmailModalName,
} from "../../vendorrisk/components/modals/UpdateBillingEmailModal";
import UpdateCreditCardModal, {
  UpdateCreditCardModalName,
} from "../../vendorrisk/components/modals/UpdateCreditCardModal";
import UpdatePlanModal, {
  UpdatePlanModalName,
} from "../../vendorrisk/components/modals/UpdatePlanModal";
import VendorLookupsModal, {
  VendorLookupsModalName,
} from "../../vendorrisk/components/modals/VendorLookupsModal";
import YesNoModal, { YesNoModalName } from "./YesNoModal";
import AddTyposquatDomainModal, {
  AddTyposquatDomainModalName,
} from "../../vendorrisk/components/modals/AddTyposquatDomainModal";
import OrganisationAPIKeyModal, {
  OrganisationAPIKeyModalName,
} from "../../vendorrisk/components/modals/OrganisationAPIKeyModal";
import TyposquattingIgnoreModal, {
  TyposquattingIgnoreModalName,
} from "../../vendorrisk/components/modals/TyposquattingIgnoreModal";
import BreachSightCloseReasonModal, {
  BreachSightCloseReasonModalName,
} from "../../vendorrisk/components/modals/BreachSightCloseReasonModal";
import BreachSightMessageModal, {
  BreachSightMessageModalName,
} from "../../vendorrisk/components/modals/BreachSightMessageModal";
import "../style/components/Modal.scss";
import DocumentModal, {
  DocumentModalName,
} from "../../vendorrisk/components/modals/DocumentModal";
import VerifiedVendorInviteUserModal, {
  VerifiedVendorInviteUserModalName,
} from "../../vendorrisk/components/modals/VerifiedVendorInviteUserModal";
import EditPublicSurveyDetailsModal, {
  EditPublicSurveyDetailsModalName,
} from "../../vendorrisk/components/modals/EditPublicSurveyDetailsModal";
import VerifiedVendorDenyRequestModal, {
  VerifiedVendorDenyRequestModalName,
} from "../../vendorrisk/components/modals/VerifiedVendorDenyRequestModal";
import MySharedProfileEmbedModal, {
  MySharedProfileEmbedModalName,
} from "../../vendorrisk/components/modals/MySharedProfileEmbedModal";
import ChangeDistributorModal, {
  ChangeDistributorModalName,
} from "../../admin_portal/components/modals/ChangeDistributorModal";
import EmailExposureNotificationModal, {
  EmailExposureNotificationModalName,
} from "../../vendorrisk/components/modals/EmailExposureNotificationModal";
import Icon from "./core/Icon";
import ModifyDueDateModal, {
  ModifyDueDateModalName,
} from "../../vendorrisk/components/modals/ModifyDueDateModal";
import ImpersonateOrgModal, {
  ImpersonateOrgModalName,
} from "../../admin_portal/components/modals/ImpersonateOrgModal";
import Button from "./core/Button";
import AddIPAddressesModal, {
  AddIPAddressesModalName,
} from "../../vendorrisk/components/modals/AddIPAddresses";
import { appConnect } from "../types/reduxHooks";
import { AppDispatch } from "../types/reduxStore";
import { IsInsideModalContext } from "./ModalV2";
import { History } from "history";

// Maps modal names to modal components
const modalMap: { [modalId: string]: any } = {
  [AddCustomCloudscanModalName]: AddCustomCloudscanModal,
  [AddIPAddressesModalName]: AddIPAddressesModal,
  [AddCyberEyeModalName]: AddCyberEyeModal,
  [AddEditVendorAttributeOverrideModalName]:
    AddEditVendorAttributeOverrideModal,
  [MoveDomainsModalName]: MoveDomainsModal,
  [ImpersonateOrgModalName]: ImpersonateOrgModal,
  [CancelSubscriptionModalName]: CancelSubscriptionModal,
  [ChangeDistributorModalName]: ChangeDistributorModal,
  [ConfirmationModalName]: ConfirmationModal,
  [EditCompanyDetailsModalName]: EditCompanyDetailsModal,
  [EditOrganisationModalName]: EditOrganisationModal,
  [EditOrganisationSystemLabelsModalName]: EditOrganisationSystemLabelsModal,
  [EditDeveloperTogglesModalName]: EditDeveloperTogglesModal,
  [EditUserModalName]: EditUserModal,
  [GetFindingsModalName]: GetFindingsModal,
  [IntegrationDetailsModalName]: IntegrationDetailsModal,
  [ManageLabelsModalName]: ManageLabelsModal,
  [NewOrganisationModalName]: NewOrganisationModal,
  [NewUserModalName]: NewUserModal,
  [RemediationAddUserModalName]: RemediationAddUserModal,
  [RenameVendorModalName]: RenameVendorModal,
  [ReviewPublishModalName]: ReviewPublishModal,
  [S3ObjectsModalName]: S3ObjectsModal,
  [GCSObjectsModalName]: GCSObjectsModal,
  [SetDueDateModalName]: SetDueDateModal,
  [ShareAssessmentModalName]: ShareAssessmentModal,
  [UpdateBillingEmailModalName]: UpdateBillingEmailModal,
  [UpdateCreditCardModalName]: UpdateCreditCardModal,
  [UpdatePlanModalName]: UpdatePlanModal,
  [VendorLookupsModalName]: VendorLookupsModal,
  [YesNoModalName]: YesNoModal,
  [AddTyposquatDomainModalName]: AddTyposquatDomainModal,
  [OrganisationAPIKeyModalName]: OrganisationAPIKeyModal,
  [TyposquattingIgnoreModalName]: TyposquattingIgnoreModal,
  [BreachSightCloseReasonModalName]: BreachSightCloseReasonModal,
  [BreachSightMessageModalName]: BreachSightMessageModal,
  [DocumentModalName]: DocumentModal,
  [VerifiedVendorInviteUserModalName]: VerifiedVendorInviteUserModal,
  [VerifiedVendorDenyRequestModalName]: VerifiedVendorDenyRequestModal,
  [EditPublicSurveyDetailsModalName]: EditPublicSurveyDetailsModal,
  [MySharedProfileEmbedModalName]: MySharedProfileEmbedModal,
  [EmailExposureNotificationModalName]: EmailExposureNotificationModal,
  [EditAccountAuthDetailsModalName]: EditAccountAuthDetailsModal,
  [ModifyDueDateModalName]: ModifyDueDateModal,
};

interface ModalOwnProps {
  dispatch: AppDispatch;
  history: History;
}

interface ModalConnectedProps {
  openModal?: string;
  modalData: any;
}

type ModalProps = ModalOwnProps & ModalConnectedProps;

/**
 * Full page modal controller. This will pop up and fade in a modal when the modalName parameter changes to
 * a valid modal name defined in the switch statement.
 * @param {function} modalComponent - React component function that should be rendered
 * @param {object} modalData - generic data object from state, passed to any modal
 * @param {function} dispatch
 * @param [boolean] modalData.isV2Layout - if should use v2 layout
 * @param [string] modalData.headerText - if using v2 layout, the header text to display
 * @param [number] modalData.width - width override
 */
class Modal extends Component<ModalProps> {
  componentDidMount() {
    document.addEventListener("keydown", this.escListener);
    window.addEventListener("popstate", this.popStateListener);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escListener);
    window.removeEventListener("popstate", this.popStateListener);
    document.body.classList.remove("no-scroll");
  }

  popStateListener = () => {
    // if someone tries to navigate with `back` close the modal.
    this.closeModal();
  };

  componentDidUpdate(prevProps: ModalProps) {
    if (this.props.openModal && !prevProps.openModal) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }

  closeModal = () => {
    if (this.props.modalData.onClose) {
      this.props.modalData.onClose();
    }
    this.props.dispatch(closeModal());
  };

  // Close the modal when ESC key is pressed
  escListener = (evt: any) => {
    if (evt.which === 27 && this.props.openModal) {
      this.closeModal();
    }
  };

  // Close modal if click outside the modal surface
  clickOutside = (evt: any) => {
    if (
      evt.target.className ===
        "full-page-modal v2 fade-transition-enter-done" &&
      this.props.openModal
    ) {
      this.closeModal();
    }
  };

  render() {
    const { isV2Layout } = this.props.modalData;

    // Components must start with a capital letter to pass JSX validation.
    const ModalComponent = this.props.openModal
      ? modalMap[this.props.openModal]
      : null;

    return (
      <IsInsideModalContext.Provider value={true}>
        <TransitionGroup>
          {ModalComponent && (
            <CSSTransition timeout={250} classNames="fade-transition">
              <div
                className={`full-page-modal ${isV2Layout ? "v2" : ""}`}
                onMouseDown={isV2Layout ? this.clickOutside : undefined}
              >
                {isV2Layout && (
                  <div className={"modal-content-container"}>
                    <Button
                      className={"modal-close"}
                      tertiary
                      onClick={() => this.closeModal()}
                    >
                      <Icon name="x" />
                    </Button>
                    <ModalComponent
                      modalData={this.props.modalData}
                      dispatch={this.props.dispatch}
                      history={this.props.history}
                    />
                  </div>
                )}
                {!isV2Layout && (
                  <>
                    <ModalComponent
                      modalData={this.props.modalData}
                      dispatch={this.props.dispatch}
                      history={this.props.history}
                    />
                    <div className={"modal-close"}>
                      <Icon name="x" onClick={() => this.closeModal()} />
                    </div>
                  </>
                )}
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </IsInsideModalContext.Provider>
    );
  }
}

export default appConnect<ModalConnectedProps, never, ModalOwnProps>(
  (state, _) => {
    return {
      openModal: state.common.openModal,
      modalData: state.common.modalData,
    };
  }
)(Modal);
