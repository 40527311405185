import "../../style/views/remediation_request/SurveyRiskTable.scss";
import { SurveyDetail } from "../../../_common/types/vendor";
import { RemediationRequestRisk } from "../../../_common/types/remediation";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import SurveyName from "../../components/surveys/SurveyName";
import PillLabel from "../../components/PillLabel";
import { LabelColor } from "../../../_common/types/label";
import classNames from "classnames";

interface IRiskSurveyTableProps {
  riskSurveys: SurveyDetail[];
  onSelectRiskSurvey: (selection: number[]) => void;
  selectedSurveys: number[];
  selectedRisk?: RemediationRequestRisk;
  requestId?: number;
  orgAccessNewRiskDesigns?: boolean;
}

export const RiskSurveyTable = (props: IRiskSurveyTableProps) => {
  const columns: IXTableColumnHeader[] = [
    {
      id: "survey",
      text: "Questionnaires triggering this risk",
    },
    {
      id: "public",
      text: "",
    },
    {
      id: "remediation",
      text: "Remediation status",
    },
  ];

  const onSelectClick = (id: number | string) => {
    // if all selected then unselect this one
    const selection = [...props.selectedSurveys];
    const idx = selection.indexOf(id as number);
    if (idx === -1) {
      selection.push(id as number);
    } else {
      selection.splice(idx, 1);
    }
    props.onSelectRiskSurvey(selection);
  };

  const rows: IXTableRow[] = props.riskSurveys
    .filter((rs) => !rs.isWaived)
    .map((r) => {
      // check if the survey risk is in remediation in another remediation request
      // beside the one we are currently editing
      const inRemediation =
        r.inRemediation && props.requestId !== r.remediationRequestId;

      const cells = [];

      if (props.orgAccessNewRiskDesigns) {
        cells.push(
          <XTableCell key={"survey"}>
            <div className="survey-container">
              <SurveyName survey={{ name: r.surveyName, type: r.surveyType }} />
              {r.publicSurvey && (
                <PillLabel color={LabelColor.Orange}>Shared</PillLabel>
              )}
              {inRemediation &&
                !props.selectedRisk?.surveys?.find(
                  (s) => s.surveyId === r.surveyId
                ) && (
                  <PillLabel color={LabelColor.Blue}>In remediation</PillLabel>
                )}
            </div>
          </XTableCell>
        );
      } else {
        cells.push(
          <XTableCell key={"survey"}>
            <SurveyName survey={{ name: r.surveyName, type: r.surveyType }} />
          </XTableCell>,
          <XTableCell key={"public"} className="shrink-cell">
            {r.publicSurvey && (
              <PillLabel color={LabelColor.Orange}>Shared</PillLabel>
            )}
          </XTableCell>,
          <XTableCell key={"remediation"} className="shrink-cell">
            {inRemediation &&
              !props.selectedRisk?.surveys?.find(
                (s) => s.surveyId === r.surveyId
              ) && (
                <PillLabel color={LabelColor.Blue}>In remediation</PillLabel>
              )}
          </XTableCell>
        );
      }

      return {
        id: r.surveyId,
        selectionHidden: !props.orgAccessNewRiskDesigns && inRemediation,
        selectionDisabled: props.orgAccessNewRiskDesigns && inRemediation,
        selectionDisabledHelpText:
          props.orgAccessNewRiskDesigns && inRemediation
            ? "This risk is already under remediation"
            : undefined,
        selected: props.selectedSurveys.includes(r.surveyId),
        onClick: inRemediation ? undefined : () => onSelectClick(r.surveyId),
        cells: cells,
      };
    });

  return (
    <XTable
      className={classNames({
        "survey-risk-table": props.orgAccessNewRiskDesigns,
      })}
      rows={rows}
      columnHeaders={columns}
      hideColumnHeaders
      selectable
      onSelectClick={(id) => onSelectClick(id as number)}
    />
  );
};
