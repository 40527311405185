import { FC, useMemo } from "react";
import "./RiskVendorQuestionnaires.scss";
import PillLabel from "../../components/PillLabel";
import XTable, {
  IIconOption,
  IXTableRow,
  XTableCell,
  SortDirection,
} from "../../../_common/components/core/XTable";
import { formatDateAsLocal } from "../../../_common/helpers";
import { LabelColor } from "../../../_common/types/label";
import {
  VendorSummaryRisk,
  VendorSummaryRiskSurvey,
} from "../../../_common/types/vendorSummary";
import { WaivedAssets } from "../../reducers/risks.actions";
import classnames from "classnames";
import CompensatingControlInfo, {
  CompensatingControlInfoV2,
} from "../../components/questionnaires/CompensatingControlInfo";
import { WaiverType } from "../../../_common/types/vendorRiskWaivers";
import { DropdownItem } from "../../../_common/components/core/DropdownV2";
import { HoverLocation } from "../../../_common/components/IconButton";
import {
  useVendorWords,
  columnSortDef,
  useSorting,
} from "../../../_common/hooks";

interface RiskVendorQuestionnairesProps {
  loading?: boolean;
  error?: any;
  risk?: VendorSummaryRisk;
  questionnaires: VendorSummaryRiskSurvey[];
  onQuestionnaireLinkClick: (survey: VendorSummaryRiskSurvey) => void;
  userHasWriteRiskWaiversPermission: boolean | undefined;
  onRequestRemediation?: (surveyId: number, isPublic: boolean) => void;
  onCreateWaiver?: (surveyId: number, isPublic: boolean) => void;
  onCreateAdjustment?: (surveyId: number, isPublic: boolean) => void;
  isPassive?: boolean;
  readOnly?: boolean;
  waivedAssets?: WaivedAssets;
  onViewWaivers: (
    id: number,
    isPublic?: boolean,
    waiverType?: WaiverType
  ) => void;
  onViewRemediations: (questionnaireID?: number) => void;
  showWaived?: boolean;
  orgAccessNewRiskDesigns?: boolean;
  vendorId?: number;
}

const RiskVendorQuestionnaires: FC<RiskVendorQuestionnairesProps> = ({
  risk,
  questionnaires,
  onQuestionnaireLinkClick,
  userHasWriteRiskWaiversPermission,
  isPassive,
  readOnly,
  waivedAssets,
  onViewWaivers,
  onViewRemediations,
  loading = true,
  showWaived,
  onCreateWaiver,
  onCreateAdjustment,
  onRequestRemediation,
  orgAccessNewRiskDesigns,
  vendorId,
}) => {
  const vendorWords = useVendorWords();

  const tableSortingFuncs = useTableSortingFuncs();
  const [sortedQuestionnaires, sortedBy, onSortChange] = useSorting<
    VendorSummaryRiskSurvey,
    tableColumnId
  >(questionnaires, "questionnaires", SortDirection.ASC, tableSortingFuncs);

  const getRows = () => {
    const rows: IXTableRow[] = [];

    const mappedQuestionnaires = orgAccessNewRiskDesigns
      ? sortedQuestionnaires
      : questionnaires;

    mappedQuestionnaires.forEach((q) => {
      const isPublic = q.publicSurvey;

      const showSentText = !!q.surveyLastSentBy || !!q.surveyLastSent;
      let sentText = q.publicSurvey ? "Published" : "Sent";

      if (q.surveyLastSentBy) sentText += ` by ${q.surveyLastSentBy}`;

      if (q.surveyLastSent) {
        if (orgAccessNewRiskDesigns) {
          sentText += `, ${formatDateAsLocal(q.surveyLastSent)}`;
        } else {
          sentText += ` on ${formatDateAsLocal(q.surveyLastSent)}`;
        }
      }

      const assetId = isPublic
        ? `public_survey_${q.surveyId}`
        : `survey_${q.surveyId}`;
      const isWaivedId = waivedAssets?.assetsWaived[assetId];
      const isPendingWaiverId = waivedAssets?.assetsPendingWaiver[assetId];
      const isPendingSharedWaiverId =
        waivedAssets?.assetsPendingSharedWaiver[assetId];
      const isAdjustedId = waivedAssets?.assetsAdjusted[assetId];
      const isPendingAdjustmentId =
        waivedAssets?.assetsPendingAdjustment[assetId];

      if (!showWaived && isWaivedId) {
        return; // Skip waived assets if not showing waived risks
      }

      const cells = [
        <XTableCell
          key={"qn"}
          className={classnames("questionnaire-cell", {
            waived: !!isWaivedId,
          })}
        >
          <div
            className={"questionnaire-title"}
            onClick={() => onQuestionnaireLinkClick(q)}
          >
            <span className={isPublic ? "shared-questionnaire-name" : ""}>
              {q.surveyName}{" "}
            </span>
            {isPublic && (
              <PillLabel
                color={LabelColor.Orange}
                className={"shared-questionnaire-title"}
              >
                Shared questionnaire
              </PillLabel>
            )}
          </div>
          {showSentText && (
            <div className={"sent-by-text"}>
              <div className={"sent"}>{sentText}</div>
            </div>
          )}
          {!risk?.passed && !orgAccessNewRiskDesigns && (
            <CompensatingControlInfo risk={q} />
          )}
        </XTableCell>,
      ];

      if (orgAccessNewRiskDesigns) {
        const explanationProvided = q.explanation && q.explanation.length > 0;
        const explanationExpected = q.why && q.why.length > 0;

        let compensatingControlsCellMessage = "Not requested";

        if (explanationExpected && !explanationProvided) {
          compensatingControlsCellMessage = "Not provided";
        } else if (explanationExpected && explanationProvided) {
          compensatingControlsCellMessage = "Provided";
        }

        cells.push(
          <XTableCell
            key={"compensating_controls"}
            className={classnames("questionnaire-cell", {
              waived: !!isWaivedId,
            })}
          >
            {compensatingControlsCellMessage}
          </XTableCell>
        );
      }

      const rowDropdownItems: React.ReactNode[] = [];

      const canRemediate =
        !q.inRemediation && !readOnly && !isWaivedId && !risk?.passed;
      if (canRemediate && onRequestRemediation) {
        const requestRemediation = () =>
          onRequestRemediation(q.surveyId, isPublic);

        rowDropdownItems.push(
          <DropdownItem onClick={requestRemediation}>
            Request remediation
          </DropdownItem>
        );
      }

      const canAdjust =
        userHasWriteRiskWaiversPermission &&
        !readOnly &&
        !isWaivedId &&
        !risk?.baseSeverity &&
        !isAdjustedId &&
        !isPendingAdjustmentId &&
        !risk?.passed;

      if (canAdjust && onCreateAdjustment) {
        const adjustRisk = () => onCreateAdjustment(q.surveyId, isPublic);

        rowDropdownItems.push(
          <DropdownItem onClick={adjustRisk}>Adjust risk</DropdownItem>
        );
      }

      const canWaive =
        userHasWriteRiskWaiversPermission &&
        !readOnly &&
        !risk?.isWaived &&
        !isWaivedId &&
        !isPendingWaiverId &&
        !isPendingSharedWaiverId &&
        !risk?.passed;

      if (canWaive && onCreateWaiver) {
        const waiveRisk = () => onCreateWaiver(q.surveyId, isPublic);

        rowDropdownItems.push(
          <DropdownItem onClick={waiveRisk}>Waive risk</DropdownItem>
        );
      }

      const iconOpts: IIconOption[] | undefined =
        rowDropdownItems.length > 0
          ? [
              {
                id: "action",
                icon: <i className={"cr-icon-dots-menu"} />,
                dropdownItems: rowDropdownItems,
                hoverText: "Manage this risk",
                hoverLocation: HoverLocation.Top,
              },
            ]
          : undefined;

      if (!isPassive) {
        cells.push(
          <XTableCell key={"status"} className={"status-cell"}>
            {!risk?.passed && (
              <>
                {q.inRemediation && (
                  <PillLabel
                    color={LabelColor.Blue}
                    capitalized={false}
                    onClick={() => {
                      if (onViewRemediations) {
                        onViewRemediations(q.remediationRequestId);
                      }
                    }}
                  >
                    In Remediation <i className={"cr-icon-chevron"} />
                  </PillLabel>
                )}
                {risk?.isWaived && !isWaivedId && (
                  <PillLabel color={LabelColor.Grey} capitalized={false}>
                    Waived
                  </PillLabel>
                )}
                {isWaivedId && (
                  <PillLabel
                    color={LabelColor.Grey}
                    capitalized={false}
                    onClick={() => onViewWaivers(isWaivedId)}
                  >
                    Waived <i className={"cr-icon-chevron"} />
                  </PillLabel>
                )}
                {isPendingWaiverId && (
                  <PillLabel
                    color={LabelColor.Orange}
                    capitalized={false}
                    onClick={() => onViewWaivers(isPendingWaiverId)}
                  >
                    Waiver Pending <i className={"cr-icon-chevron"} />
                  </PillLabel>
                )}
                {isAdjustedId && (
                  <PillLabel
                    color={LabelColor.TrendyPink}
                    capitalized={false}
                    onClick={() =>
                      onViewWaivers(
                        isAdjustedId,
                        undefined,
                        WaiverType.SeverityAdjustment
                      )
                    }
                  >
                    Adjusted <i className={"cr-icon-chevron"} />
                  </PillLabel>
                )}
                {isPendingAdjustmentId && (
                  <PillLabel
                    color={LabelColor.Violet}
                    capitalized={false}
                    onClick={() =>
                      onViewWaivers(
                        isPendingAdjustmentId,
                        undefined,
                        WaiverType.SeverityAdjustment
                      )
                    }
                  >
                    Adjustment Pending <i className={"cr-icon-chevron"} />
                  </PillLabel>
                )}
                {isPendingSharedWaiverId && (
                  <PillLabel
                    color={LabelColor.Orange}
                    capitalized={false}
                    onClick={() => onViewWaivers(isPendingSharedWaiverId, true)}
                  >
                    Shared Waiver Pending <i className={"cr-icon-chevron"} />
                  </PillLabel>
                )}
              </>
            )}
            {risk?.passed && !risk.isWaived && (
              <PillLabel color={LabelColor.Green} capitalized={false}>
                Passed
              </PillLabel>
            )}
            {risk?.passed && risk.isWaived && (
              <PillLabel color={LabelColor.Grey} capitalized={false}>
                Waived
              </PillLabel>
            )}
          </XTableCell>
        );
      }
      rows.push({
        id: q.surveyId,
        cells: cells,
        iconOptions: rowDropdownItems.length > 0 ? iconOpts : undefined,
        extraContent: orgAccessNewRiskDesigns ? (
          <div>
            {!risk?.passed && (
              <CompensatingControlInfoV2
                risk={{
                  why: q.why,
                  explanation: q.explanation,
                  author: q.author,
                  avatar: q.avatar,
                  sharedAt: q.sharedAt,
                  inRemediation: q.inRemediation,
                  riskVisibility: undefined,
                  surveyId: q.surveyId,
                  answerId: q.answerId,
                  questionId: risk?.baseId ?? risk?.id,
                  vendorId: vendorId,
                }}
                orgAccessNewRiskDesigns={orgAccessNewRiskDesigns}
              />
            )}
          </div>
        ) : undefined,
      });
    });
    return rows;
  };

  const columnHeaders = [];
  if (!risk?.passed) {
    columnHeaders.push({
      id: "questionnaires",
      text: orgAccessNewRiskDesigns
        ? vendorWords.singularTitleCase + " questionnaires affected"
        : "Questionnaires triggering this risk",
      sortable: orgAccessNewRiskDesigns,
    });

    if (orgAccessNewRiskDesigns) {
      columnHeaders.push({
        id: "compensating_controls",
        text: "Compensating controls",
        sortable: orgAccessNewRiskDesigns,
      });
    }
  } else {
    columnHeaders.push({
      id: "questionnaires",
      text: "Questionnaires",
      sortable: orgAccessNewRiskDesigns,
    });
  }

  if (!isPassive) {
    columnHeaders.push({
      id: "status",
      text: "Status",
      sortable: false,
    });
  }
  return (
    <div
      className={classnames("questionnaires-container", {
        "questionnaires-container-new-risk-designs": orgAccessNewRiskDesigns,
      })}
    >
      {(loading || (questionnaires && questionnaires.length > 0)) && (
        <XTable
          stickyColumnHeaders={false}
          loading={loading}
          numLoadingRows={3}
          columnHeaders={columnHeaders}
          rows={getRows()}
          sortedBy={orgAccessNewRiskDesigns ? sortedBy : undefined}
          onSortChange={orgAccessNewRiskDesigns ? onSortChange : undefined}
          iconOptionsHeader={orgAccessNewRiskDesigns ? "Actions" : undefined}
          iconOptions
        />
      )}
      {!loading && (!questionnaires || questionnaires.length === 0) && (
        <div className={"all-waived"}>No questionnaires to display</div>
      )}
    </div>
  );
};

export default RiskVendorQuestionnaires;

type tableColumnId = "questionnaires" | "compensating_controls";

const questionnaireCompensatingControlsToSortableValue = (
  q: VendorSummaryRiskSurvey
) => {
  const explanationProvided = q.explanation && q.explanation.length > 0;
  const explanationExpected = q.why && q.why.length > 0;

  let value = "02_not_requested";

  if (explanationExpected && !explanationProvided) {
    value = "01_not_provided";
  } else if (explanationExpected && explanationProvided) {
    value = "00_provided";
  }

  return value;
};

const useTableSortingFuncs = () =>
  useMemo<Record<tableColumnId, columnSortDef<VendorSummaryRiskSurvey>>>(
    () => ({
      questionnaires: {
        orderFuncs: [(questionnaire) => questionnaire.surveyName],
        sortDirsAsc: ["asc"],
        sortDirsDesc: ["desc"],
      },
      compensating_controls: {
        orderFuncs: [
          (questionnaire) =>
            questionnaireCompensatingControlsToSortableValue(questionnaire),
          (questionnaire) => questionnaire.surveyName,
        ],
        sortDirsAsc: ["asc", "asc"],
        sortDirsDesc: ["desc", "asc"],
      },
    }),
    []
  );
