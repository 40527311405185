import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  NotificationConditionalLogicAction,
  NotificationConditionalLogicState,
  NotificationConditionMode,
  NotificationParameterLimits,
  NotificationParameters,
  NotificationsDefinitionParameter,
  NotificationType,
  validateNotificationConditions,
} from "../../../_common/types/notifications";
import Button from "../../../_common/components/core/Button";
import { ILabel, LabelClassification } from "../../../_common/types/label";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import "../../style/components/EditCustomNotificationModal.scss";
import { VendorTier } from "../../reducers/vendorTiers.actions";
import ModalV2 from "../../../_common/components/ModalV2";
import NumberField, {
  NumberFieldError,
} from "../../../_common/components/NumberField";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import SeveritySelector from "../SeveritySelector";
import {
  SeverityAsString,
  SeverityFromString,
  SeverityInt,
} from "../../../_common/types/severity";
import { Portfolio } from "../../reducers/portfolios.actions";
import SetNotificationConditions from "../SetNotificationConditions";
import {
  VendorAttributeDefinition,
  VendorAttributeDefinitionType,
} from "../../reducers/vendorAttributes.actions";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import { ICreateableDefinition } from "../../reducers/org.actions";
import { CVSSInt } from "../../../_common/types/cvss";
import CVSSSelector from "../CVSSSelector";
import { usePermissions } from "../../../_common/permissions";

interface ISeverityRowProps {
  severity: SeverityInt;
  setSeverity: (val: SeverityInt) => void;
  meta: ICreateableDefinition;
}

const SeverityRow = (props: ISeverityRowProps) => {
  let descriptionText = "";
  switch (props.meta.notificationType) {
    case NotificationType.DomainRiskAdded:
      descriptionText =
        "severity or higher risks are added to my domains and IPs";
      break;
    case NotificationType.DomainRiskRemoved:
      descriptionText =
        "severity or higher risks are resolved for my domains and IPs";
      break;
    case NotificationType.VendorRiskAdded:
      descriptionText = "severity or higher risks are added to my vendors";
      break;
    case NotificationType.VendorRiskRemoved:
      descriptionText = "severity or higher risks are resolved for my vendors";
      break;
    case NotificationType.SurveyRiskAdded:
      descriptionText = "severity or higher risks are added to questionnaires";
      break;
    case NotificationType.SurveyRiskRemoved:
      descriptionText =
        "severity or higher risks are removed from questionnaires";
      break;
    case NotificationType.AdditionalEvidenceRiskAdded:
      descriptionText =
        "severity or higher risks are added to additional evidence";
      break;
    case NotificationType.AdditionalEvidenceRiskRemoved:
      descriptionText =
        "severity or higher risks are removed from additional evidence";
      break;
    default:
      throw "This meta type does not support severity options";
  }

  return (
    <div className={"severity-options"}>
      <div className={"severity-select-row"}>
        When
        <SeveritySelector
          isDisabled={false}
          onChange={(s) => props.setSeverity(SeverityFromString(s))}
          severity={SeverityAsString(props.severity)}
        />
        {descriptionText}
      </div>
    </div>
  );
};

interface ICVSSRowProps {
  cvss: CVSSInt;
  notificationType: NotificationType;
  setCVSS: (val: CVSSInt) => void;
}

function CVSSRow({
  cvss,
  notificationType,
  setCVSS,
}: ICVSSRowProps): React.ReactNode {
  const onChange = useCallback((c?: CVSSInt) => setCVSS(c!), [setCVSS]);

  let descriptionText = "";
  switch (notificationType) {
    case NotificationType.DomainVulnerabilitiesDetected:
      descriptionText =
        "or higher vulnerabilities are detected for my domains and IPs";
      break;
    case NotificationType.DomainVulnerabilityResolved:
      descriptionText =
        "or higher vulnerabilities are resolved for my domains and IPs";
      break;
    case NotificationType.VendorVulnerabilityDetected:
      descriptionText = "or higher vulnerabilities are detected for my vendors";
      break;
    case NotificationType.VendorVulnerabilityResolved:
      descriptionText = "or higher vulnerabilities are resolved for my vendors";
      break;
    default:
      throw "This notification type does not support CVSS options";
  }

  return (
    <div className={"severity-options"}>
      <div className={"severity-select-row"}>
        When CVSS (severity)
        <CVSSSelector cvss={cvss} onChange={onChange} />
        {descriptionText}
      </div>
    </div>
  );
}

interface IDateAttributeRowProps {
  attributes: VendorAttributeDefinition[];
  selectedAttributeId: number;
  setSelectedAttributeId: (id: number) => void;
}

const DateAttributeRow: React.VFC<IDateAttributeRowProps> = (props) => {
  const selectedAttribute = props.attributes.find(
    (a) => a.id == props.selectedAttributeId
  );

  return (
    <div className={"attribute-options"}>
      <span>Select the attribute this notification should apply to:</span>
      <SelectV2
        options={props.attributes
          .filter((a) => a.type == VendorAttributeDefinitionType.Date)
          .map((a) => ({
            label: a.name,
            value: a.id,
          }))}
        value={
          selectedAttribute
            ? { value: selectedAttribute.id, label: selectedAttribute.name }
            : undefined
        }
        onChange={(value) =>
          value && props.setSelectedAttributeId(value.value as number)
        }
      />
    </div>
  );
};

interface IEditCustomNotificationModalProps {
  active: boolean;
  onClose: () => void;
  onSave: () => void;
  onDelete: () => void;
  isNew: boolean;
  createableDefinitions: ICreateableDefinition[];
  selectedType?: NotificationType;
  onSelectType: (type: NotificationType) => void;
  params: NotificationParameters;
  setParams: (params: NotificationParameters) => void;
  conditions: NotificationConditionalLogicState;
  conditionsDispatch: React.Dispatch<NotificationConditionalLogicAction>;
  availableLabels: ILabel[];
  vendorTiers: VendorTier[];
  orgSupportsDomainPortfolios: boolean;
  orgSupportsVendorPortfolios: boolean;
  domainPortfolios: Portfolio[];
  vendorPortfolios: Portfolio[];
  saving: boolean;
  deleting: boolean;
  attributeDefinitions: VendorAttributeDefinition[];
  dispatch: DefaultThunkDispatch;
}

const variableNames = ["X", "Y", "Z"];

const getLabelForDef = (headline: string) => {
  const matches = headline.match(/{{[A-Za-z]+}}/g);
  if (!matches) {
    return headline;
  }

  for (let i = 0; i < matches.length && i < variableNames.length; i++) {
    headline = headline.replace(matches[i], variableNames[i]);
  }

  return headline;
};

const EditCustomNotificationModal = (
  props: IEditCustomNotificationModalProps
) => {
  const [numberErrors, setNumberErrors] = useState<Record<string, string>>({});

  const onNumberFieldError = (error: NumberFieldError, valid: boolean) => {
    if (valid) {
      setNumberErrors({ ...numberErrors, [error.fieldName]: error.message });
    } else {
      const errors = { ...numberErrors };
      delete errors[error.fieldName];
      setNumberErrors(errors);
    }
  };

  const { selectOptions, defsAsMap } = useMemo(() => {
    const selectOptions: Map<string, OptionType[]> = new Map();
    const defsAsMap: Record<string, ICreateableDefinition | undefined> = {};

    props.createableDefinitions.forEach((def) => {
      // Ensure we only show the option for date attribute notifications if at least one exists
      if (
        def.notificationType ===
          NotificationType.VendorDateAttributeNotificationType &&
        !props.attributeDefinitions.some(
          (a) => a.type === VendorAttributeDefinitionType.Date
        )
      ) {
        return;
      }

      defsAsMap[def.notificationType] = def;
      const option = {
        label: getLabelForDef(def.headline),
        value: def.notificationType,
      };
      const categoryOptions = selectOptions.get(def.category[0]);
      if (categoryOptions) {
        categoryOptions.push(option);
      } else {
        selectOptions.set(def.category[0], [option]);
      }
    });

    return {
      selectOptions: Array.from(selectOptions.keys())
        .sort()
        .map((category) => ({
          label: category,
          options: selectOptions.get(category)!,
        })),
      defsAsMap,
    };
  }, [props.createableDefinitions, props.attributeDefinitions]);

  const selectedDef = props.selectedType && defsAsMap[props.selectedType];
  useEffect(() => {
    // when a date attribute notification is selected set the attribute param to a valid value
    if (
      props.selectedType ==
        NotificationType.VendorDateAttributeNotificationType &&
      props.attributeDefinitions.some(
        (a) => a.type == VendorAttributeDefinitionType.Date
      )
    ) {
      const attr = props.attributeDefinitions.find(
        (a) => a.type == VendorAttributeDefinitionType.Date
      );
      props.setParams({
        [NotificationsDefinitionParameter.Attribute]: attr?.id ?? 0,
      });
    }
  }, [props.selectedType, props.attributeDefinitions]);

  const canSubmit =
    !!props.selectedType &&
    Object.keys(numberErrors).length === 0 &&
    validateNotificationConditions(props.conditions);

  const doClose = () => {
    setNumberErrors({});
    props.onClose();
  };

  const doSave = async () => {
    await props.onSave();
  };

  const showParamsEntry =
    !!selectedDef &&
    selectedDef.defaultParameters &&
    Object.keys(selectedDef.defaultParameters).length > 0;

  const permissions = usePermissions();
  const hasVendorRisk = permissions.canAccessVendorRisk;

  // Filter out the portfolios mode if the account doesn't have those feature enabled.
  const filteredSupportedConditionalModes = (
    selectedDef?.supportedConditionalModes ?? []
  ).filter((mode) => {
    if (
      mode === NotificationConditionMode.VendorPortfolios &&
      !props.orgSupportsVendorPortfolios &&
      !hasVendorRisk
    ) {
      return false;
    }

    if (
      mode === NotificationConditionMode.DomainPortfolios &&
      !props.orgSupportsDomainPortfolios
    ) {
      return false;
    }

    if (mode === NotificationConditionMode.Attributes && !hasVendorRisk) {
      return false;
    }

    if (mode === NotificationConditionMode.DomainPortfolios && !hasVendorRisk) {
      return false;
    }

    if (mode === NotificationConditionMode.Tiers && !hasVendorRisk) {
      return false;
    }

    if (
      mode === NotificationConditionMode.Labels &&
      selectedDef?.supportedLabelClassifications?.includes(
        LabelClassification.VendorLabel
      ) &&
      !hasVendorRisk
    ) {
      return false;
    }

    return true;
  });

  return (
    <ModalV2
      className={"edit-custom-notification-modal"}
      active={props.active}
      onClose={doClose}
      headerContent={
        props.isNew ? "Create custom notification" : "Modify notification"
      }
      footerClassName={"edit-custom-notification-footer"}
      footerContent={
        <>
          {!props.isNew && (
            <div className={"footer-left"}>
              <Button
                className={"left-button"}
                onClick={props.onDelete}
                danger
                disabled={props.saving}
                loading={props.deleting}
              >
                <span className={"cr-icon-trash"} /> Delete
              </Button>
            </div>
          )}
          <div className="btn-group">
            <Button
              onClick={doClose}
              tertiary
              disabled={props.saving || props.deleting}
            >
              Cancel
            </Button>
            <Button
              onClick={doSave}
              filledPrimary
              disabled={!canSubmit || props.deleting}
              loading={props.saving}
            >
              {props.isNew ? "Create notification" : "Save changes"}
            </Button>
          </div>
        </>
      }
    >
      <p>
        {props.isNew
          ? "Set up custom notifications for organizations and assets based on different attributes like tiers and labels"
          : "Modify the details of your custom notification below"}
      </p>
      {props.isNew ? (
        <div className={"selected-type"}>
          <SelectV2
            options={selectOptions}
            value={
              props.selectedType && {
                value: props.selectedType,
                label: getLabelForDef(selectedDef?.headline ?? ""),
              }
            }
            onChange={(newVal) => {
              const newType = (newVal as OptionType).value as NotificationType;
              props.onSelectType(newType);
              props.setParams(defsAsMap[newType]?.defaultParameters ?? {});
              props.conditionsDispatch({ type: "init" });
            }}
            placeholder="Select a notification type"
          />
        </div>
      ) : (
        <div className="selected-type">
          {getLabelForDef(selectedDef?.headline ?? "")}
        </div>
      )}
      {showParamsEntry && (
        <div className={"params-entry-container"}>
          <div className={"params-entry"}>
            {!selectedDef.defaultParameters[
              NotificationsDefinitionParameter.Severity
            ] ||
              (NotificationsDefinitionParameter.Attribute in
                selectedDef.defaultParameters && (
                <span>{selectedDef.headline}</span>
              ))}
            {selectedDef.defaultParameters.Threshold && (
              <NumberField
                className={"threshold-input"}
                value={props.params.Threshold}
                onChanged={(val) =>
                  props.setParams({
                    ...props.params,
                    [NotificationsDefinitionParameter.Threshold]: val,
                  })
                }
                min={NotificationParameterLimits.Threshold.min}
                max={NotificationParameterLimits.Threshold.max}
                name={"score"}
                placeholder={"Score"}
                onError={onNumberFieldError}
              />
            )}
            {selectedDef.defaultParameters.InDays && (
              <>
                <span> in the last </span>
                <NumberField
                  className={"days-input"}
                  name={"days"}
                  placeholder={"Days"}
                  value={props.params.InDays}
                  onChanged={(val) =>
                    props.setParams({
                      ...props.params,
                      [NotificationsDefinitionParameter.InDays]: val,
                    })
                  }
                  min={NotificationParameterLimits.InDays.min}
                  max={NotificationParameterLimits.InDays.Max}
                  onError={onNumberFieldError}
                />
                <span>days</span>
              </>
            )}
          </div>
          <div className={"error-messages"}>
            <TransitionGroup>
              {Object.keys(numberErrors).map((name) => (
                <CSSTransition
                  key={name}
                  timeout={250}
                  classNames={"fade-transition"}
                >
                  <div className={"number-error"}>
                    <i className={"icon-info"} />
                    <div className={"number-error-message"}>
                      {numberErrors[name]}
                    </div>
                  </div>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
        </div>
      )}
      {!!selectedDef?.defaultParameters[
        NotificationsDefinitionParameter.Severity
      ] && (
        <SeverityRow
          severity={props.params.Severity ?? SeverityInt.MediumSeverity}
          setSeverity={(s) =>
            props.setParams({
              ...props.params,
              [NotificationsDefinitionParameter.Severity]: s,
            })
          }
          meta={selectedDef}
        />
      )}
      {typeof selectedDef?.defaultParameters[
        NotificationsDefinitionParameter.CVSS
      ] === "number" && (
        <CVSSRow
          cvss={props.params.CVSS ?? CVSSInt.ZeroCVSS}
          setCVSS={(c) =>
            props.setParams({
              ...props.params,
              [NotificationsDefinitionParameter.CVSS]: c,
            })
          }
          notificationType={selectedDef.notificationType}
        />
      )}
      {!!selectedDef &&
        NotificationsDefinitionParameter.Attribute in
          selectedDef.defaultParameters && (
          <DateAttributeRow
            attributes={props.attributeDefinitions}
            setSelectedAttributeId={(id) => {
              props.setParams({
                ...props.params,
                [NotificationsDefinitionParameter.Attribute]: id,
              });
            }}
            selectedAttributeId={props.params.Attribute ?? 0}
          />
        )}
      {filteredSupportedConditionalModes.length > 0 && (
        <SetNotificationConditions
          conditions={props.conditions}
          conditionsDispatch={props.conditionsDispatch}
          supportedConditionalModes={filteredSupportedConditionalModes}
          supportedLabelClassifications={
            selectedDef?.supportedLabelClassifications
          }
          availableLabels={props.availableLabels}
          vendorTiers={props.vendorTiers}
          domainPortfolios={props.domainPortfolios}
          vendorPortfolios={props.vendorPortfolios}
          attributeDefinitions={props.attributeDefinitions}
          dispatch={props.dispatch}
        />
      )}
    </ModalV2>
  );
};

export default EditCustomNotificationModal;
