import { SeverityInt } from "../../../_common/types/severity";
import { FC, useCallback, useState } from "react";
import { SlidePanelSection } from "./SlidePanelSection";
import ColorCheckbox from "../ColorCheckbox";
import { severityMap } from "../../../_common/helpers";

import "../../style/components/filter/SeverityFilter.scss";

interface SeverityFilterProps {
  excludedSeverities: SeverityInt[];
  onChange: (newExcludedSeverities: SeverityInt[]) => void;
  title: string;
  startExpanded?: boolean;
}

const SeverityFilter: FC<SeverityFilterProps> = ({
  excludedSeverities = [],
  onChange,
  title,
  startExpanded = false,
}) => {
  const [expanded, setExpanded] = useState(
    startExpanded || excludedSeverities.length > 0
  );

  const onClickCheckbox = useCallback(
    (sev: SeverityInt) => {
      const newExcluded = [...excludedSeverities];
      const severityPosition = newExcluded.indexOf(sev);
      if (severityPosition === -1) {
        newExcluded.push(sev);
      } else {
        newExcluded.splice(severityPosition, 1);
      }

      onChange(newExcluded);
    },
    [onChange, excludedSeverities]
  );

  return (
    <SlidePanelSection
      title={title}
      expanded={expanded}
      toggleExpand={() => setExpanded(!expanded)}
    >
      <div className="filter-severities">
        <ColorCheckbox
          label={<>{severityMap.critical.icon} Critical</>}
          onClick={() => onClickCheckbox(SeverityInt.CriticalSeverity)}
          checked={!excludedSeverities.includes(SeverityInt.CriticalSeverity)}
        />
        <ColorCheckbox
          label={<>{severityMap.high.icon} High</>}
          onClick={() => onClickCheckbox(SeverityInt.HighSeverity)}
          checked={!excludedSeverities.includes(SeverityInt.HighSeverity)}
        />
        <ColorCheckbox
          label={<>{severityMap.medium.icon} Medium</>}
          onClick={() => onClickCheckbox(SeverityInt.MediumSeverity)}
          checked={!excludedSeverities.includes(SeverityInt.MediumSeverity)}
        />
        <ColorCheckbox
          label={<>{severityMap.low.icon} Low</>}
          onClick={() => onClickCheckbox(SeverityInt.LowSeverity)}
          checked={!excludedSeverities.includes(SeverityInt.LowSeverity)}
        />
      </div>
    </SlidePanelSection>
  );
};

export default SeverityFilter;
