import {
  AnswersForNodes,
  FilteredNodes,
  FilteredNodesIdx,
  NodeSummaryAndNode,
  SelectAnswers,
} from "../surveyViewer.helpers";
import { SurveyLockState } from "./apiActions";
import {
  ICorrespondenceMessage,
  SurveyQuestionMessageMeta,
} from "../../_common/types/correspondenceMessage";
import { surveyViewerRightPanelMode, SurveyViewFilterType } from "./reducer";
import {
  ExternalDocument,
  GptUserPreferences,
} from "../components/types/autofill.types";
import {
  GptAutofillCacheStatus,
  GptAutofillSuggestion,
  SurveyIDWithPublic,
} from "./autofill.actions";
import { vsaqQuestion } from "../../survey_builder/vsaq/vsaqTypes";
import { IVendorRiskWaiver } from "../../_common/types/vendorRiskWaivers";

export type SurveyViewerActions =
  | ResetStateAction
  | SetNodeTreeAction
  | SetAnswersAction
  | SetAnswerAction
  | SetActiveNodeAction
  | SetScrollTargetNodeAction
  | ClearAnswersPendingSaveAction
  | SetLockStateAction
  | SetSidebarExpandedStateAction
  | SetScrollNextUnansweredQuestionAction
  | SetRightPanelStateAction
  | SetQuestionCommentsAction
  | SetEditStateAction
  | SetSecondaryAnswersAction
  | SetAnswersAndSwapAction
  | CloseRightPanelHeaderAction
  | SetGptSources
  | SetExcelSourceDoc
  | SetGptAutofillCache
  | UpdateGptAutofillCache
  | SetLatestGptSuggestions
  | UpdateGptAcceptRejectSuggestion
  | UpdateGptUserPreferences
  | SetCurrentFilterAction
  | SetCurrentFilteredItemAction
  | SetFilterNodeSetDefinitionsAction
  | SetAddRiskModalStateAction
  | AddNewRiskNodeToTree
  | DeleteRiskNodeFromTree
  | EditManualRiskNode
  | SetRiskAdjustments;

export const RESET_STATE = "RESET_STATE";

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export const SET_NODE_TREE = "SET_NODE_TREE";

interface SetNodeTreeAction {
  type: typeof SET_NODE_TREE;
  nodeTree: NodeSummaryAndNode;
}

export function setNodeTree(nodeTree: NodeSummaryAndNode) {
  return {
    type: SET_NODE_TREE,
    nodeTree,
  };
}

export const SET_ANSWERS = "SET_ANSWERS";

interface SetAnswersAction {
  type: typeof SET_ANSWERS;
  answers: AnswersForNodes;
}

export function setAnswers(answers: AnswersForNodes) {
  return {
    type: SET_ANSWERS,
    answers,
  };
}

export const SET_ANSWER = "SET_ANSWER";

interface SetAnswerAction {
  type: typeof SET_ANSWER;
  nodeId: string;
  answer: undefined | string | SelectAnswers;
  acceptingSuggestion?: boolean;
}

export function setAnswer(
  nodeId: string,
  answer: undefined | string | SelectAnswers,
  acceptingSuggestion?: boolean
) {
  return {
    type: SET_ANSWER,
    nodeId,
    answer,
    acceptingSuggestion,
  };
}

export const SET_ACTIVE_NODE = "SET_ACTIVE_NODE";

interface SetActiveNodeAction {
  type: typeof SET_ACTIVE_NODE;
  nodeId: string;
}

export function setActiveNode(nodeId: string) {
  return {
    type: SET_ACTIVE_NODE,
    nodeId,
  };
}

export const SET_SCROLL_TARGET_NODE = "SET_SCROLL_TARGET_NODE";

interface SetScrollTargetNodeAction {
  type: typeof SET_SCROLL_TARGET_NODE;
  nodeId: string | undefined;
  setActiveNode: boolean;
}

export function setScrollTargetNode(
  nodeId: string | undefined,
  setActiveNode: boolean
) {
  return {
    type: SET_SCROLL_TARGET_NODE,
    nodeId,
    setActiveNode,
  };
}

export const CLEAR_ANSWERS_PENDING_SAVE = "CLEAR_ANSWERS_PENDING_SAVE";

interface ClearAnswersPendingSaveAction {
  type: typeof CLEAR_ANSWERS_PENDING_SAVE;
  answers: AnswersForNodes;
}

export function clearAnswersPendingSave(answers: AnswersForNodes) {
  return {
    type: CLEAR_ANSWERS_PENDING_SAVE,
    answers,
  };
}

export const SET_LOCK_STATE = "SET_LOCK_STATE";

interface SetLockStateAction {
  type: typeof SET_LOCK_STATE;
  lockDetails: SurveyLockState;
}

export function setLockState(lockDetails: SurveyLockState) {
  return {
    type: SET_LOCK_STATE,
    lockDetails,
  };
}

export const SET_SIDEBAR_EXPANDED_STATE = "SET_SIDEBAR_EXPANDED_STATE";

interface SetSidebarExpandedStateAction {
  type: typeof SET_SIDEBAR_EXPANDED_STATE;
  nodeIds: string[];
  state: boolean;
}

export function setSidebarExpandedState(nodeIds: string[], state: boolean) {
  return {
    type: SET_SIDEBAR_EXPANDED_STATE,
    nodeIds,
    state,
  };
}

export const SET_SCROLL_NEXT_UNANSWERED_QUESTION =
  "SET_SCROLL_NEXT_UNANSWERED_QUESTION";

interface SetScrollNextUnansweredQuestionAction {
  type: typeof SET_SCROLL_NEXT_UNANSWERED_QUESTION;
}

export function setScrollNextUnansweredQuestion() {
  return {
    type: SET_SCROLL_NEXT_UNANSWERED_QUESTION,
  };
}

export const SET_QUESTION_COMMENTS = "SET_QUESTION_COMMENTS";

interface SetQuestionCommentsAction {
  type: typeof SET_QUESTION_COMMENTS;
  messages: ICorrespondenceMessage<SurveyQuestionMessageMeta>[];
  questionId?: string;
}

// Add question messages to the survey viewer redux state so they can be used in various places
export function setQuestionComments(
  messages: ICorrespondenceMessage<SurveyQuestionMessageMeta>[],
  questionId?: string
) {
  return {
    type: SET_QUESTION_COMMENTS,
    messages,
    questionId,
  };
}

export const SET_RIGHT_PANEL_STATE = "SET_RIGHT_PANEL_STATE";

interface SetRightPanelStateAction {
  type: typeof SET_RIGHT_PANEL_STATE;
  questionId: string | undefined;
  active: boolean;
  mode: surveyViewerRightPanelMode;
}

// Show / hide the right panel and set its state
export function setRightPanelState(
  mode: surveyViewerRightPanelMode,
  questionId: string | undefined, // Pass undefined for questionId to show groups in Comments mode
  active: boolean
) {
  return {
    type: SET_RIGHT_PANEL_STATE,
    mode,
    questionId,
    active,
  };
}

export const CLOSE_RIGHT_PANEL_HEADER = "CLOSE_RIGHT_PANEL_HEADER";

interface CloseRightPanelHeaderAction {
  type: typeof CLOSE_RIGHT_PANEL_HEADER;
}

// close the right panel header with out changing the rest of the right panel state
export const closeRightPanelHeader = () => ({
  type: CLOSE_RIGHT_PANEL_HEADER,
});

// Show / hide the comment panel
// Pass undefined for questionId to show groups
export function setCurrentCommentQuestion(
  questionId: string | undefined,
  active: boolean,
  privateCommentaryOnly?: boolean
) {
  return setRightPanelState(
    privateCommentaryOnly
      ? surveyViewerRightPanelMode.CommentsPrivateOnly
      : surveyViewerRightPanelMode.Comments,
    questionId,
    active
  );
}

export const SET_EDIT_STATE = "SET_EDIT_STATE";

interface SetEditStateAction {
  type: typeof SET_EDIT_STATE;
  editState: boolean;
}

// Manually set the editing state of the survey viewer
// Used to reset when closing the viewer
export function setEditState(editState: boolean) {
  return {
    type: SET_EDIT_STATE,
    editState,
  };
}

export const SET_SECONDARY_ANSWERS = "SET_SECONDARY_ANSWERS";

interface SetSecondaryAnswersAction {
  type: typeof SET_SECONDARY_ANSWERS;
  answers: AnswersForNodes;
}

export const setLeftAnswers = (answers: AnswersForNodes) => ({
  type: SET_SECONDARY_ANSWERS,
  answers,
});

export const SET_ANSWERS_AND_SWAP = "SET_ANSWERS_AND_SWAP";

interface SetAnswersAndSwapAction {
  type: typeof SET_ANSWERS_AND_SWAP;
  answers: AnswersForNodes;
}

// sets the primary answers as the given answers and updates left answers to the current answers
export const setAnswersAndSwap = (answers: AnswersForNodes) => ({
  type: SET_ANSWERS_AND_SWAP,
  answers,
});

export const SET_GPT_SOURCES = "SET_GPT_SOURCES";

interface SetGptSources {
  type: typeof SET_GPT_SOURCES;
  docs: ExternalDocument[];
  surveys: SurveyIDWithPublic[];
}

export const setGptSources = (
  docs: ExternalDocument[],
  surveys: SurveyIDWithPublic[]
) => ({
  type: SET_GPT_SOURCES,
  docs,
  surveys,
});

export const SET_EXCEL_SOURCE_DOC = "SET_EXCEL_SOURCE_DOC";

interface SetExcelSourceDoc {
  type: typeof SET_EXCEL_SOURCE_DOC;
  doc: ExternalDocument;
}

export const setExcelSourceDoc = (doc: ExternalDocument) => ({
  type: SET_EXCEL_SOURCE_DOC,
  doc,
});

export const SET_GPT_AUTOFILL_CACHE = "SET_GPT_AUTOFILL_CACHE";

interface SetGptAutofillCache {
  type: typeof SET_GPT_AUTOFILL_CACHE;
  cache: GptAutofillCacheStatus;
}

export const setGptAutofillCache = (cache: GptAutofillCacheStatus) => ({
  type: SET_GPT_AUTOFILL_CACHE,
  cache,
});

export const UPDATE_GPT_AUTOFILL_CACHE = "UPDATE_GPT_AUTOFILL_CACHE";

interface UpdateGptAutofillCache {
  type: typeof UPDATE_GPT_AUTOFILL_CACHE;
  newSuggestions: Record<string, GptAutofillSuggestion>;
  progress: number;
  done: boolean;
}

export const updateGptAutofillCache = (
  newSuggestions: Record<string, GptAutofillSuggestion>,
  progress: number,
  done: boolean
) => ({
  type: UPDATE_GPT_AUTOFILL_CACHE,
  newSuggestions,
  progress,
  done,
});

export const SET_LATEST_GPT_SUGGESTIONS = "SET_LATEST_GPT_SUGGESTIONS";

interface SetLatestGptSuggestions {
  type: typeof SET_LATEST_GPT_SUGGESTIONS;
  newSuggestionQuestionIDs: string[];
}

export const setLatestGptSuggestions = (
  newSuggestionQuestionIDs: string[]
) => ({
  type: SET_LATEST_GPT_SUGGESTIONS,
  newSuggestionQuestionIDs,
});

export const UPDATE_GPT_ACCEPT_REJECT_SUGGESTION =
  "UPDATE_GPT_ACCEPT_REJECT_SUGGESTION";

interface UpdateGptAcceptRejectSuggestion {
  type: typeof UPDATE_GPT_ACCEPT_REJECT_SUGGESTION;
  suggestionID: number;
  accept: boolean;
}

export const updateGptAcceptRejectSuggestion = (
  suggestionID: number,
  accept: boolean
) => ({
  type: UPDATE_GPT_ACCEPT_REJECT_SUGGESTION,
  suggestionID,
  accept,
});

export const UPDATE_GPT_USER_PREFERENCES = "UPDATE_GPT_USER_PREFERENCES";

interface UpdateGptUserPreferences {
  type: typeof UPDATE_GPT_USER_PREFERENCES;
  preferences: GptUserPreferences;
}

export const updateGptUserPreferences = (preferences: GptUserPreferences) => ({
  type: UPDATE_GPT_USER_PREFERENCES,
  preferences,
});

export const SET_CURRENT_FILTER = "SET_CURRENT_FILTER";
interface SetCurrentFilterAction {
  type: typeof SET_CURRENT_FILTER;
  filter: SurveyViewFilterType;
}
export const setCurrentSelectedFilter = (filter: SurveyViewFilterType) => ({
  type: SET_CURRENT_FILTER,
  filter,
});

export const SET_CURRENT_FILTERED_ITEM = "SET_CURRENT_FILTERED_ITEM";
interface SetCurrentFilteredItemAction {
  type: typeof SET_CURRENT_FILTERED_ITEM;
  index: number;
}
export const setCurrentFilteredItem = (idx: number) => ({
  type: SET_CURRENT_FILTERED_ITEM,
  index: idx,
});

export const SET_FILTER_NODESET_DEFINITIONS = "SET_FILTER_NODESET_DEFINITIONS";
interface SetFilterNodeSetDefinitionsAction {
  type: typeof SET_FILTER_NODESET_DEFINITIONS;
  numQuestions: number;
  nodeSets: { [filterType: string]: FilteredNodes };
  nodeSetIndexes: {
    [filterType: string]: FilteredNodesIdx;
  };
}
export const setFilterNodeSetDefinitions = (
  numQuestions: number,
  nodeSets: { [filterType: string]: FilteredNodes },
  nodeSetIndexes: {
    [filterType: string]: FilteredNodesIdx;
  }
) => ({
  type: SET_FILTER_NODESET_DEFINITIONS,
  numQuestions,
  nodeSets,
  nodeSetIndexes,
});

export const SET_ADD_RISK_MODAL_STATE = "SET_ADD_RISK_MODAL_STATE";
type SetAddRiskModalStateAction =
  | {
      type: typeof SET_ADD_RISK_MODAL_STATE;
      newState: false;
    }
  | {
      type: typeof SET_ADD_RISK_MODAL_STATE;
      newState: true;
      parentID: string;
      editRiskId?: number;
      editNodeId?: string;
    };
export const setAddRiskModalState = (
  active: boolean,
  parentID?: string,
  editRiskId?: number,
  editNodeId?: string
) => ({
  type: SET_ADD_RISK_MODAL_STATE,
  newState: active,
  parentID,
  editRiskId,
  editNodeId,
});

export const ADD_NEW_RISK_NODE_TO_TREE = "ADD_NEW_RISK_NODE_TO_TREE";
type AddNewRiskNodeToTree = {
  type: typeof ADD_NEW_RISK_NODE_TO_TREE;
  newRiskNode: vsaqQuestion;
};
export const addNewRiskNodeToTree = (newRiskNode: vsaqQuestion) => ({
  type: ADD_NEW_RISK_NODE_TO_TREE,
  newRiskNode,
});

export const DELETE_RISK_NODE_FROM_TREE = "DELETE_RISK_NODE_FROM_TREE";
type DeleteRiskNodeFromTree = {
  type: typeof DELETE_RISK_NODE_FROM_TREE;
  nodeId: string;
  parentNodeId: string;
};
export const deleteRiskNodeFromTree = (
  nodeId: string,
  parentNodeId: string
) => ({
  type: DELETE_RISK_NODE_FROM_TREE,
  nodeId,
  parentNodeId,
});

export const EDIT_MANUAL_RISK_NODE = "EDIT_MANUAL_RISK_NODE";
type EditManualRiskNode = {
  type: typeof EDIT_MANUAL_RISK_NODE;
  nodeId: string;
  newRiskId: number;
  newRiskName: string;
  newRiskImpact: string;
  newRiskSeverity: string;
};
export const editManualRiskNode = (
  nodeId: string,
  newRiskId: number,
  newRiskName: string,
  newRiskImpact: string,
  newRiskSeverity: string
) => ({
  type: EDIT_MANUAL_RISK_NODE,
  nodeId,
  newRiskId,
  newRiskName,
  newRiskImpact,
  newRiskSeverity,
});

export const SET_RISK_ADJUSTMENTS = "SET_RISK_ADJUSTMENTS";
type SetRiskAdjustments = {
  type: typeof SET_RISK_ADJUSTMENTS;
  adjustments: IVendorRiskWaiver[];
};
export const setRiskAdjustments = (adjustments: IVendorRiskWaiver[]) => ({
  type: SET_RISK_ADJUSTMENTS,
  adjustments,
});
