import { SeverityInt } from "./severity";
import { VendorSummaryRiskType } from "./vendorSummary";
import { Portfolio } from "../../vendorrisk/reducers/portfolios.actions";

export enum RiskStatus {
  Created = "Created",
  Disclosed = "Disclosed",
  Acknowledged = "Acknowledged",
  Investigating = "Investigating",
  Remediating = "Remediating",
  ClosedNoIssue = "ClosedNoIssue",
  ClosedRiskAccepted = "ClosedRiskAccepted",
  ClosedFixedUnverified = "ClosedFixedUnverified",
  ClosedFixedVerified = "ClosedFixedVerified",

  // Cloudscan risk statuses
  Open = "Open",
  Closed = "Closed",
  Unknown = "Unknown",
  Draft = "Draft",
  Waived = "Waived",

  // Answer risk statuses
  Archived = "Archived",

  // Derived statuses
  Partial = "Partial",
}

export enum RemediatedStatus {
  Remediated = "Remediated",
  NotRemediated = "Not remediated",
  PartiallyRemediated = "Partially remediated",
  Archived = "Archived",
  Waived = "Waived",
}

export const GetRemediatedStatus = (s: RiskStatus): RemediatedStatus => {
  switch (s) {
    case RiskStatus.Open:
      return RemediatedStatus.NotRemediated;
    case RiskStatus.Closed:
      return RemediatedStatus.Remediated;
    case RiskStatus.Waived:
      return RemediatedStatus.Waived;
    case RiskStatus.Partial:
      return RemediatedStatus.PartiallyRemediated;
    case RiskStatus.Archived:
      return RemediatedStatus.Archived;
    default:
      return RemediatedStatus.NotRemediated;
  }
};

export enum RiskCategory {
  WebsiteSec = "website_sec",
  EmailSec = "email_sec",
  NetworkSec = "network_sec",
  Phishing = "phishing",
  BrandProtect = "brand_protect",
  QuestionnaireRisks = "questionnaire_risks",
  Vulns = "vulns",
  Typosquatting = "typosquatting",
  EmailExposures = "emailexposures",
  DataLeaks = "dataleaks",
  AdditionalEvidenceRisks = "additional_evidence",
  Website = "website_sec_v2",
  Email = "email_sec_v2",
  Network = "network_sec_v2",
  BrandReputation = "brand_protect_v2",
  IPDomainReputation = "ip_domain_reputation",
  Encryption = "encryption",
  DNS = "dns",
  DataLeakage = "data_leakage",
  VulnerabilityManagement = "patch_management",
  AttackSurface = "attack_surface",
}

export enum RiskSource {
  BreachSight = "BreachSight",
  Cloudscan = "Cloudscan",
  Survey = "Survey",
  AdditionalEvidence = "AdditionalEvidence",
  Other = "Other",
}

export type RiskType =
  | "cloudscan"
  | "survey"
  | "evidence"
  | "other"
  | "saas"
  | "appguard_package_vuln"
  | "appguard_repo_config";

export interface CloudscanRiskMetadata {
  CVENames?: string[] | null; // null means 'select all'
}

export interface GenericRisk {
  id: number;
  orgId: number;
  vendorId: number;
  riskId: number;
  riskStatus: RiskStatus;
  checkId: string;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
}

export interface CloudscanRisk extends GenericRisk {
  websites: string[];
  resolvedWebsites: string[];
  metadata?: { cveNames: string[] };
}

export interface SurveyRisk extends GenericRisk {
  riskName: string;
  riskText: string;
  riskSeverity: string;
  riskWhy: string;
  riskExplanation: string;
  riskCategory: string;
  riskImpact: string;
  surveyName: string;
}

export interface GenericRiskMeta {
  group: string;
  groupTitle: string;
  factCategory: RiskCategory;
  description: string;
  passTitle: string;
  passDescription: string;
  failTitle: string;
  failDescription: string;
  remediation: string;
  defaultSeverity: SeverityInt;
}

export interface GenericRiskWithSourceAndMeta<
  R extends GenericRisk = GenericRisk,
> {
  risk: R;
  meta: GenericRiskMeta;
  source: RiskSource;
}

export interface RiskMini {
  id: string;
  title: string;
  riskType: VendorSummaryRiskType;
  passed?: boolean;
  factCategory: RiskCategory;
}

export interface RiskHostnameDetail {
  dateDetected: string;
  hostname: string;
  isOrgCustom: boolean;
  pass: boolean;
  riskId: string;
  scannedAt: string;
  score: number;
  portfolios?: Portfolio[];
}

export type RiskHostnameDetailMap = { [hostname: string]: RiskHostnameDetail };

export type IRiskHostnames = Record<string, IRiskHostnameKeyData | undefined>;

export interface IRiskHostnameKeyData {
  loading: boolean;
  error: any;
  data?: IRiskHostnameData;
  websiteLabelIds: string[];
  domainPortfolioIds: number[];
  skipWaiverId?: number;
  includeWaived: boolean;
}

export interface IRiskHostnameData {
  hostnamesWithRisk?: string[];
  riskDetailsByHostname: RiskHostnameDetailMap;
}
