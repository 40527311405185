import { useBack } from "../../_common/types/router";
import { FC, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PageHeader from "../../_common/components/PageHeader";
import userbaseApi from "../api/userbase.api";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { usePagination, useSorting } from "../../_common/hooks";
import { IdentityBreach } from "../api/types";
import IdentityBreachSeverityIcon from "../components/IdentityBreaches/IdentityBreachSeverity";
import IdentityBreachDate from "../components/IdentityBreaches/IdentityBreachDate";
import IdentityBreachDetails from "../components/IdentityBreaches/IdentityBreachDetails";
import IdentityBreachDataClasses from "../components/IdentityBreaches/IdentityBreachDataClasses";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import SearchBox from "../../_common/components/SearchBox";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import ReportCard from "../../_common/components/ReportCard";
import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";
import "./IdentityBreachesView.scss";
import { identityBreachesUrl, identityBreachUrl } from "../UserBaseAppRouter";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import LoadingBanner from "../../_common/components/core/LoadingBanner";

const IdentityBreachesView: FC = ({}) => {
  const history = useHistory();
  const { backAction, backText } = useBack();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState("");

  const { data, isLoading } =
    userbaseApi.useGetUserBaseIdentityBreachesV1Query();

  const breaches = data?.breaches ?? [];
  const filteredBreaches = useMemo(() => {
    if (!searchText) {
      return breaches;
    }
    return breaches.filter((b) => {
      const lowerSearchText = searchText.toLocaleLowerCase();
      return (
        b.name.toLocaleLowerCase().includes(lowerSearchText) ||
        b.title.toLocaleLowerCase().includes(lowerSearchText) ||
        b.description.toLocaleLowerCase().includes(lowerSearchText) ||
        b.dataClasses
          ?.map((dt) => dt.Class.toLocaleLowerCase())
          .join(",")
          .includes(lowerSearchText)
      );
    });
  }, [breaches, searchText]);

  const columnHeaders: IXTableColumnHeader[] = [
    {
      id: "severity",
      text: "Severity",
      sortable: true,
      className: "severity-col",
    },
    {
      id: "breach_date",
      text: "Date of breach",
      sortable: true,
      className: "breach-date-col",
    },
    {
      id: "published_date",
      text: "Published date",
      sortable: true,
      className: "published-date-col",
    },
    {
      id: "breach_type",
      text: "Breach type",
      sortable: true,
      className: "breach-type-col",
    },
    {
      id: "details",
      text: "Details",
      sortable: true,
      className: "details-col",
    },
    {
      id: "data_classes",
      text: "Data exposed",
      sortable: true,
      className: "data-classes-col",
    },
    {
      id: "status",
      text: "Status",
      sortable: true,
      className: "status-col",
    },
  ];

  const [sortedBreaches, sortedBy, onSortChange] = useSorting<
    IdentityBreach,
    | "severity"
    | "breach_date"
    | "published_date"
    | "breach_type"
    | "details"
    | "data_classes"
    | "status"
  >(
    filteredBreaches,
    "published_date",
    SortDirection.ASC,
    {
      severity: {
        orderFuncs: [
          (b) => (b.archived ? -2 : b.isDarkWebBreach ? -1 : b.riskScore),
          (b) => b.name,
        ],
        sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
        sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      },
      breach_date: {
        orderFuncs: [(b) => b.breachDate],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      published_date: {
        orderFuncs: [(b) => b.publishedDate],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      breach_type: {
        orderFuncs: [(b) => b.breachType],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      details: {
        orderFuncs: [(b) => b.title.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      data_classes: {
        orderFuncs: [
          (b) =>
            b.dataClasses
              ?.map((dt) => dt.Class.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      status: {
        orderFuncs: [(b) => (b.archived ? -1 : 1)],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    pathname
  );

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedBreaches,
    10,
    undefined,
    pathname
  );

  const goToIdentityBreachPage = (breach: IdentityBreach) => {
    history.push(identityBreachUrl(breach.id), {
      from: "identity breaches",
      backContext: {
        backTo: identityBreachesUrl,
        backToText: "Back to Identity Breaches",
      },
    });
  };

  const rows = pageItems.map<IXTableRow>((breach) => {
    return {
      id: breach.name,
      onClick: () => goToIdentityBreachPage(breach),
      cells: [
        <XTableCell key="severity" className="breach-severity">
          <IdentityBreachSeverityIcon breach={breach} />
        </XTableCell>,
        <XTableCell key="breach_date" className="breach-date">
          <IdentityBreachDate breach={breach} />
        </XTableCell>,
        <XTableCell key="published_date" className="breach-published-date">
          <DateTimeFormat dateTime={breach.publishedDate} dateOnly />
        </XTableCell>,
        <XTableCell key="breach_type" className="breach-type">
          {breach.breachType}
        </XTableCell>,
        <XTableCell key="details" className="breach-details">
          <IdentityBreachDetails breach={breach} />
        </XTableCell>,
        <XTableCell key="data_classes" className="breach-data-classes">
          <IdentityBreachDataClasses breach={breach} maxWidth={250} />
        </XTableCell>,
        <XTableCell key="status" className="breach-status">
          {breach.archived ? (
            <PillLabel color={LabelColor.Grey}>Archived</PillLabel>
          ) : (
            <PillLabel color={LabelColor.Green}>Active</PillLabel>
          )}
        </XTableCell>,
      ],
    };
  });

  const showEmptySearchCard =
    !isLoading && searchText.length > 0 && sortedBreaches.length === 0;
  const showEmptyCard =
    !isLoading && searchText.length === 0 && sortedBreaches.length === 0;

  const showTable = !isLoading && sortedBreaches.length > 0;

  return (
    <div className={"userbase-identity-breaches"}>
      <PageHeader
        history={history}
        title="Identity Breaches"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey={"userbaseIdentityBreaches"}
        infoSection={
          <p>
            Identity breaches provides information about third-party data
            breaches that exposed your employees&apos; credentials. When we find
            a match, we&apos;ll let you know and allow you to notify any
            impacted employees. The severity of a breach depends on the type and
            amount of data exposed.
          </p>
        }
      />
      <ReportCard newStyles>
        <SearchBox
          onChanged={(v) => setSearchText(v)}
          value={searchText}
          disabled={isLoading}
        />
        {isLoading && <LoadingBanner />}
        {showEmptySearchCard && (
          <SearchEmptyCard
            onClear={() => setSearchText("")}
            searchItemText={"identity breaches"}
          />
        )}
        {showEmptyCard && (
          <EmptyCard text="There are no identity breaches yet." />
        )}
        {showTable && (
          <XTable
            stickyColumnHeaders={false}
            numLoadingRows={3}
            className={"identity-breaches-container"}
            columnHeaders={columnHeaders}
            rows={rows}
            loading={isLoading}
            iconOptions
            sortedBy={sortedBy}
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default IdentityBreachesView;
