import { cloneElement, ReactNode, useEffect, useState } from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import LoadingBanner from "./core/LoadingBanner";
import { v4 as uuidv4 } from "uuid";
import {
  clearCommonState,
  loginUser,
  setCyberRiskAuth,
  setUserData,
} from "../reducers/commonActions";
import { DefaultThunkDispatch } from "../types/redux";
import Button from "./core/Button";
import "../style/components/AuthMessage.scss";
import {
  clearLocalStorageItem,
  getLocalStorageItemString,
  setLocalStorageItemString,
  watchLocalStorageItem,
} from "../session";
import { GetQueryParams, LogError } from "../helpers";
import { ICyberRiskAuth } from "../types/auth";
import jwtDecode, { JwtPayload } from "jwt-decode";

// remember what path we're currently on (for deep linking after login)
const rememberCurrentAppPath = () => {
  if (window.location.pathname !== "/") {
    setLocalStorageItemString(
      "redirectPath",
      window.location.pathname + window.location.search
    );
  }
};

const pathnameIsEmpty = () =>
  window.location.pathname === "" || window.location.pathname === "/";

interface IDTokenClaims extends JwtPayload {
  email: string;
}

interface Auth0WrapperV2Props {
  dispatch: DefaultThunkDispatch;
  cyberRiskAuth: ICyberRiskAuth;
  children: any;
}

// Sends an anonymous tracking event to our tracker interface (segment et al.)
export const SendAnonymousEvent = async (
  emailAddress: string,
  anonymousTrackingID: string,
  eventType: string
) => {
  try {
    const fetchUrl = `/api/ae/p/u/v1?evt_type=${encodeURIComponent(
      eventType
    )}&email_address=${encodeURIComponent(
      emailAddress
    )}&unique_id=${encodeURIComponent(anonymousTrackingID)}`;
    await fetch(fetchUrl, { method: "PUT" });
  } catch (e) {
    console.error("Failed to send password set event: ${e}");
  }
};

export const ANON_TRACKING_KEY = "anonymousTrackingID";
export const GetAnonymousTrackingID = (): string => {
  let trackingID = getLocalStorageItemString(ANON_TRACKING_KEY) ?? "";
  if (trackingID == "") {
    trackingID = uuidv4();
    setLocalStorageItemString(ANON_TRACKING_KEY, trackingID);
  }
  return trackingID;
};
export const ResetAnonymousTrackingID = (): void => {
  clearLocalStorageItem(ANON_TRACKING_KEY);
};

// Auth0WrapperV2 ensures the user is authenticated
// and logged in to CR before rendering the app.
// It also handles new users claiming invite tokens,
// and callbacks from Auth0 after certain errors
// e.g. expired password resets
const Auth0WrapperV2 = ({
  dispatch,
  cyberRiskAuth,
  children,
}: Auth0WrapperV2Props) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    getAccessTokenSilently,
    getIdTokenClaims,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [justLoggedIn, setJustLoggedIn] = useState(false);

  const {
    invite_token,
    success,
    message,
    utm_source,
    utm_medium,
    utm_content,
    utm_campaign,
    utm_term,
    destination,
    passwd_set,
    email_address,
  } = GetQueryParams(window.location.search);
  const { id_token, access_token } = GetQueryParams(window.location.hash);

  const loading = isLoading || !!cyberRiskAuth?.loading;
  const haveInviteToken = !!invite_token && window.location.pathname === "/";
  const requestedLogoutPath = window.location.pathname === "/logout";
  const havePasswordResetCallback = success === "false" && !!message;
  const haveError = !!error || !!cyberRiskAuth?.error;
  const haveIDPInitiatedSSO = !!id_token && !!access_token;

  // we should only attempt login via auth0/CR when we're not
  // about to do something else - all of these states are captured
  // as flags in nonAuthStates
  const nonAuthStates = [
    haveInviteToken,
    havePasswordResetCallback,
    haveError,
    haveIDPInitiatedSSO,
    requestedLogoutPath,
    !!cyberRiskAuth?.forceLogout,
    !!cyberRiskAuth?.userLogout,
  ];
  const requireAuth = !loading && !nonAuthStates.some((s) => s);

  const logoutAndClearState = () => {
    dispatch(clearCommonState());
    logout(
      cyberRiskAuth?.ssoWithLogout
        ? { federated: true }
        : { returnTo: window.location.origin }
    );
  };

  // we use the cyberRiskAuth state to trigger a user logout
  // to ensure that other open tabs also logout immediately
  const doUserLogout = () =>
    dispatch(setCyberRiskAuth({ userLogout: true }, null));

  // Initialise localStorage watchers to sync our data between tabs
  useEffect(() => {
    watchLocalStorageItem("cyberRiskAuth", (newData: any) =>
      dispatch(setCyberRiskAuth(newData, null, true))
    );
    watchLocalStorageItem("userData", (newData: any) =>
      dispatch(setUserData(newData, true))
    );
  }, []);

  // handle all the various states where we're doing something other than
  // authorising and rendering the app
  useEffect(() => {
    if (loading) {
      return;
    }

    // check if the user has initiated a logout
    if (cyberRiskAuth?.userLogout) {
      // we don't want to remember the user's email if they initiated the logout
      clearLocalStorageItem("loginHint");
      logoutAndClearState();
      return;
    }

    // log an error if we got one
    if (haveError) {
      LogError("Authorization error", error || cyberRiskAuth?.error);
      return;
    }

    // if the user visited /logout, log them out
    if (requestedLogoutPath) {
      doUserLogout();
      return;
    }

    // if we received an ID token from an IdP-initiated SSO login,
    // send the user back to their IdP to complete an SP-initiated login
    if (haveIDPInitiatedSSO) {
      const { email } = jwtDecode<IDTokenClaims>(id_token);
      setLocalStorageItemString("loginHint", email);
      dispatch(clearCommonState());
      loginWithRedirect({
        redirectUri: window.location.origin,
        login_hint: email,
      });
      return;
    }

    // if we have an invite token or password reset callback,
    // we want to let that take priority over a forceLogout
    if (haveInviteToken || havePasswordResetCallback) {
      return;
    }

    // check if the CR auth state wants us to logout
    if (cyberRiskAuth?.forceLogout) {
      // save the current path first so the user comes back to where they were
      rememberCurrentAppPath();
      logoutAndClearState();
      return;
    }
  }, [loading, ...nonAuthStates]);

  useEffect(() => {
    if (passwd_set == "true" && !!email_address) {
      SendAnonymousEvent(
        email_address,
        GetAnonymousTrackingID(),
        "Common_UserInitialPasswordSet"
      );
    }
  }, [passwd_set]);

  useEffect(() => {
    if (requireAuth) {
      // if we're not authenticated by Auth0, redirect to the Auth0-hosted login
      if (!isAuthenticated) {
        rememberCurrentAppPath();

        loginWithRedirect({
          redirectUri: window.location.origin,
          login_hint: getLocalStorageItemString("loginHint") || undefined,
          "ext-utm-source": utm_source,
          "ext-utm-content": utm_content,
          "ext-utm-campaign": utm_campaign,
          "ext-utm-medium": utm_medium,
          "ext-utm-term": utm_term,
          // shows signup link only for when users access root path, for any other cases undefined
          "ext-show-signup": pathnameIsEmpty() ? "true" : undefined,
        });
        return;
      }

      // if we don't have a CR token, do a CR login
      if (!cyberRiskAuth?.token) {
        // The user had to log in, so we'll tell the root component that for tracking purposes
        setJustLoggedIn(true);

        Promise.all([getIdTokenClaims(), getAccessTokenSilently()])
          .then(([idToken, accessToken]) =>
            dispatch(
              loginUser(idToken?.__raw, accessToken, GetAnonymousTrackingID())
            ).then((success) => {
              if (success) {
                ResetAnonymousTrackingID();
              }
            })
          )
          .catch((e) => LogError("error loading ID and access tokens", e));

        // save the user's email address as a login hint for next time
        setLocalStorageItemString("loginHint", user?.email);
      }
    }
  }, [requireAuth, isAuthenticated, cyberRiskAuth?.token]);

  // check if we are claiming an invite token
  if (haveInviteToken) {
    return (
      <RegisterInvite
        inviteToken={invite_token}
        destination={destination}
        logout={doUserLogout}
      />
    );
  }

  // handle the failed password reset callback
  if (havePasswordResetCallback) {
    return <AuthMessage message={message} />;
  }

  if (haveError) {
    return (
      <AuthMessage
        header={cyberRiskAuth?.error?.errorTitle}
        message={cyberRiskAuth?.error?.errorText}
        primaryButtonText="Sign out"
        primaryButtonOnClick={doUserLogout}
      />
    );
  }

  if (!requireAuth || !isAuthenticated || !cyberRiskAuth?.token) {
    return <LoadingBanner />;
  }

  // everything looks good, we can render the app
  return cloneElement(children, { logout: doUserLogout, justLoggedIn });
};

// Auth0WrapperV2Container wraps our Auth0WrapperV2 with
// Auth0Provider to give us access to the Auth0 hooks.
const Auth0WrapperV2Container = (props: Auth0WrapperV2Props) => (
  <Auth0Provider
    domain={window.AUTH0_FRONTEND_DOMAIN ?? ""}
    clientId={window.AUTH0_CLIENTID ?? ""}
    audience={`https://${window.AUTH0_DOMAIN}/userinfo`}
    scope="openid email profile"
  >
    <Auth0WrapperV2 {...props}>{props.children}</Auth0WrapperV2>
  </Auth0Provider>
);

export default Auth0WrapperV2Container;

interface Invite {
  recipientEmail?: string;
  invitedBy?: string;
  expired?: boolean;
  newInviteRequested?: boolean;
  claimed?: boolean;
  cancelled?: boolean;
  invalid?: boolean;
  setPasswordURL?: string;
  isSSODomain?: boolean;
  userExists: boolean;
  surveyId?: number;
  redirectPath: string;
}

interface RegisterInviteProps {
  inviteToken: string;
  destination?: string;
  logout: () => void;
}

const registerInviteURL = (token: string, destination?: string) =>
  `/api/accounts/register_invite/v1/?invite_token=${encodeURIComponent(
    token
  )}&destination=${!!destination ? encodeURIComponent(destination) : ""}`;

const RegisterInvite = ({
  inviteToken,
  destination,
  logout,
}: RegisterInviteProps) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [invite, setInvite] = useState({} as Invite);
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  // load the invite data
  useEffect(() => {
    (async () => {
      try {
        const resp = await fetch(registerInviteURL(inviteToken, destination));
        const jsonData = await resp.json();
        setInvite(jsonData);
        if (resp.status !== 200) {
          const { error }: { error: string } =
            resp.status === 422
              ? jsonData
              : { error: `got status ${resp.status}` };
          LogError("error fetching invite", error);
          setError(error);
        }
      } catch (e) {
        LogError("error fetching invite", e);
        setError("unexpected");
      }
      setLoading(false);
    })();
  }, [inviteToken, destination]);

  if (loading || isLoading) {
    return <LoadingBanner />;
  }

  if (isAuthenticated) {
    // if they're currently logged in, force them to logout first
    return (
      <AuthMessage
        header="Create account"
        message="Please sign out before creating a new account."
        primaryButtonText="Sign out"
        primaryButtonOnClick={logout}
      />
    );
  }

  // if this invite is for an SSO user, they can be sent straight to login
  if (!error && invite.isSSODomain) {
    loginWithRedirect({
      redirectUri: window.location.origin,
      login_hint: invite.recipientEmail,
    });
    return <LoadingBanner />;
  }

  if (!error && invite.setPasswordURL) {
    // invite is all good, so send the user to the set password URL
    // save their email as a login hint first so they won't have to re-enter it when logging in
    setLocalStorageItemString("loginHint", invite.recipientEmail);
    window.location.replace(invite.setPasswordURL);
    return <LoadingBanner />;
  }

  if (invite.expired) {
    return <ExpiredInvite inviteToken={inviteToken} invite={invite} />;
  }

  const messageProps = {} as AuthMessageProps;

  if (error === "INVALID_REDIRECT_DESTINATION") {
    messageProps.header = "Invalid destination";
    messageProps.message = "The specified destination is invalid";
  }
  if (invite.cancelled) {
    messageProps.header = "Cancelled invitation";
    messageProps.message = "Your invitation has been cancelled";
  }
  if (invite.invalid) {
    messageProps.header = "Invalid invitation";
    messageProps.message = "The invitation token is invalid";
  }
  if (invite.claimed || invite.userExists) {
    // If we have been passed a redirect location, redirect them to the right place when they log in
    if (invite.redirectPath && invite.redirectPath.length > 0) {
      setLocalStorageItemString("redirectPath", invite.redirectPath);
    }

    messageProps.header = "Redirecting to login...";
    messageProps.message = "";

    // Go to the login page
    window.location.replace("/");
  }
  return <AuthMessage {...messageProps} />;
};

interface ExpiredInviteProps {
  inviteToken: string;
  invite: Invite;
}

const requestNewInviteURL = (token: string) =>
  `/api/accounts/request_invite/v1/?invite_token=${encodeURIComponent(token)}`;

const ExpiredInvite = ({ inviteToken, invite }: ExpiredInviteProps) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [newInviteRequested, setNewInviteRequested] = useState(
    invite.newInviteRequested
  );

  const requestNewInvite = async () => {
    setLoading(true);
    try {
      const resp = await fetch(requestNewInviteURL(inviteToken));
      if (resp.status !== 204) {
        LogError("error requesting invite", `got status ${resp.status}`);
        setError(true);
      } else {
        setNewInviteRequested(true);
      }
    } catch (e) {
      LogError("error requesting invite", e);
      setError(true);
    }
    setLoading(false);
  };

  if (loading) {
    return <LoadingBanner />;
  }
  if (error) {
    return <AuthMessage />;
  }

  if (newInviteRequested) {
    return (
      <AuthMessage
        header="Request sent"
        message={
          <>
            Your request for a new invitation has been sent to{" "}
            {invite.invitedBy}.
          </>
        }
      />
    );
  }

  return (
    <AuthMessage
      header="Your invitation has expired"
      message={
        <>
          Your invitation to UpGuard expired after 30 days. Please ask{" "}
          <a href={"mailto:" + invite.invitedBy}>{invite.invitedBy}</a> to
          resend your invitation.
        </>
      }
      primaryButtonText="Request a new invitation"
      primaryButtonOnClick={requestNewInvite}
    />
  );
};

interface AuthMessageProps {
  header?: string;
  message?: ReactNode;
  primaryButtonText?: string;
  primaryButtonOnClick?: () => void;
  secondaryButtonText?: string;
  secondaryButtonOnClick?: () => void;
}

// Shows a full screen message with optional actions
const AuthMessage = ({
  header,
  message,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
}: AuthMessageProps) => (
  <div className="auth-message-container">
    <div className="warning-icon-container">
      <i className="cr-icon-broken-link" />
    </div>
    <h2>{header}</h2>
    <div className="message">{message}</div>
    {primaryButtonText && (
      <Button primary onClick={primaryButtonOnClick}>
        {primaryButtonText}
      </Button>
    )}
    {secondaryButtonText && (
      <Button onClick={secondaryButtonOnClick}>{secondaryButtonText}</Button>
    )}
  </div>
);

AuthMessage.defaultProps = {
  header: "Sorry, something went wrong",
  message:
    "Error authorizing with UpGuard. Please refresh the page and try again.",
};
