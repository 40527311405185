import { FC } from "react";
import XTable, {
  IXTableRow,
  SortDirection,
  XTableCell,
} from "../../_common/components/core/XTable";
import { Scope } from "../api/types";
import ScopeRiskLevelDisplay from "./ScopeRiskLevelDisplay";
import { useSorting } from "../../_common/hooks";
import UserBaseAPI from "../api/userbase.api";
import "./UserPermissionsForApp.scss";

interface UserPermissionsForAppProps {
  appName: string;
  userUUID: string;
}

const UserPermissionsForApp: FC<UserPermissionsForAppProps> = ({
  appName,
  userUUID,
}) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppUserScopesV1Query({
    app: appName,
    userUUID: userUUID,
  });

  const [sortedScopes, sortedBy, onSortChange] = useSorting<
    Scope,
    "exposure_level" | "permission"
  >(data?.scopes ?? [], "exposure_level", SortDirection.DESC, {
    exposure_level: {
      orderFuncs: [(s: Scope) => s.riskLevel, (s: Scope) => s.description],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
    },
    permission: {
      orderFuncs: [(s: Scope) => s.description, (s: Scope) => s.riskLevel],
      sortDirsAsc: [SortDirection.ASC, SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC, SortDirection.ASC],
    },
  });

  const scopeIntoXTableRow = (s: Scope): IXTableRow<string> => {
    return {
      id: s.name,
      className: "permission-row",
      cells: [
        <XTableCell className="description-with-subtext" key="permission">
          <div className="description-main-text">{s.description}</div>
          <div className="description-subtext">{s.name}</div>
        </XTableCell>,
        <XTableCell className="risk-level" key="exposure_level">
          <ScopeRiskLevelDisplay riskLevel={s.riskLevel} />
        </XTableCell>,
      ],
    };
  };

  // const permissionsNUmberString = `${pluralise(
  //               sortedScopes.length,
  //               "Permission",
  //               "Permissions"
  //             )} (${sortedScopes.length})`

  return (
    <div className={"user-permissions-for-apps"}>
      {/* <h2>{permissionsNUmberString}</h2> */}
      <XTable
        className="permission-list"
        sortedBy={sortedBy}
        onSortChange={onSortChange}
        loading={isLoading}
        columnHeaders={[
          { id: "permission", text: "Description", sortable: true },
          { id: "exposure_level", text: "Exposure level", sortable: true },
        ]}
        rows={sortedScopes.map(scopeIntoXTableRow)}
      />
    </div>
  );
};

export default UserPermissionsForApp;
