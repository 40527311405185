import { FC, useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../types/reduxHooks";
import {
  OrgAccessVendors,
  usePermissions,
  UserVendorRiskEnabled,
  UserVendorRiskWrite,
} from "../permissions";
import {
  Link,
  matchPath,
  Route,
  Router,
  Switch,
  useHistory,
} from "react-router-dom";
import RouterTriggeredModal from "../../vendorrisk/components/modals/RouterTriggeredModal";
import HotjarWrapper from "../components/HotjarWrapper";
import HubspotWidgetWrapper from "../components/HubspotWidgetWrapper";
import TermsAndConditionsUpdateMessage from "../components/TermsAndConditionsUpdateMessage";
import "../style/components/AppContentContainer.scss";
import {
  getCurrentOrgFromUserData,
  isExpiredTrial,
} from "../helpers/userOrg.helpers";
import { organisationAccountType } from "../types/organisations";
import {
  fetchOrgFreeTrialEligibility,
  retrieveOnboardingCalendarForUser,
} from "../reducers/freeTrial.actions";
import {
  fetchUserInitiatedExports,
  loadInitialActivityStream,
  setCyberRiskAuth,
  switchUserOrg,
} from "../reducers/commonActions";
import { hasPermissionFromUserData } from "../selectors/permissions";
import { fetchVendorImport } from "../../vendorrisk/reducers/vendorImport.actions";
import { fetchCustomerLimitData } from "../../vendorrisk/reducers/cyberRiskActions";
import TopLevelNavigation, {
  topLevelNavigationItem,
} from "../components/navigation/TopLevelNavigation";
import CommonRouter from "./CommonRouter";
import ErrorBoundary from "../components/ErrorBoundary";
import classnames from "classnames";
import ProductSpecificNavigation from "../components/navigation/ProductSpecificNavigation";
import { History } from "history";
import { product } from "../types/products";
import IconButton from "../components/IconButton";
import Icon from "../components/core/Icon";
import DropdownV2 from "../components/core/DropdownV2";
import { UserMenu } from "./Navigation";
import ExpandableNavigationContainer from "../components/navigation/ExpandableNavigationContainer";
import HelpAndSupportDropdown from "../components/HelpAndSupportDropdown";
import { trackEvent } from "../tracking";

interface NavigationRouterWrapperProps {
  history: History;
}

// NavigationRouter - router to handle routes where standard navigation is hidden
export const NavigationRouter: FC<NavigationRouterWrapperProps> = (props) => {
  // Routes that should not show the standard site navigation components (Top Level Nav, Product Specific Nav, Action Bar)
  const routesWithNonStandardNavigation = [
    "/surveys/:id/answers",
    "/vendors/surveys/:id/answers",
    "/vendor/:vendorId/surveys/:id/answers",
    "/trustpage/surveys/:id/answers",
    "/sharedassessments/:vendorId/surveys/:id/answers",
    "/vendor/:vendorId/sharedassessment/surveys/:id/answers",
    "/vendor/:vendorId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers",
    "/vendor/:vendorId/sharedassets/:sharedAssetOrgId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers",
    "/vendor/:vendorId/sharedassets/vendor/surveys/:id/answers",
    "/vendor/:vendorId/sharedassets/:sharedAssetOrgId/surveys/:id/answers",
    "/analysts/tpvm/:orgId/:vendorId/surveys/:id/answers",
    "/analysts/tpvm/:orgId/:vendorId/sharedassessment/surveys/:id/answers",
    "/analysts/tpvm/:orgId/:vendorId/sharedassessment/surveys/:id/byassessment/:assessmentId/answers",
    "/analysts/tpvm/:orgId/:vendorId/questionnairepreview",
    "/analysts/tpvm/:orgId/:vendorId/:id/presendreview",
    "/questionnairepreview",
    "/quickstart",
  ];

  return (
    <Router history={props.history}>
      <Switch>
        {/* Routes with custom (or no) nav */}
        <Route path={routesWithNonStandardNavigation}>
          <AppContentContainer showNavigation={false} />
        </Route>
        <Route path="*">
          <AppContentContainer showNavigation={true} />
        </Route>
      </Switch>
    </Router>
  );
};

interface AppContainerProps {
  showNavigation?: boolean;
}

const AppContentContainer: FC<AppContainerProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userData = useAppSelector((state) => state.common?.userData);
  const permissions = usePermissions();

  // TODO: make sure these route lists are comprehensive
  const breachsightRoutes = [
    "/summaryreport/breachsight",
    "/risk_profile",
    "/selfremediation",
    "/riskwaivers",
    "/webscans",
    "/ips",
    "/detected_products",
    "/subsidiaries",
    "/vulns",
    "/email_exposures",
    "/typosquatting",
  ];

  const vendorRiskRoutes = [
    "/summaryreport/vendorrisk",
    "/vendor_portfolio",
    "/vendor/",
    "/vendorlist",
    "/remediation",
    "/surveys",
    "/surveybuilder/library",
    "/concentrationrisk",
    "/managedvendors",
    "/sharedassessments",
    "/vendorcomparison",
  ];

  const userRiskRoutes = ["/userbase/"];

  const trustExchangeRoutes = ["/trustpage", "contentlibrary"];

  const selectedProductMemo: product = useMemo<product>(() => {
    if (
      breachsightRoutes.some(
        (route) => history.location?.pathname?.startsWith(route)
      )
    ) {
      return "breachsight";
    } else if (
      vendorRiskRoutes.some(
        (route) => history.location?.pathname?.startsWith(route)
      )
    ) {
      return "vendor_risk";
    } else if (
      userRiskRoutes.some(
        (route) => history.location?.pathname?.startsWith(route)
      )
    ) {
      return "user_risk";
    } else if (
      trustExchangeRoutes.some(
        (route) => history.location?.pathname?.startsWith(route)
      )
    ) {
      return "trust_exchange";
    }

    return undefined;
  }, [history.location?.pathname]);

  const selectedItemMemo: topLevelNavigationItem =
    useMemo<topLevelNavigationItem>(() => {
      if (selectedProductMemo) {
        return selectedProductMemo;
      }

      if (
        matchPath(history.location?.pathname, "/home") ||
        history.location?.pathname === "/"
      ) {
        return "home";
      }

      if (matchPath(history.location?.pathname, "/reportexports")) {
        return "reports";
      }

      if (matchPath(history.location?.pathname, "/breachnewsfeed")) {
        return "incidents_and_news";
      }

      return undefined;
    }, [history.location?.pathname, selectedProductMemo]);

  const [topLevelNavOpen, setTopLevelNavOpen] = useState(false);
  const [productSpecificNavOpen, setProductSpecificNavOpen] =
    useState(!!selectedProductMemo);

  useEffect(() => {
    if (selectedProductMemo) {
      setProductSpecificNavOpen(true);
    }
  }, [selectedProductMemo]);

  useEffect(() => {
    if (userData.currentOrgID) {
      const currentOrg = getCurrentOrgFromUserData(userData);
      if (currentOrg) {
        const isExpired = isExpiredTrial(currentOrg);
        const isTrial =
          currentOrg.accountType === organisationAccountType.trial &&
          currentOrg.trialExpiresOn;
        if (currentOrg.isVendorTrial || isTrial) {
          dispatch(retrieveOnboardingCalendarForUser(userData.id));
        }

        if (!isExpired) {
          dispatch(loadInitialActivityStream(false));
          dispatch(fetchUserInitiatedExports(false));

          if (
            userData.orgPermissions.includes(OrgAccessVendors) &&
            hasPermissionFromUserData(userData, UserVendorRiskWrite)
          ) {
            dispatch(fetchVendorImport());
          }

          if (hasPermissionFromUserData(userData, UserVendorRiskEnabled)) {
            dispatch(fetchCustomerLimitData());
          }
        }

        // kick off free-trial eligibility check
        if (currentOrg && currentOrg.accountType == "free") {
          dispatch(
            fetchOrgFreeTrialEligibility(
              userData.id,
              userData.currentOrgID,
              false
            )
          );
        }
      }
    }
  }, []);

  return (
    <>
      <div
        id="app-content-container"
        className={classnames({ "nav-open": props.showNavigation })}
      >
        {props.showNavigation && (
          <>
            <div id="navigation-container">
              <ExpandableNavigationContainer
                className="top-level-navigation-container"
                isOpen={topLevelNavOpen}
                onToggleNavigationOpen={(isOpen) => setTopLevelNavOpen(isOpen)}
              >
                <TopLevelNavigation
                  open={topLevelNavOpen}
                  selectedItem={selectedItemMemo}
                />
              </ExpandableNavigationContainer>

              {selectedProductMemo && (
                <ExpandableNavigationContainer
                  className={"product-specific-navigation-container"}
                  isOpen={productSpecificNavOpen}
                  onToggleNavigationOpen={(isOpen) =>
                    setProductSpecificNavOpen(isOpen)
                  }
                  disableFader={true}
                  fullContainerClickableWhenClosed={true}
                >
                  <ProductSpecificNavigation
                    open={productSpecificNavOpen}
                    selectedProduct={selectedProductMemo}
                  />
                </ExpandableNavigationContainer>
              )}
            </div>
            <div
              className={classnames("top-level-navigation-content-pusher", {
                open: topLevelNavOpen,
              })}
            />
            {selectedProductMemo && (
              <div
                className={classnames("product-navigation-content-pusher", {
                  open: productSpecificNavOpen,
                })}
              />
            )}
          </>
        )}
        <div id="app-content">
          {props.showNavigation && (
            <div id="user-actions-bar">
              <div className="user-actions-bar-icon">
                <HelpAndSupportDropdown
                  dispatch={dispatch}
                  history={history}
                  userInNonFreeAccount={
                    userData.currentOrgID > 0 &&
                    getCurrentOrgFromUserData(userData)?.accountType !==
                      organisationAccountType.free
                  }
                  popupItem={
                    <div className="help-support-btn">
                      <div className="cr-icon-message-question-checkmark" />
                    </div>
                  }
                />
              </div>
              <div className="user-actions-bar-icon">
                <IconButton
                  key="invite-user"
                  icon={<div className="cr-icon-single-user-add-plus" />}
                  disabled={true}
                  hoverText="Coming soon!"
                />
              </div>
              <div className="user-actions-bar-icon">
                <Link
                  to={"/settings/users"}
                  onClick={() => {
                    trackEvent("navigation menu item clicked", {
                      pathTo: "/settings/users",
                      linkName: "Settings",
                    });
                  }}
                >
                  <IconButton
                    key="settings"
                    icon={<div className="cr-icon-settings" />}
                  />
                </Link>
              </div>
              <div className="user-actions-divider" />
              <DropdownV2
                className="user-menu-dropdown"
                popupItem={
                  <div className="user-details">
                    <div className="name-and-org">
                      <div
                        className="org"
                        title={getCurrentOrgFromUserData(userData)?.name}
                      >
                        {getCurrentOrgFromUserData(userData)?.name}
                      </div>
                      <div className="name" title={userData?.emailAddress}>
                        {userData?.emailAddress}
                      </div>
                    </div>
                    <Icon name="chevron" direction={180} />
                  </div>
                }
              >
                <UserMenu
                  {...userData}
                  history={history}
                  onLogoutClick={() => {
                    dispatch(setCyberRiskAuth({ userLogout: true }, null));
                  }}
                  onSwitchOrgClick={(orgId: number) => {
                    dispatch(switchUserOrg(orgId, history));
                  }}
                />
              </DropdownV2>
            </div>
          )}
          <div id="main-content-area">
            <div id="main-content-area-inner">
              <ErrorBoundary pathname={history?.location?.pathname}>
                <CommonRouter
                  history={history}
                  dispatch={dispatch}
                  userData={userData}
                  permissions={permissions.permissions}
                />
              </ErrorBoundary>
            </div>
          </div>
        </div>
      </div>
      <RouterTriggeredModal />
      <HotjarWrapper />
      <HubspotWidgetWrapper />
      <TermsAndConditionsUpdateMessage />
    </>
  );
};

export default AppContentContainer;
