import { FetchCyberRiskUrl } from "../api";
import { LogError } from "../helpers";
import { mapTimelineStatuses } from "./surveyDetails.actions";
import {
  RemediationRequest,
  RemediationRequestDetails,
  RemediationRequestMessages,
  RemediationRequestRiskHistory,
  RemediationRequestStatus,
  RemediationRequestTimeline,
  RemediationRequestUsers,
} from "../types/remediation";
import {
  clearSurveyData,
  conditionalRefreshActivityStreamForOrgUser,
  deleteRemediationRequestsByIDs,
  grabTPVMSession,
  setProjectionForRemediationRequest,
  setProjectionRequestTimestamp,
  setRemediationRequestData,
} from "./commonActions";
import { DefaultAction, DefaultThunkDispatch } from "../types/redux";
import { DefaultRootState } from "react-redux";
import {
  IAdditionalEvidenceCheckToAdd,
  IAdditionalEvidenceDocumentToAdd,
  ICloudscanCheckAndWebsite,
  ISaasCheckToAdd,
  ISurveyCheckToAdd,
} from "../../vendorrisk/reducers/remediation.actions";
import { omit as _omit, toInteger } from "lodash";
import {
  fetchVendorSummaryAndCloudscans,
  setVendorData,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { refreshLatestVendorAssessmentForVendor } from "../../analyst_portal/reducers/analystManagedVendors.actions";
import { fetchCustomerSummaryAndCloudscans } from "../../vendorrisk/reducers/customer.actions";
import { produce } from "immer";
import moment from "moment";

export const fetchRemediationRequestDetails = (
  remediationRequestId: number,
  force = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<RemediationRequestDetails> => {
    const riskInState =
      getState().common.remediationRequests[remediationRequestId];
    if (!force && riskInState && riskInState.details) {
      return riskInState.details;
    }

    let json;

    try {
      json = await FetchCyberRiskUrl<RemediationRequestDetails>(
        "remediationrequest/details/v1",
        { request_id: remediationRequestId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting remediation request details", e);

      throw new Error("Error getting request details. Please try again later.");
    }

    dispatch(
      setRemediationRequestData(remediationRequestId, { details: json })
    );

    return json;
  };
};

// reloads all remediation request details that are currently cached
export const reloadAllRemediationRequestDetails =
  () =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) =>
    Object.keys(getState().common.remediationRequests).forEach((value) => {
      dispatch(fetchRemediationRequestDetails(toInteger(value), true));
      dispatch(fetchRemediationRequestTimeline(toInteger(value), true));
    });

export const getRemediationRequestDetailsForVendorAssessmentFromState = (
  vendorAssessmentID: number,
  state: DefaultRootState
): RemediationRequest | undefined => {
  return Object.values(state.common.remediationRequests).find(
    (r) => r?.details?.vendorAssessmentId === vendorAssessmentID
  );
};

export const fetchRemediationRequestDetailsForVendorAssessment = (
  vendorAssessmentID: number,
  force = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<RemediationRequestDetails | "NOREQUEST"> => {
    if (!force) {
      const existingRequest =
        getRemediationRequestDetailsForVendorAssessmentFromState(
          vendorAssessmentID,
          getState()
        );
      if (existingRequest && existingRequest.details) {
        return existingRequest.details;
      }
    }

    let json;

    try {
      json = await FetchCyberRiskUrl<
        RemediationRequestDetails | { status: "NOREQUEST" }
      >(
        "remediationrequest/details/assessment/v1",
        { vendor_assessment_id: vendorAssessmentID },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting remediation request details", e);

      throw new Error("Error getting request details. Please try again later.");
    }

    if (json.status === "NOREQUEST") {
      return "NOREQUEST";
    }

    dispatch(setRemediationRequestData(json.id, { details: json }));

    return json;
  };
};

export const fetchRemediationRequestTimeline = (
  remediationRequestId: number,
  force = false
): DefaultAction<RemediationRequestTimeline> => {
  return async (dispatch, getState) => {
    let riskInState =
      getState().common.remediationRequests[remediationRequestId];
    if (!force && riskInState && riskInState.timeline) {
      return riskInState.timeline;
    }

    let json: RemediationRequestTimeline;

    try {
      json = await FetchCyberRiskUrl(
        "remediationrequest/timeline/v1",
        { request_id: remediationRequestId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting remediation risk timeline", e);

      throw new Error("Error getting risk timeline. Please try again later.");
    }

    const timeline = {
      items: mapTimelineStatuses(json.items),
      users: json.users,
    };

    dispatch(setRemediationRequestData(remediationRequestId, { timeline }));

    // Also reset the unread count on the request details if it exists
    riskInState = getState().common.remediationRequests[remediationRequestId];
    if (riskInState && riskInState.details) {
      dispatch(
        setRemediationRequestData(remediationRequestId, {
          details: {
            ...riskInState.details,
            unreadMessages: 0,
          },
        })
      );
    }
    return json;
  };
};

export const updateRemediationRequestStatus = (
  remediationRequestId: number,
  newStatus: RemediationRequestStatus,
  isSubsidiary: boolean,
  vendorID?: number,
  message?: string
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);

    try {
      await FetchCyberRiskUrl(
        "remediationrequest/updatestatus/v1",
        {
          remediation_request_id: remediationRequestId,
          new_status: newStatus,
          subsidiary: isSubsidiary,
          message_content: message,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating remediation request status", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // clear the survey status in case changes are reflected in this
    dispatch(clearSurveyData());
    if (vendorID) {
      dispatch(
        setVendorData(
          vendorID,
          {
            surveys: {
              loading: false,
              error: null,
              result: null,
            },
          },
          isSubsidiary,
          tpvmSession as any
        )
      );
      // refresh the cloudscans
      dispatch(
        fetchVendorSummaryAndCloudscans(
          vendorID,
          true,
          false,
          false,
          isSubsidiary
        )
      );

      if (tpvmSession.tpvm) {
        dispatch(refreshLatestVendorAssessmentForVendor(vendorID));
      }
    } else {
      // Refresh customer data
      dispatch(fetchCustomerSummaryAndCloudscans(true));
    }
  };
};

export const updateRemediationRequestArchived = (
  remediationRequestIds: number[],
  newArchived: boolean,
  isSubsidiary: boolean,
  vendorIDs: (number | undefined)[]
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    const tpvmSession = grabTPVMSession(getState);

    try {
      await FetchCyberRiskUrl(
        "remediationrequest/updatearchived/v1",
        {
          remediation_request_ids: remediationRequestIds,
          archived: newArchived,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating remediation request status", e);

      throw e;
    }

    // Update the archived status in redux
    const existingRequests = getState().common.remediationRequests;
    for (const id of remediationRequestIds) {
      const request = existingRequests[id];
      const newRequest = produce(request, (draftRequest) => {
        draftRequest.details.archived = newArchived;
        if (newArchived) {
          draftRequest.details.status = RemediationRequestStatus.Closed;
        }
      });
      dispatch(setRemediationRequestData(id, newRequest));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // clear the survey status in case changes are reflected in this
    dispatch(clearSurveyData());
    vendorIDs.forEach((vendorID) => {
      if (vendorID) {
        dispatch(
          setVendorData(
            vendorID,
            {
              surveys: {
                loading: false,
                error: null,
                result: null,
              },
            },
            isSubsidiary,
            tpvmSession as any
          )
        );

        if (tpvmSession.tpvm) {
          dispatch(refreshLatestVendorAssessmentForVendor(vendorID));
        }

        // refresh the cloudscans
        dispatch(
          fetchVendorSummaryAndCloudscans(
            vendorID,
            true,
            false,
            false,
            isSubsidiary
          )
        );
      }
    });

    if (vendorIDs.length === 0 || vendorIDs.every((v) => v === undefined)) {
      // Assume org, so refresh data for org
      dispatch(fetchCustomerSummaryAndCloudscans(true));
    }
  };
};

export const addRemediationRequestMessage = (
  remediationRequestId: number,
  parentId: number | undefined,
  content: string,
  is_private: boolean
): DefaultAction<unknown> => {
  return async (dispatch, getState) => {
    const opts: any = {
      remediation_request_id: remediationRequestId,
      content,
      is_private,
    };

    if (parentId) {
      opts.parent_id = parentId;
    }
    let result;
    try {
      result = await FetchCyberRiskUrl(
        "remediationrequest/messages/v1",
        opts,
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error adding message to remediation risk", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());

    return result;
  };
};

export const editRemediationRequestMessage = (
  messageId: number,
  content: string
): DefaultAction<unknown> => {
  return async (dispatch, getState) => {
    const opts = {
      message_id: messageId,
      content,
    };

    let result;
    try {
      result = await FetchCyberRiskUrl(
        "remediationrequest/messages/v1",
        opts,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error editing message", e);

      throw e;
    }

    return result;
  };
};

export const fetchRemediationRequestMessages = (
  remediationRequestId: number,
  force = false,
  noMarkRead = false,
  noCache = false
): DefaultAction<RemediationRequestMessages> => {
  return async (dispatch, getState) => {
    const riskInState =
      getState().common.remediationRequests[remediationRequestId];
    if (!force && riskInState && riskInState.messages) {
      return riskInState.messages;
    }

    let messages: RemediationRequestMessages;
    try {
      messages = await FetchCyberRiskUrl(
        "remediationrequest/messages/v1",
        {
          remediation_request_id: remediationRequestId,
          no_mark_read: noMarkRead,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching remediation messages", e);

      throw new Error("Error fetching messages. Please try again later.");
    }

    if (!noCache) {
      dispatch(
        setRemediationRequestData(remediationRequestId, {
          messages: {
            messages: messages.messages,
            users: messages.users,
          },
        })
      );

      if (!noMarkRead) {
        // Mark the messages as read in the state
        const remediationInState =
          getState().common.remediationRequests[remediationRequestId];
        if (remediationInState && remediationInState.messages) {
          let messagesInState = [...remediationInState.messages.messages];
          messagesInState = messagesInState.map((m) => ({
            ...m,
            unread: false,
          }));
          dispatch(
            setRemediationRequestData(remediationRequestId, {
              messages: {
                messages: messagesInState,
                users: messages.users,
              },
            })
          );
        }
      }
    }

    return messages;
  };
};

export const fetchRemediationRequestUsers = (
  remediationRequestId: number,
  force = false
): DefaultAction<RemediationRequestUsers> => {
  return async (dispatch, getState) => {
    const riskInState =
      getState().common.remediationRequests[remediationRequestId];
    if (!force && riskInState && riskInState.users) {
      return riskInState.users;
    }

    let users: RemediationRequestUsers;
    try {
      users = await FetchCyberRiskUrl(
        "remediationrequest/users/v1",
        { remediation_request_id: remediationRequestId },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error fetching remediation users", e);

      throw new Error("Error fetching users. Please try again later.");
    }

    dispatch(
      setRemediationRequestData(remediationRequestId, {
        users,
      })
    );

    return users;
  };
};

export const addRemediationRequestUsers = (
  remediationRequestId: number,
  emailAddresses: string[],
  message: string,
  emailsToRemove?: string[]
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/users/v1",
        {
          remediation_request_id: remediationRequestId,
          email_addresses: emailAddresses,
          message: message,
          emails_to_remove: emailsToRemove,
        },
        { method: "POST" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error adding remediation request users", e);

      throw e;
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export const fetchProjectionForExistingRemediationRequest = (
  remediationRequestId: number
): DefaultAction<void> => {
  return async (dispatch, getState) => {
    const timestamp = moment().format();
    const timestampKey = `remediationRequest_${remediationRequestId}`;
    dispatch(setProjectionRequestTimestamp(timestamp, timestampKey));
    dispatch(
      setProjectionForRemediationRequest(remediationRequestId, true, null)
    );

    const opts = {
      request_id: remediationRequestId,
    };
    let json;

    try {
      json = await FetchCyberRiskUrl<{
        initialScore?: number;
        currentScore?: number;
        projectedScore?: number;
      }>("projection/remediationrequest/v1", opts, null, dispatch, getState);
    } catch (e) {
      dispatch(
        setProjectionForRemediationRequest(remediationRequestId, false, e)
      );
      LogError("Error retrieving score projection for remediation request", e);

      throw e;
    }

    if (
      timestamp == getState().common.projection.requestTimestamps[timestampKey]
    ) {
      dispatch(
        setProjectionForRemediationRequest(
          remediationRequestId,
          false,
          null,
          json.initialScore,
          json.currentScore,
          json.projectedScore
        )
      );
    }

    return;
  };
};

export const updateRemediationRequestTitle =
  (
    remediationRequestId: number,
    newTitle: string,
    isSubsidiary: boolean
  ): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/updatetitle/v1",
        {
          remediation_request_id: remediationRequestId,
          new_title: newTitle,
          subsidiary: isSubsidiary,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating remediation request title", e);

      throw e;
    }

    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // update the title of the request in the state
    const remediationRequest =
      getState().common.remediationRequests[remediationRequestId];
    if (remediationRequest) {
      const { details } = remediationRequest;
      dispatch(
        setRemediationRequestData(remediationRequestId, {
          details: { ...details, title: newTitle },
        })
      );
    }
  };

export const updateRemediationRequestDueDate =
  (
    remediationRequestId: number,
    dueDate?: string,
    reminderDate?: string
  ): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/updatedue/v1",
        {
          remediation_request_id: remediationRequestId,
          due_date: dueDate,
          reminder_date: reminderDate,
        },
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating remediation request due date");

      throw e;
    }

    dispatch(conditionalRefreshActivityStreamForOrgUser());

    // update the due date of the request in the state
    const remediationRequest =
      getState().common.remediationRequests[remediationRequestId];
    const { details } = remediationRequest;
    dispatch(
      setRemediationRequestData(remediationRequestId, {
        details: { ...details, dueDate, reminderDate },
      })
    );
  };

export interface IRemediationRequestBaseRequest {
  cloudscanChecksAndWebsites: ICloudscanCheckAndWebsite[];
  surveyChecksToAdd: ISurveyCheckToAdd[];
  additionalEvidenceChecksToAdd: IAdditionalEvidenceCheckToAdd[];
  additionalEvidenceDocumentsToAdd: IAdditionalEvidenceDocumentToAdd[];
  saasChecksToAdd: ISaasCheckToAdd[];
  title: string;
  inviteEmails: string[];
  emailMessage: string;
  initialScore?: number;
  projectedScore?: number;
  projectedScoreAt?: string;
  dueDate?: string;
  reminderDate?: string;
}

export interface IRemediationRequestEditRequest
  extends IRemediationRequestBaseRequest {
  requestId: number;
  openRequest: boolean;
}

export const updateRemediationRequestDraft =
  (request: IRemediationRequestEditRequest): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/draft/v1",
        {},
        { method: "POST", body: JSON.stringify(request) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating remediation request draft", e);

      throw e;
    }

    // update the details of the remediation request in the state
    const remediationRequest =
      getState().common.remediationRequests[request.requestId];
    if (remediationRequest) {
      const { details } = remediationRequest;
      dispatch(
        setRemediationRequestData(request.requestId, {
          details: {
            ...details,
            ..._omit(request, [
              "requestId",
              "cloudscanChecksAndWebsites",
              "openRequest",
              "emailMessage",
              "inviteEmails",
            ]),
          },
        })
      );
    }
  };

export const deleteRemediationRequestDraft =
  (id: number): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/draft/v1",
        { remediation_request_id: id },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting remediation request draft", e);

      throw e;
    }

    // update state
    const remediationRequest = getState().common?.remediationRequests[id];
    if (remediationRequest) {
      const { details } = remediationRequest;
      dispatch(
        setRemediationRequestData(id, {
          details: {
            ...details,
            status: RemediationRequestStatus.DeletedDraft,
          },
        })
      );
      // refresh the risks since some may have been deleted
      if (details && details.vendorId && details.vendorId !== 0) {
        dispatch(
          fetchVendorSummaryAndCloudscans(
            details.vendorId,
            true,
            false,
            false,
            details.isSubsidiary
          )
        );
      } else {
        dispatch(fetchCustomerSummaryAndCloudscans(true));
      }
    }
  };

export const deleteRemediationRequests =
  (ids: number[]): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/v1",
        { remediation_request_ids: ids },
        { method: "DELETE" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error deleting remediation requests", e);
      throw e;
    }

    // update state
    dispatch(deleteRemediationRequestsByIDs(ids));
  };

export interface IRemediationRequestEditRisks {
  requestId: number;
  cloudscanChecksAndWebsites: ICloudscanCheckAndWebsite[];
  surveyChecks: ISurveyCheckToAdd[];
  additionalEvidenceChecks: IAdditionalEvidenceCheckToAdd[];
  additionalEvidenceDocumentsToAdd: IAdditionalEvidenceDocumentToAdd[];
  projectedScore?: number;
  projectedScoreAt?: string;
}

export const editRemediationRequestRisks =
  (request: IRemediationRequestEditRisks): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/risks/v1/",
        {},
        { method: "PUT", body: JSON.stringify(request) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("error updating remediation request risks", e);

      throw e;
    }

    // update the details of the remediation request in the state
    const remediationRequest =
      getState().common.remediationRequests[request.requestId];
    if (remediationRequest) {
      const { details } = remediationRequest;
      dispatch(
        setRemediationRequestData(request.requestId, {
          details: {
            ...details,
            ..._omit(request, ["requestId", "cloudscanChecksAndWebsites"]),
          },
        })
      );
    }
  };

export interface markRemediationRequestRiskAsRemediatedReq {
  requestId: number;
  checkId: string;
  justification: string;
}

export const markRemediationRequestRiskAsRemediated =
  (req: markRemediationRequestRiskAsRemediatedReq): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "remediationrequest/risks/remediate/v1/",
        {},
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error marking remediation request risk as remediated");

      throw e;
    }
  };

export interface markVendorRiskAsRemediatedReq {
  vendorId: number;
  checkId: string;
  justification: string;
}

export const markVendorRiskAsRemediated =
  (req: markVendorRiskAsRemediatedReq): DefaultAction<void> =>
  async (dispatch, getState) => {
    try {
      await FetchCyberRiskUrl(
        "vendor/risks/remediate/v1/",
        {},
        { method: "POST", body: JSON.stringify(req) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error marking vendor risk as remediated");

      throw e;
    }
  };

export const fetchRemediationRequestRiskHistory = (
  requestId: number,
  riskId: number,
  riskSource: string,
  force = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<RemediationRequestRiskHistory> => {
    const riskHistoryInState = getState().common.remediationRequests[requestId];
    if (
      !force &&
      riskHistoryInState &&
      riskHistoryInState.riskHistory &&
      riskHistoryInState.riskHistory[riskId]
    ) {
      return riskHistoryInState.riskHistory[riskId];
    }

    let json;

    try {
      json = await FetchCyberRiskUrl<RemediationRequestRiskHistory>(
        "vendor/risks/history/v1/",
        { request_id: requestId, risk_id: riskId, risk_source: riskSource },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error getting history for risk");

      throw e;
    }

    const currentRisksHistory =
      getState().common.remediationRequests[requestId]?.riskHistory ?? {};

    dispatch(
      setRemediationRequestData(requestId, {
        riskHistory: { ...currentRisksHistory, [riskId]: json },
      })
    );

    return json;
  };
};
