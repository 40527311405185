import { ILabel } from "../../_common/types/label";
import { VulnDetail } from "../../vendorrisk/types/vulns";
import { RiskBreakdown } from "./riskBreakdown";
import { IScore } from "../../_common/types/score";

export type CloudGitConnectionProviderType = "github" | "bitbucket" | "gitlab";

export type CloudGitConnectionSetupState = "started" | "completed";

export interface CloudGitConnectionMeta {
  gitHubInstallationId: number;
}

export interface CloudGitConnection {
  uuid: string;
  orgId: number;
  nickname: string;
  providerType: CloudGitConnectionProviderType;
  setupState: CloudGitConnectionSetupState;
  meta: CloudGitConnectionMeta;
  createdBy: number;
  createdAt: string;
  deletedAt?: string;
}

export interface CloudGitRepository {
  uuid: string;
  orgId: number;
  repoName: string;
  nickname?: string;
  providerConnectionUUID: string;
  enabled: boolean;
  createdAt: string;
  latestScanAt?: string;
  latestCommitAt?: string;
  dependencyCount?: number;
  isPrivate?: boolean;
  isFork?: boolean;
  parentURL?: string;
  parentName?: string;
  score: number;
  scoreAt?: string;
  adjustedScore: number;
  riskBreakdown: RiskBreakdown;
}

export const repoName = (r?: CloudGitRepository) =>
  r ? r.nickname || r.repoName : undefined;

export interface CloudGitRepositoryDetail extends CloudGitRepository {
  labels: ILabel[];
  description?: string;
  language?: string;
  scores: IScore[];
}

export interface CloudGitRepoManifestDetail extends CloudGitRepoManifest {
  labels?: ILabel[];
}

export interface CloudGitRepoManifest {
  uuid: string;
  repoUUID: string;
  locationPath: string;
  nickname?: string;
  ecosystem: string;
  dependencyCount: number;
  createdAt: string;
  updatedAt: string;
  score: number;
  riskBreakdown: RiskBreakdown;
}

export const manifestName = (m?: CloudGitRepoManifest) =>
  m ? m.nickname || m.locationPath : undefined;

export interface ManifestDependency {
  packageVersionUniqueName: string;
  manifestUUIDs: string[];
  dependencies: string[];
  topLevel: boolean;
  createdAt: string;
}

export interface RepoManifestDependency {
  repoUUID: string;
  repoName: string;
  manifestUUID: string;
  manifestLocation: string;
  packageVersionUniqueName: string;
  createdAt: string;
}

// peer: github/types_advisories.go
export interface GithubAdvisoryCVSS {
  vector_string: string;
  score: number;
}

// peer: github/types_advisories.go
export interface GithubAdvisoryCWE {
  cwe_id: string;
  name: string;
}

// peer: github/types_advisories.go
export interface GithubAdvisoryVulnerability {
  package: { ecosystem: string; name: string };
  first_patched_version: string;
  vulnerable_version_range: string;
  vulnerable_functions: string[];
}

// peer: github/types_advisories.go
export interface GithubAdvisory extends Record<string, unknown> {
  id: number;
  ghsa_id: string;
  cve_id: string;
  url: string;
  html_url: string;
  repository_advisory_url: string;
  summary: string;
  description: string;
  type: string;
  severity: string;
  source_code_location: string;
  identifiers: unknown;
  references: string[];
  published_at: string;
  updated_at: string;
  github_reviewed_at: string;
  nvd_published_at: string;
  withdrawn_at?: string;
  vulnerabilities: GithubAdvisoryVulnerability[];
  cvss: GithubAdvisoryCVSS;
  cwes: GithubAdvisoryCWE[];
}

// peer: appguard/package_vulns.go
export interface PackageVuln {
  cveID?: string;
  ghsaID: string;
  githubAdvisoryData: GithubAdvisory;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  sourceLastModifiedAt: string;
  affectedPackages: string[];
  epss: number;
}

// peer: appguard/package_vulns.go
export interface PackageVulnWithAffectedVersions extends PackageVuln {
  knownAffectedVersions: string[];
}

// peer: appguard/org_package_vulns.go
export interface OrgVulnDependency {
  vuln: PackageVulnWithAffectedVersions;
  orgDependencies: RepoManifestDependency[];
  breachsightVuln?: BreachsightVuln;
}

// peer: vulns/vulns.go
export interface BreachsightVuln extends VulnDetail {
  isActiveKnownExploited: boolean;
  exploitedDateAdded?: string;
  exploitedDateRemoved?: string;
}

// peer: api/appguard/vulns_v1.go
export interface OrgVulnDependencyWithBreachsightVuln
  extends OrgVulnDependency {
  breachsightVuln: BreachsightVuln;
}

export function isOrgVulnDependency(vuln: unknown): vuln is OrgVulnDependency {
  return vuln !== null && typeof vuln === "object" && "orgDependencies" in vuln;
}

export interface CloudConnection {
  uuid: string;
  orgId: number;
  nickname: string;
  provider: CloudProviderType;
  config: CloudProviderConfig;
  labels?: ILabel[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  lastScannedAt: string | null;
  enabled: boolean;
  assetCount: number;
}

export interface CloudRegion {
  name: string;
  displayName: string;
}

export interface CloudProvider {
  id: CloudProviderType;
  name: string;
  regions: CloudRegion[];
}

export interface CloudProviderAccessScope {
  name: string;
  type: string;
  providerId: string;
}

export interface AWSCloudConnConfig {
  roleArn: string;
  externalId: string;
  regions: string[];
}

export interface GCPCloudConnConfig {
  projectId: string;
  token: string;
  regions: string[];
}

export interface AzureCloudConnConfig {
  subscriptionId: string;
  clientId: string;
  clientSecret: string;
  tenantId: string;
  regions: string[];
}

/*
 
On the go side, this is represented as a poor-man's union:

type CloudAccountConfig struct {
  AWS      *types.AWSCloudAccountConfig `json:"aws,omitempty"`
  GCP      *types.GCPCloudAccountConfig `json:"gcp,omitempty"`
  Azure    *types.AzureCloudAccountConfig `json:"azure,omitempty"`
}

Here's the typescript equivalent:
 */
export type CloudProviderConfig =
  | {
      aws: AWSCloudConnConfig;
    }
  | {
      gcp: GCPCloudConnConfig;
    }
  | {
      azure: AzureCloudConnConfig;
    };

export const CloudProviders = ["aws", "gcp", "azure"] as const;
export type CloudProviderType = (typeof CloudProviders)[number];

export const CloudProviderTypeNiceNames: Record<CloudProviderType, string> = {
  aws: "Amazon AWS",
  gcp: "Google Cloud",
  azure: "Microsoft Azure",
};

export interface CloudConnectionAsset {
  uuid: string;
  cloudConnectionNickname: string;

  provider: CloudProviderType;
  providerRegion?: string;
  service: string;
  assetType: string;
  displayType: string;
  providerAssetId: string;
  providerAssetName: string;
  nickname?: string;
  displayName: string;

  detail: CloudAssetDetail;

  providerUpdatedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface CloudConnectionAssetWithChildren extends CloudConnectionAsset {
  childAssets: CloudConnectionAsset[];
}

export interface CloudAssetDetail {
  ipData?: IPData[];
  domains?: string[];
  detail?: Record<string, unknown>;
  links?: string[];
  tags?: Record<string, string>;
  systemTags?: Record<string, string>;
}

export interface IPData {
  ip?: string;
  dns?: string;
  via?: string[];
  for?: string;
  static?: boolean;
}

export interface CloudConnectionScan {
  uuid: string;
  orgId: number;
  cloudConnectionUUID?: string;
  trigger: string;
  createdAt: string;
  finishedAt?: string;
  error?: string;
}
