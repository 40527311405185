import { Filters, FilterTypes } from "./types";
import { ILabel } from "../../../_common/types/label";
import { WatchlistVendorMini } from "../../../_common/types/vendor";
import {
  extractSubsidiaries,
  subsidiaryFilterSubsidiary,
} from "./SubsidiaryFilter";
import { VendorTier } from "../../reducers/vendorTiers.actions";
import { Portfolio } from "../../reducers/portfolios.actions";
import { FC, useEffect } from "react";
import { DataLeaksClassificationNames } from "../../../_common/types/dataLeaks";
import Button from "../../../_common/components/core/Button";
import { isNumber as _isNumber } from "lodash";

import { getFiltersFromState } from "../../reducers/filters.actions";

import "../../style/components/filter/FilterLabel.scss";
import {
  VendorAssessmentAuthor,
  VendorAssessmentClassificationMap,
} from "../../types/vendorAssessments";
import { searchAssessmentAuthorList } from "../../reducers/vendorAssessment.actions";
import { RiskMini } from "../../../_common/types/risks";
import {
  GetStatusFilterAttributeDisplayName,
  GetStatusFilterDisplayName,
} from "../../../_common/types/survey";
import { newFactCategoryMeta } from "../../../_common/factCategoryHelpers";
import { appConnect } from "../../../_common/types/reduxHooks";

interface IFilterLabelButtonProps {
  onClick: () => void;
  isActive: boolean;
  primary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  filters?: string[];
}

export const FilterLabelButton: FC<IFilterLabelButtonProps> = ({
  onClick,
  isActive,
  primary,
  tertiary,
  disabled,
  filters,
}) => {
  return (
    <Button
      className="filter-label"
      onClick={onClick}
      primary={primary || isActive}
      tertiary={!isActive && tertiary}
      disabled={disabled}
    >
      <div
        className="filter-text"
        title={isActive && !!filters ? filters.join(", ") : ""}
      >
        {isActive && !!filters
          ? `Filters: ${filters.join(", ")}`
          : isActive
            ? "Filters applied"
            : "Apply filters"}
      </div>
      <div className="sep" />
      <span className="cr-icon-filter" />
    </Button>
  );
};

interface typosquatOption {
  id: string;
  name: string;
}

interface IFilterLabelOwnProps {
  vendorId?: number;
  isSubsidiary?: boolean;
  isCustomer?: boolean;
  primary?: boolean;
  tertiary?: boolean;
  disabled?: boolean;
  onClick: () => void;
  supportedFilters: FilterTypes[];
  availableCountries?: string[];
  ipSources?: string[];
  ipOwners?: string[];
  ipASNs?: string[];
  ipCountries?: string[];
  ipServices?: string[];
  timeFilterApplied?: boolean;
  userFilterApplied?: boolean;
  auditLogEventFilterApplied?: boolean;
  apiKeyFilterApplied?: boolean;
  typosquatAvailableReasons?: typosquatOption[];
  typosquatAvailableTypes?: typosquatOption[];
  typosquatAvailableMaliciousActivities?: typosquatOption[];
  typosquatTypesDoNotMatch?: boolean;
  typosquatReasonIdsMatchAll?: boolean;
  typosquatMaliciousActivitiesDoNotMatch?: boolean;
}

interface IFilterLabelConnectedProps extends Partial<Filters> {
  availableLabels?: ILabel[];
  availableVendors?: WatchlistVendorMini[];
  availableSubsidiaries?: subsidiaryFilterSubsidiary[];
  allVendorRisks?: RiskMini[];
  dataLeaksAvailableSources?: Record<string, string>;
  availableVendorTiers?: VendorTier[];
  availableAssessmentClassifications?: VendorAssessmentClassificationMap;
  availableAssessmentAuthors?: VendorAssessmentAuthor[];
  availableVendorPortfolios?: Portfolio[];
  availableDomainPortfolios?: Portfolio[];
}

type IFilterLabelProps = IFilterLabelOwnProps & IFilterLabelConnectedProps;

export const FilterLabel: FC<IFilterLabelProps> = ({
  primary = false,
  tertiary = false,
  disabled = false,
  onClick,
  supportedFilters,
  availableCountries = [],
  availableLabels = [],
  availableVendors = [],
  availableSubsidiaries = [],
  allVendorRisks = [],
  dataLeaksAvailableSources = {},
  availableVendorTiers = [],
  availableAssessmentClassifications = {},
  availableAssessmentAuthors = [],
  availableVendorPortfolios = [],
  availableDomainPortfolios = [],
  ipSources,
  ipOwners,
  ipASNs,
  ipCountries,
  ipServices,
  timeFilterApplied,
  userFilterApplied,
  auditLogEventFilterApplied,
  typosquatAvailableReasons,
  typosquatReasonIdsMatchAll,
  typosquatAvailableTypes,
  typosquatTypesDoNotMatch,
  typosquatAvailableMaliciousActivities,
  typosquatMaliciousActivitiesDoNotMatch,
  apiKeyFilterApplied,

  // Filters below
  includeUnlabeled,
  minScore,
  maxScore,
  vendorLabelIds = [],
  vendorLabelIdsMatchAll,
  vendorLabelIdsDoNotMatch,
  websiteLabelIds = [],
  websiteIncludeUnlabeled,
  websiteLabelIdsMatchAll,
  websiteLabelIdsDoNotMatch,
  riskCategories,
  vendorIds,
  riskIds,
  dataLeaksClassifications,
  dataLeaksSources,
  dataLeaksKeywords,
  dataLeaksReportedDate,
  vulnsCveNames,
  vulnsSoftware,
  vulnsExcludeVerified,
  vulnsExcludeUnverified,
  vulnsOnlyKEV,
  vulnsExcludedSeverities,
  vulnsEPSSScores,
  typosquatReasonIds = [],
  typosquatReasonIdsDoNotMatch,
  typosquatTypeIds = [],
  typosquatMaliciousActivities = [],
  subsidiaryLabelIds = [],
  subsidiaryCountries = [],
  subsidiaryIds = [],
  subsidiaryMinScore,
  subsidiaryMaxScore,
  vendorTiers,
  vendorAssessmentClassifications,
  vendorAssessmentAuthors,
  vendorAssessmentAuthorDoNotMatch,
  vendorReassessmentStartDate,
  vendorReassessmentEndDate,
  vendorReassessmentDateBefore,
  vendorReassessmentDateBetween,
  portfolioIds,
  domainPortfolioIds,
  selectedSurveyStatuses,
  selectedSurveyAttributes,
  selectedAttributes,
  vendorSurveyTypes,
  vendorEvidenceTypes,
  fourthPartyProductUUIDs,
  cloudConnectionProviderTypes,
  cloudConnectionUUIDs,
}) => {
  // Initial load
  useEffect(() => {
    if (supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_AUTHOR)) {
      searchAssessmentAuthorList();
    }
  }, []);

  const filters = [];
  if (supportedFilters.includes(FilterTypes.SCORE)) {
    if (_isNumber(minScore)) {
      filters.push(`Min score ${minScore}`);
    }

    if (_isNumber(maxScore)) {
      filters.push(`Max score ${maxScore}`);
    }
  }

  if (supportedFilters.includes(FilterTypes.VENDOR_LABEL)) {
    const labelFilters = [];

    if (includeUnlabeled) {
      labelFilters.push("Unlabelled");
    }

    if (vendorLabelIds.length > 0) {
      const availableLabelMap: Record<number, string> = {};
      for (let i = 0; i < availableLabels.length; i++) {
        availableLabelMap[availableLabels[i].id] = availableLabels[i].name;
      }

      for (let i = 0; i < vendorLabelIds.length; i++) {
        const name = availableLabelMap[vendorLabelIds[i]];
        if (name) {
          labelFilters.push(name);
        }
      }
    }

    if (labelFilters.length > 0) {
      if (vendorLabelIdsMatchAll) {
        filters.push("Labels (ALL): " + labelFilters.join(", "));
      } else if (vendorLabelIdsDoNotMatch) {
        filters.push("Labels (NOT): " + labelFilters.join(", "));
      } else {
        filters.push("Labels : " + labelFilters.join(", "));
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.WEBSITE_LABEL)) {
    if (websiteLabelIds.length > 0) {
      const availableLabelMap: Record<number, string> = {};
      for (let i = 0; i < availableLabels.length; i++) {
        availableLabelMap[availableLabels[i].id] = availableLabels[i].name;
      }
      for (let i = 0; i < websiteLabelIds.length; i++) {
        const name = availableLabelMap[websiteLabelIds[i]];
        if (name) {
          filters.push(name);
        }
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.WEBSITE_LABEL_ADVANCED)) {
    const labelFilters = [];

    if (websiteIncludeUnlabeled) {
      labelFilters.push("Unlabelled");
    }
    if (websiteLabelIds.length > 0) {
      const availableLabelMap: Record<number, string> = {};
      for (let i = 0; i < availableLabels.length; i++) {
        availableLabelMap[availableLabels[i].id] = availableLabels[i].name;
      }
      for (let i = 0; i < websiteLabelIds.length; i++) {
        const name = availableLabelMap[websiteLabelIds[i]];
        if (name) {
          labelFilters.push(name);
        }
      }
    }
    if (labelFilters.length > 0) {
      if (websiteLabelIdsMatchAll) {
        filters.push("Labels (ALL): " + labelFilters.join(", "));
      } else if (websiteLabelIdsDoNotMatch) {
        filters.push("Labels (NOT): " + labelFilters.join(", "));
      } else {
        filters.push("Labels : " + labelFilters.join(", "));
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.SUBSIDIARY_LABEL)) {
    if (subsidiaryLabelIds.length > 0) {
      const availableLabelMap: Record<number, string> = {};
      for (let i = 0; i < availableLabels.length; i++) {
        availableLabelMap[availableLabels[i].id] = availableLabels[i].name;
      }
      for (let i = 0; i < subsidiaryLabelIds.length; i++) {
        const name = availableLabelMap[subsidiaryLabelIds[i]];
        if (name) {
          filters.push(name);
        }
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.SUBSIDIARY_COUNTRY)) {
    if (subsidiaryCountries.length > 0) {
      const availableCountryMap: Record<string, string> = {};
      for (let i = 0; i < availableCountries.length; i++) {
        availableCountryMap[availableCountries[i]] = availableCountries[i];
      }
      for (let i = 0; i < subsidiaryCountries.length; i++) {
        const name = availableCountryMap[subsidiaryCountries[i]];
        if (name) {
          filters.push(name);
        }
      }
    }
  }

  if (
    supportedFilters.includes(FilterTypes.SUBSIDIARY) &&
    subsidiaryIds.length !== 0 &&
    availableSubsidiaries
  ) {
    filters.push(
      ...subsidiaryIds.map(
        (id) => availableSubsidiaries.find((sub) => id === sub.id)?.name
      )
    );
  }

  if (supportedFilters.includes(FilterTypes.SUBSIDIARY_SCORE)) {
    if (_isNumber(subsidiaryMinScore)) {
      filters.push(`Min score ${subsidiaryMinScore}`);
    }

    if (_isNumber(subsidiaryMaxScore)) {
      filters.push(`Max score ${subsidiaryMaxScore}`);
    }
  }

  if (
    supportedFilters.includes(FilterTypes.RISK_CATEGORY) &&
    riskCategories &&
    riskCategories.length !== 0
  ) {
    filters.push(...riskCategories.map((id) => newFactCategoryMeta[id].name));
  }

  if (
    supportedFilters.includes(FilterTypes.VENDOR) &&
    vendorIds &&
    vendorIds.length !== 0
  ) {
    filters.push(
      ...vendorIds
        .map((id) => availableVendors.find((vendor) => id === vendor.id)?.name)
        .filter((name) => !!name)
    );
  }

  if (
    allVendorRisks &&
    supportedFilters.includes(FilterTypes.RISK) &&
    riskIds &&
    riskIds.length !== 0
  ) {
    filters.push(
      ...riskIds.map((id) => {
        const risk = allVendorRisks.find((risk) => risk.id === id);
        return risk?.title;
      })
    );
  }

  if (
    supportedFilters.includes(FilterTypes.DATALEAKS_CLASSIFICATION) &&
    dataLeaksClassifications
  ) {
    for (let i = 0; i < dataLeaksClassifications.length; i++) {
      const name = (DataLeaksClassificationNames as any)[
        dataLeaksClassifications[i].value
      ];
      if (name) {
        filters.push(name);
      }
    }
  }

  if (
    supportedFilters.includes(FilterTypes.DATALEAKS_KEYWORD) &&
    dataLeaksKeywords
  ) {
    for (let i = 0; i < dataLeaksKeywords.length; i++) {
      filters.push(dataLeaksKeywords[i].value);
    }
  }

  if (
    supportedFilters.includes(FilterTypes.DATALEAKS_SOURCE) &&
    dataLeaksSources
  ) {
    for (let i = 0; i < dataLeaksSources.length; i++) {
      const name = dataLeaksAvailableSources[dataLeaksSources[i].value];
      if (name) {
        filters.push(name);
      }
    }
  }

  if (
    supportedFilters.includes(FilterTypes.DATALEAKS_REPORTED_DATE) &&
    !!dataLeaksReportedDate
  ) {
    filters.push(
      "Dates " +
        dataLeaksReportedDate.startDate?.format("ll") +
        " - " +
        dataLeaksReportedDate.endDate?.format("ll")
    );
  }

  if (supportedFilters.includes(FilterTypes.IP_SOURCE)) {
    filters.push(...(ipSources || []));
  }
  if (supportedFilters.includes(FilterTypes.IP_OWNER)) {
    filters.push(...(ipOwners || []));
  }
  if (supportedFilters.includes(FilterTypes.IP_ASN)) {
    filters.push(...(ipASNs || []).map((asn) => `AS${asn}`));
  }
  if (supportedFilters.includes(FilterTypes.IP_COUNTRY)) {
    filters.push(...(ipCountries || []));
  }
  if (supportedFilters.includes(FilterTypes.IP_SERVICE)) {
    filters.push(...(ipServices || []));
  }

  if (supportedFilters.includes(FilterTypes.VULNS_VERIFIED_STATUS)) {
    if (vulnsExcludeVerified) {
      filters.push("Unverified");
    } else if (vulnsExcludeUnverified) {
      filters.push("Verified");
    }
    if (vulnsOnlyKEV) {
      filters.push("KEV");
    }
  }
  if (supportedFilters.includes(FilterTypes.VULNS_CVE_NAME) && vulnsCveNames) {
    filters.push(...vulnsCveNames);
  }
  if (supportedFilters.includes(FilterTypes.VULNS_SOFTWARE) && vulnsSoftware) {
    filters.push(...vulnsSoftware.map((cpe) => cpe.title));
  }
  if (
    supportedFilters.includes(FilterTypes.VULNS_CVSS_SEVERITY) &&
    vulnsExcludedSeverities
  ) {
    if (vulnsExcludedSeverities.length) {
      filters.push("CVSS Severity");
    }
  }
  if (
    supportedFilters.includes(FilterTypes.VULNS_EPSS_SEVERITY) &&
    (!!vulnsEPSSScores?.lowerVal || !!vulnsEPSSScores?.upperVal)
  ) {
    filters.push("EPSS Score");
  }

  if (supportedFilters.includes(FilterTypes.TIME) && timeFilterApplied) {
    filters.push("Date Range");
  }

  if (supportedFilters.includes(FilterTypes.USER) && userFilterApplied) {
    filters.push("Users");
  }

  if (supportedFilters.includes(FilterTypes.API_KEY) && apiKeyFilterApplied) {
    filters.push("API Key");
  }

  if (
    supportedFilters.includes(FilterTypes.AUDIT_LOG_EVENT) &&
    auditLogEventFilterApplied
  ) {
    filters.push("Events");
  }

  if (supportedFilters.includes(FilterTypes.TYPOSQUAT_REASONS)) {
    const reasonFilters = [];

    if (typosquatReasonIds.length > 0) {
      const availableReasonMap: Record<string, string> = {};
      if (typosquatAvailableReasons) {
        for (let i = 0; i < typosquatAvailableReasons.length; i++) {
          availableReasonMap[typosquatAvailableReasons[i].id] =
            typosquatAvailableReasons[i].name;
        }
      }

      for (let i = 0; i < typosquatReasonIds.length; i++) {
        const name = availableReasonMap[typosquatReasonIds[i]];
        if (name) {
          reasonFilters.push(name);
        }
      }
    }

    if (reasonFilters.length > 0) {
      if (typosquatReasonIdsMatchAll) {
        filters.push("Reasons (ALL): " + reasonFilters.join(", "));
      } else if (typosquatReasonIdsDoNotMatch) {
        filters.push("Reasons (NOT): " + reasonFilters.join(", "));
      } else {
        filters.push("Reasons: " + reasonFilters.join(", "));
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.TYPOSQUAT_MALICIOUS_ACTIVITIES)) {
    const maliciousActivitiesFilters = [];

    if (typosquatMaliciousActivities.length > 0) {
      const availableMaliciousActivitiesMap: Record<string, string> = {};
      if (typosquatAvailableMaliciousActivities) {
        for (let i = 0; i < typosquatAvailableMaliciousActivities.length; i++) {
          availableMaliciousActivitiesMap[
            typosquatAvailableMaliciousActivities[i].id
          ] = typosquatAvailableMaliciousActivities[i].name;
        }
      }

      for (let i = 0; i < typosquatMaliciousActivities.length; i++) {
        const name =
          availableMaliciousActivitiesMap[typosquatMaliciousActivities[i]];
        if (name) {
          maliciousActivitiesFilters.push(name);
        }
      }
    }

    if (maliciousActivitiesFilters.length > 0) {
      if (typosquatMaliciousActivitiesDoNotMatch) {
        filters.push(
          "Malicious Activities (NOT): " + maliciousActivitiesFilters.join(", ")
        );
      } else {
        filters.push(
          "Malicious Activities: " + maliciousActivitiesFilters.join(", ")
        );
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.TYPOSQUAT_TYPES)) {
    const typeFilters = [];

    if (typosquatTypeIds.length > 0) {
      const availableTypesMap: Record<string, string> = {};
      if (typosquatAvailableTypes) {
        for (let i = 0; i < typosquatAvailableTypes.length; i++) {
          availableTypesMap[typosquatAvailableTypes[i].id] =
            typosquatAvailableTypes[i].name;
        }
      }

      for (let i = 0; i < typosquatTypeIds.length; i++) {
        const name = availableTypesMap[typosquatTypeIds[i]];
        if (name) {
          typeFilters.push(name);
        }
      }
    }

    if (typeFilters.length > 0) {
      if (typosquatTypesDoNotMatch) {
        filters.push("Types (NOT): " + typeFilters.join(", "));
      } else {
        filters.push("Types: " + typeFilters.join(", "));
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.VENDOR_TIER)) {
    const filteredTierNames: string[] = [];

    if (
      vendorTiers &&
      vendorTiers.length > 0 &&
      availableVendorTiers.length > 0
    ) {
      vendorTiers.forEach((tLevel) => {
        const tier = availableVendorTiers.find((t) => t.tier === tLevel);
        if (tier) {
          filteredTierNames.push(tier.name);
        }
      });
    }

    if (filteredTierNames.length > 0) {
      filters.push("Vendor Tiers: " + filteredTierNames.join(", "));
    }
  }

  if (supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_STATUS)) {
    const filteredAssessments: string[] = [];

    if (
      vendorAssessmentClassifications &&
      vendorAssessmentClassifications.length > 0 &&
      availableAssessmentClassifications
    ) {
      vendorAssessmentClassifications.forEach((clid) => {
        const classification = availableAssessmentClassifications[clid];
        if (classification) {
          filteredAssessments.push(classification.title);
        }
      });
    }
    if (filteredAssessments.length > 0) {
      filters.push("Assessment:  " + filteredAssessments.join(", "));
    }
  }

  if (supportedFilters.includes(FilterTypes.VENDOR_ASSESSMENT_AUTHOR)) {
    const filteredAuthors = [] as string[];
    (availableAssessmentAuthors ?? []).forEach((author) => {
      if (
        vendorAssessmentAuthors &&
        vendorAssessmentAuthors.includes(author.id)
      ) {
        filteredAuthors.push(author.name);
      }
    });
    if (filteredAuthors.length > 0) {
      if (vendorAssessmentAuthorDoNotMatch) {
        filters.push("Author (NOT): " + filteredAuthors.join(", "));
      } else {
        filters.push("Author:  " + filteredAuthors.join(", "));
      }
    }
  }

  if (supportedFilters.includes(FilterTypes.VENDOR_REASSESSMENT_DATE)) {
    if (
      vendorReassessmentDateBefore &&
      vendorReassessmentEndDate &&
      vendorReassessmentEndDate.length > 0
    ) {
      filters.push(`Reassessment before: ${vendorReassessmentEndDate}`);
    } else if (
      vendorReassessmentDateBetween &&
      vendorReassessmentEndDate &&
      vendorReassessmentEndDate.length > 0 &&
      vendorReassessmentStartDate &&
      vendorReassessmentStartDate.length > 0
    ) {
      filters.push(
        `Reassessment between: ${vendorReassessmentStartDate} and ${vendorReassessmentEndDate}`
      );
    } else if (
      vendorReassessmentStartDate &&
      vendorReassessmentStartDate?.length > 0
    ) {
      filters.push(`Reassessment after: ${vendorReassessmentStartDate}`);
    }
  }

  if (supportedFilters.includes(FilterTypes.VENDOR_PORTFOLIO) && portfolioIds) {
    const portfolioNames: string[] = [];

    if (availableVendorPortfolios.length > 0 && portfolioIds.length > 0) {
      availableVendorPortfolios.forEach((p) => {
        if (portfolioIds.indexOf(p.id) > -1) {
          portfolioNames.push(p.name);
        }
      });
    }

    if (portfolioNames.length > 1) {
      // Only show portfolio filters if more than one is applied.
      filters.push("Portfolios: " + portfolioNames.join(", "));
    }
  }

  if (
    supportedFilters.includes(FilterTypes.DOMAIN_PORTFOLIO) &&
    domainPortfolioIds
  ) {
    const portfolioNames: string[] = [];

    if (availableDomainPortfolios.length > 0 && domainPortfolioIds.length > 0) {
      availableDomainPortfolios.forEach((p) => {
        if (domainPortfolioIds.indexOf(p.id) > -1) {
          portfolioNames.push(p.name);
        }
      });
    }

    if (portfolioNames.length > 1) {
      // Only show portfolio filters if more than one is applied.
      filters.push("Portfolios: " + portfolioNames.join(", "));
    }
  }

  if (
    supportedFilters.includes(FilterTypes.SURVEY_STATUS) &&
    ((selectedSurveyStatuses && selectedSurveyStatuses.length > 0) ||
      (selectedSurveyAttributes && selectedSurveyAttributes.length > 0))
  ) {
    const statusFilters: string[] = [];

    if (selectedSurveyStatuses) {
      statusFilters.push(
        ...selectedSurveyStatuses.map((s) => GetStatusFilterDisplayName(s))
      );
    }

    if (selectedSurveyAttributes) {
      const filterAttributeNames = selectedSurveyAttributes.map((s) =>
        GetStatusFilterAttributeDisplayName(s)
      );

      // We shouldn't get any undefined values here, but just in case we'll guard against them
      filterAttributeNames.forEach((v) => {
        if (v !== undefined) {
          statusFilters.push(v);
        }
      });
    }

    filters.push("Include: " + statusFilters.join(", "));
  }

  if (
    supportedFilters.includes(FilterTypes.VENDOR_ATTRIBUTES) &&
    selectedAttributes &&
    Object.keys(selectedAttributes).length > 0
  ) {
    filters.push("Custom");
  }

  if (
    supportedFilters.includes(FilterTypes.VENDOR_SURVEY_TYPES) &&
    vendorSurveyTypes &&
    vendorSurveyTypes.length > 0
  ) {
    filters.push("Questionnaire types");
  }

  if (
    supportedFilters.includes(FilterTypes.VENDOR_EVIDENCE_TYPES) &&
    vendorEvidenceTypes &&
    vendorEvidenceTypes.length > 0
  ) {
    filters.push("Evidence types");
  }

  if (
    supportedFilters.includes(FilterTypes.FOURTH_PARTY_PRODUCT) &&
    fourthPartyProductUUIDs &&
    fourthPartyProductUUIDs.length
  ) {
    filters.push("Fourth Party Products");
  }

  if (
    supportedFilters.includes(
      FilterTypes.APPGUARD_CLOUD_CONNECTION_PROVIDER_TYPE
    ) &&
    cloudConnectionProviderTypes &&
    cloudConnectionProviderTypes.length
  ) {
    filters.push("Cloud provider types");
  }

  if (
    supportedFilters.includes(FilterTypes.APPGUARD_CLOUD_CONNECTION_UUID) &&
    cloudConnectionUUIDs &&
    cloudConnectionUUIDs.length
  ) {
    filters.push("Cloud connections");
  }

  const isActive = filters.length > 0;

  return (
    <FilterLabelButton
      onClick={onClick}
      isActive={isActive}
      primary={primary || isActive}
      tertiary={!isActive && tertiary}
      disabled={disabled}
      filters={filters}
    />
  );
};

export const FilterLabelContainer = appConnect<
  IFilterLabelConnectedProps,
  never,
  IFilterLabelOwnProps
>((state, props) => {
  const filters = getFiltersFromState(
    state,
    props.vendorId,
    props.isSubsidiary
  );

  let subs: subsidiaryFilterSubsidiary[] | undefined = undefined;
  if (state.cyberRisk.customerData.subsidiaries) {
    subs = extractSubsidiaries(
      state.cyberRisk.customerData.subsidiaries,
      true
    ).subs;
  }

  const availableAssessmentClassifications =
    state.cyberRisk.vendorAssessmentClassifications;

  const availableAssessmentAuthors = state.cyberRisk.vendorAssessmentAuthors;

  return {
    ...filters,
    availableLabels: state.cyberRisk.availableLabels,
    availableVendors: state.cyberRisk.allVendors.data?.vendors,
    availableSubsidiaries: subs,
    allVendorRisks: props.isCustomer
      ? state.cyberRisk.customerData?.riskList?.risks
      : !!props.vendorId
        ? props.isSubsidiary
          ? state.cyberRisk.subsidiaries[props.vendorId]?.riskList?.risks
          : state.cyberRisk.vendors[props.vendorId]?.riskList?.risks
        : (state.cyberRisk.allVendorRisks.data?.risks as RiskMini[]),
    dataLeaksAvailableSources: (state.cyberRisk as any).dataLeakScanResults
      ?.lookupData?.services,
    availableVendorTiers: state.cyberRisk.vendorTiers,
    availableAssessmentClassifications: availableAssessmentClassifications,
    availableAssessmentAuthors: availableAssessmentAuthors,
    availableVendorPortfolios: state.cyberRisk.vendorPortfolios?.portfolios,
    availableDomainPortfolios: state.cyberRisk.domainPortfolios?.portfolios,
  } as IFilterLabelConnectedProps;
})(FilterLabel);
