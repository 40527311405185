import {
  FC,
  MouseEvent,
  forwardRef,
  memo,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  useContext,
  CSSProperties,
} from "react";
import "../style/components/DismissablePopup.scss";
import Icon from "./core/Icon";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";
import { createPortal } from "react-dom";
import { throttle } from "lodash";
import { IsInsideModalContext } from "./ModalV2";

type ISidePopupV2Props = Omit<
  IDismissablePopupProps,
  "popupOnHoverToo" | "autoDismissAfter"
>;

// SidePopupV2 is a wrapper around DismissablePopup that makes it function like the SidePopup,
// in that it will simply be a popup on hover rather than manually dismissable.
export const SidePopupV2: FC<ISidePopupV2Props> = (props) => (
  <DismissablePopupMemo {...props} popupOnHoverToo autoDismissAfter={0} />
);

export interface DismissablePopupHandle {
  setDismissed: (dismissed: boolean) => void;
}

export type PopupPosition = "top" | "right" | "left" | "bottom";

interface IDismissablePopupProps extends PropsWithChildren {
  className?: string;
  popupClassName?: string; // Class applied to the fixed popup element - Note this will be mounted outside the react root so it will need to be scoped globally.
  position?: PopupPosition;
  popupStyle?: "dark" | "light";
  title?: string;
  width?: CSSProperties["width"]; // If set, determines the width of the popup. Used as maxWidth when `noWrap` is true.
  noWrap?: boolean; // If set, we set white-space: nowrap and set the width to the minimum. The "width" property becomes the maximum width of the popup.
  micro?: boolean; // Style this in a smaller way, intended for tooltips
  text: ReactNode;
  popupOnHoverToo?: boolean;
  popupDelay?: number;
  popupHoverable?: boolean; // Set to true if the popup content itself should be hoverable
  autoDismissAfter?: number; // Automatically dismiss after this, in ms. If explicitly set to 0, popup will start dismissed.
  initDismissed?: boolean; // Set an initial dismissed state
  inline?: boolean; // if true, the container will use inline-block.
}

const transitionTimeout = {
  appear: 250,
  enter: 0,
  exit: 250,
};

const DismissablePopup = forwardRef<
  DismissablePopupHandle,
  IDismissablePopupProps
>(function DismissablePopup(
  {
    className,
    popupClassName,
    position = "top",
    popupStyle = "dark",
    title,
    text,
    width = 300,
    noWrap = false,
    micro = false,
    autoDismissAfter,
    initDismissed,
    popupOnHoverToo,
    popupDelay = 0,
    popupHoverable = false,
    inline = false,
    children,
  },
  ref
) {
  const hasContent = !!title || !!text;
  const [dismissed, setDismissed] = useState(
    autoDismissAfter === 0 || initDismissed
  );
  const [hovered, setHovered] = useState(false);
  const [delayedHovered, setDelayedHovered] = useState(false);
  const isMounted = useRef(false);
  const [isPopupTargetVisible, setIsPopupTargetVisible] = useState(true);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerRefBounds, setContainerRefBounds] = useState<DOMRect>(
    new DOMRect()
  );

  // Support an optional delay before popping up on hover.
  useEffect(() => {
    if (popupDelay === 0) {
      setDelayedHovered(hovered);
      return;
    }

    if (hovered) {
      const timeout = setTimeout(() => {
        setDelayedHovered(true);
      }, popupDelay);

      return () => {
        clearTimeout(timeout);
      };
    } else {
      // Don't delay unsetting the hover state
      setDelayedHovered(false);
    }

    return;
  }, [hovered, popupDelay]);

  const isPopupVisible = !dismissed || delayedHovered;

  const isInsideModal = useContext(IsInsideModalContext);

  useEffect(() => {
    if (autoDismissAfter !== undefined) {
      setDismissed(autoDismissAfter === 0);
    }
  }, [autoDismissAfter]);

  useImperativeHandle(ref, () => ({
    setDismissed: (dismissed) => setDismissed(dismissed),
  }));

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useLayoutEffect(() => {
    if (!hasContent) {
      // We should never show the popup when there is no content, nor should we set up
      // any observers.
      setIsPopupTargetVisible(false);
      return;
    }

    // Use an intersection observer to determine whether the popup target is at all visible in the viewport.
    // If it's not visible, we won't set up the event listeners to keep the target position up to date.
    const observer = new IntersectionObserver((entries) => {
      if (entries.length === 0) {
        return;
      }

      setIsPopupTargetVisible(!!entries.find((e) => e.isIntersecting));
    });
    observer.observe(containerRef.current!);

    return () => {
      observer.disconnect();
    };
  }, [hasContent]);

  // Since this element uses a fixed position on the document body, we need to keep
  // the target element's position up to date. We do this by observing any resizing
  // of the target's size as well as scroll event listeners on the document.
  useLayoutEffect(() => {
    if (!isPopupTargetVisible || !isPopupVisible) {
      // Don't register any observers, we don't care about updating the position when the popup isn't even visible.
      return;
    }

    const resizeOrMovedCallback = throttle(
      () =>
        containerRef.current
          ? setContainerRefBounds(containerRef.current.getBoundingClientRect())
          : undefined,
      50
    );

    const elementResizeObserver = new ResizeObserver(resizeOrMovedCallback);

    // Observe any resizing of the target element itself as well as the main document
    elementResizeObserver.observe(containerRef.current!);
    elementResizeObserver.observe(document.documentElement);

    // Trigger whenever the user scrolls the viewport as well
    document.addEventListener("scroll", resizeOrMovedCallback);

    return () => {
      elementResizeObserver.disconnect();
      document.removeEventListener("scroll", resizeOrMovedCallback);
    };
  }, [isPopupTargetVisible, isPopupVisible]);

  useEffect(() => {
    let timeout: number | undefined;

    if (!dismissed && autoDismissAfter) {
      timeout = window.setTimeout(() => {
        if (isMounted.current) {
          setDismissed(true);
        }
      }, autoDismissAfter);
    }

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [dismissed, autoDismissAfter]);

  const popupWidthStyle = useMemo((): CSSProperties | undefined => {
    if (noWrap) return undefined;

    return { width, overflowWrap: "anywhere" };
  }, [width, noWrap]);

  const textWidthStyle = useMemo((): CSSProperties | undefined => {
    if (noWrap) return { maxWidth: width };

    return undefined;
  }, [width, noWrap]);

  const popupPortalStyle = useMemo(
    () => ({
      left: containerRefBounds.x,
      top: containerRefBounds.y,
      width: containerRefBounds.width,
      height: containerRefBounds.height,
    }),
    [
      containerRefBounds.width,
      containerRefBounds.height,
      containerRefBounds.x,
      containerRefBounds.y,
    ]
  );

  const onMouseEnter = useCallback(
    () => (popupOnHoverToo ? setHovered(true) : undefined),
    [popupOnHoverToo, setHovered]
  );

  const onMouseLeave = useCallback(
    () => (popupOnHoverToo ? setHovered(false) : undefined),
    [popupOnHoverToo, setHovered]
  );

  const onClickToDismiss = useCallback(() => {
    setDismissed(true);
    onMouseLeave();
  }, [setDismissed, onMouseLeave]);

  const stopPropagation = useCallback(
    (e: MouseEvent) => e.stopPropagation(),
    []
  );

  // Mount our popup directly on the document body to avoid z-index issues.
  const popup = createPortal(
    <div
      className={classnames("dismissable-popup-portal", {
        "in-modal": isInsideModal,
      })}
      style={popupPortalStyle}
      onClick={stopPropagation} // Clicks within the popup should not propagate to their container
    >
      <div />
      <CSSTransition
        in={isPopupTargetVisible && isPopupVisible}
        appear
        mountOnEnter
        unmountOnExit
        timeout={transitionTimeout}
      >
        <div
          className={classnames("dismissable-popup", popupClassName, position, {
            dismissable: autoDismissAfter !== 0,
            dismissed,
            hoverable: popupHoverable,
            "no-wrap": noWrap,
            micro: micro,
            light: popupStyle === "light",
          })}
          style={popupWidthStyle}
        >
          <div className="main-area">
            {title && (
              <div className="title" style={textWidthStyle}>
                {title}
              </div>
            )}
            <div className="main-text" style={textWidthStyle}>
              {text}
            </div>
          </div>
          {autoDismissAfter !== 0 && (
            <div
              className={classnames("x-area", {
                "with-title": !!title,
              })}
            >
              <Icon name="x" onClick={onClickToDismiss} />
            </div>
          )}
        </div>
      </CSSTransition>
    </div>,
    document.body
  );

  return (
    <div
      className={classnames("dismissible-popup-container", className, {
        inline,
      })}
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {popup}
      <div
        // Ensure we dismiss on click as sometimes the click action will open a modal that will not trigger the onMouseLeave event.
        // We use an event listener in the capture phase as children will often stop propagation, and this event would not fire in the bubbling phase.
        onClickCapture={
          isPopupTargetVisible && isPopupVisible ? onClickToDismiss : undefined
        }
        className="dismissable-popup-click-capture"
      >
        {children}
      </div>
    </div>
  );
});

const DismissablePopupMemo = memo(DismissablePopup);

export default DismissablePopupMemo;
