import PageHeader from "../../_common/components/PageHeader";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useBack } from "../../_common/types/router";
import ReportCard from "../../_common/components/ReportCard";
import OrgScoresChart from "../components/OrgScoresChart";

import OrgAppsOverview from "../components/OrgAppsOverview";
import OrgAppsNeedsReviewCount from "../components/OrgAppsNeedsReviewCount";

import "./DashboardView.scss";
import DashboardStat, {
  DashboardStatToShow,
} from "../components/DashboardStat";
import AboutUserDirectory from "../components/AboutUserDirectory";
interface ApplicationsViewProps {}

const DashboardView: FC<ApplicationsViewProps> = ({}) => {
  const { backAction, backText } = useBack();

  const history = useHistory();

  return (
    <div className="userbase-dashboard">
      <PageHeader
        history={history}
        title="Dashboard"
        backAction={backAction}
        backText={backText}
        infoSectionPageKey="infoSection_userBaseDashboard"
        infoSection={
          <>
            <p>
              The dashboard provides an overview of the cybersecurity posture of
              your users.
            </p>
          </>
        }
      />

      <OrgScoresChart />

      <div className="three-grid no-stretch">
        <ReportCard>
          <DashboardStat statToShow={DashboardStatToShow.UserCount} />
        </ReportCard>
        <ReportCard>
          <DashboardStat statToShow={DashboardStatToShow.AppCount} />
        </ReportCard>
        <ReportCard>
          <DashboardStat statToShow={DashboardStatToShow.PermissionCount} />
        </ReportCard>
      </div>

      <div className="three-grid no-stretch">
        <ReportCard>
          <AboutUserDirectory />
        </ReportCard>
        <ReportCard>
          <OrgAppsNeedsReviewCount />
        </ReportCard>
        <ReportCard>
          <OrgAppsOverview />
        </ReportCard>
      </div>
    </div>
  );
};

export default DashboardView;
