import { Component } from "react";
import { SizeMe } from "react-sizeme";
import classnames from "classnames";
import {
  cloudscanSeverityNumberToText,
  hashCode,
  severityMap,
} from "../../_common/helpers";
import { fetchVuln, fetchVulnsByCPE } from "../reducers/cyberRiskActions";

import LoadingBanner from "../../_common/components/core/LoadingBanner";
import { cvssToText } from "../../_common/helpers";
import "../style/components/CVEMiniList.scss";
import Icon from "../../_common/components/core/Icon";
import ColorCheckbox from "./ColorCheckbox";
import { DefaultThunkDispatchProp } from "../../_common/types/redux";
import KnownExploitedVulnPill from "./KnownExploitedVulnPill";
import { CPEDetail } from "../types/vulns";
import { appConnect } from "../../_common/types/reduxHooks";

export const vulnerableSoftwareRiskIDPrefix = "vulnerable_software_version";
export const verifiedVulnRiskIDPrefix = "verified_vuln";
export const unverifiedVulnRiskIDPrefix = "unverified_vuln";

export const IsCPERisk = (riskID: string) =>
  riskID.startsWith(vulnerableSoftwareRiskIDPrefix + ":");
export const GetCPEFromRiskID = (riskID: string) =>
  riskID.replace(vulnerableSoftwareRiskIDPrefix + ":", "");

export const IsCVERisk = (riskID: string) =>
  riskID.startsWith(verifiedVulnRiskIDPrefix + ":") ||
  riskID.startsWith(unverifiedVulnRiskIDPrefix + ":");
export const IsVerifiedCVE = (riskID: string) =>
  riskID.startsWith(verifiedVulnRiskIDPrefix + ":");
export const GetCVEFromRiskID = (riskID: string) =>
  riskID.startsWith(verifiedVulnRiskIDPrefix + ":")
    ? riskID.replace(verifiedVulnRiskIDPrefix + ":", "")
    : riskID.replace(unverifiedVulnRiskIDPrefix + ":", "");

const cveBaseWidth = 260; // 173
const cveMarginRight = 16;
const cveWidth = cveBaseWidth + cveMarginRight;
const selectableCVEWidth = cveWidth + 40;
const selectAllContainerWith = 140;

interface ICVEMiniProps {
  cveName: string;
  cvss?: number;
  severity?: number;
  isKnownExploitedCVE: boolean;
  selectable?: boolean;
  selected?: boolean;
  onClickCVE?: (cveName: string, verified: boolean) => void;
  onSelectCVE?: (selected: boolean) => void;
}

export const CVEMini = ({
  cveName,
  cvss,
  severity,
  selectable,
  selected,
  onClickCVE,
  onSelectCVE,
  isKnownExploitedCVE,
}: ICVEMiniProps) => {
  const severityText =
    cvss !== null ? cvssToText(cvss) : cloudscanSeverityNumberToText(severity);
  return (
    <div
      className={classnames("mini-cve", {
        clickable: !!onClickCVE,
        selectable,
        selected,
      })}
      onClick={() => onClickCVE && onClickCVE(cveName, false)}
    >
      {selectable && (
        <ColorCheckbox
          checked={selected}
          onClick={() => onSelectCVE && onSelectCVE(!selected)}
        />
      )}
      <div className="mini-cvss-icon">{severityMap[severityText].icon}</div>
      <div className={"mini-cve-body"}>
        <div className="mini-cve-name">{cveName}</div>
        <div className="mini-cvss-label">
          {cvss !== undefined && cvss > 0 && (
            <>
              {cvss} {severityText}
            </>
          )}
        </div>
      </div>
      {isKnownExploitedCVE && (
        <div className={"mini-cve-pills"}>
          <KnownExploitedVulnPill />
        </div>
      )}
    </div>
  );
};

CVEMini.defaultProps = {
  onClickCVE: null,
  onSelectCVE: null,
  cvss: null,
  severity: null,
  selectable: false,
  selected: false,
};

interface ICVEMiniListVuln {
  cveName: string;
  cvss: number;
  epss?: number;
  isKnownExploitedCVE: boolean;
}

interface ICVEMiniListOwnProps {
  cpeName: string; // Ignored if cveName is specified
  cveName?: string;
  isCustomer?: boolean;
  hostnames?: string[];
  onClickCVE?: (cveName: string) => void;
  cveNamesFilter?: string[];
  firstCVEName?: string;
  selectable?: boolean;
  selectedCVENames?: string[] | null;
  onSelectionChange?: (selection?: string[]) => void;
  onLoaded?: () => void;
  onVulnsCountChanged?: (vulnsCount: number | undefined) => void;
}

interface ICVEMiniListConnectedProps {
  cpeName: string; // Ignored if cveName is specified
  cveName?: string;
  loading?: boolean;
  vulns?: ICVEMiniListVuln[];
  hostsHash?: number;
}

type ICVEMiniListProps = ICVEMiniListOwnProps &
  ICVEMiniListConnectedProps &
  DefaultThunkDispatchProp;

interface ICVEMiniListState {
  displayedRows: number;
}

class CVEMiniList extends Component<ICVEMiniListProps, ICVEMiniListState> {
  static defaultProps = {
    loading: true,
    hostnames: [],
    hostsHash: 0,
    vulns: [],
    onClickCVE: undefined,
    cveNamesFilter: [],
    firstCVEName: undefined,
    selectable: false,
    selectedCVENames: [],
    onSelectionChange: undefined,
  };

  state = {
    displayedRows: 2,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: Readonly<ICVEMiniListProps>) {
    if (
      prevProps.cpeName !== this.props.cpeName ||
      prevProps.cveName !== this.props.cveName ||
      prevProps.isCustomer !== this.props.isCustomer ||
      (this.props.isCustomer && prevProps.hostsHash !== this.props.hostsHash)
    ) {
      this.fetchData();
    }

    if (this.props.onVulnsCountChanged) {
      if (!prevProps.loading && this.props.loading) {
        this.props.onVulnsCountChanged?.(undefined);
      } else if (prevProps.vulns != this.props.vulns) {
        this.props.onVulnsCountChanged(
          this.props.vulns ? this.props.vulns.length : 0
        );
      }
    }
  }

  fetchData() {
    if (this.props.cveName) {
      this.props
        .dispatch(fetchVuln(this.props.cveName))
        .then(() => !!this.props.onLoaded && this.props.onLoaded());

      return;
    }

    if (
      this.props.isCustomer &&
      (!this.props.hostnames || this.props.hostnames.length === 0)
    ) {
      return;
    }

    this.props
      .dispatch(
        fetchVulnsByCPE(
          this.props.cpeName,
          this.props.isCustomer,
          this.props.hostnames
        )
      )
      .then(() => !!this.props.onLoaded && this.props.onLoaded());
  }

  getNumCVEsPerRow(rowWidth?: number) {
    if (!rowWidth) {
      return 1;
    }
    return Math.floor(rowWidth / this.getCVEWidth());
  }

  getCVEWidth() {
    return this.props.selectable ? selectableCVEWidth : cveWidth;
  }

  isCVESelected(cveName: string) {
    return (
      !this.props.selectedCVENames ||
      this.props.selectedCVENames.includes(cveName)
    );
  }

  onSelectCVE(cveName: string, selected: boolean) {
    const filteredVulns = this.getFilteredVulns();
    const { selectedCVENames } = this.props;

    let newSelection: string[] | undefined =
      selectedCVENames === null
        ? filteredVulns.map((v) => v.cveName)
        : [...(selectedCVENames || [])];

    if (selected) {
      if (!newSelection.includes(cveName)) {
        newSelection.push(cveName);
      }
    } else {
      newSelection = newSelection.filter(
        (selectedCVE) => selectedCVE !== cveName
      );
    }

    // if we've selected everything, just wipe the selection back to null
    if (newSelection.length === filteredVulns.length) {
      newSelection = undefined;
    }

    if (this.props.onSelectionChange) {
      this.props.onSelectionChange(newSelection);
    }
  }

  getFilteredVulns() {
    const { vulns, cveNamesFilter, firstCVEName } = this.props;
    let filteredVulns = [...(vulns || [])];

    // apply our filter first
    if (cveNamesFilter && cveNamesFilter.length > 0) {
      filteredVulns = filteredVulns.filter(({ cveName }) =>
        cveNamesFilter.includes(cveName)
      );
    }

    // now move our "first CVE" to the top of the list
    if (firstCVEName) {
      const firstCVE = filteredVulns.find(
        ({ cveName }) => cveName === firstCVEName
      );
      if (firstCVE) {
        filteredVulns = [
          firstCVE,
          ...filteredVulns.filter(({ cveName }) => cveName !== firstCVEName),
        ];
      }
    }

    return filteredVulns;
  }

  showMore = (numCVEsPerRow: number) =>
    this.setState((state) => ({
      displayedRows: state.displayedRows + Math.ceil(100 / numCVEsPerRow),
    }));

  getOnClickCVE = (cveName: string) => {
    if (this.props.onClickCVE) {
      return this.props.onClickCVE;
    }
    if (this.props.selectable) {
      return () => this.onSelectCVE(cveName, !this.isCVESelected(cveName));
    }
    return;
  };

  render() {
    const { loading, selectable, selectedCVENames, onSelectionChange } =
      this.props;

    const filteredVulns = this.getFilteredVulns();

    const { displayedRows } = this.state;

    if (loading) {
      return (
        <div className="cve-mini-list">
          <LoadingBanner tight />
        </div>
      );
    }

    return (
      // using SizeMe here so that we can work out exactly how many CVEs to display
      // so that the last row is always full, regardless of screen width
      <SizeMe refreshRate={50}>
        {({ size }) => {
          let rowWidth = size.width || 0;
          if (selectable) {
            rowWidth -= selectAllContainerWith;
          }
          const numCVEsPerRow = this.getNumCVEsPerRow(rowWidth);
          const numDisplayedCVEs = numCVEsPerRow * displayedRows;
          const showMoreBarWidth =
            numCVEsPerRow * this.getCVEWidth() - cveMarginRight;
          const numUnseenCVEs = filteredVulns.length - numDisplayedCVEs;

          let numSelectedUnseenCVEs = 0;
          if (selectable) {
            numSelectedUnseenCVEs = filteredVulns
              .slice(numDisplayedCVEs)
              .filter((v) => this.isCVESelected(v.cveName)).length;
          }

          return (
            <div className="cve-mini-list">
              {selectable && (
                <div className="cve-select-all">
                  <ColorCheckbox
                    checked={selectedCVENames === null}
                    indeterminate={
                      !!selectedCVENames && selectedCVENames.length > 0
                    }
                    label={`Select all (${filteredVulns.length})`}
                    onClick={() =>
                      onSelectionChange &&
                      onSelectionChange(
                        !!selectedCVENames && selectedCVENames.length === 0
                          ? undefined
                          : []
                      )
                    }
                  />
                </div>
              )}
              <div>
                <div className="mini-cves-container">
                  {filteredVulns.slice(0, numDisplayedCVEs).map((v) => (
                    <CVEMini
                      selectable={selectable}
                      selected={this.isCVESelected(v.cveName)}
                      key={v.cveName}
                      cveName={v.cveName}
                      cvss={v.cvss}
                      onClickCVE={this.getOnClickCVE(v.cveName)}
                      onSelectCVE={(selected) =>
                        this.onSelectCVE(v.cveName, selected)
                      }
                      isKnownExploitedCVE={v.isKnownExploitedCVE}
                    />
                  ))}
                </div>
                {numUnseenCVEs > 0 && (
                  <div
                    className="show-more"
                    onClick={() => this.showMore(numCVEsPerRow)}
                    style={{ width: showMoreBarWidth }}
                  >
                    <Icon name="arrow" direction={180} />
                    {numSelectedUnseenCVEs > 0
                      ? `${numSelectedUnseenCVEs} more CVE IDs selected`
                      : `Show ${numUnseenCVEs} more`}
                  </div>
                )}
              </div>
            </div>
          );
        }}
      </SizeMe>
    );
  }
}

export default appConnect<
  ICVEMiniListConnectedProps,
  never,
  ICVEMiniListOwnProps
>((state, props) => {
  if (!props.cveName) {
    let data: CPEDetail | undefined;
    const hostsHash = hashCode(props.hostnames);
    if (props.isCustomer) {
      data =
        state.cyberRisk.customerVulnsByCPEByHostnames[props.cpeName] &&
        state.cyberRisk.customerVulnsByCPEByHostnames[props.cpeName][hostsHash];
    } else {
      data = state.cyberRisk.vulnsByCPE[props.cpeName];
    }

    return {
      cveName: props.cveName,
      cpeName: props.cpeName,
      loading: data?.loading,
      vulns: data?.vulns as ICVEMiniListVuln[],
      hostsHash,
    };
  } else {
    const vulnState = state.cyberRisk.vulns[props.cveName] || {};
    const hostsHash = hashCode(props.hostnames);
    const { loading, vulnData } = vulnState;

    let vulns: ICVEMiniListVuln[] = [];
    if (vulnData) {
      vulns = [
        {
          cveName: vulnData.cve_name,
          cvss: vulnData.cvss,
          epss: vulnData.epss,
          isKnownExploitedCVE: vulnData.isActiveKnownExploited,
        },
      ];
    }

    return {
      cveName: props.cveName,
      cpeName: props.cpeName,
      loading: loading,
      vulns: vulns,
      hostsHash,
    };
  }
})(CVEMiniList);
