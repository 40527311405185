import SearchBox from "../../_common/components/SearchBox";
import ReportCard from "../../_common/components/ReportCard";
import XTable, {
  SortDirection,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import DateTimeFormat from "../../_common/components/core/DateTimeFormat";
import { useSorting, usePagination } from "../../_common/hooks";

import { SaaSUser, AppUser } from "../api/types";
import UserBaseAPI from "../api/userbase.api";
import "./ApplicationUsersView.scss";

import { FC, useState } from "react";
import { AppRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";
import UserBaseApplicationHeader from "../components/UserBaseApplicationHeader";
import { useBack } from "../../_common/types/router";
import SearchEmptyCard from "../../_common/components/SearchEmptyCard";
import EmptyCard from "../../vendorrisk/components/EmptyCard";
import TeamsPills from "../components/TeamsPills";
import UserApprovalPill, {
  userApprovalStatus,
} from "../components/UserApprovalPill";
import ScopeRiskLevelDisplay from "../components/ScopeRiskLevelDisplay";
import UserPermissionsForApp from "../components/UserPermissionsForApp";
import {
  applicationsUrl,
  appUrlPrefix,
  appUsersUrl,
} from "../UserBaseAppRouter";

const ApplicationUsersView: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();
  const { backAction, backText } = useBack();

  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppUsersV1Query({
    app: appName,
  });

  const [searchText, setSearchText] = useState<string>("");

  // Keep track of which users' rows are expanded
  const [expanded, setExpanded] = useState<string[]>([]);

  const toggleRowExpansion = (appUser: AppUser) => {
    if (expanded.indexOf(appUser.uuid) > -1) {
      setExpanded(expanded.filter((uuid) => uuid !== appUser.uuid));
    } else {
      setExpanded([...expanded, appUser.uuid]);
    }
  };

  const userIntoXTableRow = (u: AppUser): IXTableRow<string> => {
    return {
      id: u.uuid,
      onClick: () => toggleRowExpansion(u),
      expanded: expanded.includes(u.uuid),
      expandContent: (
        <UserPermissionsForApp appName={appName} userUUID={u.uuid} />
      ),
      cells: [
        <XTableCell key="name" className="app-users-cell-with-subtext">
          <div className="app-users-maintext">{u.name}</div>
          <div className="app-users-subtext">{u.roles ? u.roles[0] : ""}</div>
        </XTableCell>,
        <XTableCell key="team">
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key="security_rating">
          <div className="app-users-score-container">
            <ColorGrade score={u.rating} size={ColorGradeSize.Small} />
            <Score score={u.rating} small />
          </div>
        </XTableCell>,
        <XTableCell key="exposure_level">
          <ScopeRiskLevelDisplay riskLevel={u.highestRiskLevel} />
        </XTableCell>,
        <XTableCell key="first_detected">
          <DateTimeFormat dateTime={u.firstDetected} dateOnly />
        </XTableCell>,
        <XTableCell key="app_status">
          <UserApprovalPill approved={u.approved} waived={u.waived} />
        </XTableCell>,
      ],
    };
  };

  const [sortedUsers, sortedBy, onSortChange] = useSorting<
    AppUser,
    | "name"
    | "team"
    | "security_rating"
    | "exposure_level"
    | "first_detected"
    | "app_status"
  >(data?.users ?? [], "name", SortDirection.ASC, {
    name: {
      orderFuncs: [(u) => u.name.toLocaleLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    team: {
      orderFuncs: [
        (u) =>
          u.teams
            .map((t) => t.toLocaleLowerCase())
            .sort((a, b) => a.localeCompare(b))
            .join(","),
      ],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    security_rating: {
      orderFuncs: [(u) => u.rating],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    exposure_level: {
      orderFuncs: [(u: AppUser) => u.highestRiskLevel],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    first_detected: {
      orderFuncs: [(u) => u.firstDetected],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    app_status: {
      orderFuncs: [(u) => userApprovalStatus(u.approved, u.waived)],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const bySearchText = (user: SaaSUser): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    if (
      user.roles &&
      user.roles.some((r) => r.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    if (
      user.teams &&
      user.teams.some((t) => t.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    return user.name.toLocaleLowerCase().indexOf(toFind) >= 0;
  };

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedUsers.filter(bySearchText),
    10
  );

  const isEmptyResult = !isLoading && pageItems.length === 0;
  const isSearching = searchText !== "";

  return (
    <div className="app-users">
      <UserBaseApplicationHeader
        breadcrumbs={[
          { text: "Applications", to: applicationsUrl },
          {
            text: appName,
            to: `${appUrlPrefix}/${match.params.appName}`,
          },
          {
            text: "Users",
            to: appUsersUrl(match.params.appName),
          },
        ]}
        backAction={backAction}
        backText={backText}
        title={"Users"}
      />

      <ReportCard newStyles>
        <div className="table-filters">
          <SearchBox
            placeholder={"Search users"}
            onChanged={(q) => setSearchText(q)}
            value={searchText}
          />
        </div>

        {isEmptyResult && isSearching && (
          <SearchEmptyCard
            searchItemText={"users"}
            onClear={() => setSearchText("")}
          />
        )}
        {isEmptyResult && !isSearching && (
          <EmptyCard
            text={"There are no users for this application at this time."}
          />
        )}
        {(isLoading || !isEmptyResult) && (
          <XTable
            className="app-users-users-list"
            loading={isLoading}
            sortedBy={sortedBy}
            expandableRows
            onSortChange={onSortChange}
            pagination={{
              currentPage: currentPage,
              totalPages: totalPages,
              onPageChange: onPageChange,
              hidePaginationIfSinglePage: true,
            }}
            columnHeaders={[
              {
                id: "name",
                text: "Name",
                sortable: true,
                className: "name-col",
              },
              {
                id: "teams",
                text: "Teams",
                sortable: true,
                className: "teams-col",
              },
              {
                id: "security_rating",
                text: "User rating",
                sortable: true,
                className: "rating-col",
              },
              {
                id: "exposure_level",
                text: "Exposure level",
                sortable: true,
                className: "exposure-col",
              },
              {
                id: "first_detected",
                text: "First detected",
                sortable: true,
                className: "first-detected-col",
              },
              {
                id: "app_status",
                text: "Status",
                sortable: true,
                className: "status-col",
              },
            ]}
            rows={pageItems.map(userIntoXTableRow)}
          />
        )}
      </ReportCard>
    </div>
  );
};

export default ApplicationUsersView;
