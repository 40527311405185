import { Component } from "react";
import PropTypes from "prop-types";
import ToggleSwitch from "../../../_common/components/core/ToggleSwitch";

import Button from "../../../_common/components/core/Button";

import LoadingBanner from "../../../_common/components/core/LoadingBanner";

import {
  getOrgAuthDetails,
  updateOrgAuthDetails,
} from "../../reducers/adminActions";

import "../../styles/EditAccountAuthDetailsModal.scss";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const EditAccountAuthDetailsModalName =
  "EditAccountAuthDetailsModalName";

class EditAccountAuthDetailsModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  state = {
    loading: false,
    entitlements: null,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.props
      .dispatch(getOrgAuthDetails(this.props.modalData.org.id))
      .then((details) => {
        this.setState({
          loading: false,
          details: details,
        });
      })
      .catch(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultUnknownErrorAlert(
            "Error occurred fetching auth details for account"
          )
        );
      });
  }

  onSSOToggle = () => {
    this.setState((state) => {
      return {
        details: {
          ...state.details,
          ssoEnabled: !state.details.ssoEnabled,
        },
      };
    });
  };

  onSSOLogoutToggle = () => {
    this.setState((state) => {
      return {
        details: {
          ...state.details,
          ssoSupportsLogout: !state.details.ssoSupportsLogout,
        },
      };
    });
  };

  onMFAToggle = () => {
    this.setState((state) => {
      return {
        details: {
          ...state.details,
          mfaEnabled: !state.details.mfaEnabled,
        },
      };
    });
  };

  onChangeSSODomains = (value) => {
    this.setState((state) => {
      return {
        details: {
          ...state.details,
          ssoDomains: value,
        },
      };
    });
  };

  validateDomainName = (domain) => {
    const regexp = RegExp(
      "/^([a-z0-9A-Z-]+.)+([a-z0-9A-Z-]+.)*([a-z0-9A-Z-]+)$/"
    );
    return regexp.test(domain);
  };

  onSubmit = (evt) => {
    evt.preventDefault();

    if (this.state.details.ssoEnabled && this.state.details.mfaEnabled) {
      this.props.dispatch(
        addSimpleErrorAlert(
          `You cannot have both MFA and SSO enabled for an account at the same time`
        )
      );
      return;
    }

    const domains = (
      (this.state.details.ssoDomains ? this.state.details.ssoDomains : "") + ""
    ).split(",");
    const cleanDomains = [];
    for (let i = 0; i < domains.length; i++) {
      const domain = domains[i].trim().toLowerCase();
      if (domain.length > 0) {
        if (!this.validateDomainName(domain)) {
          cleanDomains.push(domain);
        } else {
          this.props.dispatch(
            addSimpleErrorAlert(`The email domain '${domain}' is invalid`)
          );
          return;
        }
      }
    }
    if (this.state.details.ssoEnabled && cleanDomains.length == 0) {
      this.props.dispatch(
        addSimpleErrorAlert(
          `Please enter at least one email domain for the users of this account`
        )
      );
      return;
    }

    this.setState({ loading: true });
    this.props
      .dispatch(
        updateOrgAuthDetails(this.props.modalData.org.id, this.state.details)
      )
      .then(() => {
        this.props.dispatch(closeModal());
        this.props.dispatch(
          addDefaultSuccessAlert("Account auth details updated")
        );
      })
      .catch((e) => {
        this.setState({ loading: false });
        this.props.dispatch(addSimpleErrorAlert(e.message));
      });
  };

  render() {
    const { org } = this.props.modalData;
    if (this.state.loading || !this.state.details) {
      return (
        <div className="modal-content edit-developer-toggles-modal">
          <h2>
            Configure Authentication for
            <br />
            {org.name}
          </h2>
          <p />
          <LoadingBanner />
        </div>
      );
    }

    const { ssoEnabled, mfaEnabled, ssoSupportsLogout, ssoDomains } =
      this.state.details;

    return (
      <div className="modal-content edit-account-auth-modal">
        <h2>
          Configure Authentication for
          <br />
          {org.name}
        </h2>
        <div className={"details"}>
          <table>
            <tbody>
              {ssoEnabled && (
                <tr className={"warning"}>
                  <td colSpan={3} className={"warning-cell"}>
                    {"Please make sure that the Auth0 connection is enabled."}
                  </td>
                </tr>
              )}
              {ssoSupportsLogout && (
                <tr className={"warning"}>
                  <td colSpan={3} className={"warning-cell"}>
                    {
                      "Please make sure that the Auth0 connection has the logout URL set."
                    }
                  </td>
                </tr>
              )}
              {(ssoSupportsLogout || ssoEnabled) && (
                <tr>
                  <td colSpan={3}>&nbsp;</td>
                </tr>
              )}
              <tr className={"hr-row"}>
                <td className={"label disabled"}>{"Enable MFA"}</td>
                <td>
                  <ToggleSwitch
                    name={"mfa"}
                    selected={mfaEnabled}
                    onClick={this.onMFAToggle}
                    disabled={!ssoEnabled}
                  />
                </td>
              </tr>
              <tr>
                <td className={"label"}>{"Enable SSO"}</td>
                <td>
                  <ToggleSwitch
                    name={"sso"}
                    selected={ssoEnabled}
                    onClick={this.onSSOToggle}
                  />
                </td>
              </tr>
              <tr>
                <td className={"label"}>{"SSO supports logout"}</td>
                <td>
                  <ToggleSwitch
                    name={"ssologout"}
                    selected={ssoSupportsLogout}
                    onClick={this.onSSOLogoutToggle}
                    disabled={!ssoEnabled}
                  />
                </td>
              </tr>
              <tr>
                <td className={"label"}>
                  {"User Email Domains for SSO"}
                  <br />
                  {"(comma separated)"}
                </td>
                <td colSpan={2}>
                  <textarea
                    className={"in"}
                    id={"sso_domains"}
                    key={"sso_domains"}
                    value={ssoDomains ? ssoDomains : ""}
                    onChange={(e) => this.onChangeSSODomains(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <Button
            type="submit"
            className="btn btn-primary padded"
            loading={this.state.loading}
            disabled={this.state.loading}
            onClick={this.onSubmit}
          >
            Update Settings
          </Button>
        </div>
      </div>
    );
  }
}

export default EditAccountAuthDetailsModal;
