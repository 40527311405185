import { DefaultThunkDispatch } from "../../_common/types/redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { Domain, DomainsState } from "../../_common/types/domains";
import { hashCode, LogError } from "../../_common/helpers";
import { DefaultRootState } from "react-redux";
import { addDefaultUnknownErrorAlert } from "../../_common/reducers/messageAlerts.actions";
import { IItemWithLabelsAndPortfolios } from "../../_common/types/labelsAndPortfolios";
import { ILabel, LabelClassification } from "../../_common/types/label";
import { getFiltersFromState } from "./filters.actions";
import {
  clearCloudscanData,
  fetchVendorSummaryAndCloudscans,
  SET_CUSTOMER_RISK_HOSTNAMES,
  setCloudscanData,
} from "./cyberRiskActions";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import { IPortfolioUpdateItem, Portfolio } from "./portfolios.actions";
import { getCustomerRiskHostnames } from "./risks.actions";
import { Filters } from "../components/filter/types";
import { IRiskHostnameData } from "../../_common/types/risks";
import { fetchCustomerSummaryAndCloudscans } from "./customer.actions";
import { useAppSelector } from "../../_common/types/reduxHooks";
import { CloudProviderType } from "../../appguard/api/types";

export const SET_DOMAINS = "SET_DOMAINS";
export const setDomains = (
  params: fetchDomainsParams,
  domains: DomainsState | undefined
) => setDomainsWithCacheKey(getDomainParamsKey(params), domains);

export const setDomainsWithCacheKey = (
  cacheKey: string,
  domains: DomainsState | undefined
) => ({
  type: SET_DOMAINS,
  key: cacheKey,
  domains,
});

export const getDomainParamsKey = (inParams: fetchDomainsParams): string => {
  // sort labels and object keys before stringifying for a more stable hash
  const params = { ...inParams };
  if (params.website_label_ids) {
    params.website_label_ids.sort();
  } else {
    params.website_label_ids = [];
  }
  if (params.website_portfolio_ids) {
    params.website_portfolio_ids.sort();
  } else {
    params.website_portfolio_ids = [];
  }

  const j = JSON.stringify(params, Object.keys(params).sort());
  return hashCode(j).toString();
};

export const useDomainsSelector = (
  inParams: fetchDomainsParams
): DomainsState | undefined =>
  useAppSelector(
    (state) => state.cyberRisk.domains[getDomainParamsKey(inParams)]
  );

export interface fetchDomainsParams {
  vendor_id?: number;
  is_customer?: boolean;
  is_subsidiary?: boolean;
  active: boolean;
  website_label_ids?: number[];
  website_portfolio_ids?: number[];
  risk_ids?: string[];
  website_label_ids_match_all: boolean;
  website_label_ids_do_not_match: boolean;
  website_include_unlabeled: boolean;
  appguard_cloud_providers?: string[];
  appguard_cloud_connection_uuids?: string[];
}

interface fetchDomainsResponse {
  status: string;
  result: { domains?: Domain[] };
}

export const fetchDomains = (
  params: fetchDomainsParams,
  force = false,
  noSetLoading = false
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ): Promise<Domain[] | undefined> => {
    const currentState =
      getState().cyberRisk.domains[getDomainParamsKey(params)];

    if (currentState && !force) {
      return currentState.domains || [];
    }

    // if we are looking for inactive domains with a risk filter we can shortcut since the result will always be 0
    if (!params.active && params.risk_ids && params.risk_ids.length > 0) {
      dispatch(setDomains(params, { loading: false, domains: [] }));
      return [];
    }

    if (!noSetLoading) {
      dispatch(setDomains(params, { loading: true }));
    }

    let json: fetchDomainsResponse;

    try {
      json = await FetchCyberRiskUrl(
        params.active
          ? "vendor/active_domains/v1/"
          : "vendor/inactive_domains/v1/",
        params,
        null,
        dispatch,
        getState
      );
    } catch (e) {
      dispatch(addDefaultUnknownErrorAlert("Error fetching domains"));
      console.error(e);
      setDomains(params, {
        loading: false,
        error: e,
      });
      throw e;
    }

    dispatch(
      setDomains(params, {
        loading: false,
        domains: json.result?.domains,
      })
    );

    return json.result?.domains;
  };
};

export const CLEAR_DOMAINS = "CLEAR_DOMAINS";
export const clearDomains = () => ({ type: CLEAR_DOMAINS });

// Returns a new array with the allItems elements updated with the adjusted labels
export const adjustLabels = <ItemType extends IItemWithLabelsAndPortfolios>(
  allItems: ItemType[],
  shouldApplyLabelChangeFunc: (item: ItemType) => boolean,
  allLabels: ILabel[],
  addedLabelIds: number[],
  removedLabelIds: number[]
) => {
  const labelsToAdd = allLabels.filter(
    (l) => addedLabelIds.indexOf(l.id) !== -1
  );

  const dupedArray = <ItemType[]>[];

  allItems.forEach((item) => {
    if (shouldApplyLabelChangeFunc(item)) {
      const clonedItem = { ...item };

      // Remove labels
      if (removedLabelIds.length > 0 && clonedItem.labels) {
        clonedItem.labels = clonedItem.labels.filter(
          (l) => removedLabelIds.indexOf(l.id) === -1
        );
      }

      // Add labels
      if (labelsToAdd.length > 0) {
        if (clonedItem.labels) {
          clonedItem.labels = [...clonedItem.labels, ...labelsToAdd];
        } else {
          clonedItem.labels = [...labelsToAdd];
        }
      }

      dupedArray.push(clonedItem);
    } else {
      dupedArray.push(item);
    }
  });

  return dupedArray;
};

// After updating the labels for a set of domains,
// ensure the redux state is kept up to date.
export const updateStateAfterLabelsChange = (
  dispatch: DefaultThunkDispatch,
  state: DefaultRootState,
  datastoreVendorId: number | undefined,
  isSubsidiary: boolean | undefined,
  clearDomainDetailsCache: boolean,
  hostnames: string[],
  addedLabelIds: number[],
  removedLabelIds: number[]
) => {
  const currentFilters = getFiltersFromState(
    state,
    datastoreVendorId,
    isSubsidiary
  );

  const adjustDomainsFunc = (active: boolean) => {
    const domainsCacheParams: fetchDomainsParams = {
      vendor_id: datastoreVendorId,
      is_subsidiary: isSubsidiary,
      is_customer: !datastoreVendorId,
      active: active,
      website_label_ids: currentFilters.websiteLabelIds,
      website_portfolio_ids: currentFilters.domainPortfolioIds,
      risk_ids: currentFilters.riskIds,
      website_label_ids_match_all: currentFilters.websiteLabelIdsMatchAll,
      website_label_ids_do_not_match: currentFilters.websiteLabelIdsDoNotMatch,
      website_include_unlabeled: currentFilters.websiteIncludeUnlabeled,
      appguard_cloud_providers: currentFilters.cloudConnectionProviderTypes,
      appguard_cloud_connection_uuids: currentFilters.cloudConnectionUUIDs,
    };

    const domainState =
      state.cyberRisk.domains[getDomainParamsKey(domainsCacheParams)];

    if (domainState && domainState.domains) {
      const updatedDomains = adjustLabels<Domain>(
        domainState.domains,
        (domain) => hostnames.indexOf(domain.hostname) !== -1,
        state.cyberRisk.availableLabels.filter(
          (l) => l.classification === LabelClassification.WebsiteLabel
        ),
        addedLabelIds,
        removedLabelIds
      );

      dispatch(
        setDomains(domainsCacheParams, {
          loading: false,
          domains: updatedDomains,
        })
      );
    }
  };

  // Update the redux store used for the domain lists
  adjustDomainsFunc(true);
  adjustDomainsFunc(false);

  // Optionally, clear the per-domain cache
  if (clearDomainDetailsCache) {
    dispatch(clearCloudscanData());
  }

  if (currentFilters.websiteLabelIds.length > 0) {
    let filtersChanged = false;
    for (let i = 0; i < addedLabelIds.length; i++) {
      if (currentFilters.websiteLabelIds.indexOf(addedLabelIds[i]) > -1) {
        filtersChanged = true;
        break;
      }
    }

    if (!filtersChanged) {
      for (let i = 0; i < removedLabelIds.length; i++) {
        if (currentFilters.websiteLabelIds.indexOf(removedLabelIds[i]) > -1) {
          filtersChanged = true;
          break;
        }
      }
    }

    if (filtersChanged) {
      // the changed label would affect the result of a filter to clear the domains cache
      dispatch(clearDomains());

      if (!datastoreVendorId) {
        dispatch(fetchCustomerSummaryAndCloudscans(true));
      } else {
        dispatch(
          fetchVendorSummaryAndCloudscans(
            datastoreVendorId,
            true,
            false,
            false,
            isSubsidiary,
            currentFilters
          )
        );
      }
    }
  }

  // kick off call to update the activity stream
  dispatch(conditionalRefreshActivityStreamForOrgUser());
};

// After updating the portfolios for a set of domains,
// ensure the redux state is kept up to date.
export const updateDomainsStateAfterPortfoliosChange = async (
  dispatch: DefaultThunkDispatch,
  state: DefaultRootState,
  updateItems: IPortfolioUpdateItem[],
  allPortfolios: Portfolio[],
  applyToSubdomains: boolean
) => {
  const currentFilters = getFiltersFromState(state, undefined, undefined);

  const newPortfoliosByHostname: Record<string, Portfolio[]> = {};
  for (let i = 0; i < updateItems.length; i++) {
    const updateItem = updateItems[i];
    const portfoliosForSet = allPortfolios.filter((p) =>
      updateItem.portfolioIds.includes(p.id)
    );

    for (let j = 0; j < updateItem.itemIds.length; j++) {
      const hostname = updateItem.itemIds[j] as string;
      newPortfoliosByHostname[hostname] = portfoliosForSet;
    }
  }

  const getDomainsCacheParams = (active: boolean): fetchDomainsParams => ({
    vendor_id: undefined,
    is_subsidiary: false,
    is_customer: true,
    active: active,
    website_label_ids: currentFilters.websiteLabelIds,
    website_portfolio_ids: currentFilters.domainPortfolioIds,
    risk_ids: currentFilters.riskIds,
    website_label_ids_match_all: currentFilters.websiteLabelIdsMatchAll,
    website_label_ids_do_not_match: currentFilters.websiteLabelIdsDoNotMatch,
    website_include_unlabeled: currentFilters.websiteIncludeUnlabeled,
    appguard_cloud_providers: currentFilters.cloudConnectionProviderTypes,
    appguard_cloud_connection_uuids: currentFilters.cloudConnectionUUIDs,
  });

  if (applyToSubdomains || currentFilters.domainPortfolioIds.length > 0) {
    // the changed portfolios might affect the result of a filter, or could affect more hostnames
    // than passed in, so just fully refresh the domains cache.

    // make sure domains are cleared for all cache keys when a filter is applied to avoid stale data
    dispatch(clearDomains());

    dispatch(fetchCustomerSummaryAndCloudscans(true));

    await Promise.all([
      dispatch(fetchDomains(getDomainsCacheParams(true), true, true)),
      dispatch(fetchDomains(getDomainsCacheParams(false), true, true)),
    ]);
  } else {
    const domainKeysToKeep: string[] = [];

    const adjustDomainsFunc = (active: boolean) => {
      const domainsCacheParams = getDomainsCacheParams(active);

      const domainKey = getDomainParamsKey(domainsCacheParams);
      domainKeysToKeep.push(domainKey);

      const domainState = state.cyberRisk.domains[domainKey];

      if (domainState && domainState.domains) {
        const updatedDomains: Domain[] = domainState.domains.map((domain) => {
          const newPortfolios = newPortfoliosByHostname[domain.hostname];
          if (newPortfolios) {
            return {
              ...domain,
              portfolios: newPortfolios,
            };
          } else {
            return domain;
          }
        });

        dispatch(
          setDomains(domainsCacheParams, {
            loading: false,
            domains: updatedDomains,
          })
        );
      }
    };

    // Update the redux store used for the domain lists
    adjustDomainsFunc(true);
    adjustDomainsFunc(false);

    // Clear out any other cached domain states - they've become invalid
    for (const domainKey in state.cyberRisk.domains) {
      if (!domainKeysToKeep.includes(domainKey)) {
        dispatch(setDomainsWithCacheKey(domainKey, undefined));
      }
    }
  }

  // Now update any existing cloudscan data
  Object.entries(newPortfoliosByHostname).forEach(
    ([hostname, newPortfolios]) => {
      const existingCloudscanData = state.cyberRisk.webscans[hostname];
      if (!existingCloudscanData) {
        return;
      }

      dispatch(
        setCloudscanData(hostname, {
          result: {
            ...existingCloudscanData.result,
            portfolios: newPortfolios,
          },
        })
      );
    }
  );

  // kick off call to update the activity stream
  dispatch(conditionalRefreshActivityStreamForOrgUser());
};

export const fetchCustomerRiskHostnames =
  (
    riskId: string,
    overrideFilters?: Partial<Filters>,
    skipWaiverId?: number,
    includeWaived = false
  ) =>
  async (dispatch: DefaultThunkDispatch, getState: () => DefaultRootState) => {
    if (
      getCustomerRiskHostnames(
        getState(),
        riskId,
        overrideFilters as Filters,
        skipWaiverId,
        includeWaived
      ).data
    ) {
      return;
    }

    const filters =
      overrideFilters ?? getState().cyberRisk.customerData.filters;

    const {
      websiteLabelIds,
      domainPortfolioIds,
      websiteLabelIdsMatchAll,
      websiteLabelIdsDoNotMatch,
      websiteIncludeUnlabeled,
      cloudConnectionProviderTypes,
      cloudConnectionUUIDs,
    } = filters;

    dispatch(
      setCustomerRiskHostnames(
        riskId,
        true,
        null,
        null,
        websiteLabelIds,
        domainPortfolioIds,
        skipWaiverId,
        includeWaived
      )
    );

    let json;
    try {
      json = await FetchCyberRiskUrl<IRiskHostnameData>(
        "customer/hostnameswithrisk/v1/",
        {
          risk_id: riskId,
          website_label_ids: websiteLabelIds,
          website_label_ids_match_all: websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: websiteLabelIdsDoNotMatch,
          website_include_unlabeled: websiteIncludeUnlabeled,
          website_portfolio_ids: domainPortfolioIds,
          skip_waiver_id: skipWaiverId,
          include_waived: includeWaived,
          appguard_cloud_providers: cloudConnectionProviderTypes,
          appguard_cloud_connection_uuids: cloudConnectionUUIDs,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching vendor hostnames with risk", e);
    }

    if (!json) {
      dispatch(
        setCustomerRiskHostnames(
          riskId,
          false,
          {
            errorText: "no data",
          },
          null,
          undefined,
          undefined,
          skipWaiverId,
          includeWaived
        )
      );
      return;
    }

    dispatch(
      setCustomerRiskHostnames(
        riskId,
        false,
        null,
        json,
        websiteLabelIds,
        domainPortfolioIds,
        skipWaiverId,
        includeWaived
      )
    );
  };

export const setCustomerRiskHostnames = (
  riskId: string,
  loading = false,
  error: IError | null,
  data: IRiskHostnameData | null,
  websiteLabelIds?: number[],
  domainPortfolioIds?: number[],
  skipWaiverId?: number,
  includeWaived = false,
  cloudConnectionProviderTypes: CloudProviderType[] = [],
  cloudConnectionUUIDs: string[] = []
) => {
  return {
    type: SET_CUSTOMER_RISK_HOSTNAMES,
    riskId,
    data,
    loading,
    error,
    websiteLabelIds,
    domainPortfolioIds,
    skipWaiverId,
    includeWaived,
    cloudConnectionProviderTypes,
    cloudConnectionUUIDs,
  };
};
