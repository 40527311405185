import { cvssToText } from "../helpers";

export enum Severity {
  Pass = "pass",
  Info = "info",
  Low = "low",
  Medium = "medium",
  High = "high",
  Critical = "critical",
}

export enum SeverityInt {
  PassSeverity,
  InfoSeverity,
  LowSeverity,
  MediumSeverity,
  HighSeverity,
  CriticalSeverity,
}

// TODO this is a duplicate of cloudscanSeverityNumberToText?
export const SeverityAsString = (s: SeverityInt): Severity => {
  switch (s) {
    case SeverityInt.PassSeverity:
      return Severity.Pass;
    case SeverityInt.InfoSeverity:
      return Severity.Info;
    case SeverityInt.LowSeverity:
      return Severity.Low;
    case SeverityInt.MediumSeverity:
      return Severity.Medium;
    case SeverityInt.HighSeverity:
      return Severity.High;
    case SeverityInt.CriticalSeverity:
      return Severity.Critical;
  }
  return Severity.Pass;
};

export const SeverityFromString = (s: Severity): SeverityInt => {
  switch (s) {
    case Severity.Pass:
      return SeverityInt.PassSeverity;
    case Severity.Info:
      return SeverityInt.InfoSeverity;
    case Severity.Low:
      return SeverityInt.LowSeverity;
    case Severity.Medium:
      return SeverityInt.MediumSeverity;
    case Severity.High:
      return SeverityInt.HighSeverity;
    case Severity.Critical:
      return SeverityInt.CriticalSeverity;
  }
  return SeverityInt.PassSeverity;
};

function isSeverity(s: string): s is Severity {
  return Object.values(Severity).includes(s as Severity);
}

export const cvssToSeverity = (score: number): Severity => {
  const scoreString = cvssToText(score);
  if (isSeverity(scoreString)) {
    return scoreString;
  }

  return Severity.Pass;
};

// matches frontends/_common/helpers.jsx
// NOTE that SeverityInt.PassSeverity and SeverityInt.InfoSeverity are not in the codomain.
// TODO We should probably tighen up the types to reflect the codomain correctly.
export function cvssToSeverityInt(cvss: number): SeverityInt {
  if (cvss < 4) {
    return SeverityInt.LowSeverity;
  } else if (cvss < 7) {
    return SeverityInt.MediumSeverity;
  } else if (cvss < 9) {
    return SeverityInt.HighSeverity;
  }
  return SeverityInt.CriticalSeverity;
}
