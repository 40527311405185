import {
  hubSpotIsAvailable,
  openHubSpot,
} from "./components/HubspotWidgetWrapper";

export default function contactSupport() {
  if (hubSpotIsAvailable()) {
    openHubSpot();
  } else if (typeof window.Intercom === "function") {
    window.Intercom("showNewMessage");
  } else {
    window.open("mailto:support@upguard.com");
  }
}
