import BaseAPI from "../../_common/rtkQueryApi";
import {
  fetchSurveyDetails,
  fetchSurveyTimeline,
} from "../../_common/reducers/surveyDetails.actions";
import { ISurveyListItemResponse } from "../../_common/types/survey";
import UsersAPI, { UsersTagTypes } from "../../_common/api/usersAPI";

const surveyAPI = BaseAPI.enhanceEndpoints({
  addTagTypes: [],
}).injectEndpoints({
  endpoints: (builder) => ({
    addCollaborator: builder.mutation<
      void,
      {
        surveyId: number;
        usersToAdd: string[];
        message: string;
      }
    >({
      query: (args) => ({
        url: "collaboratesurvey/v1",
        method: "POST",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(fetchSurveyTimeline(arg.surveyId, true));
          dispatch(fetchSurveyDetails(arg.surveyId, true));
          dispatch(
            UsersAPI.util.invalidateTags([UsersTagTypes.orgCollaborator])
          );
        });
      },
    }),

    deleteCollaborator: builder.mutation<
      void,
      {
        surveyId: number;
        userEmail: string;
      }
    >({
      query: (args) => ({
        url: "collaboratesurvey/v1",
        method: "DELETE",
        body: JSON.stringify(args),
      }),
      onQueryStarted: (arg, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(fetchSurveyTimeline(arg.surveyId, true));
          dispatch(fetchSurveyDetails(arg.surveyId, true));
          dispatch(
            UsersAPI.util.invalidateTags([UsersTagTypes.orgCollaborator])
          );
        });
      },
    }),

    getCollaboratorSurveys: builder.query<
      { surveys: ISurveyListItemResponse[] },
      void
    >({
      query: () => ({
        url: "surveys/collaboration/v1",
        method: "GET",
      }),
    }),
  }),
});

export default surveyAPI;
