import PageHeader from "../../_common/components/PageHeader";
import SearchBox from "../../_common/components/SearchBox";
import ReportCard from "../../_common/components/ReportCard";
import { useDefaultHistory } from "../../_common/types/router";
import XTable, {
  SortDirection,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSorting, usePagination } from "../../_common/hooks";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import CyberTrendScore from "../../vendorrisk/components/CyberTrendScore";

import { UserWithStats } from "../api/types";
import UserBaseAPI from "../api/userbase.api";
import "./UsersView.scss";

import { FC, useState } from "react";
import TeamsPills from "../components/TeamsPills";
import { userUrl } from "../UserBaseAppRouter";
import { useLocation } from "react-router-dom";
import { SingleStatSize } from "../components/SingleStat";
import DashboardStat, {
  DashboardStatToShow,
} from "../components/DashboardStat";

const USER_LIST_PAGE_SIZE = 100;

const UsersView: FC = () => {
  const history = useDefaultHistory();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState<string>("");

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUsersV1Query({});

  const [sortedUsers, sortedBy, onSortChange] = useSorting<
    UserWithStats,
    | "name"
    | "team"
    | "security_rating"
    | "total_apps"
    | "not_approved_apps"
    | "waived_apps"
  >(
    data?.users ?? [],
    "name",
    SortDirection.ASC,
    {
      name: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      team: {
        orderFuncs: [
          (u) =>
            (u.teams ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      security_rating: {
        orderFuncs: [(u) => u.adjustedRating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      total_apps: {
        orderFuncs: [(u) => u.totalApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      not_approved_apps: {
        orderFuncs: [(u) => u.notApprovedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      waived_apps: {
        orderFuncs: [(u) => u.waivedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    pathname
  );

  const bySearchText = (user: UserWithStats): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    if (
      user.roles &&
      user.roles.some((r) => r.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    if (
      user.teams &&
      user.teams.some((t) => t.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    return user.name.toLocaleLowerCase().indexOf(toFind) >= 0;
  };

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedUsers.filter(bySearchText),
    USER_LIST_PAGE_SIZE,
    undefined,
    pathname
  );

  const intoXTableRow = (u: UserWithStats): IXTableRow<string> => {
    return {
      id: u.uuid,
      onClick: () => {
        history.push(userUrl(u.uuid));
      },
      cells: [
        <XTableCell key={"name"}>{u.name}</XTableCell>,
        <XTableCell key={"role"}>{u.roles ? u.roles[0] : ""}</XTableCell>,
        <XTableCell key={"team"}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={"security_rating"}>
          <div className="score-container">
            <ColorGrade score={u.adjustedRating} size={ColorGradeSize.Small} />
            <Score score={u.adjustedRating} small />
          </div>
        </XTableCell>,
        <XTableCell key={"trend"}>
          <div className="cyber-trend">
            <CyberTrendScore trend={u.trend} />
          </div>
        </XTableCell>,
        <XTableCell key={"total_apps"}>{u.totalApps}</XTableCell>,
        <XTableCell key={"not_approved_apps"}>{u.notApprovedApps}</XTableCell>,
        <XTableCell key={"waived_apps"}>{u.waivedApps}</XTableCell>,
      ],
    };
  };

  return (
    <div className="userbase-users">
      <PageHeader
        history={history}
        title={
          <div className="title-and-count">
            Users
            <DashboardStat
              statToShow={DashboardStatToShow.UserCount}
              statSize={SingleStatSize.Small}
            />
          </div>
        }
        infoSection={
          <>
            <p>
              Users shows all the users associated with your organization. Each
              user is given a security rating based on the user-related
              activities we have scanned. To get more information about a
              particular user, click on the user.
            </p>
          </>
        }
      />

      <ReportCard newStyles>
        <div className={"table-filters"}>
          <SearchBox
            placeholder={"Search users"}
            onChanged={(q) => setSearchText(q)}
            value={searchText}
          />
        </div>

        <XTable
          className={"userbase-users-list"}
          loading={isLoading}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          iconOptions
          pagination={{
            currentPage: currentPage,
            totalPages: totalPages,
            onPageChange: onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          columnHeaders={[
            { id: "name", text: "Name", sortable: true },
            { id: "role", text: "Role" },
            { id: "team", text: "Team", sortable: true },
            { id: "security_rating", text: "Security rating", sortable: true },
            { id: "trend", text: "Trend" },
            { id: "total_apps", text: "Total apps", sortable: true },
            {
              id: "not_approved_apps",
              text: "Not approved apps",
              sortable: true,
            },
            {
              id: "waived_apps",
              text: "Waived apps",
              sortable: true,
            },
          ]}
          rows={pageItems?.map(intoXTableRow) ?? []}
        />
      </ReportCard>
    </div>
  );
};

export default UsersView;
