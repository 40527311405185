import Button from "../../../_common/components/core/Button";
import Modal from "../../../_common/components/ModalV2";
import { useEffect, useState } from "react";
import { DefaultThunkDispatchProp } from "../../../_common/types/redux";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";

import "../../style/components/StopMonitoringVendorsConfirmtaionModal.scss";
import { getVendorWords } from "../../../_common/constants";
import { AssuranceType } from "../../../_common/types/organisations";
import {
  getVendorOpenWorkflowStatuses,
  updateVendorWatchStatusMulti,
} from "../../reducers/cyberRiskActions";
import { addSimpleErrorAlert } from "../../../_common/reducers/messageAlerts.actions";
import { IWatchedVendorSearchResult } from "../../../_common/types/vendor";
import { appConnect } from "../../../_common/types/reduxHooks";

interface StopMonitoringVendorsConfirmationModalOwnProps {
  active: boolean;
  onClose: () => void;
  vendors: IWatchedVendorSearchResult[];
  onRemoved: () => void;
}

interface StopMonitoringVendorsConfirmationModalConnectedProps {
  assuranceType: AssuranceType;
}

type StopMonitoringVendorsConfirmationModalProps =
  StopMonitoringVendorsConfirmationModalOwnProps &
    StopMonitoringVendorsConfirmationModalConnectedProps &
    DefaultThunkDispatchProp;

const StopMonitoringVendorsConfirmationModal = (
  props: StopMonitoringVendorsConfirmationModalProps
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [inError, setInError] = useState("");
  const [isOpenQuestionnaires, setIsOpenQuestionnaires] = useState(false);
  const [
    isOpenRelationshipQuestionnaires,
    setIsOpenRelationshipQuestionnaires,
  ] = useState(false);
  const [isOpenRemediation, setIsOpenRemediation] = useState(false);

  // Respond to prop changes
  useEffect(() => {
    if (props.vendors.length > 0 && props.active) {
      loadData();
    } else if (props.vendors.length == 0) {
      setInError("No monitored vendor(s) supplied");
    }
  }, [props.active]);

  const loadData = async () => {
    setIsLoading(true);
    setInError("");
    try {
      const vendorIds = props.vendors.map((v) => v.id);
      const results = await props.dispatch(
        getVendorOpenWorkflowStatuses(vendorIds)
      );
      setIsOpenRelationshipQuestionnaires(false);
      setIsOpenQuestionnaires(false);
      setIsOpenRemediation(false);
      Object.keys(results).forEach((vendorID) => {
        if (results[vendorID].numOpenRelationshipQns > 0) {
          setIsOpenRelationshipQuestionnaires(true);
        }
        if (results[vendorID].numOpenVendorQns > 0) {
          setIsOpenQuestionnaires(true);
        }
        if (results[vendorID].numOpenRemediationReqs > 0) {
          setIsOpenRemediation(true);
        }
      });
    } catch (e) {
      setIsLoading(false);
      props.dispatch(
        addSimpleErrorAlert(
          "Failed to determine status of vendors: " + (e as any).message
        )
      );
      setInError((e as any).message);
      throw e;
    }
    setIsLoading(false);
  };

  const unWatchVendors = async () => {
    setIsRemoving(true);
    try {
      const vendorIds = props.vendors.map((v) => v.id);
      await props
        .dispatch(updateVendorWatchStatusMulti([], vendorIds))
        .then(() => props.onRemoved());
      setIsRemoving(false);
      props.onClose();
    } catch (e) {
      setIsRemoving(false);
      props.dispatch(
        addSimpleErrorAlert(`Failed to unwatch: ${(e as any).message}`)
      );
      setInError((e as any).message);
      throw e;
    }
  };

  const vendorWords = getVendorWords(props.assuranceType);
  const ref =
    props.vendors.length == 1
      ? `${props.vendors[0].name}`
      : `these ${vendorWords.plural}`;
  const missingList = [];
  if (isOpenQuestionnaires) {
    missingList.push("security questionnaires");
  }
  if (isOpenRelationshipQuestionnaires) {
    missingList.push("relationship questionnaires");
  }
  if (isOpenRemediation) {
    missingList.push("remediation requests");
  }
  let pending = "";
  missingList.forEach((w, idx) => {
    if (idx == 0) {
      pending = w;
    } else if (idx > 0 && idx < missingList.length - 1) {
      pending = pending + ", " + w;
    } else {
      pending = pending + " and " + w;
    }
  });

  return (
    <Modal
      headerContent={`Remove ${
        props.vendors.length == 1
          ? vendorWords.singularTitleCase
          : vendorWords.pluralTitleCase
      }`}
      className={"stop-monitoring-modal"}
      active={props.active}
      onClose={props.onClose}
      footerContent={
        <>
          <Button tertiary onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            primary
            onClick={() => {
              unWatchVendors();
            }}
            loading={isRemoving}
            disabled={isLoading || isRemoving || inError != ""}
          >
            Remove
          </Button>
        </>
      }
    >
      {isLoading && <LoadingIcon size={32} />}
      {!isLoading && inError == "" && (
        <>
          <p>
            {`Are you sure you want to stop monitoring ${ref}?`}
            {missingList.length > 0 &&
              ` Your pending ${pending} will be archived.`}
          </p>
        </>
      )}
      {!isLoading && inError != "" && (
        <>
          <p>{`Unfortunately, something went wrong: ${inError}`}</p>
          <Button
            onClick={() => {
              loadData();
            }}
          >
            Try Again
          </Button>
        </>
      )}
    </Modal>
  );
};

export default appConnect<
  StopMonitoringVendorsConfirmationModalConnectedProps,
  never,
  StopMonitoringVendorsConfirmationModalOwnProps
>((state) => {
  return {
    assuranceType: state.common.userData.assuranceType,
  };
})(StopMonitoringVendorsConfirmationModal);
