import ModalV2, { BaseModalProps } from "../../../_common/components/ModalV2";
import { FC } from "react";
import Button from "../../../_common/components/core/Button";

import "../../style/components/VendorOnboardingFirstTimeSetupModal.scss";
import InfoBanner, { BannerType } from "../InfoBanner";

import { useHistory } from "react-router-dom";
import { isAdmin } from "../../helpers/roles";
import { RelationshipQuestionnaireStateType } from "../../../_common/types/organisations";
import {
  OrgAccessVendorPortfolios,
  OrgAccessVendors,
  UserVendorRiskWrite,
} from "../../../_common/permissions";
import { appConnect } from "../../../_common/types/reduxHooks";

type VendorOnboardingFirstTimeSetupModalOwnProps = BaseModalProps;

interface VendorOnboardingFirstTimeSetupModalConnectedProps {
  hasSetupPermission: boolean;
  orgHasVendorRisk: boolean;
  userHasVendorRiskWrite: boolean;
  relationshipQuestionnaireState: RelationshipQuestionnaireStateType;
}

type VendorOnboardingFirstTimeSetupModalProps =
  VendorOnboardingFirstTimeSetupModalOwnProps &
    VendorOnboardingFirstTimeSetupModalConnectedProps;

// VendorOnboardingFirstTimeSetupModal will be displayed when users click on the "Set it up" button on the release notes
// notification for the vendor relationship questionnaire feature. This modal introduces the feature and allows admin users to
// click through to the "Review Questions" step of setting up the vendor relationship quesitonnaire feature.
const VendorOnboardingFirstTimeSetupModal: FC<
  VendorOnboardingFirstTimeSetupModalProps
> = ({
  active,
  onClose,
  hasSetupPermission,
  relationshipQuestionnaireState,
  orgHasVendorRisk,
  userHasVendorRiskWrite,
}) => {
  const history = useHistory();
  return (
    <ModalV2
      className="vendor-onboarding-first-setup-modal"
      active={active}
      onClose={onClose}
      headerContent={
        orgHasVendorRisk &&
        relationshipQuestionnaireState ===
          RelationshipQuestionnaireStateType.active
          ? "Send a Vendor Relationship Questionnaire"
          : "Configure your Vendor Relationship Questionnaire"
      }
      footerContent={
        <>
          <Button
            className="left-button"
            tertiary
            onClick={() =>
              window.open(
                "https://help.upguard.com/en/articles/6192683-how-to-use-vendor-relationship-questionnaires",
                "_blank"
              )
            }
          >
            Learn more
          </Button>
          {orgHasVendorRisk &&
          hasSetupPermission &&
          (relationshipQuestionnaireState ===
            RelationshipQuestionnaireStateType.initial ||
            relationshipQuestionnaireState ===
              RelationshipQuestionnaireStateType.inactive) ? (
            <>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                filledPrimary
                onClick={() =>
                  history.push(`/settings/relationship_questionnaire`, {
                    backTo: history.location.pathname,
                    backToText: "Back",
                  })
                }
              >
                Review questions <div className="cr-icon-arrow-right" />
              </Button>
            </>
          ) : orgHasVendorRisk &&
            userHasVendorRiskWrite &&
            relationshipQuestionnaireState ===
              RelationshipQuestionnaireStateType.active ? (
            <>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                filledPrimary
                onClick={() =>
                  history.push(`/vendorlist`, {
                    backTo: history.location.pathname,
                    backToText: "Back",
                  })
                }
              >
                Monitor a vendor <div className="cr-icon-arrow-right" />
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose}>Close</Button>
            </>
          )}
        </>
      }
    >
      {orgHasVendorRisk &&
      relationshipQuestionnaireState ===
        RelationshipQuestionnaireStateType.active ? (
        <>
          <p>
            Looks like your organization already has a relationship
            questionnaire configured, great!
          </p>
          <p>
            To start using it, monitor a new vendor and follow the steps to send
            or self-assign a relationship questionnaire.
          </p>
        </>
      ) : (
        <>
          <p>
            Before enabling this feature, UpGuard requires you to review the
            questions you&apos;ll be using to understand your company&apos;s
            relationship to new vendors.
          </p>
          <p>
            After reviewing, you&apos;ll be able to send your first vendor
            relationship questionnaire by selecting a vendor and sending the
            request.
          </p>
        </>
      )}
      {!orgHasVendorRisk ? (
        <InfoBanner
          type={BannerType.INFO}
          message={
            <>
              This feature requires the Vendor Risk module. To find out more,
              please contact Customer Success.
            </>
          }
        />
      ) : !hasSetupPermission &&
        relationshipQuestionnaireState !==
          RelationshipQuestionnaireStateType.active ? (
        <InfoBanner
          type={BannerType.INFO}
          message={
            <>
              As you have limited permissions, you&apos;ll need to ask an
              administrator in your account to set up this feature before it can
              be used.
            </>
          }
        />
      ) : null}
    </ModalV2>
  );
};

export default appConnect<
  VendorOnboardingFirstTimeSetupModalConnectedProps,
  never,
  VendorOnboardingFirstTimeSetupModalOwnProps
>((state) => {
  const {
    orgPermissions,
    userPermissions,
    vendorPortfolioSpecificPermissions,
  } = state.common.userData;

  let userHasVendorRiskWrite = userPermissions.includes(UserVendorRiskWrite);

  const orgHasPortfoliosEnabled =
    orgPermissions && orgPermissions.includes(OrgAccessVendorPortfolios);
  if (
    !userHasVendorRiskWrite &&
    orgHasPortfoliosEnabled &&
    vendorPortfolioSpecificPermissions &&
    Object.keys(vendorPortfolioSpecificPermissions).length > 0
  ) {
    userHasVendorRiskWrite = Object.values(
      vendorPortfolioSpecificPermissions
    ).some((perms) => perms?.includes(UserVendorRiskWrite));
  }

  return {
    hasSetupPermission: isAdmin(state.common.userData.currentOrgRoles),
    orgHasVendorRisk:
      state.common.userData.orgPermissions.includes(OrgAccessVendors),
    userHasVendorRiskWrite,
    relationshipQuestionnaireState:
      state.common.userData.relationshipQuestionnaireState,
  };
})(VendorOnboardingFirstTimeSetupModal);
