import {
  OnboardingStepName,
  OnboardingStepsMeta,
} from "../types/onboardingStepsMeta";
import { DefaultThunkDispatch } from "../types/redux";
import OnboardingUserIcon from "../images/onboarding-user-icon.svg";
import OnboardingDocumentIcon from "../images/onboarding-document-icon.svg";
import "../style/components/OnboardingSteps.scss";
import { FC, FormEvent, FormEventHandler, useState, useEffect } from "react";
import TextField from "../components/TextField";
import Button from "../components/core/Button";
import {
  setOnboardingStepComplete,
  updateUserProfile,
} from "../reducers/commonActions";
import { getNamedRoles } from "../../_common/reducers/commonActions";
import { validateEmail } from "../helpers";
import { addDefaultUnknownErrorAlert } from "../reducers/messageAlerts.actions";
import {
  addOrgUsers,
  updateVendorSurveyAddToSharedProfile,
} from "../../vendorrisk/reducers/cyberRiskActions";
import { trackEvent } from "../tracking";
import TrackedButton from "../components/TrackedButton";
import AcknowledgeRisks from "../images/onboarding/acknowledge-risks.svg";
import VendorRatings from "../images/onboarding/vendor-ratings.svg";
import VendorSecurityRatingsOverTime from "../images/onboarding/vendor-security-ratings-over-time.svg";
import WebscannerRating from "../images/onboarding/webscanner-rating.svg";
import Avatars from "../images/onboarding/invite-avatars.png";
import OnboardingNav from "./OnboardingNav";
import { SelectV2 } from "../../_common/components/SelectV2";
import { NamedRole } from "../../_common/types/namedRole";
import { History } from "history";

export const shouldShowOnboardingScreen = (
  onboardingSteps: OnboardingStepsMeta
): boolean => {
  for (const stepName of Object.values(OnboardingStepName)) {
    if (
      stepName !== OnboardingStepName.TrialAccountRevertedToFree && // Do not show onboarding screen if this step is present, it's shown as a modal instead.
      stepName !== OnboardingStepName.QuickStart &&
      stepName !== OnboardingStepName.TermsConditionsMar24 &&
      !!onboardingSteps[stepName]
    ) {
      return true;
    }
  }

  return false;
};

interface IHelpSectionProps {
  name: string;
  title: string;
  href: string;
  description: string;
  openIntercom?: boolean;
}

const HelpSection = (props: IHelpSectionProps) => {
  return (
    <div className={"onboaring-help-section"}>
      <a
        href={props.href}
        onClick={(e) => {
          e.preventDefault();
          if (props.openIntercom) {
            // @ts-ignore
            if (typeof window.Intercom === "function") {
              // @ts-ignore
              window.Intercom("show");
            }
          } else {
            window.open(props.href);
          }
          trackEvent(`OnboardingSteps_${props.name}_HelpClicked`);
        }}
      >
        {props.title}
      </a>
      <p>{props.description}</p>
    </div>
  );
};

interface IStepProps {
  dispatch: DefaultThunkDispatch;
  advanceStep: (skipSkippableSteps?: boolean) => void;
  onboardingSteps: OnboardingStepsMeta;
}

interface INameOnboaringStepProps extends IStepProps {
  firstName: string;
  setFirstName: (val: string) => void;
  lastName: string;
  setLastName: (val: string) => void;
  jobTitle: string;
  setJobTitle: (val: string) => void;
  afterSetName: () => void;
  submitName: () => Promise<void>;
  nameSaving: boolean;
}

const SetFirstLastNameOnboardingStep = (props: INameOnboaringStepProps) => {
  const isVendor =
    props.onboardingSteps[OnboardingStepName.SetFirstLastName]?.isVendor ||
    false;

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (props.nameSaving) {
      return;
    }

    trackEvent("OnboardingSteps_SetFirstLastName");

    props.submitName().then(() => {
      props.advanceStep();
      props.afterSetName();
    });
  };

  if (isVendor) {
    return (
      <div className="onboarding-step centred set-first-last-name-step">
        <div className="onboarding-icon">
          <img src={OnboardingUserIcon} alt="user" />
        </div>
        <h1>What&apos;s your name?</h1>
        <p>
          Your name appears on your profile and is visible when you send or
          respond to requests.
        </p>
        <form onSubmit={onSubmit}>
          <TextField
            name="firstName"
            required
            value={props.firstName}
            onChanged={props.setFirstName}
            placeholder="First name"
          />
          <TextField
            name="lastName"
            required
            value={props.lastName}
            onChanged={props.setLastName}
            placeholder="Last name"
          />
          <Button filledPrimary loading={props.nameSaving} type="submit">
            Continue
          </Button>
        </form>
      </div>
    );
  } else {
    return (
      <div className={"onboarding-quickstart-container"}>
        <div className={"onboarding-step set-first-last-name-step-org"}>
          <div className={"name-text"}>
            <h2>Welcome to UpGuard</h2>
            <p>
              UpGuard helps you identify, assess, remediate and ultimately
              control cybersecurity risks across your organization and your
              vendors.
            </p>
            <p>
              Let’s spend a few minutes walking through a couple of concepts to
              get you started.
            </p>
          </div>
          <form onSubmit={onSubmit}>
            <label>Please enter your name and job title.</label>
            <TextField
              name="firstName"
              required
              value={props.firstName}
              onChanged={props.setFirstName}
              placeholder="First name"
            />
            <TextField
              name="lastName"
              required
              value={props.lastName}
              onChanged={props.setLastName}
              placeholder="Last name"
            />
            <TextField
              name="jobTitle"
              required
              value={props.jobTitle}
              onChanged={props.setJobTitle}
              placeholder="Job title"
            />
            <Button
              arrow
              filledPrimary
              loading={props.nameSaving}
              type={"submit"}
            >
              Next
            </Button>
          </form>
        </div>
        <div
          className={`onboarding-quickstart-video-container set-first-last-name-video-container`}
        >
          <img
            src={VendorSecurityRatingsOverTime}
            id={"rating-time"}
            alt={"Vendor security ratings over time"}
          />
          <img
            src={WebscannerRating}
            id={"webscanner-rating"}
            alt={"Webscanner rating"}
          />
        </div>
      </div>
    );
  }
};

interface IQuickStartStepsProps extends IStepProps {
  name: OnboardingStepName;
  final?: boolean;
  heading: React.ReactNode;
  text: React.ReactNode;
  isLastQuickstartStep?: boolean;
  extraContent?: React.ReactNode;
  helpSections?: {
    title: string;
    href: string;
    description: string;
    openIntercom?: boolean;
  }[];
  videoHref: string; // not used till we have videos
  videoText: string; // not used till we have videos
  videoPlaceholder: React.ReactNode; // used till we have videos, remove later
}

const QuickStartStep = (props: IQuickStartStepsProps) => {
  const skipStep = () => {
    props.dispatch(setOnboardingStepComplete(OnboardingStepName.QuickStart));
    props.advanceStep(true);
  };

  const advanceStep = () => {
    if (props.isLastQuickstartStep) {
      props.dispatch(setOnboardingStepComplete(OnboardingStepName.QuickStart));
    }
    props.advanceStep();
  };

  return (
    <div className={"onboarding-quickstart-container"}>
      <div
        className={`onboarding-step onboarding-step-${props.name.toLowerCase()}`}
      >
        <div className={"top-text"}>
          {props.heading}
          {props.text}
        </div>
        {props.extraContent && (
          <div className={"onboarding-step-extra"}>{props.extraContent}</div>
        )}
        {props.helpSections?.map((h) => (
          <HelpSection key={props.name} name={props.name} {...h} />
        ))}
        <div className={"onboarding-buttons"}>
          {false && ( //Disabled till videos are ready
            <TrackedButton
              eventName={`Onboarding_Steps_${props.name}_Video_Opened`}
              filledSecondary
            >
              <span className={"cr-icon-play"} />
              {props.videoText}
            </TrackedButton>
          )}
          <TrackedButton
            eventName={`Onboarding_Steps_${props.name}_${
              props.isLastQuickstartStep ? "Finished" : "Next"
            }`}
            filledPrimary
            arrow
            onClick={advanceStep}
          >
            {props.final ? "Get Started" : "Next"}
          </TrackedButton>
          {!props.final && (
            <TrackedButton
              eventName={`Onboarding_Steps_${props.name}_Skipped`}
              onClick={skipStep}
              filledSecondary
            >
              Skip all
            </TrackedButton>
          )}
        </div>
      </div>
      <div
        className={`onboarding-quickstart-video-container ${props.name.toLowerCase()}-video-container`}
      >
        {props.videoPlaceholder}
      </div>
    </div>
  );
};

const BreachSightStep = (props: IStepProps) => {
  return (
    <QuickStartStep
      name={OnboardingStepName.BreachSight}
      heading={
        <>
          <h3>
            <span className={"cr-icon-breachsight coloured"} />
            UpGuard BreachSight
          </h3>
          <h2>Monitor your attack surface</h2>
        </>
      }
      text={
        <>
          <p>
            BreachSight examines your organization for cybersecurity risks that
            make data breaches possible. You can discover imposter websites
            stealing your customer data, detect software vulnerabilities, and
            find leaked employee credentials.
          </p>
          <p>
            It’s easy to address all detected risks with remediation workflows,
            and you can quickly track improvements to your overall cybersecurity
            posture using UpGuard’s security ratings.
          </p>
        </>
      }
      videoHref={"TODO"}
      videoText={"Monitoring your organization"}
      videoPlaceholder={
        <img
          src={AcknowledgeRisks}
          id={"acknowledge-risks"}
          alt={"acknowledge risks"}
        />
      }
      {...props}
    />
  );
};

const VendorRiskStep = (props: IStepProps) => {
  return (
    <QuickStartStep
      name={OnboardingStepName.VendorRisk}
      heading={
        <>
          <h3>
            <span className={"cr-icon-vendor-risk coloured"} />
            UpGuard Vendor Risk
          </h3>
          <h2>Monitor your vendors</h2>
        </>
      }
      text={
        <>
          <p>
            Did you know that nearly 60% of data breaches are caused by
            compromised vendors?
          </p>
          <p>
            Protect your company’s data by conducting vendor risk assessments
            and remediation based on our comprehensive internet scanning and
            security questionnaires. Leverage our continuous monitoring to be
            alerted whenever new risks are identified.
          </p>
        </>
      }
      videoHref={"TODO"}
      videoText={"Monitoring your vendors"}
      videoPlaceholder={
        <img src={VendorRatings} id={"vendor-ratings"} alt={"vendor ratings"} />
      }
      {...props}
    />
  );
};

const TrustExchangeStep = (props: IStepProps) => {
  return (
    <QuickStartStep
      name={OnboardingStepName.TrustExchange}
      heading={
        <>
          <h3>
            <span className={"cr-icon-vendor-risk coloured"} />
            UpGuard Trust Exchange
          </h3>
          <h2>Trust Exchange</h2>
        </>
      }
      text={
        <>
          <p>Lorem ipsum la di da</p>
        </>
      }
      videoHref={"TODO"}
      videoText={"Monitoring your vendors"}
      videoPlaceholder={
        "TODO"
        //<img src={VendorRatings} id={"vendor-ratings"} alt={"vendor ratings"} />
      }
      {...props}
    />
  );
};

const AddSurveyToSharedProfileStep = (props: IStepProps) => {
  const [loading, setLoading] = useState(false);

  const stepMeta =
    props.onboardingSteps[OnboardingStepName.AddSurveyToSharedProfile]!;
  const skipStep = () => {
    // Set the step to completed in the background, so we don't bug them again
    trackEvent("OnboardingSteps_AddSurveyToSharedProfile_Skipped");
    props.dispatch(
      setOnboardingStepComplete(OnboardingStepName.AddSurveyToSharedProfile)
    );
    props.advanceStep();
  };

  const addToProfile = () => {
    // Set the step to completed in the background, so we don't bug them again
    trackEvent("OnboardingSteps_AddSurveyToSharedProfile_Confirmed");
    setLoading(true);
    props.dispatch(
      setOnboardingStepComplete(OnboardingStepName.AddSurveyToSharedProfile)
    );

    props
      .dispatch(updateVendorSurveyAddToSharedProfile(stepMeta.surveyId, true))
      .catch((e) => {
        console.error(e);
        props.dispatch(
          addDefaultUnknownErrorAlert("Error updating profile settings")
        );
      })
      .then(() => props.advanceStep());
  };

  return (
    <div className="onboarding-step add-survey-to-shared-profile-step">
      <div className="onboarding-icon">
        <img src={OnboardingDocumentIcon} alt="document" />
      </div>
      <h1>
        {stepMeta.senderCompanyName} has asked you to complete a security
        questionnaire. Once it’s completed, we can add it to your Trust Page
        which makes it faster for other companies to assess you.
      </h1>
      <p>
        By creating your Trust Page you ensure assessors have an accurate and
        current view of your security posture. It also means less time and
        resources spent answering questionnaires. Best of all, you have complete
        control over who has access, what information is shared, and a full
        audit log of who has viewed your page.
      </p>
      <h3>UpGuard&apos;s free Trust Page allows you to:</h3>
      <div className="checkmark-list">
        <div>
          <div className="check-container">
            <div className="cr-icon-check" />
          </div>
          <div>Proactively publish security-related information</div>
        </div>
        <div>
          <div className="check-container">
            <div className="cr-icon-check" />
          </div>
          <div>Approve or deny access requests</div>
        </div>
        <div>
          <div className="check-container">
            <div className="cr-icon-check" />
          </div>
          <div>Save time and reduce operational overhead</div>
        </div>
      </div>
      <div className="btn-group">
        <Button tertiary onClick={skipStep}>
          Skip
        </Button>
        <Button loading={loading} filledPrimary onClick={addToProfile}>
          Great! Add it to my profile
        </Button>
      </div>
    </div>
  );
};

const InviteUsersToOrgStep = (props: IStepProps) => {
  const stepMeta = props.onboardingSteps[OnboardingStepName.InviteUsersToOrg];
  const [inviteEmails, setInviteEmails] = useState<string[]>([""]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<{ value: number; label: string }[]>(
    []
  );
  const [namedRoleId, setNamedRoleId] = useState(-1);
  const selectedValue = options.find((option) => option.value === namedRoleId);

  useEffect(() => {
    props
      .dispatch(getNamedRoles())
      .catch((e) => {
        console.error(e);
        props.dispatch(
          addDefaultUnknownErrorAlert("Error getting Account Types")
        );
      })
      .then((namedRoles: NamedRole[]) => {
        const opts: { value: number; label: string }[] = [];
        namedRoles.forEach((namedRole) =>
          opts.push({
            value: namedRole.id,
            label: namedRole.name,
          })
        );
        opts.sort((a, b) =>
          a.label < b.label ? -1 : a.label > b.label ? 1 : 0
        );
        setOptions(opts);
        setNamedRoleId(opts[0]?.value);
      });
  }, []);

  let anyEmailsFilledOut = false;
  let anyInvalidEmails = false;
  for (const email of inviteEmails) {
    if (email.trim() === "") {
      continue;
    }

    anyEmailsFilledOut = true;

    if (!validateEmail(email.trim())) {
      anyInvalidEmails = true;
    }
  }

  const isSubmittable = !anyInvalidEmails;

  const advanceStep = () => {
    // Set the step to completed in the background, so we don't bug them again
    props.dispatch(
      setOnboardingStepComplete(OnboardingStepName.InviteUsersToOrg)
    );
    props.advanceStep();
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = (ev) => {
    ev.preventDefault();

    if (!isSubmittable || loading) {
      return;
    }

    if (!anyEmailsFilledOut) {
      trackEvent("OnboardingSteps_InviteUsersToOrg_Skipped");
      advanceStep();
      return;
    }

    trackEvent("OnboardingSteps_InviteUsersToOrg_Invited");

    // Create an invite for each of the filled out emails.
    setLoading(true);

    const emails: string[] = [];
    for (const email of inviteEmails) {
      const emailTrimmed = email.trim();
      if (emailTrimmed !== "" && !emails.includes(emailTrimmed)) {
        emails.push(email.toLowerCase());

        trackEvent("onboarding invited users", {
          email: email,
          namedRoleId: namedRoleId,
        });
      }
    }

    props
      .dispatch(addOrgUsers(emails, [], [], namedRoleId))
      .catch((e) => {
        console.error(e);
        props.dispatch(addDefaultUnknownErrorAlert("Error inviting users"));
      })
      .then(() => {
        advanceStep();
      });
  };

  return (
    <div className={"onboarding-quickstart-container"}>
      <div className="onboarding-step invite-users-to-org-step">
        <div className="top-text">
          <h2>Invite your team</h2>
          <p>
            UpGuard works best when you work together with your team. Enter the
            emails of your teammates below to invite them to join your account.
            You can also skip this step and invite your colleagues later in
            Settings.
          </p>
        </div>
        <form onSubmit={onSubmit}>
          <div className="input-row">
            <label className="input-label">Account Type</label>
            <SelectV2
              value={selectedValue}
              onChange={(selectedOption) => {
                setNamedRoleId(selectedOption?.value as number);
              }}
              options={options}
              isLoading={!options}
              controlShouldRenderValue={true}
            />
          </div>
          {inviteEmails.map((email, i) => (
            <div key={i} className="input-row">
              <label className="input-label">
                {i === 0 && "Email Address"}
              </label>
              <TextField
                name={`email-${i}`}
                type="email"
                value={email}
                placeholder={`example@${stepMeta?.orgPrimaryHostname}`}
                onChanged={(newVal) =>
                  setInviteEmails((inviteEmails) => {
                    const newInviteEmails = [...inviteEmails];
                    newInviteEmails[i] = newVal;
                    return newInviteEmails;
                  })
                }
              />
            </div>
          ))}
          {inviteEmails.length < 8 && (
            <div
              className="action-button"
              onClick={() =>
                setInviteEmails((inviteEmails) => [...inviteEmails, ""])
              }
            >
              + Add more
            </div>
          )}

          <div className={"onboarding-buttons"}>
            {false && ( //Disabled till videos are ready
              <TrackedButton
                eventName={"OnboardingSteps_InviteUsersToOrg_Video_Opened"}
                filledSecondary
              >
                <span className={"cr-icon-play"} />
                Collaboration in UpGuard
              </TrackedButton>
            )}
            <Button
              filledPrimary
              type="submit"
              disabled={!isSubmittable}
              loading={loading}
              arrow
            >
              Finish
            </Button>
          </div>
        </form>
      </div>
      <div className={`invite-users-to-org-video-container`}>
        <img src={Avatars} id={"avatars"} alt={"avatars"} />
      </div>
    </div>
  );
};

export interface IOnboardingStepsProps {
  dispatch: DefaultThunkDispatch;
  history: History;
  dismissOnboardingSteps: () => void;
  onboardingSteps: OnboardingStepsMeta;
}

// Possible steps are defined in order here. They will only be shown if the correct metadata
// exists in the onboardingSteps prop.
export const steps: {
  name: OnboardingStepName;
  title: string;
  description: string;
  component: FC<any>;
  quickStartStep?: boolean;
  isSkippable?: boolean; // used when "Skip all" is pressed
}[] = [
  {
    name: OnboardingStepName.SetFirstLastName,
    title: "Welcome to UpGuard",
    description:
      "Understand and manage your own and your vendors’ risk profiles.",
    component: SetFirstLastNameOnboardingStep,
  },
  {
    name: OnboardingStepName.BreachSight,
    title: "UpGuard BreachSight",
    description:
      "Digital risk protection services designed to prevent identity breaches and improve your security posture",
    component: BreachSightStep,
    quickStartStep: true,
    isSkippable: true,
  },
  {
    name: OnboardingStepName.VendorRisk,
    title: "UpGuard Vendor Risk",
    description:
      "A complete cyber vendor risk management platform designed to reduce third and fourth-party risk.",
    component: VendorRiskStep,
    quickStartStep: true,
    isSkippable: true,
  },
  {
    name: OnboardingStepName.TrustExchange,
    title: "UpGuard Trust Exchange",
    description:
      // TODO copy for Trust Exchange description goes here
      "",
    component: TrustExchangeStep,
    quickStartStep: true,
    isSkippable: true,
  },
  {
    name: OnboardingStepName.AddSurveyToSharedProfile,
    title: "Create your Trust Page",
    description:
      "Save time spent answering questionnaires and proactively share your security posture.",
    component: AddSurveyToSharedProfileStep,
  },
  {
    name: OnboardingStepName.InviteUsersToOrg,
    title: "Invite your team",
    description: "Invite your teammates to get the most out of UpGuard",
    component: InviteUsersToOrgStep,
    isSkippable: true,
  },
];

const OnboardingSteps = (props: IOnboardingStepsProps) => {
  // It's assumed this component has only been mounted if there is at least one relevant step.
  const filteredSteps = steps.filter((step) => {
    return !!props.onboardingSteps[step.name];
  });

  const quickStartSteps = filteredSteps.filter((step) => {
    return step.quickStartStep;
  });

  const [currentStepIdx, setCurrentStepIdx] = useState(0);
  const advanceStep = (skipSkippableSteps?: boolean) => {
    for (let i = currentStepIdx + 1; i < filteredSteps.length; i++) {
      if (
        !!props.onboardingSteps[filteredSteps[i].name] &&
        (!skipSkippableSteps || !filteredSteps[i].isSkippable)
      ) {
        // There is a subsequent step, we can progress to that.
        setCurrentStepIdx(i);
        return;
      }
    }

    // We have not found a subsequent step, so we're done. Dismiss OnboardingSteps instead.
    props.dismissOnboardingSteps();

    // We want to trigger the onboarding checklist that is delivered via Intercom
    // using a URL parameter
    props.history.replace({
      pathname: props.history.location.pathname,
      search:
        props.history.location.search.length === 0
          ? "?onboarded=true"
          : props.history.location.search + "&onboarded=true",
      hash: props.history.location.hash,
    });
  };

  const isVendor =
    props.onboardingSteps[OnboardingStepName.SetFirstLastName]?.isVendor ||
    false;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [nameHasBeenSet, setNameHasBeenSet] = useState(false);
  const [nameSaving, setNameSaving] = useState(false);

  const submitName = async () => {
    setNameSaving(true);
    return props
      .dispatch(
        updateUserProfile({
          first_name: firstName,
          last_name: lastName,
          job_title: jobTitle,
        })
      )
      .then(() => setNameSaving(false))
      .catch((e) => {
        console.log(e);
        props.dispatch(addDefaultUnknownErrorAlert("Error updating your name"));
        setNameSaving(false);
      });
  };

  let extraProps = {};
  if (
    filteredSteps[currentStepIdx].name === OnboardingStepName.SetFirstLastName
  ) {
    extraProps = {
      firstName,
      setFirstName,
      lastName,
      setLastName,
      jobTitle,
      setJobTitle,
      afterSetName: () => setNameHasBeenSet(true),
      submitName,
      nameSaving,
    };
  }
  const setStepWithName = async (name: string) => {
    if (
      filteredSteps[currentStepIdx].name === OnboardingStepName.SetFirstLastName
    ) {
      await submitName();
    }
    for (let i = 0; i < filteredSteps.length; i++) {
      if (filteredSteps[i].name === name) {
        setCurrentStepIdx(i);
        return;
      }
    }
  };

  const StepComponent = filteredSteps[currentStepIdx].component;

  return (
    <div className={"onboarding-steps-container"}>
      {!isVendor && (
        <OnboardingNav
          currentStepIdx={currentStepIdx}
          setCurrentStep={setStepWithName}
          steps={filteredSteps.map((s) => s.name)}
          canNavigate={
            (filteredSteps[currentStepIdx].name !==
              OnboardingStepName.SetFirstLastName ||
              nameHasBeenSet) &&
            filteredSteps[currentStepIdx].name !==
              OnboardingStepName.InviteUsersToOrg
          }
        />
      )}
      <div className="onboarding-steps">
        <StepComponent
          dispatch={props.dispatch}
          advanceStep={advanceStep}
          onboardingSteps={props.onboardingSteps}
          isLastQuickstartStep={currentStepIdx === quickStartSteps.length - 1}
          final={currentStepIdx === filteredSteps.length - 1}
          {...extraProps}
        />
      </div>
    </div>
  );
};

export default OnboardingSteps;
