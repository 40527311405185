import { IUserMini } from "./user";
import { RiskCategory, RiskSource, RiskStatus } from "./risks";
import { ICorrespondenceMessage } from "./correspondenceMessage";
import { ITimelineEvent } from "./apiTimeline";
import { SeverityInt } from "./severity";

export enum RemediationRequestStatus {
  Open = "Open",
  AwaitingReview = "AwaitingReview",
  Closed = "Closed",
  Draft = "Draft",
  DeletedDraft = "DeletedDraft",
}

export interface RemediationRequestDetails {
  id: number;
  title?: string;
  organisationId: number;
  vendorId?: number;
  status: RemediationRequestStatus;
  archived: boolean;
  createdBy: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  isSubsidiary: boolean;
  dueDate?: string;
  reminderDate?: string;
  draftInviteEmails?: string[];
  message?: string;
  risksRemoved: boolean;
  vendorAssessmentId?: number;
  additionalEvidenceIds?: number[];
}

export interface RemediationRequestWithSender
  extends RemediationRequestDetails {
  SenderName: string;
  SenderEmail: string;
  SenderAvatar: string;
  VendorName: string;
  VendorDomain: string;
}

export interface RemediationRequestRiskSurvey {
  surveyId?: number;
  publicSurveyId?: number;
  surveyName: string;
  remediated: boolean;
  explanation: string;
  author?: string;
  avatar?: string;
  sharedAt?: string;
  surveyLastSent?: string;
  surveyLastSentBy?: string;
  surveyRiskStatus: RiskStatus;
}

export interface RemediationRequestSharedEvidence {
  id: number;
  name: string;
  createdAt: string;
  filename?: string;
  documentId?: number;
  virusScanned?: boolean;
  virusSafe?: boolean;
}

export interface RemediationRequestRiskAdditionalEvidence {
  evidenceID: number;
  evidenceName: string;
  evidenceRiskStatus: RiskStatus;
  documentID: number;
  filename: string;
  virusScanned: boolean;
  virusSafe: boolean;
}

export interface RemediationRequestRisk {
  source: RiskSource;

  group: string;
  groupTitle: string;
  factCategory: RiskCategory;
  description: string;
  passTitle: string;
  passDescription: string;
  failTitle: string;
  failDescription: string;
  remediation: string;
  defaultSeverity: SeverityInt;

  id: number;
  orgId: number;
  vendorId: number;
  riskId: number;
  riskStatus: RiskStatus;
  checkId: string;
  createdBy: number;
  createdAt: string;
  updatedAt: string;

  isAllWebsites: boolean;
  allWebsitesFailedCountSnapshot: number;
  allWebsitesFailedCountCurrent: number;

  websites: string[];
  resolvedWebsites: string[];
  waivedWebsites: string[];
  movedWebsites: string[];
  metadata?: { cveNames: string[] };

  surveys: RemediationRequestRiskSurvey[];

  additionalEvidences: RemediationRequestRiskAdditionalEvidence[];

  baseSeverity?: SeverityInt;
  baseCheckId?: string;
}

export interface RemediationRequestWithMeta
  extends RemediationRequestWithSender {
  risks: RemediationRequestRisk[];
  orgName: string;
  vendorName?: string;
  vendorDomain: string;
  sender: IUserMini;
  messages: number;
  unreadMessages: number;
  isUserPartOfVerifiedVendorOrgForVendor: boolean;
  canWrite: boolean;
  domainsFilteredOut?: boolean; // If true, domains have been filtered out because this user has portfolio-specific perms
  sharedEvidence?: RemediationRequestSharedEvidence[];
}

export interface RemediationRequestTimeline {
  items: ITimelineEvent[];
  users: { [userId: number]: IUserMini };
}

export interface RemediationRequestMessages {
  messages: ICorrespondenceMessage[];
  users: { [userId: number]: IUserMini };
}

export interface RemediationRequestUsers {
  status: string;
  sharedUsers: IUserMini[];
  createdByUser: IUserMini;
  invitedEmails: string[];
}

export interface RemediationRequestProjection {
  initialScore: number;
  currentScore: number;
  currentScoreAt: string;
  projectedScore: number;
  projectedSurveyScore: number;
  projectedScoreAt: string;
  numAffectedAssets: number;
  loading: boolean;
}

export interface RiskHistoryMetadata {
  evidences?: { id: number; name: string }[];
}

export interface RemediationRequestRiskHistoryItem {
  at: string;
  actionText: string;
  userId?: number;
  comment: string;
  statusUpdate?: {
    prevStatus: string;
    newStatus: string;
  };
  metadata: RiskHistoryMetadata;
}

export interface RemediationRequestRiskHistory {
  items: RemediationRequestRiskHistoryItem[];
  users: { [userId: number]: IUserMini };
}

export interface RemediationRequest {
  details: RemediationRequestWithMeta;
  timeline?: RemediationRequestTimeline;
  messages?: RemediationRequestMessages;
  users?: RemediationRequestUsers;
  projection?: RemediationRequestProjection;
  riskHistory?: { [key: number]: RemediationRequestRiskHistory };
}

export interface RemediationRequestCounts {
  inProgress: number;
  awaitingReview: number;
  completed: number;
  archived: number;
  draft?: number;
}

export interface FilteredRemediationRequest {
  details: RemediationRequestWithMeta;
  openRiskNames: string[];
  closedRiskNames: string[];
  archived: boolean;
  type: string;
}

export interface CloudscanInRemediation {
  remediationRequestIds: number[];
  checkId: string;
  websites: { [key: string]: number[] };
  isAllWebsites: boolean;
  allWebsitesRequestId?: number;
}

export interface AdditionalEvidenceInRemediation {
  remediationRequestIds: number[];
  checkId: string;
}

export interface RemediationProjectionResponse {
  initialScore: number;
  currentScore: number;
  currentScoreAt: string;
  projectedScore: number;
  projectedSurveyScore: number;
  projectedScoreAt: string;
  numAffectedAssets: number;
}
