import PillLabel from "../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../_common/types/label";

import { App, AppStatus } from "../api/types";
import { FC } from "react";

import "./AppStatusDisplay.scss";
import classnames from "classnames";
import { labelColorForAppStatus } from "../helpers/apps";

export const statusDisplayText = (app: App): string => {
  switch (app.status) {
    case AppStatus.NotApprovedAppStatus:
      return "Not approved";
    case AppStatus.NeedsReviewAppStatus:
      return "Need initial review";
    case AppStatus.InReviewAppStatus:
      return "In review";
    case AppStatus.ApprovedAppStatus:
      if (app.approvedForAll) {
        return "Approved for all";
      } else if (app.approvedForTeams.length) {
        return "Approved for specific teams";
      } else if (app.approvedForRoles.length) {
        return "Approved for specific roles";
      }

      return "-";
  }
};

export const statusIcon = (app: App): string => {
  switch (app.status) {
    case AppStatus.NotApprovedAppStatus:
      return "cr-icon-cancel";
    case AppStatus.NeedsReviewAppStatus:
      return "cr-icon-risk";
    case AppStatus.InReviewAppStatus:
      return "cr-icon-unclassified";
    case AppStatus.ApprovedAppStatus:
      return "cr-icon-accepted";
  }
};

const statusColor = (app: App): LabelColor => {
  return labelColorForAppStatus(app.status);
};

export interface AppStatusProps {
  app: App;
  large?: boolean;
}

const AppStatusDisplay: FC<AppStatusProps> = ({ app, large = false }) => {
  const icon = statusIcon(app);
  const text = statusDisplayText(app);
  const color = statusColor(app);

  return (
    <PillLabel color={color} large={large}>
      <div className={"app-status"}>
        <i className={classnames("app-status-icon", icon)} />
        <div>{text}</div>
      </div>
    </PillLabel>
  );
};

export default AppStatusDisplay;
