import { RiskDetail } from "../../../_common/types/vendor";
import { DefaultThunkDispatch } from "../../../_common/types/redux";
import {
  AdditionalEvidenceInRemediation,
  CloudscanInRemediation,
  RemediationRequestRisk,
} from "../../../_common/types/remediation";
import {
  fetchCustomerOrVendorRiskWebsites,
  IAdditionalEvidenceCheckToAdd,
  ICloudscanCheckAndWebsite,
  ISurveyCheckToAdd,
} from "../../reducers/remediation.actions";
import { CloudscanCheckAndWebsiteAction } from "./cloudscan.reducer";
import { SurveyCheckAction } from "./survey.reducer";
import { VendorAssessmentEvidence } from "../../types/vendorAssessments";
import { AdditionalEvidenceCheckAction } from "./additional_evidence.reducer";
import { WebscanResult } from "../../types/webscans";
import { IVendorRiskWaiver } from "../../../_common/types/vendorRiskWaivers";
import { OrganisationAcceptedRisk } from "../../reducers/customerAcceptedRisks.actions";
import { OptionType, SelectV2 } from "../../../_common/components/SelectV2";
import {
  calculateWaivedAssetsForRisks,
  WaivedAssets,
} from "../../reducers/risks.actions";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../../../_common/components/core/XTable";
import { RiskDomainTable } from "./RiskDomainTable";
import CVEMiniList, {
  GetCPEFromRiskID,
  IsCPERisk,
  GetCVEFromRiskID,
  IsCVERisk,
  IsVerifiedCVE,
} from "../../components/CVEMiniList";
import { RiskSurveyTable } from "./SurveyRiskTable";
import { AdjustedSeverityIcon } from "../../../_common/components/SeverityIcon";
import { SeverityAsString } from "../../../_common/types/severity";
import { RiskSource, IRiskHostnames } from "../../../_common/types/risks";
import ReportCard from "../../../_common/components/ReportCard";
import SearchBox from "../../../_common/components/SearchBox";
import SearchEmptyCard from "../../../_common/components/SearchEmptyCard";
import { useAllRiskWebsites } from "../../reducers/remediationRequest.selector";
import { getVendorRiskWaiversFromState } from "../../reducers/vendorRiskWaiver.actions";
import { fetchCloudscanByHostname } from "../../reducers/cyberRiskActions";
import {
  Dispatch,
  FC,
  ReactNode,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  additionalEvidencePrefix,
  cloudscanPrefix,
  surveyPrefix,
} from "../RequestRemediationV2";
import { RiskProfileRiskTypes } from "../../../_common/constants";
import DomainsAndIPsPanelGroup, {
  OpenSidePanel,
} from "../../components/DomainsAndIPsPanelGroup";
import RiskPanel, {
  RiskPanelRowData,
  CalculatedAssetsAffectedHasOccurrences,
  CalculateRiskNumberOfOccurrences,
} from "./../../components/RiskPanel";
import VulnPanel, {
  VulnDataToVulnPanelTitle,
} from "../../components/VulnPanel";
import { locationState } from "../../../_common/types/router";
import { History, Location } from "history";
import { GetQueryParams } from "../../../_common/helpers";
import Button from "../../../_common/components/core/Button";
import RiskVendorQuestionnaires from "../VendorPortfolioRiskProfile/RiskVendorQuestionnaires";
import RiskVendorAdditionalEvidences from "../VendorPortfolioRiskProfile/RiskVendorAdditionalEvidences";
import { IRiskSurvey } from "../../components/RiskSummaryDetails";
import Icon from "../../../_common/components/core/Icon";

const emptyArr: never[] = [];
const emptyObj = {};

import { getOptions } from "../../components/risk_waivers/SelectRiskStep";
import { appConnect } from "../../../_common/types/reduxHooks";
import OrganisationFlagsAPI from "../../../_common/api/organisationFlagsAPI";

import { TourHighlightFromPLGContent } from "../../../_common/components/TourHighlight";
import { VendorSummaryRisk } from "../../../_common/types/vendorSummary";
import RiskVendorDomains from "./../../components/RiskVendorDomains";
import classNames from "classnames";
import LoadingIcon from "../../../_common/components/core/LoadingIcon";

const GetRiskTypeAndIDFromID = (id: string) => {
  if (id.startsWith(cloudscanPrefix)) {
    return [RiskSource.Cloudscan, id.slice(5)];
  }
  if (id.startsWith(surveyPrefix)) {
    return [RiskSource.Survey, id.slice(5)];
  }
  if (id.startsWith(additionalEvidencePrefix)) {
    return [RiskSource.AdditionalEvidence, id.slice(5)];
  }
  return ["error", "0"];
};

interface ISelectRiskStepOwnProps {
  loading: boolean;
  risks?: RiskDetail[];
  dispatch: DefaultThunkDispatch;
  isSelfRemediation: boolean;
  isSubsidiary: boolean;
  vendorID?: number;
  cloudscansInRemediation: { [key: string]: CloudscanInRemediation };
  additionalEvidenceInRemediation: {
    [key: string]: AdditionalEvidenceInRemediation;
  };
  selectedCloudscanChecks: Record<string, ICloudscanCheckAndWebsite>;
  cloudscanDispatch: Dispatch<CloudscanCheckAndWebsiteAction>;
  surveyChecks: Record<string, ISurveyCheckToAdd>;
  surveyDispatch: Dispatch<SurveyCheckAction>;
  filterForWebsite: string;
  selectedRisks?: RemediationRequestRisk[];
  filterForSurvey?: boolean;
  forVendorAssessment: boolean;
  vendorAssessmentEvidence?: VendorAssessmentEvidence;
  vendorAssessmentIncludeWebscan?: boolean;
  requestId?: number;
  writableDomainPortfolioIds?: number[];
  selectedAdditionalEvidenceChecks: Record<
    string,
    IAdditionalEvidenceCheckToAdd
  >;
  additionalEvidenceDispatch: Dispatch<AdditionalEvidenceCheckAction>;
  orgAccessNewRiskDesigns: boolean;
  history: History;
  location: Location<locationState>;
  urlPrefix: string;
}

interface ISelectRiskConnectedProps {
  loading: boolean;
  filterForWebsiteCloudscan?: WebscanResult;
  vendorRiskWaivers?: IVendorRiskWaiver[];
  customerRiskWaivers?: OrganisationAcceptedRisk[];
  maxSelectableWebsites?: number;
  customerRiskHostnames: IRiskHostnames;
}

interface ISelectRiskDispatchProps {
  fetchRiskHostnames: (riskID: string) => Promise<void>;
  fetchCloudscanByHostname: () => Promise<void>;
}

type ISelectRiskStepProps = ISelectRiskStepOwnProps &
  ISelectRiskConnectedProps &
  ISelectRiskDispatchProps;

const SelectRiskStep: FC<ISelectRiskStepProps> = ({
  loading,
  isSelfRemediation,
  cloudscansInRemediation,
  additionalEvidenceInRemediation,
  cloudscanDispatch,
  surveyChecks,
  surveyDispatch,
  risks,
  selectedCloudscanChecks,
  isSubsidiary,
  vendorID,
  fetchRiskHostnames,
  filterForWebsite,
  filterForWebsiteCloudscan,
  fetchCloudscanByHostname,
  selectedRisks,
  filterForSurvey,
  forVendorAssessment,
  vendorAssessmentEvidence,
  vendorAssessmentIncludeWebscan,
  requestId,
  vendorRiskWaivers,
  customerRiskWaivers,
  selectedAdditionalEvidenceChecks,
  additionalEvidenceDispatch,
  orgAccessNewRiskDesigns,
  history,
  location,
  customerRiskHostnames,
  urlPrefix,
}) => {
  const {
    data: { VendorRiskRemediateWebsitesLimit } = {},
    isFetching: isFetchingOrgFlags,
  } = OrganisationFlagsAPI.useGetOrganisationFlagsV1Query();

  const queryParams = GetQueryParams(location.search);

  let openedRisk: VendorSummaryRisk | undefined;
  if (orgAccessNewRiskDesigns) {
    openedRisk = risks?.find((r) => r.id === queryParams.risk);
  }

  const isVendorRiskRemediation = !isSelfRemediation && !isSubsidiary;
  const maxVendorRiskSelectableWebsites = VendorRiskRemediateWebsitesLimit;

  const [
    selectedRiskPotentialVulnerabilitiesCount,
    setSelectedRiskPotentialVulnerabilitiesCount,
  ] = useState(0);

  const [potentialVulnsLoadedForRisks, setPotentialVulnsLoadedForRisks] =
    useState<Set<string>>(new Set<string>());
  const [searchText, setSearchText] = useState("");
  // if we have preselected a survey risk use the questionnaire_risks option type here
  const [selectedOption, setSelectedOption] = useState<OptionType | undefined>(
    filterForSurvey
      ? { value: "questionnaire_risks", label: "Questionnaire Risks" }
      : undefined
  );
  const [options, setOptions] = useState<OptionType[]>([]);
  const [filteredRisks, setFilteredRisks] = useState([] as RiskDetail[]);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [fetchedHosthames, setFetchedHostnames] = useState<
    Record<string, boolean>
  >({});

  const [selectedRiskMap, setSelectedRiskMap] = useState<
    Record<string, RemediationRequestRisk> | undefined
  >(undefined);

  useEffect(() => {
    if (selectedRisks) {
      setSelectedRiskMap(
        selectedRisks.reduce(
          (map, risk) => {
            map[risk.checkId] = risk;
            return map;
          },
          {} as Record<string, RemediationRequestRisk>
        )
      );
    }
  }, [selectedRisks]);

  useEffect(() => {
    if (filterForWebsite !== "") {
      fetchCloudscanByHostname();
    }
  }, [filterForWebsite]);

  const [filterForWebsiteRisks, setFilterForWebsiteRisks] = useState<
    RiskDetail[] | undefined
  >(undefined);
  useEffect(() => {
    if (
      filterForWebsiteCloudscan &&
      filterForWebsiteCloudscan.result &&
      risks
    ) {
      const filteredRisks = risks?.filter((risk) =>
        filterForWebsiteCloudscan.result.checkResults.some(
          (check) => check.id === risk.id && !check.pass
        )
      );
      setFilterForWebsiteRisks(filteredRisks);
    }
  }, [filterForWebsiteCloudscan, risks]);

  useEffect(() => {
    let risksToUse = risks;
    // if this is for a vendor assessment wait for the evidence to load
    if (forVendorAssessment && !vendorAssessmentEvidence) {
      return;
    }

    // if we're waiting for a filtered cloudscan return early
    if (filterForWebsite !== "") {
      if (!filterForWebsiteRisks) {
        return;
      }
      risksToUse = filterForWebsiteRisks;
    }

    // filter by the evidence for the risk assessment first (if needed)
    if (forVendorAssessment && vendorAssessmentEvidence) {
      const publicSurveyIds = vendorAssessmentEvidence.publicSurveys
        .filter((p) => p.selected)
        .map((p) => p.id);
      const surveyIds = vendorAssessmentEvidence.surveys
        .filter((s) => s.selected)
        .map((s) => s.id);
      const additionalEvidenceNames = vendorAssessmentEvidence.additional.map(
        (a) => a.name
      );

      risksToUse = risksToUse?.filter((r) => {
        switch (r.riskType) {
          case "cloudscan":
            return vendorAssessmentIncludeWebscan;
          case "survey":
            return (
              r.surveys?.some(
                (s) =>
                  (s.publicSurvey && publicSurveyIds.includes(s.surveyId)) ||
                  (!s.publicSurvey && surveyIds.includes(s.surveyId))
              ) ?? false
            );
          case "evidence":
            return (
              r.additionalEvidences?.some((ae) =>
                additionalEvidenceNames.includes(ae.name)
              ) ?? false
            );
          default:
            return false;
        }
      });
    }

    if (risksToUse) {
      risksToUse = risksToUse?.filter((r) => {
        if (r.passed || r.isWaived) {
          return false;
        }

        // if we are filtering for a website make sure that the risk is in it's cloudscan
        if (filterForWebsite !== "") {
          if (
            !filterForWebsiteCloudscan?.result?.checkResults.some(
              (check) => check.id === r.id && !check.pass
            )
          ) {
            return false;
          }
        }

        if (
          searchText &&
          r.title
            .toLocaleLowerCase()
            .indexOf(searchText.toLocaleLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });

      setOptions(getOptions(risksToUse));

      risksToUse = risksToUse?.filter((r) => {
        if (
          selectedOption &&
          selectedOption.value !== "all" &&
          r.factCategory !== selectedOption.value
        ) {
          return false;
        }

        return true;
      });

      setFilteredRisks(risksToUse);
    }
  }, [
    searchText,
    risks,
    selectedOption,
    filterForWebsite,
    filterForWebsiteRisks,
    filterForWebsiteCloudscan,
    forVendorAssessment,
    vendorAssessmentEvidence,
    vendorAssessmentIncludeWebscan,
  ]);

  const waivedRiskAssets = useMemo(
    () =>
      calculateWaivedAssetsForRisks(
        risks ?? [],
        isSelfRemediation,
        vendorRiskWaivers,
        undefined,
        customerRiskWaivers
      ),
    [risks, isSelfRemediation, vendorRiskWaivers, customerRiskWaivers]
  );

  const riskHostnames = useAllRiskWebsites(risks, {
    isSelfRemediation,
    isSubsidiary,
    vendorID,
  });

  const headers: IXTableColumnHeader[] = [
    {
      id: "sev",
      text: "Sev.",
      className: "sev-col",
    },
    {
      id: "risk",
      text: "Risk",
      className: "risk-col",
    },
  ];

  if (filterForWebsite === "") {
    headers.push({
      id: "assets",
      text: "Affected assets",
      className: "assets-col",
    });
  }

  // we want to "pre-expand" any pre-selected vuln risks on page load
  const didPreExpand = useRef(false);
  useEffect(() => {
    if (didPreExpand.current || !Object.keys(selectedCloudscanChecks).length) {
      return;
    }
    const risk = Object.keys(selectedCloudscanChecks)[0];
    didPreExpand.current = true;
    if (!IsCPERisk(risk)) {
      return;
    }
    if (!(risk in fetchedHosthames)) {
      setFetchedHostnames({ ...fetchedHosthames, [risk]: true });
      fetchRiskHostnames(risk);
    }
    const riskId = "[cs]-" + risk;
    if (!expandedRows.includes(riskId)) {
      setExpandedRows([...expandedRows, riskId]);
    }
  }, [selectedCloudscanChecks, expandedRows]);

  const onOpenCVEPanelFromRiskPanel = useCallback(
    (vuln: string, verified?: boolean) => {
      return OpenSidePanel(
        history,
        { vuln, verified: verified ?? false, risk: openedRisk?.id },
        "'" + openedRisk?.title + "'",
        location
      );
    },
    [history, openedRisk]
  );

  const onOpenRiskPanel = useCallback(
    (risk: string) => {
      return OpenSidePanel(history, { risk: risk });
    },
    [history]
  );
  const riskPanelTrailingRows: RiskPanelRowData[] = [];
  let openedRiskAssetsAffectedTitle: string;

  const getCustomerRiskHostnames = useCallback(
    (riskId: string) => {
      const riskHostnames = customerRiskHostnames[riskId];
      if (riskHostnames && !riskHostnames.loading && riskHostnames.data) {
        return riskHostnames.data.hostnamesWithRisk || emptyArr;
      }
      return undefined;
    },
    [customerRiskHostnames]
  );

  const onQuestionnaireClicked = useCallback(
    (survey: IRiskSurvey) => {
      if (!vendorID || isSubsidiary) {
        // Weird - these risks should only be shown on vendor pages.
        return;
      }

      if (survey.publicSurvey) {
        window
          .open(
            `${urlPrefix}/sharedassessment/surveys/${survey.surveyId}`,
            "_blank"
          )
          ?.focus();
      } else {
        window
          .open(`${urlPrefix}/surveys/${survey.surveyId}`, "_blank")
          ?.focus();
      }
    },
    [vendorID, isSubsidiary, urlPrefix, history]
  );

  const rows: IXTableRow[] = [];

  rows.push(
    ...filteredRisks.map((r) => {
      let idPrefix = "[cs]-";
      switch (r.riskType) {
        case "survey":
          idPrefix = "[su]-";
          break;
        case "evidence":
          idPrefix = "[ae]-";
          break;
      }
      const id = idPrefix + r.id;
      const expanded = expandedRows.indexOf(id) > -1;
      let expandContent = <></>;
      const cloudscanCheck = selectedCloudscanChecks[r.id] ?? {
        selectAll: false,
        checkId: r.id,
        websites: [],
      };
      const thisRiskHostnames = riskHostnames[r.id];
      const surveyCheck = surveyChecks[r.id] ?? {
        riskID: r.id,
        selectAll: false,
        surveys: [],
      };

      if (expanded) {
        const viewRiskInformationLink = orgAccessNewRiskDesigns ? (
          <div
            onClick={() => {
              onOpenRiskPanel?.(r.id);
            }}
            className="view-risk-information-container"
          >
            <span className="view-risk-information-link">
              <span>View risk information</span>
              <Icon name="chevron" direction={90} />
            </span>
          </div>
        ) : (
          <></>
        );

        if (r.riskType == RiskProfileRiskTypes.Cloudscan) {
          const isCPERisk = IsCPERisk(r.id);

          const domainTableLoading =
            thisRiskHostnames.loading || isFetchingOrgFlags;
          const expandedContentLoading =
            domainTableLoading ||
            (isCPERisk && !potentialVulnsLoadedForRisks.has(r.id));

          const domainTable =
            filterForWebsite === "" ? (
              <RiskDomainTable
                loading={domainTableLoading}
                maxSelectableWebsites={
                  isVendorRiskRemediation
                    ? maxVendorRiskSelectableWebsites
                    : undefined
                }
                numLoadingRows={r.numFailedCloudscans}
                riskHostnames={thisRiskHostnames.data?.hostnamesWithRisk ?? []}
                cloudscanInRemediation={cloudscansInRemediation[r.id]}
                allSelected={cloudscanCheck.selectAll}
                selectedRiskHostnames={cloudscanCheck.websites}
                onSelectRiskHostname={(websites) =>
                  cloudscanDispatch({
                    type: "setCheckWebsites",
                    checkId: r.id,
                    websites,
                  })
                }
                selectedRisk={
                  selectedRiskMap ? selectedRiskMap[r.id] : undefined
                }
                orgAccessNewRiskDesigns={orgAccessNewRiskDesigns}
                hide={isCPERisk && expandedContentLoading}
              />
            ) : (
              <></>
            );

          if (IsCPERisk(r.id)) {
            expandContent = (
              <>
                {viewRiskInformationLink}
                {expandedContentLoading && (
                  <div className="loading-spinner-container">
                    <LoadingIcon />
                  </div>
                )}
                <CVEMiniList
                  cpeName={GetCPEFromRiskID(r.id)}
                  isCustomer={isSelfRemediation}
                  hostnames={thisRiskHostnames.data?.hostnamesWithRisk ?? []}
                  selectable
                  selectedCVENames={cloudscanCheck.metadata?.CVENames}
                  onSelectionChange={(cves) =>
                    cloudscanDispatch({
                      type: "setSelectedCVEs",
                      checkId: r.id,
                      selectedCVEs: cves ?? null,
                    })
                  }
                  onLoaded={() => {
                    setPotentialVulnsLoadedForRisks(
                      (previous) => new Set([...previous, r.id])
                    );
                  }}
                  hide={expandedContentLoading}
                />
                {domainTable}
              </>
            );
          } else {
            expandContent = (
              <>
                {viewRiskInformationLink}
                {domainTable}
              </>
            );
          }
        } else if (r.riskType === RiskProfileRiskTypes.Survey) {
          expandContent = (
            <div>
              {viewRiskInformationLink}
              <RiskSurveyTable
                riskSurveys={r.surveys?.filter((s) => !s.publicSurvey) ?? []}
                selectedSurveys={surveyCheck.surveys ?? []}
                onSelectRiskSurvey={(surveyIDs) => {
                  surveyDispatch({
                    type: "setCheckSurveys",
                    riskId: r.id,
                    surveys: surveyIDs,
                    publicSurveys: undefined,
                  });
                }}
                selectedRisk={
                  selectedRiskMap ? selectedRiskMap[r.id] : undefined
                }
                requestId={requestId}
                orgAccessNewRiskDesigns={orgAccessNewRiskDesigns}
              />
              <RiskSurveyTable
                riskSurveys={r.surveys?.filter((s) => s.publicSurvey) ?? []}
                selectedSurveys={surveyCheck.publicSurveys ?? []}
                onSelectRiskSurvey={(surveyIDs) => {
                  surveyDispatch({
                    type: "setCheckSurveys",
                    riskId: r.id,
                    surveys: undefined,
                    publicSurveys: surveyIDs,
                  });
                }}
                selectedRisk={
                  selectedRiskMap ? selectedRiskMap[r.id] : undefined
                }
                requestId={requestId}
              />
            </div>
          );
        } else if (
          orgAccessNewRiskDesigns &&
          r.riskType == RiskProfileRiskTypes.Evidence
        ) {
          expandContent = viewRiskInformationLink;
        }
      }

      let assetsLine: ReactNode = "";
      let partiallySelected = false;
      let allSelected = false;
      let disableSelectingRisk = false;

      const hasSelectedRisk =
        (selectedRiskMap && !!selectedRiskMap[r.id]) ?? false;

      switch (r.riskType) {
        case "cloudscan":
          let waivedAssets: WaivedAssets = {
            assetsWaived: {},
            assetsPendingWaiver: {},
            assetsPendingSharedWaiver: {},
            assetsAdjusted: {},
            assetsPendingAdjustment: {},
          };
          if (waivedRiskAssets[r.id] && waivedRiskAssets[r.id]) {
            waivedAssets = waivedRiskAssets[r.id];
          }
          const waivedHostnames = Object.keys(waivedAssets.assetsWaived);
          const numAssetsUnwaived =
            r.numFailedCloudscans - waivedHostnames.length;

          const failedHostnames =
            r.failedCloudscans?.map((c) => c.hostname) ?? [];
          const unwaivedHostnames = failedHostnames.filter(
            (h) => !waivedHostnames.includes(h)
          );

          assetsLine =
            `${numAssetsUnwaived} ` +
            ((numAssetsUnwaived ?? 0) > 1 ? `domains & IPs` : `domain or IP`);
          if (cloudscansInRemediation[r.id]) {
            if (cloudscansInRemediation[r.id].isAllWebsites) {
              assetsLine = (
                <>
                  {assetsLine}
                  <br />
                  (all in remediation)
                </>
              );
              disableSelectingRisk = true;
            } else {
              const numAssets = Object.keys(
                cloudscansInRemediation[r.id].websites
              ).length;
              assetsLine = (
                <>
                  {assetsLine}
                  <br />
                  {`(${numAssets} asset${
                    numAssets > 1 ? "s" : ""
                  } in remediation)`}
                </>
              );
              disableSelectingRisk =
                (filterForWebsite !== "" &&
                  !!cloudscansInRemediation[r.id].websites[filterForWebsite]) ||
                Object.keys(cloudscansInRemediation[r.id].websites).length ===
                  numAssetsUnwaived;
            }
          }
          partiallySelected =
            cloudscanCheck.websites.length > 0 &&
            !(
              unwaivedHostnames.length > 0 &&
              unwaivedHostnames.every((w) =>
                cloudscanCheck.websites.includes(w)
              )
            ) &&
            cloudscanCheck.websites.length != r.numFailedCloudscans &&
            !cloudscanCheck.selectAll &&
            filterForWebsite === "";

          allSelected =
            cloudscanCheck.selectAll ||
            (cloudscanCheck.websites.length > 0 &&
              unwaivedHostnames.every((w) =>
                cloudscanCheck.websites.includes(w)
              )) ||
            (filterForWebsite !== "" && cloudscanCheck.websites.length > 0);

          if (IsCPERisk(r.id)) {
            const allCVEsSelected = cloudscanCheck.metadata?.CVENames === null;
            const someCVEsSelected = !!(
              cloudscanCheck.metadata?.CVENames &&
              cloudscanCheck.metadata.CVENames.length > 0
            );
            const allWebsitesSelected = allSelected;
            const someWebsitesSelected = partiallySelected;

            allSelected = allWebsitesSelected && allCVEsSelected;

            partiallySelected =
              (allWebsitesSelected ||
                allCVEsSelected ||
                someWebsitesSelected ||
                someCVEsSelected) &&
              !allSelected;
          }
          break;
        case "survey":
          const numSurveys =
            r.surveys?.filter((s) => !s.publicSurvey && !s.isWaived).length ??
            0;
          const numPublicSurveys =
            r.surveys?.filter((s) => s.publicSurvey && !s.isWaived).length ?? 0;
          const numRemediations =
            r.surveys?.reduce((sum, s) => sum + (s.inRemediation ? 1 : 0), 0) ??
            0;
          assetsLine =
            numSurveys + numPublicSurveys === 1
              ? "1 questionnaire"
              : `${numSurveys + numPublicSurveys} questionnaires`;
          if (numRemediations > 0) {
            assetsLine = (
              <>
                {assetsLine}
                <br />
                {`(${numRemediations} asset${
                  numRemediations > 1 ? "s" : ""
                } in remediation)`}
              </>
            );

            disableSelectingRisk =
              r.surveys?.every((s) => s.inRemediation || s.isWaived) ?? true;
          }

          const numSurveysSelected = surveyCheck.surveys?.length ?? 0;
          const numPublicSurveysSelected =
            surveyCheck.publicSurveys?.length ?? 0;

          allSelected =
            numSurveysSelected === numSurveys &&
            numPublicSurveysSelected === numPublicSurveys;

          partiallySelected =
            !allSelected &&
            (numSurveysSelected > 0 || numPublicSurveysSelected > 0);
          break;
        case "evidence":
          assetsLine = additionalEvidenceInRemediation[r.id]
            ? "In remediation"
            : r.additionalEvidences?.length === 1
              ? "1 document"
              : `${r.additionalEvidences?.length} documents`;
          allSelected = !!selectedAdditionalEvidenceChecks[r.id];

          disableSelectingRisk = !!additionalEvidenceInRemediation[r.id];
          break;
      }

      const cells = [
        <XTableCell key="sev" className="shrink-cell">
          <AdjustedSeverityIcon
            severity={SeverityAsString(r.severity)}
            baseSeverity={
              r.baseSeverity ? SeverityAsString(r.baseSeverity) : undefined
            }
          />
        </XTableCell>,
        <XTableCell
          key={"risk"}
          className={classNames({
            "risk-cell": orgAccessNewRiskDesigns,
          })}
        >
          <div className={"risk-title"}>{r.title}</div>
          <div className={"risk-subtitle"}>{r.categoryTitle}</div>
        </XTableCell>,
      ];

      if (filterForWebsite === "") {
        cells.push(
          <XTableCell key={"assets"} className={"assets-cell"}>
            {assetsLine}
          </XTableCell>
        );
      }

      return {
        cells,
        id,
        expanded,
        expandContent,
        expandDisabled:
          (filterForWebsite !== "" && !IsCPERisk(r.id)) ||
          (!orgAccessNewRiskDesigns && r.riskType === "evidence"),
        selected: allSelected,
        partiallySelected: partiallySelected,
        selectionDisabled: disableSelectingRisk && !hasSelectedRisk,
        selectionDisabledHelpText:
          "All assets are already under remediation or waived",
        onClick:
          !orgAccessNewRiskDesigns && r.riskType === "evidence"
            ? undefined
            : () => {
                if (filterForWebsite === "" || IsCPERisk(r.id)) {
                  setExpandedRows((rows) => {
                    const idx = rows.indexOf(id);
                    if (idx === -1) {
                      return [...rows, id];
                    } else {
                      rows.splice(idx, 1);
                      return [...rows];
                    }
                  });
                  if (!(r.id in fetchedHosthames)) {
                    setFetchedHostnames({ ...fetchedHosthames, [r.id]: true });
                    fetchRiskHostnames(r.id);
                  }
                } else if (!disableSelectingRisk) {
                  cloudscanDispatch({
                    type: "setCheckWebsites",
                    checkId: r.id,
                    websites: allSelected ? [] : [filterForWebsite],
                  });
                }
              },
      };
    })
  );

  const onSelectClick = (rowId: string | number, newSelectedState: boolean) => {
    const [riskType, id] = GetRiskTypeAndIDFromID(rowId as string);
    if (riskType === RiskSource.Cloudscan) {
      if (!newSelectedState) {
        cloudscanDispatch({ type: "removeCheckID", checkId: id });
      } else {
        if (IsCPERisk(id)) {
          cloudscanDispatch({
            type: "setSelectedCVEs",
            checkId: id,
            selectedCVEs: null,
          }); // null means select all
        }
        if (filterForWebsite === "") {
          cloudscanDispatch({
            type: "selectAll",
            checkId: id,
            selectAll: newSelectedState,
          });
        } else {
          // if we are filtered for a website only select/deselect that website
          cloudscanDispatch({
            type: "setCheckWebsites",
            checkId: id,
            websites: newSelectedState ? [filterForWebsite] : [],
          });
        }
      }
    } else if (riskType === RiskSource.Survey) {
      if (!newSelectedState) {
        surveyDispatch({ type: "removeCheckID", riskId: id });
      } else {
        const risk: RiskDetail | undefined = risks?.find((r) => r.id === id);
        if (risk) {
          surveyDispatch({
            type: "setCheckSurveys",
            riskId: id,
            surveys:
              risk.surveys
                ?.filter((s) => !s.inRemediation && !s.publicSurvey)
                .map((s) => s.surveyId) ?? [],
            publicSurveys:
              risk.surveys
                ?.filter((s) => !s.inRemediation && s.publicSurvey)
                .map((s) => s.surveyId) ?? [],
          });
        }
      }
    } else if (riskType === RiskSource.AdditionalEvidence) {
      if (!newSelectedState) {
        additionalEvidenceDispatch({ type: "removeCheckID", riskId: id });
      } else {
        additionalEvidenceDispatch({ type: "addCheckID", riskId: id });
      }
    }
  };

  let newRiskVendorDomains:
    | ((
        onClickDomain: (
          riskId: string,
          vendorId: number | undefined,
          hostname: string
        ) => void
      ) => JSX.Element)
    | undefined;

  let header = "Select risks to remediate";
  if (filterForWebsite) {
    header += ` on ${filterForWebsite}`;
  }

  if (openedRisk) {
    const openedRiskWaivedAssets = waivedRiskAssets[openedRisk.id];

    const openedRiskNumberOfOccurrences = CalculateRiskNumberOfOccurrences(
      openedRisk,
      false,
      openedRiskWaivedAssets
    );

    const isCustomer = !vendorID && !isSubsidiary;

    let openedRiskAllSitesRemediationId: number | undefined = undefined;
    let openedRiskSitesInRemediation: { [key: string]: number[] } = {};
    if (openedRisk.riskType === RiskProfileRiskTypes.Cloudscan) {
      const remediation = cloudscansInRemediation?.[openedRisk.id];

      openedRiskAllSitesRemediationId = remediation?.allWebsitesRequestId;
      openedRiskSitesInRemediation = remediation?.websites ?? {};
    }

    if (IsCPERisk(openedRisk.id)) {
      riskPanelTrailingRows.push({
        key: "potential_vulnerabilities",
        title:
          "Potential vulnerabilities" +
          (selectedRiskPotentialVulnerabilitiesCount
            ? " (" + selectedRiskPotentialVulnerabilitiesCount + ")"
            : ""),
        startExpanded: true,
        children: (
          <>
            <CVEMiniList
              cpeName={GetCPEFromRiskID(openedRisk.id)}
              isCustomer={isCustomer}
              hostnames={getCustomerRiskHostnames(openedRisk.id)}
              onClickCVE={(riskID) =>
                onOpenCVEPanelFromRiskPanel(riskID, false)
              }
              onVulnsCountChanged={(vulnsCount: number | undefined) => {
                setSelectedRiskPotentialVulnerabilitiesCount(vulnsCount ?? 0);
              }}
            />
          </>
        ),
      });
    }

    if (openedRisk.numFailedCloudscans > 0) {
      openedRiskAssetsAffectedTitle =
        "Assets affected" +
        (CalculatedAssetsAffectedHasOccurrences(openedRisk)
          ? " (" + openedRiskNumberOfOccurrences + ")"
          : "");

      const openedRiskCaptured = openedRisk;

      newRiskVendorDomains = (
        onClickDomain: (
          riskId: string,
          vendorId: number | undefined,
          hostname: string
        ) => void
      ) => {
        return (
          <RiskVendorDomains
            userHasWriteRiskWaiversPermission={false}
            vendorId={vendorID}
            riskId={openedRiskCaptured.id}
            riskHostnames={customerRiskHostnames[openedRiskCaptured.id] || null}
            isSubsidiary={isSubsidiary ?? false}
            onClickDomain={onClickDomain}
            allDomainsRemediationID={openedRiskAllSitesRemediationId}
            domainsInRemediation={openedRiskSitesInRemediation}
            passed={openedRiskCaptured.passed}
            isWaived={openedRiskCaptured.isWaived ?? false}
            waivedAssets={openedRiskWaivedAssets}
            showWaived={false}
            noDetectedDates={false}
            orgAccessNewRiskDesigns={orgAccessNewRiskDesigns}
            readonly={true}
          />
        );
      };

      riskPanelTrailingRows.push({
        key: "assets_affected",
        title: openedRiskAssetsAffectedTitle,
        startExpanded: true,
        children: newRiskVendorDomains(
          (riskId: string, _vendorId: number | undefined, hostname: string) =>
            OpenSidePanel(
              history,
              { scan: hostname, risk: riskId },
              "'" + openedRisk?.title + "'",
              location
            )
        ),
      });
    }

    if (openedRisk.surveys && openedRisk.surveys.length > 0) {
      riskPanelTrailingRows.push({
        key: "surveys",
        title:
          "Vendor questionnaires affected" +
          " (" +
          openedRisk.surveys.length +
          ")",
        startExpanded: true,
        children: (
          <RiskVendorQuestionnaires
            orgAccessNewRiskDesigns={orgAccessNewRiskDesigns}
            loading={false}
            questionnaires={openedRisk.surveys}
            risk={openedRisk}
            onQuestionnaireLinkClick={onQuestionnaireClicked}
            userHasWriteRiskWaiversPermission={false}
            waivedAssets={openedRiskWaivedAssets}
            onViewWaivers={() => {}}
            onViewRemediations={(remediationRequestID?: number) => {
              let location = `${urlPrefix}/remediation`;

              if (remediationRequestID) {
                location += `/${remediationRequestID}`;
              }

              history.push(location, {
                backContext: {
                  goBack: true,
                  backToText: "Back to Remediation",
                },
              });
            }}
            showWaived={false}
            vendorId={vendorID}
          />
        ),
      });
    }

    if (
      openedRisk.additionalEvidences &&
      openedRisk.additionalEvidences.length > 0
    ) {
      riskPanelTrailingRows.push({
        key: "additional_evidences",
        title: `Additional evidence with this risk (${openedRisk.additionalEvidences.length})`,
        startExpanded: true,
        children: (
          <RiskVendorAdditionalEvidences
            additionalEvidences={openedRisk.additionalEvidences}
            getUrlPrefix={() => urlPrefix}
          />
        ),
      });
    }
  }

  return (
    <>
      <ReportCard newStyles className={"select-risk-step"}>
        <div className={"header"}>{header}</div>
        <div className={"search-and-select"}>
          <SelectV2
            className={"select"}
            options={options}
            value={selectedOption ?? options[0]}
            onChange={(op) => setSelectedOption(op ?? undefined)}
            isDisabled={loading}
          />
          <SearchBox
            onChanged={(s) => setSearchText(s)}
            value={searchText}
            disabled={loading}
          />
        </div>
        <TourHighlightFromPLGContent
          onboardingTaskId={"Checklist_BreachSight_Remediation"}
          highlightIndex={0}
          visible={true}
          position={"top"}
        >
          <XTable
            rows={rows}
            columnHeaders={headers}
            selectable
            expandableRows
            onSelectClick={onSelectClick}
            loading={
              loading ||
              (filterForWebsiteCloudscan &&
                filterForWebsiteCloudscan.loading) ||
              (forVendorAssessment && !vendorAssessmentEvidence)
            }
          />
        </TourHighlightFromPLGContent>
        {!loading && filteredRisks.length === 0 && (
          <SearchEmptyCard
            searchItemText={"risks"}
            onClear={() => {
              setSearchText("");
              setSelectedOption(options[0]);
            }}
          />
        )}
      </ReportCard>
      {orgAccessNewRiskDesigns ? (
        <DomainsAndIPsPanelGroup
          history={history}
          location={location}
          isSubsidiary={isSubsidiary}
          vendorId={vendorID ? vendorID : undefined}
          isVendorPortal={false}
          riskPanel={
            openedRisk && (
              <RiskPanel
                risk={openedRisk}
                titleButtonsContent={
                  <>
                    {IsCVERisk(openedRisk.id) && (
                      <Button
                        onClick={() => {
                          if (!openedRisk) {
                            return;
                          }

                          onOpenCVEPanelFromRiskPanel(
                            GetCVEFromRiskID(openedRisk.id),
                            IsVerifiedCVE(openedRisk.id)
                          );
                        }}
                        style={{ marginRight: "10px" }}
                      >
                        View CVE details
                      </Button>
                    )}
                  </>
                }
                trailingRows={riskPanelTrailingRows}
              />
            )
          }
          getVulnPanel={
            orgAccessNewRiskDesigns
              ? (
                  vendorID: number | undefined,
                  isSubsidiary: boolean,
                  cveName: string,
                  verified: boolean
                ) => {
                  return (
                    <VulnPanel
                      vendorId={vendorID}
                      isSubsidiary={isSubsidiary}
                      cveName={cveName}
                      verified={verified}
                      orgAccessNewRiskDesigns={orgAccessNewRiskDesigns}
                      assetsAffectedSectionTitle={openedRiskAssetsAffectedTitle}
                      renderAssetsAffectedSectionContent={(vulnData: any) =>
                        newRiskVendorDomains?.(
                          (
                            riskId: string,
                            _vendorId: number | undefined,
                            hostname: string
                          ) =>
                            OpenSidePanel(
                              history,
                              { scan: hostname, risk: riskId },
                              "'" + VulnDataToVulnPanelTitle(vulnData) + "'",
                              location
                            )
                        )
                      }
                    />
                  );
                }
              : undefined
          }
        />
      ) : (
        <></>
      )}
    </>
  );
};

SelectRiskStep.defaultProps = {
  vendorID: 0,
  filterForWebsiteCloudscan: undefined,
  risks: [],
};

export const ConnectedSelectRiskStep = appConnect<
  ISelectRiskConnectedProps,
  ISelectRiskDispatchProps,
  ISelectRiskStepOwnProps
>(
  (state, props) => {
    const filterForWebsiteCloudscanResult =
      props.filterForWebsite !== ""
        ? state.cyberRisk.webscans[props.filterForWebsite]
        : undefined;

    return {
      loading:
        props.loading ||
        (filterForWebsiteCloudscanResult
          ? filterForWebsiteCloudscanResult.loading
          : false),
      filterForWebsiteCloudscan: filterForWebsiteCloudscanResult,
      vendorRiskWaivers: getVendorRiskWaiversFromState(
        state,
        props.vendorID ?? 0
      ),
      customerRiskWaivers:
        state.cyberRisk.customerData?.acceptedRisks?.acceptedRisks,

      customerRiskHostnames:
        !props.vendorID && !props.isSubsidiary
          ? state.cyberRisk.customerData.riskHostnames
          : emptyObj,
    };
  },
  (dispatch: DefaultThunkDispatch, ownProps): ISelectRiskDispatchProps => ({
    fetchRiskHostnames: (riskID: string) =>
      dispatch(
        fetchCustomerOrVendorRiskWebsites(
          riskID,
          ownProps.vendorID,
          ownProps.isSelfRemediation,
          ownProps.isSubsidiary,
          ownProps.writableDomainPortfolioIds
        )
      ),
    fetchCloudscanByHostname: () =>
      dispatch(
        fetchCloudscanByHostname(
          ownProps.filterForWebsite,
          false,
          ownProps.isSelfRemediation
        )
      ),
  })
)(SelectRiskStep);
