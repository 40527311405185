import { FC } from "react";
import ReportCard from "../../_common/components/ReportCard";
import { AppRouteParams } from "../UserBaseNavItems";
import { useRouteMatch } from "react-router-dom";
import UserBaseAPI from "../api/userbase.api";
import LoadingBanner from "../../_common/components/core/LoadingBanner";
import InfoTable, { InfoTableRow } from "../../_common/components/InfoTable";
import "./AboutApp.scss";
import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import PillLabel from "../../vendorrisk/components/PillLabel";

const AboutApp: FC = ({}) => {
  const match = useRouteMatch<AppRouteParams>();

  const appName = match.params.appName;

  const { data, isLoading } = UserBaseAPI.useGetUserBaseAppSummaryV1Query({
    app: appName,
  });

  return (
    <ReportCard newStyles className="userbase-about-app">
      <div className="header">
        <span>About {appName}</span>
      </div>
      {isLoading && <LoadingBanner />}
      {!isLoading && data && (
        <div className={"content"}>
          <InfoTable>
            <InfoTableRow
              label="Primary domain"
              rowClass={"no-max-height"}
              value={data.app.domain ?? ""}
            />
            <InfoTableRow
              label="Industry"
              rowClass={"no-max-height bottom-border"}
              value={data.app.industry ?? ""}
            />
            <InfoTableRow
              label="Vendor details"
              rowClass={"no-max-height section-header"}
              value={""}
            />
            <InfoTableRow
              label="Name"
              rowClass={"no-max-height"}
              value={data.app.name ?? ""}
            />
            <InfoTableRow
              label="Domain"
              rowClass={"no-max-height"}
              value={data.app.domain ?? ""}
            />
            <InfoTableRow
              label="Security rating"
              rowClass={"no-max-height"}
              value={
                <>
                  <ColorGrade
                    score={data.app.securityScore}
                    size={ColorGradeSize.Small}
                  />
                  <Score score={data.app.securityScore} outOf={950} small />
                </>
              }
            />
            <InfoTableRow
              label="Vendor Monitored"
              rowClass={"no-max-height"}
              value={<PillLabel>wip</PillLabel>}
            />
          </InfoTable>
        </div>
      )}
    </ReportCard>
  );
};

export default AboutApp;
