import { Filters } from "../components/filter/types";
import { DefaultThunkDispatch } from "../../_common/types/redux";
import { DefaultRootState } from "react-redux";
import { FetchCyberRiskUrl } from "../../_common/api";
import { LogError } from "../../_common/helpers";
import { fetchOwnSharedAssessment, setCustomerData } from "./cyberRiskActions";
import { IUserMini } from "../../_common/types/user";
import { conditionalRefreshActivityStreamForOrgUser } from "../../_common/reducers/commonActions";
import {
  addDefaultUnknownErrorAlert,
  addMessageAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { BannerType } from "../components/InfoBanner";
import { AppDispatch } from "../../_common/types/reduxStore";
import { handleKnownErrors } from "./errors.actions";

export enum OrganisationAcceptedRiskStatus {
  Inactive = "inactive",
  Active = "active",
  AwaitingApproval = "awaitingapproval",
  Expired = "expired",
  Cancelled = "cancelled",
  Rejected = "rejected",
}

export const sortOrganisationAcceptedRiskStatus = (
  status: OrganisationAcceptedRiskStatus
) => {
  switch (status) {
    case OrganisationAcceptedRiskStatus.AwaitingApproval:
      return 0;
    case OrganisationAcceptedRiskStatus.Active:
      return 1;
    case OrganisationAcceptedRiskStatus.Expired:
      return 2;
    case OrganisationAcceptedRiskStatus.Rejected:
      return 3;
    case OrganisationAcceptedRiskStatus.Cancelled:
      return 4;
    case OrganisationAcceptedRiskStatus.Inactive:
      return 5;
    default:
      return 5;
  }
};

/* NOTE: actually maps to customerscores.AcceptedRiskWithName */
export interface OrganisationAcceptedRisk {
  id: number;
  organisationId: number;
  riskId: string;
  riskName: string;
  riskDescription: string;
  riskSummary: string;
  riskDetails: string;
  riskRecommendedRemediation: string;
  riskSeverity: number;
  riskCategoryName: string;
  riskAssetType: RiskAssetType;
  status: OrganisationAcceptedRiskStatus;
  allWebsites: boolean;
  websites: string[];
  createdBy: number;
  approverId?: number;
  approverEmail?: string;
  approverReason?: string;
  requesterReason?: string;
  expiresAt?: string;
  activeAt?: string;
  inactiveAt?: string;
  createdAt: string;
  updatedAt: string;
  deprecated: boolean;
  isPublic: boolean;

  // Relevant for users with portfolio-specific permissions
  domainsFilteredOut?: boolean;
  canWrite?: boolean;
  repoNames: string[];
  manifestLocations: string[];
  userInfos: AcceptedRiskUserInfo[];
}

interface AcceptedRiskUserInfo {
  name: string;
  uuid: string;
}

interface fetchCustomerAcceptedRisksResp {
  acceptedRisks: OrganisationAcceptedRisk[];
  users: IUserMini[];
}

export const fetchCustomerAcceptedRisks = (
  forced: boolean,
  filters?: Filters
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const { filters: currentFilters } = getState().cyberRisk.customerData;

    if (
      !forced &&
      getState().cyberRisk.customerData &&
      getState().cyberRisk.customerData.acceptedRisks
    ) {
      return getState().cyberRisk.customerData.acceptedRisks;
    }

    dispatch(setCustomerData({ acceptedRisks: null }));

    const newFilters = filters || currentFilters;

    let json;

    try {
      json = await FetchCyberRiskUrl<fetchCustomerAcceptedRisksResp>(
        "riskacceptance/org/v1/",
        {
          website_label_ids: newFilters.websiteLabelIds,
          website_portfolio_ids: newFilters.domainPortfolioIds,
          website_label_ids_match_all: newFilters.websiteLabelIdsMatchAll,
          website_label_ids_do_not_match: newFilters.websiteLabelIdsDoNotMatch,
          website_include_unlabeled: newFilters.websiteIncludeUnlabeled,
        },
        null,
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error fetching customer accepted risks", e);
      throw e;
    }

    const data = {
      acceptedRisks: json.acceptedRisks,
      users: json.users,
    };

    dispatch(setCustomerData({ acceptedRisks: data }));

    return data;
  };
};

export enum RiskAssetType {
  cloudscan = "cloudscan",
  appguardRepo = "appguard_repo",
  appguardManifest = "appguard_manifest",
  userbaseUser = "userbase_user",
}

export interface RiskAcceptanceCreateV1ReqBody {
  riskId: string;
  approverEmail?: string;
  justification: string;
  expiresAt?: string;
  isPublic?: boolean;
  websites: string[];
  allFutureWebsites: boolean;
}

export const createCustomerAcceptedRisk = (
  body: RiskAcceptanceCreateV1ReqBody
) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "riskacceptance/v1/",
        {},
        { method: "POST", body: JSON.stringify(body) },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error creating customer accepted risk", e);
      throw e;
    }

    // Re-get own shared profile if required
    if (getState().cyberRisk.customerData.sharedAssessment && body.isPublic) {
      dispatch(fetchOwnSharedAssessment(true));
    }

    // kick off call to update the activity stream
    dispatch(conditionalRefreshActivityStreamForOrgUser());
  };
};

export interface updateCustomerAcceptedRiskOpts {
  id: number;
  changeExpiryDate?: string;
  removeExpiryDate?: boolean;
  changeApprover?: string;
  removeApprover?: boolean;
  isPublic: boolean;
  justification: string;
  allDomains: boolean;
  domains: string[];
}

export const updateCustomerAcceptedRisk = ({
  id,
  changeExpiryDate,
  removeExpiryDate,
  changeApprover,
  removeApprover,
  isPublic,
  justification,
  domains,
  allDomains,
}: updateCustomerAcceptedRiskOpts) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    const params: any = {
      id,
    };

    if (changeExpiryDate) {
      params.change_expiry_date = changeExpiryDate;
    } else if (removeExpiryDate) {
      params.remove_expiry_date = true;
    }

    if (changeApprover) {
      params.change_approver = changeApprover;
    } else if (removeApprover) {
      params.remove_approver = true;
    }

    params.is_public = isPublic;
    params.justification = justification;
    params.domains = domains;
    params.all_domains = allDomains;

    try {
      await FetchCyberRiskUrl(
        "riskacceptance/v1/",
        params,
        { method: "PUT" },
        dispatch,
        getState
      );
    } catch (e) {
      LogError("Error updating customer accepted risk", e);

      throw e;
    }
  };
};

export const cancelCustomerAcceptedRisk = (id: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "riskacceptance/cancel/v1/",
        { id },
        { method: "PUT" },
        dispatch,
        getState
      );

      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      LogError("Error cancelling customer accepted risk", e);
      throw e;
    }
  };
};

export const deleteCustomerAcceptedRisk = (id: number) => {
  return async (
    dispatch: DefaultThunkDispatch,
    getState: () => DefaultRootState
  ) => {
    try {
      await FetchCyberRiskUrl(
        "riskacceptance/v1/",
        { id },
        { method: "DELETE" },
        dispatch,
        getState
      );

      // kick off call to update the activity stream
      dispatch(conditionalRefreshActivityStreamForOrgUser());
    } catch (e) {
      LogError("Error cancelling customer accepted risk", e);
      throw e;
    }
  };
};

export const handleBreachSightWaiverCreationError = (
  dispatch: AppDispatch,
  e: Error
) => {
  switch (e.message) {
    case "ACCEPTED_RISK_EXISTS":
      dispatch(
        addMessageAlert({
          message: "An active waiver for this risk already exists",
          type: BannerType.WARNING,
          subMessages: [
            "Please cancel the existing waiver before creating a new one.",
          ],
        })
      );
      break;
    case "UNAPPROVED_WAIVER_EXISTS":
      dispatch(
        addMessageAlert({
          message: "An unapproved waiver for this risk already exists",
          type: BannerType.WARNING,
          subMessages: [
            "Please approve the existing waiver, or cancel it before creating a new one.",
          ],
        })
      );
      break;
    default:
      if (!dispatch(handleKnownErrors(e))) {
        dispatch(addDefaultUnknownErrorAlert("Error creating risk waiver"));
      }
  }
};
