import { DefaultThunkDispatchProp, IVendorSummary } from "../../types/redux";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { get as _get, noop, toLower } from "lodash";
import classnames from "classnames";
import moment from "moment";
import ModalV2 from "../ModalV2";
import Button from "../core/Button";
import Icon from "../core/Icon";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
} from "../../reducers/messageAlerts.actions";
import "../../style/components/ExecutiveReportingModal.scss";
import {
  OrgAccessVendorAssessments,
  OrgAccessVendorPortfolios,
  OrgAccessVendors,
  UserSystemRoleVendorManagementAnalyst,
  UserVendorRiskEnabled,
} from "../../permissions";
import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";
import {
  calculateFirstRun,
  ScheduleFrequencyPicker,
} from "../../../vendorrisk/components/modals/ExportReportModal";
import { CreatableV2, OptionType } from "../SelectV2";
import { factCategories, validateEmail } from "../../helpers";
import { fetchOrgUserEmailAddresses } from "../../../vendorrisk/reducers/org.actions";
import { UserEmailAddress } from "../../types/user";
import PillLabel from "../../../vendorrisk/components/PillLabel";
import { LabelColor } from "../../types/label";
import {
  DisplayableScheduledReportRecipient,
  ExportFiletype,
  ExportType,
} from "../../types/exportReport";
import { ValueType } from "react-select";
import {
  exportReport,
  getScheduledExports,
  IExportReportParams,
  IScheduleExportReportParams,
  scheduleExecSummaryReport,
  scheduleExport as scheduleExportPDF,
  submitConfigurablePDFRiskAssessmentReportRequest,
} from "../../../vendorrisk/reducers/export.actions";
import { getVendorWords, IVendorWords } from "../../constants";
import { getVendorRiskWaiversFromState } from "../../../vendorrisk/reducers/vendorRiskWaiver.actions";
import {
  fetchVendorSummaryAndCloudscans,
  requestExecSummaryPDFExport,
} from "../../../vendorrisk/reducers/cyberRiskActions";
import ReportTypeBadge, {
  ExecutiveReportType,
  getReportButtonDefinition,
} from "../../../vendorrisk/components/reporting/ReportTypeBadge";
import * as ReportTypes from "../../../vendorrisk/constants/reportTypes";
import { ReportVendorSelectorTable } from "../ReportVendorSelector";
import {
  Portfolio,
  PortfolioType,
} from "../../../vendorrisk/reducers/portfolios.actions";
import XTable, {
  IXTableColumnHeader,
  IXTableRow,
  XTableCell,
} from "../core/XTable";
import {
  Filters,
  FilterTypes,
} from "../../../vendorrisk/components/filter/types";
import LoadingIcon from "../core/LoadingIcon";
import { ReportComparisonVendorSelectorTable } from "../ReportComparisonVendorSelector";
import EmptyCardWithAction from "../EmptyCardWithAction";
import { AssuranceType } from "../../types/organisations";
import {
  AssessmentClassificationType,
  VendorAssessmentClassificationMap,
} from "../../../vendorrisk/types/vendorAssessments";
import { fetchAssessmentBreakdown } from "../../../vendorrisk/reducers/vendorAssessment.actions";
import PaginatedTable from "../../../vendorrisk/components/PaginatedTable";
import {
  IVendorSearchVendorAndWatched,
  IWatchedVendorSearchResult,
} from "../../types/vendor";
import { History } from "history";
import { appConnect } from "../../types/reduxHooks";
import { SidePopupV2 } from "../DismissablePopup";
import { isFilterActive } from "../../../vendorrisk/components/filter";

// used to represent the group of collected assessment statuses
const COMPLETED = 666;

export const vendorHasValidQuestionnairesForExport = (
  counts:
    | {
        returned?: number;
        shared?: number;
      }
    | undefined
) => !!counts && ((counts.returned ?? 0) > 0 || (counts.shared ?? 0) > 0);

export enum ExecutiveReportingMode {
  VendorRiskReporting,
  BreachsightReporting,
  UpGuardReporting,
  PortfolioReporting,
}

interface ISubOptionDef {
  name: string;
  label: string;
  disabled?: boolean;
}
interface IOptionDef {
  name: string;
  label: string;
  disabled?: boolean;
  subtext: string;
  subOptionName?: string;
  subOptions?: ISubOptionDef[];
  requiredDataSources?: string[];
}

const PAGETYPETITLE_PLACEHOLDER = "TBA"; // There's a page but we dont know what it is..
const PAGETYPETITLE_SELECT = "Select Report Type";
const PAGETYPETITLE_SELECTASSESSEDVENDOR = "Select Assessed Vendor";
const PAGETYPETITLE_SELECTVENDOR = "Select Vendor";
const PAGETYPETITLE_SELECTCOMPARISONVENDORS = "Select Vendors for Comparison";
const PAGETYPETITLE_SELECTDOMAINPORTFOLIOS = "Select Asset Portfolios";
const PAGETYPETITLE_SELECTVENDORPORTFOLIOS = "Select Portfolios";
const PAGETYPETITLE_RISKASSESSMENTOPTIONS = "Customize Assessments Report";
const PAGETYPETITLE_CUSTOMIZE = "Customize Report";
const PAGETYPETITLE_DELIVERY = "Report Delivery";

export interface IExecutiveReportingModalOwnProps {
  active: boolean;
  onClose: () => void;
  mode?: ExecutiveReportingMode;
  vendorId?: number;
  managedOrgId?: number;
  isManagementAnalystSession?: boolean;
  preSelectedReportType?: ExecutiveReportType;
  preSelectedVendors?: IWatchedVendorSearchResult[];
  history: History;
  supportedFilters?: FilterTypes[];
}

interface IExecutiveReportingModalConnectProps {
  filters: Filters;
  orgUserEmailAddresses: {
    loading: boolean;
    data?: UserEmailAddress[];
  };
  vendorSummaryDataLoaded: boolean;
  publishedRiskAssessment?: any;
  orgHasVendorRiskEnabled: boolean;
  orgHasAssessmentsEnabled: boolean;
  vendorHasValidQuestionnaires: boolean;
  vendorHasValidAdditionalEvidence: boolean;
  vendorHasEvidencePages: boolean;
  vendorHasVendorRiskWaivers: boolean;
  vendorHasWebPresence: boolean;
  vendorHasTooManyDomains: boolean;
  vendorPortfolios?: Portfolio[];
  domainPortfolios?: Portfolio[];
  assuranceType: AssuranceType;
  vendorAssessmentBreakdown?: VendorAssessmentClassificationMap;
  orgHasVendorPortfoliosEnabled: boolean;
  preSelectedAssessmentStates: {
    [status: number]: boolean;
  };
  userHasVendorRisk: boolean;
}

interface IVendorSummaryDetails {
  publishedRiskAssessment: any;
  vendorSummaryDataLoaded: boolean;
  vendorSummaryDataError: string;
  vendorHasValidQuestionnaires: boolean;
  vendorHasValidAdditionalEvidence: boolean;
  vendorHasEvidencePages: boolean;
  vendorHasVendorRiskWaivers: boolean;
  vendorHasWebPresence: boolean;
  vendorHasTooManyDomains: boolean;
}

interface IReportListEntry {
  reportType: ExecutiveReportType;
  busy: boolean;
  disabledReason: string;
  publishedRiskAssessment?: boolean;
}

type IExecutiveReportingModalProps = IExecutiveReportingModalOwnProps &
  IExecutiveReportingModalConnectProps &
  DefaultThunkDispatchProp;

const ExecutiveReportingModal = (props: IExecutiveReportingModalProps) => {
  const {
    dispatch,
    onClose,
    history,
    active,
    mode,
    filters,
    vendorId,
    preSelectedReportType,
    orgUserEmailAddresses,
    assuranceType,
    vendorSummaryDataLoaded,
    publishedRiskAssessment,
    orgHasVendorRiskEnabled,
    orgHasAssessmentsEnabled,
    vendorHasValidQuestionnaires,
    vendorHasValidAdditionalEvidence,
    vendorHasVendorRiskWaivers,
    vendorHasEvidencePages,
    vendorHasWebPresence,
    vendorHasTooManyDomains,
    vendorPortfolios,
    domainPortfolios,
    preSelectedVendors,
    orgHasVendorPortfoliosEnabled,
    preSelectedAssessmentStates,
    userHasVendorRisk,
  } = props;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedReport, setSelectedReport] = useState(
    preSelectedReportType || ExecutiveReportType.Unknown
  );
  const [selectedSubReport, setSelectedSubReport] = useState(
    ExecutiveReportType.Unknown
  );
  const [selectedVendorId, setSelectedVendorId] = useState(
    vendorId ? vendorId : 0
  );
  const [multiSelectedVendorIds, setMultiSelectedVendorIds] = useState(
    vendorId
      ? [vendorId]
      : preSelectedVendors
        ? preSelectedVendors.map((v) => v.id)
        : []
  );
  const [selectedMultiVendorCache, setSelectedMultiVendorCache] = useState(
    [] as any[]
  );
  const [vendorFilterText, setVendorFilterText] = useState("");
  const [inclusionsExpanded, setInclusionsExpanded] = useState(true);
  const [scheduleExport, setScheduleExport] = useState(false);
  const [scheduleInterval, setScheduleInterval] = useState("m");
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedTime, setSelectedTime] = useState(9);
  const [dayOfWeek, setDayOfWeek] = useState("Monday");
  const [emailReport, setEmailReport] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    show_risks: true,
  } as {
    [name: string]: boolean;
  });
  const [emailRecipients, setEmailRecipients] = useState(
    [] as DisplayableScheduledReportRecipient[]
  );
  const [selectedVendorPortfolioIds, setSelectedVendorPortfolioIds] = useState<
    number[]
  >([]);
  const [selectedDomainPortfolioIds, setSelectedDomainPortfolioIds] = useState<
    number[]
  >([]);
  const [selectedAssessmentStatuses, setSelectedAssessmentStatuses] = useState(
    preSelectedAssessmentStates
  );
  const [portfolioListPage, setPortfolioListPage] = useState(0);

  const [allVendorsSearchResult, setAllVendorsSearchResult] = useState(
    [] as IVendorSearchVendorAndWatched[]
  );

  const [includeFilters, setIncludeFilters] = useState(
    !!props.supportedFilters && isFilterActive(filters, props.supportedFilters)
  );

  const [vendorSummaryDetails, setVendorSummaryDetails] = useState({
    vendorSummaryDataLoaded,
    publishedRiskAssessment,
    vendorHasValidQuestionnaires,
    vendorHasValidAdditionalEvidence,
    vendorHasVendorRiskWaivers,
    vendorHasWebPresence,
    vendorHasTooManyDomains,
    vendorHasEvidencePages,
  } as IVendorSummaryDetails);

  // load required data on first load
  useEffect(() => {
    fetchOrgEmails();
    if (orgHasVendorRiskEnabled && userHasVendorRisk) {
      fetchVendorSummary(false);
      props.dispatch(fetchAssessmentBreakdown());
    }
  }, []);

  useEffect(() => {
    setSelectedReport(
      preSelectedReportType
        ? preSelectedReportType
        : ExecutiveReportType.Unknown
    );
  }, [preSelectedReportType]);

  useEffect(() => {
    if (!active) {
      return;
    }

    // When vendor portfolios are loaded in, set the preselected portfolio IDs
    // to either the set in the filters, or all available portfolios.
    if (filters.portfolioIds && filters.portfolioIds.length > 0) {
      setSelectedVendorPortfolioIds([...filters.portfolioIds]);
    } else if (vendorPortfolios) {
      setSelectedVendorPortfolioIds(vendorPortfolios.map((p) => p.id));
    }
  }, [active, filters, vendorPortfolios]);

  useEffect(() => {
    if (!active) {
      return;
    }

    // When domain portfolios are loaded in, set the preselected portfolio IDs
    // to either the set in the filters, or all available portfolios.
    if (filters.domainPortfolioIds && filters.domainPortfolioIds.length > 0) {
      setSelectedDomainPortfolioIds([...filters.domainPortfolioIds]);
    } else if (domainPortfolios) {
      setSelectedDomainPortfolioIds(domainPortfolios.map((p) => p.id));
    }
  }, [active, filters, domainPortfolios]);

  useEffect(() => {
    fetchVendorSummary(true);
  }, [selectedVendorId]);

  useEffect(() => {
    setVendorSummaryDetails({
      vendorSummaryDataLoaded,
      publishedRiskAssessment,
      vendorHasValidQuestionnaires,
      vendorHasValidAdditionalEvidence,
      vendorHasVendorRiskWaivers,
      vendorHasWebPresence,
      vendorHasTooManyDomains,
      vendorHasEvidencePages,
    } as IVendorSummaryDetails);
  }, [vendorSummaryDataLoaded]);

  const vendorWords = getVendorWords(assuranceType);

  //
  // getVendorReportButtons
  // When a 'Generic' vendor report is specified as type, this function supplies the
  // set of report types that the user gets to choose from. A button may be displayed as busy if its disabled state
  // is not yet determined
  //
  const getVendorReportButtons = (
    _vendorWords: IVendorWords
  ): IReportListEntry[] => {
    return [];
  };

  //
  // getPortfolioReportButtons
  // When the user is requesting a report from a vendor risk page (that is not a specific vendor) then we give
  // them the option to generate several reports over all their vendors.
  //
  const getVendorPortfolioReportButtons = (): IReportListEntry[] => {
    const entries = [
      {
        reportType: ExecutiveReportType.VendorComparisonReport,
        busy: false,
        disabledReason: "",
      },
    ];

    let disabled = false;
    let reason = "";

    let total = 0;
    if (!props.orgHasAssessmentsEnabled) {
      disabled = true;
      reason = "Risk assessments are not enabled for your account";
    } else if (props.vendorAssessmentBreakdown) {
      Object.keys(props.vendorAssessmentBreakdown).map((k: string) => {
        if (
          props.vendorAssessmentBreakdown?.[k].classification !=
          AssessmentClassificationType.NotAssessed
        ) {
          total += props.vendorAssessmentBreakdown?.[k].usageCount ?? 0;
        }
      });
    }
    if (!disabled && total == 0) {
      disabled = true;
      reason = "You haven't conducted a risk assessment for a vendor yet.";
    }

    entries.push({
      reportType: ExecutiveReportType.RiskAssessmentSummary,
      busy: false,
      disabled: disabled,
      disabledReason: reason,
      publishedRiskAssessment: !disabled,
    } as IReportListEntry);
    return entries;
  };

  const getVendorRiskExecutiveSummaryReportOptions = (
    vendorWords: IVendorWords
  ): IOptionDef[] => {
    return [
      {
        name: ReportTypes.VendorRiskRiskMatrix,
        label: `${vendorWords.singularTitleCase} Security Ratings by Business Impact`,
        subtext: `Include the Security Ratings by Business Impact matrix`,
      },
      {
        name: ReportTypes.VendorRiskOverview,
        label: `${vendorWords.vendorRiskModuleName} Overview`,
        subtext: `Include the ${vendorWords.vendorRiskModuleName} Overview.`,
      },
      {
        name: ReportTypes.VendorRiskRatingsBreakdown,
        label: `Highest And Lowest Rated ${vendorWords.pluralTitleCase}`,
        subtext: `Include Highest And Lowest Rated ${vendorWords.pluralTitleCase}.`,
      },
      {
        name: ReportTypes.VendorRiskSupplyChain,
        label: "Fourth Parties",
        subtext: "Include Fourth Parties.",
      },
      {
        name: ReportTypes.VendorRiskGeolocation,
        label: `${vendorWords.singularTitleCase} Geolocation`,
        subtext: `Include ${vendorWords.singularTitleCase} Geolocation.`,
      },
    ] as IOptionDef[];
  };

  //
  // getOptionSelected
  // Consults the state and determines if an option has been set to 'true' indicating that it is selected. Note that for
  // the reporting options list we want all options to be 'selected' by default, so we provide this as an option to the
  // function. As such, if the map entry is undefined and trueByDefault is set, the we return selected = true.
  // @see toggleOption
  //
  const getOptionSelected = (option: string, trueByDefault = false) => {
    let selected = selectedOptions[option];
    if (selected == undefined && trueByDefault) {
      selected = true;
    }
    return !!selected;
  };

  //
  // toggleOption
  // Toggles the selected state of a particular option by name. We also pass in the trueByDefault flag here so that the
  // current state of the option can be correctly determined.
  // @see getOptionSelected
  //
  const toggleOption = (
    option: string,
    trueByDefault: boolean,
    requiredDataSources: string[] | null = null,
    subOptions: ISubOptionDef[] | null = null,
    isRadio = false
  ) => {
    let selected = getOptionSelected(option, trueByDefault);
    if (isRadio && selected) {
      // make sure clicking a selected radio does not toggle it
      selected = false;
    }
    setSelectedOptions((prevState) => ({
      ...prevState,
      [option]: !selected,
    }));
    if (requiredDataSources && requiredDataSources.length > 0 && !selected) {
      for (let i = 0; i < requiredDataSources.length; i++) {
        setSelectedOptions((prevState) => ({
          ...prevState,
          [requiredDataSources[i]]: true,
        }));
      }
    }
    // if suboptions have been supplied, we want radio-button operation on the others, so iterate and reset them
    if (!selected && subOptions && subOptions.length > 0) {
      subOptions.map((s) => {
        if (s.name !== option) {
          setSelectedOptions((prevState) => ({
            ...prevState,
            [s.name]: false,
          }));
        }
      });
    }
  };

  //
  // toggleInclusionsExpanded
  // toggle the state of inclusions title (list accordian) on the customization page of the modal
  //
  const toggleInclusionsExpanded = () => {
    setInclusionsExpanded(!inclusionsExpanded);
  };

  //
  // fetchOrgEmails
  // Call the back-end to retrieve the list of email addresses for users of the org. Used to populate the
  // dropdown for specifying target emails for delivery.
  //
  const fetchOrgEmails = () => {
    dispatch(fetchOrgUserEmailAddresses());
  };

  //
  // fetchVendorSummary
  // Retrieves the vendor summary information for the supplied or selected vendor. Specifically, the summary is used to enable/disable
  // data source options for the vendor.
  // @see getVendorRiskDataSourceOptions()
  // @see getOptionsPage(),
  //
  const fetchVendorSummary = async (wait: boolean) => {
    if (selectedVendorId) {
      if (wait) {
        setVendorSummaryDetails({
          vendorSummaryDataLoaded: false,
          vendorSummaryDataError: "",
        } as IVendorSummaryDetails);

        dispatch(fetchVendorSummaryAndCloudscans(selectedVendorId, false))
          .then((vendorSummary) => {
            vendorSummary = vendorSummary as IVendorSummary;
            if (vendorSummary && !!vendorSummary.result) {
              const summary = {
                vendorSummaryDataLoaded: true,
                vendorSummaryDataError: "",
              } as IVendorSummaryDetails;

              if (
                vendorSummary.result.assessmentSummary &&
                vendorSummary.result.assessmentSummary.publishedAt
              ) {
                summary.publishedRiskAssessment =
                  vendorSummary.result.assessmentSummary;
              }

              summary.vendorHasValidQuestionnaires =
                vendorHasValidQuestionnairesForExport(
                  vendorSummary.result.mostRecentQuestionnaire?.counts
                );

              if (
                vendorSummary.result.categorySummaries &&
                !!vendorSummary.result.categorySummaries[
                  factCategories.AdditionalEvidenceRisks
                ]
              ) {
                summary.vendorHasValidAdditionalEvidence = true;
              }

              if (vendorSummary.result.evidencePages) {
                summary.vendorHasEvidencePages = true;
              }

              summary.vendorHasVendorRiskWaivers =
                vendorSummary.result.numRiskWaivers > 0;

              if (
                vendorSummary.result.totalCloudscans > 0 ||
                vendorSummary.result.totalInactiveDomains > 0
              ) {
                summary.vendorHasWebPresence = true;
              }
              if (vendorSummary.result.totalCloudscansOverExportMax) {
                summary.vendorHasTooManyDomains = true;

                // unselect show_risks_and_assets if necessary, as it's now disabled
                if (getOptionSelected("show_risks_and_assets")) {
                  setSelectedOptions((prevState) => ({
                    ...prevState,
                    show_risks_and_assets: false,
                    show_risks: true,
                  }));
                }
              }
              setVendorSummaryDetails(summary);
            } else {
              console.error(`failed to retrieve vendor summary data`);
              setVendorSummaryDetails({
                vendorSummaryDataLoaded: false,
                vendorSummaryDataError: "Failed to load vendor summary",
              } as IVendorSummaryDetails);
            }
          })
          .catch((e) => {
            console.error(`##### failed to fetch vendor summary: ${e}`);
            setVendorSummaryDetails({
              vendorSummaryDataLoaded: false,
              vendorSummaryDataError: `Failed to retrieve risk summary: ${e}`,
            } as IVendorSummaryDetails);
          });
      } else {
        dispatch(fetchVendorSummaryAndCloudscans(selectedVendorId, false));
      }
    }
  };

  //
  // validatePageInputs
  // For the current page of the modal, checks that all mandatory data requirements have been met so that
  // the 'Next' button can be enabled.
  //
  const validatePageInputs = (
    vendorWords: IVendorWords
  ): { passed: boolean; msg: string } => {
    const thisPage = getPageType(
      selectedReport,
      selectedSubReport,
      !!vendorId,
      page
    );
    const nextPage = getPageType(
      selectedReport,
      selectedSubReport,
      !!vendorId,
      page + 1
    );
    if (
      (thisPage == PAGETYPETITLE_SELECTVENDOR ||
        thisPage == PAGETYPETITLE_SELECTASSESSEDVENDOR) &&
      selectedVendorId == 0
    ) {
      return { passed: false, msg: `Please select a ${vendorWords.singular}` };
    }
    if (thisPage == PAGETYPETITLE_CUSTOMIZE) {
      const {
        opts: dataSourceOpts,
        loading,
        error,
      } = getReportDataSourceOptions(selectedReport, selectedSubReport);
      const userOptions = getReportUserOptions(
        selectedReport,
        selectedSubReport
      );
      const oneMandatory = getReportUserOptionsOneMandatory(
        selectedReport,
        selectedSubReport
      );

      let selectedOptionCount = 0;
      for (let i = 0; i < dataSourceOpts.length; i++) {
        selectedOptionCount +=
          getOptionSelected(dataSourceOpts[i].name, true) &&
          !dataSourceOpts[i].disabled
            ? 1
            : 0;
      }
      if (dataSourceOpts.length > 0 && (loading || error)) {
        return {
          passed: false,
          msg: "Data sources must be selected for this report",
        };
      }
      if (dataSourceOpts.length > 0 && selectedOptionCount == 0) {
        return {
          passed: false,
          msg: "At least 1 data source must be selected for this report",
        };
      }
      // test the required dependencies for data sources.
      let numUserOptionsSelected = 0;
      for (let i = 0; i < userOptions.length; i++) {
        if (getOptionSelected(userOptions[i].name, true)) {
          // user option is selected. does it have datasource dependencies?
          numUserOptionsSelected += 1;
          const requiredDatasources = userOptions[i].requiredDataSources
            ? userOptions[i].requiredDataSources
            : ([] as string[]);
          if (requiredDatasources) {
            for (let j = 0; j < requiredDatasources.length; j++) {
              if (!getOptionSelected(requiredDatasources[j], true)) {
                let datasourceLabel = requiredDatasources[j];
                dataSourceOpts.map((d) => {
                  if (d.name == requiredDatasources[j]) {
                    datasourceLabel = d.label;
                  }
                });
                return {
                  passed: false,
                  msg: `Report inclusion '${userOptions[i].label}' requires data source '${datasourceLabel}'`,
                };
              }
            }
          }
        }
      }
      if (numUserOptionsSelected == 0 && oneMandatory) {
        return {
          passed: false,
          msg: `At least 1 option must be selected`,
        };
      }
    }
    if (
      (thisPage == PAGETYPETITLE_SELECTCOMPARISONVENDORS &&
        multiSelectedVendorIds.length < 2) ||
      multiSelectedVendorIds.length > 4
    ) {
      return {
        passed: false,
        msg: `Please select at least 2 and at most 4 ${vendorWords.plural} for comparison`,
      };
    }
    if (
      selectedReport != ExecutiveReportType.Unknown &&
      nextPage == PAGETYPETITLE_PLACEHOLDER
    ) {
      return {
        passed: false,
        msg: "Please select a report type",
      };
    }
    if (thisPage == PAGETYPETITLE_RISKASSESSMENTOPTIONS) {
      const noPortfolios = selectedVendorPortfolioIds.length == 0;
      let noAssessmentStatuses = true;
      Object.keys(selectedAssessmentStatuses).forEach((k) => {
        if (selectedAssessmentStatuses[parseInt(k, 10)]) {
          noAssessmentStatuses = false;
        }
      });
      return {
        passed:
          (!orgHasVendorPortfoliosEnabled || !noPortfolios) &&
          !noAssessmentStatuses,
        msg:
          noPortfolios || noAssessmentStatuses
            ? `Please select ${
                orgHasVendorPortfoliosEnabled ? "at least 1 portfolio and " : ""
              }at least 1 assessment status`
            : "",
      };
    }
    return {
      passed:
        nextPage != PAGETYPETITLE_PLACEHOLDER &&
        selectedReport != ExecutiveReportType.Unknown,
      msg: "",
    };
  };

  // getReportUserOptions
  // For a specific selected report type, returns the set of options that are used to configure the content of the report.
  // Typically, these options are presented to the user for that report type on the options page.
  //
  const getReportUserOptions = (
    selectedReport: ExecutiveReportType,
    selectedSubReport: ExecutiveReportType
  ): IOptionDef[] => {
    const selectedReportType =
      selectedSubReport && selectedSubReport != ExecutiveReportType.Unknown
        ? selectedSubReport
        : selectedReport;
    switch (selectedReportType) {
      case ExecutiveReportType.VendorRiskExecSummaryReport:
        return getVendorRiskExecutiveSummaryReportOptions(vendorWords);
    }
    return [] as IOptionDef[];
  };

  // getReportUserOptionsOneMandatory
  // For a specific selected report type, determines if the user must select at least 1 option defining the content of the report.
  //
  const getReportUserOptionsOneMandatory = (
    selectedReport: ExecutiveReportType,
    selectedSubReport: ExecutiveReportType
  ): boolean => {
    const selectedReportType =
      selectedSubReport && selectedSubReport != ExecutiveReportType.Unknown
        ? selectedSubReport
        : selectedReport;
    switch (selectedReportType) {
      case ExecutiveReportType.VendorRiskExecSummaryReport:
        return true;
    }
    return false;
  };

  //
  // getReportDataSourceOptions
  // For a specific selected report type, returns the set of 'data source' options that are used to configure the content of the report.
  // Typically, these options are presented to the user for that report type on the options page, and apply to VR specifically.
  //
  const getReportDataSourceOptions = (
    selectedReport: ExecutiveReportType,
    selectedSubReport: ExecutiveReportType
  ): { opts: IOptionDef[]; loading: boolean; error: boolean } => {
    const selectedReportType =
      selectedSubReport && selectedSubReport != ExecutiveReportType.Unknown
        ? selectedSubReport
        : selectedReport;
    switch (selectedReportType) {
      default:
        return { opts: [] as IOptionDef[], loading: false, error: false };
    }
  };

  //
  // submitVendorRiskExecutiveSummaryPDF
  // Uses the VR executive summary export endpoint to generate the VR executive summary report for the selected set of
  // portfolios.
  //
  const submitVendorRiskExecutiveSummaryPDF = async (
    selectedEmailAddresses: string[],
    firstRun: string
  ) => {
    let selectedOptions: { [name: string]: any } = {};
    const userOptions: IOptionDef[] | undefined = getReportUserOptions(
      selectedReport,
      selectedSubReport
    );

    if (userOptions) {
      userOptions.map((o) => {
        if (o.subOptionName && o.subOptions && o.subOptions.length > 0) {
          o.subOptions.map((s) => {
            if (getOptionSelected(s.name)) {
              delete selectedOptions[s.name];
              selectedOptions[o.subOptionName as string] = s.name;
            }
          });
        }
        selectedOptions[o.name] = getOptionSelected(o.name, true);
      });
    }

    selectedOptions = {
      ...selectedOptions,
      portfolio_ids: selectedVendorPortfolioIds,
      first_run: firstRun,
      interval: scheduleInterval,
      period: "1",
    };

    if (scheduleExport) {
      await dispatch(
        scheduleExecSummaryReport(
          selectedOptions,
          false,
          firstRun,
          scheduleInterval,
          1,
          selectedEmailAddresses
        )
      );
    } else {
      await dispatch(
        requestExecSummaryPDFExport(
          selectedOptions,
          false,
          selectedEmailAddresses
        )
      );
    }
  };

  const submitRiskAssessmentStatusReportRequest = async (
    selectedEmailAddresses: string[],
    selectedPortfolioIds: number[],
    firstRun: string
  ) => {
    const selectedStatuses: number[] = [];
    Object.keys(selectedAssessmentStatuses).forEach((k: string) => {
      const selected = selectedAssessmentStatuses[parseInt(k)];
      if (parseInt(k, 10) == COMPLETED && selected) {
        selectedStatuses.push(AssessmentClassificationType.Overdue);
        selectedStatuses.push(AssessmentClassificationType.DueWithin30Days);
        selectedStatuses.push(AssessmentClassificationType.DueOutside30Days);
        selectedStatuses.push(AssessmentClassificationType.Reschedule);
      } else if (selected) {
        selectedStatuses.push(parseInt(k, 10));
      }
    });
    let portfolioIds = [...selectedPortfolioIds];
    // if the user has selected ALL portfolios, then dont filter by portfolio
    if (selectedPortfolioIds.length === vendorPortfolios?.length) {
      portfolioIds = [];
    }

    await dispatch(
      submitConfigurablePDFRiskAssessmentReportRequest(
        portfolioIds,
        selectedStatuses,
        scheduleExport,
        scheduleInterval,
        firstRun,
        emailReport,
        selectedEmailAddresses,
        includeFilters
      )
    );
  };

  const submitVendorComparisonReportRequest = async (
    selectedEmailAddresses: string[],
    firstRun: string
  ) => {
    if (scheduleExport) {
      const params = {
        exportType: ExportType.VendorComparisonReport,
        fileType: ExportFiletype.PDF,
        exportOptions: {
          vendor_ids: multiSelectedVendorIds,
        },
        applyFilters: false,
        sections: [],
        overrideFilters: {} as Filters,
        emailRecipients: scheduleExport ? selectedEmailAddresses : undefined,
        first_run: firstRun,
        interval: scheduleInterval,
        period: 1,
      } as IScheduleExportReportParams;
      await dispatch(scheduleExportPDF(params));
    } else {
      const params = {
        exportType: ExportType.VendorComparisonReport,
        fileType: ExportFiletype.PDF,
        exportOptions: {
          vendor_ids: multiSelectedVendorIds,
        },
        applyFilters: false,
        sections: [],
        overrideFilters: {} as Filters,
        emailRecipients: scheduleExport ? selectedEmailAddresses : undefined,
      } as IExportReportParams;
      await dispatch(exportReport(params));
    }
  };

  const onSuccessfulSchedule = useCallback(() => {
    onClose();
    history.push("/reportexports/scheduled", {
      noRemoveWhispers: true,
    });
  }, [onClose, history]);
  const onSuccessfulGenerate = useCallback(() => {
    onClose();
    history.push("/reportexports/generated", {
      noRemoveWhispers: true,
    });
  }, [onClose, history]);

  //
  // onSubmitReport
  // This last step in the process collects together all the state data and sends it to the back-end for processing.
  //
  const onSubmitReport = async () => {
    setLoading(true);

    let reportType = selectedReport;
    if (selectedSubReport != ExecutiveReportType.Unknown) {
      reportType = selectedSubReport;
    }

    const selectedEmailAddresses = [] as string[];
    emailRecipients.map((e) => {
      if (!e.unsubscribed && !e.unsubscribedAll) {
        selectedEmailAddresses.push(e.emailAddress);
      }
    });

    const firstRun = calculateFirstRun(
      scheduleInterval,
      selectedDate,
      selectedTime,
      dayOfWeek
    );

    try {
      switch (reportType) {
        case ExecutiveReportType.VendorRiskExecSummaryReport:
          await submitVendorRiskExecutiveSummaryPDF(
            selectedEmailAddresses,
            firstRun
          );
          break;
        case ExecutiveReportType.VendorComparisonReport:
          await submitVendorComparisonReportRequest(
            selectedEmailAddresses,
            firstRun
          );
          break;
        case ExecutiveReportType.RiskAssessmentSummary:
          await submitRiskAssessmentStatusReportRequest(
            selectedEmailAddresses,
            selectedVendorPortfolioIds,
            firstRun
          );
          break;
        default:
          throw "Report type not yet implemented";
      }

      if (!scheduleExport) {
        dispatch(
          addDefaultSuccessAlert("Your report has been queued for processing", [
            "Please refer to the Reports section on the left navigation for updates on its status.",
          ])
        );
        onSuccessfulGenerate();
      } else {
        dispatch(getScheduledExports())
          .then(() => {
            dispatch(
              addDefaultSuccessAlert("Your report has been scheduled", [
                "Please refer to the Reports section on the left navigation for more details.",
              ])
            );
            onSuccessfulSchedule();
          })
          .catch(() => {
            dispatch(
              addDefaultUnknownErrorAlert("Error refreshing scheduled exports")
            );
          });
      }
    } catch (e) {
      console.log(`${e}`);
      dispatch(
        addDefaultUnknownErrorAlert(`Failed to submit your report request`)
      );
    }
    setLoading(false);
  };

  //
  // onSelectEmail
  // Adds a new email address to the list of recipient addresses being collected for the current report request.
  //
  const onSelectEmail = (selectedOptions: ValueType<OptionType, true>) => {
    if (selectedOptions) {
      setEmailRecipients(
        selectedOptions.map(
          (o) =>
            ({
              emailAddress: toLower(o.value.toString()),
              unsubscribedAll: false,
              unsubscribed: false,
            }) as DisplayableScheduledReportRecipient
        )
      );
    } else {
      setEmailRecipients([]);
    }
  };

  //
  // onRemoveEmail
  // Removes an existing email address from the list of recipient addresses being collected for the current report request.
  //
  const onRemoveEmail = (email: DisplayableScheduledReportRecipient) => {
    setEmailRecipients(
      emailRecipients.filter((e) => e.emailAddress !== email.emailAddress)
    );
  };

  //
  // reportTypeSelectionButtons
  // Generates a list of buttons that appear on the first page of the modal and allow the required report type
  // to be selected. It takes a set of button definitions that are supplied based on the mode (breachsight | vendor risk).
  //
  const reportTypeSelectionButtons = (def: IReportListEntry[]): any[] => {
    const buttons = [] as any[];
    const vendorWords = getVendorWords(assuranceType);
    def.map((b) => {
      buttons.push(
        <ReportTypeBadge
          key={b.reportType}
          selected={selectedSubReport == b.reportType}
          reportDef={getReportButtonDefinition(
            b.reportType,
            vendorWords,
            b.publishedRiskAssessment ||
              !!vendorSummaryDetails.publishedRiskAssessment,
            orgHasAssessmentsEnabled
          )}
          assuranceType={assuranceType}
          onClick={() => setSelectedSubReport(b.reportType)}
          busy={b.busy}
          disabledReason={b.disabledReason}
        />
      );
    });
    return buttons;
  };

  //
  // vendorSelectionPage
  // Generates a searchable list of watched vendors to select from. This is required when the user has opted for a vendor-specific report but
  // has not supplied the vendorId. Typically resides on page 2 of the modal, where appropriate.
  //
  const vendorSelectionPage = (assessedVendorsOnly: boolean) => {
    return (
      <div className={"simple-modal-form tabled"}>
        <ReportVendorSelectorTable
          dispatch={props.dispatch}
          selectedVendorIDs={[selectedVendorId] as number[]}
          allVendorsSearchResult={allVendorsSearchResult}
          setAllVendorsSearchResult={setAllVendorsSearchResult}
          filterText={vendorFilterText}
          setFilterText={setVendorFilterText}
          onSelectVendor={(selected: boolean, vendorId: number) => {
            if (selected) {
              setSelectedVendorId(vendorId);
            }
          }}
          pageSize={5}
          defaultFilterText={vendorFilterText}
          writablePortfoliosOnly={true}
          enabledPortfolioIDs={selectedVendorPortfolioIds}
          assessedVendorsOnly={assessedVendorsOnly}
          vendorWords={vendorWords}
          assuranceType={assuranceType}
          useStateFilters={true}
        />
      </div>
    );
  };

  //
  // vendorComparisonSelectionPage
  // Generates a searchable list of watched vendors to select for comparison purposes. This is required when the user has opted for the vendor comparison
  // report and has not pre-supplied the vendorIds. Typically resides on page 1 of the modal, where appropriate.
  //
  const vendorComparisonSelectionPage = () => {
    return (
      <div className={"simple-modal-form tabled"}>
        <ReportComparisonVendorSelectorTable
          dispatch={props.dispatch}
          selectedVendorIDs={multiSelectedVendorIds}
          onSelectVendor={(vendorId: number, selected: boolean) => {
            if (selected) {
              setMultiSelectedVendorIds([...multiSelectedVendorIds, vendorId]);
            } else {
              const idx = multiSelectedVendorIds.indexOf(vendorId);
              if (idx > -1) {
                multiSelectedVendorIds.splice(idx, 1); // 2nd parameter means remove one item only
              }
              setMultiSelectedVendorIds([...multiSelectedVendorIds]);
            }
          }}
          allowMultiSelect={true}
          pageSize={5}
          setFilterText={(filterText: string) => {
            setVendorFilterText(filterText);
          }}
          defaultFilterText={vendorFilterText}
          vendorWords={vendorWords}
          selectedVendorCache={selectedMultiVendorCache}
          setSelectedVendorCache={(cache) => {
            setSelectedMultiVendorCache(cache);
          }}
        />
      </div>
    );
  };

  //
  // onSelectPortfolio
  // When a portfolio is selected or de-selected on the portfolio selection page, add/remove the portfolio from
  // the list of selected portfolios being maintained in state.
  //
  const onSelectVendorPortfolio = (portfolioId: number) => {
    const newPortfolioIds = [...selectedVendorPortfolioIds];
    if (newPortfolioIds.includes(portfolioId)) {
      const idx = newPortfolioIds.indexOf(portfolioId);
      newPortfolioIds.splice(idx, 1); // 2nd parameter means r
    } else {
      newPortfolioIds.push(portfolioId);
    }
    setSelectedVendorPortfolioIds(newPortfolioIds);
  };

  const onSelectDomainPortfolio = (portfolioId: number) => {
    const newPortfolioIds = [...selectedDomainPortfolioIds];
    if (newPortfolioIds.includes(portfolioId)) {
      const idx = newPortfolioIds.indexOf(portfolioId);
      newPortfolioIds.splice(idx, 1); // 2nd parameter means r
    } else {
      newPortfolioIds.push(portfolioId);
    }
    setSelectedDomainPortfolioIds(newPortfolioIds);
  };

  //
  // portfolioSelectionPage
  // Generates a selectable list of vendor portfolios from which to select. This is required for the vendor executive summary report where normally one or
  // more portfolios forms the basis for the report. Typically resides on page 1 of the modal, where appropriate.
  //
  const portfolioSelectionPage = (portfolioType: PortfolioType) => {
    const portfolios =
      (portfolioType === PortfolioType.Domain
        ? domainPortfolios
        : vendorPortfolios) ?? [];
    const selectedPortfolioIds =
      portfolioType === PortfolioType.Domain
        ? selectedDomainPortfolioIds
        : selectedVendorPortfolioIds;
    const onSelectPortfolio =
      portfolioType === PortfolioType.Domain
        ? onSelectDomainPortfolio
        : onSelectVendorPortfolio;

    const selectAll = () => {
      const ids = portfolios.map((p) => p.id);
      portfolioType === PortfolioType.Domain
        ? setSelectedDomainPortfolioIds(ids)
        : setSelectedVendorPortfolioIds(ids);
    };

    const selectNone = () =>
      portfolioType === PortfolioType.Domain
        ? setSelectedDomainPortfolioIds([])
        : setSelectedVendorPortfolioIds([]);

    const getPortfolioRows = (): IXTableRow[] =>
      portfolios.map((p) => {
        const checked = selectedPortfolioIds.includes(p.id);

        return {
          id: p.id,
          expandDisabled: true,
          selected: checked,
          onClick: () => onSelectPortfolio(p.id),
          cells: [
            <XTableCell key="name" className={"portfolio-cell"}>
              {p.name}
            </XTableCell>,
            <XTableCell key="vendors" className={"vendors-cell"}>
              <PillLabel
                color={
                  selectedPortfolioIds.includes(p.id)
                    ? LabelColor.Blue
                    : LabelColor.Grey
                }
              >
                {p.numItems || 0}
              </PillLabel>
            </XTableCell>,
          ],
        };
      });

    const canCreatePortfolios = true;
    return (
      <div className={"simple-modal-form tabled"}>
        {portfolios.length === 1 && (
          <>
            <div className="no-portfolios">
              &quot;{portfolios[0].name}&quot; is currently your only portfolio.
              {canCreatePortfolios && portfolioType === PortfolioType.Domain ? (
                <>
                  {" "}
                  To start using more portfolios, select a domain on the Domains
                  screen and add it to a new portfolio.
                </>
              ) : canCreatePortfolios &&
                portfolioType === PortfolioType.Vendor ? (
                <>
                  {" "}
                  To start using more portfolios, select{" "}
                  {vendorWords.singularIndefiniteArticle} on the{" "}
                  {vendorWords.vendorsPageTitle} screen and add it to a new
                  portfolio.
                </>
              ) : undefined}
            </div>
          </>
        )}
        <XTable
          className="portfolios-list"
          expandableRows={false}
          loading={portfolios.length == 0}
          selectable={true}
          onSelectNoneClick={selectNone}
          onSelectAllClick={selectAll}
          onSelectToggle={(all: boolean) => {
            if (all) {
              selectAll();
            } else {
              selectNone();
            }
          }}
          onSelectClick={(rowId: number | string) => {
            onSelectPortfolio(rowId as number);
          }}
          columnHeaders={[
            {
              id: "name",
              text: "Portfolio",
            },
            {
              id: "vendors",
              text:
                portfolioType === PortfolioType.Domain
                  ? "Assets"
                  : vendorWords.pluralTitleCase,
            },
          ]}
          rows={getPortfolioRows()}
        />
      </div>
    );
  };

  const riskAssessmentsOptionsPage = () => {
    const getPortfolioRows = (): IXTableRow[] =>
      (vendorPortfolios ?? []).map((p) => {
        const checked = selectedVendorPortfolioIds.includes(p.id);
        return {
          id: p.id,
          expandDisabled: true,
          selected: checked,
          onClick: () => onSelectVendorPortfolio(p.id),
          cells: [
            <XTableCell key="name" className={"portfolio-cell"}>
              {p.name}
            </XTableCell>,
            <XTableCell key="vendors" className={"vendors-cell"}>
              <PillLabel
                color={
                  selectedVendorPortfolioIds.includes(p.id)
                    ? LabelColor.Blue
                    : LabelColor.Grey
                }
              >
                {p.numItems || 0}
              </PillLabel>
            </XTableCell>,
          ],
        };
      });

    const selectAll = () => {
      setSelectedVendorPortfolioIds((vendorPortfolios ?? []).map((p) => p.id));
    };
    const selectNone = () => {
      setSelectedVendorPortfolioIds([]);
    };

    const checkboxes = [] as ReactNode[];
    if (
      props.vendorAssessmentBreakdown &&
      props.vendorAssessmentBreakdown[AssessmentClassificationType.InProgress]
    ) {
      const selected =
        !!selectedAssessmentStatuses[AssessmentClassificationType.InProgress];
      checkboxes.push(
        <ColorCheckbox
          key={AssessmentClassificationType.InProgress}
          checked={selected}
          onClick={() =>
            setSelectedAssessmentStatuses((prevState) => ({
              ...prevState,
              [AssessmentClassificationType.InProgress]: !selected,
            }))
          }
          label={
            props.vendorAssessmentBreakdown[
              AssessmentClassificationType.InProgress
            ].title
          }
        />
      );
    }

    if (props.vendorAssessmentBreakdown) {
      const selected = !!selectedAssessmentStatuses[COMPLETED];
      checkboxes.push(
        <ColorCheckbox
          key={AssessmentClassificationType.Overdue}
          checked={selected}
          onClick={() =>
            setSelectedAssessmentStatuses((prevState) => ({
              ...prevState,
              [COMPLETED]: !selected,
            }))
          }
          label={"Completed"}
        />
      );
    }

    if (
      props.vendorAssessmentBreakdown &&
      props.vendorAssessmentBreakdown[AssessmentClassificationType.NotAssessed]
    ) {
      const selected =
        !!selectedAssessmentStatuses[AssessmentClassificationType.NotAssessed];
      checkboxes.push(
        <ColorCheckbox
          key={AssessmentClassificationType.NotAssessed}
          checked={selected}
          onClick={() =>
            setSelectedAssessmentStatuses((prevState) => ({
              ...prevState,
              [AssessmentClassificationType.NotAssessed]: !selected,
            }))
          }
          label={
            props.vendorAssessmentBreakdown[
              AssessmentClassificationType.NotAssessed
            ].title
          }
        />
      );
    }

    const canCreatePortfolios = true;
    return (
      <div className="simple-modal-form">
        <div className={"delivery-form-grid"}>
          {orgHasVendorPortfoliosEnabled && (
            <>
              <div className={"left grid-section"}>
                <h3>Select Portfolios</h3>
              </div>
              <div className={"right grid-section"}>
                {vendorPortfolios?.length === 1 && (
                  <>
                    <div className="no-portfolios">
                      &quot;{vendorPortfolios[0].name}&quot; is currently your
                      only portfolio.
                      {canCreatePortfolios && (
                        <>
                          {" "}
                          To start using more portfolios, select{" "}
                          {vendorWords.singularIndefiniteArticle} on the{" "}
                          {vendorWords.vendorsPageTitle} screen and add it to a
                          new portfolio.
                        </>
                      )}
                    </div>
                  </>
                )}
                <PaginatedTable
                  page={portfolioListPage}
                  pageSize={5}
                  onPageChange={(newPage: number) => {
                    setPortfolioListPage(newPage - 1);
                  }}
                  className="portfolios-assessment-list"
                  expandableRows={false}
                  loading={!vendorPortfolios || vendorPortfolios.length == 0}
                  selectable={true}
                  onSelectNoneClick={() => {
                    selectNone();
                  }}
                  onSelectAllClick={() => {
                    selectAll();
                  }}
                  onSelectToggle={(all: boolean) => {
                    if (all) {
                      selectAll();
                    } else {
                      selectNone();
                    }
                  }}
                  onSelectClick={(rowId: number | string) => {
                    onSelectVendorPortfolio(parseInt(String(rowId), 10));
                  }}
                  columnHeaders={
                    [
                      {
                        id: "name",
                        text: "Portfolio",
                      },
                      {
                        id: "vendors",
                        text: "Vendors",
                      },
                    ] as IXTableColumnHeader[]
                  }
                  rows={getPortfolioRows()}
                />
              </div>
            </>
          )}
          <div className={"left grid-section"}>
            <h3>Select Assessment Statuses</h3>
          </div>
          <div className={"right grid-section"}>
            {checkboxes.length > 0 ? checkboxes : <LoadingIcon />}
          </div>
          {props.supportedFilters &&
            isFilterActive(filters, props.supportedFilters) && (
              <>
                <div className={"left grid-section"}>
                  <h3>Apply other filters?</h3>
                  <p>
                    Generate a report using any other filters currently applied
                    to your vendor list.
                  </p>
                </div>
                <div className={"right grid-section"}>
                  <ColorCheckbox
                    radio
                    checked={includeFilters}
                    onClick={() => setIncludeFilters(true)}
                    label={"Yes, include filters"}
                  />
                  <ColorCheckbox
                    radio
                    checked={!includeFilters}
                    onClick={() => setIncludeFilters(false)}
                    label={"No, do not include filters"}
                  />
                </div>
              </>
            )}
        </div>
      </div>
    );
  };

  //
  // optionsPage
  // Generates a list of options, allowing the contents and format of the report to be configured. Typically resides on
  // page 2 of the modal, where appropriate. Note that some report types do not have a config page.
  //
  const optionsPage = (
    options: IOptionDef[] | undefined,
    sourceOptions: IOptionDef[] | undefined = undefined,
    sourceOptionsLoading = false,
    sourceOptionsError = false
  ) => {
    const opts = [] as any[];
    const sourceOpts = [] as any[];
    if (sourceOptions) {
      sourceOpts.push(
        <h3 key={"h32"}>
          {"Data sources"}
          {sourceOptionsLoading && <LoadingIcon size={16} />}
        </h3>
      );
    }
    const useExpanding = !!sourceOptions && sourceOptions.length > 0;
    if (options && options.length > 0) {
      if (useExpanding) {
        opts.push(
          <div
            key="header"
            className={classnames("expando-h3", { pointer: useExpanding })}
          >
            <h3
              key={"h31"}
              onClick={() =>
                useExpanding ? toggleInclusionsExpanded() : noop()
              }
            >
              Report inclusions{" "}
            </h3>
            {useExpanding && (
              <Icon
                name="chevron"
                direction={inclusionsExpanded ? 0 : 180}
                onClick={() => toggleInclusionsExpanded()}
              />
            )}
          </div>
        );
      }
      if (!useExpanding || inclusionsExpanded) {
        options.map((o) => {
          const subOpts = [] as any[];
          if (o.subOptions && o.subOptions.length > 0) {
            o.subOptions.map((s) => {
              subOpts.push(
                <ColorCheckbox
                  radio
                  key={s.name}
                  checked={!s.disabled && getOptionSelected(s.name)}
                  onClick={() => {
                    toggleOption(
                      s.name,
                      false,
                      o.requiredDataSources,
                      o.subOptions,
                      true
                    );
                  }}
                  label={s.label}
                  disabled={s.disabled}
                />
              );
            });
          }
          opts.push(
            <div className={"option"} key={o.name + "_o"}>
              <ColorCheckbox
                color={"blue"}
                key={o.name}
                checked={o.disabled ? false : getOptionSelected(o.name, true)}
                onClick={() => {
                  toggleOption(o.name, true, o.requiredDataSources);
                }}
                disabled={o.disabled}
              />
              <div className={"label"}>
                <div
                  className={"major"}
                  key={o.name + "_oma"}
                  onClick={() => {
                    toggleOption(o.name, true, o.requiredDataSources);
                  }}
                >
                  {o.label}
                </div>
                <div
                  key={o.name + "_omi"}
                  className={"minor"}
                  onClick={() => {
                    toggleOption(o.name, true, o.requiredDataSources);
                  }}
                >
                  {o.subtext}
                </div>
                <div className={"subs"} key={o.name + "_os"}>
                  {subOpts}
                </div>
              </div>
            </div>
          );
        });
      }
    }
    if (sourceOptions) {
      sourceOptions.map((o) => {
        sourceOpts.push(
          <div className={"option"} key={o.name + "_o"}>
            <ColorCheckbox
              color={"blue"}
              key={o.name}
              checked={o.disabled ? false : getOptionSelected(o.name, true)}
              disabled={o.disabled}
              onClick={() => {
                toggleOption(o.name, true, o.requiredDataSources);
              }}
            />
            <div
              className={"label"}
              onClick={() => {
                toggleOption(o.name, true, o.requiredDataSources);
              }}
            >
              <div className={classnames("major", { greyed: o.disabled })}>
                {o.label}
              </div>
              <div className={classnames("minor", { greyed: o.disabled })}>
                {o.subtext}
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <>
        {sourceOptions && sourceOptions.length > 0 && !sourceOptionsError && (
          <div className="simple-modal-form padded">{sourceOpts}</div>
        )}
        {sourceOptions && sourceOptions.length > 0 && sourceOptionsError && (
          <div className="simple-modal-form padded">
            <h3 key={"h32"}>{"Data sources"}</h3>
            <EmptyCardWithAction
              emptyText={
                "We failed to retrieve the details for your selected vendor"
              }
              emptySubText={
                "These details are required to determine which data sources are valid for this vendor"
              }
              actionButtonText={"Retry"}
              onActionClick={async () => {
                await fetchVendorSummary(true);
              }}
              actionButtonIconClass={"redo"}
            />
          </div>
        )}
        {options && options.length > 0 && (
          <>
            <div className={"simple-modal-form"}>{opts}</div>
          </>
        )}
      </>
    );
  };

  //
  // deliveryPage
  // Generates the contents of the last page in the modal, providing the destination options for the report including
  // asynchronous scheduling data.
  // @see page2(), page3()
  //
  const deliveryPage = () => {
    const selectedEmails = emailRecipients.map((e) =>
      e.unsubscribed || e.unsubscribedAll ? (
        <SidePopupV2
          className={"unsubscribed popup"}
          position={"top"}
          key={e.emailAddress}
          text={
            <>
              <h3>This recipient has unsubscribed</h3>
              <div>
                {`${e.emailAddress} has unsubscribed from `}
                {e.unsubscribedAll ? `all reports ` : `these emails `}
                {`and will not receive this reports export until they resubscribe.`}
              </div>
            </>
          }
        >
          <PillLabel
            key={e.emailAddress}
            color={LabelColor.Red}
            removeable
            large
            constrained
            capitalized={false}
            onRemoveClick={() => onRemoveEmail(e)}
          >
            {e.emailAddress}
          </PillLabel>
        </SidePopupV2>
      ) : (
        <PillLabel
          key={e.emailAddress}
          color={LabelColor.Blue}
          removeable
          large
          constrained
          capitalized={false}
          onRemoveClick={() => onRemoveEmail(e)}
        >
          {e.emailAddress}
        </PillLabel>
      )
    );

    return (
      <div className="simple-modal-form">
        <div className={"delivery-form-grid"}>
          <div className={"left grid-section"}>
            <h3>Frequency</h3>
            <p>How often do you want to receive this report?</p>
          </div>
          <div className={"right grid-section"}>
            <ColorCheckbox
              radio
              checked={!scheduleExport}
              onClick={() => setScheduleExport(false)}
              label={"Generate once"}
            />
            <ColorCheckbox
              radio
              checked={scheduleExport}
              onClick={() => setScheduleExport(true)}
              label={"Set up recurring report generation"}
            />
            {scheduleExport && (
              <ScheduleFrequencyPicker
                interval={scheduleInterval}
                onChangeInterval={(interval) => setScheduleInterval(interval)}
                dayOfWeek={dayOfWeek}
                onChangeDayOfWeek={(day) => setDayOfWeek(day)}
                date={selectedDate}
                onChangeDate={(date) => setSelectedDate(date)}
                time={selectedTime}
                onChangeTime={(time) => setSelectedTime(time)}
              />
            )}
          </div>
          <div className={"left grid-section"}>
            <h3>Delivery</h3>
            <p>
              How would you like to receive this{" "}
              {scheduleExport && "recurring "}report?
            </p>
          </div>
          <div className={"right grid-section"}>
            <ColorCheckbox
              radio
              checked={!emailReport}
              onClick={() => setEmailReport(false)}
              label={"Save to reports only"}
            />
            <ColorCheckbox
              radio
              checked={emailReport}
              onClick={() => setEmailReport(true)}
              label={"Send report via email and save to reports"}
            />
          </div>
          {emailReport && (
            <>
              <div className={"left grid-section"}>
                <h3>Email recipients</h3>
                <p>Type to search or add in a new email recipient.</p>
              </div>
              <div className={"right grid-section"}>
                <CreatableV2
                  className={"email-select"}
                  isLoading={orgUserEmailAddresses.loading}
                  options={orgUserEmailAddresses.data?.map((e) => ({
                    label: e.emailAddress,
                    value: e.emailAddress,
                  }))}
                  value={emailRecipients.map((e) => ({
                    value: e.emailAddress,
                    label: e.emailAddress,
                  }))}
                  isClearable
                  isMulti
                  isSearchable
                  controlShouldRenderValue={false}
                  onChange={onSelectEmail}
                  isValidNewOption={validateEmail}
                  placeholder={"Enter an email address"}
                />
                <div className={"label-list"}> {selectedEmails} </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  //
  // getCurrentPage
  // Given the current page stored in component state, this function renders the page. What gets rendered is dependent on
  // the page number and the report type selected.
  // @see getPageType()
  //
  const getCurrentPage = (vendorWords: IVendorWords) => {
    const title = getPageType(
      selectedReport,
      selectedSubReport,
      !!vendorId,
      page
    );
    switch (title) {
      case PAGETYPETITLE_SELECT:
        return (
          <div className="simple-modal-form">
            {reportTypeSelectionButtons(
              mode == ExecutiveReportingMode.VendorRiskReporting
                ? getVendorReportButtons(vendorWords)
                : mode == ExecutiveReportingMode.PortfolioReporting
                  ? getVendorPortfolioReportButtons()
                  : []
            )}
          </div>
        );
      case PAGETYPETITLE_SELECTVENDOR:
        return vendorSelectionPage(false);
      case PAGETYPETITLE_SELECTASSESSEDVENDOR:
        return vendorSelectionPage(true);
      case PAGETYPETITLE_SELECTCOMPARISONVENDORS:
        return vendorComparisonSelectionPage();
      case PAGETYPETITLE_SELECTVENDORPORTFOLIOS:
        return portfolioSelectionPage(PortfolioType.Vendor);
      case PAGETYPETITLE_SELECTDOMAINPORTFOLIOS:
        return portfolioSelectionPage(PortfolioType.Domain);
      case PAGETYPETITLE_RISKASSESSMENTOPTIONS:
        return riskAssessmentsOptionsPage();
      case PAGETYPETITLE_CUSTOMIZE:
        const { opts, loading, error } = getReportDataSourceOptions(
          selectedReport,
          selectedSubReport
        );
        return optionsPage(
          getReportUserOptions(selectedReport, selectedSubReport),
          opts,
          loading,
          error
        );
      case PAGETYPETITLE_DELIVERY:
        return deliveryPage();
    }
    return <div />;
  };

  //
  // getCurrentTitle
  // Given the current page stored in component state, this function determines what the title of that page should be. In most
  // cases the title is the string stored as the unique identifier of the page type.
  // @see getPageType()
  //
  const getCurrentTitle = (): string => {
    const pageType = getPageType(
      selectedReport,
      selectedSubReport,
      !!vendorId,
      page
    );
    switch (pageType) {
      case PAGETYPETITLE_RISKASSESSMENTOPTIONS:
        return "Customize Report";
      default:
        return pageType;
    }
    return "";
  };

  //
  // getPageType
  // Given a report type and a page number, this function determines whether the current selected report type has a page at that index or not,
  // and what the type of that page might be. Basically we model the structure of pages for each supported report type. You can note that the
  // string identifying the type of the page also doubles as the page's title.
  //
  // A note about the 'Generic' report types - GenericVendorReport and GenericBreachsightReport.
  // These are the report types you ask for when you want the user to decide between levels of detail. Each of these begins with a
  // report-type selection step. Once this step is completed, the reportType becomes set and we dont come back to the generic page set.
  //
  const getPageType = (
    baseReportType: ExecutiveReportType,
    subReportType: ExecutiveReportType,
    _vendorIdPreselected: boolean,
    page: number
  ): string => {
    if (baseReportType == ExecutiveReportType.Unknown) {
      return "";
    }
    const page0I = page - 1;

    try {
      switch (baseReportType) {
        case ExecutiveReportType.VendorRiskExecSummaryReport:
          return [
            ...(orgHasVendorPortfoliosEnabled
              ? [PAGETYPETITLE_SELECTVENDORPORTFOLIOS]
              : []),
            PAGETYPETITLE_CUSTOMIZE,
            PAGETYPETITLE_DELIVERY,
          ][page0I];
        case ExecutiveReportType.VendorComparisonReport:
          return [
            PAGETYPETITLE_SELECTCOMPARISONVENDORS,
            PAGETYPETITLE_DELIVERY,
          ][page0I];
        case ExecutiveReportType.RiskAssessmentSummary:
          return [PAGETYPETITLE_RISKASSESSMENTOPTIONS, PAGETYPETITLE_DELIVERY][
            page0I
          ];
        case ExecutiveReportType.PortfolioReport:
          if (subReportType == ExecutiveReportType.RiskAssessmentSummary) {
            return [
              PAGETYPETITLE_SELECT,
              PAGETYPETITLE_RISKASSESSMENTOPTIONS,
              PAGETYPETITLE_DELIVERY,
            ][page0I];
          } else {
            // comparison report
            return [
              PAGETYPETITLE_SELECT,
              PAGETYPETITLE_SELECTCOMPARISONVENDORS,
              PAGETYPETITLE_DELIVERY,
            ][page0I];
          }
      }
    } catch (e) {
      return "";
    }
    return "";
  };

  const vendorizeTitle = (
    pageTitle: string,
    vendorWords: IVendorWords
  ): string => {
    switch (pageTitle) {
      case PAGETYPETITLE_SELECTASSESSEDVENDOR:
        return `Select Assessed ${vendorWords.singularTitleCase}`;
      case PAGETYPETITLE_SELECTVENDOR:
        return `Select ${vendorWords.singularTitleCase}`;
      case PAGETYPETITLE_SELECTCOMPARISONVENDORS:
        return `Select ${vendorWords.pluralTitleCase} for Comparison`;
      default:
        return pageTitle;
    }
  };

  //
  // hasPage
  // Wraps the pageTitle function to determine whether the currently selected report type has page x in its page sequence.
  // @see getPageType()
  //
  const hasPage = (page: number): boolean => {
    const pageName = getPageType(
      selectedReport,
      selectedSubReport,
      !!vendorId,
      page
    );
    return !!pageName;
  };

  const clearStateOnClose = () => {
    setPage(1);
    setVendorFilterText("");
    setSelectedVendorId(0);
    setMultiSelectedVendorIds([]);
    setSelectedReport(ExecutiveReportType.Unknown);
    setSelectedSubReport(ExecutiveReportType.Unknown);
    setInclusionsExpanded(true);
    setScheduleExport(false);
    setScheduleInterval("m");
    setEmailReport(false);
    setSelectedOptions({
      show_risks: true,
    } as {
      [name: string]: boolean;
    });
    setEmailRecipients([] as DisplayableScheduledReportRecipient[]);
  };

  const titleText = getCurrentTitle();
  const { passed, msg } = validatePageInputs(vendorWords);
  return (
    <ModalV2
      className={"vendor-summary-report-modal"}
      active={active}
      onClose={() => {
        onClose();
        clearStateOnClose();
      }}
      headerContent={vendorizeTitle(titleText, vendorWords)}
      footerContent={
        <div className={"vendor-summary-report-modal-footer"}>
          <div className="btn-group left modal-buttons">
            {page > 1 && !loading && (
              <Button onClick={() => setPage(page - 1)}>
                <Icon id="rarrow" name="arrow" direction={270} />
                {"Previous"}
              </Button>
            )}
            {loading && (
              <span className={"progress-message"}>Generating report...</span>
            )}
          </div>
          <div className="btn-group modal-buttons">
            <>
              <SidePopupV2
                className={"validation-tip"}
                text={msg}
                position={"top"}
                width={200}
              >
                {hasPage(page + 1) && (
                  <Button
                    className={"next"}
                    disabled={!passed}
                    filledPrimary
                    onClick={async () => {
                      setPage(page + 1);
                    }}
                  >
                    Next <Icon name="arrow" direction={90} />
                  </Button>
                )}
                {!hasPage(page + 1) && (
                  <Button
                    disabled={!passed}
                    filledPrimary
                    loading={loading}
                    onClick={async () => {
                      setLoading(true);
                      await onSubmitReport();
                      onClose();
                      clearStateOnClose();
                    }}
                  >
                    Generate
                  </Button>
                )}
              </SidePopupV2>
            </>
          </div>
        </div>
      }
    >
      {getCurrentPage(vendorWords)}
    </ModalV2>
  );
};

export default appConnect<
  IExecutiveReportingModalConnectProps,
  never,
  IExecutiveReportingModalOwnProps
>((state, props) => {
  let vendorSummaryDataLoaded = false;
  let publishedRiskAssessment = null;
  let vendorHasValidQuestionnaires = false;
  let vendorHasValidAdditionalEvidence = false;
  let vendorHasEvidencePages = false;
  let vendorHasVendorRiskWaivers = false;
  let vendorHasWebPresence = false;
  let vendorHasTooManyDomains = false;

  const userSystemRoles = state.common.userData.system_roles.reduce(
    (prev: Record<string, true | undefined>, perm) => {
      prev[perm] = true;
      return prev;
    },
    {}
  );

  const userIsManagedVendorAnalyst =
    !!userSystemRoles[UserSystemRoleVendorManagementAnalyst];

  const isManagedVendorAnalyst =
    userIsManagedVendorAnalyst &&
    props.isManagementAnalystSession &&
    props.managedOrgId &&
    props.managedOrgId > 0;

  let orgPerms;
  if (isManagedVendorAnalyst) {
    const managedOrgEntitlements = _get(
      state.cyberRisk.managedVendorData,
      `[${props.managedOrgId}].entitlements`,
      []
    );
    orgPerms = managedOrgEntitlements
      ? managedOrgEntitlements.reduce(
          (prev: Record<string, true | undefined>, perm: string) => {
            prev[perm] = true;
            return prev;
          },
          {}
        )
      : [];
  } else {
    orgPerms = state.common.userData.orgPermissions
      ? state.common.userData.orgPermissions.reduce(
          (prev: Record<string, true | undefined>, perm) => {
            prev[perm] = true;
            return prev;
          },
          {}
        )
      : [];
  }

  if (props.vendorId && props.vendorId > 0) {
    let vendorSummary;
    if (isManagedVendorAnalyst) {
      vendorSummary = _get(
        state.cyberRisk.managedVendorData,
        `[${props.managedOrgId}][${props.vendorId}].summary.result`,
        null
      );
    } else {
      vendorSummary = _get(
        state.cyberRisk.vendors[props.vendorId],
        ["summary", "result"],
        null
      );
    }
    const waivers = getVendorRiskWaiversFromState(state, props.vendorId);

    if (vendorSummary) {
      vendorSummaryDataLoaded = true;
      if (
        vendorSummary.assessmentSummary &&
        vendorSummary.assessmentSummary.publishedAt
      ) {
        publishedRiskAssessment = vendorSummary.assessmentSummary;
      }

      vendorHasValidQuestionnaires = vendorHasValidQuestionnairesForExport(
        vendorSummary.mostRecentQuestionnaire?.counts
      );

      if (
        vendorSummary.categorySummaries &&
        !!vendorSummary.categorySummaries[
          factCategories.AdditionalEvidenceRisks
        ]
      ) {
        vendorHasValidAdditionalEvidence = true;
      }

      if (vendorSummary.evidencePages) {
        vendorHasEvidencePages = true;
      }

      if (waivers && waivers.length > 0) {
        vendorHasVendorRiskWaivers = true;
      }

      if (
        vendorSummary.totalCloudscans > 0 ||
        vendorSummary.totalInactiveDomains > 0
      ) {
        vendorHasWebPresence = true;
      }

      vendorHasTooManyDomains = vendorSummary.totalCloudscansOverExportMax;
    }
  }

  const vendorPortfolios = state.cyberRisk.vendorPortfolios?.portfolios;
  const domainPortfolios = state.cyberRisk.domainPortfolios?.portfolios;

  const preSelectedAssessmentStates = {} as {
    [status: number]: boolean;
  };
  preSelectedAssessmentStates[AssessmentClassificationType.InProgress] = true;
  preSelectedAssessmentStates[AssessmentClassificationType.NotAssessed] = true;
  preSelectedAssessmentStates[COMPLETED] = true;

  const vendorAssessmentBreakdown =
    state.cyberRisk.vendorAssessmentClassifications;

  const orgHasVendorPortfoliosEnabled = !!orgPerms[OrgAccessVendorPortfolios];

  const userHasVendorRisk =
    state.common.userData.userPermissions.includes(UserVendorRiskEnabled) ||
    (orgHasVendorPortfoliosEnabled &&
      Object.keys(state.common.userData.vendorPortfolioSpecificPermissions)
        .length > 0);

  const connectedProps: IExecutiveReportingModalConnectProps = {
    filters: state.cyberRisk.customerData.filters,
    orgUserEmailAddresses: state.cyberRisk.orgUserEmailAddresses,
    assuranceType: state.common.userData.assuranceType,
    vendorSummaryDataLoaded,
    publishedRiskAssessment,
    orgHasVendorRiskEnabled: !!orgPerms[OrgAccessVendors],
    orgHasAssessmentsEnabled: !!orgPerms[OrgAccessVendorAssessments],
    orgHasVendorPortfoliosEnabled,
    userHasVendorRisk,
    vendorHasValidQuestionnaires,
    vendorHasValidAdditionalEvidence,
    vendorHasEvidencePages,
    vendorHasVendorRiskWaivers,
    vendorHasWebPresence,
    vendorHasTooManyDomains,
    vendorPortfolios,
    domainPortfolios,
    vendorAssessmentBreakdown,
    preSelectedAssessmentStates,
  };

  return connectedProps;
})(ExecutiveReportingModal);
