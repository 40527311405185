import { Component } from "react";
import PropTypes from "prop-types";

import Button from "../../../_common/components/core/Button";
import { closeModal } from "../../../_common/reducers/commonActions";
import {
  createNewRequest,
  createNewRequestGroup,
  fetchScratchRequestGroupsList,
} from "../../reducers/actions";

import ColorCheckbox from "../../../vendorrisk/components/ColorCheckbox";

import "../../style/GetFindingsModal.scss";
import {
  addDefaultSuccessAlert,
  addDefaultUnknownErrorAlert,
  addSimpleErrorAlert,
} from "../../../_common/reducers/messageAlerts.actions";

export const GetFindingsModalName = "GetFindingsModalName";

const searchTypes = [
  { id: "github", name: "GitHub" },
  { id: "s3", name: "S3" },
  { id: "gcs", name: "GCS" },
];

class GetFindingsModal extends Component {
  static propTypes = {
    modalData: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    let termsObj = {};
    if (props.modalData.terms) {
      termsObj = props.modalData.terms.reduce((prev, cur) => {
        return {
          ...prev,
          [cur.name]: cur.terms,
        };
      }, {});
    }

    const selectedSearchTypes = {};
    const keywords = {};
    for (let i = 0; i < searchTypes.length; i++) {
      const searchTypeId = searchTypes[i].id.toLowerCase();
      if (termsObj[searchTypeId]) {
        keywords[searchTypeId] = termsObj[searchTypeId];
        selectedSearchTypes[searchTypeId] = true;
      } else {
        keywords[searchTypeId] = "";
        selectedSearchTypes[searchTypeId] = false;
      }
    }

    this.state = {
      selectedSearchTypes,
      keywords,
      loading: false,
      name: "",
    };
  }

  onSubmit = () => {
    const searchTypesAndKeywords = {};
    let hasSearchTypes = false;

    for (let i = 0; i < searchTypes.length; i++) {
      const { id, name } = searchTypes[i];
      if (this.state.selectedSearchTypes[id] === true) {
        hasSearchTypes = true;
        const keywords = this.state.keywords[id];
        if (!keywords) {
          // Validation error
          this.props.dispatch(
            addSimpleErrorAlert(
              `${name} was selected but no keywords were added`
            )
          );
          return;
        }

        // Split the keywords into an array and trim whitespace
        const keywordsArr = keywords.split(",");
        for (let j = 0; j < keywordsArr.length; j++) {
          keywordsArr[j] = keywordsArr[j].trim();
        }

        searchTypesAndKeywords[id] = keywordsArr;
      }
    }

    if (
      this.props.modalData.scratchAnalystId &&
      this.props.modalData.scratchAnalystId > 0 &&
      (!this.props.modalData.groupId || this.props.modalData.groupId == 0)
    ) {
      if (this.state.name == "") {
        // Validation error
        this.props.dispatch(
          addSimpleErrorAlert("Please set a name for your search")
        );
        return;
      }
    }

    if (!hasSearchTypes) {
      this.props.dispatch(addSimpleErrorAlert("No search types were selected"));
      return;
    }

    this.setState({ loading: true });
    if (this.props.modalData.groupId && this.props.modalData.groupId > 0) {
      // TODO: create a new request and assign it to the existing group
      this.props
        .dispatch(
          createNewRequest(
            this.props.modalData.orgId,
            this.props.modalData.groupId,
            searchTypesAndKeywords
          )
        )
        .then(() => {
          this.props.dispatch(
            addDefaultSuccessAlert("Successfully initiated search")
          );
          if (
            this.props.modalData.scratchAnalystId &&
            this.props.modalData.scratchAnalystId > 0
          ) {
            this.props.dispatch(fetchScratchRequestGroupsList(true, false));
          }
          this.props.dispatch(closeModal());
        })
        .catch(() => {
          this.props.dispatch(
            addDefaultUnknownErrorAlert("Error initiating search")
          );
          this.setState({ loading: false });
        });
    } else if (
      this.props.modalData.scratchAnalystId &&
      this.props.modalData.scratchAnalystId > 0
    ) {
      this.props
        .dispatch(
          createNewRequestGroup(
            this.state.name,
            this.props.modalData.scratchAnalystId,
            searchTypesAndKeywords
          )
        )
        .then(() => {
          this.props.dispatch(
            addDefaultSuccessAlert("Successfully initiated search")
          );
          if (
            this.props.modalData.scratchAnalystId &&
            this.props.modalData.scratchAnalystId > 0
          ) {
            this.props.dispatch(fetchScratchRequestGroupsList(true, false));
          }
          this.props.dispatch(closeModal());
        })
        .catch(() => {
          this.props.dispatch(
            addDefaultUnknownErrorAlert("Error initiating search")
          );
          this.setState({ loading: false });
        });
    } else {
      this.props
        .dispatch(
          createNewRequest(
            this.props.modalData.orgId,
            0,
            searchTypesAndKeywords
          )
        )
        .then(() => {
          this.props.dispatch(
            addDefaultSuccessAlert("Successfully initiated search")
          );
          if (
            this.props.modalData.scratchAnalystId &&
            this.props.modalData.scratchAnalystId > 0
          ) {
            this.props.dispatch(fetchScratchRequestGroupsList(true, false));
          }
          this.props.dispatch(closeModal());
        })
        .catch(() => {
          this.props.dispatch(
            addDefaultUnknownErrorAlert("Error initiating search")
          );
          this.setState({ loading: false });
        });
    }
  };

  toggleSearchType = (searchTypeId) =>
    this.setState((state) => ({
      ...state,
      selectedSearchTypes: {
        ...state.selectedSearchTypes,
        [searchTypeId]: !state.selectedSearchTypes[searchTypeId],
      },
    }));

  changeSearchTypeKeywords = (searchTypeId, keywords) =>
    this.setState((state) => ({
      ...state,
      keywords: {
        ...state.keywords,
        [searchTypeId]: keywords,
      },
    }));

  onChangeSearchName = (name) => this.setState((state) => ({ ...state, name }));

  render() {
    let mode = "UNKNONWN";
    if (this.props.modalData.groupId && this.props.modalData.groupId > 0) {
      mode = "EXTEND";
    } else if (
      !(this.props.modalData.groupId && this.props.modalData.groupId > 0) &&
      this.props.modalData.scratchAnalystId &&
      this.props.modalData.scratchAnalystId > 0
    ) {
      mode = "NEWGROUP";
    } else {
      mode = "NEW";
    }

    return (
      <div className="modal-content get-findings-modal">
        <h2>
          {mode == "EXTEND" && <span>Extend your Exploratory Search</span>}
          {mode == "NEWGROUP" && <span>New Exploratory Search</span>}
          {mode == "NEW" && <span>Get more findings</span>}
        </h2>
        {mode == "NEWGROUP" && (
          <div className="search-name">
            <label htmlFor="searchName">Name your search:</label>
            <input
              type="text"
              name="searchName"
              placeholder="Search Name"
              required
              ref={(ref) => (this.searchName = ref)}
              onChange={(evt) => this.onChangeSearchName(evt.target.value)}
              value={this.state.name}
            />
          </div>
        )}
        {searchTypes.map((searchType) => (
          <div className="search-type-keywords" key={searchType.id}>
            <ColorCheckbox
              color="darkgrey"
              checked={this.state.selectedSearchTypes[searchType.id]}
              onClick={() => this.toggleSearchType(searchType.id)}
              label={searchType.name}
            />
            <input
              type="text"
              name={searchType.id}
              value={this.state.keywords[searchType.id]}
              disabled={!this.state.selectedSearchTypes[searchType.id]}
              placeholder="Enter comma-separated search keywords..."
              onChange={(evt) =>
                this.changeSearchTypeKeywords(searchType.id, evt.target.value)
              }
            />
          </div>
        ))}
        <Button primary onClick={this.onSubmit} loading={this.state.loading}>
          Initiate search
        </Button>
      </div>
    );
  }
}

export default GetFindingsModal;
