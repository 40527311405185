import PageHeader from "../../_common/components/PageHeader";
import SearchBox from "../../_common/components/SearchBox";
import ReportCard from "../../_common/components/ReportCard";
import { useDefaultHistory } from "../../_common/types/router";
import XTable, {
  SortDirection,
  XTableCell,
  IXTableColumnHeader,
} from "../../_common/components/core/XTable";
import { useSortingWithPagination } from "../../_common/hooks";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";
import CyberTrendScore from "../../vendorrisk/components/CyberTrendScore";

import { UserWithStats } from "../api/types";
import UserBaseAPI from "../api/userbase.api";
import "./UsersView.scss";

import { FC, useState } from "react";
import TeamsPills from "../components/TeamsPills";
import { userUrl } from "../UserBaseAppRouter";
import { useLocation } from "react-router-dom";
import { SingleStatSize } from "../components/SingleStat";
import DashboardStat, {
  DashboardStatToShow,
} from "../components/DashboardStat";

const PAGE_SIZE = 100;

const NAME_COL = "name_col";
const ROLE_COL = "role_col";
const TEAM_COL = "team_col";
const SECURITY_RATING_COL = "security_rating_col";
const TREND_COL = "trend_col";
const TOTAL_APPS_COL = "total_apps_col";
const NOT_APPROVED_APPS_COL = "not_approved_apps_col";
const WAIVED_APPS_COL = "waived_apps_col";

const UsersView: FC = () => {
  const history = useDefaultHistory();
  const { pathname } = useLocation();

  const [searchText, setSearchText] = useState<string>("");

  const { data, isLoading } = UserBaseAPI.useGetUserBaseUsersV1Query({});

  // Get the users to display from the API
  const usersToUse = data?.users ?? [];

  // Filter the users by the search text
  const bySearchText = (user: UserWithStats): boolean => {
    if (searchText.length == 0) {
      return true;
    }

    const toFind = searchText.toLocaleLowerCase();

    if (
      user.roles &&
      user.roles.some((r) => r.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    if (
      user.teams &&
      user.teams.some((t) => t.toLocaleLowerCase().includes(toFind))
    ) {
      return true;
    }

    return user.name.toLocaleLowerCase().indexOf(toFind) >= 0;
  };

  const filteredUsers = usersToUse.filter(bySearchText);

  // Sort and Paginate the users
  const [
    usersToDisplay,
    sortedBy,
    onSortChange,
    currentPage,
    totalPages,
    onPageChange,
  ] = useSortingWithPagination<
    UserWithStats,
    | typeof NAME_COL
    | typeof ROLE_COL
    | typeof TEAM_COL
    | typeof SECURITY_RATING_COL
    | typeof TOTAL_APPS_COL
    | typeof NOT_APPROVED_APPS_COL
    | typeof WAIVED_APPS_COL
    | typeof TREND_COL
  >(
    filteredUsers,
    NAME_COL,
    SortDirection.ASC,
    {
      [NAME_COL]: {
        orderFuncs: [(u) => u.name.toLocaleLowerCase()],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [ROLE_COL]: {
        orderFuncs: [
          (u) =>
            (u.roles ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TEAM_COL]: {
        orderFuncs: [
          (u) =>
            (u.teams ?? [])
              .map((t) => t.toLocaleLowerCase())
              .sort((a, b) => a.localeCompare(b))
              .join(","),
        ],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [SECURITY_RATING_COL]: {
        orderFuncs: [(u) => u.adjustedRating],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TOTAL_APPS_COL]: {
        orderFuncs: [(u) => u.totalApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [NOT_APPROVED_APPS_COL]: {
        orderFuncs: [(u) => u.notApprovedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [WAIVED_APPS_COL]: {
        orderFuncs: [(u) => u.waivedApps],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
      [TREND_COL]: {
        orderFuncs: [(u) => u.trend],
        sortDirsAsc: [SortDirection.ASC],
        sortDirsDesc: [SortDirection.DESC],
      },
    },
    PAGE_SIZE,
    undefined,
    pathname
  );

  const columnHeaders: IXTableColumnHeader[] = [
    { id: NAME_COL, text: "Name", sortable: true },
    { id: ROLE_COL, text: "Role", sortable: true },
    { id: TEAM_COL, text: "Team", sortable: true },
    { id: SECURITY_RATING_COL, text: "Security rating", sortable: true },
    { id: TREND_COL, text: "Trend", sortable: true },
    { id: TOTAL_APPS_COL, text: "Total apps", sortable: true },
    {
      id: NOT_APPROVED_APPS_COL,
      text: "Not approved apps",
      sortable: true,
    },
    {
      id: WAIVED_APPS_COL,
      text: "Waived apps",
      sortable: true,
    },
  ];

  const rows = usersToDisplay.map((u) => {
    return {
      id: u.uuid,
      onClick: () => {
        history.push(userUrl(u.uuid));
      },
      cells: [
        <XTableCell key={NAME_COL}>{u.name}</XTableCell>,
        <XTableCell key={ROLE_COL}>{u.roles ? u.roles[0] : ""}</XTableCell>,
        <XTableCell key={TEAM_COL}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={SECURITY_RATING_COL}>
          <div className="score-container">
            <ColorGrade score={u.adjustedRating} size={ColorGradeSize.Small} />
            <Score score={u.adjustedRating} small />
          </div>
        </XTableCell>,
        <XTableCell key={TREND_COL}>
          <div className="cyber-trend">
            <CyberTrendScore trend={u.trend} />
          </div>
        </XTableCell>,
        <XTableCell key={TOTAL_APPS_COL}>{u.totalApps}</XTableCell>,
        <XTableCell key={NOT_APPROVED_APPS_COL}>
          {u.notApprovedApps}
        </XTableCell>,
        <XTableCell key={WAIVED_APPS_COL}>{u.waivedApps}</XTableCell>,
      ],
    };
  });

  return (
    <div className="userbase-users">
      <PageHeader
        history={history}
        title={
          <div className="title-and-count">
            Users
            <DashboardStat
              statToShow={DashboardStatToShow.UserCount}
              statSize={SingleStatSize.Small}
            />
          </div>
        }
        infoSection={
          <>
            <p>
              Users shows all the users associated with your organization. Each
              user is given a security rating based on the user-related
              activities we have scanned. To get more information about a
              particular user, click on the user.
            </p>
          </>
        }
      />

      <ReportCard newStyles>
        <div className={"table-filters"}>
          <SearchBox
            placeholder={"Search users"}
            onChanged={(q) => {
              onPageChange(1);
              setSearchText(q);
            }}
            value={searchText}
          />
        </div>

        <XTable
          className={"userbase-users-list"}
          loading={isLoading}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          iconOptions
          pagination={{
            currentPage: currentPage,
            totalPages: totalPages,
            onPageChange: onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          columnHeaders={columnHeaders}
          rows={rows}
        />
      </ReportCard>
    </div>
  );
};

export default UsersView;
