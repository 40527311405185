import { matchPath, useHistory } from "react-router-dom";
import classnames from "classnames";
import { getRiskProfileDisplayPrefs } from "../../../vendorrisk/helpers/riskProfile.helpers";
import { useCurrentOrg } from "../../selectors/commonSelectors";
import { useAppSelector } from "../../types/reduxHooks";
import NavLink, { NewFeatureInAppMessagePopupContent } from "../NavLink";
import Icon from "../core/Icon";
import * as Permissions from "../../permissions";
import { pageDescriptions } from "../../pageDescriptions";
import {
  AssuranceType,
  organisationAccountType,
} from "../../types/organisations";
import { InAppMessageType } from "../InAppMessage";
import { domainPortfolioHelpURL } from "../../../vendorrisk/components/portfolios/helpers";
import { useBaseVendorData } from "../../selectors/vendorSelectors";
import { get as _get } from "lodash";
import { getSubtitleDisplay } from "../../helpers/string.helpers";

type BreachSightNavItemsProps = {
  trialExpired: boolean;
  clickNavLinkFunc: (
    path: string,
    linkName: string,
    viewingVendorId?: string
  ) => () => void;
};

const getSubsidiaryVendorIdFromPath = (pathname: string) => {
  const isOnSubsidiaryRouteMatch = matchPath<{ vendorId: string }>(pathname, {
    path: "/subsidiaries/:vendorId",
  });
  if (isOnSubsidiaryRouteMatch) {
    return parseInt(isOnSubsidiaryRouteMatch.params.vendorId);
  }
  return undefined;
};

const BreachSightNavItems = (props: BreachSightNavItemsProps) => {
  const history = useHistory();
  const currentOrg = useCurrentOrg();
  const userData = useAppSelector((state) => state.common.userData);

  const hasUserPermission = (perm: string) =>
    userData.userPermissions.includes(perm);
  const hasOrgPermission = (perm: string) =>
    userData.orgPermissions.includes(perm);

  const currentOrgHasSubsidiaries = !!currentOrg?.hasSubsidiaries;
  const currentOrgIsFreeOrg =
    currentOrg?.accountType === organisationAccountType.free;

  // get the last visited risk profile tab so we can shoot back there
  const riskProfileDisplayPrefs = getRiskProfileDisplayPrefs(
    undefined,
    undefined
  );
  const riskProfileSubsidiariesTab =
    currentOrgHasSubsidiaries &&
    riskProfileDisplayPrefs.tab === "with_subsidiaries";

  const closeSubsidiariesMenu = (isOnSubsidiaryRoute: boolean) => {
    if (isOnSubsidiaryRoute) {
      history.push("/subsidiaries");
    }
  };
  const selectedSubsidiaryVendorId = getSubsidiaryVendorIdFromPath(
    history.location.pathname
  );
  const isOnSubsidiaryRoute = !!selectedSubsidiaryVendorId;
  const selectedSubsidiary = useBaseVendorData(
    selectedSubsidiaryVendorId,
    true
  );

  const userHasBreachsight =
    hasUserPermission(Permissions.UserBreachsightEnabled) ||
    Object.keys(userData.domainPortfolioSpecificPermissions).length > 0;

  const userHasDomainPortfolioSpecificPermissions =
    userData.domainPortfolioSpecificPermissions &&
    Object.keys(userData.domainPortfolioSpecificPermissions).length > 0;

  // items visibility
  const showExecutiveSummary =
    userHasBreachsight && hasOrgPermission(Permissions.OrgAccessCustomerInfo);
  const showRiskProfile =
    (userHasBreachsight &&
      hasOrgPermission(Permissions.OrgAccessCustomerInfo)) ||
    currentOrgIsFreeOrg;
  const showRemediation =
    userHasBreachsight &&
    userData.assuranceType === AssuranceType.None &&
    hasOrgPermission(Permissions.OrgAccessCustomerInfo);
  const showRiskWaivers =
    userHasBreachsight && hasOrgPermission(Permissions.OrgAccessCustomerInfo);
  const showDomains =
    userHasBreachsight && hasOrgPermission(Permissions.OrgAccessCustomerInfo);
  const showIPAddresses =
    userHasBreachsight && hasOrgPermission(Permissions.OrgAccessCustomerInfo);
  const showDetectedProducts =
    userHasBreachsight && hasOrgPermission(Permissions.OrgAccessCustomerInfo);
  const showSubsidiaries =
    userHasBreachsight &&
    hasOrgPermission(Permissions.OrgAccessCustomerInfo) &&
    hasOrgPermission(Permissions.OrgAccessSubsidiaries) &&
    currentOrgHasSubsidiaries &&
    !userHasDomainPortfolioSpecificPermissions;
  const showSubsidiaryMenu =
    userHasBreachsight &&
    selectedSubsidiaryVendorId &&
    (hasOrgPermission(Permissions.OrgAccessCustomerInfo) ||
      hasOrgPermission(Permissions.OrgAccessSubsidiaries));
  const showVulnerabilities =
    userHasBreachsight &&
    hasOrgPermission(Permissions.OrgAccessCustomerInfo) &&
    hasOrgPermission(Permissions.OrgAccessVulns);
  const showIdentityBreaches =
    userHasBreachsight &&
    hasOrgPermission(Permissions.OrgAccessEmailExposures) &&
    hasUserPermission(Permissions.UserEmailExposureRead);
  const showTyposquatting =
    userHasBreachsight &&
    hasOrgPermission(Permissions.OrgAccessTyposquatting) &&
    hasUserPermission(Permissions.UserTyposquattingRead);

  // section visibility
  const showSection =
    showExecutiveSummary ||
    showRiskProfile ||
    showRemediation ||
    showRiskWaivers ||
    showDomains ||
    showIPAddresses ||
    showDetectedProducts ||
    showSubsidiaries ||
    showSubsidiaryMenu ||
    showVulnerabilities ||
    showIdentityBreaches ||
    showTyposquatting;

  return (
    <>
      {showSection && <div className="subtitle">BreachSight</div>}

      {showExecutiveSummary && (
        <NavLink
          disabled={props.trialExpired}
          icon="analytics"
          text="Executive Summary"
          hoverText={pageDescriptions.ExecutiveSummaryReport}
          onClick={props.clickNavLinkFunc(
            "/summaryreport/breachsight",
            "BreachSight Executive Summary"
          )}
          to="/summaryreport/breachsight"
        />
      )}
      {showRiskProfile && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="focus"
          text="Risk Profile"
          hoverText={pageDescriptions.RiskProfile}
          onClick={props.clickNavLinkFunc(
            riskProfileSubsidiariesTab
              ? "/risk_profile/include_subsidiaries"
              : "/risk_profile",
            "Risk Profile"
          )}
          to={
            riskProfileSubsidiariesTab
              ? "/risk_profile/include_subsidiaries"
              : "/risk_profile"
          }
          isActive={(_match, location) =>
            !!matchPath(location.pathname, "/risk_profile")
          }
        />
      )}
      {showRemediation && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="spanner"
          text="Remediation"
          hoverText={pageDescriptions.SelfRemediation}
          onClick={props.clickNavLinkFunc("/selfremediation", "Remediation")}
          to="/selfremediation"
        />
      )}
      {showRiskWaivers && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="risk-waivers"
          text="Risk Waivers"
          hoverText={pageDescriptions.RiskWaivers}
          onClick={props.clickNavLinkFunc("/riskwaivers", "Risk Waivers")}
          to="/riskwaivers"
        />
      )}
      {showDomains && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          inAppMessageText={
            hasOrgPermission(Permissions.OrgAccessDomainPortfolios) ? (
              <NewFeatureInAppMessagePopupContent
                featureName="Asset Portfolios"
                description="You can now organize your domains into portfolios."
                learnMoreLink={domainPortfolioHelpURL}
              />
            ) : undefined
          }
          inAppMessageType={
            hasOrgPermission(Permissions.OrgAccessDomainPortfolios)
              ? InAppMessageType.AssetPortfoliosNewFeature
              : undefined
          }
          icon="globe"
          text="Domains"
          hoverText={pageDescriptions.WebsitesAndAPIs}
          exact
          onClick={props.clickNavLinkFunc("/webscans", "Domains")}
          to="/webscans"
        />
      )}
      {showIPAddresses && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="globe"
          text="IP Addresses"
          hoverText={pageDescriptions.IPAddresses}
          exact
          onClick={props.clickNavLinkFunc("/ips", "IP Addresses")}
          to="/ips"
        />
      )}
      {showDetectedProducts && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="radar"
          text="Detected Products"
          hoverText={pageDescriptions.DetectedProducts}
          exact
          onClick={props.clickNavLinkFunc(
            "/detected_products",
            "Detected Products"
          )}
          to="/detected_products"
          inAppMessageText={
            <NewFeatureInAppMessagePopupContent
              featureName="Detected Products"
              description="You can now view products used by your organization to audit your technology inventory and find shadow IT."
              learnMoreLink={
                "https://help.upguard.com/en/articles/8663342-what-is-detected-products"
              }
            />
          }
          inAppMessageType={InAppMessageType.DetectedProductsNewFeature}
        />
      )}
      {showSubsidiaries && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="subsidiaries"
          text="Subsidiaries"
          hoverText={pageDescriptions.Subsidiaries}
          exact
          onClick={props.clickNavLinkFunc("/subsidiaries", "Subsidiaries")}
          to="/subsidiaries"
        />
      )}
      {showSubsidiaryMenu && (
        <>
          <div
            className={classnames("nav-sub-section", {
              active: isOnSubsidiaryRoute,
            })}
          >
            <div
              className={classnames("subtitle header-section-name", {
                active: isOnSubsidiaryRoute,
              })}
              onClick={() => closeSubsidiariesMenu(isOnSubsidiaryRoute)}
            >
              <div className="subtitle vendor-name">
                {getSubtitleDisplay(
                  _get(selectedSubsidiary!, "display_name", null)
                )}
              </div>
              <Icon name="x" />
            </div>

            <NavLink
              disabled={props.trialExpired}
              icon="vendor-summary"
              text="Summary"
              hoverText={pageDescriptions.SubsidiarySummary}
              onClick={props.clickNavLinkFunc(
                `/subsidiaries/${selectedSubsidiaryVendorId}/summary`,
                "Subsidiary Summary",
                `${selectedSubsidiaryVendorId}`
              )}
              to={`/subsidiaries/${selectedSubsidiaryVendorId}/summary`}
            />
            <NavLink
              disabled={
                props.trialExpired ||
                hasOrgPermission(Permissions.OrgHideVendorRisks)
              }
              icon="focus"
              text="Risk Profile"
              hoverText={pageDescriptions.SubsidiaryRiskProfile}
              onClick={props.clickNavLinkFunc(
                `/subsidiaries/${selectedSubsidiaryVendorId}/risk_profile`,
                "Subsidiary Risk Profile",
                `${selectedSubsidiaryVendorId}`
              )}
              to={`/subsidiaries/${selectedSubsidiaryVendorId}/risk_profile`}
            />
            <NavLink
              disabled={
                props.trialExpired ||
                hasOrgPermission(Permissions.OrgHideVendorRisks)
              }
              icon="globe"
              text="Domains"
              hoverText={pageDescriptions.SubsidiaryWebsitesAndAPIs}
              exact
              onClick={props.clickNavLinkFunc(
                `/subsidiaries/${selectedSubsidiaryVendorId}/webscans`,
                `Subsidiary Domains`,
                `${selectedSubsidiaryVendorId}`
              )}
              to={`/subsidiaries/${selectedSubsidiaryVendorId}/webscans`}
            />
            <NavLink
              disabled={
                props.trialExpired ||
                hasOrgPermission(Permissions.OrgHideVendorRisks)
              }
              icon="globe"
              text="IP Addresses"
              hoverText={pageDescriptions.SubsidiaryIPAddresses}
              exact
              onClick={props.clickNavLinkFunc(
                `/subsidiaries/${selectedSubsidiaryVendorId}/ips`,
                "Subsidiary IP Addresses"
              )}
              to={`/subsidiaries/${selectedSubsidiaryVendorId}/ips`}
            />
            {hasOrgPermission(Permissions.OrgAccessVulns) && (
              <NavLink
                disabled={
                  props.trialExpired ||
                  hasOrgPermission(Permissions.OrgHideVendorRisks)
                }
                icon="bug"
                text="Vulnerabilities"
                hoverText={pageDescriptions.SubsidiaryVulns}
                exact
                onClick={props.clickNavLinkFunc(
                  `/subsidiaries/${selectedSubsidiaryVendorId}/vulns`,
                  "Subsidiary Vulnerabilities"
                )}
                to={`/subsidiaries/${selectedSubsidiaryVendorId}/vulns`}
              />
            )}
            {
              <NavLink
                disabled={
                  props.trialExpired ||
                  hasOrgPermission(Permissions.OrgHideVendorRisks)
                }
                icon="spanner"
                text="Remediation"
                hoverText={pageDescriptions.SubsidiaryRemediation}
                onClick={props.clickNavLinkFunc(
                  `/subsidiaries/${selectedSubsidiaryVendorId}/remediation`,
                  "Subsidiary Remediation"
                )}
                to={`/subsidiaries/${selectedSubsidiaryVendorId}/remediation`}
              />
            }
            {selectedSubsidiaryVendorId &&
              _get(selectedSubsidiary, "has_subsidiaries", false) && (
                <NavLink
                  disabled={props.trialExpired}
                  icon="subsidiaries"
                  text="Subsidiaries"
                  hoverText={pageDescriptions.Subsidiaries}
                  exact
                  onClick={props.clickNavLinkFunc(
                    `/subsidiaries/${selectedSubsidiaryVendorId}/subsidiaries`,
                    "Subsidiary Subsidiaries"
                  )}
                  to={`/subsidiaries/${selectedSubsidiaryVendorId}/subsidiaries`}
                />
              )}
          </div>
        </>
      )}
      {showVulnerabilities && (
        <NavLink
          disabled={
            props.trialExpired ||
            hasOrgPermission(Permissions.OrgHideVendorRisks)
          }
          icon="bug"
          text="Vulnerabilities"
          hoverText={pageDescriptions.Vulns}
          exact
          onClick={props.clickNavLinkFunc("/vulns/", "Vulnerabilities")}
          to="/vulns"
        />
      )}
      {showIdentityBreaches && (
        <NavLink
          disabled={props.trialExpired}
          icon="email"
          text="Identity Breaches"
          hoverText={pageDescriptions.Identities}
          onClick={props.clickNavLinkFunc(
            "/email_exposures",
            "Identity Breaches"
          )}
          to="/email_exposures"
        />
      )}
      {showTyposquatting && (
        <NavLink
          disabled={props.trialExpired}
          icon="typosquatting"
          text="Typosquatting"
          hoverText={pageDescriptions.Typosquatting}
          onClick={props.clickNavLinkFunc("/typosquatting", "Typosquatting")}
          to="/typosquatting"
        />
      )}
    </>
  );
};

export default BreachSightNavItems;
