import { Component, useState } from "react";

import { get as _get } from "lodash";
import {
  setAdditionalEvidenceSelectedTab,
  fetchVendorWatchStatus,
  refreshAdditionalEvidenceData,
  fetchVendorSummaryAndCloudscans,
} from "../reducers/cyberRiskActions";
import {
  fetchAdditionalEvidenceForVendor,
  uploadAndCreateNewAdditionalEvidenceDocument,
} from "../reducers/additionalEvidence.actions";
import {
  VendorOverlayInjectedProps,
  wrapInVendorOverlay,
} from "./VendorOverlayWrapper";
import {
  UserAccessVendorAssessments,
  UserWriteAdditionalEvidence,
  OrgAccessVendorAssessments,
  UserSystemRoleVendorManagementAnalyst,
  GroupAccessVendorAssetSharing,
  UserBreachsightWrite,
  UserVendorRiskWrite,
  getUserPermissionsForVendorFromState,
  OrgRequestAdditionalEvidence,
  hasOrgPermission,
} from "../../_common/permissions";
import Button from "../../_common/components/core/Button";
import SlidePanel from "../../_common/components/SlidePanel";
import { FilterPanelContainer } from "../components/filter";

import "../style/views/AdditionalEvidenceDocumentsTab.scss";
import ReportCard from "../../_common/components/ReportCard";
import TabButtons from "../../_common/components/TabButtons";
import AdditionalEvidenceListTable from "../components/AdditionalEvidenceListTable";
import { useDropzone } from "react-dropzone";
import { getLocalStorageItem } from "../../_common/session";
import { getCurrentOrgFromUserData } from "../../_common/helpers";
import EmptyCardWithAction from "../../_common/components/EmptyCardWithAction";
import {
  addDefaultSuccessAlert,
  addSimpleErrorAlert,
} from "../../_common/reducers/messageAlerts.actions";
import { getVendorWords } from "../../_common/constants";
import {
  DefaultThunkDispatch,
  DefaultThunkDispatchProp,
} from "../../_common/types/redux";
import { Filters } from "../components/filter/types";
import { History } from "history";
import { AssuranceType } from "../../_common/types/organisations";
import { DefaultRouteProps, locationState } from "../../_common/types/router";
import DragDropUploadMulti from "../../_common/components/DragDropUploadMulti";

import {
  AdditionalEvidenceSummary,
  CurrentAdditionalEvidenceList,
  CurrentSharedAdditionalEvidenceList,
  SharedAdditionalEvidence,
} from "../types/additionalEvidence";
import CircleDocAttachmentSvg from "../images/circle_doc_attachment.svg";
import { AdditionalEvidenceRiskEvidence } from "../../_common/types/vendorSummary";
import { RemediationRequestSharedEvidence } from "../../_common/types/remediation";
import { appConnect } from "../../_common/types/reduxHooks";
import { invalidateVendorAssessmentDraftsForVendor } from "../reducers/vendorAssessmentAPI";
import { getColorForKey } from "../components/PillLabel";

export const AcceptedEvidenceFileExtensions = [
  ".jpg",
  ".jpeg",
  ".png",
  ".pdf",
  ".docx",
  ".xlsx",
  ".csv",
  ".pptx",
];
export const AcceptedEvidenceMimeTypes = [
  "text/csv",
  "text/x-csv",
  "text/plain",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
];
export const AcceptedEvidenceFileExtensionsList =
  AcceptedEvidenceFileExtensions.map((ext) => ext.split(".").pop()).join(", ");

export const DocumentTypes = (typeName: string) => ({
  label: typeName,
  color: getColorForKey(typeName),
});

export const DownloadEvidenceFromSummary = (
  evidence: AdditionalEvidenceSummary,
  isVendorPortal: boolean,
  dispatch: DefaultThunkDispatch
) =>
  downloadEvidence(
    evidence.documentID,
    evidence.filename,
    evidence.virusScanned,
    evidence.virusSafe,
    isVendorPortal,
    dispatch
  );

export const DownloadEvidenceFromRiskEvidence = (
  evidence: AdditionalEvidenceRiskEvidence,
  isVendorPortal: boolean,
  dispatch: DefaultThunkDispatch
) =>
  downloadEvidence(
    evidence.documentId,
    evidence.filename,
    evidence.virusScanned,
    evidence.virusSafe,
    isVendorPortal,
    dispatch
  );

export const DownloadEvidenceFromRemediationRisk = (
  evidence: RemediationRequestSharedEvidence,
  isVendorPortal: boolean,
  dispatch: DefaultThunkDispatch
) =>
  downloadEvidence(
    evidence.documentId ?? 0,
    evidence.filename ?? "",
    evidence.virusScanned ?? false,
    evidence.virusSafe ?? false,
    isVendorPortal,
    dispatch
  );

const downloadEvidence = (
  documentId: number,
  filename: string,
  virusScanned: boolean,
  virusSafe: boolean,
  isVendorPortal: boolean,
  dispatch: DefaultThunkDispatch
) => {
  if (virusScanned && virusSafe) {
    const { apiKey, token } = getLocalStorageItem("cyberRiskAuth");
    const url = `/api/${
      isVendorPortal ? "vendorportal" : "vendor"
    }/evidence/download/v1/?apikey=${encodeURIComponent(
      apiKey
    )}&token=${encodeURIComponent(token)}&document_id=${documentId}`;
    window.open(url, "_blank");
  } else if (!virusScanned) {
    dispatch(
      addSimpleErrorAlert(
        `Virus scanning for file ${filename} has not yet completed`
      )
    );
  } else {
    dispatch(
      addSimpleErrorAlert(
        `Virus scanning for file ${filename} failed. It cannot be downloaded`
      )
    );
  }
};

interface IDragDropUploadButtonProps {
  onFileSelected: (file: File) => void;
  onFileRejected: (file: File) => void;
  maxFileSize?: number;
  acceptedFileTypeFilters?: string[];
  text?: string;
  icon?: string;
  side?: string;
  primary?: boolean;
  className?: string;
}

export function DragDropUploadButton({
  onFileSelected,
  onFileRejected,
  maxFileSize = 50000000,
  acceptedFileTypeFilters = [
    ...AcceptedEvidenceFileExtensions,
    ...AcceptedEvidenceMimeTypes,
  ],
  text = "Upload file",
  icon = "cr-icon-upload",
  side = "left",
  primary = false,
  className,
}: IDragDropUploadButtonProps) {
  const [_, setSelectedFile] = useState<File | null>(null);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    maxSize: maxFileSize,
    accept: acceptedFileTypeFilters,
    onDrop: (acceptedFiles, rejectedFiles) => {
      if (acceptedFiles.length > 0) {
        setSelectedFile(acceptedFiles[0]);
        onFileSelected(acceptedFiles[0]);
      } else if (rejectedFiles.length > 0) {
        onFileRejected(rejectedFiles[0]);
      }
    },
  });

  return (
    <div>
      <div {...getRootProps({})}>
        <input {...getInputProps()} />
        <Button primary={primary} className={className}>
          {side === "left" && <div className={icon} />}
          {text}
          {side !== "left" && <div className={icon} />}
        </Button>
      </div>
    </div>
  );
}

interface matchParams {
  currentTab?: string;
}

type AdditionalEvidenceDocumentsTabOwnProps = DefaultRouteProps<
  matchParams,
  locationState
>;

interface AdditionalEvidenceDocumentsTabConnectedProps {
  userHasWriteAdditionalEvidencePermission: boolean;
  userHasVendorAssessmentPermission: boolean;
  currentTab?: string;
  evidence?: CurrentAdditionalEvidenceList;
  sharedAdditionalEvidence?: CurrentSharedAdditionalEvidenceList;
  loading: boolean;
  error?: any;
  filters: Filters;
  userIsManagedVendorAnalyst?: boolean;
  managedOrgId?: number;
  isManagementAnalystSession?: boolean;
  mayHaveSharedAssets: boolean;
  userHasWriteVendorInfoPermission: boolean;
  assuranceType: AssuranceType;
  hasOrgRequestAdditionalEvidence: boolean;
}

type AdditionalEvidenceDocumentsTabProps =
  AdditionalEvidenceDocumentsTabOwnProps &
    AdditionalEvidenceDocumentsTabConnectedProps &
    VendorOverlayInjectedProps &
    DefaultThunkDispatchProp;

interface AdditionalEvidenceDocumentsTabState {
  watchedStateLoading: boolean;
  filterPanelOpen: boolean;
  followLoading: boolean;
  newEvidenceId?: number;
}

/**
 * TODO: would be great to turn this into a generic 'DocumentDisplay' component
 * as I don't think there is too much here that is specific for AdditionalEvidenceDocument's
 */
class AdditionalEvidenceDocumentsTab extends Component<
  AdditionalEvidenceDocumentsTabProps,
  AdditionalEvidenceDocumentsTabState
> {
  public static defaultProps = {
    vendorId: undefined,
    vendorName: "",
    sharedAdditionalEvidence: { active: [], archived: [] },
    error: null,
    vendorVerified: false,
    newlyWatched: false,
    userIsManagedVendorAnalyst: false,
    managedOrgId: 0,
    isManagementAnalystSession: false,
  };

  private autoRefresh: any; // TODO

  constructor(props: AdditionalEvidenceDocumentsTabProps) {
    super(props);
    this.state = {
      watchedStateLoading: false,
      filterPanelOpen: false,
      followLoading: false,
    };

    // this is the data that is missing on reload
    if (props.vendorId) {
      this.props.dispatch(fetchVendorWatchStatus(props.vendorId));
    }

    if (!props.vendorId || props.watching) {
      this.fetchData(props.dispatch, props.history, props.vendorId, false);
    }

    // Set up a timer to auto refresh virus scanning status
    this.autoRefresh = setInterval(this.refreshScanningEvidence, 5000);
  }

  componentDidUpdate(prevProps: AdditionalEvidenceDocumentsTabProps) {
    if (prevProps.vendorId !== this.props.vendorId) {
      this.fetchData(
        this.props.dispatch,
        this.props.history,
        this.props.vendorId,
        false
      );
    } else if (
      this.props.vendorId &&
      !prevProps.watching &&
      this.props.watching
    ) {
      this.fetchData(
        this.props.dispatch,
        this.props.history,
        this.props.vendorId,
        true
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.autoRefresh);
    delete this.autoRefresh;
  }

  fetchData = (
    dispatch: DefaultThunkDispatch,
    _history: History,
    vendorId = 0,
    force = false
  ) => {
    // currently we only operate under the auspices of a selected vendor
    if (vendorId) {
      dispatch(fetchAdditionalEvidenceForVendor(vendorId, force));
    }
  };

  onChangeTab = (tab: string) => {
    this.props.dispatch(
      setAdditionalEvidenceSelectedTab(this.props.vendorId, tab)
    );

    this.props.history.replace(
      this.props.vendorId
        ? `${this.backTo(this.props.vendorId)}/${tab}`
        : `/evidence/${tab}`,
      { ...this.props.location.state }
    );
  };

  onToggleFilterPanel = () => {
    this.setState(({ filterPanelOpen }) => ({
      filterPanelOpen: !filterPanelOpen,
    }));
  };

  onVendorWatched = () => {
    this.fetchData(
      this.props.dispatch,
      this.props.history,
      this.props.vendorId,
      true
    );
  };

  getEvidenceFromProps = (props: AdditionalEvidenceDocumentsTabProps) => {
    let evidence: (AdditionalEvidenceSummary | SharedAdditionalEvidence)[] = [];
    let all: (AdditionalEvidenceSummary | SharedAdditionalEvidence)[] = [];
    all = all.concat(
      props.evidence?.active
        ? props.evidence.active.map((ev) => ({ ...ev, kind: "evidence" }))
        : []
    );
    all = all.concat(
      props.evidence?.archived
        ? props.evidence.archived.map((ev) => ({ ...ev, kind: "evidence" }))
        : []
    );
    all = all.concat(
      props.sharedAdditionalEvidence?.active.map((ev) => ({
        ...ev,
        totalRisks: 0,
        includeInRiskProfile: false,
        kind: "shared_evidence",
      })) || []
    );
    all = all.concat(
      props.sharedAdditionalEvidence?.archived.map((ev) => ({
        ...ev,
        totalRisks: 0,
        includeInRiskProfile: false,
        kind: "shared_evidence",
      })) || []
    );

    switch (props.currentTab) {
      case "active":
        evidence = props.evidence?.active
          ? props.evidence.active.map((ev) => ({ ...ev, kind: "evidence" }))
          : [];
        evidence = evidence.concat(
          props.sharedAdditionalEvidence?.active.map((ev) => ({
            ...ev,
            kind: "shared_evidence",
          })) || []
        );
        break;
      case "archived":
        evidence =
          props.evidence?.archived.map((ev) => ({ ...ev, kind: "evidence" })) ??
          [];
        evidence = evidence.concat(
          props.sharedAdditionalEvidence?.archived.map((ev) => ({
            ...ev,
            kind: "shared_evidence",
          })) || []
        );
        break;
      case "all":
        evidence = all;
        break;
    }
    return { evidence, all };
  };

  refreshScanningEvidence = async () => {
    const { evidence } = this.getEvidenceFromProps({
      currentTab: "all",
      evidence: this.props.evidence,
    } as AdditionalEvidenceDocumentsTabProps);
    for (let i = 0; i < evidence.length; i++) {
      if (!evidence[i].virusScanned) {
        this.props
          .dispatch(
            refreshAdditionalEvidenceData(
              evidence[i].datastoreVendorID,
              evidence[i].id
            )
          )
          .then((updatedevidence) => {
            if (
              updatedevidence &&
              updatedevidence.virusScanned &&
              !updatedevidence.virusSafe
            ) {
              this.props.dispatch(
                addSimpleErrorAlert(
                  `A virus was detected in evidence file ${updatedevidence.filename}`,
                  ["The file has been removed from the system."]
                )
              );
            }
          });
      }
    }
  };

  uploadEvidenceFile = (file: File) => {
    return this.props
      .dispatch(
        uploadAndCreateNewAdditionalEvidenceDocument(
          file,
          this.props.vendorId,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          true
        )
      )
      .then((newEvidenceId) => {
        this.setState({ newEvidenceId: newEvidenceId.id });
      });
  };

  fetchDataAfterUpload = (newEvidenceId?: number) => {
    if (this.props.userHasVendorAssessmentPermission && this.props.vendorId) {
      this.props.dispatch(
        invalidateVendorAssessmentDraftsForVendor(this.props.vendorId)
      );
    }
    if (this.props.vendorId) {
      // update the vendor summary data
      this.props.dispatch(
        fetchVendorSummaryAndCloudscans(this.props.vendorId, true)
      );
      if (newEvidenceId) {
        this.displayEvidenceDetails({
          datastoreVendorID: this.props.vendorId,
          id: newEvidenceId,
        } as AdditionalEvidenceSummary);
      }
    }
  };

  displayEvidenceDetails = (evidence: AdditionalEvidenceSummary) => {
    if (!this.props.vendorId) return;
    const backContext = {
      backTo: this.props.vendorId
        ? this.backTo(this.props.vendorId)
        : `/evidence`,
      backToText: `Back to Additional Evidence List`,
    };
    this.props.history.push(
      `${this.urlPrefix(this.props.vendorId)}/evidence/details/${evidence.id}`,
      {
        backContext,
        noRemoveWhispers: true,
      }
    );
  };

  displayEvidenceRequestForm = () => {
    if (!this.props.vendorId) return;
    const backContext = {
      backTo: this.props.history.location.pathname,
      backToText: `Back to Additional Evidence List`,
    };
    this.props.history.push(
      `${this.urlPrefix(this.props.vendorId)}/evidence/request`,
      {
        backContext,
        noRemoveWhispers: true,
      }
    );
  };

  displaySharedAssetsDetails = (orgId: number) => {
    if (!this.props.vendorId) return;
    const backContext = {
      backTo: this.props.vendorId
        ? this.backTo(this.props.vendorId)
        : `/evidence`,
      backToText: `Back to Additional Evidence List`,
    };

    this.props.history.push(
      `${this.urlPrefix(this.props.vendorId)}/sharedassets/${orgId}`,
      {
        backContext,
      }
    );
  };

  displaySharedProfileDetails = () => {
    if (!this.props.vendorId) return;
    const backContext = {
      backTo: this.props.vendorId
        ? this.backTo(this.props.vendorId)
        : `/evidence`,
      backToText: `Back to Additional Evidence List`,
    };

    this.props.history.push(
      `${this.urlPrefix(this.props.vendorId)}/sharedassets/vendor`,
      {
        backContext,
      }
    );
  };

  urlPrefix = (vendorId: number) => {
    if (this.props.isManagementAnalystSession) {
      return `/analysts/tpvm/${this.props.managedOrgId}/${vendorId}`;
    }
    return `/vendor/${vendorId}`;
  };

  backTo = (vendorId: number) => {
    if (this.props.isManagementAnalystSession) {
      return `/analysts/tpvm/${this.props.managedOrgId}/${vendorId}/evidence`;
    }
    return `/vendor/${vendorId}/evidence`;
  };

  render() {
    const title = "Documents";
    const notFollowing =
      this.props.vendorId &&
      (this.state.watchedStateLoading || !this.props.watching);

    const { evidence, all } = this.getEvidenceFromProps(this.props);

    const vendorWords = getVendorWords(this.props.assuranceType);

    const active = all.filter((e) => !e.archived);
    const archived = all.filter((e) => e.archived);

    return (
      <div id="additional_evidence_view">
        {this.props.error && (
          <ReportCard newStyles className="evidence-card">
            <div className="empty-content">
              <div className={"text"}>{this.props.error.errorText}</div>
              <Button onClick={this.props.error.actionOnClick}>
                {this.props.error.actionText}
              </Button>
            </div>
          </ReportCard>
        )}
        {!notFollowing && !this.props.error && (
          <ReportCard newStyles className="evidence-card">
            <div className="header">
              <span className={"title"}>{title}</span>
              {this.props.hasOrgRequestAdditionalEvidence && (
                <Button onClick={this.displayEvidenceRequestForm} primary>
                  <div className="cr-icon-q-builder-document-attachment cr-icon-lg" />{" "}
                  Request documents
                </Button>
              )}
            </div>
            {this.props.userHasWriteAdditionalEvidencePermission &&
              this.props.watching && (
                <div className={"upload-target"}>
                  <DragDropUploadMulti
                    acceptedFileTypeFilters={[
                      ...AcceptedEvidenceFileExtensions,
                      ...AcceptedEvidenceMimeTypes,
                    ]}
                    maxFileSize={50000000}
                    autoCloseOnAllComplete
                    onUploadFile={(file) => this.uploadEvidenceFile(file)}
                    onDone={(numUploaded) => {
                      if (numUploaded > 1) {
                        this.props.dispatch(
                          addDefaultSuccessAlert(
                            `${numUploaded} documents have been added to additional evidence`,
                            [
                              "To add more details, click on any of the documents in the list and click Edit.",
                            ]
                          )
                        );
                      } else if (numUploaded === 1) {
                        this.props.dispatch(
                          addDefaultSuccessAlert(
                            `Document added to additional evidence`
                          )
                        );
                      }

                      if (numUploaded > 0) {
                        this.onChangeTab("active");

                        this.fetchDataAfterUpload(
                          numUploaded === 1
                            ? this.state.newEvidenceId
                            : undefined
                        );
                      }
                    }}
                  />
                </div>
              )}
            {(!this.props.userHasWriteAdditionalEvidencePermission ||
              !this.props.watching) && <div className="vert-spacer" />}
            {!!all && all.length > 0 && (
              <div className={"tabs"}>
                <TabButtons
                  tabs={[
                    {
                      id: "active",
                      text: `Active (${
                        (this.props.evidence?.active || []).length +
                        (this.props.sharedAdditionalEvidence?.active.length ??
                          0)
                      })`,
                    },
                    {
                      id: "archived",
                      text: `Archived (${
                        (this.props.evidence?.archived || []).length +
                        (this.props.sharedAdditionalEvidence?.archived.length ??
                          0)
                      })`,
                    },
                  ]}
                  activeTabId={this.props.currentTab}
                  onChangeTab={(tab) => {
                    this.onChangeTab(tab);
                  }}
                />
              </div>
            )}
            {(this.props.loading || (evidence && evidence.length > 0)) && (
              <AdditionalEvidenceListTable
                dispatch={this.props.dispatch}
                history={this.props.history}
                evidence={evidence}
                loading={this.props.loading}
                stickyColumnHeaders
                userHasWritePermission={
                  this.props.userHasWriteAdditionalEvidencePermission
                }
                mode={this.props.currentTab}
                downloadEvidence={(ev) =>
                  DownloadEvidenceFromSummary(ev, false, this.props.dispatch)
                }
                displayEvidenceDetails={this.displayEvidenceDetails}
                displaySharedAssetsDetails={this.displaySharedAssetsDetails}
                displaySharedProfileDetails={this.displaySharedProfileDetails}
                mayHaveSharedAssets={this.props.mayHaveSharedAssets}
                vendorId={this.props.vendorId ?? 0}
                vendorName={this.props.vendorName}
                assuranceType={this.props.assuranceType}
                userHasVendorAssessmentPermission={
                  this.props.userHasVendorAssessmentPermission
                }
              />
            )}
            {this.props.currentTab === "active" &&
              active.length === 0 &&
              archived.length > 0 && (
                <EmptyCardWithAction
                  iconSrc={CircleDocAttachmentSvg}
                  emptyText={"No active additional evidence."}
                />
              )}
            {this.props.currentTab === "archived" &&
              active.length > 0 &&
              archived.length === 0 && (
                <EmptyCardWithAction
                  iconSrc={CircleDocAttachmentSvg}
                  emptyText={"No archived additional evidence."}
                />
              )}
            {!this.props.loading &&
              (!evidence || evidence.length === 0) &&
              (!this.props.userHasWriteAdditionalEvidencePermission ||
                !this.props.watching) && (
                <EmptyCardWithAction
                  emptyText="No additional evidence found"
                  emptySubText={`No additional evidence has been uploaded for this ${vendorWords.singular} yet.`}
                />
              )}
          </ReportCard>
        )}
        <SlidePanel
          active={this.state.filterPanelOpen}
          title="Filter by"
          newStyles
          dimContent
          onClose={this.onToggleFilterPanel}
        >
          <FilterPanelContainer
            closePanel={this.onToggleFilterPanel}
            supportedFilters={[]}
          />
        </SlidePanel>
      </div>
    );
  }
}

export default wrapInVendorOverlay(
  appConnect<
    AdditionalEvidenceDocumentsTabConnectedProps,
    undefined,
    AdditionalEvidenceDocumentsTabOwnProps & VendorOverlayInjectedProps
  >((state, props) => {
    const userSystemRoles = state.common.userData.system_roles.reduce(
      (prev, perm) => {
        prev[perm] = true;
        return prev;
      },
      {} as Record<string, boolean>
    );

    const isSubsidiary = props.match.path.startsWith("/subsidiaries");

    const userIsManagedVendorAnalyst =
      !!userSystemRoles[UserSystemRoleVendorManagementAnalyst];

    const isManagedVendorAnalyst =
      userIsManagedVendorAnalyst &&
      props.match.path.startsWith("/analysts/tpvm") &&
      !!props.managedOrgId &&
      props.managedOrgId > 0;

    const isBreachSight = !props.vendorId || isSubsidiary;
    const isVendor = !!props.vendorId && !isSubsidiary;
    const userPerms = getUserPermissionsForVendorFromState(
      state,
      isVendor ? props.vendorId || 0 : 0
    );

    let orgPerms: Record<string, boolean>;
    if (isManagedVendorAnalyst && !!props.managedOrgId) {
      const managedOrgEntitlements: string[] = state.cyberRisk
        .managedVendorData[props.managedOrgId]
        ? state.cyberRisk.managedVendorData[props.managedOrgId].entitlements
        : [];
      orgPerms = managedOrgEntitlements
        ? managedOrgEntitlements.reduce(
            (prev, perm) => {
              prev[perm] = true;
              return prev;
            },
            {} as Record<string, boolean>
          )
        : {};
    } else {
      orgPerms = state.common.userData.orgPermissions
        ? state.common.userData.orgPermissions.reduce(
            (prev, perm) => {
              prev[perm] = true;
              return prev;
            },
            {} as Record<string, boolean>
          )
        : {};
    }

    let data;
    if (props.vendorId) {
      if (isManagedVendorAnalyst) {
        data = _get(
          state.cyberRisk.managedVendorData,
          `[${props.managedOrgId}][${props.vendorId}]`,
          {}
        );
      } else {
        data = _get(state.cyberRisk, `vendors.${props.vendorId}`, {});
      }
    } else {
      data = state.cyberRisk.customerData;
    }

    let { currentTab } = props.match.params;
    if (!currentTab) {
      currentTab = _get(data, "evidence_tab", "active");
    }
    let evidence = _get(data, "evidence.data", { active: [], archived: [] });
    if (!evidence) {
      evidence = { active: [], archived: [] };
    }

    let managedOrgId;
    if (isManagedVendorAnalyst) {
      managedOrgId = parseInt(props.match.params.orgId || "");
    }

    const currentOrg = getCurrentOrgFromUserData(state.common.userData);
    const mayHaveSharedAssets =
      !!props.vendorVerified ||
      (!!currentOrg?.organisationGroupId &&
        !!currentOrg?.organisationGroupEntitlements &&
        currentOrg?.organisationGroupEntitlements.includes(
          GroupAccessVendorAssetSharing
        ));

    const userHasWriteVendorInfoPermission =
      (isBreachSight && !!userPerms[UserBreachsightWrite]) ||
      (isVendor && !!userPerms[UserVendorRiskWrite]);

    return {
      currentTab: currentTab,
      evidence: evidence,
      loading: _get(data, "evidence.loading", true),
      error: _get(data, "evidence.error", null),
      sharedAdditionalEvidence: _get(
        data,
        "evidence.sharedAdditionalEvidence",
        { active: [], archived: [] }
      ),
      userHasWriteAdditionalEvidencePermission:
        !!userPerms[UserWriteAdditionalEvidence] || isManagedVendorAnalyst,
      userHasVendorAssessmentPermission:
        !!orgPerms[OrgAccessVendorAssessments] &&
        (isManagedVendorAnalyst || !!userPerms[UserAccessVendorAssessments]),
      userHasWriteVendorInfoPermission: userHasWriteVendorInfoPermission,
      filters: state.cyberRisk.customerData.filters,
      assuranceType: state.common.userData.assuranceType,
      totalCloudscansOverExportMax: _get(
        data,
        "summary.result.totalCloudscansOverExportMax",
        false
      ),
      exportMaxCloudscans: _get(data, "summary.result.exportMaxCloudscans", 0),
      managedOrgId: managedOrgId,
      isManagementAnalystSession: isManagedVendorAnalyst,
      userIsManagedVendorAnalyst: userIsManagedVendorAnalyst,
      mayHaveSharedAssets,
      hasOrgRequestAdditionalEvidence: hasOrgPermission(
        state.common.permissions,
        OrgRequestAdditionalEvidence
      ),
    } as AdditionalEvidenceDocumentsTabConnectedProps;
  })(AdditionalEvidenceDocumentsTab)
);
