import { DefaultRootState, shallowEqual } from "react-redux";
import { IRiskHostnameKeyData } from "../../_common/types/risks";
import { getRiskVendorWebsites } from "./vendorRiskPortfolio.actions";
import { RiskVendorWebsitesKeyData } from "../../_common/types/redux";
import { RiskDetail } from "../../_common/types/vendor";
import { useAppSelector } from "../../_common/types/reduxHooks";

export const getCustomerRiskWebsites = (
  state: DefaultRootState,
  riskId: string
): IRiskHostnameKeyData =>
  state.cyberRisk?.customerData?.riskHostnames[riskId] ?? {
    loading: false,
    error: null,
    data: undefined,
    websiteLabelIds: [],
    domainPortfolioIds: [],
    includeWaived: false,
  };

export interface RiskWebsiteOptions {
  isSelfRemediation: boolean;
  isSubsidiary: boolean;
  vendorID?: number;
}

interface IAllRiskHostnames {
  [key: string]: IRiskHostnameKeyData | RiskVendorWebsitesKeyData;
}

const getAllRiskWebsites = (
  state: DefaultRootState,
  risks: RiskDetail[] | undefined,
  opts: RiskWebsiteOptions
): IAllRiskHostnames =>
  risks?.reduce((map, risk) => {
    if (opts.isSelfRemediation) {
      map[risk.id] = getCustomerRiskWebsites(state, risk.id);
    } else if (opts.vendorID) {
      map[risk.id] = getRiskVendorWebsites(
        state,
        risk.id,
        opts.vendorID,
        opts.isSubsidiary,
        false,
        undefined
      );
    }
    return map;
  }, {} as IAllRiskHostnames) || {};

export const useAllRiskWebsites = (
  risks: RiskDetail[] | undefined,
  options: RiskWebsiteOptions
) =>
  useAppSelector(
    (state) => getAllRiskWebsites(state, risks, options),
    shallowEqual
  );

export const useRiskWebsites = (
  riskId: string,
  opts: RiskWebsiteOptions
): IRiskHostnameKeyData | RiskVendorWebsitesKeyData | undefined =>
  useAppSelector((state) =>
    opts.isSelfRemediation
      ? getCustomerRiskWebsites(state, riskId)
      : opts.vendorID
        ? getRiskVendorWebsites(
            state,
            riskId,
            opts.vendorID,
            opts.isSubsidiary,
            false,
            undefined
          )
        : undefined
  );
