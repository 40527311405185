import { FC } from "react";
import UserBaseAPI from "../api/userbase.api";
import { useDefaultHistory } from "../../_common/types/router";
import { dashboardUrl, usersUrl } from "../UserBaseAppRouter";
import SingleStat from "./SingleStat";
import { pluralise } from "../../_common/helpers";

const OrgUserCount: FC = ({}) => {
  const history = useDefaultHistory();

  const onViewUsers = () => {
    history.push(usersUrl, {
      backContext: {
        backTo: dashboardUrl,
        backToText: `Back to Dashboard`,
      },
    });
  };

  const { data, isLoading } = UserBaseAPI.useGetUserBaseDashboardStatsV1Query();
  const numUsers = data?.stats?.numUsers ?? 0;

  return (
    <SingleStat
      isLoading={isLoading}
      statNum={numUsers}
      statDescription={pluralise(
        numUsers,
        "Total active user",
        "Total active users"
      )}
      onClick={onViewUsers}
      statLinkText="View all users"
    />
  );
};

export default OrgUserCount;
