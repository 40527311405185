import ReportCard from "../../_common/components/ReportCard";
import XTable, {
  SortDirection,
  IXTableRow,
  XTableCell,
} from "../../_common/components/core/XTable";
import { useSorting, usePagination } from "../../_common/hooks";

import ColorGrade, {
  ColorGradeSize,
} from "../../vendorrisk/components/executive_summary/ColorGrade";
import Score from "../../vendorrisk/components/Score";

import { Scope, UserWithStats } from "../api/types";
import UserBaseAPI from "../api/userbase.api";

import { FC, useState } from "react";
import TeamsPills from "./TeamsPills";

import classNames from "classnames";
import SlidePanel from "../../_common/components/SlidePanel";
import UserAppsForSpecificScopePanel from "./UserAppsForSpecificScopePanel";
import "./UsersTableForScope.scss";

interface UserTableForScopeProps {
  // The uids of the users to display
  userUUIDs: string[];

  // The scope the users above have granted access to
  scope: Scope;

  // Allow for custom class names
  className?: string;
}
const UserTableForScope: FC<UserTableForScopeProps> = ({
  userUUIDs,
  scope,
  className,
}) => {
  const { data, isLoading } = UserBaseAPI.useGetUserBaseUsersV1Query({
    userUUIDs: userUUIDs,
  });

  const usersToUse = data?.users ?? [];

  const [slidePanelUser, setSlidePanelUser] = useState<
    UserWithStats | undefined
  >(undefined);

  const [sortedUsers, sortedBy, onSortChange] = useSorting<
    UserWithStats,
    "name" | "team" | "security_rating"
  >(usersToUse ?? [], "name", SortDirection.ASC, {
    name: {
      orderFuncs: [(u) => u.name.toLocaleLowerCase()],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    team: {
      orderFuncs: [
        (u) =>
          (u.teams ?? [])
            .map((t) => t.toLocaleLowerCase())
            .sort((a, b) => a.localeCompare(b))
            .join(","),
      ],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
    security_rating: {
      orderFuncs: [(u) => u.adjustedRating],
      sortDirsAsc: [SortDirection.ASC],
      sortDirsDesc: [SortDirection.DESC],
    },
  });

  const [pageItems, currentPage, totalPages, onPageChange] = usePagination(
    sortedUsers,
    100
  );

  const onViewUser = (user: UserWithStats) => {
    setSlidePanelUser(user);
  };

  const intoXTableRow = (u: UserWithStats): IXTableRow<string> => {
    return {
      id: u.uuid,
      onClick: () => onViewUser(u),
      cells: [
        <XTableCell key={"name"}>{u.name}</XTableCell>,
        <XTableCell key={"role"}>{u.roles ? u.roles[0] : ""}</XTableCell>,
        <XTableCell key={"team"}>
          {u.teams ? <TeamsPills teams={u.teams} /> : " "}
        </XTableCell>,
        <XTableCell key={"security_rating"}>
          <div className="score-container">
            <ColorGrade score={u.adjustedRating} size={ColorGradeSize.Small} />
            <Score score={u.adjustedRating} small />
          </div>
        </XTableCell>,
      ],
    };
  };

  return (
    <div className={classNames("userbase-users-table", className)}>
      <ReportCard newStyles>
        <XTable
          className={"userbase-users-list"}
          loading={isLoading}
          sortedBy={sortedBy}
          onSortChange={onSortChange}
          iconOptions
          pagination={{
            currentPage: currentPage,
            totalPages: totalPages,
            onPageChange: onPageChange,
            hidePaginationIfSinglePage: true,
          }}
          columnHeaders={[
            { id: "name", text: "Name", sortable: true },
            { id: "role", text: "Role" },
            { id: "team", text: "Team", sortable: true },
            { id: "security_rating", text: "Security rating", sortable: true },
          ]}
          rows={pageItems?.map(intoXTableRow) ?? []}
        />
      </ReportCard>

      <SlidePanel
        newStyles
        dimContent
        active={!!slidePanelUser}
        className={"user-apps-slide-panel"}
        onClose={() => {
          setSlidePanelUser(undefined);
        }}
      >
        {!!slidePanelUser && (
          <UserAppsForSpecificScopePanel
            user={slidePanelUser}
            scope={scope}
            className="unset-background"
          />
        )}
      </SlidePanel>
    </div>
  );
};

export default UserTableForScope;
